const initialState = {
    treatments: [],
    user: {}
}

export const patientReducer = ( state = initialState ,action ) => {
    switch( action.type ){
        case 'GET_PATIENT':
            const p = action.payload
            return { 
                ...state,
                treatment: p.treatments,
                user: p.user
            }

        default:
            return state;
    }
}