import React, { Component, Fragment } from "react";
import apiClient from "../../../../utils/backend";
import { connect } from "react-redux";

import Header from '../../components/header_image'

class index extends Component {

    constructor(props){
        super(props)

        this.state = {
            data: {},
            loading: true
        }
    }

    componentDidMount(){
            const path = window.location.pathname.split('/')[3]
            
            apiClient.get(`/api/cases/${path}`)
            .then(res => {this.setState({data: res.data, loading: false, id: path}); console.log(res)})
            .catch(err => console.log(err))
    }

  render() {

    if(this.state.loading){
        return null
    } else {
        return (
            <Fragment>
                <div className="container-fluid p-0">
                <Header />
                <div className="row px-5 mt-4">
                    <div className="col-12 px-5">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                                <li className="breadcrumb-item"><a href="/ayuda/galeriacasos" className="text-muted">Galeria de casos</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Todos</li>
                            </ol>
                            </nav>
                    </div>
                </div>
                <div className="shadow container p-4" style={{borderRadius: '20px'}}>
                    <div className="row px-5 py-4 bg-primary" style={{borderRadius: '30px', height: '100%'}}>
                        <div className="col-8 d-flex flex-column py-4">
                            <div>
                                <h2 className="text-white">Caso {this.state.id}</h2>
                                <hr className="" style={{height: '3px', backgroundColor: '#fff', opacity: '1'}} />
                                <p className="text-white fs-5">Diagnostico</p>
                            </div>
                            <div className="mt-3">
                                <p className="text-white fs-5">{this.state.data.diagnosis}</p>
                            </div>
                            <div className="mt-4">
                                <p className="fw-bold text-white fs-5">{this.state.data.dentist.full_name}</p>
                            </div>
                            <div className="mt-3">
                                <p className="text-white fs-5">04/2018 - 09/2019</p>
                            </div>
                        </div>
                        <div className="col-4 p-4">
                            <div className="w-100 h-100 bg-white"></div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-10 mx-auto">
                            <div><h2 className="text-primary fw-bold">Información del tratamiento</h2></div>
                            <div className="row">
                                <div className="col-6 d-flex flex-column">
                                    <div className="mt-4">
                                        <h5 className="fw-bold mb-3">Información del tratamiento</h5>
                                        <p>Edad: 27 años</p>
                                        <p>Sexo: Femenino</p>
                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fw-bold mb-3">Tipo de tratamiento</h5>
                                        <p>Duracion: 16 meses</p>
                                        <p>Numero de alineadores: {this.state.data.align}</p>
                                        <p>Cantidad de etapas: {this.state.data.stage_quantity}</p>
                                    </div>
                                </div>
                                <div className="col-6 d-flex flex-column">
                                    <div className="mt-4">
                                        <h5 className="fw-bold mb-3">Tiempo de uso de Alineadores</h5>
                                        <p>Cambio cada 15 dias</p>
                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fw-bold mb-3">Resultados logrados</h5>
                                        <p>Alineación, nivelación cierre de espacios.</p>
                                    </div>
                                    <div className="mt-4">
                                        <h5 className="fw-bold mb-3">Maxilar</h5>
                                        <p>{this.state.data.maxilla === 1 ? 'Superior' : this.state.maxilla === 2 ? 'Inferior' : 'Ambos'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-10 mx-auto d-flex flex-column">
                            <div>
                                <h2 className="text-primary fw-bold">Fotos faciales</h2>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">Inicial</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                </div>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">Final</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                </div>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-10 mx-auto d-flex flex-column">
                            <div>
                                <h2 className="text-primary fw-bold">Fotos intraorales</h2>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">Inicial</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                </div>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">Final</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                </div>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-10 mx-auto d-flex flex-column">
                            <div>
                                <h2 className="text-primary fw-bold">Estudios</h2>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">Inicial</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                </div>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">Final</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                    <div className="border border-1 mx-2" style={{width: '100px', height: '100px'}}></div>
                                </div>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-10 mx-auto d-flex flex-column">
                            <div>
                                <h2 className="text-primary fw-bold">Visor 3D</h2>
                                <hr className="bg-primary" style={{height: '2px', opacity: 1}} />
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">1era Etapa</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100%', height: '300px'}}></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">2da Etapa</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100%', height: '300px'}}></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2"><h3 className="text-primary">3era Etapa</h3></div>
                                <div className="col-10 pb-3 d-flex flex-row">
                                    <div className="border border-1 mx-2" style={{width: '100%', height: '300px'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Fragment>
        )
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
