import React, { Component } from "react";
import Select from "react-select";
import apiClient from "../../../utils/backend";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      od_list: [],
      od_name: null,
      od_patients: [],
      patient_name: null,
      external_id: null,
      reason: null,
      detail: null,
      plates: null,
      maxilla: null,
      plates_quantity: null,
      plates_cost: false,
      plates_cost_count: null,
      observation: null,
      end_date: null,

      error: { error: false, message: "" },
    };

    this.handleRequest = this.handleRequest.bind(this);
    this.handleGetPatients = this.handleGetPatients.bind(this);
    this.handlePatient = this.handlePatient.bind(this);
    this.handleOd = this.handleOd.bind(this);
  }

  componentDidMount() {
    apiClient
      .get("/api/helper/getDentists")
      .then((res) => {
        if (res.status === 200) {
          // this.setState({od_list: res.data})
          let formatted = [];

          for (var i in res.data)
            formatted.push({ value: Number(i), label: res.data[i] });

          this.setState({ od_list: formatted });
          console.log(formatted);
        }
      })
      .catch((err) =>
        this.setState({
          error: {
            error: true,
            message:
              "Error al obtener los odontologos, por favor intente nuevamente.",
          },
        })
      );
  }

  handleRequest() {
    const headers = {
      "Content-Type": "application/json",
    };

    console.log(this.state);
    apiClient
      .post(
        "/api/treatmentPlateRepeat",
        {
          external_id: this.state.treatment_id,
          reason: this.state.reason,
          detail: this.state.detail,
          plates: this.state.plates,
          maxilla: this.state.maxilla,
          quantity: this.state.plates_quantity,
          carries_cost: this.state.plates_cost === true ? "yes" : "not",
          cost: this.state.plates_cost_count,
          observation: this.state.observation,
          finished_at: moment(this.state.end_date).format("YYYY-MM-DD"),
        },
        headers
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.props.history.push("/procesos/placasRepeticion");
        } else if (res.status === 422) {
          console.log("res error, ", res);
          this.setState({
            error: {
              error: true,
              message: "Error al crear la placa, por favor intente nuevamente.",
            },
          });
        } else {
          this.setState({
            error: {
              error: true,
              message: "Error al crear la placa, por favor intente nuevamente.",
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: {
            error: true,
            message: "Error al crear la placa, por favor intente nuevamente.",
          },
        });
        this.alertComponent.scrollIntoView({ behavior: "smooth" });
      });
  }

  handleOd(e) {
    this.setState({ od_name: e.label, od_id: e.value });
    this.handleGetPatients(e.value);
  }

  handlePatient(e) {
    apiClient
      .post("api/helper/getExternalId", {
        patient_id: e.value,
        dentist_id: this.state.od_id,
      })
      .then((res) => this.setState({ treatment_id: res.data }))
      .catch((err) => console.log(err));

    this.setState({ name: e.label, treatment_id: e.value });
  }

  handleGetPatients(id) {
    apiClient
      .get(`api/helper/getPatientsByDentist/${id}`)
      .then((res) => {
        let patients = [];
        Object.entries(res.data).map(([key, value]) => {
          patients.push({ value: key, label: value });
        });
        this.setState({ od_patients: patients });
        console.log(this.state);
      })
      .catch((err) => console.log(err));
    console.log(this.state);
  }

  render() {
    const selectStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        borderBottom: "2px solid #0027a8!important",
      }),
    };

    const maxilla = [
      { value: 1, label: "Superior" },
      { value: 2, label: "Inferior" },
      { value: 3, label: "Ambos" },
    ];

    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-10 d-flex flex-row justify-content-between">
            <h1 style={{ fontWeight: 700 }} className="text-primary">
              Nueva placa de repeticion
            </h1>
          </div>
        </div>
        <div className="row mt-4">
          {this.state.error.error ? (
            <div
              className="col-12 alert alert-danger text-center"
              ref={(el) => {
                this.alertComponent = el;
              }}
            >
              {this.state.error.message}
            </div>
          ) : null}
          <div className="col-3">
            <h5>Fecha de salida:</h5>
            <DatePicker
              selected={this.state.end_date}
              onChange={(date) => this.setState({ end_date: date })}
              className="form-control"
            />
          </div>
          <div className="col-5">
            <h5 className="fw-bold">Ortodoncista</h5>
            <form className="row mb-4 mt-2">
              <div className="col-12">
                <Select
                  styles={selectStyle}
                  options={this.state.od_list}
                  placeholder="Seleccione ortodoncista..."
                  isSearchable
                  onChange={(e) => this.handleOd(e)}
                />
              </div>
            </form>
            {this.state.od_name !== null && (
              <div>
                <h5 className="fw-bold">Paciente</h5>
                <form className="row mb-4 mt-2">
                  <div className="col-8">
                    <label htmlFor="name">Nombre y apellido</label>
                    <Select
                      styles={selectStyle}
                      options={this.state.od_patients}
                      placeholder="Seleccione paciente..."
                      isSearchable
                      onChange={(e) => this.handlePatient(e)}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="external_id">ID Externo</label>
                    <input
                      type="number"
                      className="form-control"
                      id="external_id"
                      disabled
                      value={this.state.treatment_id}
                    />
                  </div>
                </form>
                <h5 className="fw-bold">Placa de repeticion</h5>
                <form className="row mb-4 mt-2">
                  <div className="col-6">
                    <label htmlFor="reason">Motivo</label>
                    <input
                      type="text"
                      className="form-control"
                      id="reason"
                      onChange={(e) =>
                        this.setState({ [e.target.id]: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="detail">Detalle</label>
                    <input
                      type="text"
                      className="form-control"
                      id="detail"
                      onChange={(e) =>
                        this.setState({ [e.target.id]: e.target.value })
                      }
                    />
                  </div>
                </form>
                <form className="row mb-4 mt-2">
                  <div className="col-4">
                    <label htmlFor="plates">Placas</label>
                    <input
                      type="text"
                      className="form-control"
                      id="plates"
                      onChange={(e) =>
                        this.setState({ [e.target.id]: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="maxilla">Maxilar</label>
                    <Select
                      styles={selectStyle}
                      options={maxilla}
                      placeholder="Seleccione maxilar..."
                      isSearchable
                      onChange={(e) => this.setState({ maxilla: e.value })}
                    />
                    <input
                      type="text"
                      className="form-control"
                      id="maxilla"
                      onChange={(e) =>
                        this.setState({ [e.target.id]: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="plates_quantity">Cant. de placas</label>
                    <input
                      type="number"
                      className="form-control"
                      id="plates_quantity"
                      onChange={(e) =>
                        this.setState({ [e.target.id]: e.target.value })
                      }
                    />
                  </div>
                </form>
                <form className="row mb-4 mt-2">
                  <div className="col-6 d-flex flex-column">
                    <small className="mx-1">¿Las placas tienen costo?</small>
                    <div className="d-flex flex-row mt-2">
                      <p className="m-0">NO</p>
                      <div className="form-check form-switch mx-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="plates_cost"
                        />
                      </div>
                      <p className="m-0">SI</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <small>Monto</small>
                    <input
                      type="number"
                      name="plates_cost_count"
                      id="plates_cost_count"
                      placeholder="USD"
                      className="form-control m-0 "
                    />
                  </div>
                </form>
                <form className="row mb-4 mt-2">
                  <label htmlFor="observation">Observaciones:</label>
                  <textarea
                    name="observation"
                    id="observation"
                    cols="30"
                    rows="5"
                  ></textarea>
                </form>
              </div>
            )}
          </div>
          <div className="col-4 px-5">
            <h6 className="text-primary fw-bold mb-2">Resumen del pedido</h6>
            <div className="row">
              <div className="col-6 mt-1 d-flex flex-column">
                <small>Fecha de salida</small>
                <p className="fw-bold">
                  {moment(this.state.end_date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="col-6 mt-1 d-flex flex-column">
                <small>Paciente</small>
                <p className="fw-bold">{this.state.name}</p>
              </div>
              <div className="col-6 mt-1 d-flex flex-column">
                <small>Motivo</small>
                <p className="fw-bold">{this.state.reason}</p>
              </div>
              <div className="col-6 mt-1 d-flex flex-column">
                <small>Detalle</small>
                <p className="fw-bold">{this.state.detail}</p>
              </div>
              <div className="col-4 mt-1 d-flex flex-column">
                <small>Placas</small>
                <p className="fw-bold">{this.state.plates}</p>
              </div>
              <div className="col-4 mt-1 d-flex flex-column">
                <small>Maxilar</small>
                <p className="fw-bold">{this.state.maxilla}</p>
              </div>
              <div className="col-4 mt-1 d-flex flex-column">
                <small>Cantidad</small>
                <p className="fw-bold">{this.state.plates_quantity}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4 mx-auto d-flex flex-row justify-content-end">
            <button
              className="btn btn-primary px-4"
              onClick={() => this.handleRequest()}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    );
  }
}
