import React from 'react'
import styles from '../styles/stepTwo.module.css'

const StepTwo = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className={styles.mainContainer}>
        <div className={styles.textContainer}>
          <p className={styles.title}>Primer paso:</p>
          <p className={styles.text}>Actualizar sus datos personales.</p>
          <p className={styles.text}>
            Para esto tiene que ingresar al icono de arriba a la derecha e
            ingresar a "Mi Perfil".
          </p>
          <p className={styles.listTitle}>Aqui puede completar:</p>
          <div className="d-flex flex-column">
            <div className={styles.listItem}>
              <div></div>
              <p>Número de teléfono</p>
            </div>
            <div className={styles.listItem}>
              <div></div>
              <p>Datos fiscales</p>
            </div>
            <div className={styles.listItem}>
              <div></div>
              <p>Direcciones *</p>
            </div>
            <div className={styles.listItem}>
              <div></div>
              <p>Redes sociales</p>
            </div>
          </div>
        </div>
        <div className={styles.videoSquare} />
      </div>
      <div className={styles.footer}>
        <p className={styles.quote}>
          *Las direcciones son necesarias para poder visualizarse en el
          "Buscador de doctores".
        </p>
        <div className={styles.nextButton} onClick={() => setStep(3)}>
          Siguiente
        </div>
      </div>
    </div>
  )
}

export default StepTwo
