import React from 'react'
import '../index.css'
import './buttons.css'
import sglogo from '../../../../assets/images/study_group_logo.png'
import saologo from '../../../../assets/images/sao_logo.png'
import { AlertCircle } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const DentistHomeCard = ({ patient }) => {
  const history = useHistory()

  const handleRedirect = (isSee = false) => {
    if (isSee) {
      return history.replace(`historia-clinica/${patient.treatment.id}`)
    }
    history.push(`treatment-stages/${patient.patient.id}`)
  }

  return (
    <div className={`patientCard d-flex`}>
      <Link
        className={`patientHistory type-${patient?.treatment.type.id}`}
        onClick={() => handleRedirect()}
      ></Link>
      <div className="d-flex w-100 my-2 ms-4">
        <div className="avatar" onClick={() => handleRedirect()}>
          <img
            alt="img"
            src={patient?.treatment.frontal_sonriendo}
            className="avatarIMG"
          />
        </div>
        <div className="profile" onClick={() => handleRedirect()}>
          {patient?.patient.groups.length !== 0 &&
            patient?.patient.groups.map((group) => (
              <img
                src={group.id === 1 ? saologo : sglogo}
                alt={group.name}
                className="me-1"
                style={{ width: '40px' }}
              />
            ))}
        </div>
        <div
          className="patientDataList d-flex align-items-center"
          onClick={() => handleRedirect()}
        >
          <p
            className="m-0 text-truncate"
            title={`${patient?.patient.name} ${patient?.patient.surname}`}
          >
            {`${patient?.patient.name} ${patient?.patient.surname}`}
          </p>
        </div>
        <div
          className="treatmentDataList d-flex align-items-center"
          onClick={() => handleRedirect()}
        >
          <p className="m-0 text-truncate" title={patient?.treatment.type.name}>
            {patient?.treatment.type.name}
          </p>
        </div>
        <div
          className="stepDataList d-flex align-items-center"
          onClick={() => handleRedirect()}
        >
          <p
            className="m-0 ms-1 text-truncate"
            title={patient?.treatment.order}
          >
            {patient?.treatment.order}
          </p>
        </div>
        <div className="stepSeeList d-flex align-items-center justify-content-between">
          {patient?.treatment.plan === 1 &&
          patient?.treatment.estudios === 1 &&
          patient?.treatment.fotos === 1 ? (
            <div></div>
          ) : (
            <div
              className={`stepStateList d-flex align-items-center text-danger`}
              onClick={() => handleRedirect()}
            >
              <AlertCircle size={22} />
              <p
                className={`m-0 ms-1 text-truncate `}
                title={'Documentación incompleta'}
              >
                {'Documentación incompleta'}
              </p>
            </div>
          )}
          <Link
            className="seeBTN px-4 py-1"
            onClick={() => handleRedirect(true)}
          >
            <p className="m-0">Ver</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

DentistHomeCard.propTypes = {
  patient: PropTypes.object.isRequired,
}

export default DentistHomeCard
