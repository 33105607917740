import React, {Fragment} from 'react';

function HeadersPlatesRepeat() {
    return (
        <Fragment>
            <div className="col-half d-flex flex-row justify-content-center"><p className="text-primary fw-bold">ID</p></div>
            <div className="col-2 d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Paciente</p></div>
            <div className="col-2 d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Doctor</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Fecha Pedido</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Fecha Salida</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Placas</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Maxilar</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Cant.</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Impresora</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Estado</p></div>
            <div className="col"></div>
        </Fragment>
    )
}

export default HeadersPlatesRepeat