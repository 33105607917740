import React from 'react'

const CardNamesComponent = () => {
  return (
    <div className="row mt-4">
      <div className="col-1" style={{ maxWidth: '75px' }}>
        <p className="text-primary fw-bold m-0 text-center">Ext. ID</p>
      </div>
      <div className="col-2 d-flex justify-content-center">
        <p className="text-primary fw-bold m-0 text-center">Paciente</p>
      </div>
      <div className="col-2 d-flex justify-content-center">
        <p className="text-primary fw-bold m-0 text-center">Doctor</p>
      </div>
      <div className="col-1 d-flex justify-content-center">
        <p className="text-primary fw-bold m-0 text-center">Perfil</p>
      </div>
      <div className="col-1 d-flex justify-content-center">
        <p className="text-primary fw-bold m-0 text-center">Tratamiento</p>
      </div>
      <div className="col-1">
        <p className="text-primary fw-bold m-0 text-center">Etapa</p>
      </div>
      <div className="col-1">
        <p className="text-primary fw-bold m-0 text-center">Maxilar</p>
      </div>
      <div className="col-1">
        <p className="text-primary fw-bold m-0 text-center">Movimiento</p>
      </div>
      <div className="col-1">
        <p className="text-primary fw-bold m-0 text-center">Estado</p>
      </div>
      <div className="col-1">
        <p className="text-primary fw-bold m-0 text-center"></p>
      </div>
    </div>
  )
}

export default CardNamesComponent
