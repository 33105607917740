export const studiesInput = [
  {
    label: 'Rx Panoramica',
    slug: 'rxpanoramica',
  },
  {
    label: 'Telerradiografia',
    slug: 'telerradiografia',
  },
  {
    label: 'Ricketts',
    slug: 'ricketts',
  },
]
