import clinicaImage from '../../assets/images/consult-clinica.png'
import AtencionImage from '../../assets/images/consult-atencion.png'
import marketingImage from '../../assets/images/consult-marketing.png'
import cobranzasImage from '../../assets/images/consult-cobranzas.png'
import otrasImage from '../../assets/images/consult-otras.png'

let imagesObject = {
  Clínica: clinicaImage,
  Atencion: AtencionImage,
  Marketing: marketingImage,
  Cobranzas: cobranzasImage,
  Otras: otrasImage,
}

const displayImage = (title) => {
  if (title === 'Clínica') {
    return clinicaImage
  }
  if (title === 'Atención al Odontólogo y logística') {
    return AtencionImage
  }
  if (title === 'Marketing') {
    return marketingImage
  }
  if (title === 'Cobranzas') {
    return cobranzasImage
  }
  if (title === 'Otras') {
    return otrasImage
  }
}

export default displayImage
