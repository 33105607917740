import React from 'react'
import frontal_sonriendo from '../../../assets/images/frontalsonriendo.png'

const TreatmentCard = ({ treatmentCardData }) => {
  function getTreatmentColor(name) {
    switch (name) {
      case 'Full':
        return 1
      case 'Fast':
        return 2
      case 'Medium':
        return 3
      case 'Kids Full':
        return 4
      case 'Kids Fast':
        return 5
      case 'Teens':
        return 6
      default:
        break
    }
  }

  return (
    <div className="RO-patient-card mb-4 mt-4">
      <div className="d-flex flex-wrap">
        <img
          className={`CH-image-container RO-light-shadow ${
            treatmentCardData ? null : 'disabled'
          }`}
          alt=""
          src={
            treatmentCardData?.frontal_sonriendo
              ? treatmentCardData.frontal_sonriendo
              : frontal_sonriendo
          }
        />
        <div className="ps-3 py-2 d-flex flex-column justify-content-between">
          <div className="d-flex align-items-center ">
            {treatmentCardData && (
              <>
                <p
                  className="h4 my-0 fw-bold text-primary text-truncate"
                  style={{ maxWidth: 400 }}
                >
                  {treatmentCardData?.patient.name +
                    ' ' +
                    treatmentCardData?.patient.surname}
                </p>
                <p
                  className={`type-tag type-${getTreatmentColor(
                    treatmentCardData?.treatment
                  )} my-0 px-3 py-2 ms-3 me-3`}
                >
                  {treatmentCardData?.treatment?.toUpperCase()}
                </p>
              </>
            )}
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="me-5">
              <small className="CH-small">Doctor:</small>
              <p
                className="my-0 fw-bold text-truncate"
                style={{ maxWidth: 200 }}
              >
                {treatmentCardData &&
                  `${treatmentCardData?.dentists[0]?.user?.name} ${treatmentCardData?.dentists[0]?.user?.surname}`}
              </p>
            </div>

            <div className="me-5">
              <small className="CH-small">Maxilar:</small>
              <p className="my-0 fw-bold">{treatmentCardData?.maxilla}</p>
            </div>

            {treatmentCardData && (
              <p className="text-primary fw-bold CH-stage-tag my-0 px-3 py-1">
                ETAPA {treatmentCardData?.stage}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TreatmentCard
