import React, { useCallback, useEffect, useRef, useState } from 'react'
import FormOnline from '../../../../../FormularioOnline'
import frontalsonriendo from '../../../../../../assets/images/frontalsonriendo.png'
import apiClient from '../../../../../../utils/backend'
import { baseToFile } from '../../../../../../utils/baseToFile'
import 'react-toastify/dist/ReactToastify.css'
import handleToast from '../../../../../../components/toaster'
import FormOnlineOnePage from '../../../../../../components/FormOnlineOnePage/FormOnline'
import PDFViewer from '../../../../../../components/Modals/PDFViewer'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { getLicense } from '../../../../../../utils/licenseKeyLightGallery'
import DocImage from '../../../../../Solicitud/components/DocImage'
import DocImageInput from '../../../../../Solicitud/components/DocImageInput'
import styles from './DocumentationPlan.module.css'
import { useSelector } from 'react-redux'
import moment from 'moment'

const DocumentationPlan = ({
  planFiles,
  setPlanFiles,
  recallDocumentation,
  state,
  documentationState,
  setInformationState,
  stateNew,
  setStateNew,
  backendFiles,
}) => {
  const lightGallery = useRef(null)
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const [storedPlanFiles, setStoredPlanFiles] = useState([])
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance
    }
  }, [])

  const handleImageSelect = (image) => {
    let type = image?.type ? 'pdf' : 'image'
    const newFile = { file: image, type }
    setPlanFiles([...planFiles, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newPlanFiles = planFiles
    newPlanFiles.splice(indexToDelete, 1)
    setPlanFiles([...newPlanFiles])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newPlanFiles = planFiles
    newPlanFiles[indexToEdit].file = image
    setPlanFiles([...newPlanFiles])
  }

  const checkFrotalSonriendo = () => {
    if (state?.frontal_sonriendo) {
      return state.frontal_sonriendo
    }
    if (state?.selected_patient?.patientImage) {
      return state?.selected_patient?.patientImage
    }
    return frontalsonriendo
  }

  const handleColor = (type) => {
    const types = {
      Full: '#001b70',
      Fast: '#288b7e',
      Medium: '#5a468e',
      Kids: '#e47662',
      'Kids Fast': '#e8793a',
      Teens: '#f7c05b',
      'Sin tipo': '#001b70',
    }
    console.log(types[type])
    return types[type]
  }

  const sendStepFourOnline = () => {
    // Set images
    const files =
      stateNew.online_form_state.InstructionsTratment.GeneralInstructions
        .attachmentFile
    // Normalize Online form data
    const form_online = { form_online: stateNew.online_form_state }
    delete form_online.form_online.InstructionsTratment.GeneralInstructions
      .attachmentFile
    // Send online form Info
    apiClient
      .post(`api/incomes/${stateNew.treatment_id}/plan`, form_online)
      .then((res) => {
        sentStepFourOnlineImages(files)
        setTimeout(() => {
          handleToast('success', 'Formulario online cargado correctamente')
          recallDocumentation()
          recallInformation()
        }, 3000)
      })
      .catch((err) =>
        handleToast('error', 'Error al cargar el formulario online')
      )
  }

  const sentStepFourOnlineImages = (files) => {
    if (files.length > 0) {
      files.map((Attachedfile, key) => {
        //Normalize Online form images
        const fileimg = baseToFile(Attachedfile.file, key)
        let formOnlineFile = new FormData()
        formOnlineFile.append('file', fileimg)

        // Send online form Images
        apiClient
          .post(
            `api/incomes/${stateNew.treatment_id}/plan/formOnline`,
            formOnlineFile
          )
          .then((res) =>
            handleToast(
              'success',
              'Archivos de formulario online cargados correctamente'
            )
          )
          .catch((err) =>
            handleToast(
              'error',
              'Error al cargar archivos del formulario online'
            )
          )
      })
    }
  }

  const recallInformation = async () => {
    try {
      const res = await apiClient(
        `/api/medicalRecordDentist/${stateNew.treatment_id}`
      )
      setInformationState(res.data.data)
    } catch (err) {
      return console.log(err, 'Error getting documentation data')
    }
  }

  useEffect(() => {
    setStoredPlanFiles([...backendFiles.plan])
  }, [backendFiles])

  useEffect(() => {
    if (stateNew.online_form_state) {
      sendStepFourOnline()
    }
  }, [stateNew.step])

  return (
    <div className="my-4 d-flex flex-wrap">
      {stateNew.online_plan && pageWidth === 'mobile' && (
        <div className="text-center p-2 form-error-card">
          <p className="fw-bold mt-3">Formulario Online</p>
          <p className="fw-bold mt-3 text-orange">
            Para acceder a cargar el formulario online le recomendamos que
            ingrese desde desktop
          </p>
        </div>
      )}

      {documentationState.plan.online_form !== 0 && pageWidth === 'desktop' && (
        <FormOnlineOnePage
          state={state}
          formData={documentationState?.plan.online_form}
          documentationData={documentationState}
        />
      )}

      {documentationState.plan.online_form !== 0 && pageWidth === 'mobile' && (
        <div
          className="text-center p-4 form-error-card "
          style={{ maxWidth: 400 }}
        >
          <div className="d-flex justify-content-between flex-wrap gap-3 text-start">
            <div>
              <small>Paciente:</small>
              <p className="fw-bold">{`${state.patient.name} ${state.patient.surname}`}</p>
            </div>
            <div>
              <small>Tratamiento:</small>
              <p style={{ color: handleColor(state?.type?.name) }}>
                KeepSmiling <span className="fw-bold">{state.type.name}</span>
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap gap-3 text-start">
            <div>
              <small>Maxilar:</small>
              <p className="fs-5">{state.maxilla}</p>
            </div>
            <div>
              <small>Fecha de nacimiento:</small>
              <p>
                <p className="fs-5">
                  {state.patient.birthdate === null
                    ? 'No tiene fecha de nacimiento'
                    : moment.utc(state.patient?.birthdate).format('DD-MM-YYYY')}
                </p>
              </p>
            </div>
          </div>

          <hr />
          <p className="fw-bold mt-3 text-orange">
            Para acceder a cargar el formulario online le recomendamos que
            ingrese desde desktop
          </p>
        </div>
      )}

      {storedPlanFiles.length > 0 &&
        storedPlanFiles.map((file, index) => (
          <div
            className={`cursor-pointer mx-auto plan-container m-3 ${styles.imageContainer}`}
            key={index}
          >
            {file.file.includes('.pdf') ? (
              <div style={{ width: 150 }}>
                <PDFViewer
                  style={{ width: 150 }}
                  imageSource={file.path}
                  isOd={true}
                />
              </div>
            ) : (
              <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                className={`gallery ${styles.imagePreview}`}
                onInit={onInit}
                licenseKey={getLicense()}
              >
                <div data-src={file.path} className={styles.imagePreview}>
                  <img
                    alt={file.name}
                    className={`w-100 img-fluid shadow ${styles.thumb}`}
                    src={file.path}
                  />
                </div>
              </LightGallery>
            )}
          </div>
        ))}

      {!documentationState.plan.online_form && !storedPlanFiles.length && (
        <div className="mt-3 mx-auto w-100">
          <div className="row text-center mb-4">
            <div className="col-md-6">
              <button
                className={`btn btn-outline-primary border-2 p-4 my-2 ${
                  stateNew.online_plan && 'disabled bg-primary text-white'
                }`}
                style={{ borderRadius: '15px' }}
                onClick={() => {
                  setPlanFiles([])
                  setStateNew({
                    ...stateNew,
                    online_plan: !stateNew.online_plan,
                    image_plan: false,
                    plantratamiento: null,
                  })
                }}
              >
                Cargar Formulario Online
              </button>
            </div>

            <div className="col-md-6">
              <button
                className={`btn btn-outline-primary border-2 p-4 my-2 ${
                  stateNew.image_plan && 'disabled bg-primary text-white'
                }`}
                style={{ borderRadius: '15px' }}
                onClick={() =>
                  setStateNew({
                    ...stateNew,
                    image_plan: !stateNew.image_plan,
                    online_plan: false,
                  })
                }
              >
                Cargar Formulario en foto
              </button>
            </div>
          </div>

          {stateNew.online_plan && pageWidth === 'desktop' && (
            <FormOnline
              applicationState={stateNew}
              setStateNew={setStateNew}
              checkFrotalSonriendo={checkFrotalSonriendo}
              type={stateNew.treatment_type}
              nextParentStep={(e) =>
                setStateNew({
                  ...stateNew,
                  step: 5,
                  online_form_state: e,
                })
              }
            />
          )}

          {stateNew.image_plan && (
            <div className="container my-5 mx-auto">
              <div className=" mx-4 d-flex flex-wrap gap-2">
                {planFiles.map((item, index) => (
                  <DocImage
                    key={index}
                    index={index}
                    type={item.type}
                    image={item.file}
                    handleDeletePlan={handleDeletePlan}
                    handleEditPlan={handleEditPlan}
                  />
                ))}
                {planFiles.length < 5 && (
                  <DocImageInput
                    inputName="Plan de Tratamiento"
                    handleImageSelect={handleImageSelect}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DocumentationPlan
