import moment from 'moment'
import React, { useState, useEffect } from 'react'
import apiClient from '../../../../utils/backend'

const NotificationCard = ({
  notificationId,
  treatId,
  read,
  image,
  doc,
  motivo,
  detalle,
  fecha,
  handleModal,
}) => {
  const [readState, setReadState] = useState(false)

  const displayMotivo = (motivoId) => {
    if (motivoId === 8) return 'Solicitud de ingreso'
    if (motivoId === 9) return 'Nueva documentación'
    if (motivoId === 10) return 'Aprobación de propuesta de Tratamiento'
    if (motivoId === 11) return 'Rechazo de propuesta de Tratamiento'
  }

  const buttonCardHandler = (treatId) => {
    handleModal(treatId)
    if (read) return
    setReadState(true)
    apiClient.put(`/api/notificationAsRead/${notificationId}`)
  }

  useEffect(() => {
    if (read) setReadState(true)
  }, [read])

  return (
    <div className="py-2 NT-bottom-bar-two d-flex align-items-center justify-content-between">
      <div className=" d-flex align-items-center">
        <div className="NT-doc-card ">
          <div
            className={readState ? 'NT-circle-green' : 'NT-circle-yellow'}
          ></div>
          {image ? (
            <img alt="avatar" className="NT-avatar" src={image} />
          ) : (
            <div className="NT-avatar"></div>
          )}
          <div
            className={`text-truncate NT-doc-name ${readState && 'disabled'}`}
          >
            {doc}
          </div>
        </div>
        <div className={`NT-mot-card ${readState && 'disabled'}`}>
          {displayMotivo(motivo)}
        </div>
        <div className={`NT-det-card ${readState && 'disabled'}`}>
          {detalle}
        </div>
        <div className={`NT-fecha-card ${readState && 'disabled'}`}>
          {moment.utc(fecha).format('DD-MM-YYYY')}
        </div>
      </div>
      {motivo < 10 ? (
        <button
          className="NT-btn-card"
          onClick={() => buttonCardHandler(treatId)}
        >
          Ver solicitud
        </button>
      ) : (
        <button
          className="NT-btn-card"
          onClick={() => buttonCardHandler(treatId)}
        >
          Ver Historia Clinica
        </button>
      )}
    </div>
  )
}

export default NotificationCard
