import React from 'react'
import { Upload } from 'react-feather'
import Compressor from 'compressorjs'
import 'pintura/pintura.css'
import { ImageUploadContainer, ImageUploadLabel } from './styled'
import { getQuality } from '../../../utils/quality'
import handleToast from '../../../components/toaster'

const DocImageInput = React.forwardRef(
  ({ handleImageSelect, inputName, allowPDF = false }) => {
    async function handleImage(e) {
      e.preventDefault()

      if (e.target.files[0]?.type === 'application/pdf') {
        if (allowPDF) return handleToast('error','No se permite subir archivos formato .pdf')
        handleImageSelect(e.target.files[0])
      } else {
        let file = e.target.files[0]
        new Compressor(file, {
          quality: getQuality(),
          success(result) {
            let reader = new FileReader()
            let file_compressed = result

            reader.onloadend = () => {
              handleImageSelect(reader.result)
            }
            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      }
    }

    return (
      <div className="d-flex flex-column mx-auto" style={{ width: 100 }}>
        <ImageUploadContainer className="my-0">
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .pdf"
            onChange={handleImage}
          />
          <Upload />
        </ImageUploadContainer>
        <ImageUploadLabel>{inputName}</ImageUploadLabel>
      </div>
    )
  }
)

export default DocImageInput
