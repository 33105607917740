import React from 'react'
import '../styles/styles.css'
import PropTypes from 'prop-types'
import smile from '../../../../assets/images/ks-smile.svg'
import { ChevronRight } from 'react-feather'
import BenefitCard from './components/BenefitCard'
import { Link } from 'react-router-dom'
import { getProfileData } from '../utilities/helpers'
import Loader from '../../../../components/loader'

export const DesktopSmileCreator = ({ loading, countryId, dentistProfile }) => {
  if (loading) return <Loader />

  return (
    <div>
      <div className="header w-100 d-flex flex-column">
        <img src={smile} alt="smile" />
        <span className="fs-2 fw-bold">Smile Creator</span>
        <p className="fs-5 mt-2">
          Recibí beneficios a partir de la cantidad de tratamientos que realices
        </p>
      </div>

      <div className="pointsDetail">
        <div className="fs-5 d-flex align-items-center">
          <img
            src={getProfileData(dentistProfile.category, true)}
            alt="coin"
            className="coin me-4"
          />
          <p className="my-0">
            {`Actualmente ${
              countryId === 1
                ? 'estas en categoria '
                : 'perteneces a la categoria '
            }`}
          </p>
          <span className="ms-1 fw-bold">
            {getProfileData(dentistProfile.category, false)}
          </span>
        </div>
        <Link className="seePoints" to="/od/smileCreator/detalle">
          Ver detalle de puntos <ChevronRight />
        </Link>
      </div>
      <div className="questions mt-5">
        <p className="fs-3  fw-bold text-primary">¿Cómo funciona?</p>
        <ul className="fs-5 list-unstyled lh-lg">
          <li>
            1) El Sistema Smile Creator de KeepSmiling consiste en recibir
            beneficios a partir de la cantidad de tratamientos que realices.
          </li>
          <li>
            2) Solo se cuentan los tratamientos ingresados con una fecha menor
            al último año, y se agrupan por trimestre (Enero, Febrero y Marzo -
            Abril, Mayo y Junio - Julio, Agosto y Septiembre - Octubre,
            Noviembre y Diciembre).
          </li>
          <li>
            3) Se cuentan como válidos los tratamientos de primera etapa Fast,
            Medium, Full y Kids, indistintamente si son una o ambas arcadas.
          </li>
          <li>
            4) Los tratamientos de influencers que son bonificados al 100% no
            suman al conteo de casos del programa.
          </li>
        </ul>
      </div>
      <div className="benefits">
        <p className="fs-3 fw-bold text-primary">Beneficios</p>
        <BenefitCard countryId={countryId} />
      </div>
    </div>
  )
}

DesktopSmileCreator.propTypes = {
  loading: PropTypes.bool.isRequired,
  countryId: PropTypes.number.isRequired,
  dentistProfile: PropTypes.object.isRequired,
}
