import React, {Fragment, useEffect, useState} from "react";
import apiClient from "../../../utils/backend";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Noticia(props) {

    const [subCategories, setSubCategories] = useState([])
    const [title, setTitle] = useState(null)
    const [body, setBody] = useState(null)
    const [showNewNotice, setShowNewNotice] = useState(false)


    useEffect(() => {
        console.log("aca")
        console.log(props)
        apiClient.get(`/api/helpCenter/${props.subCategorie.id}`).then(res => {
            setSubCategories(res.data)
        })
    }, [])

    const guardarNoticia = () => {
        let data = {
            parent_id: props.subCategorie.id,
            name: title,
            order: 1,
            route: `${props.categorie.name}/${props.subCategorie.name}/${title}`,
        }

        apiClient.post('/api/helpCenter', data).then(res => {
            apiClient.post('/api/helpCenter', {
                parent_id: res.data.id,
                body: body,
                order: 1
            })

            setSubCategories(oldSubCategories => [...oldSubCategories, res.data]);
        })
    }

    return (
        <Fragment>
            <p className="mt-3" style={{fontSize: 15}}>Categoria</p>

            <div className="row">
                <div className="col-2" style={{marginRight: 100}}>
                    <div style={{width: 250, height: 220, borderRadius: 20}}
                         className="col-2 d-flex flex-column shadow mx-2 p-4 justify-content-center align-items-center">
                        <img style={{width: 100, height: 100}} src={props.categorie.icon} alt=""/>
                        <p className="mt-3" style={{fontSize: 22, fontWeight: 'bold'}}>{props.categorie.name}</p>
                    </div>
                </div>

                <div className="col-2 d-flex flex-column">
                    <div style={{width: 200, height: 70}}
                         className="shadow rounded-pill d-flex justify-content-start align-items-center">
                        <p className="mx-3 mt-2">{props.subCategorie.name}</p>
                    </div>
                </div>

                <div className="col-4 d-flex flex-column mx-5 justify-content-between">
                    {
                        subCategories.map(subcategorie => {
                            return (
                                <div style={{width: 200, height: 70}}
                                     className="shadow rounded-pill d-flex justify-content-start align-items-center">
                                    <p className="mx-3 mt-2">{subcategorie.name}</p>
                                </div>
                            )
                        })
                    }

                    <div onClick={() => setShowNewNotice(true)} style={{width: 200, height: 70, cursor: 'pointer'}}
                         className="shadow rounded-pill d-flex justify-content-start align-items-center">
                        <p className="mx-3 mt-2">Nueva Noticia</p>
                    </div>
                </div>
            </div>

            {
                showNewNotice &&
                <Fragment>
                    <div className="row mt-5">
                        <p style={{fontSize: 20, fontWeight: 'bold'}}>Nueva Noticia</p>

                        <div className="row">
                            <div className="col-5">
                                <input onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Titulo de noticia"/>
                            </div>
                        </div>

                        <div className="row">
                            <CKEditor
                                editor={ClassicEditor}
                                data="<p>Hello from CKEditor 5!</p>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBody(data)
                                    console.log({event, editor, data});
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </div>

                        <div className="row mt-3">
                            <div className="col-2">
                                <button onClick={() => guardarNoticia()}
                                        className="btn btn-primary px-5 rounded-pill">Guardar
                                </button>
                            </div>
                        </div>
                    </div>

                </Fragment>
            }
        </Fragment>
    )
}

export default Noticia