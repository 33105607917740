import { useState, useEffect, Fragment, useRef } from "react";
import apiClient from "../../../../src/utils/backend";
import { connect } from "react-redux";
import { Book, HelpCircle, Bookmark, PenTool, Monitor, User, Video } from 'react-feather'
import Header from '../components/header_image'
import MainMenu from './main_menu'
import ChildMenu from './child_menu'
import DescriptionMenu from './description_menu'
import FilesMenu from './files_menu'

function CentroDeAyuda(props){
    const { idParent, parent, route } = props.location.state || {}
    const [menu, setMenu] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        console.log('useEffect idPArent',idParent);
        const get_menu = async () =>{
            setLoading(true)
            let path;
            console.log("id parent")
            console.log(idParent)
            !idParent ? path = '/api/helpCenter' : path = '/api/helpCenter/' + idParent
            console.log("path")
            console.log(path)
            apiClient.get(path)
            .then(res => {
                console.log('response api centro de ayuda',res)
                if(res.status===200){
                    console.log('res type',typeof res.data)
                    if(!Array.isArray(res.data)){
                        setMenu([res.data])
                    }else{
                        setMenu(res.data)
                    }
                    
                    
                }
                setLoading(false)
                
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
        }
        get_menu()
    },[idParent])
    // const getIcon = (icon ) =>{
    //     if(icon.indexOf('.')>-1){
    //         const item_icon = require(`../../../assets/images/Centro de Ayuda/${icon}`).default
    //         return <img style={{width:'60px',height:'70px'}} 
    //         src={item_icon} alt={icon} />   
    //     }else{
    //         const icons = {
    //             book : Book,
    //             help: HelpCircle,
    //             bookmark: Bookmark,
    //             pentool: PenTool,
    //             video: Video,
    //             monitor: Monitor,
    //             user: User
    //         }
    //         let IconComponent = icons[icon]
    //         return <IconComponent style={{width:'70px',height:'70px'}} />
    //     }
    // }
    
    const goSubMenu=(data)=>{
        props.history.push({
            pathname: `/centrodeayuda/${data.route}`,
            //search: '?query=abc',
            state: { route:data.route, idParent: data.id, parent: { name: data.name, body: data.body } }
            })
    }
    return (
        <Fragment>
            <Header />
            { !idParent && !loading &&
                <MainMenu main_menu={menu} goSubMenu={goSubMenu} />
            }
            {menu.length>0 &&
            idParent && !loading && menu[0].route && 
                <ChildMenu child_menu={menu} goSubMenu={goSubMenu} parent={parent} />
            }
            {menu.length>0 &&
            idParent && menu.length===1 && !menu[0].route && menu[0].files.length === 0 && !loading &&
                <DescriptionMenu description_data={menu[0]} parent={parent} />
            }
            {
            menu.length===1 && !menu[0].route && menu[0].files.length > 0 && !loading &&
            <FilesMenu files_data={menu[0]} parent={parent} />
            }
            
            {/* {
                main_menu.map((item, key)=>
                <div className="w-100 d-flex justify-content-center" key={key}>
                    <div onClick={()=>goSubMenu(item)} className="grow_hover w-90 d-flex flex-row bg-white shadow rounded m-0 px-10 py-3 my-2 align-items-center tc-container">
                        <div className='d-flex align-items-center justify-content-around w-60'>
                            <div className='d-flex align-items-center justify-content-center w-25'>
                                {getIcon(item.icon)}
                            </div>
                            <div className='d-flex flex-row align-items-center w-75'>
                                <div className="d-flex flex-column bg-transparent">
                                    <p className="h3">{item.title}</p>
                                    <p className="small">{item.body}</p>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center w-40'>
                            <button key={`btn_hig_${item.action.id}`} 
                            className={`btn rounded-pill`} 
                            style={{color:item.action.fontColor||'black',backgroundColor: item.action.color, width:'200px'}} 
                            onClick={null}>
                                {item.action.name}
                            </button>
                        </div>
                    </div>
                </div>
                )
            } */}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(CentroDeAyuda);