import React from 'react'
import '../../../style.css'

const Diastemas = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Diastemas</p>
      <hr className="mt-2 mb-0" />
      <div className="d-flex flex-column">
        <div className="improve-overjet-container">
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="diastemas"
              disabled
              checked={formData?.diastem == 1 && true}
            />
            <label className="ms-2">No aplica</label>
          </div>
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="diastemas"
              disabled
              checked={formData?.diastem == 2 && true}
            />
            <label className="ms-2">Cerrar espacios</label>
          </div>
          {formData?.diastem == 2 && (
            <div className="d-flex justify-content-between mt-3">
              <div className="w-100">
                <p className="mb-0">Cerrar por mesialización</p>
                <hr className="mt-1 me-4" />
                <div>
                  <input
                    type="radio"
                    className="mt-2 inputColor"
                    name="mesializacion"
                    disabled
                    checked={formData?.mesi_diastem == 1 && true}
                  />
                  <label className="ms-2">Si</label>
                </div>
                <div>
                  <input
                    type="radio"
                    className="mt-2 inputColor"
                    name="mesializacion"
                    disabled
                    checked={formData?.mesi_diastem == 2 && true}
                  />
                  <label className="ms-2">No</label>
                </div>
              </div>
              <div className="w-100">
                <p className="mb-0">Cerrar por retroinclinación</p>
                <hr className="mt-1" />
                <div>
                  <input
                    type="radio"
                    className="mt-2 inputColor"
                    name="retroinclinacion"
                    disabled
                    checked={formData?.retro_diastem == 1 && true}
                  />
                  <label className="ms-2">Si</label>
                </div>
                <div>
                  <input
                    type="radio"
                    className="mt-2 inputColor"
                    name="retroinclinacion"
                    disabled
                    checked={formData?.retro_diastem == 2 && true}
                  />
                  <label className="ms-2">No</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <p className="mt-3">¿Desea dejar espacio remanente? Indique donde:</p>
      <div className="step-text-area w-100 mt-2">
        {formData?.disatem_description}
      </div>
    </div>
  )
}

export default Diastemas
