import React, { useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Document, Page, pdfjs } from 'react-pdf'
import { Modal, ModalBody } from 'reactstrap'
import styles from './styles/styles.module.css'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewer = ({ imageSource, isOd = false }) => {
  const [opened, setOpened] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [countOfPages, setCountOfPages] = useState(0)
  const file = isOd ? imageSource : imageSource()
  const increaseBy = (value) => {
    let newValue = Math.max(pageNumber + value, 1)
    if (countOfPages) {
      newValue = Math.min(newValue, countOfPages)
    }
    setPageNumber(newValue)
  }

  return (
    <>
      <Document
        file={file}
        onClick={() => setOpened(!opened)}
        className={styles.littlePDF}
      >
        <Page width={150} height={isOd ? 600 : 150} scale={1} pageNumber={1} />
      </Document>
      <Modal
        style={{ marginTop: '150px' }}
        isOpen={opened}
        size="xl"
        toggle={() => {
          setOpened(!opened)
          setPageNumber(1)
        }}
      >
        <ModalBody>
          <div style={{ overflow: 'scroll' }}>
            <Document
              file={file}
              onLoadSuccess={(pdf) => setCountOfPages(pdf.numPages)}
            >
              <Page height={1500} scale={1} pageNumber={pageNumber} />
            </Document>
          </div>
          <div className={styles.pdfPaginator}>
            {pageNumber !== 1 && (
              <button onClick={() => increaseBy(-1)}>
                <ChevronLeft />
              </button>
            )}
            <p>Página {pageNumber}</p>
            {pageNumber !== countOfPages && (
              <button onClick={() => increaseBy(1)}>
                <ChevronRight />
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PDFViewer
