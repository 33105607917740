import React, { useState } from 'react'
import claimAll from '../styles/claimAll.module.css'
import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import mail from '../../../assets/icons/feather-mail.svg'
import bell from '../../../assets/icons/feather-bell.svg'
import whatsapp from '../../../assets/icons/feather-whatsapp.svg'
import ClaimsAllCard from '../components/ClaimsAllCard'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import handleToast from '../../../components/toaster'
import apiClient from '../../../utils/backend'

const ClaimAllModal = ({ claimState, setClaimState, updateAfterClaimAll }) => {
  const [opened, setOpened] = useState(false)
  const [total, setTotal] = useState(0)
  const [notificationsIcon, setNotificationsIcon] = useState({
    bell: true,
    mail: false,
    wsp: false,
  })

  const handleSendClaims = async () => {
    if (
      !notificationsIcon.bell &&
      !notificationsIcon.mail &&
      !notificationsIcon.wsp
    ) {
      return handleToast(
        'error',
        'Debe seleccionar al menos un medio de reclamo'
      )
    }
    handleToast('updating', 'Reclamando...')
    const claimListWithoutNoSelected = claimState.claims.filter(
      (claim) => claim.selected === true
    )
    let newClaimsList = claimListWithoutNoSelected.map((claim) => {
      let principalDentist = claim.dentists.find(
        (dentist) => dentist.id === claim.principal_dentist
      )
      let requiredAction
      const { studies, photos, plan } = claim.flags
      console.log(claim)
      if (claim.proposals_pending.length > 0) {
        requiredAction = `Propuesta de tratamiento pendiente`
      } else if (!studies || !photos || !plan) {
        requiredAction = `Documentación incompleta`
      }
      return {
        required_action_claimed: requiredAction,
        treatment_id: claim.id,
        whatsapp: notificationsIcon.wsp,
        email: notificationsIcon.mail,
        notification: notificationsIcon.bell,
        principal_dentist: principalDentist.id,
      }
    })

    try {
      await apiClient.post(`api/claims`, { claims: newClaimsList })
      handleToast('success', 'Reclamos hechos correctamente')
      setTimeout(() => updateAfterClaimAll(), 1000)
      setOpened(false)
    } catch (error) {
      console.log(error)
      handleToast('error', 'Hubo un error al hacer los reclamos')
    }
  }

  useEffect(() => {
    let claims = claimState.claims.filter((claim) => claim.selected === true)
    setTotal(claims.length)
    // setClaimList(claims)
  }, [claimState])

  return (
    <>
      <div
        className={claimAll.claimSelectedBTN}
        onClick={() => setOpened(!opened)}
      >
        Reclamar seleccionado <span className="text-muted">({total})</span>
      </div>
      <Modal
        style={{ marginTop: '150px' }}
        isOpen={opened}
        size="lg"
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-between">
              <p className={`${claimAll.titleHeader} fs-4`}>
                Reclamar seleccionado
              </p>
              <X
                className={claimAll.closeBTN}
                onClick={() => setOpened(false)}
                size={30}
              />
            </div>
            <div className="d-flex mt-3">
              <div
                className={`${claimAll.numberOfCases} d-flex flex-column w-50`}
              >
                <p>Cantidad de casos para reclamar:</p> <span>{total}</span>
              </div>
              <div className="d-flex flex-column w-50">
                <p className={claimAll.meansOfClaim}>Medio de Reclamo:</p>
                <div className="d-flex justify-content-between w-75">
                  <img
                    src={bell}
                    alt="iconBell"
                    className={`${
                      notificationsIcon.bell ? '' : claimAll.icon
                    } ${claimAll.meansIcon}`}
                    onClick={() =>
                      setNotificationsIcon({
                        ...notificationsIcon,
                        bell: !notificationsIcon.bell,
                      })
                    }
                  />
                  <img
                    src={mail}
                    alt="iconMail"
                    className={`${
                      notificationsIcon.mail ? '' : claimAll.icon
                    } ${claimAll.meansIcon}`}
                    onClick={() =>
                      setNotificationsIcon({
                        ...notificationsIcon,
                        mail: !notificationsIcon.mail,
                      })
                    }
                  />
                  <img
                    src={whatsapp}
                    alt="iconWSP"
                    className={`${notificationsIcon.wsp ? '' : claimAll.icon} ${
                      claimAll.meansIcon
                    }`}
                    onClick={() =>
                      setNotificationsIcon({
                        ...notificationsIcon,
                        wsp: !notificationsIcon.wsp,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className={claimAll.headerList}>
              <div className={claimAll.id}>ID</div>
              <div className={claimAll.patient}>Paciente</div>
              <div className={claimAll.doctor}>Doctor</div>
              <div className={claimAll.requiredAction}>Acción Requerida</div>
            </div>
            <div className={claimAll.cardsContainer}>
              {claimState.claims.map((claim) => (
                <ClaimsAllCard
                  key={claim.id}
                  data={claim}
                  claimState={claimState}
                  setClaimState={setClaimState}
                />
              ))}
            </div>
            <div className="d-flex justify-content-end mt-3">
              <div
                className={claimAll.claimButton}
                onClick={() => handleSendClaims()}
              >
                Reclamar
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ClaimAllModal

ClaimAllModal.propTypes = {
  claimState: PropTypes.object.isRequired,
  setClaimState: PropTypes.func.isRequired,
  updateAfterClaimAll: PropTypes.func.isRequired,
}
