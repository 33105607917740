import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useHistory } from 'react-router'
import './index.css'
import { TreatmentLogo } from '../components/TreatmentLogo'
import { ImageUploadReference } from '../components/styled'
import trescuartos_perfil from '../../../../assets/images/34deperfilsonriendo.png'
import anterioroclusion from '../../../../assets/images/anteriorenoclusion.png'
import anteriorinoclusion from '../../../../assets/images/anterioreninoclusion.png'
import bucalderecho from '../../../../assets/images/bucalderecho.png'
import bucalizquierdo from '../../../../assets/images/bucalizquierdo.png'
import frontalreposo from '../../../../assets/images/frontalenreposo.png'
import frontalsonriendo from '../../../../assets/images/frontalsonriendo.png'
import oclusalmaxilarinf from '../../../../assets/images/oclusaldelmaxilarinferior.png'
import oclusalmaxilarsup from '../../../../assets/images/oclusaldelmaxilarsuperior.png'
import perfilizqreposo from '../../../../assets/images/perfilizquierdoenreposo.png'
import radioperfil from '../../../../assets/images/telerradiografiadeperfil.png'
import ricketts from '../../../../assets/images/cefalogramadericketts.png'
import rxpanoramica from '../../../../assets/images/rxpanoramica.png'
import apiClient from '../../../../utils/backend'
import toast from 'react-hot-toast'
import handleToast from '../../../../components/toaster'
import { Spinner } from 'reactstrap'
import { baseToFile } from '../../../../utils/baseToFile'

export const TreatmentAdminStepSix = ({
  planFiles,
  state,
  setState,
  stageTwoFinish,
  stageThreeFinish,
  stageFourFinish,
  checkFrotalSonriendo,
  photosFiles,
  studiesFiles,
}) => {
  const [treatmentId, setTreatmentId] = useState(null)
  const [load, setLoad] = useState(false)
  const [step2, setstep2] = useState(false)
  const [step3, setstep3] = useState(false)
  const [step4, setstep4] = useState(false)
  const [flags, setFlags] = useState({
    photos: stageTwoFinish,
    studies: stageThreeFinish,
    plan: stageFourFinish,
  })

  const history = useHistory()

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  const imageStyle = {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    margin: '2px',
  }
  const imageStyleOpacity = {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    margin: '2px',
    opacity: '.5',
  }

  const toastProps = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({
      ...state,
      numPages: numPages,
    })
  }

  const handleRedirectIncome = () => {
    handleToast('error', 'Los datos no se guardaron correctamente')
    history.push('/ingresos')
  }

  const handleSaveTreatment = async () => {
    setLoad(true)
    const dataStepOne = {
      dentist_id: state.odontologo.id,
      external_id: state.external_id,
      maxilla: state.maxilar,
      type_id: state.treatment_type,
      intraoral: state.intraoral,
      registration_date: state.newIntraoralScanDate
        ? state.newIntraoralScanDate
        : state.sendImpressions?.takeover_date,
    }

    const dataNewPatient = {
      ...dataStepOne,
      name: state.nombre,
      surname: state.apellido,
      gender: state.genero,
    }

    const dataOldPatient = {
      ...dataStepOne,
      patient_id: state?.selected_patient?.id,
      new_stage: true,
      order: state.etapa,
    }

    await apiClient
      .post(
        `api/incomes/information`,
        state.is_new_patient ? dataNewPatient : dataOldPatient
      )
      .then((res) => {
        setTreatmentId(res.data.data.id)
        handleToast('updating', 'Guardando tratamiento..')
      })
      .catch((err) => handleToast('error', 'Error en la informacion ingresada'))
  }

  useEffect(() => {
    if (!treatmentId) return console.log('Treatment id undefined')
    //SETFLAGS
    apiClient
      .patch(`api/incomes/${treatmentId}`, flags)
      .catch(() => toast.error('Error Sending The Flags', toastProps))

    //STEP 2
    if (Object.keys(state.fotos).length < 1) setstep2(true)
    Object.entries(state.fotos).forEach((entry, index) => {
      const [key, value] = entry
      if (value !== null) {
        const file = baseToFile(value, key + '.jpg')
        let dataStepTwo = new FormData()
        dataStepTwo.append('image', file)
        dataStepTwo.append('name', key)

        apiClient
          .post(`api/incomes/${treatmentId}/images`, dataStepTwo, headers)
          .then((res) => {
            if (index + 1 === Object.keys(state.fotos).length) {
              setstep2(true)
            }
          })
          .catch((err) => toast.error('Error on step 2 images', toastProps))
      }
    })
    // OTROS PHOTOS
    if (photosFiles.length > 0) {
      Promise.all(
        photosFiles.map(async (item, index) => {
          const file = baseToFile(item.file, `.jpg`)
          let otherPhotos = new FormData()
          otherPhotos.append('image', file)
          otherPhotos.append('name', `Otros-${index + 1}`)
          otherPhotos.append('type', `image`)
          try {
            await apiClient.post(
              `api/incomes/${treatmentId}/others`,
              otherPhotos
            )
          } catch (error) {
            handleToast('error', 'Error cargando otrosPhotos..')
            console.log(error)
          }
        })
      )
    }

    //STEP 3
    if (Object.keys(state.estudios).length < 1) setstep3(true)
    Object.entries(state.estudios).forEach((entry, index) => {
      const [key, value] = entry
      if (value !== null) {
        const file = baseToFile(value, key + '.jpg')
        let dataStepThree = new FormData()
        dataStepThree.append('study', file)
        dataStepThree.append('name', key)
        apiClient
          .post(`api/incomes/${treatmentId}/studies`, dataStepThree, headers)
          .then((res) => {
            if (index + 1 === Object.keys(state.estudios).length) {
              setstep3(true)
            }
          })
          .catch((err) => toast.error('Error on step 3 images', toastProps))
      }
    })
    // OTROS STUDIES
    if (studiesFiles.length > 0) {
      Promise.all(
        studiesFiles.map(async (item, index) => {
          const file = baseToFile(item.file, `.jpg`)
          let otherStudies = new FormData()
          otherStudies.append('image', file)
          otherStudies.append('name', `Otros-${index + 1}`)
          otherStudies.append('type', `study`)
          try {
            await apiClient.post(
              `api/incomes/${treatmentId}/others`,
              otherStudies
            )
          } catch (error) {
            handleToast('error', 'Error cargando otrosStudies..')
            console.log(error)
          }
        })
      )
    }

    //STEP 4
    const sendImage = async (item, index) => {
      const file = baseToFile(item.file, `plan.jpg`)
      let dataStepFour = new FormData()
      dataStepFour.append('plan', file)
      dataStepFour.append('id_file', index)
      try {
        await apiClient.post(`api/incomes/${treatmentId}/plan`, dataStepFour)
      } catch (error) {
        handleToast('error', 'Error cargando el plan..')
        console.log(error)
      }
    }
    const sendPdf = async (item, index) => {
      let dataStepFour = new FormData()
      dataStepFour.append('plan', item.file)
      dataStepFour.append('id_file', index)
      try {
        await apiClient.post(`api/incomes/${treatmentId}/plan`, dataStepFour)
      } catch (error) {
        handleToast('error', 'Error cargando el plan..')
        console.log(error)
      }
    }

    if (planFiles.length > 0) {
      Promise.all(
        planFiles.map(async (item, index) => {
          if (item.type === 'pdf') await sendPdf(item, index)
          else await sendImage(item, index)
        })
      ).then(() => {
        setstep4(true)
      })
    } else setstep4(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentId])

  useEffect(() => {
    if (step2 && step3 && step4) {
      handleToast('success', 'Guardado correctamente')
      setTimeout(() => history.push('/ingresos'), 2000)
    }
  }, [history, step2, step3, step4])

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="row mt-5 mx-auto">
        <div className="col-12 d-flex text-center">
          <div className="container central_container shadow-lg mx-5 px-5 py-4 position-relative">
            <div className="d-flex text-start align-items-center flex-wrap">
              <div className="circulo me-3" style={{ overflow: 'hidden' }}>
                <img
                  src={checkFrotalSonriendo()}
                  alt="Frontal sonriendo"
                  className="avatar_image"
                />
              </div>
              <div className="col-2 mx-2">
                <p>Paciente:</p>
                <h5 className="fw-bold text-truncate">
                  {state?.nombre + ' ' + state?.apellido}
                </h5>
              </div>
              <div className="col-3 mx-2">
                <p>Doctor:</p>
                <h5 className="fw-bold text-truncate">
                  {state?.odontologo.user.name +
                    ' ' +
                    state?.odontologo.user.surname}
                </h5>
              </div>
              <div className="col-1  mx-2">
                <p>Maxilar:</p>
                <h5 className="fw-bold text-truncate">
                  {state?.maxilla.label}
                </h5>
              </div>

              {state?.external_id && (
                <div className="col-2">
                  <p>External ID:</p>
                  <h5
                    title={state?.external_id}
                    className="fw-bold text-truncate"
                  >
                    {state?.external_id}
                  </h5>
                </div>
              )}

              {(state.treatment_type === 1 || state.treatment_type === 4) && (
                <div className="mx-1 ">
                  <button
                    className="btn-sm step px-3 py-2"
                    style={{ cursor: 'default' }}
                  >
                    {state?.etapa && `Etapa ${state?.etapa}`}
                  </button>
                </div>
              )}
            </div>
            <TreatmentLogo treatment_type={state.treatment_type} />
            <hr />
            <div className="row text-start">
              <div className="col-3 pe-4">
                <p>Fotos:</p>
                <div className="d-flex flex-row flex-wrap">
                  <img
                    style={
                      state?.frontal_reposo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.frontal_reposo
                        ? state?.frontal_reposo
                        : frontalreposo
                    }
                    alt="Frontal en Reposo"
                    title="Frontal en Reposo"
                  />
                  <img
                    style={
                      state?.frontal_sonriendo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.frontal_sonriendo
                        ? state?.frontal_sonriendo
                        : frontalsonriendo
                    }
                    alt=""
                    title="Frontal Sonriendo"
                  />
                  <img
                    style={
                      state?.perfil_izq_reposo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.perfil_izq_reposo
                        ? state?.perfil_izq_reposo
                        : perfilizqreposo
                    }
                    alt=""
                    title="Perfil Izquierdo en Reposo"
                  />
                  <img
                    style={
                      state?.trescuartos_perfil !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.trescuartos_perfil
                        ? state?.trescuartos_perfil
                        : trescuartos_perfil
                    }
                    alt=""
                    title="Tres cuartos de perfil"
                  />
                  <img
                    style={
                      state?.oclusal_maxilar_sup !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.oclusal_maxilar_sup
                        ? state?.oclusal_maxilar_sup
                        : oclusalmaxilarsup
                    }
                    alt=""
                    title="Oclusal Maxilar Superior"
                  />
                  <img
                    style={
                      state?.oclusal_maxilar_inf !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.oclusal_maxilar_inf
                        ? state?.oclusal_maxilar_inf
                        : oclusalmaxilarinf
                    }
                    alt=""
                    title="Oclusal Maxilar Inferior"
                  />
                  <img
                    style={
                      state?.anterior_oclusion !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.anterior_oclusion
                        ? state?.anterior_oclusion
                        : anterioroclusion
                    }
                    alt=""
                    title="Anterior en Oclusion"
                  />
                  <img
                    style={
                      state?.bucal_derecho !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.bucal_derecho ? state?.bucal_derecho : bucalderecho
                    }
                    alt=""
                    title="Bucal Derecho"
                  />
                  <img
                    style={
                      state?.bucal_izquierdo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.bucal_izquierdo
                        ? state?.bucal_izquierdo
                        : bucalizquierdo
                    }
                    alt=""
                    title="Bucal Izquierdo"
                  />
                  <img
                    style={
                      state?.anterior_inoclusion !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.anterior_inoclusion
                        ? state?.anterior_inoclusion
                        : anteriorinoclusion
                    }
                    alt=""
                    title="Anterior en Inoclusion"
                  />
                  {photosFiles.length > 1 && (
                    <p className=" my-2 p-2 ms-2 plan-plus fw-bold text-primary shadow">
                      +{photosFiles.length}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-1 pe-4">
                <p>Estudios:</p>
                <div className="d-flex flex-colum flex-wrap justify-content-left">
                  <img
                    style={
                      state?.rxpanoramica !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.rxpanoramica ? state?.rxpanoramica : rxpanoramica
                    }
                    alt=""
                    title="RX Panoramica"
                  />
                  <img
                    style={
                      state?.telerradiografia !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.telerradiografia
                        ? state?.telerradiografia
                        : radioperfil
                    }
                    alt=""
                    title="Telerradiografia"
                  />
                  <img
                    style={
                      state?.ricketts !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={state?.ricketts ? state?.ricketts : ricketts}
                    alt=""
                    title="Ricketts"
                  />

                  {studiesFiles.length > 1 && (
                    <p className=" my-2 ms-2 p-2 plan-plus fw-bold text-primary shadow">
                      +{studiesFiles.length}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-2 pe-4">
                <p>Plan de tratamiento:</p>
                {planFiles[0] ? (
                  <div className="mt-3 d-flex justify-content-center align-items-center flex-column">
                    {planFiles[0].type === 'image' ? (
                      <ImageUploadReference src={planFiles[0].file} />
                    ) : (
                      <div
                        style={{
                          height: '100px',
                          width: '100px',
                          overflow: 'hidden',
                        }}
                      >
                        <Document
                          file={planFiles[0].file}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page height={125} scale={1} pageNumber={1} />
                        </Document>
                      </div>
                    )}
                    {planFiles.length > 1 && (
                      <p className=" my-2 p-2 plan-plus fw-bold text-primary shadow">
                        +{planFiles.length - 1}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="w-100 h-75 border border-1"></div>
                )}
              </div>
              <div className="col-2 pe-4">
                <p>Envio de registro:</p>
                <div className="w-100 btn btn-primary scanButton">
                  {state.intraoral_file
                    ? 'Escaneo intraoral'
                    : 'Envio de impresiones'}
                </div>
              </div>
              <div className="col-4 px-2">
                <p>Pendientes:</p>
                <div className="">
                  <button
                    onClick={() => {
                      setFlags({ ...flags, photos: !flags.photos })
                    }}
                    className={`btn border fw-light border-3 border-danger rounded  my-2 ${
                      flags.photos
                        ? 'btn-outline-warning'
                        : 'btn-danger text-white'
                    }`}
                  >
                    Fotos
                  </button>
                  <button
                    onClick={() => {
                      setFlags({ ...flags, studies: !flags.studies })
                    }}
                    className={`btn border fw-light border-3 border-danger rounded  ms-2 my-2 ${
                      flags.studies
                        ? 'btn-outline-warning'
                        : 'btn-danger text-white'
                    }`}
                  >
                    Estudios
                  </button>
                  <button
                    onClick={() => {
                      setFlags({ ...flags, plan: !flags.plan })
                    }}
                    className={`btn border fw-light border-3 border-danger rounded  ms-2 my-2 ${
                      flags.plan
                        ? 'btn-outline-warning'
                        : 'btn-danger text-white'
                    }`}
                  >
                    Plan
                  </button>
                </div>
                <p className="disabled">Observaciones:</p>
                <div className="d-flex flex-row justify-content-end disabled">
                  <textarea
                    disabled
                    onChange={(e) =>
                      setState({
                        ...state,
                        observation: e.target.value,
                      })
                    }
                    name=""
                    id=""
                    cols="45"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6 d-flex flex-row justify-content-end">
          <button
            onClick={() => handleRedirectIncome()}
            className="btn volver rounded-pill px-5 py-3 mx-3"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6 d-flex flex-row justify-content-start">
          {load ? (
            <button className="btn btn-primary rounded-pill px-6 py-3 mx-3 disabled">
              <Spinner size={'sm'} color="white"></Spinner>
            </button>
          ) : (
            <button
              onClick={() => handleSaveTreatment()}
              className="btn btn-primary rounded-pill px-5 py-3 mx-3"
            >
              Guardar
            </button>
          )}
        </div>
      </div>
      {load && (
        <div className="text-center">
          <p className="my-2 fw-bold red">
            Guardando tratamiento, esto puede llevar unos segundos...
          </p>
        </div>
      )}
    </div>
  )
}
