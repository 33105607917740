import React from 'react'
import { MoreVertical } from 'react-feather'
import { ImageUploadLabel, ImageUploadReference } from './styled'
import Compressor from 'compressorjs'
import 'pintura/pintura.css'
import {
  // Import plugins
  plugin_crop,
  plugin_finetune,
  plugin_annotate,
  plugin_resize,
  // Import languajes
  openDefaultEditor,
  setPlugins,
} from 'pintura'
import { Document, Page } from 'react-pdf'
import { getQuality } from '../../../utils/quality'

const DocImage = React.forwardRef(
  ({ index, type, image, handleDeletePlan, handleEditPlan, inputName }) => {
    const isPdf = type === 'pdf' ? true : false

    function handleImageEditor(e) {
      setPlugins(plugin_crop, plugin_finetune, plugin_annotate, plugin_resize)
      const editor = openDefaultEditor({
        src: image,
      })
      editor.on('process', ({ dest }) => {
        new Compressor(dest, {
          quality: getQuality(),
          success(result) {
            let reader = new FileReader()
            let file_compressed = result

            reader.onloadend = () => {
              handleEditPlan(reader.result, index)
            }

            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      })
    }

    return (
      <div className="d-flex flex-column mx-auto" style={{ width: 100 }}>
        <div className="row">
          <div
            style={{
              width: '100px',
              height: '100px',
              position: 'relative',
            }}
          >
            {isPdf && (
              <div
                style={{
                  height: '125px',
                  width: '100px',
                  overflow: 'hidden',
                }}
              >
                <Document file={image}>
                  <Page height={125} scale={1} pageNumber={1} />
                </Document>
              </div>
            )}

            {!isPdf && <ImageUploadReference src={image} alt="Imagen" />}

            <div class="btn-group dropend">
              <button
                type="button"
                class="btn dropdown p-0 position-absolute"
                style={{
                  top: isPdf ? '-65px' : '15px',
                  right: isPdf ? '-95px' : '5px',
                  background: 'rgba(0,0,0,0.4)',
                  borderRadius: 6,
                }}
                handleEditPlan
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MoreVertical size={24} color="#fff" />
              </button>
              <ul class="dropdown-menu">
                {!isPdf && (
                  <li>
                    <button
                      className="btn bg-none text-muted d-flex justify-content-center text-center align-items-middle"
                      onClick={() => {
                        handleImageEditor()
                      }}
                    >
                      Editar
                    </button>
                  </li>
                )}
                <li>
                  <button
                    onClick={() => {
                      handleDeletePlan(index)
                    }}
                    className="btn bg-none text-muted d-flex flex-column justify-content-center text-center align-items-middle"
                  >
                    Eliminar
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ImageUploadLabel>{`${inputName ? inputName : 'Plan'} ${
          index + 1
        }`}</ImageUploadLabel>
      </div>
    )
  }
)

export default DocImage
