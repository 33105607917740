import React from 'react'
import { useSelector } from 'react-redux'

const COUNTRYS_DATA = {
  2: {
    name: 'Uruguay',
    id: 2,
  },
  3: {
    name: 'Paraguay',
    id: 3,
  },
  4: {
    name: 'Colombia',
    id: 4,
  },
  5: {
    name: 'Chile',
    id: 5,
  },
  6: {
    name: 'Perú',
    id: 6,
  },
  7: {
    name: 'México',
    id: 7,
  },
}

const CountryFilterComponent = ({ state, changeCountryHandler }) => {
  const userCountryId = useSelector((state) => state.userReducer.countryId)

  if (!userCountryId)
    return <p className="text-end red fw-bold">Error cargando el Country ID</p>

  return (
    <div className="d-flex justify-content-end flex-wrap">
      {userCountryId === 1 ? (
        <>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 1 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(1)}
          >
            Argentina
          </div>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 5 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(5)}
          >
            Chile
          </div>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 2 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(2)}
          >
            Uruguay
          </div>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 3 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(3)}
          >
            Paraguay
          </div>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 6 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(6)}
          >
            Perú
          </div>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 4 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(4)}
          >
            Colombia
          </div>
          <div
            className={`cursor-pointer my-2 mx-3 ${state === 7 && 'fw-bold'}`}
            onClick={() => changeCountryHandler(7)}
          >
            México
          </div>
        </>
      ) : (
        <div
          className={`cursor-pointer my-2 mx-3 fw-bold`}
          onClick={() => changeCountryHandler(COUNTRYS_DATA[userCountryId]?.id)}
        >
          {COUNTRYS_DATA[userCountryId]?.name}
        </div>
      )}
    </div>
  )
}

export default CountryFilterComponent
