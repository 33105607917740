import React from 'react'
import { TeethSelector } from '../../../Utilities/TeethSelector'
import '../../../style.css'

const Apiñamiento = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Apiñamiento</p>
      <hr className="mt-2" />
      <div className="improve-overjet-container">
        <div className="d-flex flex-column">
          <div className="w-100">
            <p className="mb-0">¿Desea realizar Stripping?</p>
            <hr className="mt-1 me-3" />
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="stripping"
                disabled
                checked={formData?.stripping == 1 && true}
              />
              <label className="ms-2">Si</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="stripping"
                disabled
                checked={formData?.stripping == 2 && true}
              />
              <label className="ms-2">Solo si es necesario</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="stripping"
                disabled
                checked={formData?.stripping == 3 && true}
              />
              <label className="ms-2">Nada</label>
            </div>
          </div>
          <div className="w-100 mt-3">
            <p>Remodelado Virtual</p>
            <hr className="mt-1" />
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="virtualMakeover"
                disabled
                checked={formData?.virtual_remodel == 1 && true}
              />
              <label className="ms-2">Si</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="virtualMakeover"
                disabled
                checked={formData?.virtual_remodel == 2 && true}
              />
              <label className="ms-2">No</label>
            </div>
          </div>
        </div>
      </div>
      {formData?.virtual_remodel == 1 && (
        <>
          <p className="mt-4 improve-overjet-title">
            Indique las piezas de las que desea realizar remodelado virtual:
          </p>
          <TeethSelector formData={formData} type="virtual_remodel" />
          <p className="mt-4 improve-overjet-title">
            Indique las piezas de las que desea realizar remodelado virtual:
          </p>
          <div className="step-text-area w-100">
            {formData?.virtual_remodel_description}
          </div>
        </>
      )}
    </div>
  )
}

export default Apiñamiento
