import React from 'react'
import ImageDisplay from '../utilities/ImageDisplay'
import ImageInput from '../utilities/ImageInput'

const OtherPhotos = ({ backendFiles, othPhotos, setOthPhotos }) => {
  const handleImageSelect = (image) => {
    const newFile = { file: image }
    setOthPhotos([...othPhotos, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newPlanFiles = othPhotos
    newPlanFiles.splice(indexToDelete, 1)
    setOthPhotos([...newPlanFiles])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newPlanFiles = othPhotos
    newPlanFiles[indexToEdit].file = image
    setOthPhotos([...newPlanFiles])
  }

  return (
    <>
      {backendFiles.otherPhotos.map((item, i) => (
        <ImageDisplay
          key={i}
          name={`Otros - ${i + 1}`}
          previewImage={item.thumb}
          image={item.path}
        />
      ))}
      {othPhotos.map((item, i) => (
        <ImageInput
          index={i}
          name={`Otros - ${backendFiles.otherPhotos.length + i + 1}`}
          src={'otros'}
          imageToSave={{
            image: item.file,
          }}
          handleEditPlan={handleEditPlan}
          handleDeletePlan={handleDeletePlan}
        />
      ))}
      {backendFiles.otherPhotos.length + othPhotos.length < 5 && (
        <ImageInput
          name={'Cargar Otros'}
          src={'otros'}
          id={'Otros'}
          imageToSave={null}
          handleImageSelect={handleImageSelect}
        />
      )}
    </>
  )
}

export default OtherPhotos
