import apiClient from "../../../../../../utils/backend"
import { studiesInput } from "../filesNames/studiesInput"

export const createNotificationTypeDocumentation = async (treatment_id) => {
  try {
    await apiClient.post(`/api/createNotificationDocumentation`, {
      treatment_id,
    })
  } catch (err) {
    return console.log(err, 'Error create notification')
  }
}
export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}
export const isStudyFile = (fileId) => studiesInput.some(({ slug }) => slug === fileId) || fileId === 'studies'