import React from 'react'
import referralAll from '../styles/referralAll.module.css'
import PropTypes from 'prop-types'

export const ReferralsModalOfficesCard = ({
  office,
  officeState,
  id,
  selectOffice,
}) => {
  const { direction_name, street_address, street_number } = office

  return (
    <>
      <input
        type="radio"
        name={office.id}
        value={office.id}
        id={office.id}
        checked={officeState[id]}
        onChange={() => selectOffice(id, office)}
      />
      <label htmlFor={office.id}>
        <div className="d-flex flex-column ms-2">
          <span title={direction_name}>{direction_name}</span>
          <span
            className={`${referralAll.officeAddress} text-truncate`}
            title={`${street_address} ${street_number}`}
          >{`${street_address} ${street_number}`}</span>
        </div>
      </label>
    </>
  )
}

ReferralsModalOfficesCard.propTypes = {
  office: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  officeState: PropTypes.object.isRequired,
  selectOffice: PropTypes.func.isRequired,
}
