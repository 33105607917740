import React from 'react'
import Icons from '../Icons/Icons'
import PropTypes from 'prop-types'

const CloseSidebarItems = ({ item }) => {
  if (item.subItems) return null
  return (
    <a
      href={item.active && item.link}
      className={`mx-auto  d-flex flex-start w-full align-items-center sb-item-padding mb-4 cursor-pointer ${
        item.active ? 'scale' : 'disabled'
      }`}
    >
      <Icons type={item.logo} />
    </a>
  )
}

export default CloseSidebarItems

CloseSidebarItems.propTypes = {
  item: PropTypes.object.isRequired,
}
