import React, { useEffect, useState } from 'react'
import { Search } from 'react-feather'
import styles from '../styles/directionsForm.module.css'

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import DeleteModal from './DeleteModal'
import handleToast from '../../../../components/toaster'

const DentistDirections = ({
  direction,
  usePlacesAutocomplete,
  handleDeleteDirection,
  handleSaveDirection,
  getGeocode,
  getLatLng,
}) => {
  const [formData, setFormData] = useState({
    direction_name: direction.direction_name,
    id: direction.id,
    street_address: direction.street_address,
    street_number: direction.street_number,
    floor: direction.floor,
    apartment: direction.apartment,
    hood: direction.hood,
    zip_code: direction.zip_code,
    province: direction.province,
    city: direction.city,
    country: direction.country,
    toSave: direction.toSave,
    inputId: direction.inputId,
    lat: direction.lat,
    lng: direction.lng,
  })

  const {
    direction_name,
    id,
    street_address,
    street_number,
    floor,
    apartment,
    hood,
    zip_code,
    province,
    city,
    toSave,
    country,
    lat,
    lng,
    inputId,
  } = formData

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete()

  //Llamada a la api de details
  const handleFindPlace = async (address) => {
    resetForm()
    clearSuggestions()
    try {
      const resultsGeoCode = await getGeocode({ address })
      const { lat, lng } = await getLatLng(resultsGeoCode[0])
      handleSetDirection(resultsGeoCode[0], lat, lng)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetDirection = (directionDetails, lat, lng) => {
    const details = directionDetails.address_components
    const description = {
      street_number: getDataByType(details, 'street_number'),
      street_address: getDataByType(details, 'route'),
      hood: getDataByType(details, 'sublocality_level_1'),
      province: getDataByType(details, 'administrative_area_level_1'),
      city: getDataByType(details, 'administrative_area_level_2'),
      country: getDataByType(details, 'country'),
      zip_code: getDataByType(details, 'postal_code'),
      lat: lat.toString(),
      lng: lng.toString(),
    }
    setFormData((prev) => ({ ...prev, ...description }))
  }

  const getDataByType = (array, type) => {
    let data = array?.find((item) => item?.types.includes(type))
    return data?.long_name
  }

  const getLatLngWithoutFinder = async (directionData) => {
    const address = `${directionData.street_address} ${directionData.street_number}, ${directionData.zip_code},${directionData.country}`
    try {
      const response = await getGeocode({ address })
      const { lat: latitud, lng: longitud } = await getLatLng(response[0])
      setFormData((prev) => ({
        ...prev,
        lat: latitud,
        lng: longitud,
        toSave: false,
      }))
      handleSaveDirection(formData, latitud, longitud, true)
    } catch (error) {
      console.log(error)
    }
  }

  const saveForm = () => {
    if (
      !street_address ||
      !street_number ||
      !zip_code ||
      !province ||
      !city ||
      !country ||
      !hood
    ) {
      return handleToast('error', 'Complete los campos obligatorios *')
    }
    //If the user doesn't search the direction with the finder we need the lat and lng
    if (!lat || !lng) {
      getLatLngWithoutFinder(formData)
    } else {
      setFormData((prev) => ({ ...prev, toSave: false }))
      handleSaveDirection(formData)
    }
  }

  const resetForm = () => {
    setFormData((prev) => ({
      ...prev,
      street_address: '',
      street_number: null,
      floor: '',
      apartment: '',
      hood: '',
      zip_code: '',
      province: '',
      city: '',
      country: '',
      toSave: true,
      lat: 0,
      lng: 0,
    }))
  }

  useEffect(() => {
    setFormData((prev) => ({ ...prev, toSave: true }))
  }, [
    direction_name,
    id,
    street_address,
    street_number,
    floor,
    apartment,
    hood,
    zip_code,
    province,
    city,
    country,
  ])

  useEffect(() => {
    setFormData((prev) => ({ ...prev, toSave: false }))
  }, [])

  return (
    <>
      <div className={styles.directionFather}>
        <div className="d-flex w-100">
          <input
            type="text"
            name="direction_name"
            placeholder="Indique el nombre de la dirección"
            className={styles.directionInput}
            value={direction_name}
            onChange={handleInputChange}
          />
          <DeleteModal
            handleDeleteDirection={handleDeleteDirection}
            inputId={inputId}
            id={id}
          />
        </div>

        <Combobox className={styles.searchBox}>
          <ComboboxInput
            type="text"
            name="search"
            placeholder="🔍 Buscar..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Search color="#002e50" />
          <ComboboxPopover>
            <ComboboxList>
              {status === 'OK' &&
                data.map(({ place_id, description }) => (
                  <ComboboxOption
                    key={place_id}
                    value={description}
                    onClick={(e) => handleFindPlace(description)}
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
        <>
          <div className={`d-flex mt-4 ${styles.directionData}`}>
            <div className={`d-flex flex-column ${styles.street_address}`}>
              <label htmlFor="street_address">Calle *</label>
              <input
                type="text"
                name="street_address"
                value={street_address}
                onChange={handleInputChange}
              />
            </div>
            <div className={`d-flex flex-column ${styles.street_number}`}>
              <label htmlFor="street_number">Número *</label>
              <input
                type="number"
                name="street_number"
                value={street_number}
                onChange={handleInputChange}
              />
            </div>
            <div className={`d-flex flex-column ${styles.floor}`}>
              <label htmlFor="floor">Piso</label>
              <input
                type="number"
                name="floor"
                value={floor}
                onChange={handleInputChange}
              />
            </div>
            <div className={`d-flex flex-column ${styles.apartment}`}>
              <label htmlFor="apartment">Apartamento</label>
              <input
                type="text"
                name="apartment"
                value={apartment}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div
            className={`d-flex mt-4 justify-content-between ${styles.directionData}`}
          >
            <div className={`d-flex flex-column ${styles.hood}`}>
              <label htmlFor="hood">Barrio *</label>
              <input
                type="text"
                name="hood"
                value={hood}
                onChange={handleInputChange}
              />
            </div>
            <div className={`d-flex flex-column ${styles.zip_code}`}>
              <label htmlFor="zip_code">C.P *</label>
              <input
                type="text"
                name="zip_code"
                value={zip_code}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className={`d-flex mt-4 ${styles.directionData}`}>
            <div className={`d-flex flex-column ${styles.province}`}>
              <label htmlFor="province">Provincia *</label>
              <input
                type="text"
                name="province"
                value={province}
                onChange={handleInputChange}
              />
            </div>
            <div className={`d-flex flex-column ${styles.city}`}>
              <label htmlFor="city">Ciudad *</label>
              <input
                type="text"
                name="city"
                value={city}
                onChange={handleInputChange}
              />
            </div>
            <div className={`d-flex flex-column ${styles.country}`}>
              <label htmlFor="country">País *</label>
              <input
                type="text"
                name="country"
                value={country}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <button
            type="button"
            className={toSave ? styles.save : styles.saveDisabled}
            onClick={() => saveForm()}
            disabled={!toSave}
          >
            Guardar
          </button>
        </>
      </div>
    </>
  )
}

export default DentistDirections
