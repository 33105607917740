import React from 'react'
import kslogo from '../../../../assets/images/ksloader.png'
import DocumentsReminder from './components/DocumentsReminder'
import ProfileReminder from './components/ProfileReminder'

const Reminder = ({ modal, setModal }) => {
  return (
    <div>
      <div className="d-flex justify-content-center mt-5">
        <img src={kslogo} width={150} alt="ks-logo" />
      </div>
      {/*  <DocumentsReminder /> */}
      <ProfileReminder setModal={setModal} />
    </div>
  )
}

export default Reminder
