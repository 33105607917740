import React, { useState } from 'react'
import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import handleToast from '../../../components/toaster'
import apiClient from '../../../utils/backend'
import styles from '../styles/PaymentValidationModal.module.css'

const PaymentValidationModal = ({
  principalDentist,
  patient,
  treatId,
  getAllPayments,
  externalId,
}) => {
  const [opened, setOpened] = useState(false)
  const handleEnableTreatment = async () => {
    handleToast('updating', 'Habilitando tratamiento...')
    const dataToSend = {
      payment_validations: [{ treatment_id: treatId, payment_status: 1 }],
    }
    try {
      const res = await apiClient.post(`api/paymentvalidations`, dataToSend)
      getAllPayments(1, 1)
      setOpened(!opened)
      handleToast('success', 'Tratamiento habilitado correctamente')
    } catch (error) {
      handleToast('error', 'Hubo un error habilitando el tratamiento')
      console.log(error)
    }
  }

  return (
    <>
      <button className={styles.button} onClick={() => setOpened(!opened)}>
        Habilitar
      </button>
      <Modal
        size="lg"
        style={{ marginTop: '50px', maxWidth: '600px' }}
        isOpen={opened}
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          <div className="p-3">
            <div className="d-flex justify-content-between align-items-end">
              <p className={`${styles.title} fs-4`}>Habilitar pago</p>
              <X
                onClick={() => setOpened(!opened)}
                className={styles.closeBTN}
              />
            </div>
            <div className="d-flex my-4 justify-content-between flex-column mb-0">
              <div className={`d-flex ${styles.content}`}>
                <div>
                  <p className={styles.infoTitle}>ID:</p>
                  <p className={styles.infoText}>{externalId}</p>
                </div>
                <div>
                  <p className={styles.infoTitle}>Paciente:</p>
                  <p className={styles.infoText}>{patient}</p>
                </div>
                <div>
                  <p className={styles.infoTitle}>Doctor:</p>
                  <p className={styles.infoText}>
                    {principalDentist?.user.name}{' '}
                    {principalDentist?.user.surname}
                  </p>
                </div>
              </div>

              <div className={styles.buttonContainer}>
                <button
                  className={styles.button}
                  onClick={() => handleEnableTreatment()}
                >
                  Habilitar
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PaymentValidationModal
