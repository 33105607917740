import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Upload } from "react-feather";

import smile from "../../assets/images/ks-smile.svg";
import apiClient from "../../utils/backend";
import CategoryCard from "./components/CategoryCard";
import ConsultCard from "./components/consult_card";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      cards: [],
      patient: null,
      category: null,
      od_patients: [],
      withPatient: null,
      loading: true,
      consult_type: null,
      consult_detail: null,
      consult_photo: null,
      patient_data: {
        name: null,
        treatment: null,
        order: null,
      },
    };

    this.handleCategory = this.handleCategory.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.handleGetPatients = this.handleGetPatients.bind(this);
    this.handleGetPatientData = this.handleGetPatientData.bind(this);
  }

  componentDidMount() {
    apiClient.get(`api/helper/getConsultFull`).then((res) => {
      this.setState({ cards: res.data });
      console.log(res);
    });
    console.log(this.props);
  }

  handleCategory(category, items) {
    this.setState({
      category: category,
      patient: null,
      step: this.state.step + 1,
      category_items: items,
    });
  }

  handleGetPatients() {
    apiClient
      .get(`api/helper/getPatientsByDentist/${this.props.state.userReducer.id}`)
      .then((res) => {
        let patients = [];

        Object.entries(res.data).map(([key, value]) => {
          patients.push({ value: key, label: value });
        });
        console.log(patients, res.data);
        this.setState({ od_patients: patients, patients: res.data });
      });
  }

  handleGetPatientData(id) {
    apiClient.get(`api/helper/getPatient/${id}`).then((res) => {
      this.setState({
        patient_data: {
          name: res.data.patient[0].full_name,
          treatment: res.data.treatment.type_id,
          order: res.data.treatment.order,
        },
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step) {
      if (this.state.step === 2) {
        this.handleGetPatients();
      }
    }
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  render() {
    const selectStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        borderBottom: "2px solid #0027a8!important",
        width: "40%",
      }),
    };

    return (
      <div>
        {this.state.step === 1 && (
          <div className="container-fluid d-flex flex-column text-primary oveflow-hidden">
            <div className="row">
              <div className="col-12 position-relative">
                <div className="pagos-bg" style={{ height: "175px" }} />
              </div>
            </div>

            <div
              className="row pt-2 mt-4 px-5"
              style={{ zIndex: 1, height: "175px" }}
            >
              <div className="col-12 px-4" style={{ zIndex: 1 }}>
                <img
                  src={smile}
                  style={{ maxWidth: "75px", margin: 0 }}
                  alt=""
                />
                <h2 className="text-white fw-bold">Estamos para ayudarte</h2>
                <h4 className="text-white">
                  Para una mejor atención, a continuación clasifica el tipo de
                  consulta que quieres realizar
                </h4>
              </div>
            </div>

            <div className="mt-4 mx-auto d-flex  flex-row justify-content-around">
              {this.state.cards.map((card) => (
                <CategoryCard
                  data={card}
                  handleCategory={this.handleCategory}
                />
              ))}
            </div>
            <p className="text-center mt-5 fs-5">
              En caso de ser una consulta de <strong>urgencia</strong> puede
              comunicarse por WhatsApp haciendo click{" "}
              <a href="" style={{ borderBottom: "1px dotted blue" }}>
                Aquí
              </a>
            </p>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="container-fluid text-primary">
            <div className="row">
              <div className="col-12 position-relative">
                <div className="pagos-bg" style={{ height: "150px" }} />
              </div>
            </div>

            <div
              className="row pt-2 pt-5 px-5"
              style={{ zIndex: 1, height: "150px" }}
            >
              <div className="col-12 px-4" style={{ zIndex: 1 }}>
                <img
                  src={smile}
                  style={{ maxWidth: "75px", margin: 0 }}
                  alt=""
                />
                <h2 className="text-white fw-bold">Estamos para ayudarte</h2>
              </div>
            </div>
            <div className="px-5">
              <p className="fw-bold text-primary mt-5 px-5">Nueva consulta</p>
              <h2 className="fw-bold px-5">
                {this.state.category} |{" "}
                <span className="fw-normal" style={{ fontSize: 12 }}>
                  Su consulta va a ser recibida por el equipo de Ortodoncia de
                  KeepSmiling
                </span>
              </h2>
              <h4 className="fw-bold mt-5 px-5">
                ¿Su consulta es respecto a un paciente especifico?
              </h4>

              <div className="d-flex flex-row mt-4 px-5">
                <button
                  onClick={() => this.setState({ withPatient: true })}
                  style={{ width: 120, height: 30 }}
                  className={`${
                    this.state.withPatient
                      ? "btn-primary text-white"
                      : "text-primary"
                  } btn border-primary rounded-pill d-flex justify-content-center align-items-center`}
                >
                  Si
                </button>
                <button
                  onClick={() => {
                    this.setState({ withPatient: false, patient: null });
                  }}
                  style={{ width: 120, height: 30 }}
                  className={`${
                    !this.state.withPatient
                      ? "btn-primary text-white"
                      : "text-primary"
                  } btn border-primary rounded-pill text-primary d-flex justify-content-center align-items-center mx-3`}
                >
                  No
                </button>
              </div>

              {this.state.withPatient && (
                <div className="d-flex flex-column px-5">
                  <h4 className="fw-bold mt-5">Seleccione el paciente</h4>

                  <label className="mt-3 text-muted" style={{ fontSize: 14 }}>
                    Paciente:
                  </label>
                  <Select
                    styles={selectStyle}
                    options={this.state.od_patients}
                    onChange={(paciente) => {
                      console.log(paciente);
                      this.setState({ patient: paciente });
                      this.handleGetPatientData(paciente.value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="container-fluid text-primary">
            <div className="row">
              <div className="col-12 position-relative">
                <div className="pagos-bg" style={{ height: "150px" }} />
              </div>
            </div>

            <div
              className="row pt-2 pt-5 px-5"
              style={{ zIndex: 1, height: "150px" }}
            >
              <div className="col-12 px-4" style={{ zIndex: 1 }}>
                <img
                  src={smile}
                  style={{ maxWidth: "75px", margin: 0 }}
                  alt=""
                />
                <h2 className="text-white fw-bold">Estamos para ayudarte</h2>
              </div>
            </div>
            <div className="px-5">
              <p className="fw-bold text-primary mt-5 px-5">Nueva consulta</p>
              <h2 className="fw-bold px-5">
                {this.state.category} |{" "}
                <span className="fw-normal" style={{ fontSize: 12 }}>
                  Su consulta va a ser recibida por el equipo de Ortodoncia de
                  KeepSmiling
                </span>
              </h2>

              <div className="mt-5 row">
                <div className="col-2" style={{ paddingLeft: "3rem" }}>
                  {this.state.withPatient && (
                    <div className="d-flex flex-column">
                      <h3 className="fw-bold">Paciente:</h3>
                      <div className="w-100 h-50 border"></div>
                      <p className="fw-bold mt-3">
                        {this.state.patient_data.name}
                      </p>
                      <span>
                        {this.state.patient_data.treatment} -{" "}
                        {this.state.patient_data.order}° etapa
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-10" style={{ paddingLeft: "1rem" }}>
                  <h4 className="fw-bold">¿En que podemos ayudar?</h4>
                  <p className="mt-2" style={{ fontSize: 12 }}>
                    Indique cual es el motivo de su consulta clínica para poder
                    atenderlo mejor.
                  </p>

                  <div className="d-flex flex-row flex-wrap mt-4">
                    {this.state.category_items.map((tipoConsulta) => (
                      <div
                        onClick={() =>
                          this.setState({ consult_type: tipoConsulta.title })
                        }
                        style={{
                          height: 50,
                          borderRadius: 20,
                          cursor: "pointer",
                          marginRight: 20,
                          marginBottom: 10,
                        }}
                        className={`d-flex justify-content-start align-items-center border px-3 w-40 ${
                          this.state.consult_type === tipoConsulta.title
                            ? "bg-primary text-white"
                            : ""
                        }`}
                      >
                        <span className="fw-bold">{tipoConsulta.title}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 4 && (
          <div className="container-fluid text-primary">
            <div className="row">
              <div className="col-12 position-relative">
                <div className="pagos-bg" style={{ height: "150px" }} />
              </div>
            </div>

            <div
              className="row pt-2 pt-5 px-5"
              style={{ zIndex: 1, height: "150px" }}
            >
              <div className="col-12 px-4" style={{ zIndex: 1 }}>
                <img
                  src={smile}
                  style={{ maxWidth: "75px", margin: 0 }}
                  alt=""
                />
                <h2 className="text-white fw-bold">Estamos para ayudarte</h2>
              </div>
            </div>
            <div className="px-5">
              <p className="fw-bold text-primary mt-5 px-5 text-center fs-3">
                Nueva consulta
              </p>
              <h2 className="fw-bold px-5">
                {this.state.category} |{" "}
                <span className="fw-normal" style={{ fontSize: 12 }}>
                  Su consulta va a ser recibida por el equipo de Ortodoncia de
                  KeepSmiling
                </span>
              </h2>

              <div className="mt-5 row">
                <div className="col-2" style={{ paddingLeft: "3rem" }}>
                  {this.state.withPatient && (
                    <div className="d-flex flex-column">
                      <h3 className="fw-bold">Paciente:</h3>
                      <div className="w-100 h-50 border"></div>
                      <p className="fw-bold mt-3">
                        {this.state.patient_data.name}
                      </p>
                      <span>
                        {this.state.patient_data.treatment} -{" "}
                        {this.state.patient_data.order}° etapa
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-10" style={{ paddingLeft: "1rem" }}>
                  <div className="row">
                    <div className="col-12">
                      <h4 className="fw-bold">Consulta:</h4>
                      <p className="mt-2 fw-bold" style={{ fontSize: 18 }}>
                        {this.state.consult_type}
                      </p>
                    </div>

                    <div className="col-9 d-flex flex-row flex-wrap mt-4">
                      <textarea
                        className="form-control"
                        placeholder="Detalle sobre la consulta. Si considera necesario, puede adjuntar una imagen."
                        onChange={(e) =>
                          this.setState({ consult_detail: e.target.value })
                        }
                        name="consult_detail"
                        id="consult_detail"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                    <div className="col-3 mt-4">
                      <input
                        type="file"
                        name=""
                        onChange={(e) =>
                          this.getBase64(e.target.files[0], (res) =>
                            this.setState({ consult_photo: res })
                          )
                        }
                        disabled={
                          window.location.pathname.split("/")[2] === "perfil"
                        }
                        id="od_profile_pic_input"
                        className=""
                      />
                      <label for="od_profile_pic_input">
                        {this.state.consult_photo !== null ? (
                          <img
                            style={{
                              maxWidth: "200px",
                              maxHeight: "200px",
                              objectFit: "cover",
                            }}
                            src={this.state.consult_photo}
                            alt="Foto de perfil"
                          />
                        ) : (
                          <Upload size={74} color="#ccc" />
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.step === 5 && (
          <div className="container-fluid text-primary">
            <div className="row">
              <div className="col-12 position-relative">
                <div className="pagos-bg" style={{ height: "150px" }} />
              </div>
            </div>

            <div
              className="row pt-2 pt-5 px-5"
              style={{ zIndex: 1, height: "150px" }}
            >
              <div className="col-12 px-4" style={{ zIndex: 1 }}>
                <img
                  src={smile}
                  style={{ maxWidth: "75px", margin: 0 }}
                  alt=""
                />
                <h2 className="text-white fw-bold">Estamos para ayudarte</h2>
              </div>
            </div>
            <div className="px-5">
              <p className="fw-bold text-primary mt-5 px-5 fs-3 text-center">
                ¡Consulta enviada!
              </p>
              <p className="px-5 text-center w-75 mx-auto mt-4">
                Su consulta fue enviada correctamente. Nuestro equipo de
                Ortodoncistas la estará revisando para brindarle una solución o
                acompañamiento
              </p>

              <div className="mt-5 row px-5">
                <ConsultCard
                  data={this.state.withPatient ? this.state.patient_data : null}
                  category={this.state.category}
                  consult={this.state.consult_type}
                  detail={this.state.consult_detail}
                />
              </div>
            </div>
          </div>
        )}

        <div className="d-flex flex-column justify-content-end px-5 mt-4">
          <div className="d-flex justify-content-end">
            {this.state.step > 1 && this.state.step < 5 && (
              <button
                onClick={() => this.setState({ step: this.state.step - 1 })}
                style={{ width: 150 }}
                className="btn border-primary text-primary rounded-pill mt-3"
              >
                Volver
              </button>
            )}
            {this.state.step !== 1 && this.state.step < 4 && (
              <button
                onClick={() => this.setState({ step: this.state.step + 1 })}
                style={{ width: 150 }}
                className="btn btn-primary rounded-pill mt-3 mx-3"
              >
                Continuar
              </button>
            )}
            {this.state.step === 4 && (
              <button
                onClick={() => this.setState({ step: this.state.step + 1 })}
                style={{ width: 150 }}
                className="btn btn-primary rounded-pill mt-3 mx-3"
              >
                Enviar
              </button>
            )}
          </div>
          {this.state.step > 1 && (
            <p className="text-primary text-center w-75 mx-auto mt-3">
              En caso de ser una consulta de <strong>urgencia</strong> puede
              comunicarse por WhatsApp al: 000000000 o llamar al: 00000000
            </p>
          )}
          {this.state.step === 5 && (
            <div className="w-100 d-flex flex-row justify-content-center">
              <button
                onClick={() => this.setState({ step: this.state.step - 1 })}
                style={{ width: 150 }}
                className="btn border-primary text-primary rounded-pill mt-3"
              >
                Volver
              </button>
              <a
                href="/od/consultas"
                style={{ width: 150, whiteSpace: "nowrap" }}
                className="btn border-primary text-primary rounded-pill mt-3 mx-3"
              >
                Ir a mis consultas
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(Index);
