import moment from 'moment'
import React from 'react'
export default function ConsultCard({data, category, consult, detail}) {

    return (

        <div className="container-fluid p-0 w-75 mt-3 border border-1 shadow" style={{borderRadius: '20px', overflow: 'hidden'}}>
        {console.log(data)}
            <div className="row m-0 w-100 p-2 border-bottom py-2 px-3">
                {data !== null && <div className="col-1 d-flex justify-content-center align-items-center">
                    <div className="p-4 w-75 h-75 rounded-circle border"></div>
                </div>}
                {data !== null && <div className="col-2 d-flex flex-column py-2">
                    <p className="text-primary fw-bold">{data.name}</p>
                    <p className="text-primary fw-normal mb-0">{data.treatment} - {data.order}° etapa</p>
                </div>}
                <div className="col-2 d-flex flex-column py-2">
                    <small className="text-primary">Categoria:</small>
                    <p className="text-primary fw-bold mb-0">{category}</p>
                </div>
                <div className="col-4 d-flex flex-column py-2">
                    <small className="text-primary">Consulta:</small>
                    <p className="text-primary fw-bold mb-0">{consult}</p>
                </div>
                <div className="col-3 d-flex flex-row justify-content-end py-2">
                    <p className="text-primary mt-2 mb-0">{moment().format("DD-MM-YYYY")}</p>
                </div>
            </div>
            <div className="row m-0 w-100 p-4 mb-2 d-flex flex-column">
                <p className="mb-1" style={{lineHeight: "1.2em"}}>{detail} <small className="text-muted">Hace un instante</small></p>
            </div>
        </div>
    )
}
