import React, { useCallback, useEffect, useRef } from 'react'
import '../documentation.css'
// IMAGES STEP 2
import { ImageUploadReference } from './styled'
// Import the editor styles
import 'pintura/pintura.css'
// Import the editor component from `react-pintura`
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { getLicense } from '../../../../../../utils/licenseKeyLightGallery'
import PDFViewer from '../../../../../../components/Modals/PDFViewer'

const ImageDisplay = React.forwardRef(({ name, previewImage, image, pdf }) => {
  const lightGallery = useRef(null)
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance
    }
  }, [])

  useEffect(() => {
    // Refresh gallery
    if (!lightGallery.current) return
    lightGallery.current.refresh()
  }, [image, previewImage])

  return (
    <div className="my-2 mx-2 p-1 position-relative inputDisplay">
      {pdf ? (
        <PDFViewer imageSource={pdf} />
      ) : (
        <div>
          <LightGallery
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
            className="gallery"
            onInit={onInit}
            licenseKey={getLicense()}
          >
            <div data-src={image}>
              <ImageUploadReference
                //Preview Image
                src={previewImage}
                alt={name}
              />
            </div>
          </LightGallery>
        </div>
      )}

      <p className="my-1 image-name text-primary">{name}</p>
    </div>
  )
})

export default ImageDisplay
