import React from 'react'
import Select from 'react-select'
import Input from '../../../components/input'
import { User } from 'react-feather'
import {
  documentoDeIdentidadCol,
  gendersData,
  selectStyleData,
} from '../utilies/constantInfo'
import 'react-toastify/dist/ReactToastify.css'
import Profile from '../components/Profile'
import '../Editar/index.css'
import styles from './styles/styles.module.css'
import directionStyle from './styles/directionsForm.module.css'
import CreateDirections from './components/CreateDirections'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import useCreateDentist from './hooks/useCreateDentist'
import useCountryInputs from '../hooks/useCountryInputs'
import Loader from '../../../components/loader'

const CreateDentist = () => {
  const {
    requiredData,
    setAvatar,
    getBase64,
    setPersonalData,
    personalData,
    avatar,
    cleanError,
    setRequiredData,
    errorField,
    genderObject,
    setGenderObject,
    isChilean,
    isColombian,
    documentoDeIdentidad,
    setDocumentoDeIdentidad,
    countryId,
    fiscalConditionObject,
    setFiscalConditionObject,
    directions,
    addDirection,
    isArgentine,
    isUruguayan,
    profile,
    setProfile,
    loadingData,
    handleRequest,
    setErrorField,
    handleDeleteDirection,
    handleSaveDirection,
  } = useCreateDentist()

  const {
    handleGetCUIT,
    handleGetCondicionFiscal,
    getCondicionFiscalData,
    getMatriculaNacional,
    getMatriculaProvincial,
  } = useCountryInputs(countryId)

  if (!requiredData) return <Loader />

  return (
    <div className="container mt-4 px-5">
      <div className="row">
        <div className="col-10 d-flex flex-row justify-content-between">
          <h1 style={{ fontWeight: 701 }} className="text-primary">
            Nuevo Odontólogo
          </h1>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-8">
          <div className="row">
            <div
              className="col-4"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                style={{
                  width: 0,
                }}
                type="file"
                name="avatar"
                onChange={(e) => {
                  setAvatar(e.target.files[0])
                  getBase64(e.target.files[0], (res) =>
                    setPersonalData({
                      ...personalData,
                      profilePhoto: res,
                    })
                  )
                }}
                id="od_profile_pic_input"
              />
              <label htmlFor="od_profile_pic_input">
                {avatar ? (
                  <img
                    style={{
                      cursor: 'pointer',
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      maxWidth: '200px',
                      maxHeight: '200px',
                      objectFit: 'cover',
                    }}
                    src={personalData.profilePhoto}
                    alt="Foto de perfil"
                  />
                ) : (
                  <div
                    className="flex"
                    style={{
                      cursor: 'pointer',
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      paddingTop: 20,
                      paddingBottom: 20,
                      paddingRight: 40,
                      paddingLeft: 40,
                    }}
                  >
                    <User size={124} color="#ccc" />
                    <p className="text-primary text-center">Avatar</p>
                  </div>
                )}
              </label>
            </div>

            <div className="col-8 px-2">
              <h5 className="fw-bold">Datos personales</h5>
              <div className="row mb-4 mt-2">
                <div className="col-6">
                  <Input
                    label="Nombre"
                    type="text"
                    id="name"
                    name="name"
                    value={requiredData?.name}
                    onChange={(e) => {
                      cleanError('name')
                      setRequiredData({
                        ...requiredData,
                        name: e.target.value,
                      })
                    }}
                  />
                  {errorField.name && (
                    <p className="text-danger fw-light">{errorField.name}</p>
                  )}
                </div>
                <div className="col-6">
                  <Input
                    label="Apellido"
                    type="text"
                    id="surname"
                    name="surname"
                    value={requiredData?.surname}
                    onChange={(e) => {
                      cleanError('surname')
                      setRequiredData({
                        ...requiredData,
                        surname: e.target.value,
                      })
                    }}
                  />
                  {errorField.surname && (
                    <p className="text-danger fw-light">{errorField.surname}</p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="gender">Género</label>
                  <Select
                    styles={selectStyleData}
                    options={gendersData}
                    placeholder="Seleccione el género..."
                    value={genderObject}
                    onChange={(e) => {
                      cleanError('gender')
                      setGenderObject(e)
                      setRequiredData({
                        ...requiredData,
                        gender: e.value,
                      })
                    }}
                  />
                  {errorField.gender && (
                    <p className="text-danger fw-light mt-2">
                      {errorField.gender}
                    </p>
                  )}
                </div>
                <div className="col-6">
                  <Input
                    label="Fecha de nacimiento"
                    type="date"
                    id="dateOfBirth"
                    value={requiredData?.birthdate}
                    onChange={(e) =>
                      setRequiredData({
                        ...requiredData,
                        birthdate: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 mt-4">
            <div className="col-6">
              <Input
                label="Email"
                type="email"
                value={requiredData?.email}
                onChange={(e) => {
                  cleanError('email')
                  setRequiredData({
                    ...requiredData,
                    email: e.target.value,
                  })
                }}
              />
              {errorField.email && (
                <p className="text-danger fw-light">{errorField.email}</p>
              )}
            </div>
            <div className="col-6">
              <Input
                label="Teléfono"
                type="number"
                value={personalData.phone_number}
                onChange={(e) => {
                  if (e.target.value.length < 25)
                    setPersonalData({
                      ...personalData,
                      phone_number: e.target.value,
                    })
                }}
              />
              {errorField.phone_number && (
                <p className="text-danger fw-light">
                  {errorField.phone_number}
                </p>
              )}
            </div>
          </div>
          <hr />
          <div className="row mb-4">
            {isColombian ? (
              <div className="d-flex col-6 flex-column justify-content-center">
                <label>Documento de identidad</label>
                <div className="d-flex ">
                  <div className="col-4 ">
                    <Select
                      styles={selectStyleData}
                      placeholder="Seleccione..."
                      options={documentoDeIdentidadCol}
                      value={documentoDeIdentidad}
                      onChange={(e) => {
                        setDocumentoDeIdentidad(e)
                        setPersonalData({
                          ...personalData,
                          cuit_prefix: e.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col-8">
                    <input
                      type="number"
                      className={styles.inputDocumentoIdentidad}
                      id="cuit_col"
                      value={personalData.cuit}
                      onChange={(e) => {
                        cleanError('cuit')
                        setPersonalData({
                          ...personalData,
                          cuit: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                {errorField.cuit && (
                  <p className="text-danger fw-light">{errorField.cuit}</p>
                )}
              </div>
            ) : (
              <div className="col-6">
                <Input
                  label={handleGetCUIT(countryId)}
                  type={isChilean ? 'text' : 'number'}
                  id="cuit"
                  value={personalData.cuit}
                  onChange={(e) => {
                    cleanError('cuit')
                    setPersonalData({
                      ...personalData,
                      cuit: e.target.value,
                    })
                  }}
                />
                {errorField.cuit && (
                  <p className="text-danger fw-light">{errorField.cuit}</p>
                )}
              </div>
            )}
            <div className="col-6">
              <label htmlFor="fiscal_condition">
                {handleGetCondicionFiscal()}
              </label>
              <Select
                styles={selectStyleData}
                options={getCondicionFiscalData()}
                placeholder="Seleccione..."
                value={fiscalConditionObject}
                onChange={(e) => {
                  setFiscalConditionObject(e)
                  setPersonalData({
                    ...personalData,

                    fiscal_condition: e.value,
                  })
                }}
              />
            </div>
          </div>
          {!isColombian && (
            <div className="row mb-4">
              <div className="col-6">
                <Input
                  label={getMatriculaNacional()}
                  type="number"
                  value={personalData.national_enrollment}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      cleanError('national_enrollment')
                    setPersonalData({
                      ...personalData,
                      national_enrollment: e.target.value,
                    })
                  }}
                />
                {errorField.national_enrollment && (
                  <p className="text-danger fw-light">
                    {errorField.national_enrollment}
                  </p>
                )}
              </div>
              <div className="col-6">
                <Input
                  label={getMatriculaProvincial()}
                  type="number"
                  value={personalData.provintial_enrollment}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      cleanError('provintial_enrollment')
                    setPersonalData({
                      ...personalData,
                      provintial_enrollment: e.target.value,
                    })
                  }}
                />
                {errorField.provintial_enrollment && (
                  <p className="text-danger fw-light">
                    {errorField.provintial_enrollment}
                  </p>
                )}
              </div>
            </div>
          )}
          <hr />
          <h5 className="fw-bold">Dirección</h5>

          {directions.map((direction) => (
            <CreateDirections
              direction={direction}
              key={direction.direction_name}
              usePlacesAutocomplete={usePlacesAutocomplete}
              getGeocode={getGeocode}
              getLatLng={getLatLng}
              handleDeleteDirection={handleDeleteDirection}
              handleSaveDirection={handleSaveDirection}
            />
          ))}
          <div className="w-100 d-flex justify-content-end pe-4">
            <button
              type="button"
              className={directionStyle.addDirection}
              onClick={addDirection}
            >
              Agregar dirección
            </button>
          </div>
          <hr />
          <h5 className="fw-bold">Curso de acreditación</h5>
          <div className="row mb-4 mt-3">
            <div className="col-6">
              <label htmlFor="fecha_curso">
                Fecha en la que se realizó el curso
              </label>
              <input
                type="date"
                className="form-control"
                id="fecha_curso"
                value={personalData.accreditation_course}
                onChange={(e) => {
                  cleanError('accreditation_course')
                  setPersonalData({
                    ...personalData,
                    accreditation_course: e.target.value,
                  })
                }}
              />
              {errorField.accreditation_course && (
                <p className="text-danger fw-light">
                  {errorField.accreditation_course}
                </p>
              )}
            </div>
          </div>
          <hr />
          <h5 className="fw-bold">Redes sociales</h5>
          <div className="row mb-4 mt-3">
            <div className="col-12">
              <label htmlFor="facebook">Facebook</label>
              <input
                type="text"
                className="form-control"
                id="facebook"
                value={personalData.facebook}
                onChange={(e) =>
                  setPersonalData({
                    ...personalData,
                    facebook: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-12">
              <label htmlFor="instagram">Instagram</label>
              <input
                type="text"
                className="form-control"
                id="instagram"
                value={personalData.instagram}
                onChange={(e) =>
                  setPersonalData({
                    ...personalData,
                    instagram: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-12">
              <label htmlFor="twitter">Twitter</label>
              <input
                type="text"
                className="form-control"
                id="twitter"
                value={personalData.twitter}
                onChange={(e) =>
                  setPersonalData({
                    ...personalData,
                    twitter: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-12">
              <label htmlFor="web">Web personal</label>
              <input
                type="text"
                className="form-control"
                id="web"
                value={personalData.web}
                onChange={(e) =>
                  setPersonalData({
                    ...personalData,
                    web: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        {(isArgentine || isUruguayan) && (
          <Profile
            cleanError={cleanError}
            profile={profile}
            setProfile={setProfile}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        )}
      </div>
      <div className="row">
        <hr />
        <div className="col-12 d-flex flex-row justify-content-end">
          <button
            disabled={loadingData ? true : false}
            className="btn btn-primary"
            onClick={handleRequest}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateDentist
