import { useEffect, useState } from 'react'
import trashIcon from '../../../../assets/icons/trash.png'
import './styles.css'

const ProposalItem = ({
  status,
  index,
  id,
  url,
  loading,
  deleteAction,
  setTreatmentProposal,
  treatmentProposal,
  setWasEdited,
  setDisabled,
  isCreated,
}) => {
  const [value, setValue] = useState(url ?? null)

  const handleChange = ({ target: { value } }) => {
    setValue(value)
    setDisabled(false)
    setWasEdited(true)

    const treatmentsProposals = treatmentProposal.map((proposal) =>
      proposal.id === id
        ? {
            ...proposal,
            isEdited: isCreated ? false : true,
            url: value,
          }
        : { ...proposal }
    )
    setTreatmentProposal(treatmentsProposals)
  }

  const deleteCreated = (indexToDelete) => {
    let newPlanFiles = treatmentProposal
    newPlanFiles.splice(indexToDelete, 1)
    setTreatmentProposal([...newPlanFiles])
  }

  useEffect(() => {
    setValue(url)
  }, [url])

  return (
    <div className="d-flex align-items-center mt-3">
      <p className="my-0 fw-bold proposal-tag text-primary ">
        {index + 1}° Propuesta
      </p>
      <input
        disabled={status === 'approved'}
        className="RO-input-id w-75"
        type="text"
        value={value}
        onChange={handleChange}
      />
      {!isCreated && status !== 'approved' && (
        <button
          className={`proposal-button ${!!loading && 'disabled'}`}
          onClick={() => deleteAction({ proposalId: id })}
          disabled={loading}
        >
          <img src={trashIcon} alt="delete" />
        </button>
      )}

      {isCreated && (
        <button
          className={`proposal-button ${!!loading && 'disabled'}`}
          onClick={() => deleteCreated(index)}
          disabled={loading}
        >
          <img src={trashIcon} alt="delete" />
        </button>
      )}
    </div>
  )
}

export default ProposalItem
