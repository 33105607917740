import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../../../utils/backend'
import { baseToFile } from '../../../../../../utils/baseToFile'
import { DentistMedicalRecordContext } from '../context/DentistMedicalRecordContext'
import {
  createNotificationTypeDocumentation,
  isStudyFile,
} from '../utilities/helpers'

const useDocumentation = () => {
  const {
    informationState,
    setInformationState,
    documentationState,
    originalRegister,
    treatmentId,
    loadData,
  } = useContext(DentistMedicalRecordContext)
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const [section, setSection] = useState('photos')
  const [documentsToSave, setDocumentsToSave] = useState([])
  const [planFiles, setPlanFiles] = useState([])
  const [othPhotos, setOthPhotos] = useState([])
  const [othStudies, setOthStudies] = useState([])
  const [loading, setLoading] = useState(false)
  const [backendFiles, setBackendFiles] = useState({
    photos: [],
    otherPhotos: [],
    studies: [],
    otherStudies: [],
    plan: [],
    formPlan: [],
  })

  const displaySaveBtn = () => {
    if (section === 'photos' || section === 'studies' || section === 'plan') {
      if (section === 'plan' && documentationState.plan.online_form) return
      if (
        documentsToSave.length ||
        planFiles.length ||
        othPhotos.length ||
        othStudies.length
      )
        return (
          <button
            onClick={() => saveLoadedPhotos()}
            className="saveBtn shadow"
            disabled={loading}
          >
            Guardar cambios
          </button>
        )
    }
  }

  const displaySaveChangesText = () => {
    if (
      documentsToSave.length ||
      planFiles.length ||
      othPhotos.length ||
      othStudies.length
    ) {
      if (pageWidth === 'desktop')
        return (
          <div className="my-2 fw-bold text-yellow">
            Tiene cambios sin guardar
          </div>
        )
      else
        return (
          <>
            <div className="my-2  fw-bold text-yellow">
              Tiene cambios sin guardar
            </div>
          </>
        )
    }
  }

  const saveLoadedPhotos = async () => {
    setLoading(true)
    createNotificationTypeDocumentation(treatmentId)
    //other photos
    await Promise.all(
      othPhotos.map(async (item) => {
        const file = baseToFile(item.file, `.jpg`)
        let otherPhotos = new FormData()
        otherPhotos.append('image', file)
        otherPhotos.append('name', `Otros-${Math.ceil(Math.random() * 1000)}`)
        otherPhotos.append('type', `image`)
        try {
          await apiClient.post(`api/incomes/${treatmentId}/others`, otherPhotos)
        } catch (err) {
          console.log('ERROR EN  photos oth', err)
        }
      })
    )
    // other studies
    await Promise.all(
      othStudies.map(async (item) => {
        const file = baseToFile(item.file, `.jpg`)
        let otherStudies = new FormData()
        otherStudies.append('image', file)
        otherStudies.append('name', `Otros-${Math.ceil(Math.random() * 1000)}`)
        otherStudies.append('type', `study`)
        try {
          await apiClient.post(
            `api/incomes/${treatmentId}/others`,
            otherStudies
          )
        } catch (err) {
          console.log('ERROR EN  STUDIES oth', err)
        }
      })
    )
    //  photos/studies
    await Promise.all(
      documentsToSave.map(async ({ image, id }) => {
        const isStudy = isStudyFile(id)
        let url = `api/medicalRecordDentist/${treatmentId}/images`
        let imageFile = baseToFile(image, id + '.jpg')
        let dataImage = new FormData()
        dataImage.append(isStudy ? 'study' : 'image', imageFile)
        dataImage.append('name', id)
        if (isStudy) url = `api/incomes/${treatmentId}/studies`
        try {
          await apiClient.post(url, dataImage)
        } catch (err) {
          console.log('ERROR EN PHOTOS O STUDIES', err)
        }
      })
    )
    // plan
    await Promise.all(
      planFiles.map(async (item, index) => {
        let file
        // Validate if is PDF or IMG
        if (item.type === 'pdf') file = item.file
        else file = baseToFile(item.file, 'plan.jpg')
        //Normalize Data
        let dataStepFour = new FormData()
        dataStepFour.append('plan', file)
        dataStepFour.append('id_file', index)
        //Send plan file
        try {
          await apiClient.post(`api/incomes/${treatmentId}/plan`, dataStepFour)
        } catch (err) {
          console.log('ERROR EN PHOTOS O STUDIES', err)
        }
      })
    )
    setDocumentsToSave([])
    setPlanFiles([])
    setOthPhotos([])
    setOthStudies([])
    loadData()
  }

  const loadImage = (image, id) => {
    let mutationArray = []
    documentsToSave.forEach((item) => {
      if (item.id !== id) mutationArray.push(item)
    })
    mutationArray = [...mutationArray, { image: image, id: id }]
    setDocumentsToSave(mutationArray)
  }

  const deleteImage = (id) => {
    let mutationArray = []
    documentsToSave.forEach((item) => {
      if (item.id !== id) mutationArray.push(item)
    })
    setDocumentsToSave(mutationArray)
  }

  useEffect(() => {
    if (!documentationState) return
    setLoading(true)
    let photos = []
    let otherPhotos = []
    let studies = []
    let otherStudies = []
    let plan = []

    documentationState.documentation.forEach((item) => {
      item.type === 1 && (photos = [...photos, ...item.files])
      item.type === 5 && (otherPhotos = [...otherPhotos, ...item.files])
      item.type === 2 && (studies = [...studies, ...item.files])
      item.type === 6 && (otherStudies = [...otherStudies, ...item.files])
      item.type === 3 && (plan = [...plan, ...item.files])
    })

    setBackendFiles({
      photos,
      otherPhotos,
      studies,
      otherStudies,
      plan,
    })
    setTimeout(() => {
      setLoading(false)
    }, 2800)
  }, [documentationState])

  return {
    loading,
    informationState,
    setInformationState,
    documentationState,
    originalRegister,
    treatmentId,
    loadData,
    section,
    documentsToSave,
    planFiles,
    backendFiles,
    othPhotos,
    othStudies,
    displaySaveBtn,
    displaySaveChangesText,
    loadImage,
    deleteImage,
    setSection,
    setDocumentsToSave,
    setPlanFiles,
    setOthPhotos,
    setOthStudies,
  }
}

export default useDocumentation
