import React from 'react'

const TextAreaCounter = ({ value, maxValue, error = '' }) => {
  return (
    <div
      className={`w-100 d-flex justify-content-${
        error ? 'between' : 'end'
      } align-items-center mt-2 ${value.length >= maxValue && 'text-red'}`}
    >
      {error && (
        <div className="alert-danger alert-link p-2 mt-1 w-50">
          {error.message}
        </div>
      )}
      <div style={{ height: '32px' }}>
        {value.length >= maxValue && (
          <span className="text-red mb-0 me-2">
            ¡Cantidad límite de caracteres alcanzados!
          </span>
        )}
        <span>
          {value.length}/{maxValue}
        </span>
      </div>
    </div>
  )
}

export default TextAreaCounter
