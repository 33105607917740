import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import { extractions_options } from '../Utilities/OptionsRadios'
import RadioList from '../Utilities/RadioList'
import ToothsSelect from '../Utilities/ToothsSelector'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from '../../propsStateConnect'
function Extractions(props) {
  type_dispatch.step = 'PretratmentDiagnosisValues'
  type_dispatch.field = 'Extractions'

  const { colors, updateState, updateErrors } = props
  const extractions_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  useEffect(() => {
    if (extractions_state.extraction) {
      updateErrors([])
    }
  }, [extractions_state.extraction, updateErrors])
  return (
    <div>
      <div
        className="w-100 fw-bold pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Extracciones
      </div>
      {error1 && (
        <div className="alert-danger alert-link p-2 mt-1">{error1.message}</div>
      )}
      <RadioList
        enabled={props.formOnlineReducer.Enabled}
        colors={colors}
        radios={extractions_options}
        setter={(value) => updateState({ key: 'extraction', value })}
        stateField={extractions_state.extraction}
        name="extractions"
      />
      {extractions_state.extraction === 3 && (
        <div>
          <div className="small text-muted mt-2">
            (Indique implantes, puentes, dientes que no quiera extraer)
          </div>
          <div className="mt-5">
            <ToothsSelect
              enabled={props.formOnlineReducer.Enabled}
              step={type_dispatch.step}
              field={type_dispatch.field}
              tooths_state={
                props.formOnlineReducer.PretratmentDiagnosisValues[
                  type_dispatch.field
                ]
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Extractions)
