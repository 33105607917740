import React, { useState, useEffect } from 'react'
import styles from '../styles/stepFive.module.css'

export const TreatmentStepFive = ({ state, setState }) => {
  const [isMexico, setIsMexico] = useState(false)
  const [method, setMethod] = useState({
    weTransfer: false,
    shape: false,
  })
  const todayDate = new Date().toISOString().slice(0, 10)

  useEffect(() => {
    if (localStorage.getItem('country_id') == '7') {
      setIsMexico(true)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setState({
        ...state,
        isWeTransfer: method.weTransfer,
        is3Shape: method.shape,
      })
    }, 100)
  }, [method])

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className=" mt-2 mx-3">
        <p className={styles.alertText}>
          KeepSmiling no dará comienzo al tratamiento hasta no recibir el
          registro de su paciente.
        </p>
        <div className="d-flex flex-wrap justify-content-center gap-3">
          <div
            onClick={() =>
              setState({
                ...state,
                intraoral: 1,
                intraoral_file: true,
                sendImpressions: {},
                newIntraoralScanDate: todayDate,
              })
            }
            className={
              state.intraoral === 1
                ? styles.escaneoIntraoralBTNSelected
                : styles.escaneoIntraoralBTN
            }
          >
            {state.intraoral === 1 ? (
              <div>
                <p className={styles.cardTitle}>Escaneo Intraoral</p>
                <div className={styles.cardContent}>
                  <p className="fs-5 px-2 fw-bold">
                    Seleccione el medio por el que va a enviar el registro:
                  </p>
                  <div>
                    <div
                      className={`${styles.optionsBTN} ${
                        state.isWeTransfer ? 'fw-bold' : ''
                      }`}
                      onClick={() =>
                        setMethod({
                          weTransfer: true,
                          shape: false,
                        })
                      }
                      href="https://keepsmiling-escaneos.wetransfer.com/"
                      target="_blank"
                    >
                      We Transfer
                    </div>
                    <p
                      className={`${styles.optionsBTN} ${
                        state.is3Shape ? 'fw-bold' : ''
                      }`}
                      onClick={() =>
                        setMethod({
                          shape: true,
                          weTransfer: false,
                        })
                      }
                    >
                      3Shape Communicate
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <p className="fs-4 fw-bold">Escaneo Intraoral</p>
                <p className="mt-4">
                  KeepSmiling puede recibir el archivo 3D a través de 3Shape
                  Communicate, We Transfer y email.
                </p>
              </>
            )}
          </div>

          {!isMexico && (
            <div
              onClick={() => {
                setState({
                  ...state,
                  intraoral: 0,
                  intraoral_file: false,
                  newIntraoralScanDate: todayDate,
                  isWeTransfer: false,
                  is3Shape: false,
                })
              }}
              className={
                state.intraoral === 0
                  ? styles.envioDeImpresionesBTNSelected
                  : styles.envioDeImpresionesBTN
              }
            >
              {state.intraoral === 0 ? (
                <p className={styles.cardTitle}>Envío de Impresiones</p>
              ) : (
                <>
                  <p className="fs-4 fw-bold">Envío de Impresiones</p>
                  <p className="mt-4">
                    El equipo de logística coordinará para el envío o retiro de
                    impresiones.
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
