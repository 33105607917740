import React from 'react'
import Loader from '../../../components/loader'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../../components/Paginator'
import Searchbar from '../../../components/searchbar'
import '../index.css'
import DispatchCard from './components/DispatchCard'
import useDispatchControl from './hooks/useDispatchControl'

const Dispatch = () => {
  const { state, setState, getInitialData, paginatorHandler, countryId } =
    useDispatchControl()

  if (state.loading) return <Loader />
  return (
    <div className="p-4">
      <div className="mb-4">
        <p className="fs-2 fw-bold text-primary">Despachos</p>
      </div>
      <div className="d-flex justify-content-end">
        {/* // implement filter width country ID on URL*/}
        <Searchbar
          initialQueryType="treatment"
          state={state}
          setState={setState}
          page={'dispatchControl'}
          url={`api/treatments?filter[status]=9&filter[statusNot]=12&page=1&`}
        />
      </div>
      <hr className="mt-5" />
      <div className="ms-4 d-flex">
        <div className="mx-2 id">
          <p className="fw-bold text-primary my-0">Id</p>
        </div>
        <div className="patient">
          <p className="fw-bold text-primary my-0">Paciente</p>
        </div>
        <div className="doctor">
          <p className="fw-bold text-primary my-0">Doctor</p>
        </div>
        <div style={{ width: '100px', textAlign: 'center' }}>
          <p className="fw-bold text-primary my-0">Tratamiento</p>
        </div>
        <div style={{ width: '55px', textAlign: 'center' }}>
          <p className="fw-bold text-primary my-0">Etapa</p>
        </div>
        <div className="maxillar">
          <p className="fw-bold text-primary my-0">Maxilar</p>
        </div>
        <div style={{ width: '105px', textAlign: 'center' }}>
          <p className="fw-bold text-primary my-0">Movimiento</p>
        </div>
        <div>
          <p className="fw-bold text-primary my-0">Propuesta de tratamiento</p>
        </div>
      </div>
      {!state.treatments.length && <NotFoundComponent />}
      {state.treatments.map((item) => (
        <DispatchCard
          key={item.id}
          data={item}
          getInitialData={getInitialData}
        />
      ))}
      <Paginator paginator={state.paginator} getPaginator={paginatorHandler} />
    </div>
  )
}

export default Dispatch
