import React from 'react'
import { useSelector } from 'react-redux'
import MobileIndex from './MobileIndex'
import DesktopIndex from './DesktopIndex'

const DentistPatientIndex = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  if (pageWidth === 'desktop') return <DesktopIndex />
  else return <MobileIndex />
}

export default DentistPatientIndex
