import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import apiClient from "../../../utils/backend";
import { useHistory } from "react-router";
import { Check } from "react-feather";

const RejectModal = (props) => {
  const { className, id, opened, handleModal } = props;

  const [obs, setObs] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleApprove() {
    setLoading(true);

    apiClient
      .post(`/api/status/nextStatus`, {
        treatment_id: id,
        process: "Aprobacion",
        observation: obs,
      })
      .then((res) => {
        if (res.status === 200) {
          history.go(0);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <Modal isOpen={opened} toggle={handleModal} className={className}>
        <ModalBody>
          <div className="container">
            <h2 className="mb-3 fw-bold text-danger text-center">
              Rechazar tratamiento
            </h2>
            <div className="row">
              <div className="col-12 text-center mt-2 mb-2">
                <p className="fw-bold">¿POR QUÉ NO ESTÁ CONFORME?</p>
              </div>
              <div
                className="col-12 px-3 mt-2 text-center"
                style={{ lineHeight: "1.2em" }}
              >
                <p>
                  A continuación detalle los cambios requeridos de la propuesta
                  de tratamiento:
                </p>
              </div>
              <div className="col-12 px-3 mt-2 d-flex flex-column justify-content-center">
                <textarea
                  onChange={(e) => setObs(e.target.value)}
                  name=""
                  id=""
                  cols="50"
                  rows="6"
                ></textarea>
                {obs !== "" && (
                  <button className="btn bg-none text-end">
                    <Check size="24" color="#444" />
                  </button>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button
                  className="btn btn-success text-white w-100 rounded-pill px-5"
                  onClick={handleApprove}
                >
                  ENVIAR
                </button>
                <button
                  className="btn btn-danger text-white w-100 rounded-pill px-5 mt-2"
                  onClick={handleModal}
                >
                  NO AHORA
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RejectModal;
