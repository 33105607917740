import React, { useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import {
  mantain_keep_not_apply_options,
  crossBiteList,
} from '../Utilities/OptionsRadios'
import TextAreaCounter from '../Utilities/TextAreaCounter'
import CheckBoxList from '../Utilities/CheckBoxList'

function CrossBite(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'CrossBite'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]

  const crossBite = middle_state.crossBite
  const crossBiteSelected = middle_state.improveCrossBite

  const othersCrossBiteRef = useRef(null)

  const updateResources = (val, chk) => {
    let updatedArray = []
    if (chk) {
      updatedArray = [...middle_state.improveCrossBite, val]
    } else {
      updatedArray = middle_state.improveCrossBite.filter((itm) => itm !== val)
    }
    updateState({ key: 'improveCrossBite', value: updatedArray })
  }

  useEffect(() => {
    if (crossBite === 1) {
      updateState({ key: 'improveCrossBite', value: [] })
    }
  }, [crossBite, updateState])

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  // useErrorList(errors_list, updateErrors, [crossBite])

  useEffect(() => {
    if (errors_list.find((err) => err.order === 1)) {
      updateErrors(errors_list.filter((err) => err.order === 2))
    }
    if (errors_list.find((err) => err.order === 2)) {
      updateErrors(errors_list.filter((err) => err.order === 1))
    }
  }, [middle_state])

  return (
    <div>
      <div
        className="w-100 fw-bolder pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Mordida Cruzada Posterior
      </div>
      <div className="mt-3 ms-3">
        <div
          className="fw-bolder small pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Mordida cruzada
        </div>
        {error1 && (
          <div className="alert-danger alert-link p-2 mt-1">
            {error1.message}
          </div>
        )}
        <RadioList
          enabled={props.formOnlineReducer.Enabled}
          colors={colors}
          radios={mantain_keep_not_apply_options}
          setter={(value) => updateState({ key: 'crossBite', value })}
          stateField={crossBite}
          name="crossBite"
        />
        {crossBite === 2 && (
          <div>
            <div
              className="ms-2 mt-3 fw-bolder small pb-1"
              style={{ borderBottom: `1px solid ${colors.color}` }}
            >
              ¿Cómo desea mejorar la mordida cruzada?
            </div>

            <CheckBoxList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={crossBiteList}
              setter={(value, checked) => updateResources(value, checked)}
              stateField={crossBiteSelected}
              name="improveCrossBite"
            />
            {crossBiteSelected.includes(5) && (
              <div className="mt-3 ms-4">
                <small>Otras:</small>
                <textarea
                  disabled={!props.formOnlineReducer.Enabled}
                  onChange={(e) => {
                    updateState({
                      key: 'improveCrossBiteOthers',
                      value: e.target.value,
                    })
                  }}
                  value={middle_state.improveCrossBiteOthers}
                  ref={othersCrossBiteRef}
                  className="form-control mt-1"
                  name="observacion"
                  id="observacion"
                  cols="30"
                  rows="3"
                ></textarea>
                <TextAreaCounter
                  value={middle_state.improveCrossBiteOthers}
                  maxValue={2500}
                  error={error2}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(CrossBite)
