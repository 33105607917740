import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import apiClient from '../../../utils/backend'

const DentistNotificationsList = ({ active, dentistId, displayRedTag }) => {
  const [state, setState] = useState()
  const history = useHistory()

  const displayType = (motivoId) => {
    if (motivoId === 1) return 'Su paciente fue dado de alta'
    if (motivoId === 2) return 'Su paciente fue dado de alta'
    if (motivoId === 3) return 'Nueva Propuesta de Tratamiento'
    if (motivoId === 4) return 'En Producción'
    if (motivoId === 5) return 'Entrega de Alineadores'
    if (motivoId === 6) return 'Reclamo de Documentación'
    if (motivoId === 7) return 'Reclamo de Propuesta pendiente de revisión'
  }

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const res = await apiClient(
          `api/dentists/${dentistId}/notifications?cant_pages=10&page=1&include=treatment.dentists`
        )
        setState(res.data.table)

        let pendingNotification = res.data.table.find(
          (element) => !element.read
        )

        if (pendingNotification) return displayRedTag(true)
        else displayRedTag(false)
      } catch (error) {
        console.log(error)
      }
    }

    getNotifications()
  }, [dentistId, active, displayRedTag])

  if (!state || state.length === 0)
    return (
      <div className="d-flex">
        <div className="d-flex align-items-center justify-content-center m-auto fw-bold fs-5 text-primary">
          No hay nuevas notificaciones
        </div>
      </div>
    )

  return (
    <div className="px-2">
      {state.map((item, i) => {
        return (
          <div
            key={i}
            className="DD-notification-card cursor-pointer "
            onClick={() => {
              history.push('/od/notificaciones')
            }}
          >
            <div className="d-flex align-items-center">
              <div
                className={item.read ? 'DD-circle-green' : 'DD-circle-yellow'}
              ></div>
              {item?.treatment?.frontal_sonriendo ? (
                <img
                  alt="avatar"
                  className="DD-avatar"
                  src={item?.treatment?.frontal_sonriendo}
                />
              ) : (
                <div className="NT-avatar"></div>
              )}
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center justify-content-between">
                <p
                  className="my-0 fs-7 DD-user-name text-truncate"
                  title={`${item?.treatment?.patient.name} ${item?.treatment?.patient.surname}`}
                >
                  {item?.treatment?.patient.name +
                    ' ' +
                    item?.treatment?.patient.surname}
                </p>
                <p style={{ fontSize: 10 }} className="disabled my-0">
                  {moment.utc(item?.created_at).format('DD-MM-YYYY')}
                </p>
              </div>
              <div className="my-1 fw-bold">{displayType(item.type)}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default DentistNotificationsList
