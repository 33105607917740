import React from 'react'
import moment from 'moment'

import frontal_sonriendo from '../../../assets/images/frontalsonriendo.png'
import CHIsTreatment from './Documentation/components/CHIsTreatment'
import CHIsApplicationRegistro from './Documentation/components/CHIsApplicationRegistro'
import CHIsApplicationMaxilar from './Documentation/components/CHIsApplicationMaxilar'

const ClinicHistoryHeader = ({ propsToClinicHistoryHeader }) => {
  const {
    data,
    dataImage,
    displayDoctorName,
    isTreatment,
    editRegistrationDate,
    handleSetRegistrarionDate,
    setEditRegistrationDate,
    isApplication,
    handleChangeRegistro,
    handleChangeMaxilar,
    externalValue,
    setExternalValue,
    handleApproveTreatment,
  } = propsToClinicHistoryHeader

  return (
    <div className="d-flex justify-content-between mt-4">
      <div className="d-flex ">
        <img
          className="CH-image-container"
          alt=""
          src={dataImage || frontal_sonriendo}
        ></img>
        <div className="ps-3 py-2 d-flex flex-column justify-content-between">
          <div className="d-flex align-items-center ">
            <p
              className="h3 my-0 fw-bold text-primary text-truncate"
              style={{ maxWidth: 400 }}
            >
              {`${data.patient.name} ${data.patient.surname}`}
            </p>
            {data.type && (
              <p
                className={`type-tag type-${data.type?.id} my-0 px-3 py-2 ms-3 `}
              >
                {data?.type?.id === 4
                  ? 'Kids Full'
                  : `${data.type.name.toUpperCase()}`}
              </p>
            )}
          </div>
          <div className="d-flex align-items-top flex-wrap pt-2">
            <div className="me-3">
              <small className="CH-small">Doctor:</small>
              <p
                className="my-1 fw-bold text-truncate"
                style={{ maxWidth: 200 }}
              >
                {displayDoctorName()}
              </p>
            </div>
            <div className="me-3">
              <small className="CH-small">Fecha de Ingreso:</small>
              {isTreatment ? (
                <CHIsTreatment
                  editRegistrationDate={editRegistrationDate}
                  data={data}
                  handleSetRegistrarionDate={handleSetRegistrarionDate}
                  setEditRegistrationDate={setEditRegistrationDate}
                />
              ) : (
                <p className="my-1 fw-bold">
                  {moment(data.registration_date.split(' ')[0]).format(
                    'DD-MM-YYYY'
                  )}
                </p>
              )}
            </div>
            {isApplication && (
              <CHIsApplicationRegistro
                handleChangeRegistro={handleChangeRegistro}
                data={data}
              />
            )}
            <div className="me-3">
              <small className="CH-small">Maxilar:</small>
              <p className="fw-bold ">
                {isApplication ? (
                  <CHIsApplicationMaxilar
                    handleChangeMaxilar={handleChangeMaxilar}
                    data={data}
                  />
                ) : (
                  <p className="my-1">{data?.maxilla}</p>
                )}
              </p>
            </div>
            <p className="text-primary fw-bold CH-stage-tag CH-blueKS my-1 px-3 py-1">
              ETAPA {data.order}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p
          className={`h4 my-1 fw-bold ${
            isApplication || isTreatment ? '' : 'pe-5'
          }`}
        >
          <span className="h4 my-0 fw-bold text-primary">ID:</span>
          {isApplication || isTreatment ? (
            <input
              maxLength={15}
              type="text"
              className="idInput ms-2 ps-2"
              value={externalValue}
              onChange={(e) => setExternalValue(e.target.value)}
            />
          ) : (
            data.external_id
          )}
        </p>
        {(isApplication || isTreatment) && (
          <button
            disabled={externalValue.length ? false : true}
            onClick={() => handleApproveTreatment()}
            className={`darDeAltaBTN mt-2 ${
              externalValue.length ? null : 'disabled'
            }`}
          >
            {isApplication ? 'Dar de alta' : 'Cambiar ID'}
          </button>
        )}
      </div>
    </div>
  )
}

export default ClinicHistoryHeader
