import React, { useState, useEffect } from 'react'
import { ChevronLeft } from 'react-feather'
import { StepBar } from './components/StepBar'
import { type_dispatch } from '../../FormularioOnline/propsStateConnect'
import { TreatmentAdminStepOne } from './Stages/TreatmentAdminStepOne'
import { TreatmentAdminStepTwo } from '../components/TreatmentAdminStepTwo'
import { TreatmentAdminStepThree } from '../components/TreatmentAdminStepThree'
import { TreatmentAdminStepFour } from './Stages/TreatmentAdminStepFour'
import { TreatmentAdminStepFive } from './Stages/TreatmentAdminStepFive'
import { TreatmentAdminStepSix } from './Stages/TreatmentAdminStepSix'
import frontalsonriendo from '../../../assets/images/frontalsonriendo.png'
import Loader from '../../../components/loader'
import handleToast from '../../../components/toaster'

const LoadTreatmentAdmin = () => {
  const [stageOneFinish, setStageOneFinish] = useState(false)
  const [stageTwoFinish, setStageTwoFinish] = useState(false)
  const [stageThreeFinish, setStageThreeFinish] = useState(false)
  const [stageFourFinish, setStageFourFinish] = useState(false)
  const [stageFiveFinish, setStageFiveFinish] = useState(false)
  const [planFiles, setPlanFiles] = useState([])
  const [photosFiles, setPhotosFiles] = useState([])
  const [studiesFiles, setStudiesFiles] = useState([])
  const [state, setState] = useState({
    numPages: null,
    pageNumber: 1,
    treatment_id: null,
    plantratamiento: null,
    step: 1,
    loading: false,
    uploaded: false,

    gender_string: null,
    od_list: null,
    nombre: null,
    apellido: null,
    external_id: null,
    genero: null,
    gender_select: null,
    maxilla: null,
    maxilar: 0,
    maxilar_string: '',
    odontologo: null,
    dentist_id: null,
    treatment_type: 1,
    intraoral: 2,
    etapa: 1,
    is_new_patient: true,
    od_patients: [],
    selected_patient: null,
    selected_od: null,
    intraoral_file: false,
    online_plan: false,
    image_plan: false,
    dentistOffices: null,
    newIntraoralScanDate: null,
    sendImpressions: {},

    fotos: {},
    fotos_files: [],
    estudios: {},
    estudios_files: [],
    plan: {},

    registration_datetime: null,
    scan_file: null,

    // STEP DATA
    flag_photos: 0,
    flag_studies: 0,
    flag_plan: 0,

    // ERROR HANDLER
    error: {
      error: false,
      message: '',
    },

    // CROPPER
    cropper: false,
    cropper_img: null,

    errors: [],
  })

  const stageTitleArray = [
    null,
    'Cargar datos',
    'Adjuntar fotos',
    'Adjuntar estudios',
    'Adjuntar Plan de Tratamiento',
    'Envío de Registro',
  ]

  type_dispatch.step = 'Header'
  type_dispatch.field = 'MainInformation'

  const handleChangeStep = () => {
    if (!stageOneFinish)
      return handleToast('error', 'Todos los campos son requeridos')

    if (state.step === 5 && !stageFiveFinish)
      return handleToast('error', 'Todos los campos son requeridos')

    setState({ ...state, step: state.step + 1 })
  }

  const checkFrotalSonriendo = () => {
    if (state?.frontal_sonriendo) return state.frontal_sonriendo

    if (state?.selected_patient?.patientImage)
      return state?.selected_patient?.patientImage

    return frontalsonriendo
  }

  useEffect(() => {
    // Validate Step 1
    if (
      state.odontologo &&
      state.nombre &&
      state.apellido &&
      state.genero &&
      state.maxilla &&
      state.external_id
    ) {
      setStageOneFinish(true)
    } else setStageOneFinish(false)

    // Validate Step 2
    let i = 0
    const values = Object.values(state.fotos)
    values.forEach((value) => {
      if (value !== null) ++i
    })
    if (i > 9) setStageTwoFinish(true)
    else setStageTwoFinish(false)

    // Validate Step 3
    if (state.telerradiografia && state.rxpanoramica && state.ricketts) {
      setStageThreeFinish(true)
    } else setStageThreeFinish(false)

    //Validate Step 4
    if (planFiles.length > 0) {
      setStageFourFinish(true)
    } else setStageFourFinish(false)

    //Validate Step 5
    if (state) {
      if (state.newIntraoralScanDate) return setStageFiveFinish(true)
      if (
        state.sendImpressions.takeover_date &&
        state.sendImpressions.dateToGet &&
        state.sendImpressions.withdrawalAddress
      )
        return setStageFiveFinish(true)
      setStageFiveFinish(false)
    }
  }, [state])

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <div>
          <div className="col-12 mx-auto text-center mt-4">
            <h2 style={{ fontWeight: 700, cursor: 'pointer' }}>
              Nuevo Ingreso de Tratamiento
            </h2>
            <h5>{stageTitleArray[state.step]}</h5>
          </div>
          <StepBar
            state={state}
            stageTwoFinish={stageTwoFinish}
            stageThreeFinish={stageThreeFinish}
            stageFourFinish={stageFourFinish}
          />
          {state.step === 1 && (
            <TreatmentAdminStepOne state={state} setState={setState} />
          )}

          {state.step === 2 && (
            <TreatmentAdminStepTwo
              state={state}
              setState={setState}
              photosFiles={photosFiles}
              setPhotosFiles={setPhotosFiles}
            />
          )}

          {state.step === 3 && (
            <TreatmentAdminStepThree
              state={state}
              setState={setState}
              studiesFiles={studiesFiles}
              setStudiesFiles={setStudiesFiles}
            />
          )}

          {state.step === 4 && (
            <TreatmentAdminStepFour
              planFiles={planFiles}
              setPlanFiles={setPlanFiles}
            />
          )}

          {state.step === 5 && (
            <TreatmentAdminStepFive state={state} setState={setState} />
          )}

          {state.step === 6 && (
            <TreatmentAdminStepSix
              planFiles={planFiles}
              state={state}
              setState={setState}
              stageTwoFinish={stageTwoFinish}
              stageThreeFinish={stageThreeFinish}
              stageFourFinish={stageFourFinish}
              checkFrotalSonriendo={checkFrotalSonriendo}
              photosFiles={photosFiles}
              studiesFiles={studiesFiles}
            />
          )}

          {state.step !== 6 && state.odontologo && (
            <div className="row mt-5 px-5 mx-5">
              <div className="col-12 f-flex text-end">
                {state.step > 1 && (
                  <button
                    className="btn btn-light text-primary rounded-pill px-4 mx-2"
                    onClick={() => {
                      setState({
                        ...state,
                        step: state.step > 1 && state.step - 1,
                      })
                    }}
                  >
                    <ChevronLeft size="16" /> Volver
                  </button>
                )}
                <button
                  className="btn btn-primary rounded-pill px-4"
                  onClick={() => handleChangeStep()}
                >
                  Continuar
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LoadTreatmentAdmin
