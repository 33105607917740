import React from 'react'
import '../style.css'

export const TreatmentIndicationsStepBar = () => {
  return (
    <div className="d-flex flex-column w-100 align-items-end mt-3 me-1 position-relative">
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Linea Media</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Overjet/Overbite</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Relación Canina</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Relación molar</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Mordida cruzada</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Apiñamiento</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Diastemas</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Espacios</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>
          Distalación Secuencial
        </span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>
          Recursos Auxiliares
        </span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="progressBarLineTreatmentIndications"></div>
    </div>
  )
}
