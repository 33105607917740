import React from 'react'
import '../../../style.css'

const Spaces = ({ formData }) => {

  const searchSpacesResource = (number) => {
    return formData?.close_space.includes(number)
  }

  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Espacios</p>
      <hr className="mt-2 mb-0" />
      <div className="d-flex flex-column">
        <div className="improve-overjet-container">
          <div className="d-flex flex-column">
            <div className="w-100 d-flex justify-content-between">
              <div className="me-3">
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="spaces"
                  disabled
                  checked={formData?.space == 1 && true}
                />
                <label className="ms-2">Mantener espacios</label>
              </div>
              <div className="me-3">
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="spaces"
                  disabled
                  checked={formData?.space == 2 && true}
                />
                <label className="ms-2">
                  Abrir{' '}
                  <input
                    type="number"
                    className="middleLineInputs"
                    value={
                      formData?.space_open_mm && formData?.space == 2
                        ? formData?.space_open_mm
                        : 0
                    }
                    disabled
                  />{' '}
                  mm
                </label>
              </div>
              <div className="me-3">
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="spaces"
                  disabled
                  checked={formData?.space == 3 && true}
                />
                <label className="ms-2">No aplica</label>
              </div>
              <div className="me-3">
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="spaces"
                  disabled
                  checked={formData?.space == 4 && true}
                />
                <label className="ms-2">Cerrar espacios</label>
              </div>
            </div>
            {formData?.space == 4 && (
              <div className="w-100 mt-4">
                <p className="mb-0">Cerrar espacios</p>
                <hr className="mt-1" />
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="closeSpaces"
                    disabled
                    checked={searchSpacesResource(1)}
                  />
                  <label className="ms-2">
                    Por mesialización de sectores posteriores (pérdida de
                    anclaje posterior)
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="closeSpaces"
                    disabled
                    checked={searchSpacesResource(2)}
                  />
                  <label className="ms-2">
                    Por distalización del sector anterior (pérdida de anclaje
                    anterior)
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="closeSpaces"
                    disabled
                    checked={searchSpacesResource(3)}
                  />
                  <label className="ms-2">Cierre recíproco</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="closeSpaces"
                    disabled
                    checked={searchSpacesResource(4)}
                  />
                  <label className="ms-2">Otras:</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {searchSpacesResource(4)&& (
        <>
          <p className="mt-3">Indique como desea cerrar espacios:</p>
          <div className="step-text-area w-100 mt-2">
            {formData?.close_space_other}
          </div>
        </>
      )}
    </div>
  )
}

export default Spaces
