import React from 'react'

const CHIsApplicationMaxilar = ({handleChangeMaxilar,data}) => {
  return (
    <select
      className="CH-select my-1"
      onChange={(e) => {
        handleChangeMaxilar(e.target.value)
      }}
    >
      {!data?.maxilla && <option>Sin maxilar</option>}
      <option selected={data?.maxilla === 'Superior' ? true : false}>
        Superior
      </option>
      <option selected={data?.maxilla === 'Inferior' ? true : false}>
        Inferior
      </option>
      <option selected={data?.maxilla === 'Ambos' ? true : false}>Ambos</option>
    </select>
  )
}

export default CHIsApplicationMaxilar
