import React from 'react'

// COMPONENTS
import {CheckCircle, XCircle} from 'react-feather'

export default function card() {
  return (
            <div className="row  w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-center py-3  my-2 align-items-middle tc-container type-1">
              <div className="col-1 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">123456</p></div>
              <div className="col-2 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">Paciente Pirulo</p></div>
              <div className="col-2 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">Doctor Pablito Lescano</p></div>
              <div className="col-1 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">Full</p></div>
              <div className="col-1 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">10</p></div>
              <div className="col-1 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">12/02/2021</p></div>
              <div className="col-1 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">Intraoral</p></div>
              <div className="col-2 d-flex flex-row justify-content-center"><p className="m-0 text-aling-center justify-items-middle text-wrap">Lorem, ipsum dolor.</p></div>
              <div className="col-1 d-flex flex-row justify-content-center"><button className="btn p-0 mx-1"><XCircle size={28} color="#e74c3c" /></button> <button className="btn p-0 mx-1"><CheckCircle size={28} color="#2ecc71" /></button></div>
            </div>
  )
}
