import React from 'react'
import Loader from '../../../components/loader'
import Paginator from '../../../components/Paginator'
import Searchbar from '../../../components/searchbar'
import NotificationSwitch from './components/NotificationSwitch'
import { useNotifications } from './hooks/useNotifications'

export const NotificationsDesktop = () => {
  const {
    loading,
    state,
    setState,
    getNotificationsPag,
    url,
    changeFilterHandler,
    filter,
  } = useNotifications(1)

  if (loading) return <Loader />

  return (
    <>
      <div className="notificationsHeader px-5 py-4">
        <p className="fs-2 fw-bold text-white m-0">Notificaciones</p>
      </div>
      <div className="d-flex flex-column mx-5 my-2">
        <div className="searchbar align-self-end">
          <Searchbar
            url={`${url}&`}
            state={state}
            setState={setState}
            page="notificationDentist"
          />
        </div>
        <hr />
        <div className="d-flex w-100">
          <div
            className={`${
              filter === 1 ? 'filterBTNSelected' : 'filterBTN'
            } me-3`}
            onClick={() => changeFilterHandler(1)}
          >
            Todos <span>({state.meta.total})</span>
          </div>
          <div
            className={`${filter === 2 ? 'filterBTNSelected' : 'filterBTN'}`}
            onClick={() => changeFilterHandler(2)}
          >
            Reclamos <span>({state.meta.total_actions})</span>
          </div>
        </div>
        {state.notifications.map((notification) => (
          <NotificationSwitch data={notification} key={notification.id} />
        ))}
        <Paginator
          paginator={state.paginator}
          getPaginator={getNotificationsPag}
        />
      </div>
    </>
  )
}
