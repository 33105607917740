export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

export const imageUrlToFile = async ({ imageUrl, imageName }) => {
  const imgExt = getUrlExtension(imageUrl)
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  const file = new File([blob], `${imageName ?? 'image'}.${imgExt}`, {
    type: blob.type,
  })

  return file
}
