import React from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import Loader from '../../../components/loader'
import MobilePatientCard from '../../../components/MobilePatientCard/MobilePatientCard'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../../components/Paginator'
import Searchbar from '../../../components/searchbar'
import { getProfileData } from '../SmileCreator/utilities/helpers'
import useOdHome from './hooks/useOdHome'
import './index.css'

const MobileHome = () => {
  const {
    state,
    filter,
    setState,
    history,
    handleFilter,
    handleGender,
    name,
    surname,
    gender,
    getPaginatorData,
    getUrl,
    category,
  } = useOdHome({ isMobile: true })

  const handleSetRequiredData = (state) => {
    const flags = {
      studies: state.treatment.estudios,
      photos: state.treatment.fotos,
      plan: state.treatment.plan,
    }
    const { studies, photos, plan } = flags
    if (state.treatment.missing_documentation.length > 0) {
      return `proposal`
    } else if (!studies || !photos || !plan) {
      return `documentation`
    }
  }

  if (state.loading) return <Loader />
  return (
    <div className="odHomeContainer">
      <div className="odHomeHeader">
        <p className="text-white fs-2 fw-light ps-3 pt-5">Hola</p>
        <p className="text-white fs-2 fw-bold ps-3 pb-3">
          <span>{handleGender(gender)}</span> {`${name} ${surname}`}
          {category.loading ? (
            <Spinner
              color="white"
              style={{
                height: '1.2rem',
                width: '1.2rem',
                marginLeft: '20px',
                marginBottom: '10px',
              }}
              type="grow"
            >
              Cargando...
            </Spinner>
          ) : (
            <img
              alt="Imagen"
              src={getProfileData(category.image, true)}
              className="smileCreatorIMG"
              onClick={() => {
                history.replace('/od/smileCreator')
              }}
            />
          )}
        </p>
        <div className="d-flex justify-content-center">
          <Link className="odHeaderBtn" to="solicitud">
            NUEVA SOLICITUD
          </Link>
        </div>
      </div>
      <p className="fs-2 fw-bold m-0 mt-5 ps-3">Mis Pacientes</p>
      <div className="d-flex flex-wrap mt-3 ms-3">
        <p
          className={filter === 1 ? 'home-active' : 'home-inActive'}
          onClick={() => handleFilter(1)}
        >
          Todos ({state.totalPatients})
        </p>

        <p
          className={filter === 2 ? 'home-active' : 'home-inActive'}
          onClick={() => handleFilter(2)}
        >
          Acciones Requeridas ({state.totalPendings})
        </p>
      </div>
      <hr className="mx-3" />
      <div className="px-3 mb-4 pb-2 mx-auto" style={{ maxWidth: 440 }}>
        <Searchbar
          initialQueryType="odHome"
          state={state}
          setState={setState}
          page={'odHome'}
          url={`${getUrl(filter)}&`}
          isOdHome={true}
        />
      </div>
      <div className="px-3 pb-3 d-flex flex-column align-items-center">
        {state.data.length ? (
          state.data.map((item) => (
            <MobilePatientCard
              treatmentId={item.patient.id}
              avatar={item.treatment.frontal_sonriendo}
              name={item.patient.name + ' ' + item.patient.surname}
              maxilar={item.treatment.maxilla}
              stage={item.treatment.order}
              type={handleSetRequiredData(item)}
              treatmentType={item.treatment.type.id}
            />
          ))
        ) : (
          <NotFoundComponent />
        )}
      </div>

      <Paginator
        center={true}
        paginator={state.paginator}
        getPaginator={getPaginatorData}
      />
    </div>
  )
}

export default MobileHome
