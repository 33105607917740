import React, { useState, useEffect } from 'react'
import { Edit, RefreshCcw, X } from 'react-feather'
import { useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import ConfirmationModal from '../../../components/confirmation_modal'
import apiClient from '../../../utils/backend'
import handleToast from '../../../components/toaster'
import './index.css'
import throttle from '../../../utils/throttle'
import PropTypes from 'prop-types'
import loadingIcon from '../../../assets/images/loading.svg'

const EditTreatment = ({ data, principalDentist, setInitialData }) => {
  const user = useSelector((state) => state.userReducer)
  const [opened, setOpened] = useState(false)
  const [query, setQuery] = useState('')
  const [selectedDentist, setSelectedDentist] = useState(null)
  const [defaultSelected, setDefaultSelected] = useState(false)
  const [principalDentistId, setPrincipalDentist] = useState()
  const [showSearch, setShowSearch] = useState(false)
  const [recentlySelected, setRecentlySelected] = useState(false)
  const [dentists, setDentists] = useState([])
  const [dentistsToAdd, setDentistsToAdd] = useState([])
  const { dentistList } = useSelector((state) => state.odTreatments)
  const [userData, setUserData] = useState()
  const [errorToast, setErrorToast] = useState({
    editType: false,
    addDentist: false,
    principalDentist: false,
  })

  const handleReset = () => {
    setDefaultSelected(true)
    setTimeout(() => {
      setDefaultSelected(false)
    }, 500)
  }

  const deleteDentist = (id) => {
    if (principalDentist.id === id) {
      return handleToast('error', 'No se puede borrar el dentista principal')
    }
    let newDentistList
    newDentistList = userData.dentistToDelete.filter(
      (dentist) => dentist.id !== id
    )
    setUserData({
      ...userData,
      dentistToDelete: newDentistList,
      dentist: newDentistList,
    })
    const isNewDentist = data.dentists.find((dentist) => dentist.id === id)
    if (!isNewDentist) {
      let dataDentist
      dataDentist = dentistsToAdd.filter((dentist) => dentist !== id)

      return setDentistsToAdd(dataDentist)
    }

    const deleteDentistApiCall = async () => {
      try {
        await apiClient.delete(
          `/api/treatments/${Number(data.id)}/dentists/${id}`
        )
        handleToast('success', 'Dentista eliminado')
      } catch (error) {
        console.log(error)
        handleToast('error', 'Error al eliminar el dentista')
      }
    }
    deleteDentistApiCall()
  }

  const handleSave = () => {
    handleToast('updating', 'Guardando los cambios...')
    const existPrincipalDentist = userData.dentistToDelete.filter(
      (dentist) => dentist.id === principalDentistId
    )

    const components = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
    }

    const editTreatmentType = async () => {
      try {
        await apiClient.patch(`/api/treatments/${Number(data.id)}`, {
          type_id: Number(components[userData.treatment]),
          order: userData.stage,
        })
      } catch (error) {
        setErrorToast({ ...errorToast, editType: true })
        console.log(error)
      }
    }

    const addDentistInTreatment = () => {
      if (dentistsToAdd.length === 0) return
      dentistsToAdd.map(async (dentist) => {
        try {
          await apiClient.post(`/api/treatments/${Number(data.id)}/dentists`, {
            dentist_id: dentist,
          })
        } catch (error) {
          setErrorToast({ ...errorToast, addDentist: true })
          console.log(error)
        }
      })
      setDentistsToAdd([])
    }
    const updatePrincipalDentist = async () => {
      try {
        await apiClient.patch(
          `api/treatments/${Number(data.id)}/dentists/${principalDentist.id}`,
          {
            new_principal_dentist_id: principalDentistId,
          }
        )
      } catch (error) {
        setErrorToast({ ...errorToast, principalDentist: true })
        console.log(error)
      }
    }
    //  si lees esto nico, te odio
    editTreatmentType()
    addDentistInTreatment()

    if (
      principalDentist.id !== principalDentistId &&
      existPrincipalDentist.length !== 0
    ) {
      updatePrincipalDentist()
    }
    setTimeout(() => {
      setInitialData(user.countryId)
      setOpened(!opened)
    }, 2000)

    if (
      errorToast.editType ||
      errorToast.addDentist ||
      errorToast.principalDentist
    ) {
      return handleToast(
        'error',
        'Hubo un error al guardar los datos, intente nuevamente'
      )
    } else {
      return handleToast(
        'success',
        'Los datos del tratameinto fueron guardados correctamente'
      )
    }
  }

  const handleSearchInput = (value) => {
    setQuery(value)
  }

  const handleSelectedDentist = (dentistNew) => {
    const isOnDentistList = userData.dentist.find(
      (dentista) => dentista.user_id === dentistNew.user_id
    )
    const isOnDentistToDelete = userData.dentistToDelete.find(
      (dentista) => dentista.user_id === dentistNew.user_id
    )
    if (
      isOnDentistList ||
      isOnDentistToDelete ||
      dentistNew.id === principalDentistId
    )
      return handleToast('error', 'El dentista ya está en la lista')

    setSelectedDentist({
      id: dentistNew.user.id,
      name: dentistNew.user.full_name,
    })
    setUserData({
      ...userData,
      dentistToDelete: [...userData.dentistToDelete, dentistNew],
      dentist: [...userData.dentist, dentistNew],
    })
    setDentistsToAdd([...dentistsToAdd, dentistNew.id])
  }

  useEffect(() => {
    const handleRequest = async (queryValue) => {
      if (!queryValue || queryValue.length < 4) return
      if (recentlySelected) return setRecentlySelected(false)

      try {
        const queryValueEncoded = encodeURIComponent(queryValue)
        const res = await apiClient.get(
          `/api/dentists?cant_pages=all&filter[fullname]=${queryValueEncoded}`
        )
        let dentists = res.data.table.body.slice(0, 11)
        setDentists(dentists)
      } catch (err) {
        console.log(err)
      }
    }

    throttle(handleRequest, query, 1000)
  }, [query])

  useEffect(() => {
    setTimeout(() => {
      setPrincipalDentist(principalDentist.id)
      setUserData({
        dentistToDelete: data.dentists.filter(
          (dentist) => dentist.id !== principalDentist.id
        ),
        dentist: data.dentists.filter(
          (dentist) => dentist.id !== principalDentist.id
        ),
        id: data.id,
        stage: data.stage,
        treatment: data.treatment,
        odList: dentistList,
      })
    }, 1000)
  }, [data])

  return (
    <>
      <Edit onClick={() => setOpened(!opened)} />
      <Modal
        style={{ marginTop: '150px' }}
        size="lg"
        isOpen={opened}
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          {!userData ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
              }}
            >
              <img
                src={loadingIcon}
                style={{ width: '200px', height: 'auto' }}
                alt="Loading..."
              />
            </div>
          ) : (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex ">
                    <p className="mt-2 col-11 text-black text-start fs-2 fw-bold">
                      Editar Tratamiento
                    </p>
                    <X
                      onClick={() => setOpened(false)}
                      className="col-1 x_button m-0"
                    />
                  </div>
                  <div className=" mt-4 d-flex justify-content-between">
                    <p className="text-black text-start fs-5 fw-bold mt-2">
                      Tratamiento
                    </p>
                    <button
                      onClick={() =>
                        setUserData({ ...userData, treatment: 'Full' })
                      }
                      style={{ backgroundColor: '#001B70' }}
                      className={`${
                        userData.treatment === 'Full' &&
                        'treatment_button_active'
                      } text-white fw-light btn treatment_button ms-2`}
                    >
                      FULL
                    </button>
                    <button
                      onClick={() =>
                        setUserData({ ...userData, treatment: 'Fast' })
                      }
                      style={{ backgroundColor: '#288B7E' }}
                      className={`${
                        userData.treatment === 'Fast' &&
                        'treatment_button_active'
                      } text-white fw-light btn treatment_button ms-2`}
                    >
                      FAST
                    </button>
                    <button
                      onClick={() =>
                        setUserData({ ...userData, treatment: 'Medium' })
                      }
                      style={{ backgroundColor: '#5A468E' }}
                      className={`${
                        userData.treatment === 'Medium' &&
                        'treatment_button_active'
                      } text-white fw-light btn treatment_button ms-2`}
                    >
                      MEDIUM
                    </button>
                    <button
                      onClick={() =>
                        setUserData({ ...userData, treatment: 'Kids' })
                      }
                      style={{ backgroundColor: '#E47662' }}
                      className={`${
                        userData.treatment === 'Kids' &&
                        'treatment_button_active'
                      } text-white fw-light btn treatment_button ms-2`}
                    >
                      FULL Kids
                    </button>
                    <button
                      onClick={() =>
                        setUserData({ ...userData, treatment: 'Kids Fast' })
                      }
                      style={{ backgroundColor: '#E8793A' }}
                      className={`${
                        userData.treatment === 'Kids Fast' &&
                        'treatment_button_active'
                      } text-white fw-light btn treatment_button ms-2`}
                    >
                      FAST Kids
                    </button>
                    <button
                      onClick={() =>
                        setUserData({ ...userData, treatment: 'Teens' })
                      }
                      style={{ backgroundColor: '#f7c05b' }}
                      className={`${
                        userData.treatment === 'Teens' &&
                        'treatment_button_active'
                      } text-white fw-light btn treatment_button ms-2`}
                    >
                      TEENS
                    </button>
                  </div>
                  {(userData.treatment === 'Full' ||
                    userData.treatment === 'Kids' ||
                    userData.treatment === 'Teens') && (
                    <div className="col-12 mt-4 d-flex justify-content-space-between">
                      <button
                        onClick={() => setUserData({ ...userData, stage: 1 })}
                        className={`${
                          userData.stage == 1 ? 'step_active' : 'step_inactive'
                        } col-2 btn-sm`}
                      >
                        ETAPA 1
                      </button>
                      <button
                        onClick={() => setUserData({ ...userData, stage: 2 })}
                        className={`${
                          userData.stage == 2 ? 'step_active' : 'step_inactive'
                        } col-2 ms-2 btn-sm`}
                      >
                        ETAPA 2
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mt-5">
                      <p>Doctor:</p>
                    </div>
                    <div className="row d-flex">
                      <div className="col-10">
                        <select
                          className="form-control"
                          onChange={(e) => setPrincipalDentist(e.target.value)}
                        >
                          <option
                            value={principalDentist?.id}
                            selected={defaultSelected}
                          >
                            {principalDentist?.user.name}{' '}
                            {principalDentist?.user.surname}
                          </option>
                          {userData.dentist.map((option, i) => (
                            <option key={i} value={option.id}>
                              {option.user?.name} {option.user?.surname}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="d-flex col-2 mb-3 align-items-center">
                        <RefreshCcw
                          className="input_button ms-2"
                          onClick={() => handleReset()}
                        />
                        <span
                          className="input_button ms-2 fs-1 fw-bold"
                          onClick={() => setShowSearch(!showSearch)}
                          title="Agregar dentista"
                        >
                          +
                        </span>
                      </div>
                    </div>
                    {showSearch && (
                      <div className="col-12 d-flex flex-column">
                        <div className="row d-flex">
                          <div className="col-10">
                            <input
                              className="form-control w-50 my-0"
                              type="text"
                              onChange={(e) => {
                                handleSearchInput(e.target.value)
                              }}
                              placeholder=" 🔍 Buscar..."
                              value={query}
                            />

                            {dentists.length > 0 && (
                              <ul
                                className="ulScroll me-2 w-50"
                                style={{ position: 'relative' }}
                              >
                                {dentists.map((dentist, i) => (
                                  <li
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        dentist.user.id === selectedDentist?.id
                                          ? 'rgb(220, 226, 255)'
                                          : 'white',
                                    }}
                                    onClick={(e) => {
                                      setRecentlySelected(true)
                                      setQuery(
                                        dentist.user.name +
                                          ' ' +
                                          dentist.user.surname
                                      )
                                      handleSelectedDentist(dentist)
                                      setDentists([])
                                    }}
                                    className="liScroll mt-2"
                                    key={dentist.user.id}
                                  >
                                    {dentist.user.name +
                                      ' ' +
                                      dentist.user.surname}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-12 d-flex flex-row flex-wrap justify-content-center">
                      {userData.dentistToDelete.map((dentist, i) => (
                        <button
                          key={i}
                          className="d-flex align-items-center btn btn-light btn-sm m-2 p-1"
                        >
                          <p className="text-truncate mt-2">
                            {dentist.user?.name} {dentist.user?.surname}
                          </p>
                          <ConfirmationModal
                            type="dentist-delete"
                            data={dentist}
                            deleteDentist={deleteDentist}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 d-flex justify-content-end">
                  <button
                    onClick={handleSave}
                    className="btn btn-success btn-lg text-white"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

EditTreatment.propTypes = {
  data: PropTypes.object.isRequired,
  principalDentist: PropTypes.object.isRequired,
}

export default EditTreatment
