import React, { useEffect, useState } from "react";
import moment from "moment";
import apiClient from "../../../utils/backend";

// COMPONENTS
import Datepicker from "react-datepicker";

export default function CardPlatesRepeat(props) {
  useEffect(() => {
    moment.locale("es");
  }, []);

  const [printDate, setPrintDate] = useState(
    props.item.print_date ? new Date(props.item.print_date) : new Date()
  );
  const [dateVisible, setDateVisible] = useState(
    props.item.print_date !== null
  );

  function handlePrintDate(date) {
    setPrintDate(date);
    let date_format = moment(date);
    apiClient
      .post("api/helper/setPrintDate", {
        treatment_id: props.item.id,
        date: date_format.format("YYYY-MM-DD"),
      })
      .then((res) =>
        res.status === 200 ? window.location.reload() : window.location.reload()
      );
  }

  function setPrinter(e) {
    console.log("printer");
    console.log(e.target.value);
    apiClient
      .post("api/helper/setPrinter", {
        treatment_id: props.item.id,
        printer: e.target.value,
      })
      .then((res) => window.location.reload());
  }

  return (
    <div className="row  w-100 d-flex flex-row bg-white shadow rounded m-0 py-3  my-2 align-items-middle tc-container type-1 grow_hover">
      <div className="col-half d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.id}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.patient.full_name}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.dentist.full_name}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.fecha_pedido}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.fecha_pedido}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.plates}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.maxilla}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.cant_plates}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <select
          value={props.item.printer}
          onChange={setPrinter}
          className="form-control"
          id=""
        >
          <option value={null}>Seleccionar</option>
          <option value="Phrozen">Phrozen</option>
          <option value="Ultimaker">Ultimaker</option>
          <option value="Proveedor">Proveedor</option>
        </select>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <p className="m-0 text-aling-center justify-items-middle text-wrap">
          {props.item.status.id > 20 && <p>Finalizado</p>}

          {!dateVisible && props.item.status.id <= 20 ? (
            <button
              className="btn btn-success text-white"
              onClick={() => setDateVisible(true)}
            >
              Imprimir
            </button>
          ) : null}
          {moment().format("YYYY-MM-DD") !== props.item.print_date &&
            dateVisible && (
              <Datepicker
                dateFormat="dd/MM"
                style={{ width: "40px!important", fontSize: ".7rem!important" }}
                orientation="bottom left auto"
                selected={printDate}
                onChange={(date) => {
                  handlePrintDate(date);
                }}
                className="form-control"
              />
            )}
          {moment().format("YYYY-MM-DD") == props.item.print_date &&
          props.item.status.id <= 20 ? (
            <button
              onClick={() =>
                props.handleModal(`modal_impresion-${props.item.id}`)
              }
              style={{ backgroundColor: "#F0E76E" }}
              className={`btn rounded-pill py-3 text-white`}
            >
              Imprimiendo
            </button>
          ) : null}
        </p>
      </div>
      <div className="col"></div>
    </div>
  );
}
