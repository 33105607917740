import React, { useState } from 'react'
import './documentation.css'
import DocumentationPlan from './Sections/DocumentationPlan'
import DocumentationTreatmentProposal from './Sections/DocumentationTreatmentProposal'
import moment from 'moment'
import ImageInput from './utilities/ImageInput'
import ImageDisplay from './utilities/ImageDisplay'
import 'react-toastify/dist/ReactToastify.css'
// import handleToast from '../../../../../components/toaster'
// import {
//   createNotificationTypeDocumentation,
//   dataURLtoFile,
// } from './utilities/helpers'
import DocumentationSelector from './utilities/DocumentationSelector'
import { photosInput } from './filesNames/photosInput'
import { studiesInput } from './filesNames/studiesInput'
import useDocumentation from './hooks/useDocumentation'
import { Spinner } from 'reactstrap'
import OtherPhotos from './Sections/OtherPhotos'
import OtherStudies from './Sections/OtherStudies'
import DocumentationOriginalRegister from './Sections/DocumentationOriginalRegister'

const DentistMedicalRecordDocumentation = () => {
  const {
    loading,
    backendFiles,
    informationState,
    setInformationState,
    documentationState,
    originalRegister,
    // treatmentId,
    loadData,
    othPhotos,
    othStudies,
    setOthPhotos,
    setOthStudies,
    displaySaveBtn,
    displaySaveChangesText,
    loadImage,
    deleteImage,
    section,
    setSection,
    documentsToSave,
    // setDocumentsToSave,
    planFiles,
    setPlanFiles,
  } = useDocumentation()

  const [stateNew, setStateNew] = useState({
    apellido: informationState.patient.surname,
    nombre: informationState.patient.name,
    treatment_type: informationState.type.id,
    step: 1,
    treatment_id: informationState.id,
    image_plan: false,
    online_plan: false,
    plantratamiento: null,
    patient_id: informationState.patient.id,
  })

  if (loading)
    return (
      <div className="d-flex justify-content-center mx-auto my-6">
        <Spinner
          color="primary"
          style={{
            height: '3rem',
            width: '3rem',
            marginTop: '40px',
          }}
        >
          Cargando...
        </Spinner>
      </div>
    )
  return (
    // docStatus
    <div className="mt-5 ">
      <div className="d-flex flex-wrap">
        {informationState.treatment_status.documentation == 'completed' ? (
          <div className="d-flex">
            <p className="my-0 me-3 fw-bold text-green">
              Documentación completa
            </p>
            <p className="my-0 disabled">
              {moment
                .utc(documentationState.documentation_upload_at)
                .format('DD-MM-YYYY')}
            </p>
          </div>
        ) : (
          <div className="d-flex ">
            <p className="my-0 me-3 fw-bold text-red">
              ¡Documentación incompleta!
            </p>
          </div>
        )}
      </div>
      {displaySaveChangesText()}

      <DocumentationSelector
        section={section}
        setSection={setSection}
        originalRegister={originalRegister}
        informationState={informationState}
      />

      {section === 'photos' && (
        <div
          className="d-flex flex-wrap justify-content-center my-4 mx-auto"
          style={{ maxWidth: '1172px' }}
        >
          {photosInput.map(({ slug, label }) => {
            const hasImageUploaded = backendFiles?.photos.find(
              ({ name }) => name === slug
            )
            const imageLoaded =
              !hasImageUploaded &&
              documentsToSave.find((item) => item.id === slug)

            return hasImageUploaded ? (
              <ImageDisplay
                key={slug}
                name={label}
                previewImage={hasImageUploaded?.thumb}
                image={hasImageUploaded?.path}
                pdf={false}
              />
            ) : (
              <ImageInput
                key={slug}
                name={label}
                src={slug}
                id={slug}
                imageToSave={imageLoaded}
                loadImage={loadImage}
                deleteImage={deleteImage}
              />
            )
          })}

          <OtherPhotos
            backendFiles={backendFiles}
            othPhotos={othPhotos}
            setOthPhotos={setOthPhotos}
          />
        </div>
      )}
      {section === 'studies' && (
        <div
          className="d-flex flex-wrap my-4 justify-content-around"
          style={{ maxWidth: '1272px' }}
        >
          {studiesInput.map(({ slug, label }) => {
            const hasImageUploaded = backendFiles?.studies.find(
              ({ name }) => name === slug
            )
            const imageLoaded =
              !hasImageUploaded &&
              documentsToSave.find((item) => item.id === slug)
            return hasImageUploaded ? (
              <ImageDisplay
                key={slug}
                name={label}
                previewImage={hasImageUploaded?.thumb}
                image={hasImageUploaded?.path}
                pdf={false}
              />
            ) : (
              <ImageInput
                key={slug}
                name={label}
                src={slug}
                id={slug}
                imageToSave={imageLoaded}
                loadImage={loadImage}
                deleteImage={deleteImage}
              />
            )
          })}
          <OtherStudies
            backendFiles={backendFiles}
            othStudies={othStudies}
            setOthStudies={setOthStudies}
          />
        </div>
      )}

      {section === 'plan' && (
        <DocumentationPlan
          backendFiles={backendFiles}
          planFiles={planFiles}
          setPlanFiles={setPlanFiles}
          state={informationState}
          stateNew={stateNew}
          setStateNew={setStateNew}
          documentationState={documentationState}
          setInformationState={setInformationState}
        />
      )}

      {section === 'originalRegister' && (
        <div className="mx-4 mt-5">
          {originalRegister?.url && (
            <DocumentationOriginalRegister
              originalRegister={originalRegister}
            />
          )}
        </div>
      )}
      {section === 'treatmentProposal' && (
        <DocumentationTreatmentProposal
          proposalData={informationState.proposals}
          loadData={loadData}
          userName={[
            informationState.patient.name,
            informationState.patient.surname,
          ]}
        />
      )}
      <div className="d-flex justify-content-center">{displaySaveBtn()}</div>
    </div>
  )
}

export default DentistMedicalRecordDocumentation
