import React from 'react'

export default function RadioList({
  radios,
  stateField,
  setter,
  name,
  colors,
  enabled,
}) {
  return (
    <div>
      {radios.map((rad, i) => (
        <div className={`form-check mt-${i === 0 ? '3' : '2'}`} key={i}>
          <input
            disabled={!enabled}
            style={
              stateField === rad.value
                ? {
                    backgroundColor: `${colors.color}`,
                    borderColor: `${colors.color}`,
                  }
                : {}
            }
            checked={stateField === rad.value}
            onChange={() => setter(rad.value)}
            className="form-check-input"
            type="radio"
            name={name + i}
            id={name + i}
          />
          <label className="form-check-label force-inline-text">
            {rad.label}
          </label>
        </div>
      ))}
    </div>
  )
}
