import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
//import { Book, HelpCircle, Bookmark, PenTool, Monitor, User, Video } from 'react-feather'

function DescriptionCentroDeAyuda(props){
    const { description_data, parent } = props
    return (
        <Fragment>
            <div className="w-100 d-flex justify-content-center py-5">
            <div className='d-flex align-items-center w-90'>
                {/* {getIcon(parent()?.icon) && 
                <div className='d-flex align-items-center justify-content-center w-10'>
                    {getIcon(parent()?.icon)}
                </div>
                } */}
                <div className='d-flex flex-row align-items-center'>
                    <div className="d-flex flex-column bg-transparent">
                        <strong style={{fontSize: 50}}>{parent.name}</strong>
                    </div>
                </div>
            </div>
            </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="p-5 w-90 d-flex flex-row bg-white shadow rounded m-0 px-10 py-3 my-2 align-items-center tc-container">
                            <div className='d-flex flex-row align-items-center justify-content-center'>
                                <div dangerouslySetInnerHTML={{__html: description_data.body}} className="d-flex flex-column bg-transparent">
                                </div>
                            </div>
                    </div>
                </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(DescriptionCentroDeAyuda);