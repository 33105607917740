let initialState = {
  id: 1,
  data: []
}

const modalReducer = (state = initialState, action) => {
  switch(action.type) {
      case "SET_MODAL_DATA":
          return {
              ...state,
              data: action.payload
          }
      default: {
          return state
      }
  }
}

export default modalReducer