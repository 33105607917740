import React, { useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import { spaces_options, spacesList } from '../Utilities/OptionsRadios'
import TextAreaCounter from '../Utilities/TextAreaCounter'
import CheckBoxList from '../Utilities/CheckBoxList'

function Spaces(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'Spaces'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
  //State
  const spaces = middle_state.spaces
  const amountOpen = middle_state.amountOpen
  const spacesSelected = middle_state.closeSpaces

  //Reference
  const othersCloseSpacesRef = useRef(null)
  const openAmountRef = useRef(null)

  const updateResources = (val, chk) => {
    let updatedArray = []
    if (chk) {
      updatedArray = [...middle_state.closeSpaces, val]
    } else {
      updatedArray = middle_state.closeSpaces.filter((itm) => itm !== val)
    }
    updateState({ key: 'closeSpaces', value: updatedArray })
  }

  useEffect(() => {
    if (spaces === 1) {
      updateState({ key: 'closeSpaces', value: [] })
    }
  }, [spaces, updateState])

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  // useErrorList(errors_list, updateErrors, [spaces])
  useEffect(() => {
    if (errors_list.find((err) => err.order === 1)) {
      updateErrors(errors_list.filter((err) => err.order === 2))
    }
    if (errors_list.find((err) => err.order === 2)) {
      updateErrors(errors_list.filter((err) => err.order === 1))
    }
  }, [middle_state])
  return (
    <div>
      <div
        className="w-100 fw-bolder pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Espacios:
      </div>
      {props.formOnlineReducer.Enabled && (
        <div
          className="rounded mb-3 mt-2 p-2 d-flex flex-column"
          style={{
            color: 'white',
            backgroundColor: '#00155c',
          }}
        >
          <small className="fw-bold text-center force-inline-text text-white">
            RECUERDE QUE PARA MEJORAR GENERAR O CERRAR ESPACIOS SERÁ NECESARIA
          </small>
          <small className="fw-bold text-center force-inline-text text-white">
            LA COLOCACIÓN DE ATTACHMENTS
          </small>
        </div>
      )}
      {error1 && (
        <div className="alert-danger alert-link p-2 mt-1">{error1.message}</div>
      )}
      <div className="d-flex">
        <div className="col-2 ms-4">
          <RadioList
            enabled={props.formOnlineReducer.Enabled}
            colors={colors}
            radios={spaces_options}
            setter={(value) => updateState({ key: 'spaces', value })}
            stateField={spaces}
            name="spaces"
          />
        </div>
        <div className="col-2">
          {spaces === 2 && (
            <div className="mt-2 d-flex align-items-end h-50">
              <input
                name="middleUpLine_value"
                id="middleUpLine_value"
                type="number"
                onChange={(e) =>
                  updateState({ key: 'amountOpen', value: e.target.value })
                }
                value={amountOpen}
                ref={openAmountRef}
                tabIndex="1"
                maxLength="100"
                style={{ border: `1px solid black` }}
                className="rounded field text medium w-25 mb-0 ms-3 p-1"
                disabled={!props.formOnlineReducer.Enabled}
              />
              <small className="h-0 ms-1">mm</small>
            </div>
          )}
        </div>
      </div>
      {spaces === 4 && (
        <div className="ms-5">
          <div
            className="mt-3 w-50 fw-bolder small pb-1"
            style={{ borderBottom: `1px solid ${colors.color}` }}
          >
            Cerrar espacios
          </div>
          <div>
            <div>
              <CheckBoxList
                enabled={props.formOnlineReducer.Enabled}
                colors={colors}
                radios={spacesList}
                setter={(value, checked) => updateResources(value, checked)}
                stateField={spacesSelected}
                name="closeSpaces"
              />
            </div>
            <div>
              {spacesSelected.includes(4) && (
                <div className="ms-2">
                  <div className="text-muted mt-3 small mb-3">
                    Indique como desea cerrar espacios:
                  </div>
                  <textarea
                    disabled={!props.formOnlineReducer.Enabled}
                    onChange={(e) => {
                      updateState({
                        key: 'closeSpacesDescription',
                        value: e.target.value,
                      })
                    }}
                    value={middle_state.closeSpacesDescription}
                    ref={othersCloseSpacesRef}
                    className="mt-1 form-control"
                    name="observacion"
                    id="observacion"
                    cols="30"
                    rows="3"
                  ></textarea>
                  <TextAreaCounter
                    value={middle_state.closeSpacesDescription}
                    maxValue={2500}
                    error={error2}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Spaces)
