import React from 'react'
import { useDispatch } from 'react-redux'
import styles from '../styles/stepOne.module.css'

const StepFive = ({ modal, setModal }) => {
  const dispatch = useDispatch()

  return (
    <div className="p-4">
      <div className="d-flex justify-content-end"></div>
      <div className="d-flex flex-column align-items-center mx-auto">
        <p className={styles.title}>¡Somos creadores de sonrisas!</p>
        <p className={styles.text}>
          Completando estos pasos ya esta listo/a para navegar el nuevo portal
          KeepSmiling.
        </p>
        <p className={styles.watch}>😁</p>
        <p
          className={styles.startButton}
          onClick={() => {
            setModal((prev) => ({ ...prev, first_login: false }))
            if (modal.complete_offices) {
              dispatch({ type: 'LOGIN_MODAL', payload: false })
            }
          }}
        >
          ¡Listo!
        </p>
      </div>
    </div>
  )
}

export default StepFive
