import React from "react";
import Paciente from "./paciente";

function Form({ paciente, categoria, setText }) {
  return (
    <div className="container text-primary">
      <h2 className="fw-bold mt-5">
        {categoria} |{" "}
        <span className="fw-normal" style={{ fontSize: 12 }}>
          Su consulta bls bla
        </span>
      </h2>

      <div className="d-flex flex-row mt-5">
        {paciente && <Paciente paciente={paciente} />}

        <div className={`d-flex flex-column ${paciente && "mx-6"} w-100`}>
          <h4 className="fw-bold">Consulta:</h4>
          <h5 className="fw-bold mt-3">Los alineadores no calzan bien</h5>
          <p style={{ fontSize: 12 }}>
            A continuación puede detallar sobre su consulta. Si considera
            necesario puede adjuntar una imagen.
          </p>

          <textarea
            onChange={(e) => setText(e.target.value)}
            className="mt-4"
            cols="30"
            rows="10"
          />
          <input id="file" className="mt-3" type="file" />
        </div>
      </div>
    </div>
  );
}

export default Form;
