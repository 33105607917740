import LightGallery from 'lightgallery/react'
import '../../../../../assets/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import React, { useEffect, useState } from 'react'
import { getLicense } from '../../../../../utils/licenseKeyLightGallery'
import getExtensionFromBase64 from '../../../../getExtensionFromBase64'
import PDFViewer from '../../../../Modals/PDFViewer'

const ExtraInformation = ({ formData, documentationData }) => {
  const [extraDocuments, setExtraDocuments] = useState([])
  const [docs, setDocs] = useState([])

  const [extensions, setExtension] = useState([])
  const getBase64FromUrl = async (url, id) => {
    try {
      const data = await fetch(url)
      const blob = await data.blob()
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const extension = getExtensionFromBase64(reader.result)
          resolve({ extension, base64: reader.result })
        }
      })
      promise.then(({ extension, base64 }) =>
        setExtension((prevState) => [...prevState, { extension, id, base64 }])
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const filterDoc = documentationData?.documentation.filter(
      (doc) => doc.type == 4
    )
    filterDoc.map(({ files }) => {
      files.map((file) => {
        setExtraDocuments((prevState) => [...prevState, file])
        getBase64FromUrl(file.path, file.id)
      })
    })
  }, [])

  useEffect(() => {
    let newDocuments = []
    extraDocuments.map((file) => {
      extensions.map((extension) => {
        if (file.id === extension.id) {
          file.extension = extension.extension
          file.file_to_base64 = extension.base64
          newDocuments.push(file)
        }
      })
    })
    setDocs(newDocuments)
  }, [extensions])

  return (
    <div className="w-100 d-flex mt-5">
      <div className="step-title mx-3 d-flex align-items-end flex-column">
        <div>
          <p className="fs-4 fw-bold text-end p-2 m-0 pe-1">
            Indicaciones de
            <br />
            tratamiento
          </p>
        </div>
        <div className="title-sub-bar mb-2"></div>
      </div>
      <div className="d-flex flex-column diagnosis-container">
        <div className="steps-diagnosis-container d-flex">
          <div className=" mt-4">
            <p className="m-0 ms-1">
              A continuación puede aclarar información que considere necesaria:
            </p>
            <div
              className="step-text-area w-100 mt-2 overflow-scroll"
              style={{ maxWidth: '800px' }}
            >
              {formData?.general_instruction}
            </div>
            {extraDocuments.length !== 0 && (
              <div className="mt-4">
                <p>Archivos adjuntos:</p>
                <div className="d-flex justify-content-between">
                  {docs?.map((file) =>
                    file.extension === '.pdf' ? (
                      <PDFViewer
                        imageSource={file.file_to_base64}
                        isOd={true}
                      />
                    ) : (
                      <LightGallery
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        className="gallery"
                        licenseKey={getLicense()}
                      >
                        <img
                          src={`${file.path}`}
                          className="w-25 m-2 extraDocumentationIMG"
                        />
                      </LightGallery>
                    )
                  )} 
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraInformation
