import React from 'react'
import styles from '../styles/referrals.module.css'
export const Filters = ({ setAllReferrals, isAllSelected, visible }) => {
  if (!visible) return null
  return (
    <div className={`d-flex ${styles.tableHead}`}>
      <div className={styles.allFilter}>
        <input
          type="checkbox"
          onClick={() => setAllReferrals()}
          checked={isAllSelected}
        />
      </div>
      <p className={styles.completeName}>Nombre y Apellido</p>
      <p className={styles.email}>Email</p>
      <p className={styles.phone}>Teléfono</p>
      <p className={styles.preferenceDate}>Días y horarios de preferencia</p>
      <p className={styles.locality}>Zona/Localidad</p>
      <p className={styles.state}>Estado</p>
      <p className={styles.od}>Ortodoncista</p>
      <p className={styles.referral}>Fecha de Derivación</p>
    </div>
  )
}
