import React from 'react'
import TreatmentStateStepBar from '../../../../components/TreatmentStateStepBar'
import '../styles/styles.css'
import moment from 'moment'

const NotificationCard4 = ({ data }) => {
  const { treatment } = data
  return (
    <div className="w-100 notificationCard my-2 p-3 d-flex">
      <div className=" middleLine pe-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap">
            <div className="circleNotification">
              {treatment.frontal_sonriendo !== null && (
                <img src={treatment.frontal_sonriendo} alt="avatar" />
              )}
            </div>
            <div className="d-flex flex-column ms-2">
              <p>{`${treatment.patient.name} ${treatment.patient.surname}`}</p>
              <p className="fw-bold">En Producción</p>
            </div>
          </div>
          <div>
            <p className="text-muted notificationTime">
              {`${moment(data.created_at).startOf('minutes').fromNow()}`}
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="circleDisabled"></div>
          <div>
            <p className="ms-2 mt-2 descriptionCard">
              ¡Los Alineadores de su paciente ya están en producción!
            </p>
          </div>
        </div>
      </div>
      <div className="treatment-state-notifiaction-dentist">
        <TreatmentStateStepBar type={4} />
      </div>
    </div>
  )
}

export default NotificationCard4
