import React from 'react'
import notFound from '../../assets/icons/not-found.svg'

const NotFoundComponent = () => {
  return (
    <div className="d-flex flex-column my-5">
      <img height={220} src={notFound} alt="not-found-img" />
      <h2 className="fw-bold text-primary text-center mt-4">
        No se encontraron resultados para tu búsqueda
      </h2>
    </div>
  )
}

export default NotFoundComponent
