import React from 'react'
import { HelpCircle } from 'react-feather'
import { TreatmentIndicationsStepBar } from '../../Utilities/TreatmentIndicationsStepBar'
import Apiñamiento from './Substeps/Apiñamiento'
import AuxiliarResources from './Substeps/AuxiliarResources'
import CanineRelation from './Substeps/CanineRelation'
import CrossBite from './Substeps/CrossBite'
import Diastemas from './Substeps/Diastemas'
import ExtraInformation from './Substeps/ExtraInformation'
import MiddleLine from './Substeps/MiddleLine'
import MolarRelation from './Substeps/MolarRelation'
import Overbite from './Substeps/Overbite'
import Overjet from './Substeps/Overjet'
import SequentialDistillation from './Substeps/SequentialDistillation'
import BackSpaceGain from './Substeps/BackSpaceGain'
import Spaces from './Substeps/Spaces'
import PreviousSpaceGain from './Substeps/PreviousSpaceGain'

const TreatmentIndications = ({ formData, documentationData }) => {
  return (
    <>
      <div className="w-100 d-flex mt-5">
        <div className="step-title mx-3 d-flex align-items-end flex-column">
          <div>
            <p className="fs-4 fw-bold text-end p-2 m-0 pe-1">
              Indicaciones de
              <br />
              tratamiento
            </p>
          </div>
          <div className="title-sub-bar mb-2"></div>
          <HelpCircle />
          <TreatmentIndicationsStepBar />
        </div>
        <div className="d-flex flex-column diagnosis-container">
          <MiddleLine formData={formData} />
          <Overjet formData={formData} />
          <Overbite formData={formData} />
          <CanineRelation formData={formData} />
          <MolarRelation formData={formData} />
          <CrossBite formData={formData} />
          <Apiñamiento formData={formData} />
          <BackSpaceGain formData={formData} />
          <PreviousSpaceGain formData={formData} />
          <Diastemas formData={formData} />
          <Spaces formData={formData} />
          <SequentialDistillation formData={formData} />
          <AuxiliarResources formData={formData} />
        </div>
      </div>
      <ExtraInformation
        formData={formData}
        documentationData={documentationData}
      />
    </>
  )
}

export default TreatmentIndications
