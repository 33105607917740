import React from 'react'
import priceListMex from '../../../assets/images/KSPreciosMex.jpg'

const PriceListMexico = () => {
  return (
    <div className="d-flex justify-content-center">
      <img
        src={priceListMex}
        alt="Price List Mexico"
        style={{ width: '67vw' }}
      />
    </div>
  )
}

export default PriceListMexico
