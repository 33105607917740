import React from 'react'
import '../../styles/styles.css'
import gold from '../../../../../assets/icons/ks-categoriasgold.svg'
import black from '../../../../../assets/icons/ks-categoriasblack.svg'
import platinum from '../../../../../assets/icons/ks-categoriasplatinum.svg'
import elite from '../../../../../assets/icons/ks-categoriaselite.svg'
import PropTypes from 'prop-types'

const BenefitCard = ({ countryId }) => {
  return (
    <div className="d-flex justify-content-start mt-5 benefitsCardContainer">
      <div className="p-3 w-25 card first">
        <img src={gold} alt="gold" className="coinCard" />
        <div className="d-flex h-100 flex-column justify-content-between ">
          <div className="align-self-center text-center">
            <p
              className="mt-5 fs-5 fw-bold smileTitle dataContainer"
              style={{ color: '#DBB307' }}
            >
              Smile Creator Gold
            </p>
            <p className="text-muted" style={{ fontSize: '14px' }}>
              Entre 5 y 19 casos*
            </p>
            <ul className="list-unstyled cardListBenefit">
              <li
                className="fw-bold"
                style={{ color: '#2651E8', fontSize: '17px' }}
              >
                5% de descuento
              </li>
              {countryId === 1 ? (
                <li>Blanqueador incluido en todos los tratamientos.</li>
              ) : (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
              <li>Participación sin cargo en los StudyGroups.</li>
              <li>
                Exposición <span>media</span> en la web y redes sociales.
              </li>
              {countryId === 1 && (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
            </ul>
          </div>
          <p className="ms-4 footerCard">
            *Cualquier caso de primera etapa ingresado en el último año.
          </p>
        </div>
      </div>
      <div className="p-3 w-25 card">
        <img src={platinum} alt="platinum" className="coinCard" />

        <div
          className="d-flex 
         h-100 
        justify-content-between flex-column"
        >
          <div className="mt-5 align-self-center text-center dataContainer">
            <p className="fs-5 fw-bold smileTitle" style={{ color: '#575757' }}>
              Smile Creator Platinum
            </p>
            <p className="text-muted" style={{ fontSize: '14px' }}>
              Entre 20 y 34 casos*
            </p>
            <ul className="list-unstyled cardListBenefit">
              <li
                className="fw-bold"
                style={{ color: '#2651E8', fontSize: '17px' }}
              >
                10% de descuento
              </li>
              <li>
                <span>Línea de crédito de cuenta corriente.</span>
              </li>
              {countryId === 1 ? (
                <li>Blanqueador incluido en todos los tratamientos.</li>
              ) : (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
              <li>Participación sin cargo en los StudyGroups.</li>
              <li>
                Exposición <span>alta</span> en la web y redes sociales.
              </li>
              {countryId === 1 && (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
            </ul>
          </div>
          <p className="ms-4 footerCard">
            *Cualquier caso de primera etapa ingresado en el último año.
          </p>
        </div>
      </div>
      <div className="p-3 w-25 card">
        <img src={black} alt="black" className="coinCard" />

        <div className="d-flex h-100 justify-content-between flex-column">
          <div className="mt-5 align-self-center text-center dataContainer">
            <p className="fs-5 fw-bold smileTitle">Smile Creator Black</p>
            <p className="text-muted" style={{ fontSize: '14px' }}>
              Entre 35 y 49 casos*
            </p>
            <ul className="list-unstyled cardListBenefit">
              <li
                className="fw-bold"
                style={{ color: '#2651E8', fontSize: '17px' }}
              >
                15% de descuento
              </li>
              {countryId === 1 && (
                <>
                  <li>
                    <span>Servicio de Community Manager.</span>
                  </li>
                  <li>
                    <span>Sesión fotográfica en el consultorio.</span>
                  </li>
                </>
              )}
              {countryId === 1 ? (
                <li>Línea de crédito de cuenta corriente.</li>
              ) : (
                <li>
                  <span>Línea de crédito de cuenta corriente.</span>
                </li>
              )}
              {countryId === 1 ? (
                <li>Blanqueador incluido en todos los tratamientos.</li>
              ) : (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
              <li>Participación sin cargo en los StudyGroups.</li>
              {countryId !== 1 && (
                <>
                  <li>
                    <span>Servicio de Community Manager.</span>
                  </li>
                  <li>
                    <span>Sesión fotográfica en el consultorio.</span>
                  </li>
                </>
              )}
              <li>
                Exposición <span>muy alta</span> en la web y redes sociales.
              </li>
              {countryId === 1 && (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
            </ul>
          </div>
          <p className="ms-4 footerCard">
            *Cualquier caso de primera etapa ingresado en el último año.
          </p>
        </div>
      </div>
      <div className="p-3 w-25 card last">
        <img src={elite} alt="elite" className="coinCard" />

        <div className="d-flex justify-content-between flex-column">
          <div className="mt-5 align-self-center text-center  dataContainer">
            <p className="fs-5 fw-bold smileTitle" style={{ color: '#001D57' }}>
              Smile Creator Elite
            </p>
            <p className="text-muted" style={{ fontSize: '14px' }}>
              Más de 50 casos*
            </p>
            <ul className="list-unstyled cardListBenefit">
              <li
                className="fw-bold"
                style={{ color: '#2651E8', fontSize: '17px' }}
              >
                20% de descuento
              </li>
              {countryId === 1 && (
                <>
                  <li>
                    <span>Servicio de Community Manager.</span>
                  </li>
                  <li>
                    <span>Sesión fotográfica en el consultorio.</span>
                  </li>
                </>
              )}
              {countryId === 1 ? (
                <li>Línea de crédito de cuenta corriente.</li>
              ) : (
                <li>
                  <span>Línea de crédito de cuenta corriente.</span>
                </li>
              )}
              {countryId === 1 ? (
                <li>Blanqueador incluido en todos los tratamientos.</li>
              ) : (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
              <li>Participación sin cargo en los StudyGroups.</li>
              {countryId !== 1 && (
                <>
                  <li>
                    <span>Servicio de Community Manager.</span>
                  </li>
                  <li>
                    <span>Sesión fotográfica en el consultorio.</span>
                  </li>
                </>
              )}
              <li>
                Exposición <span>destacada</span> en la web y redes sociales.
              </li>
              {countryId === 1 && (
                <li>
                  Más de 1500 beneficios con <span>Club KeepSmiling.</span>
                </li>
              )}
            </ul>
          </div>
          <p className="ms-4 footerCard">
            *Cualquier caso de primera etapa ingresado en el último año.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BenefitCard

BenefitCard.propTypes = {
  countryId: PropTypes.number.isRequired,
}
