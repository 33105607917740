import React, { useEffect, useState } from 'react'
import { Check } from 'react-feather'

export const StepBar = ({
  state,
  stageTwoFinish,
  stageThreeFinish,
  stageFourFinish,
}) => {
  const [step, setStep] = useState(null)

  useEffect(() => {
    setStep(state.step)
  }, [state.step])

  const widths = {
    1: '0%',
    2: '25%',
    3: '50%',
    4: '75%',
    5: '100%',
    6: '100%',
  }

  return (
    <div
      className="col-12 mt-5 mb-5 mx-auto"
      style={{ position: 'relative', maxWidth: '70vw' }}
      id="timeline"
    >
      <ul
        className="w-100"
        style={{
          background: '#ECEEF1',
          height: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          listStyleType: 'none',
          position: 'relative',
        }}
      >
        {/* marco gris del stepbar */}
        <li
          style={{
            width: '110%',
            height: '100%',
            border: '3px solid #ECEEF1',
            position: 'absolute',
            padding: '23px',
            top: '-20px',
            borderRadius: '30px',
            left: '-3%',
            right: '0',
          }}
        ></li>
        <li
          style={{
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            background: step > 1 ? '#2ecc71' : '#ECEEF1',
            position: 'absolute',
            top: '-13px',
            zIndex: 10,
            left: '0',
          }}
        >
          {step >= 1 && (
            <Check style={{ marginTop: 6, marginLeft: 5, color: '#fff' }} />
          )}
          <p
            style={{
              position: 'absolute',
              top: '50px',
              width: '100px',
              textAlign: 'center',
              left: '-30px',
              fontWeight: '500',
              fontSize: '.74rem',
            }}
          >
            Datos del Paciente
          </p>
        </li>
        <li
          style={{
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            background:
              step > 2 ? (stageTwoFinish ? '#2ecc71' : '#DC3636') : '#ECEEF1',
            position: 'absolute',
            top: '-13px',
            zIndex: 10,
            left: '25%',
          }}
        >
          {step >= 2 &&
            (stageTwoFinish ? (
              <Check style={{ marginTop: 6, marginLeft: 5, color: '#fff' }} />
            ) : (
              <div
                style={{
                  fontSize: '25px',
                  marginTop: 5,
                  marginLeft: 13,
                  color: '#fff',
                }}
              >
                !
              </div>
            ))}
          <p
            style={{
              position: 'absolute',
              top: '50px',
              width: '100px',
              textAlign: 'center',
              left: '-30px',
              fontWeight: '500',
              fontSize: '.74rem',
            }}
          >
            Adjuntar Fotos
          </p>
        </li>
        <li
          style={{
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            background:
              step > 3 ? (stageThreeFinish ? '#2ecc71' : '#DC3636') : '#ECEEF1',
            position: 'absolute',
            top: '-13px',
            zIndex: 10,
            left: '50%',
          }}
        >
          {step >= 3 &&
            (stageThreeFinish ? (
              <Check style={{ marginTop: 6, marginLeft: 5, color: '#fff' }} />
            ) : (
              <div
                style={{
                  fontSize: '25px',
                  marginTop: 5,
                  marginLeft: 13,
                  color: '#fff',
                }}
              >
                !
              </div>
            ))}

          <p
            style={{
              position: 'absolute',
              top: '50px',
              width: '100px',
              textAlign: 'center',
              left: '-30px',
              fontWeight: '500',
              fontSize: '.74rem',
            }}
          >
            Adjuntar Estudios
          </p>
        </li>
        <li
          style={{
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            background:
              step > 4 ? (stageFourFinish ? '#2ecc71' : '#DC3636') : '#ECEEF1',
            position: 'absolute',
            top: '-13px',
            zIndex: 10,
            left: '75%',
          }}
        >
          {step >= 4 &&
            (stageFourFinish ? (
              <Check style={{ marginTop: 6, marginLeft: 5, color: '#fff' }} />
            ) : (
              <div
                style={{
                  fontSize: '25px',
                  marginTop: 5,
                  marginLeft: 13,
                  color: '#fff',
                }}
              >
                !
              </div>
            ))}
          <p
            style={{
              position: 'absolute',
              top: '50px',
              width: '100px',
              textAlign: 'center',
              left: '-30px',
              fontWeight: '500',
              fontSize: '.74rem',
            }}
          >
            Adjuntar Plan de Tratamiento
          </p>
        </li>
        <li
          style={{
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            background: step > 5 ? '#2ecc71' : '#ECEEF1',
            position: 'absolute',
            top: '-13px',
            zIndex: 10,
            left: '100%',
          }}
        >
          {step >= 5 && (
            <Check style={{ marginTop: 6, marginLeft: 5, color: '#fff' }} />
          )}
          <p
            style={{
              position: 'absolute',
              top: '50px',
              width: '100px',
              textAlign: 'center',
              left: '-30px',
              fontWeight: '500',
              fontSize: '.74rem',
            }}
          >
            Envio de Registro
          </p>
        </li>
      </ul>
      <ul
        style={{
          background: '#2ecc71',
          height: '10px',
          width: widths[step],
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          listStyleType: 'none',
          position: 'absolute',
          top: '0',
          zIndex: 9,
        }}
      ></ul>
    </div>
  )
}
