import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { CarouselItem } from "reactstrap";
import { main_menu, sub_menu, sub_menu_two } from "../dashboard/demoData";
//import { Book, HelpCircle, Bookmark, PenTool, Monitor, User, Video } from 'react-feather'

function CentroDeAyudaChild(props){
    const { child_menu, goSubMenu, parent } = props
    //const [data_menu,setDataMenu]=useState({})
    // useEffect(()=>{
    //     if(!props.location.state){
    //         props.history.push({
    //             pathname: '/centrodeayuda'
    //         })
    //         return
    //     }
    //     setDataMenu({
    //         idParent: props.location.state.idParent,
    //         submenu_type: props.location.state.submenu_type
    //     })
    // },[props])
    // const getIcon = (icon ) =>{
    //     try{
    //         const item_icon = require(`../../../assets/images/Centro de Ayuda/${icon}`).default
    //         return <img style={{width:'60px',height:'70px'}} 
    //         src={item_icon} alt={icon} />   
    //     }catch{
    //         return null
    //     }
        
    // }
    // const getParentMenu=()=>{
    //     return main_menu.find(menu=>menu.id===data_menu.idParent)
    // }
    // const getSubMenu=()=>{
    //     return sub_menu.find(menu=>menu.id===data_menu.idParent)
    // }
    // const goSubMenu=(item)=>{
    //     if(item.has_sub_menu){
    //         props.history.push({
    //             pathname: item.path,
    //             //search: '?query=abc',
    //             state: { idParent: item.id, submenu_type:(data_menu.submenu_type+1) }
    //           })
    //     }
        
    // }
    
    // const parent = () => {
    //     if(data_menu.submenu_type===1){
    //         return getParentMenu()
    //     }else if(data_menu.submenu_type===2){
    //         return getSubMenu()
    //     }
    // }
    return (
        <Fragment>
            <div className="w-100 d-flex justify-content-center py-5">
            <div className='d-flex align-items-center w-90'>
                {/* {getIcon(parent()?.icon) && 
                <div className='d-flex align-items-center justify-content-center w-10'>
                    {getIcon(parent()?.icon)}
                </div>
                } */}
                <div className='d-flex flex-row align-items-center'>
                    <div className="d-flex flex-column bg-transparent">
                        <p className={`h1 bold`}>{parent.name}</p>
                        <p className="small">{parent.body}</p>
                    </div>
                </div>
            </div>
            </div>
             {child_menu?.map((item, key)=>
                <div className="w-100 d-flex justify-content-center" key={key} onClick={()=>goSubMenu(item)}>
                    <div className="grow_hover p-5 w-90 d-flex flex-row bg-white shadow rounded m-0 px-10 py-3 my-2 align-items-center tc-container">
                            <div className='d-flex flex-row align-items-center w-75'>
                                <div className="d-flex flex-column bg-transparent">
                                    <p className="h3">{item.name}</p>
                                </div>
                            </div>
                    </div>
                </div>
                )
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(CentroDeAyudaChild);