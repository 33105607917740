import React from 'react'
import { HelpCircle } from 'react-feather'
import { PrevDiagnosisStepBar } from '../../Utilities/PrevDiagnosisStepBar'
import Attachments from './Substeps/Attachments'
import Extractions from './Substeps/Extractions'
import Movements from './Substeps/Movements'
import VirtualTeeths from './Substeps/VirtualTeeths'

const PreviousDiagnosis = ({ formData }) => {
  return (
    <div className="w-100 d-flex mt-5">
      <div className="step-title mx-3 d-flex align-items-end flex-column">
        <div>
          <p className="fs-4 fw-bold text-end p-2 m-0 pe-1">
            Diagnóstico previo
            <br /> al tratamiento
          </p>
        </div>
        <div className="title-sub-bar mb-2"></div>
        <HelpCircle />
        <PrevDiagnosisStepBar />
      </div>
      <div className="d-flex flex-column diagnosis-container">
        <Movements formData={formData} />
        <Extractions formData={formData} />
        <VirtualTeeths formData={formData} />
        <Attachments formData={formData} />
      </div>
    </div>
  )
}

export default PreviousDiagnosis
