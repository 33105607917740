import React from 'react'
import '../../../style.css'

const VirtualTeeths = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Dientes Virtuales</p>
      <hr className="mt-2" />
      <div className="d-flex flex-column">
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="virtualTeeths"
            checked={formData?.virtual_tooth == 1 && true}
            disabled
          />
          <label className="ms-2">
            Aplicar dientes virtuales para cerrar espacios de extracciones
          </label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="virtualTeeths"
            checked={formData?.virtual_tooth == 2 && true}
            disabled
          />
          <label className="ms-2">
            NO aplicar dientes virtuales para cerrar espacios de extracciones
          </label>
        </div>
      </div>
      <p className="mt-5">Puede detallar a continuación:</p>
      <div className="step-movements-text-area">
        {formData?.virtual_tooth_description}
      </div>
    </div>
  )
}

export default VirtualTeeths
