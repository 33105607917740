import apiClient from '../../../utils/backend'

export const createClaim = async (claim) => {
  if (!claim) return console.log('props are required')

  try {
    const res = await apiClient.post(`api/claims`, claim)
    return res
  } catch (err) {
    return err
  }
}
