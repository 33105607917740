import React, { Fragment, useState } from "react";
import Card from "./components/card";
import ElegirPaciente from "./components/elegir_paciente";
import TiposConsulta from "./components/tipos_consulta";
import Form from "./components/form";
import Resumen from "./components/resumen";

import smile from "../../assets/images/ks-smile.svg";

const cards = [
  {
    img: null,
    title: "Clinica",
    description:
      "Su consulta va a ser recibida por el equipo de Ortodoncia de KeepSmiling.",
    choosePatient: true,
  },
  {
    img: null,
    title: "Atencion",
    description:
      "Su consulta va a ser recibida por el equipo de Atención al Odontologo.",
    choosePatient: true,
  },
  {
    img: null,
    title: "Marketing",
    description:
      "Su consulta va a ser recibida por el equipo de Marketing de KeepSmiling",
    choosePatient: false,
  },
  {
    img: null,
    title: "Facturacion",
    description:
      "Su consulta va a ser recibida por el equipo de Cobranzas de KeepSmiling",
    choosePatient: false,
  },
];

const MENU_WITH_PATIENT = [
  "categoria",
  "paciente",
  "tipo_consulta",
  "form",
  "resumen",
];
const MENU_WITHOUT_PATIENT = ["categoria", "tipo_consulta", "form", "resumen"];

function Ayuda() {
  const [step, setStep] = useState("categoria");
  const [categoria, setCategoria] = useState(null);
  const [paciente, setPaciente] = useState(null);
  const [tipoConsulta, setTipoConsulta] = useState(null);
  const [text, setText] = useState(null);

  const next = () => {
    let menuWithPatient = cards.find((e) => e.title === categoria);
    console.log(menuWithPatient);
    let next;

    if (menuWithPatient) {
      let current = MENU_WITH_PATIENT.findIndex((e) => e === step);
      next = MENU_WITH_PATIENT[current + 1];
    } else {
      let current = MENU_WITHOUT_PATIENT.findIndex((e) => e === step);
      next = MENU_WITHOUT_PATIENT[current + 1];
    }

    setStep(next);
  };

  const back = () => {
    let menuWithPatient = cards.find(
      (e) => e.title === categoria
    ).choosePatient;
    let back;

    if (menuWithPatient) {
      let current = MENU_WITH_PATIENT.findIndex((e) => e === step);
      back = MENU_WITH_PATIENT[current - 1];
    } else {
      let current = MENU_WITHOUT_PATIENT.findIndex((e) => e === step);
      back = MENU_WITHOUT_PATIENT[current - 1];
    }

    setStep(back);
  };

  return (
    <Fragment>
      {step === "categoria" && (
        <div className="container-fluid d-flex flex-column text-primary oveflow-hidden">
          <div className="row">
            <div className="col-12 position-relative">
              <div className="pagos-bg" style={{ height: "175px" }} />
            </div>
          </div>

          <div
            className="row pt-2 mt-4 px-5"
            style={{ zIndex: 1, height: "175px" }}
          >
            <div className="col-12 px-4" style={{ zIndex: 1 }}>
              <img src={smile} style={{ maxWidth: "75px", margin: 0 }} alt="" />
              <h2 className="text-white fw-bold">Estamos para ayudarte</h2>
              <h4 className="text-white">
                Para una mejor atención, a continuación clasifica el tipo de
                consulta que quieres realizar
              </h4>
            </div>
          </div>

          <div className="mt-4 mx-auto d-flex  flex-row justify-content-around">
            {cards.map((card) => (
              <Card
                data={card}
                next={next}
                setCategoria={setCategoria}
                setPaciente={setPaciente}
              />
            ))}
          </div>
          <p className="text-center mt-5 fs-5">
            En caso de ser una consulta de <strong>urgencia</strong> puede
            comunicarse por WhatsApp haciendo click{" "}
            <a href="" style={{ borderBottom: "1px dotted blue" }}>
              Aquí
            </a>
          </p>
        </div>
      )}

      {step === "paciente" && (
        <ElegirPaciente setPaciente={setPaciente} categoria={categoria} />
      )}

      {step === "tipo_consulta" && (
        <TiposConsulta
          paciente={paciente}
          categoria={categoria}
          setTipoConsulta={setTipoConsulta}
          setStep={setStep}
        />
      )}

      {step === "form" && (
        <Form paciente={paciente} categoria={categoria} setText={setText} />
      )}

      {step === "resumen" && (
        <Resumen
          paciente={paciente}
          categoria={categoria}
          tipoConsulta={tipoConsulta}
          text={text}
        />
      )}

      <div className="d-flex flex-row justify-content-center">
        {step !== "categoria" && (
          <button
            onClick={back}
            style={{ width: 150 }}
            className="btn border-primary text-primary rounded-pill mt-3"
          >
            Volver
          </button>
        )}
        {step !== "categoria" && step !== "form" && step !== "resumen" && (
          <button
            onClick={next}
            style={{ width: 150 }}
            className="btn border-primary text-primary rounded-pill mt-3 mx-3"
          >
            Continuar
          </button>
        )}
        {step === "form" && (
          <button
            onClick={next}
            style={{ width: 150 }}
            className="btn border-primary text-primary rounded-pill mt-3 mx-3"
          >
            Enviar
          </button>
        )}
      </div>
    </Fragment>
  );
}

export default Ayuda;
