import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import kssmile from '../../assets/images/ks-smile.png'
import './index.css'

const Solicitud = () => {
  const { countryId } = useSelector((state) => state.userReducer)

  return (
    <>
      <div className=" mb-4 title_container px-4 py-5">
        <img src={kssmile} alt={'ksmile'} className="smile_image" />
        <h2 className="text-white fw-bold">Crear una Nueva Solicitud</h2>
        <p className="text-white fs-4">
          A continuación puede elegir el tipo de solicitud que desea crear.
        </p>
      </div>
      <div className="px-4 py-4">
        <div className="d-flex flex-wrap justify-content-center  text-center">
          <div className="  p-3">
            <div
              className="border border-3 shadow d-flex flex-column p-4 justify-content-between solicitudCard"
              style={{
                width: '280px',
                borderRadius: '20px',
                height: '250px',
                paeddingTop: '2.5rem',
              }}
            >
              <h4 className="text-primary fw-bold">Nuevo Tratamiento</h4>
              <p className="text-primary mt-2">
                Solicitud de Tratamiento para un nuevo paciente que comienza un
                tratamiento de KeepSmiling.
              </p>
              <Link
                to="/od/ingresos/nuevo"
                className="btn btn-primary rounded-pill mb-3"
              >
                Crear una solicitud
              </Link>
            </div>
          </div>
          <div className="  p-3">
            <div
              className="border border-3 shadow d-flex flex-column p-4 justify-content-between solicitudCard"
              style={{
                width: '280px',
                borderRadius: '20px',
                height: '250px',
                paeddingTop: '2.5rem',
              }}
            >
              <h4 className="text-primary fw-bold">Nueva Etapa</h4>
              <p className="text-primary mt-2">
                Solicitud de Nueva Etapa para un paciente existente que continúa
                con su tratamiento.
              </p>
              <Link
                to="/od/etapa/nuevo"
                className="btn btn-primary rounded-pill mb-3 "
              >
                Crear una solicitud
              </Link>
            </div>
          </div>
          <div className="  p-3">
            <div
              className="border border-3 shadow d-flex flex-column p-4 justify-content-between solicitudCard"
              style={{
                width: '280px',
                borderRadius: '20px',
                height: '250px',
              }}
            >
              <h4 className="text-primary fw-bold">Viabilidad</h4>
              <p className="text-primary mt-2" style={{ height: '64px' }}>
                Solicitud de consulta de Viabilidad de un tratamiento.
              </p>
              <Link
                // to="/od/viabilidad/nuevo"
                className="btn btn-primary rounded-pill mb-3  viabilityBTN"
              >
                ¡Proximamente!
              </Link>
            </div>
          </div>
          <div className="col-12 text-center">
            <p className="text-center text-primary mx-auto mt-4">
              Todas las solicitudes enviadas serán revisadas por el equipo de
              KeepSmiling y usted recibirá un seguimiento por parte del equipo.
              Una solicitud no es un paciente ingresado aún.
            </p>
            <p className="text-center text-primary mx-auto mt-4">
              Para cualquier consulta, puede comunicarse al
              {countryId === 7 && <span> 55 4914 9356</span>}
              {countryId === 4 && <span> 57 320 4669215</span>}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Solicitud
