import moment from 'moment';
import './index.css'

export const TreatmentCard = (props) => {
    const {
        title,
        date,
        order,
        id,
        handleCardClick,
    } = props;

    return (
        <div className="history-card col-4 p-4 btn text-start mt-4" style={{marginRight: '20px',maxHeight:"200px"}} onClick={ () => handleCardClick(id) }>
                <div className="col-12">
                    <h3 className="text-white fw-bold">{title}</h3>
                <div className="row">
                    <p className="text-white fw-light">{moment(date).format('DD-MM-YYYY')}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-10 mt-4 d-flex flex-column position-relative">
                    <h1 style={{color: '#002541', position: 'absolute', right: '-50px', fontSize: '5em', fontWeight: 'bold'}}>{order}</h1>
                </div>
            </div>
        </div>
    
    )
}
