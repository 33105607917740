import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import MobileSidebar from '../components/Sidebar/MobileSidebar/MobileSidebar'
import Navbar from '../components/Navbar/Navbar'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../components/LoginModal/CustomModal'
import apiClient from '../utils/backend'

const validate = () => {
  // Solo Abel y dios sabian como funcionaba esto, ahora solo lo sabe dios.
  const path = window.location.href.toUpperCase()
  return (
    path.search('LOGIN') > -1 ||
    path.search('PASSWORDRESET') > -1 ||
    path.search('CHANGEPASSWORD') > -1
  )
}

const Layout = ({ children }) => {
  const [onboardingData, setOnboardingData] = useState()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  const hangleGetOnboarding = async () => {
    try {
      const res = await apiClient(`api/onboarding`)
      setOnboardingData(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let previousPageWidth = window.innerWidth
    window.addEventListener('resize', () => {
      // Check if go from Mobile to Desk
      if (window.innerWidth >= 1000 && previousPageWidth < 1000)
        dispatch({ type: 'UPDATE_WIDTH', payload: 'desktop' })

      // Check if go from Desk to Mobile
      if (window.innerWidth <= 1000 && previousPageWidth > 1000)
        dispatch({ type: 'UPDATE_WIDTH', payload: 'mobile' })

      previousPageWidth = window.innerWidth
    })

    return () => {
      window.removeEventListener('resize', null)
    }
  }, [dispatch])

  useEffect(() => {
    hangleGetOnboarding()
  }, [])

  if (pageWidth === 'desktop')
    return (
      <div className="h-100">
        {!validate() && <Sidebar />}
        <div
          // Tocar esto es lava att. Abel
          id={validate() ? 'content-full' : 'content'}
          className={validate() ? 'container-fluid' : 'container'}
        >
          {!validate() && (
            <>
              {!loading && <CustomModal onboardingData={onboardingData} />}
              <Navbar />
            </>
          )}
          <ToastContainer
            limit={1}
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
          />
          {children}
        </div>
      </div>
    )
  else
    return (
      <div className="h-100" style={{ overflowX: 'hidden' }}>
        {!validate() && (
          <>
            <MobileSidebar />
            <Navbar />
            {!loading && <CustomModal onboardingData={onboardingData} />}
          </>
        )}
        <ToastContainer
          limit={1}
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
        />
        {children}
      </div>
    )
}
export default Layout
