import React, { useState, useEffect } from 'react'
import { ChevronLeft } from 'react-feather'
import { StepBar } from './components/StepBar'
import { type_dispatch } from '../../FormularioOnline/propsStateConnect'
import { TreatmentStepFour } from './Stages/TreatmentStepFour'
import { TreatmentStepFive } from '../components/TreatmentStepFive'
import { TreatmentStepSix } from './Stages/TreatmentStepSix'
import frontalsonriendo from '../../../assets/images/frontalsonriendo.png'
import { useDispatch, useSelector } from 'react-redux'
import './Stages/index.css'
import Loader from '../../../components/loader'
import { TreatmentStepOne } from './Stages/TreatmentStepOne'
import { TreatmentAdminStepThree } from '../components/TreatmentAdminStepThree'
import { TreatmentAdminStepTwo } from '../components/TreatmentAdminStepTwo'
import handleToast from '../../../components/toaster'

const NewTreatmentSolicitud = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const { dentistId, name, surname } = useSelector((state) => state.userReducer)
  const [planFiles, setPlanFiles] = useState([])
  const [stageOneFinish, setStageOneFinish] = useState(false)
  const [stageTwoFinish, setStageTwoFinish] = useState(false)
  const [stageThreeFinish, setStageThreeFinish] = useState(false)
  const [stageFourFinish, setStageFourFinish] = useState(false)
  const [stageFiveFinish, setStageFiveFinish] = useState(false)
  const [photosFiles, setPhotosFiles] = useState([])
  const [studiesFiles, setStudiesFiles] = useState([])
  const [state, setState] = useState({
    dentist_id: dentistId,
    numPages: null,
    pageNumber: 1,
    treatment_id: null,
    plantratamiento: null,
    plantratamiento_image: null,
    step: 1,
    loading: false,
    uploaded: false,

    gender_string: null,
    od_list: null,
    nombre: null,
    apellido: null,
    external_id: null,
    genero: null,
    gender_select: null,
    maxilla: null,
    maxilar: 0,
    maxilar_string: '',
    odontologo: {
      id: dentistId,
      value: dentistId,
      name: name,
      surname: surname,
      label: `${name}, ${surname}`,
    },
    treatment_type: 1,
    intraoral: null,
    is3Shape: false,
    isWeTransfer: false,
    etapa: 1,
    is_new_patient: true,
    od_patients: [],
    selected_patient: null,
    selected_od: null,
    intraoral_file: false,
    online_plan: false,
    image_plan: false,
    dentistOffices: null,
    newIntraoralScanDate: null,
    sendImpressions: {},

    fotos: {},
    fotos_files: [],
    estudios: {},
    estudios_files: [],
    plan: {},

    registration_datetime: null,
    scan_file: null,

    // STEP DATA
    flag_photos: 0,
    flag_studies: 0,
    flag_plan: 0,

    // ERROR HANDLER
    error: {
      error: false,
      message: '',
    },

    // CROPPER
    cropper: false,
    cropper_img: null,

    errors: [],
  })
  const dispatch = useDispatch()

  const stageTitleArray = [
    null,
    'Cargar Datos',
    'Adjuntar Fotos',
    'Adjuntar Estudios',
    'Adjuntar Plan de Tratamiento',
    'Envío de Registro',
  ]

  const text = (
    <p className="text-center">
      Recuerde que puede cargar fotos y estudios en otro momento. Podrá acceder
      a sus pacientes con acciones requeridas para adjuntar las fotos o estudios
      faltantes, <br />
      <b>
        KeepSmiling no dará comienzo al tratamiento hasta no recibir toda la
        documentación.
      </b>
    </p>
  )

  const stageFooterArray = [
    null,
    <p className="text-center">
      Todas las solicitudes enviadas, serán revisadas por el equipo de
      KeepSmiling y usted recibirá un seguimiento por parte del equipo. Una
      solicitud no es un paciente ingresado aún. <br />
      Para cualquier consulta, puede comunicarse por ... al XXXXXXXXXXXXXXXX
    </p>,
    text,
    text,
    text,
    text,
  ]

  type_dispatch.step = 'Header'
  type_dispatch.field = 'MainInformation'

  const handleChangeStep = () => {
    if (!stageOneFinish)
      return handleToast('error', 'Todos los campos son requeridos')

    if (state.step === 5 && !stageFiveFinish)
      return handleToast('error', 'Todos los campos son requeridos')

    setState({ ...state, step: state.step + 1 })
  }

  const checkFrotalSonriendo = () => {
    if (state?.frontal_sonriendo) return state.frontal_sonriendo

    if (state?.selected_patient?.patientImage)
      return state?.selected_patient?.patientImage

    return frontalsonriendo
  }

  useEffect(() => {
    // Validate Step 1
    if (
      state.nombre &&
      state.apellido &&
      state.genero &&
      state.maxilla &&
      state.odontologo
    ) {
      setStageOneFinish(true)
    } else setStageOneFinish(false)

    // Validate Step 2
    let i = 0
    const values = Object.values(state.fotos)
    values.forEach((value) => {
      if (value !== null) ++i
    })
    if (i > 9) setStageTwoFinish(true)
    else setStageTwoFinish(false)

    // Validate Step 3
    if (state.telerradiografia && state.rxpanoramica && state.ricketts) {
      setStageThreeFinish(true)
    } else setStageThreeFinish(false)

    //Validate Step 4
    if (planFiles.length || state?.online_form_state) {
      setStageFourFinish(true)
    } else setStageFourFinish(false)

    //Validate Step 5
    if (state) {
      if (state.intraoral === 1 && !state.isWeTransfer && !state.is3Shape) {
        return setStageFiveFinish(false)
      }
      if (state.newIntraoralScanDate) return setStageFiveFinish(true)
      if (
        state.sendImpressions.takeover_date &&
        state.sendImpressions.dateToGet &&
        state.sendImpressions.withdrawalAddress
      ) {
        return setStageFiveFinish(true)
      }
      setStageFiveFinish(false)
    }
  }, [state])

  useEffect(() => {
    localStorage.removeItem('persist:primary')
    dispatch({ type: 'clear_form_online', payload: {} })
  }, [dispatch])

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <div>
          <div className="col-12 mx-auto text-center mt-4">
            <h2 style={{ fontWeight: 700, cursor: 'pointer' }} className="px-4">
              Nueva Solicitud de Ingreso de Tratamiento
            </h2>
            <h5>{stageTitleArray[state.step]}</h5>
          </div>
          {pageWidth === 'desktop' && (
            <StepBar
              state={state}
              stageTwoFinish={stageTwoFinish}
              stageThreeFinish={stageThreeFinish}
              stageFourFinish={stageFourFinish}
            />
          )}

          {state.step === 1 && (
            <TreatmentStepOne state={state} setState={setState} />
          )}

          {state.step === 2 && (
            <TreatmentAdminStepTwo
              state={state}
              setState={setState}
              photosFiles={photosFiles}
              setPhotosFiles={setPhotosFiles}
            />
          )}

          {state.step === 3 && (
            <TreatmentAdminStepThree
              state={state}
              setState={setState}
              studiesFiles={studiesFiles}
              setStudiesFiles={setStudiesFiles}
            />
          )}

          {state.step === 4 && (
            <TreatmentStepFour
              planFiles={planFiles}
              setPlanFiles={setPlanFiles}
              state={state}
              setState={setState}
              checkFrotalSonriendo={checkFrotalSonriendo}
            />
          )}

          {state.step === 5 && (
            <TreatmentStepFive state={state} setState={setState} />
          )}

          {state.step === 6 && (
            <TreatmentStepSix
              photosFiles={photosFiles}
              studiesFiles={studiesFiles}
              planFiles={planFiles}
              state={state}
              setState={setState}
              stageTwoFinish={stageTwoFinish}
              stageThreeFinish={stageThreeFinish}
              stageFourFinish={stageFourFinish}
              checkFrotalSonriendo={checkFrotalSonriendo}
            />
          )}

          {state.step !== 6 && (
            <div
              className={
                pageWidth === 'desktop'
                  ? 'text-end pe-6 mt-4'
                  : 'text-center mt-4'
              }
            >
              {state.step > 1 && (
                <button
                  className="btn btn-light text-primary rounded-pill px-4 mx-2"
                  onClick={() => {
                    setState({
                      ...state,
                      step: state.step > 1 && state.step - 1,
                    })
                  }}
                >
                  <ChevronLeft size="16" /> Volver
                </button>
              )}
              <button
                disabled={state.online_plan && state.step === 4 ? true : false}
                className={`btn btn-primary rounded-pill px-4 ${
                  state.online_plan && state.step === 4
                    ? 'buttonFormOnline'
                    : ''
                }`}
                onClick={() => handleChangeStep()}
              >
                Continuar
              </button>
            </div>
          )}
          <div className="col-9 mx-auto mt-5">
            {stageFooterArray[state.step]}
          </div>
        </div>
      )}
    </>
  )
}
export default NewTreatmentSolicitud
