import React from 'react'
import '../style.css'

export const PrevDiagnosisStepBar = () => {
  return (
    <div className="d-flex flex-column w-100 align-items-end mt-3 me-1 position-relative">
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Movimientos</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Extracciones</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex mb-3">
        <span className={`text-stepper text-end pe-2`}>Dientes Virtuales</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="d-flex">
        <span className={`text-stepper text-end pe-2`}>Attachments</span>
        <span className="circle bg-primary"></span>
      </div>
      <div className="progressBarLine"></div>
    </div>
  )
}
