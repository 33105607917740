import React, {Fragment, useEffect, useState} from "react";
import apiClient from "../../../utils/backend";

function Categories(props) {

    const [categories, setCategories] = useState([])
    const [newCategorie, setNewCategorie] = useState(null)
    const [showNewCategorie, setShowNewCategorie] = useState(false)

    useEffect(() => {
        apiClient.get('/api/helpCenter').then(res => {
            setCategories(res.data)
        })
    }, [])

    const guardarCategoria = () => {
        apiClient.post('/api/helpCenter', {
            name: newCategorie,
            route: newCategorie,
            order: 1
        }).then(res => {
            setCategories(oldCategories => [...oldCategories, res.data]);
        })
    }

    return (
        <Fragment>
            <p className="mt-3" style={{fontSize: 15}}>Categoria</p>

            <div className="row">
                <Fragment>
                    {
                        categories.map(categorie => {
                            return (
                                <div className="my-3" onClick={() => props.setCategorie(categorie)} style={{width: 250, height: 220, borderRadius: 20, cursor: 'pointer'}}
                                     className="col-2 d-flex flex-column shadow mx-2 my-2 p-4 justify-content-center align-items-center">
                                    <img style={{width: 100, height: 100}} src={categorie.icon} alt=""/>
                                    <p className="mt-3" style={{fontSize: 22, fontWeight: 'bold'}}>{categorie.name}</p>
                                </div>
                            )
                        })
                    }

                    <div className="my-3" onClick={() => setShowNewCategorie(true)} style={{width: 250, height: 220, borderRadius: 20, cursor: 'pointer'}}
                         className="col-2 d-flex flex-column shadow mx-2 my-2 p-4 justify-content-center align-items-center">
                        <img style={{width: 100, height: 100}} src="https://api.keepsmiling.app/storage/templates/centroayuda/add-icon.png" alt=""/>
                        <p className="mt-3" style={{fontSize: 22, fontWeight: 'bold'}}>Nueva Categoria</p>
                    </div>
                </Fragment>
            </div>

            {
                showNewCategorie &&
                <div className="row mt-5">
                    <p style={{fontSize: 20, fontWeight: 'bold'}}>Nueva Categoría</p>

                    <div className="col-6 d-flex flex-row">
                        <input onChange={(e) => setNewCategorie(e.target.value)} className="form-control" type="text"/>
                        <button onClick={() => guardarCategoria()} className="btn btn-primary px-5 rounded-pill">Guardar</button>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Categories