import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { ChevronLeft } from 'react-feather';
import '../components/index.css'
import moment from 'moment';
import { Message } from '../../../components/Message';

export const Treatment = () => {

    const {treatment,user} = useSelector(state => state.patientReducer);
    const params = useParams();
    const stage = treatment.find( t => t.id == params.id)
    const history = useHistory();
    console.log(stage)
    return (
        <div className="container-fluid  px-5 position-relative">
                <div className="row pt-4 px-4">
                <div className="col-12"><h2  className="text-primary fw-bold">Historia clinica</h2></div>
                </div>
                <div className="row mt-4 px-4">
                    <div className="col-12 shadow p-3 d-flex flex-row  treatment-card overflow-hidden" style={{borderRadius: '20px'}}>
                        <div>
                            <img src="https://secure.gravatar.com/avatar/b7cbb245307bbb8b4c5499c99bdf42e6?s=250&d=mm&r=g" alt="" className="rounded-circle" style={{maxWidth: '100px', width: '100%', height: '100%', marginRight: '10px', marginLeft: '20px', border: '1px solid #00155c'}} />
                        </div>
                        <div className="d-flex flex-column py-3 px-3">
                            <h4 className="fw-bold text-white">{user?.full_name}</h4>
                            <div className="col-12 flex ">
                                <div className="row  align-content-end" style={{height: "50px"}}>
                                    <h6 className="fw-bold text-white">{`${stage?.order}° etapa`}</h6>
                                    <p className="text-white fw-light mb-0">{moment(stage.created_at).format('DD-MM-YYYY')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mt-2 d-flex flex-column ">
                            <p className="align-self-end" style={{color: '#002541', position: 'absolute', fontSize: '5em', fontWeight: 'bold'}}>{stage?.order}</p>
                        </div> 
                    </div>
                </div>
                <div className="col-12" onClick={() => (history.replace('/mi-tratamiento'))}>
                    <ChevronLeft className="m-3 button" size={30} color="#000" />
                </div>
                <div className="row m-3">
                    <div className="col-6 flex flex-column justify-content-center">
                        <p>Registro Original</p>
                    </div>
                    <div className="col-6 flex flex-column justify-content-center">
                        <p>Propuesta de tratamiento</p>
                    </div>
                </div>
                <hr className="m-3"/>
                <div className="row">
                    <div className="col-12">
                        {
                            (stage.viewers[0]?.url) ? ( <iframe src={stage.viewers[0]?.url}/> )
                            : (<div className="w-50 mx-auto"><Message message={"Visor no disponible por el momento, consulte con el administrador."} type="alert alert-danger text-center" /></div>)
                        }
                    </div>
                </div>
                <hr className="m-3"/>
            </div>
    )
}
