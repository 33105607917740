import React from 'react'
import Header from './components/StickyHeader'
import PacientData from './components/PacientData'

export default function FormOnline(props) {
  const get_colors = () => {
    switch (props.applicationState.treatment_type) {
      case 1: //'full'
        return { color: '#001b70', alternative_color: '#fff' }
      case 2: //'Fast'
        return { color: '#288b7e', alternative_color: '#000' }
      case 3: //Medium
        return { color: '#5a468e', alternative_color: '#fff' }
      case 4: //'Kids'
        return { color: '#e47662', alternative_color: '#000' }
      case 5: //'Kids Fast'
        return { color: '#e8793a', alternative_color: '#fff' }
      case 6: //'Teens'
        return { color: '#f7c05b', alternative_color: '#fff' }
      default:
        return { color: '#288B7E', alternative_color: '#fff' }
    }
  }


  return (
    <div className="mt-3 shadow w-100 p-3 bg-white rounded">
      <Header
        colors={get_colors()}
        applicationState={props.applicationState}
        checkFrotalSonriendo={props.checkFrotalSonriendo}
      />
      <div className="d-flex justify-content-center">
        <div className="col-12">
          <PacientData
            colors={get_colors()}
            type={props.type}
            nextParentStep={props.nextParentStep}
            applicationState={props.applicationState}
            setState={props.setState}
          />
        </div>
      </div>
    </div>
  )
}
