import React, { useState, useEffect } from 'react'
import frontalsonriendo from '../../assets/images/frontalsonriendo.png'
import StagesCard from './StagesCard'
import './styles.css'
import apiClient from '../../utils/backend'
import { useParams } from 'react-router-dom'
import Loader from '../../components/loader'
import { useSelector } from 'react-redux'

const MedicalRecordStages = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const [stagesData, setStagesData] = useState({})
  const [stages, setStages] = useState({})
  const [typeTreatments, setTypeTreatments] = useState([])

  useEffect(() => {
    const getStageData = async () => {
      try {
        const res = await apiClient(`/api/medicalRecordStages/${params.id}`)
        setLoading(false)
        setStagesData(res.data.table.body[0])
      } catch (err) {
        console.log(err)
      }
    }

    getStageData()
  }, [params.id])

  useEffect(() => {
    if (!stagesData.treatments) return
    let filterStages = stagesData.treatments
      // .filter((treatment) => treatment.type_id === 1 || treatment.type_id === 4)
      .sort((treatment) => treatment.order)
    setStages(filterStages)
  }, [stagesData])

  useEffect(() => {
    if (!stages.length > 0) return
    let newStages = stages.map((treatment) => treatment.type_id)
    let uniqueValues = [...new Set(newStages)]
    setTypeTreatments(uniqueValues)
  }, [stages])

  return (
    <>
      {!loading ? (
        <div className="container py-4 px-4">
          <h2 className="fw-bold">Historia Clínica</h2>
          {pageWidth === 'desktop' ? (
            <DeskCard stagesData={stagesData} typeTreatments={typeTreatments} />
          ) : (
            <MobCard stagesData={stagesData} typeTreatments={typeTreatments} />
          )}

          <div className="d-flex mt-4 flex-wrap justify-content-evenly">
            {stages.length > 0 && (
              <>
                {stages.map((data, i) => (
                  <StagesCard data={data} key={i} />
                ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default MedicalRecordStages

export function DeskCard({ stagesData, typeTreatments }) {
  return (
    <div className="patientDataCard p-3 mt-3">
      <img
        src={
          stagesData.patient?.avatar
            ? stagesData.patient.avatar
            : frontalsonriendo
        }
        alt="img"
        style={{ height: '140px', width: '140px' }}
      />
      <span className="fw-bold text-primary ps-3 fs-5 mt-3">
        {stagesData.patient?.full_name}
      </span>

      {typeTreatments.length > 0 && (
        <>
          {typeTreatments.map((type) => {
            if (type === 1) {
              return <div className="treatmentBTN1 py-2 px-3">Full </div>
            }
            if (type === 2) {
              return <div className="treatmentBTN2 py-2 px-3">Fast </div>
            }
            if (type === 3) {
              return <div className="treatmentBTN3 py-2 px-3">Medium </div>
            }
            if (type === 4) {
              return <div className="treatmentBTN4 py-2 px-3">Full kids </div>
            }
            if (type === 5) {
              return <div className="treatmentBTN5 py-2 px-3">Kids Fast </div>
            }
            if (type === 6) {
              return <div className="treatmentBTN6 py-2 px-3">Teens</div>
            }
          })}
        </>
      )}
    </div>
  )
}

export function MobCard({ stagesData, typeTreatments }) {
  return (
    <div
      className="patientDataCard p-3 mt-4"
      style={{ maxWidth: 350, margin: 'auto' }}
    >
      <img
        src={
          stagesData.patient?.avatar
            ? stagesData.patient.avatar
            : frontalsonriendo
        }
        alt="img"
        style={{ height: '80px', width: '80px', borderRadius: 80 }}
      />
      <span className="fw-bold text-primary text-truncate ps-3 fs-5 mt-3">
        {stagesData.patient?.full_name}
      </span>

      {typeTreatments.length > 0 && (
        <>
          {typeTreatments.map((type) => {
            if (type === 1) {
              return <div className="treatmentBTN1 py-2 px-3">Full </div>
            }
            if (type === 2) {
              return <div className="treatmentBTN2 py-2 px-3">Fast </div>
            }
            if (type === 3) {
              return <div className="treatmentBTN3 py-2 px-3">Medium </div>
            }
            if (type === 4) {
              return <div className="treatmentBTN4 py-2 px-3">Full kids </div>
            }
            if (type === 5) {
              return <div className="treatmentBTN5 py-2 px-3">Kids Fast </div>
            }
            if (type === 6) {
              return <div className="treatmentBTN6 py-2 px-3">Teens</div>
            }
          })}
        </>
      )}
    </div>
  )
}
