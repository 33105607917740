import React from 'react'
import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import './styles/index.css'
import ClinicHistoryDetail from './components/ClinicHistoryDetail'
import ClinicHistoryFlags from './components/ClinicHistoryFlags'
import ClinicHistoryDocumentation from './components/Documentation/ClinicHistoryDocumentation'
import ClinicHistoryPagination from './components/ClinicHistoryPagination'

import PropTypes from 'prop-types'
import DeleteTreatmentModal from './components/DeleteTreatmentModal'
import useMedicalRecordModal from './hooks/useMedicalRecordModal'
import Loader from '../loader'
import ClinicHistoryHeader from './components/ClinicHistoryHeader'

const ClinicHistoryModal = ({
  id,
  opened,
  handleModal,
  isApplication = false,
  isProposalControl = false,
  isTreatment = false,
}) => {
  const {
    loading,
    dataImage,
    data,
    externalValue,
    documentationData,
    currentModalId,
    originalRegister,
    editRegistrationDate,
    setCurrentModalId,
    setEditRegistrationDate,
    displayDoctorName,
    handleSetRegistrarionDate,
    handleChangeRegistro,
    handleChangeMaxilar,
    setExternalValue,
    handleApproveTreatment,
    setData,
    setDocumentationData,
    updateData,
    loadDataAdmin,
  } = useMedicalRecordModal(id, opened)

  const propsToClinicHistoryHeader = {
    data,
    dataImage,
    isTreatment,
    editRegistrationDate,
    isApplication,
    externalValue,
    displayDoctorName,
    handleSetRegistrarionDate,
    setEditRegistrationDate,
    handleChangeRegistro,
    handleChangeMaxilar,
    setExternalValue,
    handleApproveTreatment,
  }

  return (
    <Modal
      size="xl"
      isOpen={opened}
      className="modal-container"
      toggle={() => {
        handleModal()
        setCurrentModalId(null)
        setEditRegistrationDate(false)
      }}
    >
      <ModalBody className="px-0 modal-container">
        {loading ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              width: '100%',
              height: '400px',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className=" ps-4 pe-3">
            <div className="d-flex justify-content-end">
              <X
                onClick={() => {
                  handleModal()
                  setCurrentModalId(null)
                  setEditRegistrationDate(false)
                }}
                className="XBtn"
              />
            </div>
            <ClinicHistoryHeader
              propsToClinicHistoryHeader={propsToClinicHistoryHeader}
            />

            <hr className="mx-4" />
            <ClinicHistoryDetail
              data={data}
              treatmentStatus={data?.treatment_status}
              paymentStatus={data?.payment_status}
              paymentDate={data?.payment_date}
            />
            <div className="CH-space-maker shadow"></div>
            {documentationData && (
              <>
                <ClinicHistoryFlags
                  setData={setData}
                  currentModalId={currentModalId}
                  documentationData={documentationData}
                />
                <ClinicHistoryDocumentation
                  loadDataAdmin={loadDataAdmin}
                  treatmentId={data?.id}
                  updateData={updateData}
                  data={data}
                  documentationData={documentationData}
                  setDocumentationData={setDocumentationData}
                  originalRegister={originalRegister}
                  isApplication={isApplication}
                  isProposalControl={isProposalControl}
                />
                <ClinicHistoryPagination
                  data={data}
                  setCurrentModalId={setCurrentModalId}
                />
                <hr className="mx-4" />
                <DeleteTreatmentModal data={data} />
              </>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ClinicHistoryModal

ClinicHistoryDetail.propTypes = {
  id: PropTypes.number.isRequired,
  opened: PropTypes.bool.isRequired,
  handleModal: PropTypes.string.isRequired,
  isApplication: PropTypes.bool.isRequired,
  isProposalControl: PropTypes.bool.isRequired,
  isTreatment: PropTypes.bool.isRequired,
}
