import React, { useEffect } from 'react'
import { attachments_options } from '../Utilities/OptionsRadios'
import RadioList from '../Utilities/RadioList'
import ToothsSelect from '../Utilities/ToothsSelector'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from '../../propsStateConnect'
import { connect } from 'react-redux'
import TextAreaCounter from '../Utilities/TextAreaCounter'
function Attachments(props) {
  type_dispatch.step = 'PretratmentDiagnosisValues'
  type_dispatch.field = 'Attachments'
  const { colors, updateState, updateErrors } = props
  const attachments_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  useEffect(() => {
    if (attachments_state.attachment) {
      updateErrors([])
    }
  }, [attachments_state.attachment, updateErrors])
  return (
    <div>
      <div
        className="w-100 fw-bold fs-6 pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Attachments
      </div>
      {props.formOnlineReducer.Enabled && (
        <div
          className="rounded mb-3 mt-2 p-2 d-flex flex-column"
          style={{
            backgroundColor: '#00155c',
            color: colors.alternative_color,
          }}
        >
          <small className="fw-bold text-center force-inline-text text-white">
            RECUERDE QUE CIERTOS MOVIMIENTOS REQUIEREN DEL USO DE ATTACHMENTS
            PARA SER LOGRADOS CON ÉXITO
          </small>
          <small className="mt-4 text-center text-white">
            Recuerde que en Kids además de los attachments necesarios para los
            movimientos, es necesario colocar attachments en los primeros
            molares permanentes, si éstos no existieran se colocarán en los
            segundos molares temporarios para aumentar la correcta retención de
            alinear en boca.
          </small>
          <small className="mt-4 text-center text-white">
            Si tiene una duda respecto al uso de attachments puede comunicarse
            con nuestro equipo de ortodoncia antes de completar esta sección
          </small>
        </div>
      )}
      {error1 && (
        <div className="alert-danger alert-link p-2 mt-1">{error1.message}</div>
      )}
      <RadioList
        enabled={props.formOnlineReducer.Enabled}
        colors={colors}
        radios={attachments_options}
        setter={(value) => updateState({ key: 'attachment', value })}
        stateField={attachments_state.attachment}
        name="attachment"
      />
      {attachments_state.attachment === 3 && (
        <div>
          <div className="text-muted mt-3 small">
            (Indique las piezas en las que NO QUIERE que se aplique attachment)
          </div>
          <div className="mt-3">
            <ToothsSelect
              enabled={props.formOnlineReducer.Enabled}
              step={type_dispatch.step}
              field={type_dispatch.field}
              tooths_state={
                props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
              }
            />
          </div>
          <div className="fw-bolder mt-3 small">
            Puede detallar a continuación:
          </div>
          <div className="text-muted mt-2 small">
            (Recuerde indicar la pieza a la que hace referencia el detalle)
          </div>
          <div className="mt-2">
            <textarea
              disabled={!props.formOnlineReducer.Enabled}
              onChange={(e) => {
                updateState({ key: 'description', value: e.target.value })
              }}
              value={attachments_state.description}
              className="form-control"
              name="observacion"
              id="observacion"
              cols="30"
              rows="7"
            ></textarea>
            <TextAreaCounter
              value={attachments_state.description}
              maxValue={2500}
              error={error2}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachments)
