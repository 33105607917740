import React from 'react'

export default function card() {
    return (
        <div className="shadow container pt-5 pb-4" style={{borderRadius: '20px'}}>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8 d-flex flex-column">
                    <h4 className="fw-bold">Presenta tu caso</h4>
                    <p>Solicitud de tratamiento para un nuevo paciente que comienza un tratamiento KeepSmiling</p>
                </div>
            </div>
            <div className="row mt-4 px-4">
                <button className="btn text-start col-12 rounded-3 py-3 px-4 mt-2 mb-2" style={{backgroundColor: '#F8F9FA'}}>
                    <h3 className="fw-bold">¿Cómo funciona?</h3>
                </button>
                <button className="btn text-start col-12 rounded-3 py-3 px-4 mt-2 mb-2" style={{backgroundColor: '#F8F9FA'}}>
                    <h3 className="fw-bold">Enviar casos</h3>
                </button>
            </div>
        </div>
    )
}
