export const handleCanBeClaimed = (
  pendings,
  countPendings,
  flags,
  countFlags
) => {
  const { studies, photos, plan } = flags
  if (pendings.length > 0 && countPendings < 10) {
    return true
  }
  if ((!studies || !photos || !plan) && countFlags < 10) {
    return true
  }
  return false
}
