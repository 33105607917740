import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MoreVertical, Upload } from 'react-feather'
import '../../styles/index.css'

// IMAGES STEP 2
import trescuartos_perfil from '../../../../assets/images/34deperfilsonriendo.png'
import anterioroclusion from '../../../../assets/images/anteriorenoclusion.png'
import anteriorinoclusion from '../../../../assets/images/anterioreninoclusion.png'
import bucalderecho from '../../../../assets/images/bucalderecho.png'
import bucalizquierdo from '../../../../assets/images/bucalizquierdo.png'
import frontalreposo from '../../../../assets/images/frontalenreposo.png'
import frontalsonriendo from '../../../../assets/images/frontalsonriendo.png'
import oclusalmaxilarinf from '../../../../assets/images/oclusaldelmaxilarinferior.png'
import oclusalmaxilarsup from '../../../../assets/images/oclusaldelmaxilarsuperior.png'
import perfilizqreposo from '../../../../assets/images/perfilizquierdoenreposo.png'

// IMAGES STEP 3
import radioperfil from '../../../../assets/images/telerradiografiadeperfil.png'
import ricketts from '../../../../assets/images/cefalogramadericketts.png'
import rxpanoramica from '../../../../assets/images/rxpanoramica.png'
import plan from '../../../../assets/images/plan.png'
import { ImageUploadContainer, ImageUploadReference } from './styled'
import Compressor from 'compressorjs'

// Import the editor styles
import 'pintura/pintura.css'

// Import the editor component from `react-pintura`
import {
  // Import plugins
  plugin_crop,
  plugin_finetune,
  plugin_annotate,
  plugin_resize,
  // Import languajes
  openDefaultEditor,
  setPlugins,
} from 'pintura'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { getLicense } from '../../../../utils/licenseKeyLightGallery'
import { getQuality } from '../../../../utils/quality'
import handleToast from '../../../toaster'
import { imageUrlToFile } from './imageUrlToFile'
import PDFViewer from '../../../Modals/PDFViewer'
import { Spinner } from 'reactstrap'

const ImageInput = React.forwardRef(
  (
    {
      section,
      src,
      previewImage,
      fullImage,
      imageId,
      handleUpload,
      handleEditPhotos,
      onDeleteImage,
      reCategorize,
      categories,
      name,
    },
    ref
  ) => {
    const [image, setImage] = useState(null)
    const [loader, setLoader] = useState(false)
    const [dropDown, setDropDown] = useState(false)
    const lightGallery = useRef(null)
    let isPdf = fullImage?.includes('.pdf')

    const Components = {
      trescuartos_perfil: trescuartos_perfil,
      frontal_sonriendo: frontalsonriendo,
      frontal_reposo: frontalreposo,
      anterior_oclusion: anterioroclusion,
      anterior_inoclusion: anteriorinoclusion,
      bucal_izquierdo: bucalizquierdo,
      bucal_derecho: bucalderecho,
      oclusal_maxilar_sup: oclusalmaxilarsup,
      oclusal_maxilar_inf: oclusalmaxilarinf,
      perfil_izq_reposo: perfilizqreposo,
      telerradiografia: radioperfil,
      ricketts: ricketts,
      rxpanoramica: rxpanoramica,
      plan: plan,
      otros: plan,
    }

    const onInit = useCallback((detail) => {
      if (detail) {
        lightGallery.current = detail.instance
      }
    }, [])

    async function handleImage(e) {
      e.preventDefault()

      if (e.target.files[0]?.type === 'application/pdf') {
        if (section !== 'plan')
          return handleToast('error', 'No se aceptan PDF en esta sección')
        handleUpload({ file: e.target.files[0], isPDF: true })
      } else {
        let imageFile = e.target.files[0]
        new Compressor(imageFile, {
          quality: getQuality(),
          success(result) {
            let reader = new FileReader()
            let file_compressed = result
            reader.onloadend = () => {
              if (section === 'plan')
                handleUpload({
                  file: reader.result,
                  src,
                })
              else
                handleUpload({
                  imageBase64: reader.result,
                  src,
                  others: section === 'others' ? true : false,
                })
            }
            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      }
    }

    async function handleImageEditor() {
      setPlugins(plugin_crop, plugin_finetune, plugin_annotate, plugin_resize)

      const file = await imageUrlToFile({
        imageUrl: fullImage,
        imageName: name,
      })

      const editor = openDefaultEditor({
        src: file,
      })

      editor.on('process', ({ dest }) => {
        // Here we use URL.createObjectURL to create a URL to the output image
        new Compressor(dest, {
          quality: getQuality(),
          success(result) {
            let reader = new FileReader()
            let file_compressed = result
            reader.onloadend = () => {
              setImage(null)
              if (section === 'plan')
                handleUpload({
                  file: reader.result,
                  src,
                  imageId,
                })
              else
                handleEditPhotos({ imageBase64: reader.result, src, imageId })
            }
            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      })
    }

    useEffect(() => {
      if (!lightGallery.current) return
      lightGallery.current.refresh()
    }, [fullImage])

    useEffect(() => {
      if (!previewImage) return
      setLoader(true)
      setTimeout(() => {
        setImage(previewImage)
        setLoader(false)
      }, 2800)
    }, [previewImage])

    return (
      <div
        key={src}
        className="col-3 image-upload-container d-flex flex-column my-4 mx-auto"
        style={{
          maxHeight: '200px',
          width: '200px',
          position: 'relative',
        }}
      >
        <div className="d-flex flex-row justify-content-center">
          <ImageUploadContainer>
            {!image && (
              <>
                {loader ? (
                  <Spinner />
                ) : (
                  <>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .pdf"
                      onChange={handleImage}
                    />
                    <ImageUploadReference
                      src={Components[src]}
                      className={`${!image ? 'opacityPlaceholder' : ''}`}
                      alt="Imagen"
                      ref={ref}
                    />
                  </>
                )}
              </>
            )}

            {isPdf && <PDFViewer imageSource={fullImage} isOd={true} />}

            {image && !isPdf && (
              <>
                <LightGallery
                  speed={500}
                  plugins={[lgThumbnail, lgZoom]}
                  className="gallery"
                  onInit={onInit}
                  licenseKey={getLicense()}
                >
                  <div data-src={fullImage}>
                    <ImageUploadReference
                      src={image}
                      className={`${!image ? 'opacityPlaceholder' : ''}`}
                      alt="Imagen"
                    />
                  </div>
                </LightGallery>
              </>
            )}
          </ImageUploadContainer>

          {/* Top Right BTN */}
          {!image ? (
            <Upload color="#10456d" size={24} className="uploadBTN" />
          ) : (
            <MoreVertical
              color="#10456d"
              size={24}
              className="moreVerticalBTN"
              onClick={() => {
                setDropDown(!dropDown)
              }}
            />
          )}

          {dropDown && (
            <div className="inputDropDown">
              {!isPdf && (
                <span
                  onClick={() => {
                    handleImageEditor()
                    setDropDown(!dropDown)
                  }}
                >
                  Editar
                </span>
              )}
              <hr className="m-1" />
              <span
                onClick={() => {
                  onDeleteImage(imageId)
                  setTimeout(() => {
                    setImage(null)
                  }, 500)
                  setDropDown(!dropDown)
                }}
              >
                Eliminar
              </span>
            </div>
          )}
        </div>

        {/* ReCategorize Select */}
        {categories ? (
          <select
            disabled={image ? false : true}
            className="imageInputSelect text-center mt-1"
            onChange={(e) => {
              reCategorize({ imageId, src: e.target.value })
              setImage(null)
            }}
            value={{ label: null, id: null }}
          >
            <option value={src} hidden>
              {name}
            </option>
            {categories &&
              categories?.map(
                ({ slug, label }, i) =>
                  slug !== src && (
                    <option key={i} value={slug}>
                      {label}
                    </option>
                  )
              )}
          </select>
        ) : (
          <div className="imageInputText mt-1">{name}</div>
        )}
      </div>
    )
  }
)

export default ImageInput
