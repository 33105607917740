import { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'
import throttle from '../../../utils/throttle'
import handleToast from '../../../components/toaster'
import PropTypes from 'prop-types'

export const useReferralModal = (referralsState, getInitialReferrals) => {
  const [opened, setOpened] = useState(false)
  const [showList, setShowList] = useState(false)
  const [query, setQuery] = useState('')
  const [amountSelectedReferrals, setAmountSelectedReferrals] = useState(0)
  const [showOfficesError, setShowOfficesError] = useState(false)
  const [showSearchError, setShowSearchError] = useState(false)
  const [referralModalState, setReferralModalState] = useState({
    dentists: [],
    selectedDentist: null,
    offices: null,
    selectedOffice: null,
  })

  const handleModalOpen = () => {
    setOpened(!opened)
    setReferralModalState((prevState) => ({
      ...prevState,
      selectedDentist: null,
      selectedOffice: null,
      offices: null,
      dentists: [],
    }))
    setShowList(false)
    setQuery('')
  }

  const handleSelectedDentist = (dentist) => {
    setShowList(false)
    setReferralModalState((prevState) => ({
      ...prevState,
      selectedOffice: null,
      selectedDentist: {
        name: dentist.user.full_name,
        id: dentist.id,
      },
    }))
    setQuery(dentist.user.full_name)
  }

  const handleSendReferrals = async () => {
    if (
      !referralModalState.selectedDentist ||
      !referralModalState.selectedOffice
    ) {
      return handleToast(
        'error',
        'Debe seleccionar un dentista y un consultorio'
      )
    }
    const filteredReferrals = referralsState.referralsList.filter(
      (referral) => referral.selected
    )
    if (!filteredReferrals.length)
      return handleToast('error', 'Seleccione al menos un paciente')

    handleToast('updating', 'Generando derivacion')
    const referrals = filteredReferrals.map((referral) => ({
      dentist_id: referralModalState?.selectedDentist?.id,
      referral_id: referral.id,
      office: referralModalState?.selectedOffice,
    }))

    try {
      await apiClient.post('/api/referrals', { referrals })
      handleToast('success', 'Derivacion exitosa')
      getInitialReferrals()
    } catch (error) {
      console.log(error)
      handleToast('error', 'Hubo un error al generar la derivacion')
    }
    setReferralModalState((prevState) => ({
      ...prevState,
      selectedDentist: null,
      selectedOffice: null,
      offices: null,
      dentists: [],
    }))
    setShowList(false)
    setQuery('')
    setOpened(!opened)
  }

  const handleShowList = () => {
    if (referralModalState.dentists.length) setShowList(false)
  }

  const getOffices = async (dentistID) => {
    setShowOfficesError(false)
    if (!dentistID) return
    try {
      const res = await apiClient.get(`/api/dentists/${dentistID}/offices`)
      setReferralModalState((prevState) => ({
        ...prevState,
        offices: res.data.table,
      }))
      if (!res.data.table.length) setShowOfficesError(!showOfficesError)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const handleRequest = async (queryValue) => {
      if (
        queryValue === referralModalState.selectedDentist?.name ||
        !queryValue ||
        queryValue.length < 3
      )
        return
      setShowSearchError(false)
      try {
        const res = await apiClient.get(
          `/api/dentists?page=1&include=treatmentsCount,patientsCount&filter[country_id]=1&filter[fullname]=${queryValue}`
        )
        setReferralModalState((prevState) => ({
          ...prevState,
          dentists: res.data.table.body.slice(0, 11),
        }))
        if (!res.data.table.body.length) setShowSearchError(true)
        if (referralModalState.dentists.length > 0) setShowList(true)
      } catch (err) {
        console.log(err)
      }
    }
    throttle(handleRequest, query, 1000)
  }, [
    query,
    referralModalState.dentists.length,
    referralModalState.selectedDentist,
  ])

  useEffect(() => {
    setReferralModalState((prevState) => ({
      ...prevState,
      selectedOffice: null,
    }))
    getOffices(referralModalState.selectedDentist?.id)
  }, [referralModalState.selectedDentist])

  useEffect(() => {
    if (!referralsState.referralsList) return
    const amountOfReferrals = referralsState.referralsList.filter(
      (referral) => referral.selected
    )
    setAmountSelectedReferrals(amountOfReferrals.length)
  }, [referralsState.referralsList])

  useEffect(() => {
    setReferralModalState((prevState) => ({
      ...prevState,
      selectedOffice: null,
    }))
  }, [referralModalState.selectedDentist, query])

  useEffect(() => {
    if (referralModalState.dentists.length === 0) setShowList(false)
    if (query === referralModalState.selectedDentist?.name) return

    setReferralModalState((prevState) => ({
      ...prevState,
      selectedDentist: null,
      selectedOffice: null,
      offices: null,
    }))
  }, [query])

  return {
    handleModalOpen,
    handleSelectedDentist,
    handleSendReferrals,
    handleShowList,
    setReferralModalState,
    setQuery,
    setOpened,
    amountSelectedReferrals,
    referralModalState,
    query,
    showList,
    opened,
    showOfficesError,
    showSearchError,
  }
}
useReferralModal.PropTypes = {
  referralsState: PropTypes.object.isRequired,
  getInitialReferrals: PropTypes.func.isRequired,
}
