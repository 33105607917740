import React, { useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import { mantain_keep_options } from '../Utilities/OptionsRadios'
import TextAreaCounter from '../Utilities/TextAreaCounter'

function CanineRelationship(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'CanineRelationship'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]

  const canRelationshipRight = middle_state.right
  const canRelationshipLeft = middle_state.left

  const refObsRight = useRef(null)
  const refObsLeft = useRef(null)
  useEffect(() => {
    if (canRelationshipRight === 2) {
      refObsRight.current.focus()
    }
  }, [canRelationshipRight])

  useEffect(() => {
    if (canRelationshipLeft === 2) {
      refObsLeft.current.focus()
    }
  }, [canRelationshipLeft])

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  const error3 = errors_list.find((err) => err.order === 3)
  const error4 = errors_list.find((err) => err.order === 4)
  /*   useErrorList(errors_list, updateErrors, [middle_state.right, middle_state.left]) */

  useEffect(() => {
    if (errors_list.find((err) => err.order === 1)) {
      updateErrors(errors_list.filter((err) => err.order === 2))
    }
    if (errors_list.find((err) => err.order === 2)) {
      updateErrors(errors_list.filter((err) => err.order === 1))
    }
  }, [middle_state])

  return (
    <div>
      <div
        className="w-100 fw-bold pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Relación Canina
      </div>
      <div className="d-flex mt-3">
        <div className="col-6 ms-3">
          <div
            className="w-90 fw-bolder small pb-1"
            style={{ borderBottom: `1px solid ${colors.color}` }}
          >
            Relación canina derecha
          </div>
          {error1 && (
            <div className="alert-danger alert-link p-2 mt-1 me-2">
              {error1.message}
            </div>
          )}
          <RadioList
            enabled={props.formOnlineReducer.Enabled}
            colors={colors}
            radios={mantain_keep_options}
            setter={(value) => updateState({ key: 'right', value })}
            stateField={canRelationshipRight}
            name="canRelationshipRight"
          />
          {canRelationshipRight === 2 && (
            <div>
              <div className="fw-bolder mt-3 small">
                Puede detallar a continuación:
              </div>
              <div className="text-muted mt-2 small">
                (Recuerde indicar la pieza a la que hace referencia el detalle)
              </div>
              <div className="mt-2 col-11">
                <textarea
                  disabled={!props.formOnlineReducer.Enabled}
                  onChange={(e) => {
                    updateState({
                      key: 'rightDescription',
                      value: e.target.value,
                    })
                  }}
                  value={middle_state.rightDescription}
                  ref={refObsRight}
                  className="form-control"
                  name="observacion"
                  id="observacion"
                  cols="30"
                  rows="3"
                ></textarea>
                <TextAreaCounter
                  value={middle_state.rightDescription}
                  maxValue={2500}
                />
                {error3 && (
                  <div className="alert-danger alert-link p-2 mt-1 me-2">
                    {error3.message}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="col-6">
          <div
            className="w-100 fw-bolder small pb-1"
            style={{ borderBottom: `1px solid ${colors.color}` }}
          >
            Relación canina izquierda
          </div>
          {error2 && (
            <div className="alert-danger alert-link p-2 mt-1">
              {error2.message}
            </div>
          )}
          <RadioList
            enabled={props.formOnlineReducer.Enabled}
            colors={colors}
            radios={mantain_keep_options}
            setter={(value) => updateState({ key: 'left', value })}
            stateField={canRelationshipLeft}
            name="canRelationshipLeft"
          />
          {canRelationshipLeft === 2 && (
            <div>
              <div className="fw-bolder mt-3 small">
                Puede detallar a continuación:
              </div>
              <div className="text-muted mt-2 small">
                (Recuerde indicar la pieza a la que hace referencia el detalle)
              </div>
              <div className="mt-2 col-11">
                <textarea
                  disabled={!props.formOnlineReducer.Enabled}
                  onChange={(e) => {
                    updateState({
                      key: 'leftDescription',
                      value: e.target.value,
                    })
                  }}
                  value={middle_state.leftDescription}
                  ref={refObsLeft}
                  className="form-control"
                  name="observacion"
                  id="observacion"
                  cols="30"
                  rows="3"
                ></textarea>
                <TextAreaCounter
                  value={middle_state.leftDescription}
                  maxValue={2500}
                />
                {error4 && (
                  <div className="alert-danger alert-link p-2 mt-1 me-2">
                    {error4.message}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(CanineRelationship)
