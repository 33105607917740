import React, { useEffect, useState } from 'react'
import apiClient from '../../utils/backend'
import moment from 'moment'
import { Edit2, Calendar } from 'react-feather'
import './components/index.css'
import PriceListGeneralCard from './components/PriceListGeneralCard'
import CalendarModal from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Loader from '../../components/loader'
import { useSelector } from 'react-redux'
import usePriceList from './hooks/usePriceList'

const PriceList = () => {
  const [priceListFull, setPriceListFull] = useState([])
  const [priceListFast, setPriceListFast] = useState([])
  const [priceListMedium, setPriceListMedium] = useState([])
  const [priceListKidsFull, setPriceListKidsFull] = useState([])
  const [priceListKidsFast, setPriceListKidsFast] = useState([])
  const [priceListTeens, setPriceListTeens] = useState([])
  const [priceListInfo, setPriceListInfo] = useState()
  const [editMode, setEditMode] = useState(false)
  const [isAdmin, setIsAdmin] = useState(true)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [dateToSend, setDateToSend] = useState()
  const [loading, setLoading] = useState(true)

  const { countryId } = useSelector((state) => state.userReducer)
  const treatmentsList = [1, 2, 3, 4, 5, 6]
  const treatmentsListCol = [1, 2, 3, 4]

  const { getCountryPriceList } = usePriceList()

  const handleSendData = async (date) => {
    let data
    data = {
      prices_valid_until: moment(date).format('YYYY-MM-DD'),
      dollar_reference: priceListInfo.dollar_reference,
    }
    if (date === undefined) {
      data = {
        prices_valid_until: dateToSend,
        dollar_reference: priceListInfo.dollar_reference,
      }
    }
    try {
      const res = await apiClient.put(`api/priceListInformation/1`, data)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  moment.locale('es')
  useEffect(() => {
    const apiCall = async () => {
      try {
        const res = await apiClient.get(
          `api/priceList?filter[country_id]=${countryId}`
        )
        const res2 = await apiClient.get(
          `api/priceListInformation?filter[country_id]=${countryId}`
        )
        const data = {
          prices_valid_until: moment(
            res2.data.table.body[0].prices_valid_until
          ).format('DD [de] MMMM [de] YYYY'),
          dollar_reference: res2.data.table.body[0].dollar_reference,
        }
        setDateToSend(
          moment(res2.data.table.body[0].prices_valid_until).format(
            'YYYY-MM-DD'
          )
        )
        setPriceListInfo(data)
        setPriceListFull(
          res.data.table.body.filter((full) => full.type_id === 1)
        )
        setPriceListFast(
          res.data.table.body.filter((full) => full.type_id === 2)
        )
        setPriceListMedium(
          res.data.table.body.filter((full) => full.type_id === 3)
        )
        setPriceListKidsFull(
          res.data.table.body.filter((full) => full.type_id === 4)
        )
        setPriceListKidsFast(
          res.data.table.body.filter((full) => full.type_id === 5)
        )
        setPriceListTeens(
          res.data.table.body.filter((full) => full.type_id === 6)
        )
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    apiCall()
  }, [])

  useEffect(() => {
    const userType = localStorage.getItem('type_user')
    if (userType == 1) {
      return setIsAdmin(true)
    }
    setIsAdmin(false)
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container m-auto mt-4 ">
          {countryId !== 1 && countryId !== 4 ? (
            getCountryPriceList(countryId)
          ) : (
            <div className="ms-4">
              <div className="row">
                <div className="col-10">
                  <p className="fs-2 fw-bold text-primary">Lista de Precios</p>
                </div>
                {isAdmin && (
                  <div className="col-2">
                    <button
                      className={`btn ${
                        editMode
                          ? 'btn-primary saveButton'
                          : 'btn-outline-primary '
                      } d-flex flex-row align-items-center justify-content-center`}
                      onClick={() => setEditMode(!editMode)}
                    >
                      {!editMode && <Edit2 size={20} className={`me-2`} />}

                      {editMode ? 'Guardar' : 'Editar'}
                    </button>
                  </div>
                )}
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  {countryId !== 4 && (
                    <div className="d-flex flex-row">
                      <p className="fw-bold">Dólar de referencia:</p>
                      {editMode ? (
                        <input
                          type="number"
                          className="inputPriceList ms-1"
                          placeholder={`${priceListInfo?.dollar_reference} USD`}
                          onChange={(e) => {
                            if (e.target.value.length > 8) {
                              return alert(
                                `${e.target.value} supera el máximo de caracteres.`
                              )
                            }
                            if (
                              e.target.value.length <= 8 &&
                              e.target.value >= 0
                            ) {
                              setPriceListInfo({
                                ...priceListInfo,
                                dollar_reference: e.target.value,
                              })
                            }
                          }}
                          onBlur={() => handleSendData()}
                          min={0}
                        />
                      ) : (
                        <p className="fw-bold ms-2">
                          {priceListInfo?.dollar_reference} USD
                        </p>
                      )}
                    </div>
                  )}
                  <div className="d-flex flex-row align-content-center">
                    <p className="text-muted fs-7 m-0 d-flex align-self-center">
                      Precios válidos hasta el{' '}
                      {priceListInfo?.prices_valid_until}
                    </p>
                    {editMode && (
                      <div>
                        <Calendar
                          className="calendarIcon"
                          onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                        />
                        {isCalendarOpen && (
                          <div className="calendarFather">
                            <CalendarModal
                              className="calendar"
                              locale="es"
                              onChange={(e) => {
                                setDateToSend(moment(e).format('YYYY-MM-DD'))
                                setPriceListInfo({
                                  ...priceListInfo,
                                  prices_valid_until: moment(e).format(
                                    'DD [de] MMMM [de] YYYY'
                                  ),
                                })
                                handleSendData(e)
                                setIsCalendarOpen(!isCalendarOpen)
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-2 me-4">
                <div className="d-flex flex-row">
                  <div className="col-2"></div>
                  <div className="col-2 text-muted text-center px-0">
                    <p className="m-0">Maxilar</p>
                  </div>
                  <div className="col-2 text-muted text-center px-0">
                    <p className="m-0">Precios</p>
                  </div>
                  <div className="col-2 text-muted text-center px-0 me-2">
                    <p className="m-0">Cantidad de Alineadores</p>
                  </div>
                  <div className="col-2 text-muted text-center px-0">
                    <p className="m-0">Reposición Alineador</p>
                  </div>
                  <div className="col-2 text-muted text-center px-0">
                    <p className="m-0">Etapa Adicional</p>
                  </div>
                </div>
                {countryId === 4
                  ? treatmentsListCol.map((treatment, i) => (
                      <PriceListGeneralCard
                        treatmentId={treatment}
                        priceListFull={priceListFull}
                        priceListFast={priceListFast}
                        priceListMedium={priceListMedium}
                        priceListKidsFull={priceListKidsFull}
                        priceListKidsFast={priceListKidsFast}
                        priceListTeens={priceListTeens}
                        editMode={editMode}
                        key={treatment.id}
                        countryId={countryId}
                      />
                    ))
                  : treatmentsList.map((treatment, i) => (
                      <PriceListGeneralCard
                        treatmentId={treatment}
                        priceListFull={priceListFull}
                        priceListFast={priceListFast}
                        priceListMedium={priceListMedium}
                        priceListKidsFull={priceListKidsFull}
                        priceListKidsFast={priceListKidsFast}
                        priceListTeens={priceListTeens}
                        editMode={editMode}
                        key={treatment.id}
                        countryId={countryId}
                      />
                    ))}
                <div className="row mt-2">
                  <div className="col-12 mt-2 d-flex justify-content-end">
                    <div
                      className={`alert alert-info col-6 ${
                        countryId === 4 ? 'infoCardCol' : 'infoCard'
                      }`}
                    >
                      <p className="fw-bold mb-3" style={{ fontSize: '18px' }}>
                        Todos los precios incluyen IVA.
                      </p>
                      {countryId !== 4 && (
                        <>
                          <p>
                            50% del pago se realiza al iniciar el tratamiento.
                          </p>
                          <p>
                            25% del pago después de recibir los alineadores.
                          </p>
                          <p>
                            25% del pago después de los 30 días del segundo
                            pago.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default PriceList
