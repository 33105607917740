import React from 'react'
import '../../../style.css'

const CrossBite = ({ formData }) => {

  const searchCrossbiteResource = (number) => {
    return formData?.improve_crossbite.includes(number)
  }

  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Mordida cruzada posterior</p>
      <hr className="mt-2" />
      <div className="improve-overjet-container">
        <div className="d-flex flex-column">
          <div className="w-100">
            <p className="mb-0">Mordida cruzada</p>
            <hr className="mt-1 me-3" />
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="crossBite"
                disabled
                checked={formData?.crossbite == 1 && true}
              />
              <label className="ms-2">Mantener</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="crossBite"
                disabled
                checked={formData?.crossbite == 2 && true}
              />
              <label className="ms-2">Mejorar</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="crossBite"
                disabled
                checked={formData?.crossbite == 3 && true}
              />
              <label className="ms-2">No aplica</label>
            </div>
          </div>
          {formData?.crossbite == 2 && (
            <div className="w-100 mt-3">
              <p className="improve-overjet-title">
                ¿Cóomo desea mejorar la mordida cruzada?
              </p>
              <hr className="mt-1" />
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="howToImproveCrossBite"
                  disabled
                  checked={searchCrossbiteResource(1)}
                />
                <label className="ms-2">Por expansión superior</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="howToImproveCrossBite"
                  disabled
                  checked={searchCrossbiteResource(2)}
                />
                <label className="ms-2">
                  Por torque positivo posterior superior
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="howToImproveCrossBite"
                  disabled
                  checked={searchCrossbiteResource(3)}
                />
                <label className="ms-2">
                  Por compresión posterior inferior
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="howToImproveCrossBite"
                  disabled
                  checked={searchCrossbiteResource(4)}
                />
                <label className="ms-2">
                  Por torque negativo posterior inferior
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="howToImproveCrossBite"
                  disabled
                  checked={searchCrossbiteResource(5)}
                />
                <label className="ms-2">Otras</label>
              </div>
            </div>
          )}
        </div>
      </div>
      {searchCrossbiteResource(5) && (
        <>
          <p className="mt-3">Otras:</p>
          <div className="step-text-area w-100 overflow-scroll">
            {formData?.improve_crossbite_other}
          </div>
        </>
      )}
    </div>
  )
}

export default CrossBite
