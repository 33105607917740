import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSidebar, dentistSidebar } from './data/data'
import './sidebar.css'

import CloseSidebarItem from './components/CloseSidebarItems'
import OpenSidebarItem from './components/OpenSidebarItems'
import SmileIcon from './Icons/SmileIcon'
import KeepSmilingLogo from './Icons/KeepSmilingLogo'
import { useLocation } from 'react-router-dom'
import { Heart } from 'react-feather'

const Sidebar = () => {
  const [sidebarState, setSidebarState] = useState(null)
  const location = useLocation()
  const dispatch = useDispatch()
  const { collapsed, userType } = useSelector((state) => ({
    collapsed: state.navbarReducer.collapsed,
    userType: state.userReducer.typeId,
  }))

  useEffect(() => {
    if (!userType) return
    if (userType === 1) return setSidebarState([...adminSidebar])
    if (userType === 2) return setSidebarState([...dentistSidebar])
    throw new Error('User type is not identified')
  }, [userType])

  // Closes Sidebar on Route change
  useEffect(() => {
    if (collapsed) return
    dispatch({ type: 'COLLAPSE' })
  }, [location])

  if (!sidebarState) return <div id="sidebar" className="collapsed" />
  return (
    <div id="sidebar" className={`side-container ${collapsed && 'collapsed'}`}>
      <div className="d-flex flex-column ">
        {collapsed ? (
          <>
            <div className="d-flex flex-column" style={{ marginTop: '120px' }}>
              {sidebarState.map((item) => (
                <CloseSidebarItem item={item} />
              ))}
            </div>
          </>
        ) : (
          <>
            <KeepSmilingLogo />
            {sidebarState.map((item) => (
              <OpenSidebarItem item={item} />
            ))}
          </>
        )}
      </div>

      {collapsed && <SmileIcon />}
      {!collapsed && userType === 1 && (
        <div
          className=" text-center mb-2 text-white d-flex align-items-center justify-content-center gap-1 flex-wrap"
          style={{ opacity: 0.7, fontSize: '13px' }}
        >
          <p className="m-0">Hecho con </p>
          <span className="fs-4"> ♥ </span>
          <p className="m-0">por un gran equipo</p>
        </div>
      )}
    </div>
  )
}

export default Sidebar
