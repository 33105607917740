export const movements_options = [
  { label: 'Se pueden mover todos los dientes', value: 1 },
  { label: 'NO mover los siguientes dientes', value: 2 },
]
export const virtual_tooths_options = [
  {
    label: 'Aplicar dientes virtuales para cerrar espacios de extracciones',
    value: 1,
  },
  {
    label: 'NO aplicar dientes virtuales para cerrar espacios de extracciones',
    value: 2,
  },
]
export const extractions_options = [
  { label: 'Se pueden extraer dientes', value: 1 },
  { label: 'NO aplica', value: 2 },
  { label: 'NO extraer los siguientes dientes', value: 3 },
]
export const attachments_options = [
  {
    label: 'Estoy de acuerdo con la colocación de attachment de ser necesario',
    value: 1,
  },
  {
    label:
      'NO estoy de acuerdo con la colocación de attachments en NINGUNA de las piezas',
    value: 2,
  },
  {
    label:
      'Estoy de acuerdo con la colocación de attachments, pero NO en las siguentes piezas',
    value: 3,
  },
]
export const middleLine_up_options = [
  { label: 'No Mover', value: 1 },
  { label: 'Mover a la izquierda', value: 2 },
  { label: 'Mover a la derecha', value: 3 },
]
export const middleLine_down_options = [
  { label: 'No Mover', value: 1 },
  { label: 'Mover a la izquierda', value: 2 },
  { label: 'Mover a la derecha', value: 3 },
]
export const mantain_keep_options = [
  { label: 'Mantener', value: 1 },
  { label: 'Mejorar', value: 2 },
]
export const upper_overjet_options = [
  { label: 'Por retrusión superior', value: 1 },
  { label: 'Por retroinclinación superior(torque negativo)', value: 2 },
  { label: 'Por protusión inferior', value: 3 },
  { label: 'Por proinclinación inferior(torque positivo)', value: 4 },
  { label: 'Otras', value: 5 },
]
export const lower_overjet_options = [
  { label: 'Por protrusión superior', value: 1 },
  { label: 'Por proinclinación superior(torque positivo)', value: 2 },
  { label: 'Por retrusión inferior', value: 3 },
  { label: 'Por retroinclinación inferior(torque negativo)', value: 4 },
  { label: 'Otras', value: 5 },
]
export const upper_overbite_options = [
  { label: 'Por intrusión anterosuperior', value: 1 },
  { label: 'Por intrusión anteroinferior', value: 2 },
  { label: 'Por extrusión posterosuperior', value: 3 },
  { label: 'Por extrusión posteroinferior', value: 4 },
  { label: 'Otras', value: 5 },
]
export const lower_overbite_options = [
  { label: 'Por intrusión posterosuperior', value: 1 },
  { label: 'Por intrusión posteroinferior', value: 2 },
  { label: 'Por extrusión anterosuperior', value: 3 },
  { label: 'Por extrusión anteroinferior', value: 4 },
  { label: 'Otras', value: 5 },
]
export const yes_nothing_options = [
  { label: 'Sí', value: 1 },
  { label: 'Solo si es necesario', value: 2 },
  { label: 'Nada', value: 3 },
]
export const yes_no_options = [
  { label: 'Sí', value: 1 },
  { label: 'No', value: 2 },
]
export const mantain_keep_not_apply_options = [
  { label: 'Mantener', value: 1 },
  { label: 'Mejorar', value: 2 },
  { label: 'NO Aplica', value: 3 },
]
export const improve_cross_bite_options = [
  { label: 'Por expansión superior', value: 1 },
  { label: 'Por torque positivo posterior superior', value: 2 },
  { label: 'Por compresión posterior inferior', value: 3 },
  { label: 'Por torque negativo posterior inferior', value: 4 },
  { label: 'Otras', value: 5 },
]
export const diastemas_options = [
  { label: 'No aplica', value: 1 },
  { label: 'Cerrar espacios', value: 2 },
]
export const spaces_options = [
  { label: 'Mantener espacios', value: 1 },
  { label: 'Abrir', value: 2 },
  { label: 'No aplica', value: 3 },
  { label: 'Cerrar espacios', value: 4 },
]
export const close_spaces_options = [
  {
    label:
      'Por mesialización de sectores posteriores (pérdida de anclaje posterior)',
    value: 1,
  },
  {
    label:
      'Por distalización del sector anterior (pérdida de anclaje anterior)',
    value: 2,
  },
  { label: 'Cierre recíproco', value: 3 },
  { label: 'Otras', value: 4 },
]
export const apply_noapply_options = [
  { label: 'Aplica', value: 1 },
  { label: 'NO Aplica', value: 2 },
]
export const auxiliary_resources_options = [
  { label: 'Gomas de clase 2', value: 1 },
  { label: 'Gomas de clase 3', value: 2 },
  { label: 'Goma crisscross', value: 3 },
  { label: 'Microimplantes', value: 4 },
  { label: 'Topes de mordida', value: 5 },
]
export const auxiliary_resources_options_2 = [
  { label: 'Levante de mordida', value: 6 },
  { label: 'Barras de presión', value: 7 },
  { label: 'Aceleradores', value: 8 },
  { label: 'Microperforadores', value: 9 },
]

export const upperOverjetList = [
  { label: 'Por retrusión superior', value: 1 },
  { label: 'Por retroinclinación superior(torque negativo)', value: 2 },
  { label: 'Por protusión inferior', value: 3 },
  { label: 'Por proinclinación inferior(torque positivo)', value: 4 },
  { label: 'Otras', value: 5 },
]
export const lowerOverjetList = [
  { label: 'Por protrusión superior', value: 1 },
  { label: 'Por proinclinación superior(torque positivo)', value: 2 },
  { label: 'Por retrusión inferior', value: 3 },
  { label: 'Por retroinclinación inferior(torque negativo)', value: 4 },
  { label: 'Otras', value: 5 },
]

export const upperOverbiteList = [
  { label: 'Por intrusión anterosuperior', value: 1 },
  { label: 'Por intrusión anteroinferior', value: 2 },
  { label: 'Por extrusión posterosuperior', value: 3 },
  { label: 'Por extrusión posteroinferior', value: 4 },
  { label: 'Otras', value: 5 },
]
export const lowerOverbiteList = [
  { label: 'Por intrusión posterosuperior', value: 1 },
  { label: 'Por intrusión posteroinferior', value: 2 },
  { label: 'Por extrusión anterosuperior', value: 3 },
  { label: 'Por extrusión anteroinferior', value: 4 },
  { label: 'Otras', value: 5 },
]

export const crossBiteList = [
  { label: 'Por expansión superior', value: 1 },
  { label: 'Por torque positivo posterior superior', value: 2 },
  { label: 'Por compresión posterior inferior', value: 3 },
  { label: 'Por torque negativo posterior inferior', value: 4 },
  { label: 'Otras', value: 5 },
]
export const spacesList = [
  { label: 'Por mesialización de sectores posteriores (pérdida de anclaje posterior)', value: 1 },
  { label: 'Por distalización del sector anterior (pérdida de anclaje anterior)', value: 2 },
  { label: 'Cierre recíproco', value: 3 },
  { label: 'Otras', value: 4 },
]
