import React from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import Loader from '../../../components/loader'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../../components/Paginator'
import Searchbar from '../../../components/searchbar'
import { getProfileData } from '../SmileCreator/utilities/helpers'

import DentistHomeCard from './components/DentistHomeCard'
import useOdHome from './hooks/useOdHome'
import './index.css'

const DesktopHome = () => {
  const {
    state,
    filter,
    setState,
    history,
    handleFilter,
    handleGender,
    name,
    surname,
    gender,
    getPaginatorData,
    getUrl,
    category,
  } = useOdHome({ isMobile: false })

  if (state.loading) return <Loader />
  return (
    <div className="odHomeContainer">
      <div className="odHomeHeader p-5 d-flex justify-content-between">
        <div>
          <p className="text-white fs-2 fw-light">Hola</p>
          <p className="text-white fs-2 fw-bold">
            <span>{handleGender(gender)}</span> {`${name} ${surname}`}
            {category.loading ? (
              <Spinner
                color="white"
                style={{
                  height: '1.2rem',
                  width: '1.2rem',
                  marginLeft: '20px',
                  marginBottom: '10px',
                }}
                type="grow"
              >
                Cargando...
              </Spinner>
            ) : (
              <img
                alt="Imagen"
                src={getProfileData(category.image, true)}
                className="smileCreatorIMG"
                onClick={() => {
                  history.replace('/od/smileCreator')
                }}
              />
            )}
          </p>
        </div>
        <Link className="odHeaderBtnDesk px-5 py-0" to="solicitud">
          <p className="fw-bold m-0">NUEVA SOLICITUD</p>
        </Link>
      </div>
      <div className="odHomeBody p-5">
        <div className="d-flex justify-content-between">
          <p className="fs-2 fw-bold m-0">Mis Pacientes</p>
          <div className="col-3 d-flex flex-column px-3">
            <Searchbar
              initialQueryType="odHome"
              state={state}
              setState={setState}
              page={'odHome'}
              url={`${getUrl(filter)}&`}
              isOdHome={true}
            />
          </div>
        </div>
        <div className="d-flex mt-4 mb-2">
          <div
            className={`odFilter${
              filter === 1 ? 'Active' : 'Desactive'
            } py-1 px-4`}
            onClick={() => handleFilter(1)}
          >
            <p className="m-0">Todos ({state.totalPatients})</p>
          </div>
          <div
            className={`odFilter${
              filter === 2 ? 'Active' : 'Desactive'
            } py-1 px-3 ms-3`}
            onClick={() => handleFilter(2)}
          >
            <p className="m-0">Acciones Requeridas ({state.totalPendings})</p>
          </div>
        </div>
        <hr />
        <div className="d-flex">
          <div className="patientHeaderList">
            <p>Paciente</p>
          </div>
          <div className="treatmentHeaderList">
            <p>Tratamiento</p>
          </div>
          <div className="stepHeaderList">
            <p>Etapa</p>
          </div>
        </div>
        <div>
          {state.data.length ? (
            state.data.map((patient) => (
              <DentistHomeCard key={patient.id} patient={patient} />
            ))
          ) : (
            <NotFoundComponent />
          )}
        </div>
        <Paginator
          paginator={state.paginator}
          getPaginator={getPaginatorData}
        />
      </div>
    </div>
  )
}

export default DesktopHome
