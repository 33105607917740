import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { LightgalleryProvider } from 'react-lightgallery'
import returnStoreAndPersistor from './redux/store'
import 'lightgallery.js/dist/css/lightgallery.css'
import './assets/css/keepdesigning.min.css'
import NotFound from './pages/NotFound'
import NewLogin from './pages/Login/NewLogin'
import Ingresos from './pages/Ingresos'
import CargarViabilidad from './pages/CargarViabilidad'
import CargarConsulta from './pages/CargarConsulta'
import Odontologos from './pages/Odontologos'
import Aprobacion from './pages/Aprobacion'
import Escaneo from './pages/Escaneo'
import Impresion from './pages/Impresion'
import PlacasRepeticion from './pages/PlacasRepeticion'
import CargarPlacasRepeticion from './pages/PlacasRepeticion/Nuevo'
import Pacientes from './pages/Pacientes'
import CargarPaciente from './pages/Pacientes/Nuevo'
import PlacasRepeticionOd from './pages/PlacasRepeticionOd'

// OD PAGES
import CargarTratamientoOdontologo from './pages/Solicitud/Tratamiento'
import CargarEtapaOdontologo from './pages/Solicitud/Etapa'
import CargarViabilidadOdontologo from './pages/Solicitud/Viabilidad'
import { Notifications } from './pages/Dentist/Notifications/Notifications'

//HELP PAGES
import Ayuda from './pages/Ayuda'
import BankData from './pages/BankData/index'
import Casos from './pages/CentroAyuda/PresentaCaso'
import CasosHow from './pages/CentroAyuda/PresentaCaso/ComoFunciona'
import CargarCaso from './pages/CentroAyuda/PresentaCaso/EnviarCaso'
import CentroDeAyuda from './pages/CentroAyuda/dashboard'
import AdminCentroDeAyuda from './pages/CentroAyuda/Admin'
import DentistPatientIndex from './pages/Dentist/Patients/DentistPatientIndex'
import GaleriaCasos from './pages/CentroAyuda/GaleriaCasos'
import DetalleCaso from './pages/CentroAyuda/GaleriaCasos/DetalleCaso'

import HistoriaClinica from './pages/HistoriaClinica'
import Documentacion from './pages/HistoriaClinica/Documentacion'
import Claims from './pages/Reclamos/Claims'
import Consultas from './pages/Consultas'
import SolicitudTratamiento from './pages/FormularioOnline'
import SolicitudTratamientoAdmin from './pages/FormularioOnline/admin'
import Comunicaciones from './pages/Comunicaciones'
import HomeAdmin from './pages/HomeAdmin'
import UsuariosAdmin from './pages/Usuarios/index'
import { PatientTreatment } from './pages/Pacientes/Tratamientos/PatientTreatment'
import { Treatment } from './pages/Pacientes/Tratamientos/Treatment'
import { NewPassword } from './pages/Login/NewPassword'
import { ResetPassword } from './pages/Login/ResetPassword'
import { TreatmentIndex } from './pages/Tratamientos/index.js'
import CreateDentist from './pages/Odontologos/admin/CreateDentist.js'
import EditDentist from './pages/Odontologos/admin/EditDentist'
import ProfileIndex from './pages/Dentist/Profile/ProfileIndex'
import LoadTreatmentAdmin from './pages/Solicitud/Admin'
import PriceList from './pages/Pagos/PriceList'
import Movement from './pages/Procesos/Movement/Movement'
import RegistroOriginal from './pages/Procesos/RegistroOriginal/RegistroOriginal'
import Proposal from './pages/Procesos/Proposal/Proposal'
import DentistHome from './pages/Dentist/Home/Home'
import ProposalControl from './pages/Procesos/ProposalControl/ProposalControl'
import DentistMedicalRecord from './pages/DentistMedicalRecord/DentistMedicalRecord'
import MedicalRecordStages from './pages/DentistMedicalRecordStages/MedicalRecordStages'
import Dispatch from './pages/Procesos/ProcessesDispatch/Dispatch'

import DentistConsult from './pages/DentistConsult'
import NotificacionesIndexAdmin from './pages/Notificaciones/Admin'
import Solicitud from './pages/Solicitud'
import Layout from './Layout/Layout'
import PaymentValidation from './pages/PaymentValidation/PaymentValidation'
import Legal from './pages/Legal/Legal'
import Referrals from './pages/Referrals/Referrals'
import SmileCreator from './pages/Dentist/SmileCreator/Benefits/SmileCreator'
import SmileCreatorDetail from './pages/Dentist/SmileCreator/Points/SmileCreatorDetail'

const PrivateRoute = ({ ...props }) => {
  const logged_in = localStorage.getItem('lgt')
  if (logged_in) return <Route {...props} />
  else return <Redirect to="/login" />
}

function App() {
  const { store, persistor } = returnStoreAndPersistor()
  return (
    <LightgalleryProvider licenseKey="07579EEE-13574B7D-98CCD718-5169CA9B">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Layout>
              <Switch>
                <Route exact path="/login" component={NewLogin} />
                <Route
                  exact
                  path="/admin/notificaciones"
                  component={NotificacionesIndexAdmin}
                />
                <Route
                  exact
                  path="/passwordReset/:token"
                  component={NewPassword}
                />
                <Route exact path="/changePassword" component={ResetPassword} />
                <Route
                  exact
                  path="/passwordUpdate/:token"
                  component={NewLogin}
                />

                <PrivateRoute
                  exact
                  path="/procesos/validacionPagos"
                  component={PaymentValidation}
                />
                <PrivateRoute exact path="/centrodeayuda" component={Ayuda} />
                <PrivateRoute
                  exact
                  path="/procesos/escaneo"
                  component={RegistroOriginal}
                />
                <PrivateRoute
                  exact
                  path="/od/centrodeayuda"
                  component={Consultas}
                />
                <PrivateRoute
                  exact
                  path="/procesos/movimiento"
                  component={Movement}
                />
                <PrivateRoute
                  exact
                  path="/od/solicitud"
                  component={Solicitud}
                />

                <PrivateRoute
                  exact
                  path="/procesos/propuesta"
                  component={Proposal}
                />

                <PrivateRoute
                  exact
                  path="/procesos/controlCalidad"
                  component={ProposalControl}
                />
                <PrivateRoute
                  exact
                  path="/procesos/despacho"
                  component={Dispatch}
                />

                <PrivateRoute exact path="/bancos" component={BankData} />
                <PrivateRoute
                  exact
                  path="/centrodeayuda/caso"
                  component={Casos}
                />
                <PrivateRoute
                  exact
                  path="/centrodeayuda/caso/comofunciona"
                  component={CasosHow}
                />
                <PrivateRoute
                  exact
                  path="/centrodeayuda/caso/cargar"
                  component={CargarCaso}
                />
                <PrivateRoute
                  exact
                  path="/centrodeayuda/galeriacasos/"
                  component={GaleriaCasos}
                />
                <PrivateRoute
                  exact
                  path="/centrodeayuda/galeriacasos/:id"
                  component={DetalleCaso}
                />
                <PrivateRoute
                  exact
                  path="/centrodeayuda/admin"
                  component={AdminCentroDeAyuda}
                />
                <PrivateRoute
                  exact
                  path="/centrodeayuda"
                  component={CentroDeAyuda}
                />

                <PrivateRoute exact path="/ingresos/" component={Ingresos} />
                <PrivateRoute
                  exact
                  path="/ingresos/nuevo"
                  component={LoadTreatmentAdmin}
                />
                <PrivateRoute
                  exact
                  path="/tratamientos"
                  component={TreatmentIndex}
                />
                <PrivateRoute
                  exact
                  path="/viabilidad/nuevo"
                  component={CargarViabilidad}
                />
                <PrivateRoute
                  exact
                  path="/derivaciones"
                  component={Referrals}
                />

                <PrivateRoute
                  exact
                  path="/odontologos"
                  component={Odontologos}
                />
                <PrivateRoute
                  exact
                  path="/odontologos/crear"
                  component={CreateDentist}
                />
                <PrivateRoute
                  exact
                  path="/odontologos/:id"
                  component={EditDentist}
                />

                <PrivateRoute exact path="/pacientes" component={Pacientes} />

                <PrivateRoute
                  exact
                  path="/pacientes/nuevo"
                  component={CargarPaciente}
                />

                <PrivateRoute exact path="/od/home" component={DentistHome} />
                <PrivateRoute
                  exact
                  path="/od/notificaciones"
                  component={Notifications}
                />
                <PrivateRoute
                  exact
                  path="/od/perfil"
                  component={ProfileIndex}
                />
                <PrivateRoute
                  exact
                  path="/od/pacientes"
                  component={DentistPatientIndex}
                />

                <PrivateRoute
                  exact
                  path="/od/ingresos/nuevo"
                  component={CargarTratamientoOdontologo}
                />
                <PrivateRoute
                  exact
                  path="/od/etapa/nuevo"
                  component={CargarEtapaOdontologo}
                />
                <PrivateRoute
                  exact
                  path="/od/treatment-stages/:id"
                  component={MedicalRecordStages}
                />
                <PrivateRoute
                  exact
                  path="/od/historia-clinica/:id"
                  component={DentistMedicalRecord}
                />
                <PrivateRoute
                  exact
                  path="/od/viabilidad/nuevo"
                  component={CargarViabilidadOdontologo}
                />
                <PrivateRoute
                  exact
                  path="/od/pacientes/:id/historiaclinica"
                  component={HistoriaClinica}
                />
                <PrivateRoute
                  exact
                  path="/od/pacientes/:id/historiaclinica/:id/documentacion"
                  component={Documentacion}
                />
                <PrivateRoute
                  exact
                  path="/mi-tratamiento/"
                  component={PatientTreatment}
                />
                <PrivateRoute
                  exact
                  path="/mi-tratamiento/:id"
                  component={Treatment}
                />
                <PrivateRoute
                  exact
                  path="/od/smilecreator"
                  component={SmileCreator}
                />
                <PrivateRoute
                  exact
                  path="/od/smileCreator/detalle"
                  component={SmileCreatorDetail}
                />
                <PrivateRoute
                  exact
                  path="/od/placasRepeticion/nuevo"
                  component={PlacasRepeticionOd}
                />
                <PrivateRoute
                  exact
                  path="/od/consultas"
                  component={DentistConsult}
                />
                <PrivateRoute exact path="/od/legal" component={Legal} />
                <PrivateRoute
                  exact
                  path="/admin/consultas"
                  component={Consultas}
                />
                <PrivateRoute
                  exact
                  path="/ayuda/consultas"
                  component={CargarConsulta}
                />

                <PrivateRoute exact path="/reclamos" component={Claims} />
                <PrivateRoute exact path="/ayuda" component={Ayuda} />

                <PrivateRoute exact path="/admin/home" component={HomeAdmin} />
                <PrivateRoute
                  exact
                  path="/admin/usuarios"
                  component={UsuariosAdmin}
                />
                <PrivateRoute
                  exact
                  path="/procesos/impresion"
                  component={Impresion}
                />
                <PrivateRoute
                  exact
                  path="/procesos/placasRepeticion"
                  component={PlacasRepeticion}
                />
                <PrivateRoute
                  exact
                  path="/procesos/placasRepeticion/nuevo"
                  component={CargarPlacasRepeticion}
                />
                <PrivateRoute exact path="/procesos" component={Aprobacion} />
                <PrivateRoute exact path="/escaneo" component={Escaneo} />
                <PrivateRoute
                  exact
                  path="/pagos/lista-precios"
                  component={PriceList}
                />
                <PrivateRoute
                  exact
                  path="/comunicaciones"
                  component={Comunicaciones}
                />

                <PrivateRoute
                  exact
                  path="/tratamiento/:id"
                  component={SolicitudTratamiento}
                />
                <PrivateRoute
                  exact
                  path="/tratamiento-view"
                  component={SolicitudTratamientoAdmin}
                />

                <Route path={'/'} component={NotFound} />
              </Switch>
            </Layout>
          </Router>
        </PersistGate>
      </Provider>
    </LightgalleryProvider>
  )
}

export default App
