import React, { useState } from 'react'
import Loader from '../../../components/loader'
import SearchBar from '../../../components/searchbar'
import NotificationCard from './components/NotificationCard'
import Paginator from '../../../components/Paginator'
import './styles/styles.css'
import ClinicHistoryModal from '../../../components/MedicalRecordModal/ClinicHistoryModal'
import { useNotificationsAdmin } from './hooks/useNotificationsAdmin'
import CountryFilterComponent from '../../../components/CountryFilters/CountryFilterComponent'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'

const NotificationIndexAdmin = () => {
  const [modalState, setModalState] = useState({
    modal_show: null,
    modal_id: null,
  })

  const {
    loading,
    state,
    setState,
    changeTypeHandler,
    changeCountryHandler,
    getNotificationsPaginator,
  } = useNotificationsAdmin(1)

  const handleModal = (id) => {
    if (id)
      return setModalState({
        ...modalState,
        modal_show: !modalState.modal_show,
        modal_id: id,
      })
    else
      return setModalState({
        ...modalState,
        modal_show: false,
      })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <ClinicHistoryModal
            isProposalControl={state.type > 9 ? true : false}
            id={modalState.modal_id}
            opened={modalState.modal_show}
            handleModal={handleModal}
          />
          <div className="d-flex justify-content-between flex-wrap">
            <h1 className="text-primary fw-bold">Notificaciones</h1>
            <div style={{ width: '250px' }}>
              <SearchBar
                initialQueryType={'dentist'}
                url={`/api/notifications?include=treatment.dentists&`}
                state={state}
                setState={setState}
                page={'notificationAdmin'}
              />
            </div>
          </div>
          <div className="NT-bottom-bar">
            <CountryFilterComponent
              state={state.country}
              changeCountryHandler={changeCountryHandler}
            />
          </div>
          <div className="d-flex flex-wrap">
            <div
              className={`cursor-pointer my-3 me-4 ${
                state?.type === 0 && 'fw-bold'
              } `}
              onClick={() => changeTypeHandler(0)}
            >
              <span className="text-primary"> Todos</span>
              <span className="disabled">({state.meta.total})</span>
            </div>
            <div
              className={`cursor-pointer my-3 me-4 ${
                state?.type === 8 && 'fw-bold'
              } `}
              onClick={() => changeTypeHandler(8)}
            >
              <span className="text-primary">Solicitud de Ingreso</span>
              <span className="disabled">({state.meta.total_income})</span>
            </div>
            <div
              className={`cursor-pointer my-3 me-4 ${
                state?.type === 9 && 'fw-bold'
              } `}
              onClick={() => changeTypeHandler(9)}
            >
              <span className="text-primary">Nueva Documentación</span>
              <span className="disabled">
                ({state.meta.total_new_documentation})
              </span>
            </div>
            <div
              className={` my-3 me-4 cursor-pointer ${
                state?.type === 10 && 'fw-bold'
              } `}
              onClick={() => changeTypeHandler(10)}
            >
              <span className="text-primary">Propuesta de Tratamiento</span>
              <span className="disabled">({state.meta.total_proposal})</span>
            </div>
          </div>

          <div className="NT-bottom-bar-two d-flex pb-2">
            <div className="fw-bold my-2 NT-doc">Doctor</div>
            <div className="fw-bold my-2 NT-mot">Motivo</div>
            <div className="fw-bold my-2 NT-det">Detalle</div>
            <div className="fw-bold my-2 NT-fecha">Fecha</div>
          </div>
          <div className="d-flex flex-column mb-3">
            {state.notifications.length ? state.notifications.map((notification, i) => (
              <NotificationCard
                key={i}
                notificationId={notification?.id}
                treatId={notification?.treatment?.id}
                handleModal={handleModal}
                read={notification?.read}
                image={notification?.treatment?.dentists[0]?.user.avatar}
                doc={
                  notification?.treatment?.dentists[0]?.user.name +
                  ' ' +
                  notification?.treatment?.dentists[0]?.user.surname
                }
                motivo={notification?.type}
                detalle={notification?.detail}
                fecha={notification?.created_at}
              />
            )): <NotFoundComponent />}
          </div>
          <Paginator
            paginator={state.paginator}
            getPaginator={getNotificationsPaginator}
          />
        </div>
      )}
    </>
  )
}

export default NotificationIndexAdmin
