import React from 'react'
import TreatmentStateStepBar from '../../../../components/TreatmentStateStepBar'
import '../styles/styles.css'
import { handleRedirect } from '../../../Notificaciones/utilities/helpers'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const NotificationCard3 = ({ data }) => {
  const { treatment } = data
  const history = useHistory()
  return (
    <div className="w-100 notificationCard my-2 p-3 d-flex">
      <div className=" middleLine pe-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap">
            <div className="circleNotification">
              {treatment.frontal_sonriendo && (
                <img src={treatment.frontal_sonriendo} alt="avatar" />
              )}
            </div>
            <div className="d-flex flex-column ms-2">
              <p>{`${treatment.patient.name} ${treatment.patient.surname}`}</p>
              <p className="fw-bold">Nueva Propuesta de Tratamiento</p>
            </div>
          </div>
          <p className="text-muted notificationTime">
            {`${moment(data.created_at).startOf('minutes').fromNow()}`}
          </p>
        </div>
        <div className="d-flex">
          <div className="circleDisabled"></div>
          <div className="w-100">
            <p className="ms-2 mt-2 descriptionCard">
              Tiene una propuesta de tratamiento pendiente de revisión.
            </p>
            <button
              className="medicalRecordBTN ms-2 py-1 px-4 mt-2 mx-auto"
              onClick={() => handleRedirect(treatment.id, history)}
            >
              Historia Clinica
            </button>
          </div>
        </div>
      </div>
      <div className="treatment-state-notifiaction-dentist">
        <TreatmentStateStepBar type={3} />
      </div>
    </div>
  )
}

export default NotificationCard3
