import React, { useRef, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import ImageInput from '../components/ImageInput'

export const ViabilityThreeDentist = ({ state, setState }) => {
  const handleImageSelect = async (name, img, type) => {
    setState({
      ...state,
      [name]: img,
      cropper_img: img,
      [type]: {
        ...state[type],
        [name]: img,
      },
    })
  }

  const otro2 = useRef()
  const rxpanoramica_ref = useRef()
  const telerradiografia_ref = useRef()
  const ricketts_ref = useRef()

  useEffect(() => {
    console.log(state.fotos)
  }, [state])

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="container mt-5">
        <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
          <ImageInput
            name="rxpanoramica"
            src="rxpanoramica"
            id="Rx Panoramica"
            type="estudios"
            loadImage={state.rxpanoramica}
            ref={rxpanoramica_ref}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="telerradiografia"
            src="telerradiografia"
            id="Telerradiografia"
            type="estudios"
            loadImage={state.telerradiografia}
            ref={telerradiografia_ref}
            handleImageSelect={handleImageSelect}
          />
          <ImageInput
            name="ricketts"
            src="ricketts"
            id="Ricketts"
            type="estudios"
            loadImage={state.ricketts}
            ref={ricketts_ref}
            handleImageSelect={handleImageSelect}
          />
          <ImageInput
            name="otro2"
            id="Otro"
            type="estudios"
            loadImage={state.otro2}
            ref={otro2}
            handleImageSelect={handleImageSelect}
          />
        </div>
      </div>
    </div>
  )
}
