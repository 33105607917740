import React from 'react'

const DocumentationSelector = ({
  section,
  setSection,
  originalRegister,
  informationState,
}) => {
  return (
    <div className="d-flex mt-3 pb-3 nav-btns-container flex-wrap">
      <button
        className={`nav-btn text-primary ${
          section === 'photos' ? 'fw-bold' : null
        }`}
        onClick={() => setSection('photos')}
      >
        Fotos
      </button>
      <button
        className={`nav-btn text-primary ${
          section === 'studies' ? 'fw-bold' : null
        }`}
        onClick={() => setSection('studies')}
      >
        Estudios
      </button>
      <button
        className={`nav-btn text-primary ${
          section === 'plan' ? 'fw-bold' : null
        }`}
        onClick={() => setSection('plan')}
      >
        Plan de tratamiento
      </button>
      <button
        disabled={originalRegister ? false : true}
        className={`nav-btn text-primary ${
          section === 'originalRegister' ? 'fw-bold' : null
        } ${originalRegister ? null : 'disabled'}`}
        onClick={() => setSection('originalRegister')}
      >
        Registro original
      </button>
      <button
        disabled={informationState.proposals.length ? false : true}
        className={`nav-btn text-primary  ${
          section === 'treatmentProposal' ? 'fw-bold' : null
        } ${informationState.proposals.length ? null : 'disabled'}`}
        onClick={() => setSection('treatmentProposal')}
      >
        Propuesta de tratamiento
      </button>
    </div>
  )
}

export default DocumentationSelector
