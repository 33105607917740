import React, { useState, useEffect } from 'react'
import apiClient from '../../../../utils/backend'
import './index.css'

export const TreatmentAdminStepFive = ({ state, setState }) => {
  const [offices, setOffices] = useState()
  const [isMexico, setIsMexico] = useState(false)
  const todayDate = new Date().toISOString().slice(0, 10)

  useEffect(() => {
    apiClient
      .get(`api/dentists/${state.dentist_id}/offices`)
      .then((res) => setOffices(res.data.table))
      .catch((err) => console.log(err))

    if (localStorage.getItem('country_id') == '7') {
      setIsMexico(true)
    }
  }, [])

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="container mt-2" style={{ minHeight: '300px' }}>
        <div className="row d-flex flex-row justify-content-center mx-auto px-5">
          <div className="col-6 d-flex justify-content-center">
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-center">
                <div
                  onClick={() =>
                    setState({
                      ...state,
                      intraoral: 1,
                      intraoral_file: true,
                      sendImpressions: {},
                      newIntraoralScanDate: todayDate,
                    })
                  }
                  className={` ${
                    state.intraoral === 1
                      ? 'escaneoIntraoralBTNSelected '
                      : 'escaneoIntraoralBTN'
                  }`}
                >
                  <p className="fs-4 fw-bold">Escaneo intraoral</p>
                  <p className="mt-4">
                    KeepSmiling puede recibir el archivo 3D a través de
                    comunícate, email, we transfer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!isMexico && (
            <div className="col-6 d-flex flex-colum justify-content-center">
              <div className="row">
                <div className="col-12 d-flex flex-row justify-content-center">
                  <div
                    onClick={() =>
                      setState({
                        ...state,
                        intraoral: 0,
                        intraoral_file: false,
                        newIntraoralScanDate: todayDate,
                      })
                    }
                    className={` ${
                      state.intraoral === 0
                        ? 'escaneoIntraoralBTNSelected '
                        : 'escaneoIntraoralBTN'
                    }`}
                  >
                    <p className="fs-4 fw-bold">Envio de impresiones</p>
                    <p className="mt-4">
                      El equipo de logística coordinara para el envió o retiro
                      de impresiones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
