import React from 'react'
import { Modal, ModalBody } from 'reactstrap';

const ConfirmModal = (props) => {
  const {
          className,
          opened,
          handleModal,
          handleStep
        } = props;

  return (
      <div>
          <Modal isOpen={opened} toggle={handleModal} className={className} centered>
              <ModalBody>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mt-3">
                                    <p className="text-primary text-center">La información a continuación no es obligatoria, pero recuerde que cuanta mas información, mejor podrá el equipo KeepSmiling responder a su consulta.</p>
                                    <p className="text-primary text-center">En caso de que, luego de recibir respuesta por parte de KeepSmiling, quiera enviar una solicitud de este paciente, podrá completar esta misma solicitud.</p>
                            </div>
                            <div className="col-6 text-center mx-auto">
                            <button className="btn btn-primary text-white rounded-pill px-5 mt-3" onClick={handleStep}>OK</button>
                            </div>
                        </div>
                    </div>
              </ModalBody>
          </Modal>
      </div>
  );
}

export default ConfirmModal