import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Danger from '../../../assets/images/danger_triangle.png'
import PropTypes from 'prop-types'
import '../../styles.css'
import apiClient from '../../../utils/backend'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

const DeleteTreatmentModal = ({ data }) => {
  const [opened, setOpened] = useState(false)

  const deletTreatmentHandler = async () => {
    try {
      await apiClient.delete(`/api/medicalRecord/${data.id}`)
      setTimeout(() => window.location.reload(), 2000)
      toast.success('Tratamiento elminado exitosamente.')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-start pe-3 mt-4">
        <button
          className="btn btn-outline-warning rounded-pill deleteBTN"
          onClick={() => setOpened(true)}
        >
          Eliminar
        </button>
      </div>
      <Modal style={{ marginTop: '150px' }} isOpen={opened}>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-12 flex text-center">
                <img
                  alt=""
                  src={Danger}
                  className="mt-1"
                  style={{ width: '80px' }}
                />
                <p className="mt-2 text-black fs-4 fw-bold">
                  ¿Estas seguro que deseas eliminar la solicitud:
                </p>
                <p className="text-black fs-5 fw-bold">
                  {`Paciente: ${data?.patient.name} ${data?.patient.surname}`}
                </p>
                <p className="text-black fs-5 fw-bold">
                  {`Doctor: ${data?.dentists[0].user.full_name}`}
                </p>
                <p className="text-black fs-5 fw-bold">
                  Tratamiento: {data?.type === null ? '-' : data?.type?.name}
                </p>
                <p className="text-black pt-2 fs-6">
                  Se eliminaran toda la documentación relacionada a este
                  tratamiento
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <button
            onClick={() => setOpened(false)}
            className="btn btn-outline-dark me-4 rounded-pill px-4"
          >
            Volver
          </button>
          <button
            onClick={() => deletTreatmentHandler()}
            className="btn btn-outline-warning rounded-pill px-4"
          >
            Eliminar
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

DeleteTreatmentModal.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DeleteTreatmentModal
