import React, { useEffect, useState } from 'react'
import apiClient from '../../utils/backend'
import EditButton from './components/EditButton'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.css'
import { Copy } from 'react-feather'
import Loader from '../../components/loader'
import { useSelector } from 'react-redux'

const BankData = () => {
  let test
  const [infoState, setInfoState] = useState(null)
  const [adminUser, setAdminUser] = useState(false)
  const { countryId } = useSelector((state) => state.userReducer)
  console.log(countryId)

  useEffect(() => {
    const apiCall = async () => {
      try {
        const res = await apiClient.get(
          `api/bankData?filter[country_id]=${countryId}`
        )
        setInfoState(res.data.table.body)
      } catch (err) {
        console.log(err)
      }
    }

    apiCall()
    const userType = localStorage.getItem('type_user')
    if (userType === 1) {
      return setAdminUser(true)
    }
    setAdminUser(false)
  }, [])

  if (!infoState) return <Loader />
  return (
    <div className="pb-3">
      <div className="p-5 d-flex justify-content-between flex-wrap">
        <p className="text-primary fw-bold fs-2"> Datos Bancarios </p>
        {adminUser && (
          <EditButton
            btnText={'Editar'}
            onClickHandler={() => console.log('btn-press')}
          />
        )}
      </div>
      <div className="d-flex justify-content-center aling-items-center flex-wrap">
        {infoState.map((bank, index) => {
          return (
            <div key={index} className="bank-card">
              <img className="card-image" src={bank?.logo} alt="bank" />
              <p className="my-5 fs-5 fw-bold text-primary">{bank?.name}</p>
              <p className="light-blue fs-7">{`Titular: ${bank?.headline}`}</p>
              <p className="light-blue fs-7">{`CUIT: ${bank?.cuit}`}</p>
              <p className="light-blue fs-7">{`Sucursal N°: ${bank?.branch_office}`}</p>
              {bank.current_account_cbu_pesos && (
                <>
                  <hr />
                  <p className="light-blue fw-bold fs-7">
                    Cuenta Corriente en PESOS
                  </p>
                  <p className="light-blue fs-7">{`N°: ${bank.current_account_number_pesos}`}</p>
                  <div className="d-flex">
                    <p className="light-blue fs-7">{`CBU: ${bank.current_account_cbu_pesos}`}</p>
                    <CopyToClipboard text={bank.current_account_cbu_pesos}>
                      <Copy className="copy-icon text-primary" />
                    </CopyToClipboard>
                  </div>
                </>
              )}
              {bank.current_account_cbu_usd && (
                <>
                  <hr />
                  <p className="light-blue fw-bold fs-7">
                    Cuenta Corriente en USD
                  </p>
                  <p className="light-blue fs-7">{`N°: ${bank.current_account_number_usd}`}</p>
                  <div className="d-flex">
                    <p className="light-blue fs-7">{`CBU: ${bank.current_account_cbu_usd}`}</p>
                    <CopyToClipboard text={bank.current_account_cbu_usd}>
                      <Copy className="copy-icon text-primary" />
                    </CopyToClipboard>
                  </div>
                </>
              )}
            </div>
          )
        })}
      </div>
      <p className="light-blue bottom-card fs-7">
        Remita el comprobante correspondiente al e-mail{' '}
        <span className="fw-bold text-primary">
          cobranzas@keepsmiling.com.ar
        </span>{' '}
        indicando en el asunto Paciente y Concepto abonado
      </p>
    </div>
  )
}

export default BankData
