import PriceListChile from '../countries/PriceListChile'
import PriceListMexico from '../countries/PriceListMexico'
import PriceListPeru from '../countries/PriceListPeru'

const usePriceList = () => {
  const getCountryPriceList = (country) => {
    if (country === 5) return <PriceListChile />
    if (country === 6) return <PriceListPeru />
    if (country === 7) return <PriceListMexico />
  }

  return {
    getCountryPriceList,
  }
}

export default usePriceList

// Argentina 1
// Uruguay 2
// Paraguay 3
// Colombia 4
// Chile 5
// Perú 6
// México 7
