import { useState } from 'react'
import PropTypes from 'prop-types'

const usePaymentCard = (
  data,
  setPaymentState,
  paymentState,
  setisAllSelected
) => {
  const [state, setState] = useState({ modal_show: false, modal_id: data.id })
  const handleModal = (id) => {
    if (id)
      return setState({ ...state, modal_show: !state.modal_show, modal_id: id })
    else
      return setState({
        ...state,
        modal_show: !state.modal_show,
      })
  }

  const setClinicHistoryType = (type) => {
    const types = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
      'Sin tipo': 1,
    }
    return types[type]
  }

  const selectPayment = (thisPayment) => {
    if (thisPayment.selected) {
      setisAllSelected(false)
      setPaymentState({
        ...paymentState,
        payments: paymentState.payments.map((payment) => {
          if (payment.id === thisPayment.id) {
            return { ...payment, selected: false }
          }
          return payment
        }),
      })
    } else {
      setPaymentState({
        ...paymentState,
        payments: paymentState.payments.map((payment) => {
          if (payment.id === thisPayment.id) {
            return { ...payment, selected: true }
          }
          return payment
        }),
      })
    }
  }

  return {
    handleModal,
    setState,
    setClinicHistoryType,
    selectPayment,
    state,
  }
}

export default usePaymentCard

usePaymentCard.propTypes = {
  data: PropTypes.object.isRequired,
}
