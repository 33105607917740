import React, { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'
import throttle from '../../../utils/throttle'
import successImage from '../../../assets/images/RO-check.png'
import './index.css'
import TreatmentCard from '../components/TreatmentCard'

const Movement = () => {
  const [inputState, setInputState] = useState()
  const [linkInputState, setLinkInputState] = useState()
  const [treatmentCardData, setTreatmentData] = useState()
  const [treatmentId, setTreatmentId] = useState()
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({ id: null, link: null })

  function idInputHandler(e) {
    setTreatmentData(null)
    setLinkInputState('')
    setErrors({ id: null, link: null })
    setInputState(e.target.value)
    throttle(getTreatmentById, e.target.value, 800)
  }

  function linkInputHandler(e) {
    setErrors({ id: null, link: null })
    setLinkInputState(e.target.value)
  }

  async function getTreatmentById(query) {
    if (!query) return setTreatmentData(null)
    try {
      const res = await apiClient.post('api/getByExternalId', {
        external_id: query,
      })
      setTreatmentData(res.data.data)
      setTreatmentId(res.data.data.id)
    } catch (err) {
      setErrors({ id: 'Paciente no encontrado.', link: null })
    }
  }

  async function onSaveBtnHandler() {
    if (!linkInputState)
      return setErrors({ id: null, link: 'Campo obligatorio.' })

    try {
      await apiClient.post('api/processes', {
        process_id: 2,
        treatment_id: treatmentId,
      })

      try {
        const res = await apiClient.patch(
          `api/movements/${treatmentCardData.id}`,
          {
            movements: linkInputState,
          }
        )

        if (res.data.success) return setSuccess(true)
      } catch (err) {
        setErrors({ id: null, link: 'Error cargando el Movimiento.' })
      }
    } catch (err) {
      setErrors({ id: null, link: 'Error creando el Movimiento.' })
    }
  }

  /* const restrict2 = function (e, tb) {
     e.value = e.value.replace(/-/g, '')
    handleSend(e)
  }*/

  const handleSend = (e) => {
    if (e.charCode !== 13) return
    onSaveBtnHandler()
  }

  const handleRestrict = (e) => {
    let lettersRestrict = ['e', 'E', '+', '-']
    lettersRestrict.includes(e.key) && e.preventDefault()
  }

  useEffect(() => {
    if (!success) return
    setTimeout(() => {
      setTreatmentData(null)
      setInputState('')
      setLinkInputState('')
      setSuccess(false)
    }, 3000)
  }, [success])

  return (
    <>
      {!success ? (
        <div className="RO-container">
          <h4 className="fw-bold mb-4 text-primary">Movimiento</h4>
          <p className="my-0 fw-bold mb-3 text-primary">ID:</p>
          <input
            maxLength={15}
            className="RO-input-id "
            value={inputState}
            onChange={idInputHandler}
          />

          {errors.id && <p className="mb-3 mt-2 RO-error">{errors.id}</p>}

          <TreatmentCard treatmentCardData={treatmentCardData} />
          <p
            className={`my-0 fw-bold mb-3 text-primary ${
              !treatmentCardData ? 'disabled' : null
            }`}
          >
            Movimientos:
          </p>
          <input
            disabled={treatmentCardData ? false : true}
            className="RO-input-id "
            type="number"
            maxLength={3}
            onKeyDown={(e) => handleRestrict(e)}
            onKeyPress={(e) => handleSend(e)}
            value={linkInputState}
            onChange={(e) => {
              if (e.target.value.length < 3) {
                // restrict2(e.target)
                linkInputHandler(e)
              }
            }}
          />
          {errors.link && <p className="mb-3 mt-2 RO-error">{errors.link}</p>}
          <div className="d-flex justify-content-end mt-4">
            <button
              disabled={treatmentCardData && linkInputState ? false : true}
              className={`RO-btn RO-light-shadow ${
                !treatmentCardData || !linkInputState ? 'disabled' : null
              }`}
              onClick={onSaveBtnHandler}
            >
              Guardar
            </button>
          </div>
        </div>
      ) : (
        <div className="RO-container d-flex">
          <h4 className="fw-bold mb-4 text-primary">Movimiento</h4>
          <TreatmentCard treatmentCardData={treatmentCardData} />
          {errors.link && <p className="mb-3 mt-2 RO-error">{errors.link}</p>}
          <div className="mt-3 RO-success-image">
            <img width={200} height={200} alt="check" src={successImage} />
          </div>
          <p className="mt-4 RO-success-text justify-content-end">
            ¡Movimientos cargados correctamente!
          </p>
        </div>
      )}
    </>
  )
}

export default Movement
