import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../utils/backend'

export const useNotificationsAdmin = (page) => {
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)
  const [state, setState] = useState({ country: countryId, type: 0 })
  const [loading, setLoading] = useState(true)

  const getNotifications = async (currentPage, country, type) => {
    let url = `/api/notifications?include=treatment.dentists&page=${currentPage}`
    if (type) url += `&filter[type_id]=${type}`
    if (country) url += `&filter[country_id]=${country}`

    try {
      const response = await apiClient.get(url)
      setState({
        country: country,
        type: type,
        meta: response.data.meta,
        notifications: response.data.table,
        paginator: response.data.paginator,
        clearSearchBarInput: true,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const getNotificationsPaginator = async (currentPage) => {
    let url = `/api/notifications?include=treatment.dentists&page=${currentPage}`
    if (state.type) url += `&filter[type_id]=${state.type}`
    if (state.country) url += `&filter[country_id]=${state.country}`

    if (state.searchQuery)
      url = `/api/notifications?include=treatment.dentists&page=${currentPage}&filter[${state.currentFilterType}]=${state.searchQuery}`

    try {
      const response = await apiClient.get(url)
      setState({
        ...state,
        meta: response.data.meta,
        notifications: response.data.table,
        paginator: response.data.paginator,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const changeTypeHandler = (type) => {
    getNotifications(1, state.country, type)
  }

  const changeCountryHandler = (country) => {
    getNotifications(1, country, state.type)
  }

  // reset data after clearing serach
  useEffect(() => {
    if (state?.loadTodosFilter) getNotifications(1, countryId, 0)
  }, [state])

  // loads initial data
  useEffect(() => {
    getNotifications(1, countryId, 0)
  }, [])

  return {
    loading,
    state,
    setState,
    changeTypeHandler,
    changeCountryHandler,
    getNotificationsPaginator,
  }
}
