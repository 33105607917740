import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DentistMedicalRecordInformation from './components/DentistMedicalRecordInformation/DentistMedicalRecordInformation'
import DentistMedicalRecordPagination from './components/DentistMedicalRecordInformation/DentistMedicalRecordPagination'
import DentistMedicalRecordDocumentation from './components/DentistMedicalRecordInformation/Documentation/DentistMedicalRecordDocumentation'
import apiClient from '../../utils/backend'
import Loader from '../../components/loader'
import { DentistMedicalRecordContext } from './components/DentistMedicalRecordInformation/Documentation/context/DentistMedicalRecordContext'
import { useSelector } from 'react-redux'

const DentistMedicalRecord = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const [informationState, setInformationState] = useState(null)
  const [originalRegister, setOriginalRegister] = useState(null)
  const [documentationState, setDocumentationState] = useState(null)
  const [loading, setLoading] = useState(true)
  const params = useParams()

  const loadData = async (info = true, doc = true, id = params.id) => {
    if (info) {
      setInformationState(null)
      try {
        const res = await apiClient(`/api/medicalRecordDentist/${id}`)
        setInformationState(res.data.data)
      } catch (err) {
        return console.log(err, 'Error getting information data')
      }
    }
    if (doc) {
      setDocumentationState(null)
      try {
        const res = await apiClient(
          `/api/medicalRecordDocDentist/${id}?include=form`
        )
        setDocumentationState(res.data.data)
      } catch (err) {
        return console.log(err, `Error getting documentation data`)
      }
    }
  }

  // Load initial data
  useEffect(() => {
    const requestInitialData = async () => {
      try {
        const res = await apiClient(`/api/medicalRecordDentist/${params.id}`)
        setInformationState(res.data.data)
      } catch (err) {
        return console.log(err, 'Error getting information data')
      }
      try {
        const res = await apiClient(
          `/api/medicalRecordDocDentist/${params.id}?include=form`
        )
        setDocumentationState(res.data.data)
      } catch (err) {
        return console.log(err, 'Error getting documentation data')
      }
      try {
        const res = await apiClient(`api/viewerDentist/${params.id}`)
        setOriginalRegister(res.data.data)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
    requestInitialData()
  }, [params.id])

  if (loading || !documentationState || !informationState) return <Loader />
  return (
    <DentistMedicalRecordContext.Provider
      value={{
        informationState,
        documentationState,
        originalRegister,
        treatmentId: params.id,
        loadData,
      }}
    >
      <div
        style={{ maxWidth: pageWidth === 'desktop' ? 1200 : 680 }}
        className="container py-4 px-3"
      >
        <div className="d-flex">
          <h2 className="fw-bold mb-4">Historia Clínica</h2>
        </div>
        <DentistMedicalRecordInformation informationState={informationState} />
        <DentistMedicalRecordDocumentation
          setInformationState={setInformationState}
        />
        <DentistMedicalRecordPagination
          pageWidth={pageWidth}
          informationState={informationState}
        />
      </div>
    </DentistMedicalRecordContext.Provider>
  )
}

export default DentistMedicalRecord
