import React, { Component } from "react";
import apiClient from "../../../utils/backend";
import { connect } from "react-redux";

import Header from '../components/header_image'
import Card from '../components/card'

// import "./style.css";

class index extends Component {

  render() {

    return (
        <div className="container-fluid">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Presenta tu caso</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <Card />
        </div>
    )
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
