import React, { useEffect, useState } from 'react'
import claimAll from '../styles/claimAll.module.css'
import PropTypes from 'prop-types'
import useClaimCard from '../hooks/useClaimCard'
import { handleCanBeClaimed } from '../utilitis/handleCanBeClaimed'

const ClaimsAllCard = ({ data, setClaimState, claimState }) => {
  const { handleSetRequiredData } = useClaimCard(data)

  const [canBeClaimed, setCanBeClaimed] = useState(false)

  let principalDentist = data.dentists.find(
    (dentist) => dentist.id === data.principal_dentist
  )

  const selectClaim = (thisClaim) => {
    if (thisClaim.selected) {
      setClaimState({
        ...claimState,
        claims: claimState.claims.map((claim) => {
          if (claim.id === thisClaim.id) {
            return { ...claim, selected: false }
          }
          return claim
        }),
      })
    } else {
      setClaimState({
        ...claimState,
        claims: claimState.claims.map((claim) => {
          if (claim.id === thisClaim.id) {
            return { ...claim, selected: true }
          }
          return claim
        }),
      })
    }
  }

  useEffect(() => {
    let isCanBeClaimed = handleCanBeClaimed(
      data.proposals_pending,
      data.pending_proposals_count,
      data.flags,
      data.incomplete_documentation_count
    )
    setCanBeClaimed(isCanBeClaimed)
  }, [])

  return (
    <div className={claimAll.card}>
      <div className={claimAll.id}>{data.id}</div>
      <div
        className={`${claimAll.patient} text-truncate`}
        title={`${data.patient.name} ${data.patient.surname}`}
      >
        {`${data.patient.name} ${data.patient.surname}`}
      </div>
      <div
        className={`${claimAll.doctor} text-truncate`}
        title={`${principalDentist?.user.name} ${principalDentist?.user.surname}`}
      >
        {`${principalDentist?.user.name} ${principalDentist?.user.surname}`}
      </div>
      <div className={claimAll.requiredAction} style={{ color: '#E42129' }}>
        {handleSetRequiredData(data.proposals_pending, data.flags)}
      </div>
      <div className={claimAll.flagsFather}>
        {!data.flags.photos && <div className={claimAll.flags}>Fotos</div>}
        {!data.flags.plan && <div className={claimAll.flags}>Plan</div>}
        {!data.flags.studies && <div className={claimAll.flags}>Estudios</div>}
      </div>
      {canBeClaimed && (
        <input
          type="checkbox"
          className={claimAll.checkbox}
          checked={data.selected}
          onClick={() => selectClaim(data)}
        />
      )}
      {!canBeClaimed && (
        <input type="checkbox" className={claimAll.checkbox} disabled />
      )}
    </div>
  )
}

export default ClaimsAllCard

ClaimsAllCard.propTypes = {
  data: PropTypes.object.isRequired,
  setClaimsList: PropTypes.func.isRequired,
  claimsList: PropTypes.array.isRequired,
}
