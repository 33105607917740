import React from "react";
import { Bar } from "react-chartjs-2";

const data = {
  labels: ["Ingresos", "Reclamos", "Consultas", "Control"],
  completos: {
    label: "08/10/2019 - 09/30/2019",
    dataSet: [85, 90, 40, 120],
  },
  proceso: {
    label: "08/10/2019 - 09/30/2019",
    dataSet: [85, 90, 40, 120],
  },
  pendientes: {
    label: "08/10/2019 - 09/30/2019",
    dataSet: [85, 90, 40, 120],
  },
};

export default function TodayChart() {
  return (
    <div className="shadow p-3" style={{ borderRadius: "20px" }}>
      <Bar
        pointStyle="star"
        data={{
          labels: data.labels,
          responsive: true,
          offset: true,
          datasets: [
            {
              label: "Completos",
              pointStyle: "rectRounded",
              backgroundColor: "#45C15A",
              barThickness: 15,
              categoryPercentage: 1,
              data: data.completos.dataSet, //From API
            },
            {
              label: "En Proceso",
              pointStyle: "rectRounded",
              backgroundColor: "#E5F82A",
              barThickness: 15,
              categoryPercentage: 1,
              data: data.proceso.dataSet, //From API
            },
            {
              label: "Pendientes",
              pointStyle: "rectRounded",
              backgroundColor: "#D2D5DB",
              barThickness: 15,
              categoryPercentage: 1,
              data: data.pendientes.dataSet, //From API
            },
          ],
        }}
        height={220}
        options={{
          offsetGridLines: true,
          drawTicks: true,
          layout: {
            padding: {
              top: 10,
              right: 10,
              bottom: 10,
            },
          },
          legend: {
            display: true,
            position: "top",
            align: "center",
            labels: {
              usePointStyle: true,
              fontSize: 14,
            },
          },
          tooltips: { display: false },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  padding: 5,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
}
