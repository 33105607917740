import React from 'react'
import styles from '../styles/referralAll.module.css'
import PropTypes from 'prop-types'
import { selectReferral } from '../utils/selectReferral'

export const ReferralsAllCard = ({
  data,
  referralsState,
  setReferralsState,
  setisAllSelected,
}) => {
  const {
    full_name,
    email,
    phone,
    preferred_time,
    location,
    status,
    selected,
  } = data

  return (
    <div className={styles.card}>
      <div className={`${styles.fullName} text-truncate`} title={full_name}>
        {full_name}
      </div>
      <div className={`${styles.email} text-truncate`} title={full_name}>
        {email}
      </div>
      <div className={styles.phone} style={{ marginLeft: '8px' }} title={phone}>
        {phone}
      </div>
      <div
        className={styles.preferenceDate}
        style={{ marginLeft: '28px' }}
        title={preferred_time}
      >
        {preferred_time}
      </div>
      <div
        className={styles.locality}
        style={{ marginLeft: '3px' }}
        title={location}
      >
        {location}
      </div>
      {status === 'derived' ? (
        <input type="checkbox" className={styles.checkbox} disabled />
      ) : (
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={selected}
          onChange={() =>
            selectReferral(
              data,
              setisAllSelected,
              setReferralsState,
              referralsState
            )
          }
        />
      )}
    </div>
  )
}

ReferralsAllCard.propTypes = {
  data: PropTypes.object.isRequired,
  referralsState: PropTypes.object.isRequired,
  setReferralsState: PropTypes.func.isRequired,
  setisAllSelected: PropTypes.func.isRequired,
}
