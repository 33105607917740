import React, { Component } from "react";
import apiClient from "../../utils/backend";
import moment from "moment";
import { connect } from "react-redux";
import "moment/locale/es";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: {},
      title: "",
      cards: [],
      loading: true,
      country: "",
      country_filter: false,
      isopened: false,
      type: "todos",
    };

    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {
    const path = window.location.pathname.split("/")[2];
    moment.locale("es");
    console.log(path);
    console.log(this.props);

    apiClient
      .get(`/api/treatmentPlateRepeat`)
      .then((res) => {
        console.log("data");
        console.log(res.data);
        this.setState({
          table: res.data.table,
          title: res.data.title,
          country_filter: res.data.country_filter,
          country: res.data.user_country,
          cards: res.data.card,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  handleModal(modal) {
    console.log("handling modal, ", modal);
    this.setState({ modal_show: modal });
  }

  handleStatus(id, status) {
    apiClient
      .post(`/api/treatmentPlateRepeat/changeStatus`, {
        id: id,
        status: status,
      })
      .then((res) => {
        console.log("data");
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  render() {
    moment.locale();
    const dia = moment().format("dddd");
    const fecha = moment().format("LL");

    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-3 mt-2">
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontWeight: 700 }} className="text-primary">
                Placas de Repetición
              </h1>
            </div>
            {/* {this.state.cards.map(card => {
                  return <div className="col-3 mt-4 d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row">
                    <div className="row shadow py-2 mx-2 rounded">
                      <div className="col-4"><h2 className="justify-content-center align-middle text-center m-0">{card.value}</h2></div>
                      <div className="col-8"><p className="justify-content-center align-middle m-0">{card.title}</p></div>
                    </div>
                  </div>
                </div>
                })} */}
            <div className="col-3 mt-4 d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <div className="row shadow py-2 mx-2 rounded">
                  <div className="col-4">
                    <h2 className="justify-content-center align-middle text-center m-0">
                      25
                    </h2>
                  </div>
                  <div className="col-8">
                    <p className="justify-content-center align-middle m-0">
                      Nuevas placas para repetir impresión
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 mt-4 d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <div className="row shadow py-2 mx-2 rounded">
                  <div className="col-4">
                    <h2 className="justify-content-center align-middle text-center m-0">
                      25
                    </h2>
                  </div>
                  <div className="col-8">
                    <p className="justify-content-center align-middle m-0">
                      Nuevas placas de impresión
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {this.state.country_filter && (
              <div className="col-12">
                <ul
                  className="d-flex flex-row justify-content-start p-0"
                  style={{ listStyleType: "none" }}
                >
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ type: "todos" })}
                    >
                      Todos los pedidos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ type: "sin_asignar" })}
                    >
                      Sin asignar
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ type: "imprimir" })}
                    >
                      Para imprimir
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ type: "repetir" })}
                    >
                      Para repetir
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <div className="col-12">
              <input
                type="text"
                className="w-100 border-0 p-3"
                placeholder="Escribe al menos 3 caracteres..."
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="row mt-5">
            {this.state.table.head.map((header) => (
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">{header}</p>
              </div>
            ))}
          </div>
          {this.state.table.body[this.state.type][this.state.country].map(
            (item, i) => {
              let asd = Object.entries(item);
              let actions = asd.pop();
              let id = item["id"];
              console.log(item);

              return (
                <div className="row  w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
                  <button className="clinic-history-button type-1"></button>
                  {asd.map((subitem) => (
                    <div className="col d-flex flex-row justify-content-center text-center">
                      <p
                        className="m-0 text-center justify-items-middle text-wrap"
                        style={{ fontSize: ".8rem" }}
                      >
                        {subitem[1]}
                      </p>
                    </div>
                  ))}
                  <div className="col d-flex flex-row justify-content-center">
                    {item.status === "Pendiente Asignacion" && (
                      <React.Fragment>
                        <button
                          onClick={() =>
                            this.handleStatus(item.id, "Pendiente Repeticion")
                          }
                          className="btn btn-sm btn-primary"
                          style={{ marginLeft: "5px" }}
                        >
                          Repetir
                        </button>
                        <button
                          onClick={() =>
                            this.handleStatus(
                              item.id,
                              "Pendiente Impresion Rep"
                            )
                          }
                          className="btn btn-sm btn-primary"
                          style={{ marginLeft: "5px" }}
                        >
                          Imprimir
                        </button>
                      </React.Fragment>
                    )}

                    {item.status === "Pendiente Impresion Rep" ||
                    item.status === "Pendiente Repeticion" ? (
                      <React.Fragment>
                        <button
                          onClick={() =>
                            this.handleStatus(
                              item.id,
                              item.status === "Pendiente Impresion Rep"
                                ? "En Proceso Impresion"
                                : "En Proceso Repeticion"
                            )
                          }
                          className="btn btn-sm btn-success text-white"
                          style={{ marginLeft: "5px" }}
                        >
                          Tomar
                        </button>
                      </React.Fragment>
                    ) : null}

                    {item.status === "En Proceso Repeticion" ||
                    item.status === "En Proceso Impresion" ? (
                      <React.Fragment>
                        <button
                          onClick={() =>
                            this.handleStatus(
                              item.id,
                              item.status === "En Proceso Impresion"
                                ? "Finalizado Impresion"
                                : "Finalizado Repeticion"
                            )
                          }
                          className="btn btn-sm btn-success text-white"
                          style={{ marginLeft: "5px" }}
                        >
                          Ok
                        </button>
                      </React.Fragment>
                    ) : null}

                    {item.status === "Finalizado Impresion" ||
                    item.status === "Finalizado Repeticion" ? (
                      <React.Fragment>
                        <button
                          className="btn btn-sm btn-success text-white disabled"
                          style={{ marginLeft: "5px" }}
                        >
                          Finalizado
                        </button>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              );
            }
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
