import React from 'react'
import Searchbar from '../../../components/searchbar'
import MobilePatientCard from '../../../components/MobilePatientCard/MobilePatientCard'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../../components/Paginator'
import Loader from '../../../components/loader'
import useDentistPatients from './hooks/useDentistPatients'
import DentistFiltersMobile from './components/DentistFiltersMobile'

const MobileIndex = () => {
  const {
    state,
    setState,
    getUrl,
    filter,
    handleFilter,
    countryId,
    getPaginatorData,
  } = useDentistPatients({ isMobile: true })

  if (!state) return <Loader />

  const handleSetRequiredData = (state) => {
    const flags = {
      studies: state.treatment.estudios,
      photos: state.treatment.fotos,
      plan: state.treatment.plan,
    }
    const { studies, photos, plan } = flags
    if (state.treatment.missing_documentation.length > 0) {
      return `proposal`
    } else if (!studies || !photos || !plan) {
      return `documentation`
    }
  }

  return (
    <div className="px-3 py-4">
      <h1 className="fw-bold mb-3">Mis Pacientes</h1>
      <DentistFiltersMobile
        state={state}
        filter={filter}
        handleFilter={handleFilter}
        countryId={countryId}
      />
      <hr />
      <Searchbar
        state={state}
        setState={setState}
        page="myPatients"
        url={`${getUrl(filter)}&`}
      />
      <div className="my-4 d-flex flex-column align-items-center">
        {!!state.patients.length ? (
          state.patients.map((item) => (
            <MobilePatientCard
              treatmentId={item.patient.id}
              avatar={item.treatment.frontal_sonriendo}
              name={item.patient.name + ' ' + item.patient.surname}
              maxilar={item.treatment.maxilla}
              stage={item.treatment.order}
              type={handleSetRequiredData(item)}
              treatmentType={item.treatment.type.id}
            />
          ))
        ) : (
          <NotFoundComponent />
        )}
      </div>
      <Paginator
        center={true}
        paginator={state.paginator}
        getPaginator={getPaginatorData}
      />
    </div>
  )
}

export default MobileIndex
