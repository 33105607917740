import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/reminders.module.css'

const ProfileReminder = ({ setModal }) => {
  return (
    <div className="p-4">
      <div className="d-flex flex-column align-items-center">
        <p className={styles.title}>¡No se olvide de completar sus datos!</p>
        <p className={styles.text}>
          Todavía esta pendiente completar datos de direcciones en su perfil.
        </p>
        <p className={styles.icon}>😥</p>
        <Link
          className={styles.button}
          to="/od/perfil"
          onClick={() =>
            setModal((prev) => ({ ...prev, complete_offices: true }))
          }
        >
          Ir a completar mi perfil
        </Link>
      </div>
    </div>
  )
}

export default ProfileReminder
