import React, {useState, useEffect, Fragment} from "react";
import apiClient from "../../../utils/backend";

function ElegirPaciente(props) {

    const [viewPaciente, setViewPaciente] = useState(true)
    const [pacientes, setPacientes] = useState([])

    useEffect(() => {
        console.log(props)
        apiClient
            .get(`api/helper/getPatientsByDentist/1`)
            .then((res) => {
                let patients = [];

                Object.entries(res.data).map(([key, value]) => {
                    patients.push({value: key, label: value});
                });

                setPacientes(patients)
            })
    }, [])

    return (
        <div className="container-fluid text-primary">
        </div>
    )
}

export default ElegirPaciente