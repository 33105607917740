import React from 'react'
import Home from '../../../assets/icons/Home.svg'
import Inquires from '../../../assets/icons/Inquires.svg'
import Pacientes from '../../../assets/icons/Patients.svg'
import Payment from '../../../assets/icons/Payment.svg'
import SmileCreator from '../../../assets/icons/Smile.svg'
import UsuariosAdmin from '../../../assets/icons/Usuarios.svg'
import Odontologos from '../../../assets/icons/Odontologo.svg'
import Procesos from '../../../assets/icons/Procesos.svg'
import Tratamientos from '../../../assets/icons/Tratamientos.svg'
import Application from '../../../assets/icons/Application.svg'
import Studies from '../../../assets/icons/Studies.svg'
import Help from '../../../assets/icons/Help.svg'
import Legal from '../../../assets/icons/Legal.png'
import Referrals from '../../../assets/icons/Referrals.png'

import '../sidebar.css'

const Icons = ({ type }) => {
  const icon = {
    home: Home,
    income: Inquires,
    treatment: Tratamientos,
    patient: Pacientes,
    dentist: Odontologos,
    process: Procesos,
    pay: Payment,
    claim: Inquires,
    smile: SmileCreator,
    user: UsuariosAdmin,
    application: Application,
    consult: Inquires,
    group: Studies,
    help: Help,
    legal: Legal,
    referral: Referrals,
  }
  return (
    <>
      <img alt="sidebar_logo" className="sb-icons" src={icon[type]} />
    </>
  )
}

export default Icons
