import React from "react";
import { useHistory } from "react-router-dom";
import Paciente from "./paciente";

const TiposConsultas = {
  Clinica: [
    {
      title: "Los alineadores no calzan bien",
      action: {
        type: "redirect",
        to: "www.google.com",
      },
    },
    {
      title: "Quiero enviar documentación faltante",
      action: {
        type: "redirect",
        to: "www.facebook.com",
      },
    },
    {
      title: "A mi paciente se le rompió un alineador",
      action: {
        type: "redirect",
        to: "/od/placasRepeticion/nuevo",
      },
    },
    {
      title: "Quiero iniciar la segunda etapa",
    },
    {
      title: "Problemas al colocar Attachments",
    },
    {
      title: "El tratamiento no avanza como tiene que avanzar",
    },
    {
      title: "Stripping",
    },
    {
      title: "Otras..",
      action: {
        type: "form",
      },
    },
  ],
  Marketing: [
    {
      title: "Los alineadores no calzan bien",
      action: {
        type: "redirect",
        to: "www.google.com",
      },
    },
    {
      title: "Quiero enviar documentación faltante",
      action: {
        type: "redirect",
        to: "www.facebook.com",
      },
    },
  ],
};

function TiposConsulta({ paciente, categoria, setTipoConsulta, setStep }) {
  const history = useHistory();

  const handleAction = (action, tipoConsulta) => {
    if (action.type === "redirect") {
      history.push(action.to);
    } else if (action.type === "form") {
      setTipoConsulta(tipoConsulta);
      setStep("form");
    }
  };

  return (
    <div className="container text-primary">
      <h2 className="fw-bold mt-5">
        {categoria} |{" "}
        <span className="fw-normal" style={{ fontSize: 12 }}>
          Su consulta bls bla
        </span>
      </h2>

      <div className="d-flex flex-row mt-5">
        {paciente && <Paciente paciente={paciente} />}

        <div className={`d-flex flex-column ${paciente && "mx-6"} w-100`}>
          <h4 className="fw-bold">¿En que podemos ayudar?</h4>
          <p className="mt-2" style={{ fontSize: 12 }}>
            Indique cual es el motivo de su consulta clínica para poder
            atenderlo mejor.
          </p>

          <div className="d-flex flex-row flex-wrap mt-4">
            {categoria !== null &&
              TiposConsultas[categoria].map((tipoConsulta) => (
                <div
                  onClick={() =>
                    handleAction(tipoConsulta.action, tipoConsulta.title)
                  }
                  style={{
                    height: 50,
                    borderRadius: 15,
                    cursor: "pointer",
                    marginRight: 20,
                    marginBottom: 10,
                  }}
                  className="d-flex justify-content-start align-items-center border border-primary px-3 w-40"
                >
                  <span className="fw-bold">{tipoConsulta.title}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TiposConsulta;
