import React from 'react'

const ColNamesRow = ({ styles }) => {
  return (
    <>
      <p className={styles.id}>ID</p>
      <p className={styles.patients}>Paciente</p>
      <p className={styles.doctor}>Doctor</p>
      <p className={styles.treatment}>Tratamiento</p>
      <p className={styles.stage}>Etapa</p>
      <p className={styles.incomeDate}>Fecha de Ingreso</p>
      <p className={styles.enable}>Habilitar</p>
    </>
  )
}

export default ColNamesRow
