import React, { useRef } from 'react'
import ImageInput from '../components/ImageInput'
import DocImage from './DocImage'
import DocImageInput from './DocImageInput'

export const TreatmentAdminStepTwo = ({
  state,
  setState,
  photosFiles,
  setPhotosFiles,
}) => {
  const handleImagePic = (image) => {
    let type = image?.type ? 'pdf' : 'image'
    const newFile = { file: image, type }
    setPhotosFiles([...photosFiles, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newPhotosFile = photosFiles
    newPhotosFile.splice(indexToDelete, 1)
    setPhotosFiles([...newPhotosFile])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newPhotosFile = photosFiles
    newPhotosFile[indexToEdit].file = image
    setPhotosFiles([...newPhotosFile])
  }

  const handleImageSelect = async (name, img, type) => {
    setState({
      ...state,
      [name]: img,
      cropper_img: img,
      [type]: {
        ...state[type],
        [name]: img,
      },
    })
  }

  const frontal_reposo = useRef()
  const frontal_sonriendo = useRef()
  const perfil_izq_reposo = useRef()
  const trescuartos_perfil = useRef()
  const oclusal_maxilar_sup = useRef()
  const oclusal_maxilar_inf = useRef()
  const anterior_oclusion = useRef()
  const bucal_derecho = useRef()
  const bucal_izquierdo = useRef()
  const anterior_inoclusion = useRef()

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="container mt-2">
        <div className="row col-11 d-flex flex-row mx-auto px-5">
          <ImageInput
            name="frontal_reposo"
            src="frontal_reposo"
            id="Frontal Reposo"
            type="fotos"
            loadImage={state.frontal_reposo}
            ref={frontal_reposo}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="frontal_sonriendo"
            src="frontal_sonriendo"
            id="Frontal Sonriendo"
            type="fotos"
            loadImage={state.frontal_sonriendo}
            ref={frontal_sonriendo}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="perfil_izq_reposo"
            src="perfil_izq_reposo"
            id="Perfil Izquierdo Reposo"
            type="fotos"
            loadImage={state.perfil_izq_reposo}
            ref={perfil_izq_reposo}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="trescuartos_perfil"
            src="trescuartos_perfil"
            id="3/4 Perfil"
            type="fotos"
            loadImage={state.trescuartos_perfil}
            ref={trescuartos_perfil}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="oclusal_maxilar_sup"
            src="oclusal_maxilar_sup"
            id="Oclusal Maxilar Superior"
            type="fotos"
            loadImage={state.oclusal_maxilar_sup}
            ref={oclusal_maxilar_sup}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="oclusal_maxilar_inf"
            src="oclusal_maxilar_inf"
            id="Oclusal Maxilar Inferior"
            type="fotos"
            loadImage={state.oclusal_maxilar_inf}
            ref={oclusal_maxilar_inf}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="anterior_oclusion"
            src="anterior_oclusion"
            id="Anterior Oclusion"
            type="fotos"
            loadImage={state.anterior_oclusion}
            ref={anterior_oclusion}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="bucal_derecho"
            src="bucal_derecho"
            id="Bucal Derecho"
            type="fotos"
            loadImage={state.bucal_derecho}
            ref={bucal_derecho}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="bucal_izquierdo"
            src="bucal_izquierdo"
            id="Bucal Izquierdo"
            type="fotos"
            loadImage={state.bucal_izquierdo}
            ref={bucal_izquierdo}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="anterior_inoclusion"
            src="anterior_inoclusion"
            id="Anterior Inoclusion"
            type="fotos"
            loadImage={state.anterior_inoclusion}
            ref={anterior_inoclusion}
            handleImageSelect={handleImageSelect}
          />
          <div className="mt-3 d-flex flex-wrap gap-5">
            {photosFiles.map((item, index) => (
              <DocImage
                index={index}
                type={item.type}
                image={item.file}
                handleDeletePlan={handleDeletePlan}
                handleEditPlan={handleEditPlan}
                inputName="Otros"
              />
            ))}
            {photosFiles.length < 5 && (
              <DocImageInput
                allowPDF={true}
                inputName="Otros"
                handleImageSelect={handleImagePic}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
