import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import CheckBoxList from '../Utilities/CheckBoxList'
import {
  lowerOverbiteList,
  lowerOverjetList,
  mantain_keep_options,
  upperOverbiteList,
  upperOverjetList,
} from '../Utilities/OptionsRadios'
import RadioList from '../Utilities/RadioList'
import TextAreaCounter from '../Utilities/TextAreaCounter'

function OverjetOverBite(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'OverjetOverbite'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
  const overjet = middle_state.overjet
  const overbite = middle_state.overbite
  const overjetUp = middle_state.overjetUp
  const overjetDown = middle_state.overjetDown
  const overbiteUp = middle_state.overbiteUp
  const overbiteDown = middle_state.overbiteDown

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  const error3 = errors_list.find((err) => err.order === 3)
  const error4 = errors_list.find((err) => err.order === 4)
  const error5 = errors_list.find((err) => err.order === 5)
  const error6 = errors_list.find((err) => err.order === 6)

  useEffect(() => {
    if (errors_list.find((err) => err.order === 1)) {
      updateErrors(errors_list.filter((err) => err.order === 2))
    }
    if (errors_list.find((err) => err.order === 2)) {
      updateErrors(errors_list.filter((err) => err.order === 1))
    }
  }, [middle_state])

  const updateOverjetUp = (val, chk) => {
    let updatedArray = []
    if (chk) {
      updatedArray = [...middle_state.overjetUp, val]
    } else {
      updatedArray = middle_state.overjetUp.filter((itm) => itm !== val)
    }
    updateState({ key: 'overjetUp', value: updatedArray })
  }
  const updateOverjetDown = (val, chk) => {
    let updatedArray = []
    if (chk) {
      updatedArray = [...middle_state.overjetDown, val]
    } else {
      updatedArray = middle_state.overjetDown.filter((itm) => itm !== val)
    }
    updateState({ key: 'overjetDown', value: updatedArray })
  }
  const updateOverbiteUp = (val, chk) => {
    let updatedArray = []
    if (chk) {
      updatedArray = [...middle_state.overbiteUp, val]
    } else {
      updatedArray = middle_state.overbiteUp.filter((itm) => itm !== val)
    }
    updateState({ key: 'overbiteUp', value: updatedArray })
  }
  const updateOverbiteDown = (val, chk) => {
    let updatedArray = []
    if (chk) {
      updatedArray = [...middle_state.overbiteDown, val]
    } else {
      updatedArray = middle_state.overbiteDown.filter((itm) => itm !== val)
    }
    updateState({ key: 'overbiteDown', value: updatedArray })
  }

  useEffect(() => {
    if (overjet === 1) {
      updateState({ key: 'overjetUp', value: [] })
      updateState({ key: 'overjetDown', value: [] })
    }
  }, [overjet, updateState])

  useEffect(() => {
    if (overbite === 1) {
      updateState({ key: 'overbiteUp', value: [] })
      updateState({ key: 'overbiteDown', value: [] })
    }
  }, [overbite, updateState])

  return (
    <div>
      <div
        className="w-100 fw-bold pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Overjet
      </div>
      {error1 && (
        <div className="alert-danger alert-link p-2 mt-1">{error1.message}</div>
      )}
      <RadioList
        enabled={props.formOnlineReducer.Enabled}
        colors={colors}
        radios={mantain_keep_options}
        setter={(value) => updateState({ key: 'overjet', value })}
        stateField={overjet}
        name="overjet"
      />
      {overjet === 2 && (
        <div className="ms-2">
          <div
            className="mt-3 ms-3 small pb-1"
            style={{ borderBottom: `1px solid ${colors.color}` }}
          >
            ¿Cómo desea mejorar el Overjet?
          </div>
          <div className="d-flex ms-3 mt-3">
            <div className="col-6">
              <div
                className="w-90 small pb-1"
                style={{ borderBottom: `1px solid ${colors.color}` }}
              >
                Overjet Aumentado
              </div>

              <CheckBoxList
                enabled={props.formOnlineReducer.Enabled}
                colors={colors}
                radios={upperOverjetList}
                setter={(value, checked) => updateOverjetUp(value, checked)}
                stateField={overjetUp}
                name="upperOverjet"
              />
              {overjetUp?.includes(5) && (
                <div className="d-flex justify-content-center">
                  <div className="mt-3 w-60">
                    <small>Otras:</small>
                    <textarea
                      disabled={!props.formOnlineReducer.Enabled}
                      value={middle_state.overjetUpOthers}
                      onChange={(e) => {
                        updateState({
                          key: 'overjetUpOthers',
                          value: e.target.value,
                        })
                      }}
                      className="form-control mt-1"
                      name="observacion"
                      id="observacion"
                      cols="30"
                      rows="3"
                    ></textarea>
                    <TextAreaCounter
                      value={middle_state.overjetUpOthers}
                      maxValue={2500}
                    />
                    {error3 && (
                      <div className="alert-danger alert-link p-2 mt-1">
                        {error3.message}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              <div
                className="w-100 small pb-1"
                style={{ borderBottom: `1px solid ${colors.color}` }}
              >
                Overjet Disminuido
              </div>
              <CheckBoxList
                enabled={props.formOnlineReducer.Enabled}
                colors={colors}
                radios={lowerOverjetList}
                setter={(value, checked) => updateOverjetDown(value, checked)}
                stateField={overjetDown}
                name="lowerOverjet"
              />
              {overjetDown?.includes(5) && (
                <div className="d-flex justify-content-center">
                  <div className="mt-3 w-60">
                    <small>Otras:</small>
                    <textarea
                      disabled={!props.formOnlineReducer.Enabled}
                      value={middle_state.overjetDownOthers}
                      onChange={(e) => {
                        updateState({
                          key: 'overjetDownOthers',
                          value: e.target.value,
                        })
                      }}
                      className="form-control mt-1"
                      name="observacion"
                      id="observacion"
                      cols="30"
                      rows="3"
                    ></textarea>
                    <TextAreaCounter
                      value={middle_state.overjetDownOthers}
                      maxValue={2500}
                    />
                    {error4 && (
                      <div className="alert-danger alert-link p-2 mt-1">
                        {error4.message}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="mt-5 w-100">
        <div
          className="w-100 fw-bold pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Overbite - Entrecruzamiento
        </div>
        {error2 && (
          <div className="alert-danger alert-link p-2 mt-1">
            {error2.message}
          </div>
        )}
        <RadioList
          enabled={props.formOnlineReducer.Enabled}
          colors={colors}
          radios={mantain_keep_options}
          setter={(value) => updateState({ key: 'overbite', value })}
          stateField={overbite}
          name="overbite"
        />
        {overbite === 2 && (
          <div className="ms-2">
            {props.formOnlineReducer.Enabled && (
              <div
                className="rounded mb-3 mt-2 text-light p-2 d-flex flex-column"
                style={{ backgroundColor: '#00155c' }}
              >
                <small className="fw-bold text-center force-inline-text">
                  RECUERDE QUE PARA MEJORAR OVERBITE SERÁN NECESARIOS
                  ATTACHMENTS PARA MOVIMIENTOS VERTICALES
                </small>
                <small className="mt-4 text-center">
                  Compruebe que su respuesta coincida con lo indicado en la
                  sección de acuerdo al uso de attachments en su tratamiento.
                  Cualquier duda sobre el uso de attachments puede comunicarse
                  con nuestro equipo de ortodoncia antes de completar esta
                  sección.
                </small>
              </div>
            )}
            <div
              className="mt-3 ms-3 fw-bolder small"
              style={{ borderBottom: `1px solid ${colors.color}` }}
            >
              ¿Cómo desea mejorar el Overbite?
            </div>
            <div className="d-flex ms-3 mt-3">
              <div className="col-6">
                <div
                  className="w-90 fw-bolder small"
                  style={{ borderBottom: `1px solid ${colors.color}` }}
                >
                  Overbite Aumentado
                </div>
                <CheckBoxList
                  enabled={props.formOnlineReducer.Enabled}
                  colors={colors}
                  radios={upperOverbiteList}
                  setter={(value, checked) => updateOverbiteUp(value, checked)}
                  stateField={overbiteUp}
                  name="upperOverbite"
                />
                {overbiteUp?.includes(5) && (
                  <div className="d-flex justify-content-center">
                    <div className="mt-3 w-60">
                      <small>Otras:</small>
                      <textarea
                        disabled={!props.formOnlineReducer.Enabled}
                        onChange={(e) => {
                          updateState({
                            key: 'overbiteUpOthers',
                            value: e.target.value,
                          })
                        }}
                        value={middle_state.overbiteUpOthers}
                        className="form-control mt-1"
                        name="observacion"
                        id="observacion"
                        cols="30"
                        rows="3"
                      ></textarea>
                      <TextAreaCounter
                        value={middle_state.overbiteUpOthers}
                        maxValue={2500}
                      />
                      {error5 && (
                        <div className="alert-danger alert-link p-2 mt-1">
                          {error5.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-6">
                <div
                  className="w-100 fw-bolder small"
                  style={{ borderBottom: `1px solid ${colors.color}` }}
                >
                  Overbite Disminuido
                </div>
                <CheckBoxList
                  enabled={props.formOnlineReducer.Enabled}
                  colors={colors}
                  radios={lowerOverbiteList}
                  setter={(value, checked) =>
                    updateOverbiteDown(value, checked)
                  }
                  stateField={overbiteDown}
                  name="lowerOverbite"
                />
                {overbiteDown?.includes(5) && (
                  <div className="d-flex justify-content-center">
                    <div className="mt-3 w-60">
                      <small>Otras:</small>
                      <textarea
                        disabled={!props.formOnlineReducer.Enabled}
                        onChange={(e) => {
                          updateState({
                            key: 'overbiteDownOthers',
                            value: e.target.value,
                          })
                        }}
                        value={middle_state.overbiteDownOthers}
                        className="form-control mt-1"
                        name="observacion"
                        id="observacion"
                        cols="30"
                        rows="3"
                      ></textarea>
                      <TextAreaCounter
                        value={middle_state.overbiteDownOthers}
                        maxValue={2500}
                      />
                      {error6 && (
                        <div className="alert-danger alert-link p-2 mt-1">
                          {error6.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(OverjetOverBite)
