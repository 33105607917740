import React, { useEffect } from 'react'
import { ImageUploadContainer } from '../../../CargarTratamiento/components/styled'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import { useErrorList } from '../Utilities/UseErrorsList'
import PdfFile from '../../../../../src/assets/images/pdf-file.png'
import WordFile from '../../../../../src/assets/images/word-file.png'
import PhotoItem from '../../../../components/PhotoItem'
import { Delete } from '../../../../components/buttons'
import plan from '../../../../assets/images/plan.png'
import TextAreaCounter from '../Utilities/TextAreaCounter'

function GeneralInstructions(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'GeneralInstructions'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]

  const handleFileSelect = async (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      updateState({
        key: 'attachmentFile',
        value: [
          {
            name: file.name,
            extension: file.name.split('.').pop(),
            base64: reader.result,
            file: reader.result,
          },
          ...middle_state.attachmentFile,
        ],
      })
    }
  }

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order == 1)
  const error2 = errors_list.find((err) => err.order === 2)
  useErrorList(errors_list, updateErrors, [
    middle_state.instructions,
    middle_state.attachmentFile.name,
  ])
  useEffect(() => {
    if (!Array.isArray(middle_state.attachmentFile)) {
      updateState({
        key: 'attachmentFile',
        value: [middle_state.attachmentFile],
      })
    }
  }, [updateState, middle_state.attachmentFile])

  const removeAttachment = (index) => {
    const newArray = middle_state.attachmentFile.filter(
      (file, indexArray) => indexArray !== index
    )
    updateState({ key: 'attachmentFile', value: newArray })
  }
  return (
    <div>
      <div
        className="w-100 fw-bolder pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Instrucciones Generales
      </div>
      <div className="d-flex justify-content-center mt-2">
        <div className="col-12">
          <div className="mt-1 small">
            A continuación puede aclarar información que considere necesaria:
          </div>
          <textarea
            disabled={!props.formOnlineReducer.Enabled}
            onChange={(e) => {
              updateState({ key: 'instructions', value: e.target.value })
            }}
            value={middle_state.instructions}
            className="form-control mt-1"
            name="observacion"
            id="observacion"
            cols="30"
            rows="5"
          ></textarea>
          <TextAreaCounter
            value={middle_state.instructions}
            maxValue={2500}
            error={error2}
          />
          <div className="mt-2 small">
            Si desea, puede adjuntar una imagen o algún archivo que sea
            necesario:
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column align-items-center my-3">
              <ImageUploadContainer>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                  onChange={handleFileSelect}
                  id="frontal_reposo"
                  className="fotos"
                  disabled={!props.formOnlineReducer.Enabled}
                />
                {/* <Upload
                  style={
                    !props.formOnlineReducer.Enabled
                      ? { cursor: 'not-allowed' }
                      : {}
                  }
                /> */}
                <img src={plan} width="100px" />
              </ImageUploadContainer>
              <label
                className={`font-monospace small ms-1${
                  middle_state.attachmentFile.name ? ' btn-primary p-2' : ''
                }`}
              >
                {middle_state.attachmentFile.name || 'Seleccione un archivo...'}
              </label>
            </div>
            <div className="d-flex flex-wrap mb-2">
              {Array.isArray(middle_state.attachmentFile) &&
                middle_state.attachmentFile.map((file, key) => {
                  if (file.base64 !== '') {
                    let base64 =
                      file.extension === 'pdf'
                        ? PdfFile
                        : ['doc', 'docx'].indexOf(file.extension) > -1
                        ? WordFile
                        : file.base64
                    if (!base64) {
                      base64 = file
                    }
                    return (
                      <div
                        className="p-1 d-flex flex-grow-1 align-items-end "
                        key={key}
                      >
                        <div>
                          <PhotoItem
                            key={key}
                            ref={null}
                            image={base64}
                            group="generalInstructionsAttachmets"
                          />
                        </div>
                        {props.formOnlineReducer.Enabled && (
                          <div
                            className="col-1"
                            onClick={() => removeAttachment(key)}
                          >
                            <Delete />
                          </div>
                        )}
                      </div>
                    )
                  }
                  return null
                })}
            </div>
          </div>
          {props.formOnlineReducer.Enabled && (
            <div className="mt-4">
              <div className="d-flex justify-content-center small">
                Éste formulario constituye la prescripción final y completa para
                KeepSmiling. El diagnóstico y prescripción es desición
              </div>
              <div className="d-flex justify-content-center small">
                y única responsabilidad del doctor que ordena este apartado,
                quien se responsabiliza y renuncia sobre cualquier
              </div>
              <div className="d-flex justify-content-center small">
                reclamo contra KeepSmiling SA y sus empleados en caso de que con
                KeepSmiling no se consiga obtener el resultado
              </div>
              <div className="d-flex justify-content-center small">
                esperado, ya sea solo o en combinación con otros apartados.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInstructions)
