export const photosInput = [
  {
    label: 'Frontal Sonriendo',
    slug: 'frontal_sonriendo',
  },
  {
    label: '3/4 Perfil Sonriendo',
    slug: 'trescuartos_perfil',
  },
  {
    label: 'Frontal en Reposo',
    slug: 'frontal_reposo',
  },
  {
    label: 'Anterior en Oclusion',
    slug: 'anterior_oclusion',
  },
  {
    label: 'Anterior en Inoclusion',
    slug: 'anterior_inoclusion',
  },
  {
    label: 'Bucal Izquierdo',
    slug: 'bucal_izquierdo',
  },
  {
    label: 'Bucal Derecho',
    slug: 'bucal_derecho',
  },
  {
    label: 'Oclusal del Maxilar Superior',
    slug: 'oclusal_maxilar_sup',
  },
  {
    label: 'Oclusal del Maxilar Inferior',
    slug: 'oclusal_maxilar_inf',
  },
  {
    label: 'Perfil Izquierdo en Reposo',
    slug: 'perfil_izq_reposo',
  },
]
