import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import apiClient from '../../../utils/backend'

const useGetClaimHistory = (treatId, opened) => {
  const [claimState, setClaimState] = useState()
  useEffect(() => {
    const getClaims = async () => {
      if (!treatId || !opened) return

      try {
        const res = await apiClient(`api/historyClaims/${treatId}`)
        setClaimState(res.data.table.body)
      } catch (err) {
        console.log(err)
      }
    }

    getClaims()
  }, [opened, treatId])

  return {
    claimState,
  }
}

export default useGetClaimHistory

useGetClaimHistory.propTypes = {
  opened: PropTypes.bool,
  treatId: PropTypes.number.isRequired,
}
