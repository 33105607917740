import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../utils/backend'

const useDispatchControl = () => {
  const { countryId } = useSelector((state) => state.userReducer)
  const [state, setState] = useState({
    loading: true,
    treatments: [],
  })

  const paginatorHandler = async (page) => {
    // implement filter width country ID
    let url = `api/treatments?filter[status]=9&filter[statusNot]=12&page=${page}`
    if (state.searchQuery)
      url = url + `&filter[${state.currentFilterType}]=${state.searchQuery}`

    try {
      const res = await apiClient.get(url)
      setState({
        ...state,
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getInitialData = async (country) => {
    // implement filter width country
    try {
      let url = `api/treatments?filter[status]=9&filter[statusNot]=12&page=1`
      const res = await apiClient.get(url)
      setState({
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!state.loadTodosFilter) return
    getInitialData(countryId)
  }, [state, countryId])

  useEffect(() => {
    getInitialData(countryId)
  }, [countryId])
  return {
    state,
    setState,
    getInitialData,
    paginatorHandler,
    countryId,
  }
}

export default useDispatchControl
