import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../utils/backend'

export const usePatientIndex = () => {
  const user = useSelector((state) => state.userReducer)
  const [state, setState] = useState({
    loading: true,
  })

  const getUrl = (countryId, type = 1, page = 1) => {
    let url = `/api/patients/?page=${page}&include=treatments.dentists&filter[country_id]=${countryId}`
    if (type === 2) url = url + '&filter[user.groups.id]=1'
    if (type === 3) url = url + '&filter[user.groups.id]=3'
    return url
  }

  const handleChangePaginator = async (page) => {
    let url = getUrl(state.countryId, state.type, page)
    if (state.searchQuery) url = url + `&filter[fullname]=${state.searchQuery}`

    try {
      const res = await apiClient.get(url)
      setState((prevState) => ({
        ...prevState,
        patients: res.data.table.body,
        paginator: res.data.paginator,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const changeCountryHandler = async (countryId) => {
    try {
      const res = await apiClient.get(getUrl(countryId))
      setState({
        clearSearchBarInput: true,
        patients: res.data.table.body,
        paginator: res.data.paginator,
        countryId: countryId,
        type: 1,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const changeTypeHandler = async (newType) => {
    try {
      const res = await apiClient.get(getUrl(state.countryId, newType))
      setState((prevState) => ({
        clearSearchBarInput: true,
        patients: res.data.table.body,
        paginator: res.data.paginator,
        countryId: prevState.countryId,
        type: newType,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const setInitialData = async (userCountry) => {
    try {
      const res = await apiClient.get(
        `/api/patients/?include=treatments.dentists&filter[country_id]=${userCountry}`
      )
      setState({
        patients: res.data.table.body,
        paginator: res.data.paginator,
        type: 1,
        countryId: userCountry,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setInitialData(user.countryId)
  }, [user.countryId])

  // Reset index if searchbar was cleared
  useEffect(() => {
    if (state.loadTodosFilter) {
      apiClient
        .get(getUrl(state.countryId, state.type))
        .then((res) => {
          setState((prevState) => ({
            type: prevState.type,
            countryId: prevState.countryId,
            patients: res.data.table.body,
            paginator: res.data.paginator,
          }))
        })
        .catch((err) => console.log(err))
    }
  }, [state])
  return {
    state,
    setState,
    setInitialData,
    changeTypeHandler,
    changeCountryHandler,
    handleChangePaginator,
    getUrl,
  }
}
