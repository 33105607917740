import React from 'react'
import '../../../style.css'

const SequentialDistillation = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Distalización Secuencial</p>
      <hr className="mt-2 mb-0" />
      <div className="d-flex flex-column">
        <div className="improve-overjet-container">
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="secuencialDestilation"
              disabled
              checked={formData?.sequential_distalation == 1 && true}
            />
            <label className="ms-2">Aplica</label>
          </div>
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="secuencialDestilation"
              disabled
              checked={formData?.sequential_distalation == 2 && true}
            />
            <label className="ms-2">No aplica</label>
          </div>
          {formData?.sequential_distalation == 1 && (
            <>
              <p className="mt-3 mb-0">
                Indique la cantidad de mm que desea distalar:
              </p>
              <div className="d-flex justify-content-between mt-3">
                <div className="w-100">
                  <p className="mb-0">Superior</p>
                  <hr className="mt-1 me-4" />
                  <div>
                    <label className="ms-2">
                      Derecha{' '}
                      <input
                        type="number"
                        className="middleLineInputs"
                        value={
                          formData?.sequential_distalation_upright_mm
                            ? formData?.sequential_distalation_upright_mm
                            : 0
                        }
                        disabled
                      />{' '}
                      mm
                    </label>
                  </div>
                  <div>
                    <label className="ms-2">
                      Izquierda{' '}
                      <input
                        type="number"
                        className="middleLineInputs"
                        value={
                          formData?.sequential_distalation_upleft_mm
                            ? formData?.sequential_distalation_upleft_mm
                            : 0
                        }
                        disabled
                      />{' '}
                      mm
                    </label>
                  </div>
                </div>
                <div className="w-100">
                  <p className="mb-0">Inferior</p>
                  <hr className="mt-1" />
                  <div>
                    <label className="ms-2">
                      Derecha{' '}
                      <input
                        type="number"
                        className="middleLineInputs"
                        value={
                          formData?.sequential_distalation_downright_mm
                            ? formData?.sequential_distalation_downright_mm
                            : 0
                        }
                        disabled
                      />{' '}
                      mm
                    </label>
                  </div>
                  <div>
                    <label className="ms-2">
                      Izquierda{' '}
                      <input
                        type="number"
                        className="middleLineInputs"
                        value={
                          formData?.sequential_distalation_downleft_mm
                            ? formData?.sequential_distalation_downleft_mm
                            : 0
                        }
                        disabled
                      />{' '}
                      mm
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SequentialDistillation
