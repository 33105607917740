import React, {Fragment} from 'react';

function HeadersLabRepeat() {
    return (
        <Fragment>
            <div className="col-half d-flex flex-row justify-content-center"><p className="text-primary fw-bold">ID</p></div>
            <div className="col-2 d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Paciente</p></div>
            <div className="col-2 d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Doctor</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Fecha Pedido de Repetición</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Fecha Salida</p></div>
            <div className="col d-flex flex-row justify-content-start"><p className="text-primary fw-bold">Maxilar Superior</p></div>
            <div className="col d-flex flex-row justify-content-start"><p className="text-primary fw-bold">Maxilar Inferior</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Cant.</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Pedido</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Impresora</p></div>
            <div className="col d-flex flex-row justify-content-center"><p className="text-primary fw-bold">Impresión</p></div>
        </Fragment>
    )
}

export default HeadersLabRepeat