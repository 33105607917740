import React, { Component } from "react";
import { connect } from "react-redux";
import apiClient from "../../utils/backend";

// COMPONENTS
import HistoryCard from "./components/card";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loading: true,
    };
  }

  componentDidMount() {
    const path = window.location.pathname.split("/")[3];
    apiClient.get(`/api/patients/${path}`).then((res) => {
      // this.setState({ data: res.data, loading: false });
      console.log(res);
    });
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-5 position-relative">
          <div className="row pt-4 px-4">
            <div className="col-12">
              <h2 className="text-primary fw-bold">Historia Clinica</h2>
            </div>
          </div>
          <div className="row mt-4 px-4">
            <div
              className="col-12 shadow p-3 d-flex flex-row"
              style={{ borderRadius: "20px" }}
            >
              <div>
                <img
                  src="https://secure.gravatar.com/avatar/b7cbb245307bbb8b4c5499c99bdf42e6?s=250&d=mm&r=g"
                  alt=""
                  className="rounded-circle"
                  style={{
                    maxWidth: "100px",
                    width: "100%",
                    height: "100%",
                    marginRight: "10px",
                    marginLeft: "20px",
                    border: "1px solid #00155c",
                  }}
                />
              </div>
              <div className="d-flex flex-column py-3 px-3">
                <h4 className="fw-bold text-primary">
                  {this.state.data.full_name}
                </h4>
              </div>
            </div>
          </div>
          <div className="row mt-5 px-4 d-flex flex-row justify-content-left">
            {this.state.data.treatments.map((t) => (
              <HistoryCard
                title={t.order + "° Etapa"}
                date={t.updated_at}
                id={t.id}
                order={t.order}
                treatment={t}
              />
            ))}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Home);
