import React, { useState } from 'react'
import { Trash } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import styles from '../styles/directionsForm.module.css'
import Danger from '../../../../assets/images/danger_triangle.png'

const DeleteModal = ({ handleDeleteDirection, inputId, id = null }) => {
  const [opened, setOpened] = useState(false)
  return (
    <>
      <div className={styles.directionInputBTNS}>
        <Trash
          size={25}
          className={styles.BTN}
          onClick={() => setOpened(!opened)}
        />
      </div>
      <Modal
        style={{ marginTop: '150px' }}
        isOpen={opened}
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-12 flex text-center">
                <img
                  alt="danger"
                  src={Danger}
                  className="mt-1"
                  style={{ width: '80px' }}
                />
                <p className="my-4 text-black fs-5 fw-bold">
                  ¿Estas seguro que deseas eliminar la dirección ?
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => setOpened(!opened)}
              className="btn btn-outline-dark me-4 rounded-pill px-4"
            >
              Volver
            </button>
            <button
              onClick={() => {
                handleDeleteDirection(inputId, id)
                setOpened(!opened)
              }}
              className="btn btn-outline-warning rounded-pill px-4"
            >
              Eliminar
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DeleteModal
