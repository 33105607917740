import React from 'react'
import { useSelector } from 'react-redux'
import { NotificationsDesktop } from './NotificationsDesktop'
import { NotificationsMobile } from './NotificationsMobile'

export const Notifications = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  if (pageWidth === 'desktop') return <NotificationsDesktop />
  else return <NotificationsMobile />
}
