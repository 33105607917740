import React, { useState } from 'react'
import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import claimModal from '../styles/claimModal.module.css'
import bell from '../../../assets/icons/feather-bell.svg'
import mail from '../../../assets/icons/feather-mail.svg'
import whatsapp from '../../../assets/icons/feather-whatsapp.svg'
import AprobacionPropuestaCard from '../components/AprobacionPropuestaCard'
import DocumentacionIncompletaCard from '../components/DocumentacionIncompletaCard'
import ClaimModalHistory from '../components/ClaimModalHistory'
import useGetClaimHistory from '../hooks/useGetClaimHistory'
import { createClaim } from '../utilitis/createClaim.js'
import handleToast from '../../../components/toaster'

const ClaimModal = ({
  dentists,
  patient,
  type,
  treatId,
  flags,
  date,
  updateCardDate,
  updateAfterClaimAll,
  canClaimed,
  principalDentistId,
}) => {
  const [opened, setOpened] = useState(false)
  const { claimState } = useGetClaimHistory(treatId, opened)
  const [sendDataType, setSendDataTypes] = useState({
    bell: true,
    email: false,
    whatsapp: false,
  })
  let principalDentist = dentists.find(
    (dentist) => dentist.id === principalDentistId
  )
  const handleSendClaim = async () => {
    if (type === '-') return handleToast('error', 'Error creando el reclamo')
    if (!sendDataType.bell && !sendDataType.email && !sendDataType.whatsapp)
      return handleToast('error', 'Seleccione un medio de reclamo primero')
    updateCardDate()
    //Normalize claim Data
    let sendClaim = {
      claims: [
        {
          required_action_claimed:
            type === 'Completar documentación'
              ? 'Documentación incompleta'
              : 'Propuesta de tratamiento pendiente',
          treatment_id: treatId,
          whatsapp: sendDataType.whatsapp,
          email: sendDataType.email,
          notification: sendDataType.bell,
          principal_dentist: principalDentist.id,
        },
      ],
    }

    const res = await createClaim({ ...sendClaim })
    if (res.data === 'Claims created successfully') {
      handleToast('success', 'Reclamo cargado exitosamente')
      updateAfterClaimAll()
      setTimeout(() => {
        setOpened(false)
      }, 1000)
    } else {
      handleToast('error', 'Error creando el reclamo')
    }
  }

  return (
    <>
      {canClaimed && (
        <button
          className={claimModal.claimBTN}
          onClick={() => setOpened(!opened)}
        >
          Reclamar
        </button>
      )}
      {!canClaimed && (
        <button className={claimModal.claimBTNHidden} disabled>
          Reclamar
        </button>
      )}

      <Modal
        size="lg"
        style={{ marginTop: '50px', maxWidth: '600px' }}
        isOpen={opened}
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          <div className="p-3">
            <div className="d-flex justify-content-between align-items-end">
              <p className={`${claimModal.title} fs-4`}>Reclamo</p>
              <X
                onClick={() => setOpened(!opened)}
                className={claimModal.closeBTN}
              />
            </div>
            <div className="d-flex my-4 justify-content-between">
              <div className="d-flex flex-column ">
                <div>
                  <p className={claimModal.infoTitle}>Paciente:</p>
                  <p className={claimModal.infoText}>{patient}</p>
                </div>
                <div>
                  <p className={claimModal.infoTitle}>Doctor:</p>
                  <p className={claimModal.infoText}>
                    {principalDentist?.user.name}{' '}
                    {principalDentist?.user.surname}
                  </p>
                </div>
              </div>
              <div style={{ width: 220 }}>
                <p className="fs-7">Medio de Reclamo:</p>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column align-items-center cursor-pointer">
                    <img
                      className={`my-2 ${
                        sendDataType.bell ? null : 'disabled'
                      }`}
                      alt="bell-icon"
                      src={bell}
                      height={30}
                      onClick={() => {
                        setSendDataTypes((prev) => ({
                          ...prev,
                          bell: !prev.bell,
                        }))
                      }}
                    />
                    <p className={`my-0 ${claimModal.infoTitle}`}>
                      Notificación
                    </p>
                  </div>

                  <div className="d-flex flex-column align-items-center cursor-pointer">
                    <img
                      className={`my-2 ${
                        sendDataType.email ? null : 'disabled'
                      }`}
                      alt="bell-icon"
                      height={30}
                      src={mail}
                      onClick={() => {
                        setSendDataTypes((prev) => ({
                          ...prev,
                          email: !prev.email,
                        }))
                      }}
                    />
                    <p className={`my-0 ${claimModal.infoTitle}`}>
                      Enviar Mail
                    </p>
                  </div>

                  <div className="d-flex flex-column align-items-center cursor-pointer">
                    <img
                      className={`my-2 ${
                        sendDataType.whatsapp ? null : 'disabled'
                      }`}
                      alt="bell-icon"
                      height={30}
                      src={whatsapp}
                      onClick={() => {
                        setSendDataTypes((prev) => ({
                          ...prev,
                          whatsapp: !prev.whatsapp,
                        }))
                      }}
                    />
                    <p className={`my-0 ${claimModal.infoTitle}`}>WhatsApp</p>
                  </div>
                </div>
              </div>
            </div>

            {type === 'Completar documentación' && (
              <DocumentacionIncompletaCard flags={flags} />
            )}
            {type === 'Aprobación de tratamiento' && (
              <AprobacionPropuestaCard date={date} />
            )}

            <ClaimModalHistory claimState={claimState} />
            <div className="d-flex justify-content-end mt-4 mb-2">
              <button
                className={claimModal.claimModalBTN}
                onClick={() => handleSendClaim()}
              >
                Reclamar
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ClaimModal
