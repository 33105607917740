import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import apiClient from '../../../utils/backend';
import {getPatient} from '../../../redux/actions/patients';


// COMPONENTS
import {TreatmentCard} from '../components/card'

export const PatientTreatment = () => {
    
    const { id } = useSelector(state => state.userReducer)
    useEffect(() => {
        dispatch( getPatient(id) );
        setLoading(false);
    }, [])
    
    const { treatment,user } = useSelector(state => state.patientReducer)
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    console.log(treatment,user)



    const handleCardClick = (treatmentId) => {
        history.push(`/mi-tratamiento/${treatmentId}`)  
    }

    return (
        <>
        {
            loading ? null : 
                
            (   <div className="container-fluid px-5 flex position-relative">
                    <div className="row pt-4 px-4">
                    <div className="col-12"><h2  className="text-primary fw-bold">Mi tratamiento</h2></div>
                    </div>
                    <div className="row mt-4 px-4">
                        <div className="col-12 shadow p-3 d-flex flex-row" style={{borderRadius: '20px'}}>
                            <div>
                                <img src="https://secure.gravatar.com/avatar/b7cbb245307bbb8b4c5499c99bdf42e6?s=250&d=mm&r=g" alt="" className="rounded-circle" style={{maxWidth: '100px', width: '100%', height: '100%', marginRight: '10px', marginLeft: '20px', border: '1px solid #00155c'}} />
                            </div>
                            <div className="d-flex flex-column py-3 px-3">
                                <h4 className="fw-bold text-primary">{user?.full_name}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 px-4 d-flex flex-row justify-content-left">
                        {treatment?.map(t => <TreatmentCard key={t.id} title={t.order + '° Etapa'} date={t.updated_at} order={t.order} id={t.id}  handleCardClick={handleCardClick} />)}
                    </div>
                </div>
            )
        }
        </>
    )
}
