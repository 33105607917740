import React from 'react'
import PropTypes from 'prop-types'
import saologo from '../../../../assets/images/sao_logo.png'
import sglogo from '../../../../assets/images/study_group_logo.png'
import { useHistory } from 'react-router-dom'
import card from '../styles/card.module.css'
import styles from '../styles/styles.module.css'
import buttons from '../styles/buttons.module.css'

const PatientCard = ({ patientData, treatment }) => {
  const history = useHistory()

  const flags = {
    studies: treatment.estudios,
    photos: treatment.fotos,
    plan: treatment.plan,
  }

  const handleSetRequiredData = (pendings, flags) => {
    const { studies, photos, plan } = flags
    if (pendings.length > 0) {
      return `Aprobación de tratamiento`
    } else if (!studies || !photos || !plan) {
      return `Completar documentación`
    }
  }

  return (
    <div className={card.cardComponent}>
      <div
        className={`${buttons[`claimHistoryType-${treatment.type.id}`]}`}
        onClick={() => history.push(`/od/historia-clinica/${treatment.id}`)}
      ></div>
      <div
        className={`${styles.patient} d-flex`}
        onClick={() => history.push(`/od/historia-clinica/${treatment.id}`)}
      >
        <div className={card.avatar}>
          <img
            alt="avatar"
            src={treatment.frontal_sonriendo}
            className={card.avatarIMG}
          />
        </div>
        <p
          className={`${card.patient} m-0 text-truncate`}
          title={`${patientData.name} ${patientData.surname}`}
        >
          {patientData.name} {patientData.surname}
        </p>
        {patientData.groups.length !== 0 &&
          patientData.groups.map((group) => (
            <div className="d-flex justify-content-end w-25">
              <img
                className={card.logo}
                src={group.id === 1 ? saologo : sglogo}
                alt={group.name}
              />
            </div>
          ))}
      </div>
      <div
        className={styles.treatment}
        onClick={() => history.push(`/od/historia-clinica/${treatment.id}`)}
      >
        {treatment.type.name}
      </div>
      <div
        className={styles.stage}
        onClick={() => history.push(`/od/historia-clinica/${treatment.id}`)}
      >
        {treatment.order}
      </div>
      <div
        className={card.requiredAction}
        onClick={() => history.push(`/od/historia-clinica/${treatment.id}`)}
      >
        <p className="m-0">
          {handleSetRequiredData(treatment.missing_documentation, flags)}
        </p>
      </div>
      <div className={card.buttons}>
        <div
          className={card.actionBTN}
          onClick={() => history.push(`/od/historia-clinica/${treatment.id}`)}
        >
          Ver
        </div>
      </div>
    </div>
  )
}

export default PatientCard

PatientCard.propTypes = {
  patientData: PropTypes.object.isRequired,
  treatment: PropTypes.object.isRequired,
}
