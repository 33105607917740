import styled from "styled-components";

const ArrowButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #011457;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ButtonPrincipal = styled.button`
  border: none;
  background-color: #0027a8;
  color: #fff;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #0037ed;
  }
`;

const ButtonSecondary = styled.button`
  border: none;
  background-color: #3a55b0;
  color: #fff;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #335de8;
  }
`;
const ButtonSecondaryOverlay = styled.button`
  border: 1px solid #3a55b0;
  background-color: transparent;
  color: #3a55b0;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;
`;

const ButtonDelete = styled.button`
  border: none;
  background-color: #e74c3c;
  color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #fc5151;
  }

  & > svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
`;

const ButtonUpdate = styled.button`
  border: none;
  background-color: #2ecc71;
  color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #3ade7f;
  }

  & > svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
`;

const ButtonEdit = styled.button`
  border: none;
  background-color: #3061ff;
  color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #4571ff;
  }

  & > svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
`;

const ButtonCustom = styled.button`
  border: none;
  background-color: ${(props) => (props.color.bg ? props.color.bg : "")};
  color: ${(props) => (props.color.color ? props.color.color : "")};
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 12, 247, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #4571ff;
  }

  & > svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
`;

const ButtonReload = styled.button`
  border: none;
  background-color: #363636;
  color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #4f4f4f;
  }

  & > svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
`;

export {
  ArrowButton,
  ButtonPrincipal,
  ButtonSecondary,
  ButtonDelete,
  ButtonUpdate,
  ButtonEdit,
  ButtonSecondaryOverlay,
  ButtonCustom,
  ButtonReload
};
