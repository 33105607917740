import React, { useState } from 'react'
// COMPONENTS
import ClinicHistoryModal from '../../components/MedicalRecordModal/ClinicHistoryModal'
import { Card } from './components/card'
import Searchbar from '../../components/searchbar'
import Paginator from '../../components/Paginator'
import Loader from '../../components/loader'
import TypeFilterComponent from './components/TypeFilterComponent'
import CountryFilterComponent from '../../components/CountryFilters/CountryFilterComponent'
import CardNamesComponent from './components/CardNamesComponent'
import { useTreatmentIndex } from './hooks/useTreatmentIndex'
import NotFoundComponent from '../../components/NotFound.js/NotFoundComponent'

export const TreatmentIndex = () => {
  const [modalState, setModalState] = useState({
    modal_show: false,
    modal_id: null,
  })

  const {
    state,
    setState,
    setInitialData,
    changeTypeHandler,
    changeCountryHandler,
    getPaginatorData,
    getUrl,
  } = useTreatmentIndex()

  const handleModal = (id) => {
    if (id) return setModalState({ modal_show: true, modal_id: id })
    else
      return setModalState({
        modal_id: null,
        modal_show: false,
      })
  }

  if (state.loading) return <Loader />

  return (
    <div className="container-fluid px-3 mt-2">
      <ClinicHistoryModal
        id={modalState.modal_id}
        opened={modalState.modal_show}
        handleModal={handleModal}
        isTreatment={true}
      />
      <div className="row mt-4">
        <div className="col-9">
          <h2 className="text-primary fw-bold">Tratamientos</h2>
        </div>
        <div className="col-3 d-flex flex-column px-3">
          <Searchbar
            initialQueryType="treatment"
            state={state}
            setState={setState}
            page={'treatment'}
            url={`${getUrl(state.countryId, state.type)}&`}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 flex-wrap">
        <TypeFilterComponent
          state={state}
          changeTypeHandler={changeTypeHandler}
        />
        <CountryFilterComponent
          state={state.countryId}
          changeCountryHandler={changeCountryHandler}
        />
      </div>
      <hr />
      <CardNamesComponent />
      {state.treatments.length > 0 ? (
        state.treatments.map((item, i) => (
          <Card
            key={i}
            data={item}
            handleModal={handleModal}
            setInitialData={setInitialData}
          />
        ))
      ) : (
        <NotFoundComponent />
      )}
      <Paginator paginator={state.paginator} getPaginator={getPaginatorData} />
    </div>
  )
}
