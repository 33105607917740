import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import './styles.css'
import { useHistory } from 'react-router-dom'
import { AlertCircle } from 'react-feather'

const StagesCard = ({ data, handleSetActiveCard }) => {
  const [classCard, setClassCard] = useState(false)
  const history = useHistory()

  const handleActiveCard = () => {
    setClassCard(true)
  }

  const handleInactiveCard = () => {
    setClassCard(false)
  }
  const handleRedirect = () => {
    history.push(`/od/historia-clinica/${data.id} `)
  }

  return (
    <div
      onMouseOver={handleActiveCard}
      onMouseLeave={handleInactiveCard}
      className={`m-2 p-4 position-relative ${
        classCard ? 'cardContainerActive' : 'cardContainerInactive'
      }`}
      onClick={() => handleRedirect()}
    >
      <div className="d-flex flex-column justify-content-between w-100">
        <div className="d-flex flex-column justify-start">
          <span className="fs-4 fw-bold">{data.order}° Etapa</span>
          <span className={`fs-7 mt-1 ${!data?.state ? 'dateOpacity' : ''}`}>
            {moment.utc(data.registration_datetime).format('DD-MM-YYYY')}
          </span>
        </div>
        <div className="d-flex mt-2 flex-column justify-start">
          <div className="">
            {data.type_id === 1 && (
              <div className="treatmentBTN1 cardTreatment py-2 px-3">Full </div>
            )}
            {data.type_id === 2 && (
              <div className="treatmentBTN2 cardTreatment py-2 px-3">Fast </div>
            )}
            {data.type_id === 3 && (
              <div className="treatmentBTN3 cardTreatment py-2 px-3">
                Medium
              </div>
            )}
            {data.type_id === 4 && (
              <div className="treatmentBTN4 cardTreatment py-2 px-3">
                Full kids
              </div>
            )}
            {data.type_id === 5 && (
              <div className="treatmentBTN5 cardTreatment py-2 px-3">
                Kids Fast
              </div>
            )}
            {data.type_id === 6 && (
              <div className="treatmentBTN6 cardTreatment py-2 px-3">Teens</div>
            )}
          </div>
        </div>
        <div className="fw-bold">
          {data.pendings.length > 0 && (
            <div className="pendings-container text-danger">
              {data.pendings.map((item, i) => (
                <div className="d-flex align-items-center">
                  <AlertCircle />
                  <small className="my-1 ms-1 fs-7" key={i}>
                    {item}
                  </small>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-end w-50 fw-bold stageNumber">
        {data.order}
      </div>
    </div>
  )
}

StagesCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StagesCard
