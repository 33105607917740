import React from 'react'
import '../../../style.css'

const MolarRelation = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Relación Molar</p>
      <hr className="mt-2" />
      <div className="improve-overjet-container">
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <p className="mb-0">Relación molar derecha</p>
            <hr className="mt-1 me-3" />
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="rightMolar"
                disabled
                checked={formData?.molar_relationship_right == 1 && true}
              />
              <label className="ms-2">Mantener</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="rightMolar"
                disabled
                checked={formData?.molar_relationship_right == 2 && true}
              />
              <label className="ms-2">Mejorar</label>
              {formData?.molar_relationship_right == 2 && (
                <>
                  <p className="mt-3">Otras:</p>
                  <div className="step-text-area-overjet overflow-scroll">
                    {formData?.molar_relationship_right_description}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-50">
            <p className="mb-0">Relación molar izquierda</p>
            <hr className="mt-1" />
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="leftMolar"
                disabled
                checked={formData?.molar_relationship_left == 1 && true}
              />
              <label className="ms-2">Mantener</label>
            </div>
            <div>
              <input
                type="radio"
                className="mt-2 inputColor"
                name="leftMolar"
                disabled
                checked={formData?.molar_relationship_left == 2 && true}
              />
              <label className="ms-2">Mejorar</label>
              {formData?.molar_relationship_left == 2 && (
                <>
                  <p className="mt-3">Otras:</p>
                  <div className="step-text-area-overjet overflow-scroll">
                    {formData?.molar_relationship_left_description}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MolarRelation
