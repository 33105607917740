import moment from 'moment'
import React, { useState } from 'react'
import { MoreVertical } from 'react-feather'
import ConfirmationModal from '../confirmation_modal'
import AestheticPriority from './GeneralSteps/AestheticPriority/AestheticPriority'
import PreviousDiagnosis from './GeneralSteps/PreviousDiagnosis/PreviousDiagnosis'
import TreatmentIndications from './GeneralSteps/TreatmentIndications/TreatmentIndications'
import './style.css'

const FormOnline = ({ patientData, formData, documentationData }) => {
  const [dropDown, setdropDown] = useState(false)
  console.log(patientData)
  const handleDeleteFormOnline = () => {
    console.log('Eliminando formulario online')
  }

  const handleColor = (type) => {
    const types = {
      Full: '#001b70',
      Fast: '#288b7e',
      Medium: '#5a468e',
      Kids: '#e47662',
      'Kids Fast': '#e8793a',
      Teens: '#f7c05b',
      'Sin tipo': '#001b70',
    }
    console.log(types[type])
    return types[type]
  }
  return (
    <div className="form-online-container pb-4">
      <div className="d-flex justify-content-between w-100 ps-4 pe-2 py-4">
        <p className="fs-4 fw-bold">Formulario Online</p>
        <div className="dropdown-father">
          <MoreVertical
            color="#10456d"
            size={24}
            className="moreVertical"
            onClick={() => {
              setdropDown(!dropDown)
            }}
          />
          {dropDown && (
            <ConfirmationModal type="formOnline-delete" data={formData} />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-self-start header-patient-data px-4">
        <div>
          <p>Paciente:</p>
          <p className="fw-bold fs-5">{`${patientData?.patient.name} ${patientData?.patient.surname}`}</p>
        </div>
        <div>
          <p>Tratamiento:</p>
          <p
            className="fs-5"
            style={{ color: handleColor(patientData?.type?.name) }}
          >
            KeepSmiling{' '}
            <span className="fw-bold">{patientData?.type.name}</span>
          </p>
        </div>
        <div>
          <p>Maxilar:</p>
          <p className="fs-5">{patientData?.maxilla}</p>
        </div>
        <div>
          <p>Fecha de nacimiento:</p>
          <p>
            <p className="fs-5">
              {patientData?.patient.birthdate === null
                ? 'No tiene fecha de nacimiento'
                : moment
                    .utc(patientData?.patient?.birthdate)
                    .format('DD-MM-YYYY')}
            </p>
          </p>
        </div>
      </div>
      <hr className="form-online-hr mx-4" />
      <AestheticPriority formData={formData} />
      <PreviousDiagnosis formData={formData} />
      <TreatmentIndications
        formData={formData}
        documentationData={documentationData}
      />
    </div>
  )
}

export default FormOnline
