import React from 'react'

function Card({data, setCategoria, setPaciente, next}) {
    return (
        <div style={{height: 250, width: 250, border: "1px solid #ccc", borderRadius: 20}} className="d-flex flex-column align-items-center mx-3">
            {/* <img className="h-25 w-100" style={{borderBottom: "1px solid #ccc"}} /> */}
            <hr className="mt-5" />
            <h4 className="fw-bold mt-0 text-primary text-center">{data.title}</h4>
            <p className="px-5 text-center mt-2" style={{fontSize: 14}}>{data.description}</p>
            <button className="btn btn-secondary px-4 rounded-pill mt-auto mb-3" onClick={() => {setCategoria(data.title); setPaciente(null); next()}}>Enviar una consulta</button>
        </div>
    )
}

export default Card