import React, {Fragment, useEffect, useState} from "react";
import apiClient from "../../../utils/backend";

function SubCategories(props) {

    const [subCategories, setSubCategories] = useState([])
    const [newSubCategorie, setNewSubCategorie] = useState(null)
    const [showNewSubCategorie, setShowNewSubCategorie] = useState(false)

    useEffect(() => {
        console.log("aca")
        apiClient.get(`/api/helpCenter/${props.categorie.id}`).then(res => {
            setSubCategories(res.data)
        })
    }, [])

    const guardarSubCategoria = () => {
        apiClient.post('/api/helpCenter', {
            parent_id: props.categorie.id,
            name: newSubCategorie,
            route: `${props.categorie.name}/${newSubCategorie}`,
            order: 1
        }).then(res => {
            setSubCategories(oldSubCategories => [...oldSubCategories, res.data]);
        })
    }

    return (
        <Fragment>
            <p className="mt-3" style={{fontSize: 15}}>Categoria</p>

            <div className="row">
                <div className="col-2" style={{marginRight: 100}}>
                    <div style={{width: 250, height: 220, borderRadius: 20}}
                         className="col-2 d-flex flex-column shadow mx-2 p-4 justify-content-center align-items-center">
                        <img style={{width: 100, height: 100}} src={props.categorie.icon} alt=""/>
                        <p className="mt-3" style={{fontSize: 22, fontWeight: 'bold'}}>{props.categorie.name}</p>
                    </div>
                </div>

                {
                    subCategories.map(subcategorie => {
                        return (
                            <div onClick={() => props.setSubCategorie(subcategorie)} className="col-2 d-flex flex-column" style={{cursor: 'pointer'}}>
                                <div style={{width:200, height: 70}} className="shadow rounded-pill d-flex justify-content-start align-items-center">
                                    <p className="mx-3 mt-2">{subcategorie.name}</p>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="col-2 d-flex flex-column">
                    <div onClick={() => setShowNewSubCategorie(true)} style={{width:200, height: 70, cursor: 'pointer'}} className="shadow rounded-pill d-flex justify-content-start align-items-center">
                        <p className="mx-3 mt-2">Nueva Sub-Categoria</p>
                    </div>
                </div>
            </div>

            {
                showNewSubCategorie &&
                <div className="row mt-5">
                    <p style={{fontSize: 20, fontWeight: 'bold'}}>Nueva Sub-Categoría</p>

                    <div className="col-6 d-flex flex-row">
                        <input onChange={(e) => setNewSubCategorie(e.target.value)} className="form-control" type="text"/>
                        <button onClick={() => guardarSubCategoria()} className="btn btn-primary px-5 rounded-pill">Guardar</button>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default SubCategories