import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import '../styles/index.css'

const ClinicHistoryPagination = ({ data, setCurrentModalId }) => {
  const handleChangePage = (id) => {
    setCurrentModalId(id)
  }

  return (
    <>
      <div
        className={`d-flex justify-content-${
          data.prev_stage_id === null && data?.next_stage_id !== null
            ? 'end'
            : 'between'
        } px-3 mt-5`}
      >
        {data?.prev_stage_id !== null && (
          <div
            className="text-primary paginationBTN d-flex align-content-center"
            onClick={() => handleChangePage(data?.prev_stage_id)}
          >
            <ChevronLeft />
            <p className="ms-2 fs-5 my-0 paginationBTNText">Etapa anterior</p>
          </div>
        )}
        {data?.next_stage_id !== null && (
          <div
            className="text-primary paginationBTN d-flex align-content-center"
            onClick={() => handleChangePage(data?.next_stage_id)}
          >
            <p className="me-2 fs-5 my-0 paginationBTNText">Etapa siguiente</p>
            <ChevronRight />
          </div>
        )}
      </div>
    </>
  )
}

export default ClinicHistoryPagination
