import React from 'react'
import OdCard from './components/od_card'
import Searchbar from '../../components/searchbar'
import OdDesactivatedCard from './components/odDesactivated_card'
import Paginator from '../../components/Paginator'
import Loader from '../../components/loader'
import TypeFilterComponent from './components/TypeFilterComponent'
import CardDecriptionComponent from './components/CardDecriptionComponent'
import { useHistory } from 'react-router-dom'
import { useDentistIndex } from './hooks/useDentistIndex'
import CountryFilterComponent from '../../components/CountryFilters/CountryFilterComponent'
import NotFoundComponent from '../../components/NotFound.js/NotFoundComponent'

export default function Index() {
  const history = useHistory()
  const {
    state,
    setState,
    typeFilterHandler,
    countryFilterHandler,
    handleOnChangePaginator,
    getUrl,
  } = useDentistIndex()

  const displayDentistList = () => {
    if (!state.dentistArray.length) return
    if (state.type === 2)
      return state.dentistArray.map((item, i) => (
        <div key={i} className={` shadow border py-2 my-2`}>
          <OdDesactivatedCard data={item} state={state} setState={setState} />
        </div>
      ))
    return state.dentistArray.map((item, i) => (
      <div key={i} className={`shadow border py-2 my-2`}>
        <OdCard data={item} state={state} setState={setState} />
      </div>
    ))
  }

  if (state.loading) return <Loader />

  return (
    <div className="container mt-4">
      <div className="row">
        <h1
          style={{ fontWeight: 700 }}
          className="text-primary col-9 d-flex justify-content-between mb-3"
        >
          Odontólogos
        </h1>
        <div className="col-3 d-flex flex-column px-3">
          <button
            className="btn btn-primary px-4 rounded-pill mt-2 mb-1"
            onClick={() => history.push('/odontologos/crear')}
          >
            Nuevo odontólogo
          </button>
          <Searchbar
            url={`${getUrl(state.type, state.countryId)}&`}
            state={state}
            setState={setState}
            page={'dentist'}
          />
        </div>
      </div>
      <div className="NT-bottom-bar mt-2">
        <CountryFilterComponent
          state={state.countryId}
          changeCountryHandler={countryFilterHandler}
        />
      </div>
      <TypeFilterComponent
        state={state}
        typeFilterHandler={typeFilterHandler}
      />
      <CardDecriptionComponent state={state} />
      <div className="my-3 col-12">
        {displayDentistList()}
        {!state.dentistArray.length && <NotFoundComponent />}
      </div>
      <Paginator
        paginator={state.paginator}
        getPaginator={handleOnChangePaginator}
      />
    </div>
  )
}
