import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'reactstrap'
import FirstLogin from './modals/FirstLogin/FirstLogin'
import Reminder from './modals/Reminder/Reminder'
import SatisfactionSurvey from './modals/SatisfactionSurvey/SatisfactionSurvey'

const CustomModal = ({ onboardingData }) => {
  const dispatch = useDispatch()
  const { openState } = useSelector((state) => state.loginModalReducer)
  const [modal, setModal] = useState(onboardingData)

  // only used on development proccess
  useEffect(() => {
    dispatch({ type: 'LOGIN_MODAL', payload: true })
  }, [dispatch])

  useEffect(() => {
    if (modal.complete_offices && !modal.first_login)
      return dispatch({ type: 'LOGIN_MODAL', payload: false })
  }, [modal])

  return (
    <Modal
      isOpen={openState}
      size="lg"
      toggle={() => dispatch({ type: 'LOGIN_MODAL', payload: false })}
    >
      {/* <SatisfactionSurvey /> */}

      {!modal?.complete_offices && !modal?.first_login && (
        <Reminder setModal={setModal} modal={modal} />
      )}
      {modal?.first_login && <FirstLogin setModal={setModal} modal={modal} />}
    </Modal>
  )
}

export default CustomModal
