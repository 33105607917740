import React, { useEffect, useState } from 'react'
import './styles.css'
import CHAlert from '../../../../assets/images/CH-alert.png'
import CHCheck from '../../../../assets/images/CH-check.png'
import CHEmpty from '../../../../assets/images/CH-empty.png'
import CHPoints from '../../../../assets/images/CH-points.png'
import { useSelector } from 'react-redux'

const InformationProgressBar = ({ treatmentStatus, paymentStatus, data }) => {
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)
  const isChilean = countryId === 5
  const [step, setStep] = useState()
  const handleTreatmentState = (state) => {
    const img = {
      pending: CHEmpty,
      in_process: CHPoints,
      pending_doctor: CHAlert,
      completed: CHCheck,
    }
    return img[state]
  }

  const widths = {
    1: '0%',
    2: '25%',
    3: '50%',
    4: '75%',
    5: '90%',
    6: '100%',
  }

  useEffect(() => {
    if (
      treatmentStatus?.patient &&
      treatmentStatus?.documentation &&
      treatmentStatus?.plan &&
      treatmentStatus?.manufacturing === 'pending'
    ) {
      return setStep(1)
    }
    if (treatmentStatus?.patient === 'completed') {
      setStep(2)
    }
    if (treatmentStatus?.documentation === 'completed') {
      setStep(3)
    }
    if (treatmentStatus?.plan === 'completed') {
      setStep(3)
    }
    if (treatmentStatus?.manufacturing === 'completed') {
      setStep(4)
    }
    if (treatmentStatus?.dispatched === 'completed') {
      setStep(5)
    }
  }, [treatmentStatus])

  return (
    <div className="treatmentStateBox p-2">
      <p className="text-center fw-bold">Estado de tratamiento:</p>
      <div
        className="my-5 ms-2 ul-progress-bar"
        style={{ position: 'relative', maxWidth: '70vw' }}
        id="timeline"
      >
        <ul
          style={{
            background: '#ECEEF1',
            width: '90%',
            height: '4px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            listStyleType: 'none',
            position: 'relative',
          }}
        >
          {/* marco gris del stepbar */}
          <li
            style={{
              width: '110%',
              height: '100%',
              position: 'absolute',
              padding: '23px',
              top: '-30px',
              borderRadius: '30px',
              left: '-3%',
              right: '0',
            }}
          ></li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '-2px',
            }}
          >
            <img
              src={handleTreatmentState(treatmentStatus?.patient)}
              className="barItem"
              alt="CHCheck"
            />
            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              {isChilean ? (
                <p>
                  Ingreso <br /> del paciente
                </p>
              ) : (
                <p>
                  Alta de <br /> paciente
                </p>
              )}
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '35px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '25%',
            }}
          >
            <img
              src={handleTreatmentState(treatmentStatus?.documentation)}
              className="barItem"
              alt="CHCheck"
            />

            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              Documentación completa
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '50%',
            }}
          >
            <img
              src={handleTreatmentState(treatmentStatus?.plan)}
              className="barItem"
              alt="CHCheck"
            />

            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              Propuesta de tratamiento
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '75%',
            }}
          >
            <img
              src={handleTreatmentState(treatmentStatus?.manufacturing)}
              className="barItem"
              alt="CHCheck"
            />

            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              En producción de alineadores
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '100%',
            }}
          >
            <img
              src={handleTreatmentState(treatmentStatus?.dispatched)}
              className="barItem"
              alt="CHCheck"
            />
            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              Entrega de <br /> Tratamiento
            </span>
          </li>
        </ul>
        <ul
          style={{
            background: '#2ecc71',
            height: '6px',
            width: widths[step],
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            listStyleType: 'none',
            position: 'absolute',
            top: '10px',
            zIndex: 9,
          }}
        ></ul>
      </div>
    </div>
  )
}

export default InformationProgressBar
