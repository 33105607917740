import React from 'react'
import { TeethSelector } from '../../../Utilities/TeethSelector'
import '../../../style.css'

const Movements = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Movimientos</p>
      <hr className="mt-2" />
      <div className="d-flex flex-column">
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="movement"
            checked={formData?.movement == 1 && true}
            disabled
          />
          <label className="ms-2">Se pueden mover todos los dientes</label>
        </div>
        <div>
          <input
            type="radio"
            className={`mt-2 ${formData.movement != 2 ? 'mb-4' : ''} inputColor`}
            name="movement"
            checked={formData.movement == 2 && true}
            disabled
          />
          <label className="ms-2">NO se pueden mover todos los dientes</label>
        </div>
      </div>
      {formData?.movement == 2 && (
        <TeethSelector formData={formData} type="movements" />
      )}
      <p>
        Si desea indicar el movimiento individualizado para cada pieza, puede
        detallar a continuación:
      </p>
      <div className="step-movements-text-area">
        {formData?.movement_description}
      </div>
    </div>
  )
}

export default Movements
