import { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'
import { baseToFile } from '../../../utils/baseToFile'
import handleToast from '../../toaster'

const useDocumentationAdmin = ({
  documentationData,
  isProposalControl,
  treatmentId,
  loadDataAdmin,
}) => {
  const [filter, setFilter] = useState('photos')
  const [inputState, setInputState] = useState(null)
  const [loading, setLoading] = useState(false)
  const [backendFiles, setBackendFiles] = useState({
    photos: [],
    otherPhotos: [],
    studies: [],
    otherStudies: [],
    plan: [],
  })

  const reCategorize = async ({ imageId, src }) => {
    console.log(imageId, src)
    setLoading(true)
    let url = `api/images/${imageId}`
    let dataImage = new FormData()
    dataImage.append('name', src)
    dataImage.append('_method', 'PUT')

    try {
      await apiClient.post(url, dataImage)
      await loadDataAdmin()
      setLoading(false)
    } catch (error) {
      handleToast('error', 'Error re categorizando la imagen.')
      console.log(error)
    }
  }

  const onDeleteImage = async (imageId) => {
    setLoading(true)
    try {
      await apiClient.delete(`api/images/${imageId}`)
      await loadDataAdmin()
      setLoading(false)
    } catch (error) {
      handleToast('error', 'Error eliminando la imagen.')
      console.log(error)
    }
  }

  const handleEditPhotos = async ({ imageBase64, src, imageId }) => {
    setLoading(true)
    const url = `api/images/${imageId}`
    const imageFile = baseToFile(imageBase64, src + '.jpg')
    const dataImage = new FormData()
    dataImage.append('image', imageFile)
    dataImage.append('_method', 'PUT')

    try {
      await apiClient.post(url, dataImage)
      await loadDataAdmin()
      setLoading(false)
    } catch (error) {
      handleToast('error', 'Error cargando/editando la imagen.')
      console.log(error)
    }
  }

  const handleUploadPhotos = async ({ imageBase64, src, others }) => {
    setLoading(true)
    let url = `api/incomes/${treatmentId}/images`
    if (others) url = `api/incomes/${treatmentId}/others`
    if (others) src = src + Math.ceil(Math.random() * 1000)
    const imageFile = baseToFile(imageBase64, src + '.jpg')
    let dataImage = new FormData()
    dataImage.append('image', imageFile)
    dataImage.append('name', src)
    if (others) dataImage.append('type', 'image')
    try {
      await apiClient.post(url, dataImage)
      await loadDataAdmin()
      setLoading(false)
    } catch (error) {
      handleToast('error', 'Error cargando la imagen')
      console.log(error)
    }
  }
  const handleUploadStudies = async ({ imageBase64, src, others }) => {
    setLoading(true)
    let url = `api/incomes/${treatmentId}/studies`
    if (others) url = `api/incomes/${treatmentId}/others`
    if (others) src = src + Math.ceil(Math.random() * 1000)
    const imageFile = baseToFile(imageBase64, src + '.jpg')

    let dataImage = new FormData()
    dataImage.append(others ? 'image' : 'study', imageFile)
    dataImage.append('name', src)
    if (others) dataImage.append('type', 'study')
    try {
      await apiClient.post(url, dataImage)
      await loadDataAdmin()
      setLoading(false)
    } catch (error) {
      handleToast('error', 'Error cargando estudios')
      console.log(error)
    }
  }

  useEffect(() => {
    if (!documentationData) return
    let photos = []
    let otherPhotos = []
    let studies = []
    let otherStudies = []
    let plan = []

    documentationData.documentation.forEach((item) => {
      item.type === 1 && (photos = [...photos, ...item.files])
      item.type === 5 && (otherPhotos = [...otherPhotos, ...item.files])
      item.type === 2 && (studies = [...studies, ...item.files])
      item.type === 6 && (otherStudies = [...otherStudies, ...item.files])
      item.type === 3 && (plan = [...plan, ...item.files])
    })

    setBackendFiles({
      photos,
      otherPhotos,
      studies,
      otherStudies,
      plan,
    })
  }, [documentationData])

  useEffect(() => {
    if (isProposalControl) return setFilter(4)
  }, [isProposalControl])

  return {
    filter,
    inputState,
    loading,
    backendFiles,
    onDeleteImage,
    setInputState,
    setFilter,
    setLoading,
    reCategorize,
    handleEditPhotos,
    handleUploadPhotos,
    handleUploadStudies,
  }
}

export default useDocumentationAdmin
