import React from 'react'
import PropTypes from 'prop-types'
import NotificationCard1 from './NotificationCard1'
import NotificationCard2 from './NotificationCard2'
import NotificationCard3 from './NotificationCard3'
import NotificationCard4 from './NotificationCard4'
import NotificationCard5 from './NotificationCard5'
import NotificationCard6 from './NotificationCard6'
import NotificationCard7 from './NotificationCard7'

const NotificationSwitch = ({ data }) => {
  const { type } = data
  return (
    <>
      {type === 1 && data.treatment && <NotificationCard1 data={data} />}
      {type === 2 && data.treatment && <NotificationCard2 data={data} />}
      {type === 3 && data.treatment && <NotificationCard3 data={data} />}
      {type === 4 && data.treatment && <NotificationCard4 data={data} />}
      {type === 5 && data.treatment && <NotificationCard5 data={data} />}
      {type === 6 && data.treatment && <NotificationCard6 data={data} />}
      {type === 7 && data.treatment && <NotificationCard7 data={data} />}
    </>
  )
}

export default NotificationSwitch

NotificationSwitch.propTypes = {
  data: PropTypes.object.isRequired,
}

// CARD TYPES
// NotificationCard1 = Paciente de alta DOC COMPLETA
// NotificationCard2 = Paciente de alta DOC INCOMPLETA
// NotificationCard3 = Nueva propuesta de tratamiento
// NotificationCard4 = En produccion
// NotificationCard5 = Entrega de alineadores
// NotificationCard6 = Reclamo de Documentacion
// NotificationCard7 = Reclamo de propuesta pendiente de revision
