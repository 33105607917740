import React, { useEffect, useState } from 'react'
import styles from './styles/styles.module.css'
import Searchbar from '../../components/searchbar'
import ClaimsCard from './components/ClaimsCard'
import Loader from '../../components/loader'
import Paginator from '../../components/Paginator'
import useClaims from './hooks/useClaims'
import ClaimAllModal from './modals/ClaimAllModal'

const Claims = () => {
  const [isAllSelected, setisAllSelected] = useState(false)
  const [hasSelected, setHasSelected] = useState(false)
  const {
    claimState,
    getAllClaims,
    handlePaginator,
    loading,
    setClaimState,
    updateAfterClaimAll,
  } = useClaims()

  const setAllClaims = () => {
    if (isAllSelected) {
      setisAllSelected(false)
      return setClaimState({
        ...claimState,
        claims: claimState.claims.map((claim) => ({
          ...claim,
          selected: false,
        })),
      })
    } else {
      setisAllSelected(true)
      setClaimState({
        ...claimState,
        claims: claimState.claims.map((claim) => {
          const { studies, photos, plan } = claim.flags
          if (
            claim.proposals_pending.length > 0 &&
            claim.pending_proposals_count < 10
          ) {
            return { ...claim, selected: true }
          } else if (
            (!studies || !photos || !plan) &&
            claim.incomplete_documentation_count < 10
          ) {
            return { ...claim, selected: true }
          }
          return { ...claim, selected: false }
        }),
      })
    }
  }

  useEffect(() => {
    getAllClaims(1, 1)
  }, [])

  useEffect(() => {
    if (claimState.claims.find((claim) => claim.selected)) {
      setHasSelected(true)
    } else {
      setHasSelected(false)
    }
  }, [claimState])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="px-5 py-4">
          <div className="d-flex justify-content-between">
            <p className={`fs-2 fw-bold m-0 ${styles.title}`}>Reclamos</p>
            <div className="w-25">
              <Searchbar
                url={`api/claims?cant_pages=50&page=1&filter[all_to_claim]=true&`}
                state={claimState}
                setState={setClaimState}
                page={'adminClaimsIndex'}
                initialQueryType={'dentist'}
              />
            </div>
          </div>
          <hr className="mt-5" />
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex justify-content-start flex-wrap">
              <div
                className={`my-2 me-3 ${claimState.filter === 1 && 'fw-bold'} ${
                  styles.filterBTNS
                }`}
                onClick={() => getAllClaims(1, 1)}
              >
                Todos
                <span className="text-muted">({claimState.meta.total})</span>
              </div>
              <div
                className={`my-2 mx-3 ${claimState.filter === 2 && 'fw-bold'} ${
                  styles.filterBTNS
                }`}
                onClick={() => getAllClaims(1, 2)}
              >
                Documentación Faltante
                <span className="text-muted">
                  ({claimState.meta.total_incomplete_documentation})
                </span>
              </div>
              <div
                className={`my-2 mx-2 ${claimState.filter === 3 && 'fw-bold'} ${
                  styles.filterBTNS
                }`}
                onClick={() => getAllClaims(1, 3)}
              >
                Aprobacion de Propuesta de Tratamiento
                <span className="text-muted">
                  ({claimState.meta.total_pending_proposals})
                </span>
              </div>
            </div>
            {hasSelected && (
              <ClaimAllModal
                updateAfterClaimAll={updateAfterClaimAll}
                setClaimState={setClaimState}
                claimState={claimState}
              />
            )}
          </div>
          <div className={`d-flex ${styles.tableHead}`}>
            <div className={styles.allFilter}>
              <input
                type="checkbox"
                onClick={() => setAllClaims()}
                checked={isAllSelected}
              />
            </div>
            <p className={styles.id}>ID</p>
            <p className={styles.patients}>Paciente</p>
            <p className={styles.doctor}>Doctor</p>
            <p className={styles.treatment}>Tratamiento</p>
            <p className={styles.stage}>Etapa</p>
            <p className={styles.incomeDate}>Fecha de Ingreso</p>
            <p className={styles.requiredAction}>Acción Requerida</p>
            <p className={styles.lastClaim}>Último Reclamo</p>
            <p className={styles.claimNro}>N° Reclamo</p>
            <p className={styles.claim}>Reclamar</p>
          </div>
          {claimState.claims.map((claim) => (
            <ClaimsCard
              updateClaimDate={claimState.updateClaimDate}
              key={claim.id}
              data={claim}
              currentClaim={
                claimState.claims.filter((claims) => claims.id === claim.id)[0]
              }
              setClaimState={setClaimState}
              claimState={claimState}
              updateAfterClaimAll={updateAfterClaimAll}
              setisAllSelected={setisAllSelected}
            />
          ))}
          <Paginator
            paginator={claimState.paginator}
            getPaginator={handlePaginator}
          />
        </div>
      )}
    </>
  )
}

export default Claims
