import React from "react";

function Resumen({tipoConsulta, categoria, paciente, text}) {
    return (
        <div className="container d-flex flex-column text-primary align-items-center">
            <h3 className="mt-5 fw-bold">¡Consulta Enviada!</h3>

            <p className="mt-4 w-75 text-center">Su consulta fue enviada correctamente. Nuestro equipo de Ortodoncistas la estará revisando para brindarle
                una solución o acompañamiento.</p>

            <div className="w-90 border border-primary d-flex flex-column mt-5 p-3" style={{borderRadius: 15}}>
                <div className="d-flex flex-row justify-content-between">
                    {
                        paciente &&
                        <div>
                            <span className="fw-bold">{paciente.label}</span>
                            <br/>
                            <small>Full - 1era Etapa</small>
                        </div>
                    }

                    <div>
                        <span>Categoria:</span>
                        <br/>
                        <span className="fw-bold">{categoria}</span>
                    </div>

                    <div>
                        <span>Consulta:</span>
                        <br/>
                        <span className="fw-bold">{tipoConsulta}</span>
                    </div>

                    <div>
                        <span style={{fontSize: 12}}>20/07/21</span>
                    </div>
                </div>

                <hr className="w-100"/>

                <div>
                    <p>{text}</p>
                </div>
            </div>

            asdho
        </div>
    )
}

export default Resumen