import { ChevronDown } from '@styled-icons/boxicons-regular'
import React, { useEffect, useState } from 'react'
import apiClient from '../utils/backend'
import pageSelector from '../utils/search/pageSelector'
import throttle from '../utils/throttle'
import PropTypes from 'prop-types'
import './styles.css'

function Searchbar({ url, state, setState, page, initialQueryType, isOdHome }) {
  const [query, setQuery] = useState(null)
  const [dropDown, setDropDown] = useState(false)
  const [filterOptions, setFilterOption] = useState({
    filter: 'fullname',
  })

  const handleFilter = (type) => {
    // preguntar a jero xq ai tantos if
    if (!type) return
    if (type === 'patient') {
      if (page === 'notificationAdmin')
        return setFilterOption({
          filter: 'treatment.patient.fullname',
          type: 'patient',
        })
      if (page === 'referrals')
        return setFilterOption({
          filter: 'full_name',
          type: 'patient',
        })
      if (page === 'qualityControl')
        return setFilterOption({
          filter: 'treatment.patient.user.fullname',
          type: 'patient',
        })
      // on default pages
      return setFilterOption({ filter: 'patient.fullname', type: 'patient' })
    }

    if (type === 'dentist') {
      if (page === 'notificationAdmin')
        return setFilterOption({
          filter: 'treatment.dentists.fullname',
          type: 'dentist',
        })
      if (page === 'referrals')
        return setFilterOption({
          filter: 'dentist.fullname',
          type: 'dentist',
        })
      if (page === 'qualityControl')
        return setFilterOption({
          filter: 'treatment.principalDentist.fullname',
          type: 'dentist',
        })
      // on default pages
      return setFilterOption({
        filter: 'principalDentist.fullname',
        type: 'dentist',
      })
    }

    if (type === 'treatment') {
      if (page === 'qualityControl')
        return setFilterOption({ filter: 'treatment.external_id', type: 'id' })
      // on default pages
      return setFilterOption({ filter: 'external_id', type: 'id' })
    }
  }

  useEffect(() => {
    const handleRequest = async (queryValue) => {
      if (!queryValue) return
      try {
        const queryValueEncoded = encodeURIComponent(queryValue)
        const res = await apiClient.get(
          `${url}filter[${filterOptions.filter}]=${queryValueEncoded}`
        )
        pageSelector(page, res, setState, queryValue, filterOptions.filter)
      } catch (err) {
        console.log(err)
      }
    }

    throttle(handleRequest, query, 500)
  }, [page, query, setState, url, filterOptions])

  // Set load inital data EVENT on search empty
  const handleSearchInput = (value) => {
    if (value === '') setState({ ...state, loadTodosFilter: true })
    setQuery(value)
  }

  //Clear query if other index filter was selected
  useEffect(() => {
    if (state?.clearSearchBarInput) setQuery('')
  }, [state])

  //if search contains filter-type, sets initial type
  useEffect(() => {
    if (!initialQueryType) return
    handleFilter(initialQueryType)
  }, [initialQueryType])

  useEffect(() => {
    if (page !== 'notificationDentist') return
    setFilterOption({ filter: 'treatment.patient.fullname' })
  }, [page])

  return (
    <div>
      {!initialQueryType && (
        <input
          className="search-input w-100 border-0 px-3 py-2 mt-2 rounded-pill shadow-search"
          placeholder=" 🔍 Buscar..."
          value={query}
          maxLength={100}
          onChange={(e) => {
            handleSearchInput(e.target.value)
          }}
        />
      )}
      {initialQueryType && (
        <>
          <div className="d-flex">
            <input
              maxLength={100}
              className="search-input w-100 border-0 px-3 py-2 mt-2 rounded-pill shadow-search"
              placeholder={`🔍 Buscar....`}
              value={query}
              onChange={(e) => {
                handleSearchInput(e.target.value)
              }}
            />
            {!isOdHome && (
              <ChevronDown
                width={50}
                onClick={() => setDropDown(!dropDown)}
                className={`cursor-pointer tr-500 mt-2 ${
                  dropDown && 'rotate-180'
                }`}
              />
            )}
          </div>
          {dropDown && (
            <div className="position-relative row">
              <div className="position-absolute dropDownFather col-12">
                <p
                  className={`text-center text-primary dropDownItem  ${
                    filterOptions.type === 'patient' && 'fw-bold'
                  }`}
                  onClick={() => {
                    handleFilter('patient')
                    setDropDown(!dropDown)
                  }}
                >
                  Paciente
                </p>
                <hr className="m-2" />
                <p
                  className={`text-center text-primary dropDownItem  ${
                    filterOptions.type === 'dentist' && 'fw-bold'
                  }`}
                  onClick={() => {
                    handleFilter('dentist')
                    setDropDown(!dropDown)
                  }}
                >
                  Odontólogo
                </p>
                {page === 'notificationAdmin' || page === 'referrals' ? null : (
                  <>
                    <hr className="m-2" />
                    <p
                      className={`text-center text-primary dropDownItem  ${
                        filterOptions.type === 'id' && 'fw-bold'
                      }`}
                      onClick={() => {
                        handleFilter('treatment')
                        setDropDown(!dropDown)
                      }}
                    >
                      ID Tratamiento
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

Searchbar.propTypes = {
  url: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  initialQueryType: PropTypes.string,
}

export default Searchbar
