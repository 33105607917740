import {
  fiscalConditionData,
  fiscalConditionDataCol,
  fiscalConditionDataMex,
} from '../utilies/constantInfo'

const useCountryInputs = (country) => {
  const isArgentine = country === 1
  const isUruguayan = country === 2
  // const isParaguayan = country === 3
  const isColombian = country === 4
  const isChilean = country === 5
  // const isPeruvian = country === 6
  const isMexican = country === 7

  const handleGetCUIT = () => {
    if (isArgentine || isUruguayan) return 'CUIT'
    if (isMexican) return 'RFC'
    if (isChilean) return 'RUT'
  }

  const handleGetCondicionFiscal = () => {
    if (isArgentine || isColombian || isUruguayan || isChilean)
      return 'Condición Fiscal'
    if (isMexican) return 'Régimen Fiscal'
  }

  const getCondicionFiscalData = () => {
    if (isArgentine || isUruguayan) return fiscalConditionData
    if (isColombian || isChilean) return fiscalConditionDataCol
    if (isMexican) return fiscalConditionDataMex
  }

  const getMatriculaNacional = () => {
    if (isArgentine || isUruguayan) return 'Matrícula Nacional'
    if (isMexican) return 'Cédula Profesional del Odontólogo'
  }

  const getMatriculaProvincial = () => {
    if (isArgentine || isUruguayan) return 'Matrícula Provincial'
    if (isMexican) return 'Cédula de Especialidad'
  }

  return {
    handleGetCUIT,
    handleGetCondicionFiscal,
    getCondicionFiscalData,
    getMatriculaNacional,
    getMatriculaProvincial,
  }
}

export default useCountryInputs
