import React from 'react'
import style from './mobile-patient-card.module.css'
import smile from '../../assets/images/frontalsonriendo.png'
import PropTypes from 'prop-types'
import TreatmentTag from './components/TreatmentTag'
import { useHistory } from 'react-router-dom'

const MobilePatientCard = ({
  avatar,
  name,
  maxilar,
  stage,
  type,
  treatmentType = 1,
  treatmentId,
}) => {
  const history = useHistory()

  return (
    <div
      key={treatmentId}
      className={style.container}
      onClick={() =>
        !!treatmentId && history.push(`treatment-stages/${treatmentId}`)
      }
    >
      <TreatmentTag treatmentType={treatmentType} />
      <div className={type !== 'medical' ? style.topBorder : style.top}>
        <img
          width={50}
          height={50}
          alt="smile"
          src={avatar || smile}
          className={style.image}
        />
        <div className="ms-2 w-75">
          <p className="my-2 fs-7 fw-bold ">{name}</p>
          <p className="my-2 fs-7 d-flex justify-content-between">
            <span className="disabled">{maxilar || 'Sin asignar'}</span>
            <strong className={style.etapa}>ETAPA {stage}</strong>
          </p>
        </div>
      </div>
      <div className={`${!type && 'mb-4 pb-2'}`}>
        {type === 'proposal' && (
          <p className={style.new}>Nueva Propuesta de Tratamiento</p>
        )}
        {type === 'documentation' && (
          <p className={style.documentation}>Documentación incompleta</p>
        )}
      </div>
    </div>
  )
}

MobilePatientCard.propTypes = {
  treatmentId: PropTypes.number,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  maxilar: PropTypes.string,
  stage: PropTypes.number,
  type: PropTypes.string,
  treatmentType: PropTypes.number,
}

export default MobilePatientCard
