import React from 'react'
import ksSmileImage from '../../assets/images/ks-smile.png'
import LegalCards from './components/LegalCards'
import { cards } from './data/cards'
import styles from './styles/legal.module.css'

const Legal = () => {
  return (
    <div className="d-flex flex-column">
      <div className={`${styles.header} px-5 py-3`}>
        <img className="my-4" src={ksSmileImage} alt="ks-smile" width={120} />
        <p className="my-0 fs-2 fw-bold">Documentos legales</p>
        <p className="my-0 fs-4 fw-light my-2">
          Debe leer y mostrarse de acuerdo con los Términos y Condiciones y las
          Políticas de Privacidad de la empresa.
        </p>
      </div>
      <div className="d-flex p-5 justify-content-around flex-wrap">
        {cards.map((card) => (
          <LegalCards key={card.id} title={card.title} />
        ))}
      </div>
    </div>
  )
}

export default Legal
