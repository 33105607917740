import React from 'react'
import { MessageSquare } from 'react-feather'
import moment from 'moment'
import { useState } from 'react'
import { useEffect } from 'react'
import ViewerConfirmationModal from './ViewerConfirmationModal'
import UpdateProposalModal from './UpdateProposalModal'
import apiClient from '../../../../utils/backend'
import ExpandViewer from '../../../ExpandViewer'

const CHProposal = ({ proposalData, updateData }) => {
  const [opened, setOpened] = useState(false)
  const [modalState, setModalState] = useState(null)
  const [proposalsState, setProposalsState] = useState(null)
  const [state, setState] = useState([])
  const [dis, setDis] = useState(false)

  const updateProposal = async (type, propId, currentType) => {
    setModalState({ from: currentType, to: type.toLowerCase(), id: propId })
    setOpened(true)
  }

  const updateProposalCall = async (status) => {
    // if user press "volver"
    if (!status) {
      setProposalsState(null)
      setOpened(false)
      setModalState(null)
      updateData()
      return
    }

    // if user decides to update proposal
    let url = `/api/proposals/${modalState.id}/`
    if (modalState.to === 'aprobado') url = url + 'approve'
    if (modalState.to === 'rechazado') url = url + 'reject'
    if (modalState.to === 'pendiente') url = url + 'pending'
    try {
      await apiClient.post(url)
    } catch (error) {
      console.log(error)
    }

    setProposalsState(null)
    setOpened(false)
    setModalState(null)
    setTimeout(() => updateData(), 1000)
  }

  const displayTreatmentStatus = (status, id) => {
    if (status === 'approved')
      return (
        <select
          disabled={dis}
          onChange={(e) => updateProposal(e.target.value, id, status)}
          className="fw-bold CH-stage-tag CH-aprobado px-2 cursor-pointer"
          placeholder="PENDING"
        >
          <option style={{ background: 'white' }} selected disabled>
            APROBADO
          </option>
          <option
            className="CH-pendiente fw-bold"
            style={{ background: 'white' }}
          >
            PENDIENTE
          </option>
        </select>
      )
    if (status === 'pending')
      return (
        <select
          disabled={dis}
          onChange={(e) => updateProposal(e.target.value, id, status)}
          className="fw-bold CH-stage-tag CH-pendiente px-2 cursor-pointer"
          placeholder="PENDING"
        >
          <option style={{ background: 'white' }} selected disabled>
            PENDIENTE
          </option>
          <option
            className="CH-aprobado fw-bold"
            style={{ background: 'white' }}
          >
            APROBADO
          </option>
          <option
            className="CH-rechazado fw-bold"
            style={{ background: 'white' }}
          >
            RECHAZADO
          </option>
        </select>
      )

    if (status === 'rejected')
      return (
        <select
          disabled={dis}
          onChange={(e) => updateProposal(e.target.value, id, status)}
          className="fw-bold CH-stage-tag CH-rechazado px-2 cursor-pointer"
          placeholder="PENDING"
        >
          <option style={{ background: 'white' }} selected disabled>
            RECHAZADO
          </option>
          <option
            className="CH-pendiente fw-bold"
            style={{ background: 'white' }}
          >
            PENDIENTE
          </option>
        </select>
      )
  }

  useEffect(() => {
    let object = {}
    proposalData.map((item) => {
      if (item.status === 'rejected')
        return (object = { ...object, [item.id]: false })
      return null
    })
    setState(object)
    setProposalsState([...proposalData])
  }, [proposalData])

  if (!proposalsState) return <p>Sin propuestas</p>
  return (
    <>
      <UpdateProposalModal
        opened={opened}
        modalState={modalState}
        updateProposalCall={updateProposalCall}
      />
      {proposalData.map((item, i) => (
        <div key={i} className="mx-4 mt-5">
          <div className="d-flex my-3 align-items-center">
            <p className="my-0 fw-bold me-4">{i + 1}° propuesta</p>
            {displayTreatmentStatus(item.status, item.id)}
            {item.status === 'rejected' && (
              <MessageSquare
                onClick={() =>
                  setState({ ...state, [item.id]: !state[item.id] })
                }
                className="CH-comment-icon"
              />
            )}
          </div>
          <p className="my-2">
            Fecha de envio: {moment(item.created_at).format('DD-MM-YYYY')}
          </p>
          <div className="mt-4 propsal-visor-container">
            <ExpandViewer url={item.url} />
            <ViewerConfirmationModal url={item.url} />
            <iframe
              loading={i > 2 ? 'lazy' : 'auto'}
              title="algo"
              src={item.url}
            />
            <div
              className={`proposal-rech-container ${
                state[item.id] ? null : 'hidden'
              }`}
              id={`rech-card${i}`}
            >
              <div className="proposal-rech-card d-flex">
                <div className="d-flex justify-content-end">
                  <p
                    onClick={() =>
                      setState({ ...state, [item.id]: !state[item.id] })
                    }
                    className="fs-5 close-btn fw-bold pt-3 pe-4"
                  >
                    X
                  </p>
                </div>
                <p className="fw-bold fs-5 text-rech">
                  Rechazo de propuesta de tratamiento
                </p>
                <div className="rech-text-area">
                  {item?.rejected_note?.content}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default CHProposal
