import React, { Component } from "react";
import { connect } from "react-redux";
import { Document, Page } from "react-pdf";
import apiClient from "../../../utils/backend";

import { ChevronLeft, ChevronRight } from "react-feather";

// COMPONENTS
import trescuartos_perfil from "../../../assets/images/34deperfilsonriendo.png";
import anterior_inoclusion from "../../../assets/images/anterioreninoclusion.png";
import anterior_oclusion from "../../../assets/images/anteriorenoclusion.png";
import bucal_derecho from "../../../assets/images/bucalderecho.png";
import bucal_izquierdo from "../../../assets/images/bucalizquierdo.png";
import ricketts from "../../../assets/images/cefalogramadericketts.png";
import frontal_reposo from "../../../assets/images/frontalenreposo.png";
import frontal_sonriendo from "../../../assets/images/frontalsonriendo.png";
import oclusal_maxilar_inf from "../../../assets/images/oclusaldelmaxilarinferior.png";
import oclusal_maxilar_sup from "../../../assets/images/oclusaldelmaxilarsuperior.png";
import perfil_izq_reposo from "../../../assets/images/perfilizquierdoenreposo.png";
import rxpanoramica from "../../../assets/images/rxpanoramica.png";
import telerradiografia from "../../../assets/images/telerradiografiadeperfil.png";
import History from "../components/history";
import ApprobationModal from "../components/approbation_modal";
import RejectModal from "../components/reject_modal";
import { Link } from "react-router-dom";
import FormOnline from "../../FormularioOnline/admin";

import moment from "moment";

import "./index.css";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      step: 1,
      id: null,
      default_images: {
        frontal_sonriendo: frontal_sonriendo,
        frontal_reposo: frontal_reposo,
        perfil_izq_reposo: perfil_izq_reposo,
        trescuartos_perfil: trescuartos_perfil,
        oclusal_maxilar_sup: oclusal_maxilar_sup,
        bucal_izquierdo: bucal_izquierdo,
        anterior_oclusion: anterior_oclusion,
        anterior_inoclusion: anterior_inoclusion,
        bucal_derecho: bucal_derecho,
        oclusal_maxilar_inf: oclusal_maxilar_inf,
        rxpanoramica: rxpanoramica,
        telerradiografia: telerradiografia,
        cefalogramadericketts: ricketts,
      },
    };

    this.handleImageSource = this.handleImageSource.bind(this);
    this.handlePlanSource = this.handlePlanSource.bind(this);
    this.handleStudySource = this.handleStudySource.bind(this);
    this.getImageBase64 = this.getImageBase64.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
  }

  componentDidMount() {
    const path = window.location.pathname.split("/")[5];
    const id = window.location.pathname.split("/")[7];

    this.setState({ id: window.location.pathname.split("/")[3] });

    apiClient
      .get(`/api/helper/getClinicalHistory/${id !== undefined ? id : path}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${this.props.state.userReducer.login_token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.status === 500 || res.status === 404) {
          this.setState({
            loading: false,
            error: {
              error: true,
              message:
                "Error cargando la historia clinica de este tratamiento. Contáctese con el equipo de sistemas con el código 0x0500h",
            },
          });
        }

        this.setState(
          {
            data: res.data.data,
            status: res.data.data.status.id,
            library_images: res.data.data.photos_files,
            treatment_id: res.data.data.external_id,
            loading: false,
          },
          () => {
            this.handleImageSource();
            this.handlePlanSource();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          error: {
            error: true,
            message: `Error cargando la historia clinica de este tratamiento. Contáctese con el equipo de sistemas con el código 0x0500h-${this.props.id}`,
          },
        });
      });
  }

  handleOrder(id) {
    window.location.href = `https://app.keepsmilinglog.com/od/pacientes/8/historiaclinica/${id}/documentacion`;
  }

  async handleImageSource() {
    let documentation = [...this.state.data.photos_files];

    if (documentation.length > 0) {
      for (let i = 0; i < documentation.length; i++) {
        let base64 = await this.getImageBase64(documentation[i].path);

        this.setState({
          [documentation[i].name]: base64,
          [documentation[i].name + "_file"]: documentation[i].file,
        });
      }
    }
  }

  async handlePlanSource(name) {
    let base64 = await this.getImageBase64(this.state.data.plan_file);

    this.setState({
      plan_base64: base64,
    });
  }

  handleStudySource(name) {
    let documentation = [...this.state.data.studies_files];
    let image;

    if (documentation.length > 0) {
      let estudios = documentation;
      image = this.state.data.studies_files.find(
        (estudio) => estudio.name === name
      );
    }

    if (image) {
      return image.path;
    } else {
      return this.state.default_images[name];
    }
  }

  async getImageBase64(path) {
    let base64 = await apiClient.post(
      "/api/helper/getImageBase64",
      { path: path },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${this.props.state.userReducer.login_token}`,
        },
      }
    );

    return base64.data;
  }

  handleModal(id) {
    this.setState({ modal_show: !this.state.modal_show, modal_id: id });
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div className="container-fluid px-5 position-relative">
        <div className="row pt-4 px-4">
          <div className="col-12">
            <h2 className="text-primary fw-bold">Historia Clinica</h2>
          </div>
        </div>
        <div className="row mt-4 px-4">
          <div
            className="col-12 shadow p-3 d-flex flex-row bg-primary"
            style={{ borderRadius: "20px" }}
          >
            <div>
              <img
                src="https://secure.gravatar.com/avatar/b7cbb245307bbb8b4c5499c99bdf42e6?s=250&d=mm&r=g"
                alt=""
                style={{
                  maxWidth: "100px",
                  width: "100%",
                  height: "100%",
                  marginRight: "30px",
                  marginLeft: "10px",
                  border: "1px solid #00155c",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="d-flex flex-column w-100 position-relative">
              <h3 className="fw-bold text-white">
                {this.state.data.patient?.full_name}
              </h3>
              <p className="text-white fw-bold mt-3 mb-1">Primer etapa</p>
              <span className="text-muted">
                {moment(this.state.data.created_at).format("DD-MM-YYYY")}
              </span>
              <h1
                style={{
                  color: "#454875",
                  position: "absolute",
                  bottom: "-15px",
                  right: "20px",
                  fontSize: "7em",
                }}
              >
                1
              </h1>
            </div>
          </div>
        </div>
        <div className="row mt-3 px-4">
          <div className="col-6">
            {this.state.data.back !== null && (
              <button
                onClick={() => this.handleOrder(this.state.data.back)}
                className="btn rounded-pill btn-outline-primary hc-order-btn d-flex flex-row p-0"
              >
                <ChevronLeft className="m-1" />{" "}
                <span style={{ marginRight: "20px", lineHeight: "24px" }}>
                  Volver a la etapa anterior
                </span>
              </button>
            )}
          </div>
          <div className="col-6 d-flex flex-row justify-content-end">
            {this.state.data.next !== null && (
              <button
                onClick={() => this.handleOrder(this.state.data.next)}
                className="btn rounded-pill btn-outline-primary hc-order-btn d-flex flex-row justify-content-end p-0"
              >
                <span style={{ marginLeft: "20px", lineHeight: "24px" }}>
                  Ir a la etapa siguiente
                </span>{" "}
                <ChevronRight className="m-1" />
              </button>
            )}
          </div>
        </div>
        <div className="row px-5 mt-3">
          <div className="col-12 d-flex flex-row justify-content-start">
            <ul
              className="d-flex flex-row p-0 m-0"
              style={{ listStyleType: "none" }}
            >
              <li style={{ marginRight: "15px" }}>
                <a
                  className={
                    this.state.step === 1
                      ? "text-primary fs-6 btn border-0 m-0 disabled"
                      : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                  }
                  href="#"
                  onClick={() => this.setState({ step: 1 })}
                >
                  Fotos
                </a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a
                  className={
                    this.state.step === 2
                      ? "text-primary fs-6 btn border-0 m-0 disabled"
                      : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                  }
                  href="#"
                  onClick={() => this.setState({ step: 2 })}
                >
                  Estudios
                </a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a
                  className={
                    this.state.step === 3
                      ? "text-primary fs-6 btn border-0 m-0 disabled"
                      : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                  }
                  href="#"
                  onClick={() => this.setState({ step: 3 })}
                >
                  Plan de tratamiento
                </a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a
                  className={
                    this.state.step === 4
                      ? "text-primary fs-6 btn border-0 m-0 disabled"
                      : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                  }
                  href="#"
                  onClick={() => this.setState({ step: 4 })}
                >
                  Registro Original
                </a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a
                  className={
                    this.state.step === 5
                      ? "text-primary fs-6 btn border-0 m-0 disabled"
                      : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                  }
                  href="#"
                  onClick={() => this.setState({ step: 5 })}
                >
                  Propuesta de Tratamiento
                </a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a
                  className={
                    this.state.step === 6
                      ? "text-primary fs-6 btn border-0 m-0 disabled"
                      : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                  }
                  href="#"
                  onClick={() => this.setState({ step: 6 })}
                >
                  Observaciones
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="px-4">
          <hr className="mt-0" />
        </div>
        {this.state.step === 1 && (
          <div className="row d-flex flex-row justify-content-center">
            {this.state.data.photos <= 0 && (
              <div className="d-flex flex-row justify-content-between px-5">
                <p
                  className="text-danger"
                  style={{ marginLeft: "30px", paddingTop: "15px" }}
                >
                  ¡Faltan fotos!
                </p>
                <Link
                  to={`/od/documentacion/cargar/${this.state.id}`}
                  className="btn btn-outline-primary rounded-pill px-5"
                >
                  Completar
                </Link>
              </div>
            )}
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {/* <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.handleImageSource('frontal_sonriendo')} /> */}
              {this.state.frontal_sonriendo !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.frontal_sonriendo}
                  src={this.state.frontal_sonriendo}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.frontal_sonriendo}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Frontal sonriendo
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.frontal_reposo !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.frontal_reposo}
                  src={this.state.frontal_reposo}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.frontal_reposo}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Frontal en reposo
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.perfil_izq_reposo !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.perfil_izq_reposo}
                  src={this.state.perfil_izq_reposo}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.perfil_izq_reposo}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Perfil en reposo
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.trescuartos_perfil !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.trescuartos_perfil}
                  src={this.state.trescuartos_perfil}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.trescuartos_perfil}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                3/4 perfil sonriendo
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.oclusal_maxilar_sup !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.oclusal_maxilar_sup}
                  src={this.state.oclusal_maxilar_sup}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.oclusal_maxilar_sup}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Oclusal maxilar superior
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.bucal_izquierdo !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.bucal_izquierdo}
                  src={this.state.bucal_izquierdo}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.bucal_izquierdo}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Bucal izquierdo
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.anterior_oclusion !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.anterior_oclusion}
                  src={this.state.anterior_oclusion}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.anterior_oclusion}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Anterior en oclusion
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.anterior_inoclusion !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.anterior_inoclusion}
                  src={this.state.anterior_inoclusion}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.anterior_inoclusion}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Anterior en inoclusion
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.bucal_derecho !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.bucal_derecho}
                  src={this.state.bucal_derecho}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.bucal_derecho}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Bucal derecho
              </p>
            </div>
            <div
              className="col-2 mx-2 my-2 text-center mt-5"
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                marginBottom: "50px",
              }}
            >
              {this.state.oclusal_maxilar_inf !== undefined ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ref={this.oclusal_maxilar_inf}
                  src={this.state.oclusal_maxilar_inf}
                  group="photos"
                />
              ) : (
                <img
                  style={{
                    opacity: "0.7",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={this.state.default_images.oclusal_maxilar_inf}
                />
              )}
              <p style={{ fontSize: ".9rem", marginTop: "5px" }}>
                Oclusal maxilar inferior
              </p>
            </div>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="row d-flex flex-row justify-content-center">
            {this.state.data.studies <= 0 && (
              <div className="d-flex flex-row justify-content-between px-5">
                <p
                  className="text-danger"
                  style={{ marginLeft: "30px", paddingTop: "15px" }}
                >
                  ¡Faltan estudios!
                </p>
                <Link
                  to={`/od/documentacion/cargar/${this.state.id}`}
                  className="btn btn-outline-primary rounded-pill px-5"
                >
                  Completar
                </Link>
              </div>
            )}
            <div className="col-2 mx-2 my-2 text-center">
              <img
                style={{
                  opacity: ".7",
                  width: "100%",
                  height: "100%",
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
                src={this.handleStudySource("rxpanoramica")}
                group="studies"
              />
              <p className="mt-1" style={{ fontSize: ".9rem" }}>
                RX Panoramica
              </p>
            </div>
            <div className="col-2 mx-2 my-2 text-center">
              <img
                style={{
                  opacity: ".7",
                  width: "100%",
                  height: "100%",
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
                src={this.handleStudySource("telerradiografia")}
                group="studies"
              />
              <p className="mt-1" style={{ fontSize: ".9rem" }}>
                Telerradiografia de perfil
              </p>
            </div>
            <div className="col-2 mx-2 my-2 text-center">
              <img
                style={{
                  opacity: ".7",
                  width: "100%",
                  height: "100%",
                  maxWidth: "150px",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
                src={this.handleStudySource("cefalogramadericketts")}
                group="studies"
              />
              <p className="mt-1" style={{ fontSize: ".9rem" }}>
                Cefalograma de Ricketts
              </p>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="row d-flex flex-row justify-content-center">
            {this.state.data.plan <= 0 && (
              <div className="d-flex flex-row justify-content-between px-5">
                <p
                  className="text-danger"
                  style={{ marginLeft: "30px", paddingTop: "15px" }}
                >
                  ¡Falta plan!
                </p>
                <Link
                  to={`/od/documentacion/cargar/${this.state.id}`}
                  className="btn btn-outline-primary rounded-pill px-5"
                >
                  Completar
                </Link>
              </div>
            )}
            <div className="col-12 p-3">
              <div className="container border border-1">
                {this.state.plan_base64 ? (
                  <div>
                    <Document
                      file={this.state.plan_base64}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                      <Page
                        height={500}
                        scale={1}
                        pageNumber={this.state.pageNumber}
                      />
                    </Document>
                    <a href={this.state.plan_file} target="_blank">
                      <p>
                        Page {this.state.pageNumber} of {this.state.numPages}
                      </p>
                    </a>
                    <button
                      disabled={this.state.pageNumber === 1}
                      onClick={() =>
                        this.setState({
                          pageNumber: this.state.pageNumber - 1,
                        })
                      }
                    >
                      -
                    </button>
                    <button
                      disabled={this.state.pageNumber === this.state.numPages}
                      onClick={() =>
                        this.setState({
                          pageNumber: this.state.pageNumber + 1,
                        })
                      }
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <FormOnline treatment_id={this.state.treatment_id} />
                )}
              </div>
            </div>
          </div>
        )}

        {/* TODO */}
        {/* PONER LINK DE VISOR ORIGINAL CUANDO VENGA DEL BACK */}
        {this.state.step === 4 && (
          <div className="row d-flex flex-row justify-content-center mt-3">
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "<iframe src='https://viewer3d.keepsmilinglog.com/view/189c35be-b69f-4297-92f2-771f0a91ff07' />",
                }}
              />
            </div>
          </div>
        )}
        {this.state.step === 5 && (
          <div className="row d-flex flex-row justify-content-center mt-3">
            <div className="col-6"></div>
            <div className="col-6 d-flex flex-row justify-content-end">
              <button className="btn btn-outline-primary px-4 rounded-pill mx-2">
                Planilla botones
              </button>
              <button className="btn btn-outline-primary px-4 rounded-pill">
                Planilla stripping
              </button>
            </div>
            <div className="col-12 mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "<iframe src='https://viewer3d.keepsmilinglog.com/view/189c35be-b69f-4297-92f2-771f0a91ff07' />",
                }}
              />
              <ApprobationModal
                opened={
                  this.state.modal_show && this.state.modal_id === "approve"
                }
                handleModal={this.handleModal}
                name={this.state.data.patient.full_name}
              />
              <RejectModal
                opened={
                  this.state.modal_show && this.state.modal_id === "reject"
                }
                handleModal={this.handleModal}
              />
              <div className="d-flex flex-row justify-content-center mt-3">
                <button
                  className="btn btn-outline-success rounded-pill px-5 mx-2"
                  onClick={() => this.handleModal("approve")}
                >
                  Aprobar
                </button>
                <button
                  className="btn btn-outline-danger rounded-pill px-5 mx-2"
                  onClick={() => this.handleModal("reject")}
                >
                  Rechazar
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.step === 6 && (
          <div className="row d-flex flex-row justify-content-center mt-3">
            <div className="col-12 px-5">
              <h4 className="text-primary fw-bold">
                ¿En que estado está mi tratamiento?
              </h4>
              <p>
                Este es el historial de tu tratamiento, podes ver en que fecha
                se realizaron las acciones...
              </p>

              <div className="px-4">
                <History data={this.state.data.action_histories} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Home);
