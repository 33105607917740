import React from 'react'
import { HelpCircle } from 'react-feather'
import ReactTooltip from 'react-tooltip'
import ConfirmModal from '../components/confirm_modal'

const ViabilityOneDentist = ({ state, setState }) => {
  const handleStep = () => {
    setState({ ...state, step: state.step + 1 })
  }

  const handleModal = () => {
    setState({ ...state, modal: !state.modal })
  }

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <ConfirmModal
        opened={state.modal}
        handleModal={handleModal}
        handleStep={handleStep}
      />
      <div className="container mt-5">
        <div className="row mt-4">
          {state.error.error ? (
            <div className="col-12 alert alert-danger text-center">
              {state.error.message}
            </div>
          ) : null}
          <div className="row mx-auto">
            <div className="col-12 mx-auto">
              <form className="row col-6 mx-auto">
                <div className="col-12 mb-4">
                  <h4>Paciente</h4>
                </div>
                <div className="col-6">
                  <div>
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      value={state.nombre}
                      type="text"
                      className="form-control"
                      id="nombre"
                      onChange={(e) =>
                        setState({ ...state, nombre: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <label htmlFor="apellido">Apellido</label>
                    <input
                      value={state.apellido}
                      type="text"
                      className="form-control"
                      id="apellido"
                      onChange={(e) =>
                        setState({ ...state, apellido: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="consulta">
                      Consulta
                      <a data-tip data-for="Consulta">
                        {' '}
                        <HelpCircle className="text-muted bg-gray" size="18" />
                      </a>
                    </label>
                    <ReactTooltip
                      id="Consulta"
                      type="light"
                      style={{ maxWidth: '300px' }}
                    >
                      <p className="text-primary fw-bold fs-6">
                        Ayuda KeepSmiling
                      </p>
                      <p className="text-primary">Maxilar</p>
                      <div style={{ maxWidth: '300px' }}>
                        <span
                          style={{ lineHeight: '1.2em' }}
                          className="text-primary"
                        >
                          Debe indicar el maxilar a tratar. Superior, inferior o
                          ambos. Si tiene dudas sobre la viabilidad del
                          tratamiento sobre un maxilar, debe enviar antes una
                          consulta de Viabilidad. En la cual, el equipo
                          KeepSmiling evaluara la documentación de su paciente y
                          le podrá aconsejar.
                        </span>
                      </div>
                    </ReactTooltip>
                    <textarea
                      value={state.consulta}
                      onChange={(e) =>
                        setState({ ...state, consulta: e.target.value })
                      }
                      name="consulta"
                      id="consulta"
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViabilityOneDentist
