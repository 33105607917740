import React from 'react'
import { useHistory } from "react-router";
import moment from 'moment';
import './index.css'

export default function Card({title, id, date, order, treatment}) {
    const history = useHistory()

    return (
        <div className="history-card col-4 p-4 btn text-start mt-4" onClick={() => window.location.href = `https://app.keepsmilinglog.com${history.location.pathname}/${id}/documentacion`} style={{marginRight: '20px'}}>
        <div className="row">
        {console.log(date)}
            <div className="col-12">
                <h3 className="text-white fw-bold">{title}</h3>
                <p className="text-white fw-light">{moment(date).format('DD-MM-YYYY')}</p>
            </div>
            <div className="col-12 mt-5 d-flex flex-column">
                <p className={`text-white fw-normal position-relative`}>Fotos <span className={`${treatment.fotos === 0 && "red-dot"}`}></span></p>
                <p className={`text-white fw-normal position-relative`}>Estudios <span className={`${treatment.estudios === 0 && "red-dot"}`}></span></p>
                <p className={`text-white fw-normal position-relative`}>Plan de Tratamiento<span className={`${treatment.plan === 0 && "red-dot"}`}></span> </p>
                <p className={`text-white fw-normal position-relative`}>Visor Original<span></span> </p>
                <p className={`text-white fw-normal position-relative`}>Propuesta de Tratamiento<span></span> </p>
                <p className={`text-white fw-normal position-relative`}>Observaciones <span></span></p>
            </div>
        </div>
        <div className="row">
            <div className="col-10 mt-4 d-flex flex-column position-relative">
                <h1 style={{color: '#002541', position: 'absolute', bottom: '200px', right: '-50px', fontSize: '5em', fontWeight: 'bold'}}>{order}</h1>
            </div>
        </div>
        </div>
    )
}
