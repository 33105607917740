import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";

// COMPONENTS

import "./style.css";
import bg from "../../assets/images/home_bg.svg";
import ConsultCard from "./components/consult_card";
import DataCard from "./components/data_card";
import TodayChart from "./components/today_chart";
import WeeklyChart from "./components/weekly_chart";
import MontlyChart from "./components/montly_chart";

class HomeAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      todos: [],
      acciones_pendientes: [],
      step: "todos",
      cards: [],
    };
  }

  componentDidMount() {
    apiClient
      .get("/api/home")
      .then((res) => {
        console.log("res");
        console.log(res);

        this.setState({ cards: res.data, loading: false });
      })
      .catch((err) => console.log(err));
    // this.setState({loading: false})
  }

  handleTreatmentColor(type) {
    const colors = {
      "KeepSmiling Full": 1,
      "KeepSmiling Fast": 2,
      "KeepSmiling Medium": 3,
      "KeepSmiling Kids": 4,
      "KeepSmiling Kids Fast": 5,
      "KeepSmiling Teens": 6,
    };

    return colors[type];
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid position-relative overflow-hidden">
          <img
            src={bg}
            className="home-bg1"
            style={{ width: "105%", marginTop: "-40px" }}
          />
          <div className="row">
            <div className="col-9">
              <div className="row pt-4" style={{ zIndex: 2 }}>
                <div className="col-12 px-5" style={{ zIndex: 2 }}>
                  <div
                    className="w-100 mx-auto h-100 shadow p-4"
                    style={{ borderRadius: "20px" }}
                  >
                    <h2 className="text-primary">
                      ¡Hola,{" "}
                      <span className="fw-bold text-primary">
                        {this.props.state.userReducer.name}
                      </span>
                      !
                    </h2>
                    <h5 className="text-primary mx-2 mb-0">
                      Atención y logística
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mt-4 px-4">
                <div className="col-12">
                  <h5 className="text-secondary mb-2">Hoy</h5>
                </div>
                <div
                  className="d-flex flex-row flex-wrap"
                  style={{ overflow: "auto" }}
                >
                  {this.state.cards.map((card) => {
                    return <DataCard data={card} />;
                  })}
                </div>
              </div>
              <div className="row mt-4 px-4">
                <div className="col-6 px-3">
                  <h5 className="text-secondary mb-2">Reporte diario</h5>
                  <TodayChart />
                </div>
                <div className="col-6 h-100">
                  <h5 className="text-secondary mb-2">Reporte semanal</h5>
                  <WeeklyChart />
                </div>
              </div>
              <div className="row mt-4 px-4">
                <div className="col-12 px-3" style={{ height: "350px" }}>
                  <h5 className="text-secondary mb-2">Reporte mensual</h5>
                  <MontlyChart />
                </div>
              </div>
            </div>
            <div className="col-3 mt-5" style={{}}>
              <div
                className="shadow pt-4 px-3 consults_scroll_remove"
                style={{
                  borderRadius: "20px",
                  zIndex: 2,
                  height: "75vh",
                  width: "300px",
                  position: "fixed",
                  top: "85px",
                  right: "20px",
                  overflow: "auto",
                }}
              >
                <h5 className="fw-bold text-muted">
                  Te asignaron las siguientes consultas:
                </h5>
                <hr />
                <ConsultCard />
                <ConsultCard />
                <ConsultCard />
                <ConsultCard />
                <ConsultCard />
                <ConsultCard />
                <ConsultCard />
                <ConsultCard />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(HomeAdmin);
