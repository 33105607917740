import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import apiClient from '../../../utils/backend'
import { Edit, X } from 'react-feather'

import frontalsonriendo from '../../../assets/images/frontalsonriendo.png'
import saologo from '../../../assets/images/sao_logo.png'
import saologoinactive from '../../../assets/images/sao_logo_inactive.png'
import ubaLogo from '../../../assets/images/UBA.png'
import ubaLogoInactive from '../../../assets/images/UBA_inactive.png'
import './index.css'
import handleToast from '../../../components/toaster'
import { useSelector } from 'react-redux'

const EditPatient = ({ patient, principalDentist, setInitialData }) => {
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(false)
  const { countryId } = useSelector((state) => state.userReducer)

  const [data, setData] = useState({
    name: patient.name,
    surname: patient.surname,
    email: patient.email,
    profile:
      patient.groups[0]?.id === 1 ? 1 : patient.groups[0]?.id === 3 ? 3 : [],
    odProfile:
      principalDentist === null
        ? patient.treatments[0]?.dentist[0]
        : principalDentist?.user.groups,
  })

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleRequest = async (e) => {
    e.preventDefault()
    handleToast('updating', 'Guardando los cambios...')
    const headers = {
      'Content-Type': 'application/json',
    }

    if (!data.name || !data.surname || !data.email) {
      return handleToast(
        'error',
        'Nombre, apellido y email son campos obligatorios'
      )
    }

    if (
      data.rut &&
      (data.rut.length < 8 || data.rut.length > 12) &&
      countryId === 5
    ) {
      return handleToast(
        'error',
        `El campo ${
          countryId === 5 ? 'RUT/DNI' : 'DNI'
        } tiene que tener mínimo 8 caracteres y máximo 12`
      )
    }

    if (countryId !== 5) {
      delete data.rut
    }

    delete data.odProfile

    if (data.profile?.length === 0) {
      delete data.profile

      if (countryId !== 5 && data.rut) {
        delete data.rut
      }

      try {
        const res = await apiClient.put(
          `/api/patients/${patient.id}`,
          data,
          headers
        )
        handleToast(
          'success',
          'Los datos del paciente fueron guardados correctamente'
        )
        setOpened(false)
        setInitialData(countryId)
      } catch (error) {
        handleToast('error', 'Error al guardar los datos del paciente')
        console.log(error)
      }
    }
  }

  const handleAvatar = () => {
    if (!patient.treatments[0]?.frontal_sonriendo) {
      return frontalsonriendo
    }
    return patient.treatments[0]?.frontal_sonriendo
  }

  return (
    <div>
      <span
        style={{ fontSize: '.8rem', lineHeight: '25px' }}
        className="edit ms-1"
      >
        <Edit className="mt-2" onClick={() => setOpened(true)} />
      </span>
      <Modal isOpen={opened} size="lg" toggle={() => setOpened(!opened)}>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h4 className="mb-3 fw-bold text-primary">
                  Editar datos paciente
                </h4>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <X className="close_button" onClick={() => setOpened(false)} />
              </div>
            </div>
            <div className="row px-3">
              <div className="col-4 d-flex flex-column justify-content-center py-2">
                <img
                  className="editPatientAvatar"
                  src={handleAvatar()}
                  alt="avatar"
                />
                <p className="text-center mt-2 fw-light">
                  Id Paciente {patient.id}
                </p>
              </div>
              <div className="col-8 px-3">
                <form className="row mb-4 mt-2">
                  <h5 className="fw-bold mb-2">Datos Personales</h5>
                  <div className="col-6">
                    <label htmlFor="name">Nombre</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={data.name}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="surname">Apellido</label>
                    <input
                      className="form-control"
                      type="text"
                      name="surname"
                      id="surname"
                      onChange={(e) => handleChange(e)}
                      value={data.surname}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="email">E-mail</label>
                    <input
                      className="form-control"
                      type="text"
                      id="email"
                      name="email"
                      onChange={(e) => handleChange(e)}
                      value={data.email}
                    />
                  </div>

                  {countryId === 5 && (
                    <div className="col-12">
                      <label htmlFor="rut">RUT/DNI</label>
                      <input
                        className="form-control"
                        type="text"
                        id="rut"
                        name="rut"
                        onChange={(e) => handleChange(e)}
                        value={data.rut}
                      />
                    </div>
                  )}
                </form>
              </div>
              {data.odProfile?.length !== 0 && countryId !== 5 && (
                <>
                  <hr />
                  <div className="col-12 px-3 mt-3">
                    <form className="row mb-4 mt-2">
                      <div className="col-5">
                        <h5 className="text-primary fw-bold">
                          Perfil del paciente
                        </h5>
                        <div className="col-2 d-flex flex-row">
                          <div
                            onClick={() => setData({ ...data, profile: 1 })}
                            className={`btn border rounded ${
                              data.profile === 1 && 'shadow'
                            } me-2`}
                          >
                            <img
                              src={
                                data.profile === 1 ? saologo : saologoinactive
                              }
                              style={{ maxWidth: '60px' }}
                              alt="SAO"
                            />
                          </div>
                          <div className="col-2 d-flex flex-row">
                            <div
                              onClick={() => setData({ ...data, profile: 3 })}
                              className={`btn border rounded ${
                                data.profile === 3 && 'shadow'
                              } me-2`}
                            >
                              <img
                                src={
                                  data.profile === 3 ? ubaLogo : ubaLogoInactive
                                }
                                style={{ maxWidth: '60px' }}
                                alt="UBA"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            {!loading && (
              <div>
                <button
                  onClick={(e) => handleRequest(e)}
                  className="btn btn-success px-5 rounded-pill text-white"
                >
                  Guardar
                </button>
              </div>
            )}

            {loading && (
              <button class="btn btn-danger" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditPatient
