import React from 'react'
import '../../../style.css'

const MiddleLine = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container d-flex">
      <div className="w-50">
        <p className="fw-bold m-0 ms-1">Linea Media Superior</p>
        <hr className="mt-2 me-5" />
        <div className="d-flex flex-column">
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="upperMiddleLine"
              disabled
              checked={formData.middleline_high == 1 && true}
            />
            <label className="ms-2">No mover</label>
          </div>
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="upperMiddleLine"
              disabled
              checked={formData.middleline_high == 2 && true}
            />
            <label className="ms-2">
              Mover{' '}
              <input
                type="number"
                className="middleLineInputs"
                value={
                  formData.middleline_high == 2
                    ? formData.middleline_high_mm
                    : 0
                }
                disabled
              />{' '}
              mm hacia la izquierda
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="upperMiddleLine"
              disabled
              checked={formData.middleline_high == 3 && true}
            />
            <label className="ms-2">
              Mover{' '}
              <input
                type="number"
                className="middleLineInputs"
                value={
                  formData.middleline_high == 3
                    ? formData.middleline_high_mm
                    : 0
                }
                disabled
              />{' '}
              mm hacia la derecha
            </label>
          </div>
        </div>
      </div>
      <div className="w-50">
        <p className="fw-bold m-0 ms-1">Linea Media Inferior</p>
        <hr className="mt-2" />
        <div className="d-flex flex-column">
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="movements"
              disabled
              checked={formData.middleline_low == 1 && true}
            />
            <label className="ms-2">No mover</label>
          </div>
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="downMiddleLine"
              disabled
              checked={formData.middleline_low == 2 && true}
            />
            <label className="ms-2">
              Mover{' '}
              <input
                type="number"
                className="middleLineInputs"
                value={
                  formData.middleline_low == 2 ? formData.middleline_low_mm : 0
                }
                disabled
              />{' '}
              mm hacia la izquierda
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="mt-2 inputColor"
              name="downMiddleLine"
              disabled
              checked={formData.middleline_low == 3 && true}
            />
            <label className="ms-2">
              Mover{' '}
              <input
                type="number"
                className="middleLineInputs"
                value={
                  formData.middleline_low == 3 ? formData.middleline_low_mm : 0
                }
                disabled
              />{' '}
              mm hacia la derecha
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiddleLine
