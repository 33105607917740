import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { toast} from 'react-toastify'
import apiClient from '../../utils/backend'
import logo from '../../assets/images/ks-smile.png'

export const PasswordUpdate = () => {
  const [state, setState] = useState({
    loading: true,
    recover_email: [],
    token: '',
  })
  useEffect(() => {
    const path = window.location.pathname.split('/')
    if (path.indexOf('passwordUpdate') !== -1 && path.length > 2) {
      let token = path[2]
      apiClient
        .get(`/api/helper/checkPasswordToken?token=${token}`)
        .then((res) => {
            setState({
              ...state,
              loading: false,
              recover_email: res.data,
              token: token,
            })
            console.log(res)
          }
        )
        .catch((err) => {
          console.log(err)
          window.location.href = '/login'
        })
    } else {
      setState({ ...state,loading: false })
    }
  }, [])

  const handlePasswordValidation = () => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm
    let match = regex.test(state.password1)

    if (state.password1 !== state.password2) {
      setState({ ...state, password_match: false })
    } else {
      setState({ ...state, password_match: true })
    }

    if (!match) {
      setState({ ...state, password_format_error: true })
    } else {
      setState({ ...state, password_format_error: false })
    }
  }

  const handleInputChange = (e) => {
    setState({...state, [e.target.name]: e.target.value })
  }
  const handleSetPassword = () => {
    if (state.password1 !== state.password2) {
        toast.error("🤔 Las contraseñas deben ser iguales", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })}else{
      apiClient
        .post(
          `/api/changePassword?password=${state.password1}&email=${state.recover_email}&token=${state.token}`
        )
        .then((res) => {
            window.location.href = '/login';
          console.log(res)
        })
        .catch((err) => {
            toast.error("🤔 Hubo un error al crear la nueva contraseña", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
          console.log(err)
        })
    }
  }

  return (
    <main className="h-100">
      <div className="container-fluid h-100">
        <div className="row h-100 align-middle">
          <div
            className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
            style={{ minHeight: '100%', alignItems: 'center' }}
          >
            <div className="px-lg-3" style={{ marginTop: '10em' }}>
              <div className="w-100 mb-5">
                <h1 className="fw-bold text-primary mx-5">Nueva contraseña</h1>
              </div>
              {state.error?.error && (
                <div class="alert alert-danger text-center" role="alert">
                  {state.error?.message}
                </div>
              )}
              <form action="">
                <div className="mb-3 mx-5">
                  <label htmlFor="password1" className="form-label">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    name="password1"
                    onChange={(e) => {
                      handlePasswordValidation()
                      handleInputChange(e)
                    }}
                    className="form-control"
                    id="password1"
                    placeholder="********"
                  />
                </div>
                <div className="mb-3 mx-5">
                  <label htmlFor="password2" className="form-label">
                    Repita la contraseña
                  </label>
                  <input
                    type="password"
                    name="password2"
                    onChange={(e) => {
                      handlePasswordValidation()
                      handleInputChange(e)
                    }}
                    className={`${!state.password_match && ''} form-control`}
                    id="password2"
                    placeholder="********"
                  />
                  <p className="alert alert-info mt-2">La contraseña debe de tener un mínimo de 8 caracteres.</p>
                  {/* {!state.password_match && <p className="text-danger">Las contraseñas no coinciden.</p>} */}
                  {state.password_format_error && (
                    <p className="text-danger">
                      Error en el formato de la contraseña.
                    </p>
                  )}
                </div>
              </form>
              <div className="w-100 d-flex justify-content-center mt-5">
                <button
                  className="btn btn-primary rounded-pill px-5 w-50"
                  onClick={() => handleSetPassword(state.password)}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 bg-primary px-5">
          <div
            className="w-75 d-flex flex-column"
            style={{ marginTop: '15rem' }}
          >
            <h1 className="text-white fw-bold mb-3">
              Somos creadores de Sonrisas
            </h1>
            <img
              src={logo}
              alt="keepsmiling logo"
              className="img img-fluid w-75"
            />
          </div>
        </div>
      </div>
    </main>
  )
}
