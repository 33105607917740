import { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'

const useClaims = () => {
  const [loading, setLoading] = useState(true)
  const [lastUrl, setLastUrl] = useState(null)
  const [claimState, setClaimState] = useState({
    filter: 1,
    claims: [],
    paginator: [],
    meta: [],
  })

  const updateAfterClaimAll = async () => {
    if (!lastUrl) return
    try {
      const res = await apiClient(lastUrl)
      console.log(res)
      setClaimState({
        ...claimState,
        claims: res.data.table.body.map((claim) => ({
          ...claim,
          selected: false,
        })),
        paginator: res.data.paginator,
        meta: res.data.meta,
        updateClaimDate: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getUrl = (page, filter) => {
    let url = `api/claims?cant_pages=50&page=${page}&filter[all_to_claim]=true`
    if (filter === 2)
      url = `api/claims?cant_pages=50&page=${page}&filter[incomplete_documentation]=true`
    if (filter === 3)
      url = `api/claims?cant_pages=50&page=${page}&filter[pending_proposals]=pending`

    return url
  }

  const getAllClaims = async (page = 1, filter) => {
    try {
      const res = await apiClient(getUrl(page, filter))
      setClaimState({
        filter: filter,
        claims: res.data.table.body.map((claim) => ({
          ...claim,
          selected: false,
        })),
        paginator: res.data.paginator,
        meta: res.data.meta,
        clearSearchBarInput: true,
      })
      setLastUrl(getUrl(page, filter))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getSearchedClaimsPaginator = async (page) => {
    let url = `api/claims?cant_pages=50&page=${page}&filter[incomplete_documentation]=true,[pending_proposals]=pending&filter[${claimState.searchType}]=${claimState.searchQuery}`
    try {
      const res = await apiClient(url)
      setClaimState({
        ...claimState,
        claims: res.data.table.body.map((claim) => ({
          ...claim,
          selected: false,
        })),
        paginator: res.data.paginator,
        meta: res.data.meta,
      })
      setLastUrl(url)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePaginator = (page) => {
    if (claimState.searchQuery) return getSearchedClaimsPaginator(page)
    getAllClaims(page, claimState.filter)
  }

  useEffect(() => {
    if (!claimState.loadTodosFilter) return
    getAllClaims(1, 1)
  }, [claimState])

  return {
    claimState,
    getAllClaims,
    handlePaginator,
    loading,
    setClaimState,
    updateAfterClaimAll,
  }
}

export default useClaims
