import React, { useState, useEffect } from 'react'
import { AlertCircle } from 'react-feather'
import apiClient from '../../../utils/backend'
import moment from 'moment'

const ClinicHistoryFlags = ({ currentModalId, documentationData, setData }) => {
  const [flagsLoading, setFlagsLoading] = useState('')
  const [flags, setFlags] = useState(null)
  const [updateDate, setUpdateDate] = useState(null)

  useEffect(() => {
    if (!documentationData) return setFlags(null)
    setFlags({
      photos: documentationData.flags.photos,
      studies: documentationData.flags.studies,
      plan: documentationData.flags.plan,
    })
  }, [documentationData])

  useEffect(() => {
    if (!flags || !currentModalId) return

    // update flags
    setFlagsLoading('disabled')
    const updateFlags = async () => {
      try {
        await apiClient.patch(`api/incomes/${currentModalId}`, flags)
        setFlagsLoading('')
      } catch (err) {
        setFlagsLoading('')
        console.log(err)
      }

      try {
        const res = await apiClient.get(
          `api/medicalRecordDocumentation/${currentModalId}?include=dentists,form`
        )
        setUpdateDate(res.data.data.documentation_updated_at)
      } catch (error) {
        console.log(error)
      }

      try {
        const res = await apiClient.get(
          `api/medicalRecord/${currentModalId}?include=dentists`
        )
        setData(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    updateFlags()
  }, [flags, currentModalId, setData])

  useEffect(() => {
    setUpdateDate(documentationData.documentation_updated_at)
  }, [documentationData.documentation_updated_at])

  if (!flags) return <p>Cargando...</p>
  return (
    <div className="px-4 my-4 d-flex justify-content-between align-items-center flex-wrap">
      <div className="d-flex align-items-center">
        {!flags?.photos || !flags.studies || !flags.plan ? (
          <>
            <AlertCircle className="red" />
            <p className="red my-0 ms-2 me-4">Documentación incompleta</p>
            <button disabled className="CH-flags-btn-blue disabled">
              Reclamar
            </button>
          </>
        ) : (
          <p className="green my-0 ms-2 me-4">
            Documentación completa
            <span className=" my-0 ms-3 text-gray">
              {updateDate
                ? moment(updateDate).format('DD-MM-YYYY')
                : 'Sin fecha cargada.'}
            </span>
          </p>
        )}
      </div>
      <div className="d-flex align-items-center ">
        <p className="pe-2 my-0 blue">Pendiente: </p>
        <button
          className={
            flags?.photos
              ? `CH-flags-btn-blue ${flagsLoading}`
              : `CH-flags-btn-red ${flagsLoading}`
          }
          onClick={() => {
            if (flagsLoading) return
            setFlags({ ...flags, photos: !flags?.photos })
          }}
        >
          Fotos
        </button>
        <button
          className={
            flags.studies
              ? `CH-flags-btn-blue ${flagsLoading}`
              : `CH-flags-btn-red ${flagsLoading}`
          }
          onClick={() => {
            if (flagsLoading) return
            setFlags({ ...flags, studies: !flags.studies })
          }}
        >
          Estudios
        </button>
        <button
          className={
            flags.plan
              ? `CH-flags-btn-blue ${flagsLoading}`
              : `CH-flags-btn-red ${flagsLoading}`
          }
          onClick={() => {
            if (flagsLoading) return
            setFlags({ ...flags, plan: !flags.plan })
          }}
        >
          Plan
        </button>
      </div>
    </div>
  )
}

export default ClinicHistoryFlags
