import React from 'react'
import priceListPer from '../../../assets/images/KSPreciosPer.jpeg'

const PriceListPeru = () => {
  return (
    <div className="d-flex justify-content-center">
      <img
        src={priceListPer}
        alt="Price List Peru"
        style={{ width: '67vw' }}
      />
    </div>
  )
}

export default PriceListPeru
