import React from 'react'
import moment from 'moment'

import './index.css'

export default function History({data}) {
    return (
        <div>
            <ul className="clinical-history-list">
                {data.map(item => {
                    return <li className="clinical-history-list-item">
                        <small className="text-muted">{moment(item.created_at).format("DD/MM/YYYY")}</small>
                        {item.sender === "od" ? <p className="title fw-bold text-info">Tú</p> : <p className="title fw-bold text-primary">KeepSmiling</p>}
                        <p className="subtitle">{item.action.title}</p>
                    </li>
                })}
            </ul>
        </div>
    )
}
