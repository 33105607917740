import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';

const ComunicationsModal = (props) => {

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('externa')
  const [opened, setOpened] = useState(false)

  function handleModal(){
    setOpened(!opened)
  }

  function handleRegistry(){
    
  }

    return (
      <div>
      <button onClick={handleModal} className="btn btn-primary rounded-pill px-4">Nueva Comunicacion</button>
        <Modal isOpen={opened} toggle={handleModal} className="comunications_modal">
          <ModalBody>
            <div className="container">
              <h4 className="fw-bold text-primary">Nueva Comunicacion</h4>
              <div className="row mt-4">
                <div className="col-6"><button onClick={() => setType('externa')} disabled={type === 'externa'} className={`${type === 'externa' && "btn-primary"} btn btn-outline-primary w-100 py-3 rounded-3 fw-bold`}>Al doctor externo</button></div>
                <div className="col-6"><button onClick={() => setType('interna')} disabled={type === 'interna'} className={`${type === 'interna' && "btn-primary"} btn btn-outline-primary w-100 py-3 rounded-3 fw-bold`}>Interna</button></div>
              </div>
              <div className="row mt-3">
                <div className="col-3"><button className="btn btn-outline-primary fw-bold rounded-3 w-100 h-100">Clínica</button></div>
                <div className="col-3"><button className="btn btn-outline-primary fw-bold rounded-3 w-100 h-100">Atención y logística</button></div>
                <div className="col-3"><button className="btn btn-outline-primary fw-bold rounded-3 w-100 h-100">Marketing</button></div>
                <div className="col-3"><button className="btn btn-outline-primary fw-bold rounded-3 w-100 h-100">Cobranzas</button></div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="doctor">Doctor</label>
                  <Select  />
                </div>
                <div className="col-6">
                  <label htmlFor="paciente">Paciente</label>
                  <Select  />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="consulta">Consulta</label>
                  <Select />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="detalle">Detalle</label>
                  <textarea name="detalle" id="detalle" cols="30" rows="5" className="form-control w-100"></textarea>
                </div>
              </div>
              {type === 'interna' && <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="medio">Medio</label>
                  <Select />
                </div>
                <div className="col-6">
                  <label htmlFor="estado">Estado</label>
                  <Select />
                </div>
                <div className="col-6 mt-3">
                  <label htmlFor="consulta">Asignar Consulta</label>
                  <Select />
                </div>
              </div>}
            </div>
          </ModalBody>
          <ModalFooter>

            {!loading &&
              <div>
                <button className="btn btn-primary px-5 text-white rounded-pill" onClick={handleRegistry}>Enviar</button>
              </div>
            }

            {loading &&
              <button class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
              </button>
            }

          </ModalFooter>
        </Modal>
      </div>
    );
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(ComunicationsModal)
