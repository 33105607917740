import React from 'react'
import Loader from '../../../components/loader'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../../components/Paginator'
import Searchbar from '../../../components/searchbar'
import '../index.css'
import ProposalControlCard from './componentes/ProposalControlCard'
import useQualityControl from './hooks/useQualityControl'

const ProposalControl = () => {
  const { state, setState, getInitialData, paginatorHandler, countryId } =
    useQualityControl()

  if (state.loading) return <Loader />
  return (
    <div className="p-4">
      <div className="mb-4">
        <p className="fs-2 fw-bold text-primary">Control de calidad</p>
      </div>
      <div className="d-flex justify-content-end">
        <Searchbar
          initialQueryType="treatment"
          state={state}
          setState={setState}
          page={'qualityControl'}
          url={`api/proposals?filter[show]=0&filter[type]=video&include=treatment&page=1&filter[treatment.patient.user.country.id]=${countryId}&`}
        />
      </div>
      <hr className="mt-4" />
      <div className="ms-4 d-flex">
        <div className="mx-2 idControl">
          <p className="fw-bold text-primary my-0">Id</p>
        </div>
        <div className="patientControl">
          <p className="fw-bold text-primary my-0">Paciente</p>
        </div>
        <div className="doctorControl">
          <p className="fw-bold text-primary my-0">Doctor</p>
        </div>
        <div style={{ width: '100px', textAlign: 'center' }}>
          <p className="fw-bold text-primary my-0">Tratamiento</p>
        </div>
        <div style={{ width: '55px', textAlign: 'center' }}>
          <p className="fw-bold text-primary my-0">Etapa</p>
        </div>
        <div className="maxillarControl">
          <p className="fw-bold text-primary my-0">Maxilar</p>
        </div>
        <div style={{ width: '105px', textAlign: 'center' }}>
          <p className="fw-bold text-primary my-0">Movimiento</p>
        </div>
        <div className="">
          <p className="fw-bold text-primary my-0">Propuesta de tratamiento</p>
        </div>
      </div>

      {!state.treatments.length && <NotFoundComponent />}
      {state.treatments.map((item) => (
        <ProposalControlCard
          data={item}
          key={item.id}
          getInitialData={getInitialData}
        />
      ))}
      <Paginator paginator={state.paginator} getPaginator={paginatorHandler} />
    </div>
  )
}

export default ProposalControl
