import React, { useEffect, useState } from 'react'
import './MedicalRecordModal/styles/index.css'
import alert from '../assets/images/CH-alert.png'
import completed from '../assets/images/CH-check.png'
import pending from '../assets/images/CH-empty.png'
import smile from '../assets/images/CH-smile.png'
import PropTypes from 'prop-types'
import styles from './treatmentStyles.module.css'
import { useSelector } from 'react-redux'

const TreatmentStateStepBar = ({ type }) => {
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)
  const isChilean = countryId === 5
  const [step, setStep] = useState()

  const widths = {
    1: '0%',
    2: '25%',
    3: '50%',
    4: '75%',
    5: '100%',
  }

  useEffect(() => {
    if (type === 1) {
      return setStep(2)
    }
    if (type === 2) {
      return setStep(2)
    }
    if (type === 3) {
      return setStep(3)
    }
    if (type === 4) {
      return setStep(4)
    }
    if (type === 5) {
      return setStep(5)
    }
    if (type === 6) {
      return setStep(2)
    }
    if (type === 7) {
      return setStep(2)
    }
  }, [])

  return (
    <>
      {type === 1 && (
        <div className="d-flex ">
          <div
            className={`col-9 ${styles.treatmentStateBox}  p-2`}
            style={{ boxShadow: '0px 0px 0px' }}
          >
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
      {type === 2 && (
        <div className="d-flex">
          <div className={`col-9 ${styles.treatmentStateBox}  p-2`}>
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={alert} className="barItem" alt="alert" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
      {type === 3 && (
        <div className=" d-flex">
          <div className={`col-9 ${styles.treatmentStateBox}  p-2`}>
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={completed} className="barItem" alt="alert" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={alert} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
      {type === 4 && (
        <div className="d-flex">
          <div className={`col-9 ${styles.treatmentStateBox}  p-2`}>
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={completed} className="barItem" alt="alert" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={completed} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={smile} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
      {type === 5 && (
        <div className="d-flex">
          <div className={`col-9 ${styles.treatmentStateBox}  p-2`}>
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={completed} className="barItem" alt="alert" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={completed} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={completed} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={completed} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
      {type === 6 && (
        <div className="d-flex">
          <div className={`col-9 ${styles.treatmentStateBox}  p-2`}>
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={alert} className="barItem" alt="alert" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
      {type === 7 && (
        <div className="d-flex">
          <div className={`col-9 ${styles.treatmentStateBox}  p-2`}>
            <p className="text-center fw-bold">Estado de tratamiento:</p>
            <div
              className="col-10 mt-5 mb-5 ms-2"
              style={{ position: 'relative', maxWidth: '70vw' }}
              id="timeline"
            >
              <ul
                className="w-100"
                style={{
                  background: '#ECEEF1',
                  height: '6px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'relative',
                }}
              >
                {/* marco gris del stepbar */}
                <li
                  style={{
                    width: '110%',
                    height: '100%',
                    position: 'absolute',
                    padding: '23px',
                    top: '-30px',
                    borderRadius: '30px',
                    left: '-3%',
                    right: '0',
                  }}
                ></li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '-2px',
                  }}
                >
                  <img src={completed} className="barItem" alt="completed" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    {isChilean ? (
                      <p>
                        Ingreso <br /> del paciente
                      </p>
                    ) : (
                      <p>
                        Alta de <br /> paciente
                      </p>
                    )}
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '35px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '25%',
                  }}
                >
                  <img src={alert} className="barItem" alt="alert" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Documentación completa
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '50%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Propuesta de tratamiento
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '75%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />

                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    En producción de alineadores
                  </span>
                </li>
                <li
                  style={{
                    background: '#F8F9FA',
                    width: '42px',
                    height: '39px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '-14px',
                    zIndex: 10,
                    left: '100%',
                  }}
                >
                  <img src={pending} className="barItem" alt="pending" />
                  <span
                    style={{
                      position: 'absolute',
                      top: '50px',
                      width: '100px',
                      textAlign: 'center',
                      left: '-30px',
                      fontWeight: 'bold',
                      fontSize: '.6rem',
                    }}
                  >
                    Entrega de <br /> Tratamiento
                  </span>
                </li>
              </ul>
              <ul
                style={{
                  background: '#2ecc71',
                  height: '6px',
                  width: widths[step],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  listStyleType: 'none',
                  position: 'absolute',
                  top: '0',
                  zIndex: 9,
                }}
              ></ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TreatmentStateStepBar

TreatmentStateStepBar.propTypes = {
  type: PropTypes.number.isRequired,
}
