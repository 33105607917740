import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import apiClient from '../../../../utils/backend'
import moment from 'moment'
import { User } from 'react-feather'
import { useSelector } from 'react-redux'
import handleToast from '../../../../components/toaster'
import useCountryInputs from './useCountryInputs'
import { fiscalConditionOptions } from '../utilies/fiscalConditionOptions'
import { useLoadScript } from '@react-google-maps/api'

const useDentistProfile = () => {
  const [loadingData, setLoadingData] = useState(false)
  const [directions, setDirections] = useState([])
  const userId = useSelector((state) => state.userReducer.dentistId)
  const { countryId, dentistId } = useSelector(
    (reduxState) => reduxState.userReducer
  )
  const history = useHistory()
  const [avatar, setAvatar] = useState(null)
  const [avatarLink, setAvatarLink] = useState(null)
  const [genderObject, setGenderObject] = useState(null)
  const [fiscalConditionObject, setFiscalConditionObject] = useState()
  const [documentoDeIdentidad, setDocumentoDeIdentidad] = useState(null)
  const isColombian = countryId === 4
  const isChilean = countryId === 5
  const {
    handleGetCUIT,
    handleGetCondicionFiscal,
    getCondicionFiscalData,
    getMatriculaNacional,
    getMatriculaProvincial,
  } = useCountryInputs(countryId)
  const [requiredData, setRequiredData] = useState({
    name: null,
    surname: null,
    email: null,
    gender: null,
    birthdate: null,
    rut: null,
  })
  const [personalData, setPersonalData] = useState({
    accreditation_course: null,
    profilePhoto: null,
    phone_number: null,
    cuit: null,
    fiscal_condition: null,
    national_enrollment: null,
    provintial_enrollment: null,
    facebook: null,
    instagram: null,
    twitter: null,
    web: null,
  })
  const [profile, setProfile] = useState({
    sao: false,
    study_group: false,
    sg_team: null,
    sg_type: null,
    sg_level: null,
  })
  const [errorField, setErrorField] = useState({
    name: '',
    surname: '',
    gender: '',
    birthdate: '',
    email: '',
    accreditation_course: '',
    cuit: '',
    sg_type: '',
    national_enrollment: '',
    provintial_enrollment: '',
  })

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const handleSetErrors = (err) => {
    let profileError = false
    if (
      err['profile.sg_type'] ||
      err['profile.sg_team'] ||
      err['profile.sg_level']
    )
      profileError = true

    setErrorField({
      ...errorField,
      name: err['user.name'] !== null && err['user.name'],
      surname: err['user.surname'] !== null && err['user.surname'],
      gender: err['user.gender'] !== null && err['user.gender'],
      email: err['user.email'] !== null && err['user.email'],
      cuit: err['dentist.cuit'] !== null && err['dentist.cuit'],
      rut: err['user.rut'] !== null && err['user.rut'],
      national_enrollment:
        err['dentist.national_enrollment'] !== null &&
        err['dentist.national_enrollment'],
      provintial_enrollment:
        err['dentist.provintial_enrollment'] !== null &&
        err['dentist.provintial_enrollment'],
      profile: profileError,
      accreditation_course:
        err['dentist.accreditation_course'] !== null &&
        err['dentist.accreditation_course'],
    })
  }

  const cleanError = (errName) => {
    setErrorField({ ...errorField, [errName]: null })
  }

  const removeAvatar = () => {
    setAvatar(null)
  }

  const handleRedirect = () => {
    handleToast('success', '¡Dentista editado exitosamente!')

    setTimeout(() => {
      history.replace('/od/home')
    }, 2000)
  }

  const handleRequest = async () => {
    setLoadingData(true)
    const data = {
      user: {
        ...requiredData,
      },
      dentist: {
        ...personalData,
      },
      profile,
    }
    try {
      const res = await apiClient.put(`api/dentists/${userId}`, data)
      if (res.data.success) {
        return handleSendAvatar(res.data.data.id)
      }
      handleToast('error', '¡Error al actualizar el dentista!')

      handleSetErrors(res.data.errors)
      setLoadingData(false)
    } catch (error) {
      handleToast('error', '¡Error al actualizar el dentista!')
      setLoadingData(false)
      console.log(error)
    }
  }

  const handleSendAvatar = async (dentistId) => {
    if (!avatar) return handleRedirect()
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const data = new FormData()
    data.append('avatar', avatar)
    data.append('_method', 'PUT')
    try {
      const res = await apiClient.post(
        `/api/dentists/${dentistId}/avatar`,
        data,
        headers
      )
      if (res.status === 200) handleRedirect()
    } catch (err) {
      setLoadingData(false)
      console.log(err)
      handleToast('error', 'Error al cargar el avatar!')
    }
  }

  const handleGender = (value) => {
    switch (value) {
      case 'f':
        setGenderObject({ label: 'Femenino', value: value })
        break
      case 'm':
        setGenderObject({ label: 'Masculino', value: value })
        break
      case 'o':
        setGenderObject({ label: 'Otro', value: value })
        break
      default:
        break
    }
  }

  const handleFisicalCondition = (value) => {
    setFiscalConditionObject({
      label: fiscalConditionOptions[value],
      value: value,
    })
  }

  const displayAvatarImage = () => {
    if (avatar || avatarLink) {
      return (
        <img
          style={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            maxWidth: '200px',
            maxHeight: '200px',
            objectFit: 'cover',
          }}
          src={avatar ? personalData.profilePhoto : avatarLink}
          alt="Foto de perfil"
        />
      )
    } else {
      return (
        <div
          className="flex"
          style={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            paddingTop: 20,
            paddingBottom: 20,
            paddingRight: 40,
            paddingLeft: 40,
          }}
        >
          <User size={124} color="#ccc" />
          <p className="text-primary text-center">Avatar</p>
        </div>
      )
    }
  }

  //Request direction data
  const handleGetDirections = async () => {
    try {
      const res = await apiClient(`api/dentists/${dentistId}/offices`)
      if (res.data.table.length === 0) {
        setDirections([
          {
            direction_name: `#Dirección 1`,
            toSave: false,
            inputId: 1,
            search: '',
            street_address: '',
            street_number: '',
            floor: '',
            apartment: '',
            hood: '',
            zip_code: '',
            province: '',
            city: '',
            country: '',
            lat: 0,
            lng: 0,
          },
        ])
      } else {
        setDirections(res.data.table)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteDirection = async (inputId, id) => {
    if (directions.length === 1)
      return handleToast('error', 'Se necesita al menos una dirección')
    let newDirections = directions
    if (id) {
      try {
        await apiClient.delete(`api/offices/${id}`)
        handleToast('success', 'Dirección eliminada')
        handleGetDirections()
      } catch (error) {
        console.log(error)
      }
    } else {
      setDirections(
        newDirections.filter((direction) => direction.inputId !== inputId)
      )
      handleToast('success', 'Dirección eliminada')
    }
  }

  const handleSaveDirection = async (
    directionData,
    lat = '',
    lng = '',
    isWithoutGoogleApi = false
  ) => {
    let newDirectionData = {
      ...directionData,
      lat: lat.toString(),
      lng: lng.toString(),
    }
    delete directionData.toSave
    delete directionData.inputId
    try {
      if (directionData.id) {
        await apiClient.patch(
          `api/offices/${directionData.id}`,
          isWithoutGoogleApi ? newDirectionData : directionData
        )
      } else {
        await apiClient.post(
          `api/dentists/${dentistId}/offices`,
          isWithoutGoogleApi ? newDirectionData : directionData
        )
      }
      handleGetDirections()
      handleToast('success', 'Dirección guardada exitosamente')
    } catch (error) {
      console.log(error)
    }
  }

  const addDirection = () => {
    if (directions.length === 5)
      return handleToast('error', 'Solo puede agregar hasta 5 direcciones')
    setDirections((prev) => [
      ...prev,
      {
        direction_name: `#Dirección ${directions.length + 1}`,
        inputId: directions.length + 1,
        toSave: false,
        search: '',
        street_address: '',
        street_number: '',
        floor: '',
        apartment: '',
        hood: '',
        zip_code: '',
        province: '',
        city: '',
        country: '',
        lat: 0,
        lng: 0,
      },
    ])
  }

  // Set data from current dentist
  useEffect(() => {
    if (!userId) return
    const apiCall = async () => {
      const res = await apiClient.get(
        `/api/dentists/${userId}?include=user,offices,studyGroupRelationship`
      )
      let dentist = res.data.data

      let birthdate_polyfill = dentist.user.birthdate
        ?.split('/')
        .reverse()
        .join('-')

      setRequiredData({
        name: dentist.user.name,
        surname: dentist.user.surname,
        email: dentist.user.email,
        rut: dentist.user.rut,
        gender: dentist.user.gender,
        birthdate: birthdate_polyfill,
      })
      handleGender(dentist.user.gender)
      handleFisicalCondition(dentist.fiscal_condition)
      if (dentist.user.avatar) setAvatarLink(dentist.user.avatar)
      setPersonalData({
        id: dentist.id,
        profilePhoto: dentist.user.avatar,
        phone_number: dentist.phone_number,
        cuit: dentist.cuit,
        fiscal_condition: dentist.fiscal_condition,
        national_enrollment: dentist.national_enrollment,
        provintial_enrollment: dentist.provintial_enrollment,
        facebook: dentist.facebook,
        instagram: dentist.instagram,
        twitter: dentist.twitter,
        web: dentist.web,
        accreditation_course: moment(dentist.accreditation_course).format(
          'YYYY-MM-DD'
        ),
      })
      setDocumentoDeIdentidad({
        value: dentist.cuit_prefix,
        label: dentist.cuit_prefix,
      })
      setProfile({
        sao: dentist.sao,
        study_group: dentist.study_group,
        sg_type: dentist.study_group_relationship?.rol
          ? Number(dentist.study_group_relationship?.rol)
          : null,
        sg_level: dentist.study_group_relationship?.type
          ? Number(dentist.study_group_relationship?.type)
          : null,
        sg_team:
          dentist.study_group_relationship?.group_id !== null
            ? Number(dentist.study_group_relationship?.group_id)
            : null,
      })
    }
    apiCall()
    handleGetDirections()
  }, [userId])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA5GePOrq5a4XfoMbjjiLrOBYq4hpH7Cds',
    libraries: ['places'],
  })

  if (!isLoaded) return <div></div>

  return {
    avatar,
    avatarLink,
    personalData,
    errorField,
    requiredData,
    genderObject,
    isColombian,
    documentoDeIdentidad,
    countryId,
    fiscalConditionObject,
    isChilean,
    profile,
    loadingData,
    directions,
    removeAvatar,
    setAvatar,
    getBase64,
    setPersonalData,
    displayAvatarImage,
    cleanError,
    setRequiredData,
    setGenderObject,
    handleGetCUIT,
    handleGetCondicionFiscal,
    getCondicionFiscalData,
    setFiscalConditionObject,
    getMatriculaNacional,
    getMatriculaProvincial,
    handleRequest,
    setDocumentoDeIdentidad,
    handleGetDirections,
    handleDeleteDirection,
    handleSaveDirection,
    addDirection,
  }
}

export default useDentistProfile
