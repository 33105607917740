import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import '../../styles/index.css'
import Share from '../../../../assets/icons/shareIcon.png'
import { X } from 'react-feather'
import PropTypes from 'prop-types'
import CopyToClipboard from 'react-copy-to-clipboard'

const ViewerConfirmationModal = ({ url }) => {
  const [opened, setOpened] = useState(false)
  const [copy, setCopy] = useState(false)
  const handleCopy = () => {
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 1500)
  }

  return (
    <>
      <div
        className="d-flex justify-content-end w-100"
        style={{ height: '33px' }}
      >
        <div className="shareBTNFather" onClick={() => setOpened(!opened)}>
          <img src={Share} className="shareBTN" />
        </div>
      </div>
      <Modal
        style={{ marginTop: '150px' }}
        isOpen={opened}
        size="lg"
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          <div className="p-1">
            <div className="w-100 d-flex justify-content-end">
              <X className="xBTN" onClick={() => setOpened(!opened)} />
            </div>
            <div className="w-100 text-center">
              <p className="fw-bold fs-4">Compartir visor 3d</p>
            </div>
            <div className="w-100 text-center mt-3 mb-4">
              <p className="text-muted">
                Puede copiar el enlace del visor 3d para compartirlo con su
                paciente.
              </p>
            </div>
            <div className="d-flex justify-content-center w-100">
              <input type="text" className="viewerInput" value={url} disabled />
            </div>
            <div className="d-flex justify-content-center w-100 my-4">
              <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                <div
                  className={`${
                    copy ? 'disabled' : ''
                  } copyBTN mx-2 rounded-pill`}
                >
                  {copy ? '¡Copiado!' : 'Copiar Enlace'}
                </div>
              </CopyToClipboard>
              <a
                className="copyBTN mx-2 rounded-pill"
                href={`https://wa.me/?text=${url}`}
                data-action="share/whatsapp/share"
                target="_blank"
              >
                Enviar por Whatsapp
              </a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ViewerConfirmationModal

ViewerConfirmationModal.propTypes = {
  url: PropTypes.string.isRequired,
}
