import React, { useEffect, useState } from 'react'
import { ChevronLeft } from 'react-feather'
import { StepBar } from './components/StepBar'
import ViabilityOneDentist from './Stages/ViabilityOneDentist'
import { ViabilityTwoDentist } from './Stages/ViabilityTwoDentist'
import { ViabilityThreeDentist } from './Stages/ViabilityThreeDentist'
import { ViabilityFourDentist } from './Stages/ViabilityFourDentist'
import { ViabilityFiveDentist } from './Stages/ViabilityFiveDentist'
import { ViabilitySixDentist } from './Stages/ViabilitySixDentist'
import frontalsonriendo from '../../../assets/images/frontalsonriendo.png'
import apiClient from '../../../utils/backend'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/loader'

const NewViability = () => {
  const userId = useSelector((state) => state.userReducer.dentistId)
  const [stageOneFinish, setStageOneFinish] = useState(false)
  const [stageTwoFinish, setStageTwoFinish] = useState(false)
  const [stageThreeFinish, setStageThreeFinish] = useState(false)
  const [stageFourFinish, setStageFourFinish] = useState(false)
  const [stageFiveFinish, setStageFiveFinish] = useState(false)
  const [state, setState] = useState({
    dentist_id: userId,
    numPages: null,
    pageNumber: 1,
    treatment_id: null,
    plantratamiento: null,
    step: 1,
    patient: 'null',
    loading: true,

    // VISTA 1
    od_list: null,
    nombre: null,
    apellido: null,
    consulta: null,
    genero: 'm',
    maxilar: 0,
    maxilar_string: '',
    external_id: null,
    odontologo: '',
    treatment_type: 1,
    intraoral: 1,
    etapa: 1,
    is_new_patient: true,
    od_patients: [],
    selected_patient: null,
    selected_od: null,
    newIntraoralScanDate: null,
    sendImpressions: {},

    fotos: {},
    fotos_files: [],
    estudios: {},
    estudios_files: [],
    plan: {},

    registration_datetime: null,
    scan_file: null,

    // STEP DATA
    flag_photos: 0,
    flag_studies: 0,
    flag_plan: 0,

    // ERROR HANDLER
    error: {
      error: false,
      message: '',
    },

    // CROPPER
    cropper: false,
    cropper_img: null,
    cropper_file: null,

    errors: [],
    modal: false,
  })

  const dispatch = useDispatch()

  const stageTitleArray = [
    null,
    'Cargar datos',
    'Adjuntar fotos',
    'Adjuntar estudios',
    'Adjuntar Plan de Tratamiento',
    'Envío de Registro',
  ]
  const text = (
    <p className="text-center">
      Recuerde que puede cargar fotos y estudios en otro momento. Podrá acceder
      a sus pacientes con acciones requeridas para adjuntar las fotos o estudios
      faltantes, <br />
      <b>
        KeepSmiling no dará comienzo al tratamiento hasta no recibir toda la
        documentación.
      </b>
    </p>
  )

  const stageFooterArray = [
    null,
    <p className="text-center">
      Todas las solicitudes enviadas, serán revisadas por el equipo de
      KeepSmiling y usted recibirá un seguimiento por parte del equipo. Una
      solicitud no es un paciente ingresado aún. <br />
      Para cualquier consulta, puede comunicarse por ... al XXXXXXXXXXXXXXXX
    </p>,
    text,
    text,
    text,
    text,
  ]
  const toastProps = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  }

  const checkFrotalSonriendo = () => {
    if (state?.frontal_sonriendo) {
      return state.frontal_sonriendo
    }
    if (state?.selected_patient?.patientImage) {
      return state?.selected_patient?.patientImage
    }
    return frontalsonriendo
  }

  const handleChangeStep = () => {
    if (!stageOneFinish)
      return toast.error('Todos los campos son requeridos', toastProps)

    if (state.step === 5 && !stageFiveFinish)
      return toast.error('Todos los campos son requeridos', toastProps)

    setState({ ...state, step: state.step + 1 })
  }

  // set current dentist
  useEffect(() => {
    apiClient
      .get('/api/dentists?cant_pages=all')
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          let odont = {}
          res.data.table.body.map((od) => {
            if (od.id === state.dentist_id)
              return (odont = {
                value: od.id,
                id: od.id,
                label: od.user.full_name,
                name: od.user.name,
                surname: od.user.surname,
              })
          })
          setState({
            ...state,
            odontologo: odont,
            loading: false,
          })
        }
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    // Validate Step 1
    if (state.consulta && state.nombre && state.apellido) {
      setStageOneFinish(true)
    } else setStageOneFinish(false)

    // Validate Step 2
    let i = 0
    const values = Object.values(state.fotos)
    values.forEach((value) => {
      if (value !== null) ++i
    })
    if (i > 9) setStageTwoFinish(true)
    else setStageTwoFinish(false)

    // Validate Step 3
    if (state.telerradiografia && state.rxpanoramica && state.ricketts) {
      setStageThreeFinish(true)
    } else setStageThreeFinish(false)

    // Validate Step 4
    if (state.plantratamiento || state?.online_form_state) {
      setStageFourFinish(true)
    } else setStageFourFinish(false)

    //Validate Step 5
    if (state) {
      if (state.newIntraoralScanDate) return setStageFiveFinish(true)
      if (
        state.sendImpressions.takeover_date &&
        state.sendImpressions.dateToGet &&
        state.sendImpressions.withdrawalAddress
      )
        return setStageFiveFinish(true)
      setStageFiveFinish(false)
    }

    console.log(state)
  }, [state])

  useEffect(() => {
    localStorage.removeItem('persist:primary')
    dispatch({ type: 'clear_form_online', payload: {} })
  }, [dispatch])

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <div>
          <div className="row mt-5">
            <div className="col-12 px-5 text-center ">
              <h2 className="text-primary fw-bold">
                Nueva Solicitud de Viabilidad
              </h2>
            </div>
            <div className="col-12 mx-auto text-primary text-center mt-1">
              <h5>{stageTitleArray[state.step]}</h5>
            </div>
            <StepBar
              state={state}
              stageTwoFinish={stageTwoFinish}
              stageThreeFinish={stageThreeFinish}
              stageFourFinish={stageFourFinish}
            />
          </div>
          {state.step === 1 && (
            <ViabilityOneDentist state={state} setState={setState} />
          )}

          {state.step === 2 && (
            <ViabilityTwoDentist state={state} setState={setState} />
          )}

          {state.step === 3 && (
            <ViabilityThreeDentist state={state} setState={setState} />
          )}

          {state.step === 4 && (
            <ViabilityFourDentist
              state={state}
              setState={setState}
              checkFrotalSonriendo={checkFrotalSonriendo}
            />
          )}

          {state.step === 5 && (
            <ViabilityFiveDentist state={state} setState={setState} />
          )}

          {state.step === 6 && (
            <ViabilitySixDentist
              state={state}
              setState={setState}
              stageTwoFinish={stageTwoFinish}
              stageThreeFinish={stageThreeFinish}
              stageFourFinish={stageFourFinish}
              checkFrotalSonriendo={checkFrotalSonriendo}
            />
          )}

          {state.step !== 6 && (
            <div className="row mt-5 px-5 mx-5">
              <div className="col-12 f-flex text-end">
                {state.step > 1 && (
                  <button
                    className="btn btn-light text-primary rounded-pill px-4 mx-2"
                    onClick={() =>
                      setState({
                        ...state,
                        step: state.step > 1 && state.step - 1,
                      })
                    }
                  >
                    <ChevronLeft size="16" /> Volver
                  </button>
                )}
                <button
                  disabled={
                    state.online_plan && state.step === 4 ? true : false
                  }
                  className={`btn btn-primary rounded-pill px-4 ${
                    state.online_plan && state.step === 4
                      ? 'buttonFormOnline'
                      : ''
                  }`}
                  onClick={() => handleChangeStep()}
                >
                  Continuar
                </button>
              </div>
            </div>
          )}
          <div className="col-9 mx-auto mt-5">
            {stageFooterArray[state.step]}
          </div>
        </div>
      )}
    </>
  )
}

export default NewViability
