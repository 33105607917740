import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import { HelpCircle, AlertCircle } from 'react-feather'
import './index.css'
import frontal_sonriendo from '../../../../assets/images/frontalsonriendo.png'
import { useSelector } from 'react-redux'

export const StageStepOne = ({ state, setState }) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const [patients, setPatients] = useState()
  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      borderBottom: '2px solid #0027a8!important',
    }),
  }

  const maxillaOptions = [
    { label: 'Superior', value: 2 },
    { label: 'Inferior', value: 1 },
    { label: 'Ambos', value: 3 },
  ]

  const showMaxillaAlert = () => {
    if (!state.selected_patient || !state.maxilla) return
    if (state.selected_patient.maxilarNumber !== state.maxilla.value)
      return (
        <div
          className="col-12 d-flex justify-content-end"
          style={{ marginTop: -30 }}
        >
          <div className="alert alert-danger d-flex p-2">
            <span>
              <AlertCircle size={15} />
            </span>
            <p className="fs-6 ms-1">
              Recuerde que si cambia el
              <br /> maxilar a tratar, esta indicando
              <br /> que el paciente modifica en
              <br /> esta etapa el maxilar que trato
              <br /> en su etapa anterior.
            </p>
          </div>
        </div>
      )
  }

  useEffect(() => {
    if (!state.odontologo) return
    let array = []
    let treatType = null
    state.odontologo.patients.map((patient) => {
      treatType = patient.patient.last_treatment?.type?.id

      array.push({
        label: patient.patient.name + ' ' + patient.patient.surname,
        value: patient.patient.id,
        name: patient.patient.name,
        surname: patient.patient.surname,
        genero: patient.patient.gender,
        maxilarString: patient.patient.last_treatment.maxilla,
        maxilarNumber: patient.patient.last_treatment.maxilla_number,
        treatmentType: patient.patient.last_treatment.type.id,
        treatmentStage: patient.patient.last_treatment.order,
        patientImage: patient.patient.last_treatment?.frontal_sonriendo,
        id: patient.patient.id,
      })
    })
    setPatients(array)
  }, [state.odontologo])

  if (pageWidth === 'mobile')
    return (
      <div className="mt-5 px-4 max-width-600">
        <p className="fs-3 fw-bold text-primary"> Paciente </p>
        <div className="d-flex flex-column align-items-center">
          <img
            src={
              state?.selected_patient?.patientImage
                ? state?.selected_patient?.patientImage
                : frontal_sonriendo
            }
            className="my-4"
            alt="Frontal sonriendo"
            width={200}
            height={200}
            style={{ borderRadius: '100px' }}
          />
          <div>
            <p>Nombre y Apellido</p>
            <Select
              styles={selectStyle}
              options={patients}
              placeholder="Seleccionar Paciente"
              value={state.selected_patient}
              onChange={(e) => {
                setState({
                  ...state,
                  selected_patient: e,
                  nombre: e.name,
                  apellido: e.surname,
                  genero: e.genero,
                  etapa: e.treatmentStage + 1,
                  treatment_type: e.treatmentType,
                  maxilla: {
                    label: e.maxilarString,
                    value: e.maxilarNumber,
                  },
                  maxilar: Number(e.maxilarNumber),
                })
              }}
            />

            <h6 className="my-3">Tratamiento</h6>

            {state.treatment_type === 1 && (
              <div className="col-12 mt-1 ">
                <div
                  className={
                    'btn w-100 my-2 rounded-pill btn-outline-primary active scanButton'
                  }
                >
                  KeepSmiling Full
                </div>
              </div>
            )}
            {state.treatment_type === 4 && (
              <div className="col-12 mt-1 ">
                <div
                  className={
                    'btn w-100 my-2 rounded-pill btn-outline-warning active scanButton'
                  }
                >
                  KeepSmiling Kids
                </div>
              </div>
            )}
            {state.treatment_type === 2 && (
              <div className="col-12 mt-1 ">
                <div
                  className={
                    'btn w-100 my-2 rounded-pill btn-outline-secondary active scanButton'
                  }
                >
                  KeepSmiling Fast
                </div>
              </div>
            )}
            {state.treatment_type === 5 && (
              <div className="col-12 mt-1 ">
                <div
                  className={
                    'btn w-100 my-2 rounded-pill btn-outline-danger active scanButton'
                  }
                >
                  KeepSmiling Kids Fast
                </div>
              </div>
            )}
            {state.treatment_type === 3 && (
              <div className="col-12 mt-1 ">
                <div
                  className={
                    'btn w-100 my-2 rounded-pill btn-outline-info active scanButton'
                  }
                >
                  KeepSmiling Medium
                </div>
              </div>
            )}
            {state.treatment_type === 6 && (
              <div className="col-12 mt-1 ">
                <div
                  className={
                    'btn w-100 my-2 rounded-pill btn-outline-yellow active scanButton'
                  }
                >
                  KeepSmiling Teen
                </div>
              </div>
            )}
            <div className="mt-4 d-flex">
              <div className="col-4 me-2">
                <label htmlFor="etapa">Etapa</label>
                <input
                  disabled
                  name="etapa"
                  id="etapa"
                  className="form-control mt-1"
                  value={state.etapa}
                />
              </div>
              <div className="col-8 mb-3">
                <label htmlFor="maxilar" className="me-2">
                  Maxilar
                </label>
                <Select
                  styles={selectStyle}
                  options={maxillaOptions}
                  isSearchable
                  value={state.maxilla}
                  placeholder={'Seleccionar Maxilar..'}
                  onChange={(e) =>
                    setState({
                      ...state,
                      maxilla: e,
                      maxilar_string: e.label,
                      maxilar: Number(e.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <>
      <div style={{ height: '100%' }}>
        <div className="container mt-2">
          <div className="row mt-4">
            <div className="col-12 mx-auto mb-1">
              <form className="row col-6 mx-auto mt-3">
                <div className="col-12 mb-2">
                  <h4>Paciente</h4>
                </div>
                <div className="col-3 shadow" style={{ height: '100%' }}>
                  <img
                    src={
                      state?.selected_patient?.patientImage
                        ? state?.selected_patient?.patientImage
                        : frontal_sonriendo
                    }
                    alt="Frontal sonriendo"
                    className="avatar_image"
                  />
                </div>
                <div className="col-8 ms-4">
                  <div>
                    <p>Nombre y Apellido</p>
                    <Select
                      styles={selectStyle}
                      options={patients}
                      placeholder="Seleccionar Paciente"
                      value={state.selected_patient}
                      onChange={(e) => {
                        setState({
                          ...state,
                          selected_patient: e,
                          nombre: e.name,
                          apellido: e.surname,
                          genero: e.genero,
                          etapa: e.treatmentStage + 1,
                          treatment_type: e.treatmentType,
                          maxilla: {
                            label: e.maxilarString,
                            value: e.maxilarNumber,
                          },
                          maxilar: Number(e.maxilarNumber),
                        })
                      }}
                    />
                    <div className="col-12 mt-3 d-flex">
                      <h6 className="me-2">Tratamiento</h6>
                      <a data-tip data-for="treatment_tooltip">
                        <HelpCircle size={18} />
                      </a>
                      {/* LOS TOOLTIP SOLO DEBEN MOSTRARSE COMO ODONTOLOGO */}
                      <ReactTooltip id="treatment_tooltip">
                        <div className="col-6 d-flex flex-row">
                          <p className="fs-3 fw-bold">?</p>
                          <p className="mt-2 ms-1">
                            Ayuda <span className="fw-bold">KeepSmiling</span>
                          </p>
                        </div>
                        <div>
                          <p>Tipo de Tratamiento</p>
                          <p>
                            Si tiene dudas del tipo de tratamiento que requiere
                            su paciente,
                            <br /> entonces debe, primero, enviar una solicitud
                            de Viabilidad de
                            <br /> tratamiento. En la cual, el equipo de
                            KeepSmiling evaluara la
                            <br /> documentacion de su paciente y le aconsejara
                            que tipo de
                            <br />
                            tratamiento corresponde al caso de su paciente.
                          </p>
                        </div>
                      </ReactTooltip>
                    </div>
                    {state.treatment_type === 1 && (
                      <div className="col-12 mt-1 ">
                        <div
                          className={
                            'btn w-100 my-2 rounded-pill btn-outline-primary active scanButton'
                          }
                        >
                          KeepSmiling Full
                        </div>
                      </div>
                    )}
                    {state.treatment_type === 4 && (
                      <div className="col-12 mt-1 ">
                        <div
                          className={
                            'btn w-100 my-2 rounded-pill btn-outline-warning active scanButton'
                          }
                        >
                          KeepSmiling Kids
                        </div>
                      </div>
                    )}
                    {state.treatment_type === 2 && (
                      <div className="col-12 mt-1 ">
                        <div
                          className={
                            'btn w-100 my-2 rounded-pill btn-outline-secondary active scanButton'
                          }
                        >
                          KeepSmiling Fast
                        </div>
                      </div>
                    )}
                    {state.treatment_type === 5 && (
                      <div className="col-12 mt-1 ">
                        <div
                          className={
                            'btn w-100 my-2 rounded-pill btn-outline-danger active scanButton'
                          }
                        >
                          KeepSmiling Kids Fast
                        </div>
                      </div>
                    )}
                    {state.treatment_type === 3 && (
                      <div className="col-12 mt-1 ">
                        <div
                          className={
                            'btn w-100 my-2 rounded-pill btn-outline-info active scanButton'
                          }
                        >
                          KeepSmiling Medium
                        </div>
                      </div>
                    )}
                    {state.treatment_type === 6 && (
                      <div className="col-12 mt-1 ">
                        <div
                          className={
                            'btn w-100 my-2 rounded-pill btn-outline-yellow active scanButton'
                          }
                        >
                          KeepSmiling Teen
                        </div>
                      </div>
                    )}
                    <div className="col-12 mt-4">
                      <form className="d-flex col-12">
                        <div className="col-4">
                          <label htmlFor="etapa">Etapa</label>
                          <input
                            disabled
                            name="etapa"
                            id="etapa"
                            className="form-control mt-1"
                            value={state.etapa}
                          />
                        </div>
                        <div className="col-8 ms-2 mb-5">
                          <label htmlFor="maxilar" className="me-2">
                            Maxilar
                          </label>
                          <a data-tip data-for="maxilar_tooltip">
                            <HelpCircle size={18} />
                          </a>
                          {/* LOS TOOLTIP SOLO DEBEN MOSTRARSE COMO ODONTOLOGO */}
                          <ReactTooltip id="maxilar_tooltip">
                            <div className="d-flex p-2">
                              <span>
                                <AlertCircle size={15} />
                              </span>
                              <p className="fs-6 ms-1">
                                Recuerde que si cambia el
                                <br /> maxilar a tratar, esta indicando
                                <br /> que el paciente modifica en
                                <br /> esta etapa el maxilar que trato
                                <br /> en su etapa anterior.
                              </p>
                            </div>
                          </ReactTooltip>
                          <Select
                            styles={selectStyle}
                            options={maxillaOptions}
                            isSearchable
                            value={state.maxilla}
                            placeholder={'Seleccionar Maxilar..'}
                            onChange={(e) =>
                              setState({
                                ...state,
                                maxilla: e,
                                maxilar_string: e.label,
                                maxilar: Number(e.value),
                              })
                            }
                          />
                        </div>
                      </form>
                      {showMaxillaAlert()}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
