import React from 'react'
import { useSelector } from 'react-redux'
import FormOnline from '../../../FormularioOnline'
import DocImage from '../../components/DocImage'
import DocImageInput from '../../components/DocImageInput'
import '../../index.css'

export const TreatmentStepFour = ({
  planFiles,
  setPlanFiles,
  state,
  setState,
  checkFrotalSonriendo,
}) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const handleImageSelect = (image) => {
    let type = image?.type ? 'pdf' : 'image'
    const newFile = { file: image, type }
    setPlanFiles([...planFiles, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newPlanFiles = planFiles
    newPlanFiles.splice(indexToDelete, 1)
    setPlanFiles([...newPlanFiles])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newPlanFiles = planFiles
    newPlanFiles[indexToEdit].file = image
    setPlanFiles([...newPlanFiles])
  }
  if (pageWidth === 'mobile')
    return (
      <div className="mx-4 d-flex flex-wrap gap-2 my-5">
        {planFiles.map((item, index) => (
          <DocImage
            index={index}
            type={item.type}
            image={item.file}
            handleDeletePlan={handleDeletePlan}
            handleEditPlan={handleEditPlan}
          />
        ))}
        {planFiles.length < 5 && (
          <DocImageInput
            inputName="Plan de Tratamiento"
            handleImageSelect={handleImageSelect}
          />
        )}
      </div>
    )
  return (
    <div style={{ height: '100%', overflowX: 'hidden', minHeight: '300px' }}>
      <div className="container mt-2">
        <div className="row d-flex flex-row justify-content-center mx-auto px-1 mb-5">
          <div className="col-6 d-flex flex-row justify-content-center">
            <button
              className={`btn btn-outline-primary border-2 p-4 ${
                state.online_plan && 'disabled bg-primary text-white'
              }`}
              style={{ borderRadius: '15px' }}
              onClick={() =>
                setState({
                  ...state,
                  online_plan: true,
                  image_plan: false,
                })
              }
            >
              Cargar Formulario Online
            </button>
          </div>
          <div
            className="col-6 d-flex flex-row justify-content-center"
            style={{ paddingRight: '50px' }}
          >
            <button
              className={`btn btn-outline-primary border-2 p-4 ${
                state.image_plan && 'disabled bg-primary text-white'
              }`}
              style={{ borderRadius: '15px' }}
              onClick={() =>
                setState({
                  ...state,
                  image_plan: true,
                  online_plan: false,
                })
              }
            >
              Cargar Formulario en Foto
            </button>
          </div>
          {state.online_plan && (
            <div className="col-12">
              <FormOnline
                applicationState={state}
                setState={setState}
                type={state.treatment_type}
                checkFrotalSonriendo={checkFrotalSonriendo}
                nextParentStep={(e) =>
                  setState({ ...state, step: 5, online_form_state: e })
                }
              />
            </div>
          )}
          {state.image_plan && (
            <div className="container my-6 mx-auto">
              <div className="mx-6 d-flex">
                {planFiles.map((item, index) => (
                  <DocImage
                    index={index}
                    type={item.type}
                    image={item.file}
                    handleDeletePlan={handleDeletePlan}
                    handleEditPlan={handleEditPlan}
                  />
                ))}
                {planFiles.length < 5 && (
                  <DocImageInput
                    inputName="Plan de Tratamiento"
                    handleImageSelect={handleImageSelect}
                  />
                )}
              </div>
            </div>
          )}
          <div className="col-12"></div>
        </div>
      </div>
    </div>
  )
}
