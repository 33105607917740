import React from 'react'

const CHOriginalRegister = ({ originalRegister }) => {
  return (
    <div className="mx-4 mt-5">
      {originalRegister?.url && (
        <iframe title="algo" src={originalRegister.url} />
      )}
    </div>
  )
}

export default CHOriginalRegister
