import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import {
  middleLine_up_options,
  middleLine_down_options,
} from '../Utilities/OptionsRadios'
import RadioList from '../Utilities/RadioList'

function MiddleLines(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'MiddleLine'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
  const middleUpLine = middle_state.middleLineHigh
  const middleDownLine = middle_state.middleLineLow
  const middleUpLinemm = middle_state.amountMMMoveHihg
  const middleDownLinemm = middle_state.amountMMMoveLow

  const middleUpLineRefLeft = useRef(null)
  const middleUpLineRefRight = useRef(null)
  const middleDownLineRefLeft = useRef(null)
  const middleDownLineRefRight = useRef(null)
  useEffect(() => {
    if (middleDownLine === 2) {
      middleDownLineRefLeft.current.focus()
    } else if (middleDownLine === 3) {
      middleDownLineRefRight.current.focus()
    } else if (middleDownLine === 1) {
      updateState({ key: 'amountMMMoveLow', value: 0 })
    }
  }, [middleDownLine, updateState])

  console.log(middle_state)

  useEffect(() => {
    if (middleUpLine === 2) {
      middleUpLineRefLeft.current.focus()
    } else if (middleUpLine === 3) {
      middleUpLineRefRight.current.focus()
    } else if (middleUpLine === 1) {
      updateState({ key: 'amountMMMoveHihg', value: 0 })
    }
  }, [middleUpLine, updateState])

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 10)
  const error2 = errors_list.find((err) => err.order === 11)
  useEffect(() => {
    console.log(middleUpLinemm)
    if (
      (middleUpLine === 1 || (middleUpLinemm != 0 && middleUpLinemm < 100)) &&
      errors_list.find((err) => err.order === 10)
    ) {
      updateErrors(errors_list.filter((err) => err.order !== 10))
    }
  }, [middleUpLinemm, updateErrors, errors_list, middleUpLine])

  useEffect(() => {
    console.log(middleDownLinemm)

    if (
      ((middleDownLinemm != 0 && middleDownLinemm < 100) ||
        middleDownLine === 1) &&
      errors_list.find((err) => err.order === 11)
    ) {
      updateErrors(errors_list.filter((err) => err.order !== 11))
    }
  }, [middleDownLinemm, updateErrors, errors_list, middleDownLine])

  return (
    <div className="d-flex">
      <div className="col-6 d-flex flex-column">
        <div
          className="w-90 fw-bold pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Línea Media Superior
        </div>
        {error1 && (
          <div className="alert-danger alert-link p-2 mt-1 me-2">
            {error1.message}
          </div>
        )}
        <div className="d-flex">
          <div className="col-5">
            <RadioList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={middleLine_up_options}
              setter={(value) => updateState({ key: 'middleLineHigh', value })}
              stateField={middleUpLine}
              name="middleUpLine"
            />
          </div>
          <div className={`col-5 d-flex align-items-end`}>
            <div>
              <div className="d-flex align-items-end mb-1">
                <input
                  name="middleUpLine_value"
                  id="middleUpLine_value"
                  style={{ border: `1px solid black` }}
                  type="number"
                  ref={middleUpLineRefLeft}
                  tabIndex="1"
                  maxLength="100"
                  className="small w-25 p-1 rounded"
                  onChange={(e) =>
                    updateState({
                      key: 'amountMMMoveHihg',
                      value: e.target.value,
                    })
                  }
                  disabled={
                    middleUpLine !== 2 || !props.formOnlineReducer.Enabled
                  }
                  value={middleUpLine === 2 ? middle_state.amountMMMoveHihg : 0}
                />
                <small className="h-0 ms-1">mm</small>
              </div>
              <div className="d-flex align-items-end">
                <input
                  name="middleUpLine_value"
                  id="middleUpLine_value"
                  style={{ border: `1px solid black` }}
                  type="number"
                  ref={middleUpLineRefRight}
                  tabIndex="1"
                  maxLength="100"
                  className={`small w-25 p-1 rounded ${
                    middleDownLine === 2 ? 'disabled' : ''
                  }`}
                  onChange={(e) =>
                    updateState({
                      key: 'amountMMMoveHihg',
                      value: e.target.value,
                    })
                  }
                  disabled={
                    middleUpLine !== 3 || !props.formOnlineReducer.Enabled
                  }
                  value={middleUpLine === 3 ? middle_state.amountMMMoveHihg : 0}
                />
                <small className="h-0 ms-1">mm</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 d-flex flex-column">
        <div
          className="w-100 fw-bold pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Línea Media Inferior
        </div>
        {error2 && (
          <div className="alert-danger alert-link p-2 mt-1">
            {error2.message}
          </div>
        )}
        <div className="d-flex">
          <div className="col-5">
            <RadioList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={middleLine_down_options}
              setter={(value) => updateState({ key: 'middleLineLow', value })}
              stateField={middleDownLine}
              name="middleDownLine"
            />
          </div>
          <div className={`col-5 d-flex align-items-end`}>
            <div>
              <div className="d-flex align-items-end mb-1">
                <input
                  name="middleDownLine_value"
                  id="middleDownLine_value"
                  type="number"
                  style={{ border: `1px solid black` }}
                  ref={middleDownLineRefLeft}
                  tabIndex="1"
                  className={`tabIndexl w-25 p-1 rounded`}
                  onChange={(e) =>
                    updateState({
                      key: 'amountMMMoveLow',
                      value: e.target.value,
                    })
                  }
                  disabled={
                    middleDownLine !== 2 || !props.formOnlineReducer.Enabled
                  }
                  value={
                    middleDownLine === 2 ? middle_state.amountMMMoveLow : 0
                  }
                />
                <small className="h-0 ms-1">mm</small>
              </div>
              <div className="d-flex align-items-end">
                <input
                  name="middleDownLine_value"
                  id="middleDownLine_value"
                  type="number"
                  style={{ border: `1px solid black` }}
                  ref={middleDownLineRefRight}
                  tabIndex="1"
                  className={`small w-25 p-1 rounded`}
                  onChange={(e) =>
                    updateState({
                      key: 'amountMMMoveLow',
                      value: e.target.value,
                    })
                  }
                  disabled={
                    middleDownLine !== 3 || !props.formOnlineReducer.Enabled
                  }
                  value={
                    middleDownLine === 3 ? middle_state.amountMMMoveLow : 0
                  }
                />
                <small className="h-0 ms-1">mm</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(MiddleLines)
