import React, { useEffect, useState } from 'react'
import '../styles/styles.css'
import smile from '../../../../assets/images/ks-smile.svg'
import SmileProgressBar from './components/SmileProgressBar'
import { getProfileData } from '../utilities/helpers'
import Loader from '../../../../components/loader'
import apiClient from '../../../../utils/backend'
import { useSelector } from 'react-redux'

const SmileCreatorDetail = () => {
  const [dentistProfile, setDentistProfile] = useState({})
  const [loading, setLoading] = useState(true)
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  const apiCall = async () => {
    try {
      const res = await apiClient('api/smileCreator')
      setDentistProfile(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    apiCall()
  }, [])

  if (loading) return <Loader />
  return (
    <>
      <div className="header d-flex flex-column">
        <img src={smile} alt="smile" />
        <span className="fs-2 fw-bold">Smile Creator</span>
        <p className="fs-5 mt-2">
          Recibí beneficios a partir de la cantidad de tratamientos que realices
        </p>
      </div>
      <div className="pointsDetail d-flex align-items-center p-4 justify-content-between">
        <p className="fs-5">
          <img
            src={getProfileData(dentistProfile.category, true)}
            alt="coin"
            className="coin me-4"
          />
          Actualmente estas en categoria
          <span className="ms-1 mb-0">
            {getProfileData(dentistProfile.category, false)}
          </span>
        </p>
      </div>
      {pageWidth === 'desktop' && (
        <div className="seeDetails mt-4">
          <p className="fs-3 fw-bold text-primary">Detalle de Puntos</p>
          <SmileProgressBar treatmentCant={dentistProfile.total} />
        </div>
      )}
      <div className="pointList mt-3">
        <p className="fw-bold text-primary">Últimos puntos sumados</p>
        <p className="mt-2">
          Estos son los últimos pacientes de etapa 1 en tratamiento:
        </p>
        <div className="mt-5 patientList">
          <div className="d-flex justify-content-between">
            <p>Nombre y Apellido del paciente</p>
            <p>
              Puntos sumados: <strong>{dentistProfile.total}</strong>
            </p>
          </div>
          <hr className="mt-1" />
          {dentistProfile.patients.map((dentist) => (
            <div className="d-flex justify-content-between">
              <p className="ms-2">{`${dentist.user.name} ${dentist.user.surname}`}</p>
              <p className="me-3">+ 1</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SmileCreatorDetail
