import React, { useEffect, useState } from 'react'
import { Trash, X } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Danger from '../assets/images/danger_triangle.png'
import DangerGreen from '../assets/images/danger_green.png'
import { deleteTreatment } from '../redux/actions/odTreatments'
import PropTypes from 'prop-types'
import './styles.css'

const ConfirmationModal = (props) => {
  const {
    type,
    data,
    deleteDentist,
    principalDentist,
    handleDispatch,
    proposalId,
    handlePublish,
  } = props
  const [opened, setOpened] = useState(false)
  const dispatch = useDispatch()

  const handleDeleteTreatment = (id) => {
    dispatch(deleteTreatment(id))
    setOpened(false)
  }
  const handleDispatchTreatment = (id) => {
    handleDispatch(id)
    setTimeout(() => {
      setOpened(false)
    }, 3000)
  }

  const handleType = (type) => {
    const types = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
      'Sin tipo': 1,
      Viabilidad: 1,
    }
    return types[type]
  }

  return (
    <>
      {type === 'treatment-delete' && (
        <>
          <Trash onClick={() => setOpened(!opened)} />
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col-12 flex text-center">
                    <img
                      alt=""
                      src={Danger}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-3 text-black fs-5 fw-bold">
                      ¿Estas seguro que deseas eliminar el tratamiento:
                    </p>
                    <p className="text-black pt-2 fs-6 fw-bold">
                      Paciente: {data?.patient.name} {data?.patient.surname}
                    </p>
                    <p className="text-black fs-6 fw-bold">
                      Doctor: {principalDentist?.name}{' '}
                      {principalDentist?.surname}
                    </p>
                    <p className="text-black fs-6 fw-bold">
                      Tratamiento {data?.treatment} ?
                    </p>
                    <p className="text-black pt-2 fs-6">
                      Se eliminara toda la documentación relacionada a este
                      tratamiento
                    </p>
                    <div className="my-3 mt-4">
                      <button
                        onClick={() => setOpened(!opened)}
                        className="btn btn-outline-dark me-4 rounded-pill px-4"
                      >
                        Volver
                      </button>
                      <button
                        onClick={() => handleDeleteTreatment(data?.id)}
                        className="btn btn-outline-warning rounded-pill px-4"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
      {type === 'dentist-delete' && (
        <>
          <X
            size={15}
            onClick={() => setOpened(!opened)}
            className="text-muted ms-1"
          />
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col-12 flex text-center">
                    <img
                      alt=""
                      src={Danger}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-2 text-black fs-4 fw-bold">
                      ¿Estas seguro que deseas eliminar el dentista:
                    </p>
                    <p className="text-black pt-2 fs-5 fw-bold">
                      {data?.user.name} {data?.user.surname}
                    </p>
                    <p
                      style={{ color: 'red' }}
                      className="text-black pt-2 fs-6"
                    >
                      Se eliminara este dentista del tratamiento seleccionado
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <button
                onClick={() => setOpened(!opened)}
                className="btn btn-outline-dark me-4 rounded-pill px-4"
              >
                Volver
              </button>
              <button
                onClick={() => {
                  deleteDentist(data?.id)
                  setOpened(!opened)
                }}
                className="btn btn-outline-warning rounded-pill px-4"
              >
                Eliminar
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {type === 'clinic-delete' && (
        <>
          <div className="d-flex justify-content-end pe-3 mt-4">
            <button
              className="btn btn-outline-primary rounded-pill deleteBTN"
              onClick={() => setOpened(!opened)}
            >
              Eliminar
            </button>
          </div>
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col-12 flex text-center">
                    <img
                      alt="danger"
                      src={Danger}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-2 text-black fs-4 fw-bold">
                      ¿Estas seguro que deseas eliminar la solicitud:
                    </p>
                    <p className="text-black fs-5 fw-bold">
                      Paciente: {data?.treatment?.patient}
                    </p>
                    <p className="text-black fs-5 fw-bold">
                      Doctor: {data?.dentists[0]?.user?.full_name}
                    </p>
                    <p className="text-black fs-5 fw-bold">
                      Tratamiento:
                      {`${
                        data?.treatment?.type === null
                          ? 'Sin tipo'
                          : data?.treatment?.type?.name
                      }`}
                    </p>
                    <p className="text-black pt-2 fs-6">
                      Se eliminaran toda la documentación relacionada a este
                      tratamiento
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <button
                onClick={() => setOpened(!opened)}
                className="btn btn-outline-dark me-4 rounded-pill px-4"
              >
                Volver
              </button>
              <button
                onClick={() => {
                  handleDeleteTreatment(data.id)
                }}
                className="btn btn-outline-warning rounded-pill px-4"
              >
                Eliminar
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {type === 'formOnline-delete' && (
        <>
          <div className="form-online-dropdown">
            <p
              className="fw-bold text-primary m-0"
              onClick={() => setOpened(!opened)}
            >
              Eliminar
            </p>
          </div>
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col-12 flex text-center">
                    <img
                      alt=""
                      src={Danger}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-2 text-black fs-4 fw-bold">
                      ¿Estas seguro que deseas eliminar el formulario:
                    </p>
                    <p className="text-black fs-5 fw-bold">
                      {/* Paciente: {data?.patient} */}
                    </p>
                    <p className="text-black fs-5 fw-bold">
                      {/* Doctor: {data?.dentists[0]?.user?.full_name} */}
                    </p>
                    <p className="text-black fs-5 fw-bold">
                      {/* Tratamiento: {data?.type?.name} */}
                    </p>
                    <p className="text-black pt-2 fs-6">
                      Se eliminaran toda la documentación relacionada a este
                      tratamiento
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <button
                onClick={() => setOpened(!opened)}
                className="btn btn-outline-dark me-4 rounded-pill px-4"
              >
                Volver
              </button>
              <button
                onClick={() => {
                  // handleDeleteTreatment(data.id)
                }}
                className="btn btn-outline-warning rounded-pill px-4"
              >
                Eliminar
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {type === 'dispatch' && (
        <>
          <div className="dispatchBTN" onClick={() => setOpened(!opened)}>
            <span className="m-0">Despachado</span>
          </div>
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container p-2">
                <div className="row">
                  <div className="col-12 flex text-center d-flex flex-column align-items-center">
                    <img
                      alt=""
                      src={DangerGreen}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-2 text-black fs-6 fw-bold">
                      Esta seguro que desea indicar el despacho del tratamiento:
                    </p>
                    <div className="patientConfirmationModalCard">
                      <img
                        src={data.patient?.avatar}
                        alt="avatar"
                        className="cardImage me-1"
                      />
                      <div className="patientCardData">
                        <div className="patientCardNames">
                          <span
                            className="text-primary fs-6 text-truncate"
                            title={`${data.patient.name} ${data.patient.surname}`}
                          >{`${data.patient.name} ${data.patient.surname}`}</span>
                          <div
                            className={`treatmentCardConfirmation${handleType(
                              data.treatment
                            )}`}
                          >
                            {`${
                              data.treatment === null
                                ? '-'
                                : data.treatment.toUpperCase()
                            }`}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2 doctorNames">
                          <div className="d-flex flex-column align-items-start">
                            <span className="fw-bold">Doctor:</span>
                            <span
                              className="fw-bold "
                              styles={{ fontSize: '14px' }}
                            >{`${principalDentist[0].user.name} ${principalDentist[0].user.surname}`}</span>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <span className="fw-bold">Maxillar:</span>
                            <span
                              className="fw-bold "
                              styles={{ fontSize: '14px' }}
                            >{`${
                              data.maxilla === null ? '-' : data.maxilla
                            }`}</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-end">
                        <div className="rounded-pill stageBTN">
                          {`ETAPA ${data.stage}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <button
                onClick={() => setOpened(!opened)}
                className="btn btn-outline-dark me-4 rounded-pill px-4"
              >
                Volver
              </button>
              <button
                onClick={() => {
                  handleDispatchTreatment(proposalId)
                }}
                className="btn btn-outline-success rounded-pill px-4"
              >
                Despachar
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
      {type === 'publishProcess' && (
        <>
          <div className="dispatchFather pe-3 ">
            <div
              className="publicateBTNControl ms-3"
              onClick={() => setOpened(!opened)}
            >
              <p className="m-0">Publicar</p>
            </div>
          </div>
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container p-2">
                <div className="row">
                  <div className="col-12 flex text-center d-flex flex-column align-items-center">
                    <img
                      alt=""
                      src={DangerGreen}
                      className="mt-1"
                      width={80}
                      height={80}
                    />
                    <p className="mt-2 text-black fs-6 fw-bold">
                      Esta seguro que desea publicar la propuesta de
                      tratamiento:
                    </p>
                    <div className="patientConfirmationModalCard">
                      <img
                        src={data.treatment.frontal_sonriendo}
                        alt="avatar"
                        className="cardImage me-1"
                      />
                      <div className="patientCardData">
                        <div className="patientCardNames">
                          <span
                            className="text-primary fs-6 text-truncate"
                            title={`${data.treatment.patient.name} ${data.treatment.patient.surname}`}
                          >{`${data.treatment.patient.name} ${data.treatment.patient.surname}`}</span>
                          <div
                            className={`treatmentCardConfirmation${
                              data.treatment?.type !== null
                                ? data.treatment?.type?.id
                                : 1
                            }`}
                          >
                            {data.treatment?.type !== null
                              ? data.treatment.type.name.toUpperCase()
                              : '-'}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2 doctorNames">
                          <div className="d-flex flex-column align-items-start">
                            <span className="fw-bold">Doctor:</span>
                            <span
                              className="fw-bold "
                              styles={{ fontSize: '14px' }}
                            >{`${principalDentist[0].user.full_name}`}</span>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <span className="fw-bold">Maxillar:</span>
                            <span
                              className="fw-bold "
                              styles={{ fontSize: '14px' }}
                            >{`${
                              data.treatment.maxilla === null
                                ? '-'
                                : data.treatment.maxilla
                            }`}</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-end">
                        <div className="rounded-pill stageBTN">{`ETAPA ${data.treatment.order}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <button
                onClick={() => setOpened(!opened)}
                className="btn btn-outline-dark me-4 rounded-pill px-4"
              >
                Volver
              </button>
              <button
                onClick={() => {
                  handlePublish(proposalId)
                  setTimeout(() => {
                    setOpened(!opened)
                  }, 3000)
                }}
                className="btn btn-outline-success rounded-pill px-4"
              >
                Publicar
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

ConfirmationModal.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  deleteDentist: PropTypes.func,
  principalDentist: PropTypes.object,
  handleDispatch: PropTypes.func.isRequired,
  proposalId: PropTypes.number.isRequired,
  handlePublish: PropTypes.func,
}

export default ConfirmationModal
