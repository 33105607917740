import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../utils/backend'

const useQualityControl = () => {
  const { countryId } = useSelector((state) => state.userReducer)
  const [state, setState] = useState({
    loading: true,
    treatments: [],
  })

  const paginatorHandler = async (page) => {
    let url = `api/proposals?filter[show]=0&filter[type]=video&include=treatment&page=${page}&filter[treatment.patient.user.country.id]=${countryId}`
    if (state.searchQuery)
      url = url + `&filter[${state.currentFilterType}]=${state.searchQuery}`

    try {
      const res = await apiClient.get(url)
      setState({
        ...state,
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getInitialData = async (country) => {
    try {
      let url = `api/proposals?filter[show]=0&filter[type]=video&include=treatment&page=1&filter[treatment.patient.user.country.id]=${country}`
      const res = await apiClient.get(url)
      setState({
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!state.loadTodosFilter) return
    getInitialData(countryId)
  }, [state, countryId])

  useEffect(() => {
    getInitialData(countryId)
  }, [countryId])
  return {
    state,
    setState,
    getInitialData,
    paginatorHandler,
    countryId,
  }
}

export default useQualityControl
