import React from 'react'
import claimModal from '../styles/claimModal.module.css'
import PropTypes from 'prop-types'

const DocumentacionIncompletaCard = ({ flags }) => {
  const { studies, photos, plan } = flags

  return (
    <div className={claimModal.cardContainer}>
      <p className={claimModal.redTitle}>Documentación Incompleta</p>
      <div className="d-flex">
        <div className={studies ? claimModal.flagsTag : claimModal.redFlagsTag}>
          Estudios
        </div>
        <div className={photos ? claimModal.flagsTag : claimModal.redFlagsTag}>
          Fotos
        </div>
        <div className={plan ? claimModal.flagsTag : claimModal.redFlagsTag}>
          Plan
        </div>
      </div>
    </div>
  )
}

DocumentacionIncompletaCard.propTypes = {
  flags: PropTypes.object.isRequired,
}

export default DocumentacionIncompletaCard
