import React from 'react'
import styles from '../styles/stepOne.module.css'

const StepOne = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className="d-flex flex-column align-items-center mx-auto">
        <p className={styles.title}>
          ¡Bienvenido al nuevo portal de KeepSmiling!
        </p>
        <p className={styles.text}>
          Para comenzar con la nueva plataforma le pedimos que complete los
          siguientes pasos solamente le llevará unos minutos.
        </p>
        <p className={styles.watch}>⏱</p>
        <p className={styles.startButton}>¡Empecemos!</p>
      </div>
      <div className="d-flex justify-content-end w-100">
        <button className={styles.nextButton} onClick={() => setStep(2)}>
          Siguiente
        </button>
      </div>
    </div>
  )
}

export default StepOne
