import axios from 'axios'
import { url } from './url'

const apiClient = axios.create({
  baseURL: url(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem('lgt')}`,
  },
})
apiClient.defaults.headers.post['Content-Type'] = 'application/json'

export default apiClient
