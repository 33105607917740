import React, { useState } from 'react'
import {Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Filter } from 'react-feather';

const FiltersModal = (props) => {

  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false)

  function handleModal(){
    setOpened(!opened)
  }

  function handleRegistry(){
    
  }

    return (
      <div className="d-flex flex-row justify-content-end">
      <button onClick={handleModal} className="btn bg-none p-0"><Filter size={24} color="#000" /></button>
        <Modal size="lg" isOpen={opened} toggle={handleModal} className="comunications_modal">
          <ModalBody>
            <div className="container">
              <h4 className="fw-bold text-primary">Filtros y estadisticas</h4>
              <div className="row mt-4">
                <div className="col-3"><input type="date" name="start" id="start" className="form-control" /></div>
                <div className="col-3"><input type="date" name="finish" id="finish" className="form-control" /></div>
                <div className="col-3"><Select /></div>
                <div className="col-3"><button className="btn btn-outline-primary px-4 rounded-pill">Aplicar</button></div>
              </div>
              <div className="row">
                <div className="col-2 p-3">
                  <div className="py-3 w-100 d-flex flex-column text-center" style={{borderRadius: '20px', border: '2px solid #002E50'}}>
                    <span>Todos:</span>
                    <h2 className="fw-bold">20</h2>
                  </div>
                </div>
                <div className="col-2 p-3">
                  <div className="py-3 w-100 d-flex flex-column text-center" style={{borderRadius: '20px', border: '2px solid #1D9A5F'}}>
                    <span>Resuelto:</span>
                    <h2 className="fw-bold">20</h2>
                  </div>
                </div>
                <div className="col-2 p-3">
                  <div className="py-3 w-100 d-flex flex-column text-center" style={{borderRadius: '20px', border: '2px solid #FFED46'}}>
                    <span>Pendiente:</span>
                    <h2 className="fw-bold">20</h2>
                  </div>
                </div>
                <div className="col-2 p-3">
                  <div className="py-3 w-100 d-flex flex-column text-center" style={{borderRadius: '20px', border: '2px solid #FFBB00'}}>
                    <span>En espera:</span>
                    <h2 className="fw-bold">20</h2>
                  </div>
                </div>
                <div className="col-2 p-3">
                  <div className="py-3 w-100 d-flex flex-column text-center" style={{borderRadius: '20px', border: '2px solid #EF64F3'}}>
                    <span>Req. seg.:</span>
                    <h2 className="fw-bold">20</h2>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

            {!loading &&
              <div>
                <button className="btn btn-primary px-5 text-white rounded-pill" onClick={handleRegistry}>Enviar</button>
              </div>
            }

            {loading &&
              <button class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
              </button>
            }

          </ModalFooter>
        </Modal>
      </div>
    );
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(FiltersModal)
