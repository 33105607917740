import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useHistory } from 'react-router'
import './index.css'
import { TreatmentLogo } from '../components/TreatmentLogo'
import { ImageUploadReference } from '../components/styled'
import trescuartos_perfil from '../../../../assets/images/34deperfilsonriendo.png'
import anterioroclusion from '../../../../assets/images/anteriorenoclusion.png'
import anteriorinoclusion from '../../../../assets/images/anterioreninoclusion.png'
import bucalderecho from '../../../../assets/images/bucalderecho.png'
import bucalizquierdo from '../../../../assets/images/bucalizquierdo.png'
import frontalreposo from '../../../../assets/images/frontalenreposo.png'
import frontalsonriendo from '../../../../assets/images/frontalsonriendo.png'
import oclusalmaxilarinf from '../../../../assets/images/oclusaldelmaxilarinferior.png'
import oclusalmaxilarsup from '../../../../assets/images/oclusaldelmaxilarsuperior.png'
import perfilizqreposo from '../../../../assets/images/perfilizquierdoenreposo.png'
import radioperfil from '../../../../assets/images/telerradiografiadeperfil.png'
import ricketts from '../../../../assets/images/cefalogramadericketts.png'
import rxpanoramica from '../../../../assets/images/rxpanoramica.png'
import apiClient from '../../../../utils/backend'
import { useDispatch, useSelector } from 'react-redux'
import handleToast from '../../../../components/toaster'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { baseToFile } from '../../../../utils/baseToFile'

export const TreatmentStepSix = ({
  planFiles,
  state,
  setState,
  stageTwoFinish,
  stageThreeFinish,
  stageFourFinish,
  checkFrotalSonriendo,
  photosFiles,
  studiesFiles,
}) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const [treatmentId, setTreatmentId] = useState(null)
  const [load, setLoad] = useState(false)
  const [step2, setstep2] = useState(false)
  const [step3, setstep3] = useState(false)
  const [step4, setstep4] = useState(false)
  const [flags, setFlags] = useState({
    photos: null,
    studies: null,
    plan: null,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const today = moment()
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const imageStyle = {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    margin: '2px',
  }
  const imageStyleOpacity = {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    margin: '2px',
    opacity: '.5',
  }

  const displayPlanInfo = () => {
    if (planFiles.length) {
      return (
        <div className="mt-3 d-flex justify-content-center align-items-center flex-column">
          {planFiles[0].type === 'image' ? (
            <ImageUploadReference src={planFiles[0].file} />
          ) : (
            <div
              style={{
                height: '100px',
                width: '100px',
                overflow: 'hidden',
              }}
            >
              <Document
                file={planFiles[0].file}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page height={125} scale={1} pageNumber={1} />
              </Document>
            </div>
          )}
          {planFiles.length > 1 && (
            <p className=" my-2 p-2 plan-plus fw-bold text-primary shadow">
              +{planFiles.length - 1}
            </p>
          )}
        </div>
      )
    }
    if (state.online_form_state)
      return (
        <div className="btn btn-primary scanButton fs-6">Formulario Online</div>
      )
    return <div className="h-75 border border-1"></div>
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({
      ...state,
      numPages: numPages,
    })
  }

  const handleRedirectIncome = () => {
    handleToast('error', 'Los datos no se guardaron')
    history.push('/od/home')
  }

  const handleSendTreatment = async () => {
    setLoad(true)
    const dataStepOne = {
      name: state.nombre,
      surname: state.apellido,
      gender: state.genero,
      dentist_id: state.dentist_id,
      maxilla: state.maxilar,
      type_id: state.treatment_type,
      intraoral: state.intraoral,
      registration_date: today.format(),
    }
    // Send step 1 Information
    await apiClient
      .post(`api/incomes/information`, dataStepOne)
      .then((res) => {
        setTreatmentId(res.data.data.id)
        handleToast('updating', 'Guardando tratamiento..')
      })
      .catch((err) => handleToast('error', 'Error en la informacion ingresada'))
  }

  const sendFlags = () => {
    //Send Flags
    apiClient
      .patch(`api/incomes/${treatmentId}`, flags)
      .then((res) => console.log(res))
      .catch((err) => handleToast('error', 'Error en el envio de flags'))
  }

  const sendStepTwo = () => {
    Object.entries(state.fotos).forEach((entry, index) => {
      const [key, value] = entry
      if (value !== null) {
        // Normalize images
        const file = baseToFile(value, key + '.jpg')
        let dataStepTwo = new FormData()
        dataStepTwo.append('image', file)
        dataStepTwo.append('name', key)
        // Send step 2 images
        apiClient
          .post(`api/incomes/${treatmentId}/images`, dataStepTwo, headers)
          .then((res) => {
            if (index + 1 === Object.keys(state.fotos).length) {
              setstep2(true)
            }
          })
      }
    })

    // other images
    if (photosFiles.length === 0) return setstep2(true)
    Promise.all(
      photosFiles.map(async (item, index) => {
        const file = baseToFile(item.file, `.jpg`)
        let otherPhotos = new FormData()
        otherPhotos.append('image', file)
        otherPhotos.append('name', `Otros-${index + 1}`)
        otherPhotos.append('type', `image`)
        try {
          await apiClient.post(`api/incomes/${treatmentId}/others`, otherPhotos)
        } catch (error) {
          handleToast('error', 'Error cargando otrosPhotos..')
          console.log(error)
        }
      })
    ).then(() => setstep2(true))
  }

  const sendStepThree = () => {
    Object.entries(state.estudios).forEach((entry, index) => {
      const [key, value] = entry
      if (value !== null) {
        // Normalize Images
        const file = baseToFile(value, key + '.jpg')
        let dataStepThree = new FormData()
        dataStepThree.append('study', file)
        dataStepThree.append('name', key)
        // Send Study Images
        apiClient
          .post(`api/incomes/${treatmentId}/studies`, dataStepThree, headers)
          .then((res) => {
            if (index + 1 === Object.keys(state.estudios).length) {
              setstep3(true)
            }
          })
      }
    })
    // others study
    if (studiesFiles.length === 0) return setstep3(true)
    Promise.all(
      studiesFiles.map(async (item, index) => {
        const file = baseToFile(item.file, `.jpg`)
        let otherStudies = new FormData()
        otherStudies.append('image', file)
        otherStudies.append('name', `Otros-${index + 1}`)
        otherStudies.append('type', `study`)
        try {
          await apiClient.post(
            `api/incomes/${treatmentId}/others`,
            otherStudies
          )
        } catch (error) {
          handleToast('error', 'Error cargando otrosStudies..')
          console.log(error)
        }
      })
    ).then(() => setstep3(true))
  }

  const sendStepFourOnline = () => {
    // Set images
    const files =
      state.online_form_state.InstructionsTratment.GeneralInstructions
        .attachmentFile
    // Normalize Online form data
    const form_online = { form_online: state.online_form_state }
    delete form_online.form_online.InstructionsTratment.GeneralInstructions
      .attachmentFile

    // Send online form Info
    apiClient
      .post(`api/incomes/${treatmentId}/plan`, form_online, headers)
      .then((res) => {
        sentStepFourOnlineImages(files)
        setTimeout(() => {
          setstep4(true)
        }, 3000)
      })
      .catch((err) => console.log(err, 'Error loading form online'))
  }

  const sentStepFourOnlineImages = (files) => {
    if (files.length > 0) {
      files.forEach((Attachedfile, key) => {
        //Normalize Online form images
        const fileimg = baseToFile(Attachedfile.file, key)
        let formOnlineFile = new FormData()
        formOnlineFile.append('file', fileimg)

        // Send online form Images
        apiClient
          .post(
            `api/incomes/${treatmentId}/plan/formOnline`,
            formOnlineFile,
            headers
          )
          .then((res) => console.log(res))
      })
    }
  }

  const sendStepFourFile = () => {
    Promise.all(
      planFiles.map(async (item, index) => {
        let file

        // Validate if is PDF or IMG
        if (item.type === 'pdf') file = item.file
        else file = baseToFile(item.file, 'plan.jpg')

        //Normalize Data
        let dataStepFour = new FormData()
        dataStepFour.append('plan', file)
        dataStepFour.append('id_file', index)

        //Send plan file
        try {
          await apiClient.post(`api/incomes/${treatmentId}/plan`, dataStepFour)
        } catch (error) {
          handleToast('error', 'Error cargando el plan..')
          console.log(error)
        }
      })
    ).then(() => {
      setstep4(true)
    })
  }

  useEffect(() => {
    if (!treatmentId) return
    //SET FLAGS
    sendFlags()
    //STEP 2
    sendStepTwo()

    //STEP 3
    sendStepThree()

    //STEP 4
    if (state.online_form_state) sendStepFourOnline()
    else if (planFiles.length > 0) sendStepFourFile()
    else setstep4(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentId])

  useEffect(() => {
    if (!step2 || !step3 || !step4) return

    handleToast('success', 'Guardado correctamente')
    setTimeout(() => history.push('/od/home'), 2000)
  }, [history, step2, step3, step4])

  useEffect(() => {
    setFlags({
      photos: stageTwoFinish,
      studies: stageThreeFinish,
      plan: stageFourFinish,
    })
  }, [stageFourFinish, stageThreeFinish, stageTwoFinish])

  useEffect(
    () => dispatch({ type: 'clear_form_online', payload: {} }),
    [dispatch]
  )

  if (pageWidth === 'mobile')
    return (
      <div className="d-flex flex-column align-items-center px-3">
        <div className="max-width-600 w-100 central_container px-3 d-flex flex-column align-items-center position-relative shadow-lg my-5">
          <TreatmentLogo treatment_type={state.treatment_type} />
          <img
            src={checkFrotalSonriendo()}
            alt="Frontal sonriendo"
            className="my-5"
            style={{ borderRadius: 130 }}
            width={250}
            height={250}
          />

          <p>Paciente:</p>
          <h5 className="fw-bold text-truncate mb-4 stepsix-patientName">
            {state?.nombre + ' ' + state?.apellido}
          </h5>
          <p>Doctor:</p>
          <h5 className="fw-bold text-truncate mb-4">
            {state?.odontologo.name + ' ' + state?.odontologo.surname}
          </h5>
          <p>Maxilar:</p>
          <h5 className="fw-bold mb-4">{state?.maxilla.label}</h5>

          <p>Fotos:</p>
          <div className="d-flex justify-content-around flex-wrap w-50 mb-4">
            <img
              style={
                state?.frontal_reposo !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.frontal_reposo ? state?.frontal_reposo : frontalreposo
              }
              alt="Frontal en Reposo"
              title="Frontal en Reposo"
            />
            <img
              style={
                state?.frontal_sonriendo !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.frontal_sonriendo
                  ? state?.frontal_sonriendo
                  : frontalsonriendo
              }
              alt=""
              title="Frontal Sonriendo"
            />
            <img
              style={
                state?.perfil_izq_reposo !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.perfil_izq_reposo
                  ? state?.perfil_izq_reposo
                  : perfilizqreposo
              }
              alt=""
              title="Perfil Izquierdo en Reposo"
            />
            <img
              style={
                state?.trescuartos_perfil !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.trescuartos_perfil
                  ? state?.trescuartos_perfil
                  : trescuartos_perfil
              }
              alt=""
              title="Tres cuartos de perfil"
            />
            <img
              style={
                state?.oclusal_maxilar_sup !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.oclusal_maxilar_sup
                  ? state?.oclusal_maxilar_sup
                  : oclusalmaxilarsup
              }
              alt=""
              title="Oclusal Maxilar Superior"
            />
            <img
              style={
                state?.oclusal_maxilar_inf !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.oclusal_maxilar_inf
                  ? state?.oclusal_maxilar_inf
                  : oclusalmaxilarinf
              }
              alt=""
              title="Oclusal Maxilar Inferior"
            />
            <img
              style={
                state?.anterior_oclusion !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.anterior_oclusion
                  ? state?.anterior_oclusion
                  : anterioroclusion
              }
              alt=""
              title="Anterior en Oclusion"
            />
            <img
              style={
                state?.bucal_derecho !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={state?.bucal_derecho ? state?.bucal_derecho : bucalderecho}
              alt=""
              title="Bucal Derecho"
            />
            <img
              style={
                state?.bucal_izquierdo !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.bucal_izquierdo ? state?.bucal_izquierdo : bucalizquierdo
              }
              alt=""
              title="Bucal Izquierdo"
            />
            <img
              style={
                state?.anterior_inoclusion !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.anterior_inoclusion
                  ? state?.anterior_inoclusion
                  : anteriorinoclusion
              }
              alt=""
              title="Anterior en Inoclusion"
            />

            {photosFiles.length > 1 && (
              <p className=" my-2 ms-2 p-2 plan-plus fw-bold text-primary shadow">
                +{photosFiles.length}
              </p>
            )}
          </div>
          <p>Estudios:</p>
          <div className="d-flex justify-content-around flex-wrap w-50 mb-4">
            <img
              style={
                state?.rxpanoramica !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={state?.rxpanoramica ? state?.rxpanoramica : rxpanoramica}
              alt=""
              title="RX Panoramica"
            />
            <img
              style={
                state?.telerradiografia !== undefined
                  ? imageStyle
                  : imageStyleOpacity
              }
              src={
                state?.telerradiografia ? state?.telerradiografia : radioperfil
              }
              alt=""
              title="Telerradiografia"
            />
            <img
              style={
                state?.ricketts !== undefined ? imageStyle : imageStyleOpacity
              }
              src={state?.ricketts ? state?.ricketts : ricketts}
              alt=""
              title="Ricketts"
            />

            {studiesFiles.length > 1 && (
              <p className=" my-2 ms-2 p-2 plan-plus fw-bold text-primary shadow">
                +{studiesFiles.length}
              </p>
            )}
          </div>

          <p>Plan de tratamiento:</p>
          <div style={{ width: 150, height: 200 }}>{displayPlanInfo()}</div>

          <p>Envio de registro:</p>
          <div className="scanButton mb-5">
            <p className="scanButtonText px-4">
              {state.intraoral_file
                ? 'Escaneo intraoral'
                : 'Envio de impresiones'}
            </p>
            {state.intraoral === 1 && (
              <p className="my-4 px-4">
                {state.isWeTransfer ? 'We Transfer' : '3Shape Communicate'}
              </p>
            )}
          </div>
          <p>Pendientes:</p>
          <div className="mb-5">
            <button
              className={`btn border fw-light border-3 border-danger rounded  my-2 ${
                flags.photos ? 'btn-outline-warning' : 'btn-danger text-white'
              }`}
            >
              Fotos
            </button>
            <button
              className={`btn border fw-light border-3 border-danger rounded  ms-2 my-2 ${
                flags.studies ? 'btn-outline-warning' : 'btn-danger text-white'
              }`}
            >
              Estudios
            </button>
            <button
              className={`btn border fw-light border-3 border-danger rounded  ms-2 my-2 ${
                flags.plan ? 'btn-outline-warning' : 'btn-danger text-white'
              }`}
            >
              Plan
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-wrap ">
          {load ? (
            <button className="btn btn-primary rounded-pill mt-4 px-6 py-3 mx-3 disabled">
              <Spinner size={'sm'} color="white"></Spinner>
            </button>
          ) : (
            <button
              onClick={() => handleSendTreatment()}
              className="btn btn-primary rounded-pill mt-4 px-5 py-3 mx-3"
            >
              Guardar
            </button>
          )}
          <button
            onClick={() => handleRedirectIncome()}
            className="btn volver rounded-pill mt-4 px-5 py-3 mx-3"
          >
            Cancelar
          </button>
        </div>

        {load && (
          <div className="text-center">
            <p className="my-2 fw-bold red">
              Guardando tratamiento, esto puede llevar unos segundos...
            </p>
          </div>
        )}
      </div>
    )

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="row mt-5 mx-auto">
        <div className="col-12 d-flex text-center">
          <div className="container central_container shadow-lg mx-5 px-5 py-4 position-relative">
            <div className="row text-start d-flex flex-row align-items-center">
              <div className="col-2">
                <div className="circulo" style={{ overflow: 'hidden' }}>
                  <img
                    src={checkFrotalSonriendo()}
                    alt="Frontal sonriendo"
                    className="avatar_image"
                  />
                </div>
              </div>
              <div className="col-3">
                <p>Paciente:</p>
                <h5 className="fw-bold text-truncate">
                  {state?.nombre + ' ' + state?.apellido}
                </h5>
              </div>
              <div className="col-3">
                <p>Doctor:</p>
                <h5 className="fw-bold text-truncate">
                  {state?.odontologo.name + ' ' + state?.odontologo.surname}
                </h5>
              </div>
              <div className="col-1 text-start">
                <p>Maxilar:</p>
                <h5 className="fw-bold text-truncate">
                  {state?.maxilar_string}
                </h5>
              </div>
              <div className="col-2 d-flex justify-content-center">
                {state?.external_id && (
                  <div className="col-2">
                    <p>ID: {state?.external_id}</p>
                  </div>
                )}
                {state.treatment_type === 1 ||
                  (state.treatment_type === 2 && (
                    <div style={{ cursor: 'default' }}>
                      <button className="btn-sm step px-4 py-2 ">
                        {state?.etapa === 1
                          ? 'Etapa 1'
                          : 'Etapa ' + state?.etapa}
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <TreatmentLogo treatment_type={state.treatment_type} />
            <hr />
            <div className="row text-start">
              <div className="col-3 pe-3">
                <p>Fotos:</p>
                <div className="d-flex flex-row flex-wrap">
                  <img
                    style={
                      state?.frontal_reposo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.frontal_reposo
                        ? state?.frontal_reposo
                        : frontalreposo
                    }
                    alt="Frontal en Reposo"
                    title="Frontal en Reposo"
                  />
                  <img
                    style={
                      state?.frontal_sonriendo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.frontal_sonriendo
                        ? state?.frontal_sonriendo
                        : frontalsonriendo
                    }
                    alt=""
                    title="Frontal Sonriendo"
                  />
                  <img
                    style={
                      state?.perfil_izq_reposo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.perfil_izq_reposo
                        ? state?.perfil_izq_reposo
                        : perfilizqreposo
                    }
                    alt=""
                    title="Perfil Izquierdo en Reposo"
                  />
                  <img
                    style={
                      state?.trescuartos_perfil !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.trescuartos_perfil
                        ? state?.trescuartos_perfil
                        : trescuartos_perfil
                    }
                    alt=""
                    title="Tres cuartos de perfil"
                  />
                  <img
                    style={
                      state?.oclusal_maxilar_sup !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.oclusal_maxilar_sup
                        ? state?.oclusal_maxilar_sup
                        : oclusalmaxilarsup
                    }
                    alt=""
                    title="Oclusal Maxilar Superior"
                  />
                  <img
                    style={
                      state?.oclusal_maxilar_inf !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.oclusal_maxilar_inf
                        ? state?.oclusal_maxilar_inf
                        : oclusalmaxilarinf
                    }
                    alt=""
                    title="Oclusal Maxilar Inferior"
                  />
                  <img
                    style={
                      state?.anterior_oclusion !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.anterior_oclusion
                        ? state?.anterior_oclusion
                        : anterioroclusion
                    }
                    alt=""
                    title="Anterior en Oclusion"
                  />
                  <img
                    style={
                      state?.bucal_derecho !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.bucal_derecho ? state?.bucal_derecho : bucalderecho
                    }
                    alt=""
                    title="Bucal Derecho"
                  />
                  <img
                    style={
                      state?.bucal_izquierdo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.bucal_izquierdo
                        ? state?.bucal_izquierdo
                        : bucalizquierdo
                    }
                    alt=""
                    title="Bucal Izquierdo"
                  />
                  <img
                    style={
                      state?.anterior_inoclusion !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.anterior_inoclusion
                        ? state?.anterior_inoclusion
                        : anteriorinoclusion
                    }
                    alt=""
                    title="Anterior en Inoclusion"
                  />

                  {photosFiles.length > 1 && (
                    <p className=" my-2 ms-2 p-2 plan-plus fw-bold text-primary shadow">
                      +{photosFiles.length}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-1 pe-3">
                <p>Estudios:</p>
                <div className="d-flex flex-colum flex-wrap justify-content-left">
                  <img
                    style={
                      state?.rxpanoramica !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.rxpanoramica ? state?.rxpanoramica : rxpanoramica
                    }
                    alt=""
                    title="RX Panoramica"
                  />
                  <img
                    style={
                      state?.telerradiografia !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.telerradiografia
                        ? state?.telerradiografia
                        : radioperfil
                    }
                    alt=""
                    title="Telerradiografia"
                  />
                  <img
                    style={
                      state?.ricketts !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={state?.ricketts ? state?.ricketts : ricketts}
                    alt=""
                    title="Ricketts"
                  />

                  {studiesFiles.length > 1 && (
                    <p className=" my-2 ms-2 p-2 plan-plus fw-bold text-primary shadow">
                      +{studiesFiles.length}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-2 pe-4">
                <p>Plan de Tratamiento:</p>
                {displayPlanInfo()}
              </div>
              <div className="col-2 pe-2">
                <p>Envio de Registro:</p>
                <div className="scanButton">
                  <p className="scanButtonText">
                    {state.intraoral_file
                      ? 'Escaneo Intraoral'
                      : 'Envío de Impresiones'}
                  </p>
                  {state.intraoral === 1 && (
                    <p className="my-4">
                      {state.isWeTransfer
                        ? 'We Transfer'
                        : '3Shape Communicate'}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-4 px-2">
                <p>Pendientes:</p>
                <div className="">
                  <button
                    className={`btn border fw-light border-3 border-danger rounded  my-2 ${
                      flags.photos
                        ? 'btn-outline-warning'
                        : 'btn-danger text-white'
                    }`}
                  >
                    Fotos
                  </button>
                  <button
                    className={`btn border fw-light border-3 border-danger rounded  ms-2 my-2 ${
                      flags.studies
                        ? 'btn-outline-warning'
                        : 'btn-danger text-white'
                    }`}
                  >
                    Estudios
                  </button>
                  <button
                    className={`btn border fw-light border-3 border-danger rounded  ms-2 my-2 ${
                      flags.plan
                        ? 'btn-outline-warning'
                        : 'btn-danger text-white'
                    }`}
                  >
                    Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6 d-flex flex-row justify-content-end">
          <button
            onClick={() => handleRedirectIncome()}
            className="btn volver rounded-pill px-5 py-3 mx-3"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6 d-flex flex-row justify-content-start">
          {load ? (
            <button className="btn btn-primary rounded-pill px-6 py-3 mx-3 disabled">
              <Spinner size={'sm'} color="white"></Spinner>
            </button>
          ) : (
            <button
              onClick={() => handleSendTreatment()}
              className="btn btn-primary rounded-pill px-5 py-3 mx-3"
            >
              Guardar
            </button>
          )}
        </div>
      </div>
      {load && (
        <div className="text-center">
          <p className="my-2 fw-bold red">
            Guardando tratamiento, esto puede llevar unos segundos...
          </p>
        </div>
      )}
    </div>
  )
}
