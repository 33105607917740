import React from 'react'
import DeleteModal from './delete_modal'
import EditPatient from './edit_patient_modal'
import saologo from '../../../assets/images/sao_logo.png'
import ubalogo from '../../../assets/images/UBA.png'
import imagePlaceholder from '../../../assets/images/frontalsonriendo.png'
import './index.css'
import CopyToClipboard from 'react-copy-to-clipboard'
import handleToast from '../../../components/toaster'

function PatientCard({ patient, setInitialData }) {
  const principalDentist = patient.treatments[0]?.dentists?.find(
    (dentist) => dentist.id === patient.treatments[0]?.principal_dentist
  )

  return (
    <div className="row px-3 cursor-pointer">
      <div
        className="col-3 d-flex flex-row justify-content-left px-2"
        /*  onClick={() => handleOpenMR(patient)} */
      >
        <div className="avatarBorder mx-2 my-1">
          <img
            alt="patient"
            src={
              patient.treatments[0]?.frontal_sonriendo
                ? patient.treatments[0]?.frontal_sonriendo
                : imagePlaceholder
            }
            className="avatarIMG"
          />
        </div>
        <CopyToClipboard text={`${patient?.name} ${patient?.surname}`}>
          <p
            title={`${patient?.name} ${patient?.surname}`}
            onClick={() => handleToast('success', '¡Nombre copiado!', 1500)}
            className="mb-0 text-truncate hover-bold cursor-pointer"
            style={{ lineHeight: '38px' }}
          >
            {patient?.name} {patient?.surname}
          </p>
        </CopyToClipboard>
      </div>
      <div
        className="col-3 d-flex flex-row justify-content-center"
        /*  onClick={() => handleOpenMR(patient)} */
      >
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {principalDentist
            ? principalDentist?.user.name + ' ' + principalDentist?.user.surname
            : '-'}
        </p>
      </div>
      <div
        className="col-2 d-flex flex-row justify-content-center"
        /* onClick={() => handleOpenMR(patient)} */
      >
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {patient.groups.length !== 0 &&
            patient.groups.map((group) => (
              <img
                style={{ maxWidth: '35px' }}
                src={group.id === 1 ? saologo : group.id === 3 && ubalogo}
                alt={group.name}
              />
            ))}
        </p>
      </div>
      <div
        className="col-2 d-flex flex-row justify-content-center"
        /* onClick={() => handleOpenMR(patient)} */
      >
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {patient?.treatments[0]?.type?.name}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-end">
        <EditPatient
          color="#444"
          size={16}
          patient={patient}
          principalDentist={principalDentist}
          setInitialData={setInitialData}
        />
        <DeleteModal
          username={patient?.name}
          id={patient?.id}
          setInitialData={setInitialData}
        />
      </div>
    </div>
  )
}

export default PatientCard
