import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import apiClient from "../../../utils/backend";
import { useHistory } from "react-router";

const PrintModal = (props) => {
  const { id, patient, dentist, plates, print } = props.item;

  const { opened, className, handleModal, type } = props;

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log(props);
  }, [props]);

  function handleApprove() {
    setLoading(true);

    apiClient
      .post(`/api/status/nextStatus`, {
        treatment_id: id,
        process: "Impresion",
        type: type,
      })
      .then((res) => {
        console.log(res.data);

        if (res.status === 200) {
          history.go(0);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <Modal
        isOpen={opened === className ? true : false}
        toggle={handleModal}
        className={className}
      >
        <ModalBody>
          <div className="container">
            <div className="fs-6 fw-bold text-success mb-3">
              Confirmar Impresión:
            </div>
            <small>Confirmar la impresion completa del caso:</small>
            <div className="row mt-3">
              <div className="col-2 d-flex flex-column">
                <small>ID:</small>
                <p className="mt- fs-6 fw-bold text-primary">{id}</p>
              </div>
              <div className="col-5 d-flex flex-column">
                <small>Paciente:</small>
                <p className="mt-1 fs-6 fw-bold text-primary">
                  {patient.full_name}
                </p>
              </div>
              <div className="col-5 d-flex flex-column">
                <small>Doctor:</small>
                <p className="mt-1 fs-6 fw-bold text-primary">
                  {dentist.full_name}
                </p>
              </div>
              <div className="col-3 d-flex flex-row mt-3">
                <small>Placas:</small>
                <p className="fs-6 fw-bold text-primary ms-2">{plates}</p>
              </div>
              <div className="col-4 d-flex flex-row mt-3">
                <small style={{ whiteSpace: "nowrap" }}>Se imprime:</small>
                <p className="fs-6 fw-bold text-primary ms-2">{print}</p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {!loading && (
            <div>
              <button
                className="me-3 rounded-pill btn btn-light text-dark border"
                onClick={handleModal}
              >
                Volver
              </button>
              <button
                className="rounded-pill btn btn-success text-white"
                onClick={handleApprove}
              >
                Aprobar
              </button>
            </div>
          )}

          {loading && (
            <button class="btn btn-success" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PrintModal;
