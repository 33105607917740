import React from 'react'
import { TeethSelector } from '../../../Utilities/TeethSelector'
import '../../../style.css'

const Extractions = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Extracciones</p>
      <hr className="mt-2" />
      <div className="d-flex flex-column">
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="extractions"
            checked={formData?.extraction == 1 && true}
            disabled
          />
          <label className="ms-2">Se pueden extraer dientes</label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="extractions"
            checked={formData?.extraction == 2 && true}
            disabled
          />
          <label className="ms-2">NO aplica</label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="extractions"
            checked={formData?.extraction == 3 && true}
            disabled
          />
          <label className="ms-2">NO extraer los siguientes dientes</label>
        </div>
      </div>
      {formData.extraction == 3 && (
        <TeethSelector formData={formData} type="extraction" />
      )}
    </div>
  )
}

export default Extractions
