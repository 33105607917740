import React from 'react'
import './animated-hamburger.css'
import PropTypes from 'prop-types'

const AnimatedHamburger = ({ active }) => {
  return (
    <>
      <svg
        class={`ham hamRotate180 ham5 text-primary ${!active && 'active'}`}
        viewBox="0 0 100 100"
        width="40"
      >
        <path
          class="line top"
          d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429"
        />
        <path class="line middle" d="m 70,50 h -40" />
        <path
          class="line bottom"
          d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429"
        />
      </svg>
    </>
  )
}

export default AnimatedHamburger

AnimatedHamburger.propTypes = {
  active: PropTypes.bool.isRequired,
}
