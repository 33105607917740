export const selectReferral = (
  thisReferral,
  setisAllSelected,
  setReferralsState,
  referralsState
) => {
  if (thisReferral.selected) {
    setisAllSelected(false)
    setReferralsState({
      ...referralsState,
      referralsList: referralsState.referralsList.map((referral) => {
        if (referral.id === thisReferral.id) {
          return { ...referral, selected: false }
        }
        return referral
      }),
    })
  } else {
    setReferralsState({
      ...referralsState,
      referralsList: referralsState.referralsList.map((referral) => {
        if (referral.id === thisReferral.id) {
          return { ...referral, selected: true }
        }
        return referral
      }),
    })
  }
}
