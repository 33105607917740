const getExtensionFromBase64 = (url) => {
  const split = url.split(',')
  const charAt = split[1].charAt(0)
  const extension = {
    '/': '.jpg',
    i: '.png',
    R: '.gif',
    U: '.webp',
    J: '.pdf',
  }
  return extension[charAt]
}

export default getExtensionFromBase64
