import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../../utils/backend'

export const useBenefits = () => {
  const { countryId } = useSelector((state) => state.userReducer)
  const [dentistProfile, setDentistProfile] = useState({})
  const [loading, setLoading] = useState(true)

  const apiCall = async () => {
    try {
      const res = await apiClient('api/smileCreator')
      setDentistProfile(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    apiCall()
  }, [])

  return { dentistProfile, loading, countryId }
}
