import React from 'react'

const CHIsApplicationRegistro = ({handleChangeRegistro,data}) => {
  return (
    <div className="me-3 d-flex flex-column">
      <small className="CH-small">Registro:</small>
      <select
        className="CH-select my-1"
        onChange={(e) => {
          handleChangeRegistro(e.target.value)
        }}
      >
        <option selected={!data?.intraoral}>Registro original</option>
        <option selected={data?.intraoral}>Intraoral</option>
      </select>
    </div>
  )
}

export default CHIsApplicationRegistro
