import React, { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'
import throttle from '../../../utils/throttle'
import successImage from '../../../assets/images/RO-check.png'
import './index.css'
import TreatmentCard from '../components/TreatmentCard'

const RegistroOriginal = () => {
  const [inputState, setInputState] = useState()
  const [linkInputState, setLinkInputState] = useState()
  const [treatmentCardData, setTreatmentData] = useState()
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({ id: null, link: null })

  function idInputHandler(e) {
    setTreatmentData(null)
    setLinkInputState('')
    setErrors({ id: null, link: null })
    setInputState(e.target.value)
    throttle(getTreatmentById, e.target.value, 800)
  }

  function linkInputHandler(e) {
    setErrors({ id: null, link: null })
    setLinkInputState(e.target.value)
  }

  async function getTreatmentById(query) {
    if (!query) return setTreatmentData(null)
    try {
      const res = await apiClient.post('api/getByExternalId', {
        external_id: query,
      })
      setTreatmentData(res.data.data)
    } catch (err) {
      setErrors({ id: err, link: null })
    }
  }

  async function onSaveBtnHandler() {
    if (!linkInputState)
      return setErrors({ id: null, link: 'Campo obligatorio.' })

    if (!linkInputState.includes('viewer3d.keepsmilinglog.com/view/'))
      return setErrors({ id: null, link: 'Link invalido.' })

    try {
      await apiClient.post('api/processes', {
        process_id: 1,
        treatment_id: treatmentCardData.id,
      })

      try {
        const res = await apiClient.post('api/viewer', {
          treatment_id: treatmentCardData.id,
          url: linkInputState,
          type: 'registro',
        })

        if (res.data.success) return setSuccess(true)
      } catch (err) {
        setErrors({ id: null, link: 'Error cargando el visor.' })
      }
    } catch (err) {
      setErrors({ id: null, link: 'Error creando el registro.' })
    }
  }

  const handleSend = (e) => {
    if (e.charCode !== 13) return
    onSaveBtnHandler()
  }

  useEffect(() => {
    if (!success) return
    setTimeout(() => {
      setTreatmentData(null)
      setInputState('')
      setLinkInputState('')
      setSuccess(false)
    }, 3000)
  }, [success])

  return (
    <>
      {!success ? (
        <div className="RO-container">
          <h4 className="fw-bold mb-4 text-primary">Registro Original</h4>
          <p className="my-0 fw-bold mb-3 text-primary">ID:</p>
          <input
            maxLength={15}
            className="RO-input-id "
            value={inputState}
            onChange={idInputHandler}
          ></input>

          {errors.id && (
            <p className="mb-3 mt-2 RO-error">No se encontro tratamiento.</p>
          )}

          <TreatmentCard treatmentCardData={treatmentCardData} />
          <p
            className={`my-0 fw-bold mb-3 text-primary ${
              !treatmentCardData ? 'disabled' : null
            }`}
          >
            Link:
          </p>
          <input
            disabled={treatmentCardData ? false : true}
            className="RO-input-id w-100"
            type="text"
            onKeyPress={(e) => handleSend(e)}
            value={linkInputState}
            onChange={linkInputHandler}
          />
          {errors.link && <p className="mb-3 mt-2 RO-error">{errors.link}</p>}
          <div className="d-flex justify-content-end mt-4">
            <button
              disabled={treatmentCardData && linkInputState ? false : true}
              className={`RO-btn RO-light-shadow ${
                !treatmentCardData || !linkInputState ? 'disabled' : null
              }`}
              onClick={onSaveBtnHandler}
            >
              Guardar
            </button>
          </div>
        </div>
      ) : (
        <div className="RO-container d-flex">
          <h4 className="fw-bold mb-4 text-primary">Registro Original</h4>
          <TreatmentCard treatmentCardData={treatmentCardData} />
          {errors.link && <p className="mb-3 mt-2 RO-error">{errors.link}</p>}
          <div className="mt-3 RO-success-image">
            <img width={200} height={200} alt="check" src={successImage} />
          </div>
          <p className="mt-4 RO-success-text justify-content-end">
            ¡Registro original cargado correctamente!
          </p>
        </div>
      )}
    </>
  )
}

export default RegistroOriginal
