import React, { useEffect, useState } from 'react'
import styles from './styles/styles.module.css'
import Searchbar from '../../components/searchbar'
import PaymentValidationCard from './components/PaymentValidationCard'
import Loader from '../../components/loader'
import Paginator from '../../components/Paginator'
import SelectAllCheckBox from './components/SelectAllCheckBox'
import ColNamesRow from './components/ColNamesRow'
import NotFoundComponent from '../../components/NotFound.js/NotFoundComponent'
import usePaymentValidation from './hooks/usePaymentValidation'
import PaymentsAllModal from './modals/PaymentsAllModal'
import { useSelector } from 'react-redux'

const PaymentValidation = () => {
  const [hasSelected, setHasSelected] = useState(false)
  const { countryId } = useSelector((state) => state.userReducer)
  const {
    paymentState,
    getAllPayments,
    handlePaginator,
    loading,
    setPaymentState,
    updateAfterPaymentAll,
    isAllSelected,
    setisAllSelected,
  } = usePaymentValidation()

  const setAllPayments = () => {
    if (isAllSelected) {
      setisAllSelected(false)
      return setPaymentState({
        ...paymentState,
        payments: paymentState.payments.map((payment) => ({
          ...payment,
          selected: false,
        })),
      })
    } else {
      setisAllSelected(true)
      setPaymentState({
        ...paymentState,
        payments: paymentState.payments.map((payment) => ({
          ...payment,
          selected: true,
        })),
      })
    }
  }

  useEffect(() => {
    getAllPayments(1, 1)
  }, [])

  useEffect(() => {
    if (paymentState.payments.find((payment) => payment.selected)) {
      setHasSelected(true)
    } else {
      setHasSelected(false)
    }
  }, [paymentState])

  useEffect(() => {
    setisAllSelected(false)
  }, [paymentState.searchQuery])

  if (loading) return <Loader />
  if (countryId !== 1)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <NotFoundComponent />
      </div>
    )

  return (
    <div className="px-5 py-4">
      <div className="d-flex justify-content-between">
        <p className={`fs-2 fw-bold m-0 ${styles.title}`}>Habilitación pago</p>
        <Searchbar
          url={`api/paymentvalidations?cant_pages=50&page=1&filter[payment_validations]=true&`}
          state={paymentState}
          setState={setPaymentState}
          page={'adminPaymentsIndex'}
          initialQueryType={'treatment'}
        />
      </div>

      <hr className="mt-5" />

      <div className="d-flex justify-content-end">
        {hasSelected ? (
          <PaymentsAllModal
            updateAfterPaymentAll={updateAfterPaymentAll}
            setPaymentState={setPaymentState}
            paymentState={paymentState}
            getAllPayments={getAllPayments}
          />
        ) : (
          <div style={{ height: '28px' }}>
            {/*Div de relleno para que no se mueva la lista cuando sale el boton */}
          </div>
        )}
      </div>
      <div className={`d-flex ${styles.tableHead}`}>
        <SelectAllCheckBox
          styles={styles}
          setAllPayments={setAllPayments}
          isAllSelected={isAllSelected}
        />
        <ColNamesRow styles={styles} />
      </div>

      {paymentState?.payments?.length > 0 ? (
        paymentState.payments.map((payment) => (
          <PaymentValidationCard
            updatePaymentDate={paymentState.updatePaymentDate}
            key={payment.id}
            data={payment}
            currentPayment={
              paymentState.payments.filter(
                (payments) => payments.id === payment.id
              )[0]
            }
            setPaymentState={setPaymentState}
            paymentState={paymentState}
            updateAfterPaymentAll={updateAfterPaymentAll}
            setisAllSelected={setisAllSelected}
            getAllPayments={getAllPayments}
          />
        ))
      ) : (
        <NotFoundComponent />
      )}

      <Paginator
        paginator={paymentState.paginator}
        getPaginator={handlePaginator}
      />
    </div>
  )
}

export default PaymentValidation
