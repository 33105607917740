import React from 'react'
import {Trash, Check, Edit as EditIcon, Refresh} from '@styled-icons/boxicons-regular'
import {ButtonDelete, ButtonUpdate, ButtonEdit, ButtonCustom, ButtonReload} from '../styled/buttons'

export function Delete() {
    return(
        <ButtonDelete>
            <Trash />
        </ButtonDelete>
    )
}

export function Update() {
    return(
        <ButtonUpdate>
            <Check />
        </ButtonUpdate>
    )
}

export function Edit() {
    return(
        <ButtonEdit>
            <EditIcon />
        </ButtonEdit>
    )
}

export function Custom(props) {
    return(
        <ButtonCustom color={{bg: props.color.bg, color: props.color.color}}>
            {props.title}
        </ButtonCustom>
    )
}

export function Reload(props) {
    return(
        <ButtonReload>
            <Refresh />
        </ButtonReload>
    )
}