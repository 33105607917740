import React from 'react'
import AlertTriangle from '../../../../assets/images/danger_triangle.png'
import { Modal, ModalBody } from 'reactstrap'
import '../../styles/index.css'
import { ArrowRight } from 'react-feather'

const UpdateProposalModal = ({ opened, updateProposalCall, modalState }) => {
  if (!modalState) return null

  const from = () => {
    if (modalState.from === 'pending') return 'pendiente'
    if (modalState.from === 'approved') return 'aprobado'
    if (modalState.from === 'rejected') return 'rechazado'
  }

  return (
    <Modal style={{ marginTop: '150px' }} isOpen={opened}>
      <ModalBody>
        <div className="d-flex flex-column align-items-center justify-content-center pt-4">
          <img src={AlertTriangle} alt="alert" height={60} width={70} />
          <p className="my-3 fw-bold">
            Esta cambiando el estado de la propuesta de tratamiento
          </p>
          <div className="d-flex mb-3">
            <div className={`CH-${from()} px-2 py-1 rounded`}>
              {from().toUpperCase()}
            </div>
            <ArrowRight width={50} height={20} />
            <div className={`CH-${modalState.to} px-2 py-1 rounded`}>
              {modalState.to.toUpperCase()}
            </div>
          </div>
          {modalState.to === 'aprobado' && (
            <p className="mb-3 text-center">
              Esta acción indicara como "RECHAZADA" todas las propuestas
              restantes si las hay.
            </p>
          )}
          <div className="d-flex justify-content-center mb-4">
            <button
              className="back-btn"
              onClick={() => updateProposalCall(false)}
            >
              Volver
            </button>
            <button
              className="confirmate-btn"
              onClick={() => updateProposalCall(true)}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UpdateProposalModal
