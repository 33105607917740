import { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'

const usePaymentValidation = () => {
  const [loading, setLoading] = useState(true)
  const [isAllSelected, setisAllSelected] = useState(false)
  const [lastUrl, setLastUrl] = useState(null)
  const [paymentState, setPaymentState] = useState({
    payments: [],
    paginator: [],
  })

  const updateAfterPaymentAll = async () => {
    if (!lastUrl) return
    try {
      const res = await apiClient(lastUrl)
      setPaymentState({
        ...paymentState,
        payments: res.data.table.body.map((payment) => ({
          ...payment,
          selected: false,
        })),
        paginator: res.data.paginator,
        updatePaymentDate: true,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getUrl = (page) => {
    let url = `api/paymentvalidations?cant_pages=50&page=${page}&filter[payment_validations]=true&filter[patient.user.country.id]=1`
    return url
  }

  const getAllPayments = async (page = 1) => {
    try {
      const res = await apiClient(getUrl(page))
      setPaymentState({
        payments: res.data.table.body.map((payment) => ({
          ...payment,
          selected: false,
        })),
        paginator: res.data.paginator,
        clearSearchBarInput: true,
      })
      setLastUrl(getUrl(page))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getSearchedPaymentsPaginator = async (page) => {
    let url = `api/paymentvalidations?cant_pages=50&page=${page}&filter[payment_validations]=true&filter[patient.user.country.id]=1&filter[${paymentState.searchType}]=${paymentState.searchQuery}`
    try {
      const res = await apiClient(url)
      setPaymentState({
        payments: res.data.table.body.map((payment) => ({
          ...payment,
          selected: false,
        })),
        paginator: res.data.paginator,
      })
      setLastUrl(url)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePaginator = (page) => {
    setisAllSelected(false)
    if (paymentState.searchQuery) return getSearchedPaymentsPaginator(page)
    getAllPayments(page)
  }

  useEffect(() => {
    if (!paymentState.loadTodosFilter) return
    getAllPayments(1)
  }, [paymentState])

  return {
    paymentState,
    getAllPayments,
    handlePaginator,
    loading,
    setPaymentState,
    updateAfterPaymentAll,
    isAllSelected,
    setisAllSelected,
  }
}

export default usePaymentValidation
