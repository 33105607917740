import React, { useState } from 'react'
import apiClient from '../../../utils/backend'
import './index.css'

const PriceListCard = ({ priceItem, editMode, countryId }) => {
  const [dataToEdit, setDataToEdit] = useState({
    cantidad_alineadores: priceItem.cantidad_alineadores,
    etapa_adicional: priceItem.etapa_adicional,
    id: priceItem.id,
    maxilla: priceItem.maxilla,
    precios: priceItem.precios,
    reposicion_alineador: priceItem.reposicion_alineador,
    // type_id: 4,
  })

  const handleSendData = async () => {
    try {
      const res = await apiClient.put(
        `api/priceList/${dataToEdit.id}`,
        dataToEdit
      )
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  const restrict = function (tb) {
    tb.value = tb.value.replace(/[^a-zA-Z@]/g, '')
  }
  const restrict2 = function (tb) {
    tb.value = tb.value.replace(/-/g, '')
  }
  return (
    <div className="row">
      <div className="col-12 d-flex flex-row">
        <div
          className={`col-2 text-center fw-bold pt-2 ps-4 ${
            editMode ? 'text-muted' : ''
          }`}
        >
          <p className="mb-0">{dataToEdit.maxilla}</p>
        </div>
        <div className="col-3 text-center fw-bold pt-2 ps-2">
          {editMode ? (
            <input
              type="number"
              className="inputPriceList"
              placeholder={`$${dataToEdit.precios}`}
              onChange={(e) => {
                if (e.target.value.length > 8) {
                  return alert(
                    `${e.target.value} supera el máximo de caracteres.`
                  )
                }
                if (e.target.value.length <= 8 && e.target.value >= 0) {
                  setDataToEdit({ ...dataToEdit, precios: e.target.value })
                }
              }}
              onBlur={() => handleSendData()}
              min={0}
            />
          ) : (
            <p className="mb-0">$ {dataToEdit.precios}</p>
          )}
        </div>
        <div className="col-2 text-center fw-bold pt-2 px-0 me-2">
          {editMode ? (
            <input
              type="text"
              className="inputPriceList"
              placeholder={dataToEdit.cantidad_alineadores}
              onPaste={(e) => restrict2(e.target)}
              onKeyPress={(e) => restrict2(e.target)}
              onKeyUp={(e) => restrict2(e.target)}
              onChange={(e) => {
                restrict2(e.target)
                setDataToEdit({
                  ...dataToEdit,
                  cantidad_alineadores: e.target.value,
                })
              }}
              onBlur={() => handleSendData()}
              maxlength={20}
              min={0}
            />
          ) : (
            <p className="mb-0">{dataToEdit.cantidad_alineadores}</p>
          )}
        </div>
        <div className="col-3 text-center fw-bold pt-2 pe-3">
          {editMode ? (
            <input
              type="text"
              className="inputPriceList"
              placeholder={dataToEdit.reposicion_alineador}
              onPaste={(e) => {
                if (countryId === 4) return
                restrict(e.target)
              }}
              onKeyPress={(e) => {
                if (countryId === 4) return
                restrict(e.target)
              }}
              onKeyUp={(e) => {
                if (countryId === 4) return
                restrict(e.target)
              }}
              onChange={(e) => {
                if (countryId !== 4) {
                  restrict(e.target)
                }
                setDataToEdit({
                  ...dataToEdit,
                  reposicion_alineador: e.target.value,
                })
              }}
              onBlur={() => handleSendData()}
              maxlength={20}
            />
          ) : (
            <p className="mb-0">{dataToEdit.reposicion_alineador}</p>
          )}
        </div>
        <div className="col-2 text-center fw-bold pt-2 pe-3">
          {editMode ? (
            <input
              type="text"
              className="inputPriceList"
              placeholder={dataToEdit.etapa_adicional}
              onPaste={(e) => {
                if (countryId === 4) return
                restrict(e.target)
              }}
              onKeyPress={(e) => {
                if (countryId === 4) return
                restrict(e.target)
              }}
              onKeyUp={(e) => {
                if (countryId === 4) return
                restrict(e.target)
              }}
              onChange={(e) => {
                if (countryId !== 4) {
                  restrict(e.target)
                }
                setDataToEdit({
                  ...dataToEdit,
                  etapa_adicional: e.target.value,
                })
              }}
              onBlur={() => handleSendData()}
              maxlength={20}
            />
          ) : (
            <p className="mb-0">{dataToEdit.etapa_adicional}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default PriceListCard
