import React, { useEffect, useState } from 'react'
import saologo from '../../../assets/images/sao_logo.png'
import sglogo from '../../../assets/images/study_group_logo.png'
import apiClient from '../../../utils/backend'

const AdminEditProfile = ({
  profile,
  setProfile,
  errorField,
  cleanError,
  dentistIdPath,
}) => {
  const [arrayTypeOne, setArrayTypeOne] = useState()
  const [arrayTypeTwo, setArrayTypeTwo] = useState()
  const [speaker, setSpeaker] = useState({
    starter: false,
    advanced: false,
  })
  const [partTaker, setPartTaker] = useState({
    selectedGroup: null,
    starter: false,
    advanced: false,
  })

  const studyGroupHandler = (e) => {
    if (e.target.checked === false) {
      setProfile({
        ...profile,
        sg_team: null,
        sg_type: null,
        sg_level: null,
        study_group: e.target.checked,
      })
      setSpeaker({ starter: false, advanced: false })
      setPartTaker({ starter: false, advanced: false, selectedGroup: null })
    } else setProfile({ ...profile, study_group: e.target.checked })
  }
  const showProfileOptions = () => {
    if (!profile.study_group) return
    return (
      <>
        <div className="col-10 d-flex justify-content-between">
          <button
            onClick={(e) => {
              e.preventDefault()
              setProfile({
                ...profile,
                sg_type: 1,
              })
            }}
            className={`mx-1 fs-6 text-center w-100 border border-1 border-primary rounded-pill px-4 py-2 btn ${
              profile.sg_type === 1 ? 'btn-primary' : 'btn-outline-primary'
            }`}
          >
            Orador
          </button>
          <button
            onClick={(e) => {
              e.preventDefault()
              setProfile({
                ...profile,
                sg_type: 2,
              })
            }}
            className={` mx-1 fs-6 text-center w-100 border border-1 border-primary rounded-pill px-4 py-2 btn ${
              profile.sg_type === 2 ? 'btn-primary' : 'btn-outline-primary'
            }`}
          >
            Participante
          </button>
        </div>
        {profile.sg_type === 1 && (
          <div className="col-12 mt-3">
            <div className="mx-2">
              <input
                checked={speaker.starter}
                onClick={(e) =>
                  setSpeaker({ ...speaker, starter: !speaker.starter })
                }
                type="checkbox"
                name="principiante"
                id="principiante"
              />
              <label className="mx-2" htmlFor="principiante">
                Principiante
              </label>
            </div>
            <div className="mx-2 mt-1">
              <input
                checked={speaker.advanced}
                onClick={() =>
                  setSpeaker({ ...speaker, advanced: !speaker.advanced })
                }
                type="checkbox"
                name="avanzado"
                id="avanzado"
              />
              <label className="mx-2" htmlFor="avanzado">
                Avanzado
              </label>
            </div>
          </div>
        )}
        {profile.sg_type === 2 && (
          <>
            <div className="col-12 mt-3 d-flex flex-row">
              <div className="mx-1 me-3 d-flex">
                <input
                  checked={partTaker.starter}
                  onClick={(e) =>
                    setPartTaker({
                      selectedGroup: null,
                      starter: true,
                      advanced: false,
                    })
                  }
                  type="radio"
                  name="principiante"
                  id="principiante"
                  className="me-1"
                />
                <label htmlFor="principiante">Principiante</label>
              </div>
              <div className="mx-1 d-flex">
                <input
                  checked={partTaker.advanced}
                  onClick={(e) =>
                    setPartTaker({
                      selectedGroup: null,
                      starter: false,
                      advanced: true,
                    })
                  }
                  type="radio"
                  name="avanzado"
                  id="avanzado"
                  className="me-1"
                />
                <label htmlFor="avanzado">Avanzado</label>
              </div>
            </div>
            <div className="col-12 mt-3">
              <label htmlFor="sg_team">Participa en el grupo de:</label>
              <select
                disabled={
                  !partTaker.starter && !partTaker.advanced ? true : false
                }
                onChange={(e) => {
                  setPartTaker({ ...partTaker, selectedGroup: e.target.value })
                }}
                className="form-control"
                value={partTaker.selectedGroup}
              >
                <option defaultValue="0" value="0">
                  Selecciona una opción
                </option>
                {partTaker.starter && arrayTypeOne
                  ? arrayTypeOne.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.dentist?.user.full_name}
                      </option>
                    ))
                  : null}
                {partTaker.advanced && arrayTypeTwo
                  ? arrayTypeTwo.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.dentist?.user.full_name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </>
        )}
      </>
    )
  }

  // Get Groups
  useEffect(() => {
    const apiCall = async () => {
      try {
        const res = await apiClient.get('api/getGroups')

        const data1 = res.data.data
          .filter((dentist) => dentist.type === '1' || dentist.type === '3')
          .filter((od) => od.dentist_id !== Number(dentistIdPath))

        const data2 = res.data.data
          .filter((dentist) => dentist.type === '2' || dentist.type === '3')
          .filter((od) => od.dentist_id !== Number(dentistIdPath))

        setArrayTypeOne(data1)
        setArrayTypeTwo(data2)
      } catch (err) {
        console.log(err)
      }
    }
    apiCall()
  }, [])

  //set initial Data
  useEffect(() => {
    if (profile.sg_type === 1) {
      switch (profile.sg_level) {
        case 1:
          setSpeaker({ starter: true, advanced: false })
          break
        case 2:
          setSpeaker({ starter: false, advanced: true })
          break
        case 3:
          setSpeaker({ starter: true, advanced: true })
          break
        default:
      }
    } else {
      if (profile.sg_level === 1) {
        setPartTaker({
          selectedGroup: profile.sg_team,
          starter: true,
          advanced: false,
        })
      } else {
        setPartTaker({
          selectedGroup: profile.sg_team,
          starter: false,
          advanced: true,
        })
      }
    }
  }, [])

  // update profile data
  useEffect(() => {
    if (profile.sg_type === 1) {
      if (speaker.starter && speaker.advanced)
        return setProfile({ ...profile, sg_level: 3, sg_team: null })
      if (speaker.starter)
        return setProfile({ ...profile, sg_level: 1, sg_team: null })
      if (speaker.advanced)
        return setProfile({ ...profile, sg_level: 2, sg_team: null })
      return setProfile({ ...profile, sg_level: null, sg_team: null })
    } else {
      if (!partTaker.starter && !partTaker.advanced)
        return setProfile({
          ...profile,
          sg_level: null,
          sg_team: partTaker.selectedGroup,
        })
      if (partTaker.starter)
        return setProfile({
          ...profile,
          sg_level: 1,
          sg_team: partTaker.selectedGroup,
        })
      if (partTaker.advanced)
        return setProfile({
          ...profile,
          sg_level: 2,
          sg_team: partTaker.selectedGroup,
        })
    }
  }, [speaker, partTaker, profile.sg_type])

  // Clear errors
  useEffect(() => {
    cleanError('profile')
  }, [profile])

  return (
    <div className="col-4 px-5 mx-auto">
      <h5 className="fw-bold">Perfil</h5>
      <div className="row mb-4 mt-3">
        <div className="col-6 align-items-center">
          <input
            type="checkbox"
            name="study_group"
            id="study_group"
            onChange={studyGroupHandler}
            className="mx-2 rounded"
            checked={profile.study_group}
          />
          <label htmlFor="study_group" className="fs-4">
            <img
              src={sglogo}
              style={{
                width: '60px',
                height: 'auto',
              }}
              alt="Study Group"
            />
          </label>
        </div>
        <div className="col-6 align-items-center">
          <input
            type="checkbox"
            name="sao"
            id="sao"
            onChange={(e) =>
              setProfile({
                ...profile,
                sao: e.target.checked,
              })
            }
            className="mx-2 rounded"
            checked={profile.sao}
          />
          <label htmlFor="sao" className="fs-4">
            <img
              src={saologo}
              style={{ width: '60px', height: 'auto' }}
              alt="SAO"
            />
          </label>
        </div>
      </div>
      {showProfileOptions()}
      {errorField.profile && (
        <p className="text-danger fw-light mt-2">
          Debe completar esta seccion.
        </p>
      )}
    </div>
  )
}

export default AdminEditProfile
