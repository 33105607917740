import React, { useEffect, useState } from 'react'
import apiClient from '../../../../utils/backend'
import saologo from '../../../../assets/images/sao_logo.png'
import sglogo from '../../../../assets/images/study_group_logo.png'

const OdProfile = ({ profile, mobile }) => {
  const [info, setInfo] = useState({
    groupName: null,
    dentistType: null,
    dentistLevel: null,
  })
  const displayDentistLevel = (value) => {
    const arrayOfStrings = [
      null,
      'Principiante',
      'Avanzado',
      'Principiante Avanzado',
    ]
    return arrayOfStrings[value]
  }

  useEffect(() => {
    if (!profile.sg_team) return

    const genderObject = {
      f: 'Dra.',
      m: 'Dr.',
      o: 'Dr.',
    }

    const apiCall = async () => {
      try {
        const res = await apiClient.get('api/getGroups')
        const group = res.data.data.filter((gr) => gr.id === profile.sg_team)
        console.log(group)
        setInfo({
          dentistType: profile.sg_type === 1 ? 'Orador' : 'Participante',
          groupName:
            genderObject[group[0].dentist?.user.gender] +
            ' ' +
            group[0].dentist?.user.name +
            ' ' +
            group[0].dentist?.user.surname,
        })
      } catch (err) {
        console.log(err)
      }
    }
    apiCall()
  }, [profile])

  useEffect(() => {
    if (profile.sg_team) return
    setInfo({
      groupName: null,
      dentistType: profile.sg_type === 1 ? 'Orador' : 'Participante',
    })
  }, [profile])

  return (
    <>
      {!profile.sao && !profile.study_group ? null : (
        <div className={!mobile && 'col-4 px-5 mx-auto'}>
          <h5 className="fw-bold">Perfil</h5>
          <div className="row mb-4 mt-3">
            {profile.study_group && (
              <div className="col-6 align-items-center">
                <input
                  disabled
                  type="radio"
                  name="study_group"
                  id="study_group"
                  className="mx-2 rounded"
                  checked={true}
                />
                <label htmlFor="study_group" className="fs-4">
                  <img
                    src={sglogo}
                    style={{
                      width: '60px',
                      height: 'auto',
                    }}
                    alt="Study Group"
                  />
                </label>
              </div>
            )}
            {profile.sao && (
              <div className="col-6 align-items-center">
                <input
                  disabled
                  type="radio"
                  name="sao"
                  id="sao"
                  className="mx-2 rounded"
                  checked={true}
                />
                <label htmlFor="sao" className="fs-4">
                  <img
                    src={saologo}
                    style={{ width: '60px', height: 'auto' }}
                    alt="SAO"
                  />
                </label>
              </div>
            )}
          </div>
          {profile.study_group && (
            <div className="text-primary">
              <div className="fs-5">{info.dentistType}</div>
              {profile.sg_team && (
                <>
                  <p className="fs-5 p-0">Nombre del Grupo:</p>
                  <p className="fs-4 py-3 fw-bold">{info.groupName}</p>
                </>
              )}

              <div>
                <p className="fs-5 mb-2 mt-3 ">Nivel:</p>
                <p style={{ width: 150 }} className="fs-4 fw-bold">
                  {displayDentistLevel(profile.sg_level)}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default OdProfile
