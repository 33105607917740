import React from 'react'
import Searchbar from '../../../components/searchbar'
import styles from './styles/styles.module.css'
import NotFoundComponent from '../../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../../components/Paginator'
import Loader from '../../../components/loader'
import useDentistPatients from './hooks/useDentistPatients'
import DentistFiltersDesktop from './components/DentistFiltersDesktop'
import PatientCard from './components/PatientCard'

const DesktopIndex = () => {
  const {
    state,
    setState,
    getUrl,
    filter,
    handleFilter,
    countryId,
    getPaginatorData,
  } = useDentistPatients({ isMobile: false })

  if (!state) return <Loader />
  return (
    <div className="px-5 py-4">
      <div className="d-flex justify-content-between">
        <p className="fs-2 fw-bold m-0" styles={{ color: '#001C41' }}>
          Mis Pacientes
        </p>
        <div className="w-25">
          <Searchbar
            state={state}
            setState={setState}
            page="myPatients"
            url={`${getUrl(filter)}&`}
          />
        </div>
      </div>
      <hr />
      <DentistFiltersDesktop
        state={state}
        filter={filter}
        handleFilter={handleFilter}
        countryId={countryId}
      />
      <div className="d-flex mt-5">
        <p className={`${styles.patient} fw-bold`}>Paciente</p>
        <p className={`${styles.treatment} fw-bold`}>Tratamiento</p>
        <p className={`${styles.stage} fw-bold`}>Etapa</p>
      </div>
      <div>
        {state.patients.length ? (
          state.patients.map((card) => (
            <PatientCard
              key={card.patient.id}
              patientData={card.patient}
              treatment={card.treatment}
            />
          ))
        ) : (
          <NotFoundComponent />
        )}
      </div>
      <Paginator paginator={state.paginator} getPaginator={getPaginatorData} />
    </div>
  )
}

export default DesktopIndex
