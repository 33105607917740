import React from 'react'
import '../../../style.css'

const PreviousSpaceGain = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Ganancia de espacio posterior</p>
      <hr className="mt-2 mb-0" />
      <div className="d-flex flex-column">
        <div className="improve-overjet-container">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <p className="mb-0">Expansión (con torque)</p>
              <hr className="mt-1 me-4" />
              <div>
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="proexpanation"
                  disabled
                  checked={formData?.with_gain == 1 && true}
                />
                <label className="ms-2">Si</label>
              </div>
              <div>
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="proexpanation"
                  disabled
                  checked={formData?.with_gain == 2 && true}
                />
                <label className="ms-2">Solo si es necesario</label>
              </div>
              <div>
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="proexpanation"
                  disabled
                  checked={formData?.with_gain == 3 && true}
                />
                <label className="ms-2">Nada</label>
              </div>
            </div>
            <div className="w-100">
              <p className="mb-0">Expansión (sin torque)</p>
              <hr className="mt-1" />
              <div>
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="proexpanationWithoutInclination"
                  disabled
                  checked={formData?.without_gain == 1 && true}
                />
                <label className="ms-2">Si</label>
              </div>
              <div>
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="proexpanationWithoutInclination"
                  disabled
                  checked={formData?.without_gain == 2 && true}
                />
                <label className="ms-2">Solo si es necesario</label>
              </div>
              <div>
                <input
                  type="radio"
                  className="mt-2 inputColor"
                  name="proexpanationWithoutInclination"
                  disabled
                  checked={formData?.without_gain == 3 && true}
                />
                <label className="ms-2">Nada</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {formData?.other_gain && (
        <>
          <p className="mt-3">Otras:</p>
          <div className="step-text-area w-100 overflow-scroll">{formData?.other_gain}</div>
        </>
      )}
    </div>
  )
}

export default PreviousSpaceGain
