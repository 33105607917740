import React, { useState } from 'react'
import { ChevronDown } from 'react-feather'
import Icons from '../../Icons/Icons'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SidebarDropdownItem = ({ item }) => {
  const [openDrop, setOpenDrop] = useState(false)
  return (
    <>
      <div
        onClick={() => setOpenDrop((prevState) => !prevState)}
        className="scale d-flex flex-start align-items-center px-3 py-2 mb-4 cursor-pointer"
      >
        <Icons type={item.logo} />
        <span className="mx-2">{item.title}</span>
        <ChevronDown
          width={20}
          className={`tr-500 ${openDrop && 'rotate-180'}`}
        />
      </div>
      <div className={`sb-drop ${openDrop ? 'sb-active' : 'sb-inactive'}`}>
        {item.subItems.map(({ title, active, link }) => (
          <Link
            key={title}
            className={`text-white ${!active && 'disabled'} position-relative`}
            to={active && link}
          >
            <p className={`py-2 my-2 my-1 ${active && 'scale'}`}>{title}</p>
            {!active && (
              <small className="sb-inactive-text-subitem">¡Próximamente!</small>
            )}
          </Link>
        ))}
      </div>
    </>
  )
}

export default SidebarDropdownItem
SidebarDropdownItem.propTypes = {
  item: PropTypes.object.isRequired,
}
