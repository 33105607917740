import React from 'react'
import PriceListCard from './PriceListCard'
import './index.css'

const PriceListGeneralCard = ({
  treatmentId,
  priceListFull,
  priceListFast,
  priceListMedium,
  priceListKidsFull,
  priceListKidsFast,
  priceListTeens,
  editMode,
  countryId,
}) => {
  const colors = [
    null,
    { color: '#10456D', text: 'FULL' },
    { color: '#288B7E', text: 'FAST' },
    { color: '#5A468E', text: 'MEDIUM' },
    { color: '#E0614A', text: 'Kids Full' },
    { color: '#E8793A', text: 'Kids Fast' },
    { color: '#F7C05B', text: 'Teens' },
  ]

  return (
    <div className="row my-1 ps-1 pe-0">
      <div className="row">
        <div
          className="col-2 h-75 py-3 text-center priceCard d-flex align-items-center justify-content-center align-self-center"
          style={{ backgroundColor: colors[treatmentId].color }}
        >
          <p className="text-white my-0" style={{ lineHeight: '100%' }}>
            {colors[treatmentId].text}
          </p>
        </div>
        <div className="col-10">
          <hr className="mt-0 mb-1" />
          {treatmentId === 1 &&
            priceListFull?.map((priceItem, i) => (
              <PriceListCard
                countryId={countryId}
                key={priceItem.id}
                priceItem={priceItem}
                editMode={editMode}
              />
            ))}

          {treatmentId === 2 &&
            priceListFast?.map((priceItem, i) => (
              <PriceListCard
                countryId={countryId}
                key={priceItem.id}
                priceItem={priceItem}
                editMode={editMode}
              />
            ))}
          {treatmentId === 3 &&
            priceListMedium?.map((priceItem, i) => (
              <PriceListCard
                countryId={countryId}
                key={priceItem.id}
                priceItem={priceItem}
                editMode={editMode}
              />
            ))}
          {treatmentId === 4 &&
            priceListKidsFull?.map((priceItem, i) => (
              <PriceListCard
                countryId={countryId}
                key={priceItem.id}
                priceItem={priceItem}
                editMode={editMode}
              />
            ))}
          {treatmentId === 5 &&
            priceListKidsFast?.map((priceItem, i) => (
              <PriceListCard
                countryId={countryId}
                key={priceItem.id}
                priceItem={priceItem}
                editMode={editMode}
              />
            ))}
          {treatmentId === 6 &&
            priceListTeens?.map((priceItem, i) => (
              <PriceListCard
                countryId={countryId}
                key={priceItem.id}
                priceItem={priceItem}
                editMode={editMode}
              />
            ))}
        </div>
      </div>
    </div>
  )
}
export default PriceListGeneralCard
