import React from 'react'
import '../../../style.css'

const AuxiliarResources = ({ formData }) => {
  const searchAuxiliarResource = (number) => {
    return formData?.auxiliar_resource_selected.includes(number)
  }

  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Recursos Auxiliares</p>
      <hr className="mt-2 mb-0" />
      <div className="d-flex flex-column">
        <p className="mt-2">¿Quiere/piensa utilizar recursos auxiliares?:</p>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="auxiliarResources"
            disabled
            checked={formData?.auxiliar_resource == 1 && true}
          />
          <label className="ms-2">Si</label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="auxiliarResources"
            disabled
            checked={formData?.auxiliar_resource == 2 && true}
          />
          <label className="ms-2">No</label>
        </div>
        {formData?.auxiliar_resource == 1 && (
          <>
            <div className="d-flex justify-content-between mt-3">
              <div className="w-100">
                <p className="mb-0">
                  Indique los Recursos Auxiliares que desea utilizar:
                </p>
                <hr className="mt-1" />
                <div className="d-flex justify-content-between">
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(1)}
                      />
                      <label className="ms-2">Gomas de clase 2</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(2)}
                      />
                      <label className="ms-2">Gomas de clase 3</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(3)}
                      />
                      <label className="ms-2">Gomas crisscross</label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(4)}
                      />
                      <label className="ms-2">Microimplantes</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(5)}
                      />
                      <label className="ms-2">Topes de mordida</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(6)}
                      />
                      <label className="ms-2">Levante de mordida</label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(7)}
                      />
                      <label className="ms-2">Barras de presión</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(8)}
                      />
                      <label className="ms-2">Aceleradores</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="mt-2 inputColor"
                        name="itemsRecursosAuxiliares"
                        disabled
                        checked={searchAuxiliarResource(9)}
                      />
                      <label className="ms-2">Microperforadores</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-3">Aclaraciones:</p>
            <div className="step-text-area w-100 mt-2">
              {formData?.auxiliar_resource_description}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AuxiliarResources
