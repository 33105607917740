import React, { useEffect, useState } from 'react'
import apiClient from '../../utils/backend'
import { ArrowDown } from 'react-feather'
import { Link } from 'react-router-dom'
import './styles/index.css'
import ClinicHistoryModal from '../../components/MedicalRecordModal/ClinicHistoryModal'
import TreatmentCard from './components/treatments_card'
import Paginator from '../../components/Paginator'
import Loader from '../../components/loader'

const Incomes = () => {
  const [stats, setStats] = useState(null)
  const [retirement, setRetirement] = useState({
    treatments: [],
    paginator: {},
  })
  const [intraoral, setIntraoral] = useState({ treatments: [], paginator: {} })
  const [state, setState] = useState({
    loading: true,
  })

  const handleModal = (id) => {
    if (id)
      return setState({ ...state, modal_show: !state.modal_show, modal_id: id })
    else
      return setState({
        ...state,
        modal_show: !state.modal_show,
      })
  }

  const handleTreatmentColor = (type) => {
    const colors = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
    }
    return colors[type]
  }

  // index logic

  const informationApiCall = async () => {
    try {
      const res = await apiClient('/api/stats')
      setStats(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const retirementApiCall = async (path) => {
    try {
      const res = await apiClient(path)
      setRetirement({
        paginator: res.data.paginator,
        treatments: res.data.table.body,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const intraoralApiCall = async (path) => {
    try {
      const res = await apiClient(path)
      setIntraoral({
        paginator: res.data.paginator,
        treatments: res.data.table.body,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleRetirementFilter = async (
    e,
    searchDentist,
    firstDate,
    secondDate
  ) => {
    e.preventDefault()
    const path = `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=retirement&filter[rejected]=0&filter[registration_datetime]=${firstDate},${secondDate}&filter[principalDentist.fullname]=${searchDentist}&filter[no_external_id]=1`

    setState({
      ...state,
      type: null,
      retirementFilterUrl: { firstDate, secondDate, searchDentist },
    })

    retirementApiCall(path)
  }

  const handleIntraFilter = async (e, searchDentist, firstDate, secondDate) => {
    e.preventDefault()
    const path = `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=intraoral&filter[rejected]=0&filter[registration_datetime]=${firstDate},${secondDate}&filter[principalDentist.fullname]=${searchDentist}&filter[no_external_id]=1`

    setState({
      ...state,
      type: null,
      intraoralFilterUrl: { firstDate, secondDate, searchDentist },
    })

    intraoralApiCall(path)
  }

  const clearIntraFilter = async () => {
    intraoralApiCall(
      `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=intraoral&filter[rejected]=0&filter[no_external_id]=1`
    )

    setState({
      ...state,
      searchIntra: '',
      intraDateOne: '',
      intraDateTwo: '',
      filter_intraoral: false,
      intraoralFilterUrl: null,
    })
  }

  const clearRetirementFilter = async () => {
    retirementApiCall(
      `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=retirement&filter[rejected]=0&filter[no_external_id]=1`
    )

    setState({
      ...state,
      searchYeso: '',
      yesoDateOne: '',
      yesoDateTwo: '',
      filter_yeso: false,
      retirementFilterUrl: null,
    })
  }

  const handleRetirementPagination = async (currentPage) => {
    let path

    if (state.retirementFilterUrl)
      path = `/api/incomes?page=${currentPage}&include=patient,dentists,status,type&filter[type_income]=retirement&filter[rejected]=0&filter[registration_datetime]=${state.retirementFilterUrl.firstDate},${state.retirementFilterUrl.secondDate}&filter[principalDentist.fullname]=${state.retirementFilterUrl.searchDentist}&filter[no_external_id]=1`
    else
      path = `/api/incomes?page=${currentPage}&include=patient,dentists,status,type&filter[type_income]=retirement&filter[rejected]=0&filter[no_external_id]=1`
    retirementApiCall(path)
  }

  const handleIntraoralPagination = async (currentPage) => {
    let path

    if (state.intraoralFilterUrl)
      path = `/api/incomes?page=${currentPage}&include=patient,dentists,status,type&filter[type_income]=intraoral&filter[rejected]=0&filter[registration_datetime]=${state.intraoralFilterUrl.firstDate},${state.intraoralFilterUrl.secondDate}&filter[principalDentist.fullname]=${state.intraoralFilterUrl.searchDentist}&filter[no_external_id]=1`
    else
      path = `/api/incomes?page=${currentPage}&include=patient,dentists,status,type&filter[type_income]=intraoral&filter[rejected]=0&filter[no_external_id]=1`

    intraoralApiCall(path)
  }

  const loadTodosData = () => {
    retirementApiCall(
      `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=retirement&filter[rejected]=0&filter[no_external_id]=1`
    )

    intraoralApiCall(
      `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=intraoral&filter[rejected]=0&filter[no_external_id]=1`
    )

    setState({
      type: 'todos',
      loading: false,
      filter_intraoral: false,
      filter_yeso: false,
      modal_id: null,
      modal_show: false,
      searchIntra: '',
      intraDateOne: '',
      intraDateTwo: '',
      searchYeso: '',
      yesoDateOne: '',
      yesoDateTwo: '',
      retirementFilterUrl: null,
      intraoralFilterUrl: null,
    })
  }

  //set initial data
  useEffect(() => {
    retirementApiCall(
      `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=retirement&filter[rejected]=0&filter[no_external_id]=1`
    )

    intraoralApiCall(
      `/api/incomes?page=1&include=patient,dentists,status,type&filter[type_income]=intraoral&filter[rejected]=0&filter[no_external_id]=1`
    )

    informationApiCall()

    setState({
      type: 'todos',
      loading: false,
      filter_intraoral: false,
      filter_yeso: false,
      modal_id: null,
      modal_show: false,
      searchIntra: '',
      intraDateOne: '',
      intraDateTwo: '',
      searchYeso: '',
      yesoDateOne: '',
      yesoDateTwo: '',
      retirementFilterUrl: null,
      intraoralFilterUrl: null,
    })
  }, [])

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <div className="container mt-2">
          <ClinicHistoryModal
            id={state.modal_id}
            opened={state.modal_show}
            handleModal={handleModal}
            isApplication={true}
          />
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontWeight: 700 }} className="text-primary mt-3">
                Ingresos
              </h1>
            </div>
            <div className="col-9 mt-4 d-flex flex-row justify-content-between pt-3 align-items-center">
              <div className="d-flex flex-row">
                <div
                  className="row shadow py-2 mx-2 border border-1 rounded"
                  style={{
                    maxWidth: '250px',
                    maxHeight: '65px',
                    marginBottom: '11px',
                  }}
                >
                  <div className="col-4">
                    <h2 className="justify-content-center align-middle text-center m-0">
                      {stats?.total_pending}
                    </h2>
                  </div>
                  <div className="col-8">
                    <p className="justify-content-center align-middle m-0">
                      Solicitudes de ingreso pendientes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 d-flex flex-column mt-4 px-4 pt-4 align-items-center">
              <Link
                to="/ingresos/nuevo"
                className="btn btn-primary rounded-pill px-5 my-2"
              >
                Nuevo ingreso
              </Link>
            </div>
          </div>
          <hr />
          <button
            className={`text-primary fw-light fs-6 btn border-0 m-0 ${
              state.type === 'todos' && 'fw-bold'
            }`}
            onClick={() => loadTodosData()}
          >
            Todos
          </button>
          <div className="row mt-2 d-flex">
            <div className="col-6 d-flex flex-column p-2">
              <button
                className="btn d-flex flex-row justify-content-between text-primary w-100 p-2 bg-white rounded shadow border border-1"
                onClick={() =>
                  setState({ ...state, filter_yeso: !state.filter_yeso })
                }
              >
                <p className="m-0 w-100"> Retiro de Impresiones</p>
                <ArrowDown size={18} />
              </button>
              {state.filter_yeso && (
                <div className="incomes-filter-container mx-2 rounded-bottom bg-white">
                  <form
                    className="px-4 py-2 shadow"
                    onSubmit={(e) => {
                      console.log(state.searchYeso, state.yesoDateOne)
                      handleRetirementFilter(
                        e,
                        state.searchYeso,
                        state.yesoDateOne,
                        state.yesoDateTwo
                      )
                    }}
                  >
                    <div className="row">
                      <label>Fecha de solicitud</label>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_from"
                          id="date_from"
                          value={state.yesoDateOne}
                          onChange={(e) => {
                            setState({ ...state, yesoDateOne: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_to"
                          id="date_to"
                          value={state.yesoDateTwo}
                          onChange={(e) => {
                            setState({ ...state, yesoDateTwo: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="dentist">Doctor</label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          name="dentist"
                          id="dentist"
                          value={state.searchYeso}
                          onChange={(e) => {
                            setState({ ...state, searchYeso: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12 d-flex flex-row justify-content-end">
                        <button
                          className="btn btn-outline-primary rounded-pill px-3 mx-2"
                          onClick={() => clearRetirementFilter()}
                        >
                          Limpiar filtro
                        </button>
                        <button className="btn btn-outline-primary rounded-pill px-3">
                          Aplicar filtro
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <div>
                {retirement.treatments.map((item, i) => {
                  return (
                    <div key={i}>
                      <div
                        className={`w-100 shadow border border-1 rounded container-fluid tc-container text-start ps-4 mb-2 mt-2 ps-2 cursor-pointer`}
                        onClick={() => handleModal(item.id)}
                      >
                        <button
                          className={`clinic-history-button type-${handleTreatmentColor(
                            item?.type_treatment
                          )}`}
                          onClick={() => handleModal(item.id)}
                        />
                        <TreatmentCard
                          id={item.id}
                          application_date={item?.application_date}
                          external_id={item.external_id}
                          doctor={item.dentists[0].user}
                          patient={item.patient}
                          request_time={item.retirement_date}
                          maxillary={item.maxillary}
                          treatment_name={item.type_treatment}
                          treatment_phase={item.order}
                          handleModal={handleModal}
                          filter="all"
                          type="yeso"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="mt-3">
                <Paginator
                  className="mt-3"
                  paginator={retirement.paginator}
                  getPaginator={handleRetirementPagination}
                />
              </div>
            </div>

            <div className="col-6 d-flex flex-column p-2">
              <button
                className="btn d-flex flex-row justify-content-between text-primary w-100 p-2 bg-white rounded shadow border border-1"
                onClick={() =>
                  setState({
                    ...state,
                    filter_intraoral: !state.filter_intraoral,
                  })
                }
              >
                <p className="m-0 w-100"> Escaneo Intraoral</p>
                <ArrowDown size={18} />
              </button>
              {state.filter_intraoral && (
                <div className="incomes-filter-container mx-2 rounded-bottom bg-white">
                  <form
                    className="px-4 py-2 shadow"
                    onSubmit={(e) =>
                      handleIntraFilter(
                        e,
                        state.searchIntra,
                        state.intraDateOne,
                        state.intraDateTwo
                      )
                    }
                  >
                    <div className="row">
                      <label>Fecha de solicitud</label>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_from"
                          id="date_from"
                          value={state.intraDateOne}
                          onChange={(e) => {
                            setState({ ...state, intraDateOne: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_to"
                          id="date_to"
                          value={state.intraDateTwo}
                          onChange={(e) => {
                            setState({ ...state, intraDateTwo: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="dentist">Doctor</label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          name="dentist"
                          id="dentist"
                          value={state.searchIntra}
                          onChange={(e) => {
                            setState({ ...state, searchIntra: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12 d-flex flex-row justify-content-end">
                        <button
                          className="btn btn-outline-primary rounded-pill px-3 mx-2"
                          onClick={() => clearIntraFilter()}
                        >
                          Limpiar filtro
                        </button>
                        <button className="btn btn-outline-primary rounded-pill px-3">
                          Aplicar filtro
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <div>
                {intraoral.treatments.map((item, i) => {
                  return (
                    <div key={i}>
                      <div
                        className={`w-100 shadow border border-1 rounded container-fluid tc-container text-start ps-4 mt-2 mb-2 cursor-pointer`}
                        onClick={() => handleModal(item.id)}
                      >
                        <button
                          className={`clinic-history-button type-${handleTreatmentColor(
                            item?.type_treatment
                          )}`}
                          onClick={() => handleModal(item.id)}
                        />
                        <TreatmentCard
                          id={item.id}
                          external_id={item.external_id}
                          doctor={
                            item.dentists.length > 0 && item.dentists[0].user
                          }
                          application_date={item?.application_date}
                          patient={item.patient}
                          request_time={item.retirement_date}
                          maxillary={item.maxillary}
                          treatment_name={item.type_treatment}
                          treatment_phase={item.order}
                          handleModal={handleModal}
                          filter="all"
                          type="intraoral"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="mt-3">
                <Paginator
                  className="mt-3"
                  paginator={intraoral.paginator}
                  getPaginator={handleIntraoralPagination}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Incomes
