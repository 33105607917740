import apiClient from '../utils/backend'

const proposalService = {}

proposalService.editProposal = async ({ proposalId, params }) => {
  return await apiClient
    .put(`api/proposals/${proposalId}`, params)
    .then((res) => res.data)
    .catch((err) => {
      throw err?.response?.data
    })
}

proposalService.deleteProposal = async (proposalId) => {
  return await apiClient
    .delete(`api/proposals/${proposalId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err?.response?.data
    })
}

export default proposalService
