import React from 'react'

const TypeFilterComponent = ({ state, typeFilterHandler }) => {
  return (
    <ul className="d-flex p-0 col-12 mt-3" style={{ listStyleType: 'none' }}>
      <li
        className={`text-primary pointer fw-normal me-5 ${
          state.type === 1 && 'fw-bold'
        }`}
        onClick={() => typeFilterHandler(1)}
      >
        Todos
      </li>
      <li
        className={`text-primary pointer fw-normal me-5 ${
          state.type === 2 && 'fw-bold'
        }`}
        onClick={() => {
          typeFilterHandler(2)
        }}
      >
        Desactivados
      </li>
      {state.countryId === 1 && (
        <>
          <li
            className={`text-primary pointer  fw-normal me-5 ${
              state.type === 3 && 'fw-bold'
            }`}
            onClick={() => typeFilterHandler(3)}
          >
            SAO
          </li>
          <li
            className={`text-primary pointer fw-normal me-5 ${
              state.type === 4 && 'fw-bold'
            }`}
            onClick={() => typeFilterHandler(4)}
          >
            Study Group
          </li>
        </>
      )}
      {state.countryId === 5 && (
        <li
          className={`text-primary pointer  fw-normal me-5 ${
            state.type === 55235 && 'fw-bold'
          }`}
          onClick={() => typeFilterHandler(55235)}
        >
          Uno Salud
        </li>
      )}
      {state.countryId === 4 && (
        <li
          className={`text-primary pointer  fw-normal me-5 ${
            state.type === 5 && 'fw-bold'
          }`}
          onClick={() => typeFilterHandler(5)}
        >
          Pontificia Universidad Javeriana
        </li>
      )}
    </ul>
  )
}

export default TypeFilterComponent
