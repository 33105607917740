import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import handleToast from '../../../../../../../components/toaster'
import apiClient from '../../../../../../../utils/backend'
import 'react-toastify/dist/ReactToastify.css'

const AprobarModal = ({ item, userName, loadData }) => {
  const [opened, setOpened] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const toggle = () => setOpened(true)

  const handleAprobar = async () => {
    setLoading(true)
    try {
      await apiClient.post(`/api/proposals/${item.id}/approve`)
      setLoading(false)
      handleToast('success', 'Propuesta de tratamiento aprobada!')
      setOpened(false)
      loadData(true, false)
    } catch (err) {
      setError('El tratamiento ya tiene una propuesta aprobada.')
    }
  }

  return (
    <>
      <div className="green-proposal-btn shadow mt-3 me-1" onClick={toggle}>
        Aprobar
      </div>
      <Modal className="AM-container" isOpen={opened}>
        <ModalBody>
          <div className="p-2 d-flex flex-column align-items-center">
            <div className="fw-bold fs-3 my-4">Aprobar Tratamiento</div>
            <div className="fw-bold my-4">ACUERDO DE TRATAMIENTO</div>
            <div className="AM-first-text fw-bold">
              Aprobando este tratamiento confeccionado por KeepSmiling para el
              paciente {userName[0]} {userName[1]}, usted se muestra de acuerdo
              a que este se ajusta al Plan de Tratamiento propuesto en el
              Formulario de Diagnostico y Tratamiento.
            </div>
            <div className="AM-second-text">
              Una vez que aprueba la propuesta de tratamiento, KeepSmiling
              iniciara la producción de las placas alineadoras.
            </div>
            {error && <p className="my-0 red fw-bold fs-7"> {error} </p>}
            <div
              className={`AM-btn PD-green ${loading ? 'disabled' : null}`}
              onClick={() => handleAprobar()}
            >
              APROBAR AHORA
            </div>
            <div
              className="AM-btn PD-red"
              onClick={() => {
                setOpened(false)
                setLoading(false)
                setError(null)
              }}
            >
              NO AHORA
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AprobarModal
