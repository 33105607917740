import { toast } from 'react-toastify'
import handleToast from '../../components/toaster'
import apiClient from '../../utils/backend'

export const getData = (currentPage) => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get(
        `/api/treatments?page=${currentPage}&cant_pages=15&include=dentists`
      )
      const data = {
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      }
      dispatch(setTreatments(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getDataFromCountry = (id) => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get(
        `/api/treatments?page=1&cant_pages=15&include=dentists,patient.user.groups&filter[patient.user.country.id]=${id}`
      )
      const data = {
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      }
      dispatch(setTreatments(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getDataSao = (id) => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get(
        `/api/treatments?page=1&cant_pages=15&include=dentists,patient.user.groups&filter[patient.user.groups.id]=${id}`
      )
      console.log(res)
      const data = {
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      }
      dispatch(setTreatments(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getDataFiltered = (path) => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get(path)
      const data = {
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      }
      if (data.treatments.length === 0) {
        return toast.error(
          '🤔 No se encontraron tratamientos con ese filtro!',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      }
      dispatch(setTreatments(data))
      toast.success('Filtro aplicado correctamente 😀', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }
}

export const componentDidUpdate = (cant) => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get(
        `/api/treatments/?page=1&cant_pages=${cant}`
      )
      const data = {
        treatments: res.table.body,
        paginator: res.paginator,
      }
      dispatch(setTreatments(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const deleteTreatment = (id) => {
  return async (dispatch) => {
    handleToast('updating', 'Eliminando datos del tratamiento...')
    try {
      const res = await apiClient.delete(`/api/deleteTreatment?id=${id}`)
      if (res.data.code) {
        dispatch(getData(15))
        handleToast('success', 'Tratamiento eliminado')
        setTimeout(() => {
          window.location.reload()
        }, 4000)
      } else {
        handleToast('error', 'Tratamiento ya eliminado')
      }
    } catch (error) {
      console.log(error)
      handleToast('error', 'Error al eliminar tratamiento, intente nuevamente')
    }
  }
}

export const getDentist = () => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get('api/dentists')
      dispatch(setDentist(res.data.table.body))
    } catch (error) {
      console.log(error)
    }
  }
}

const setDentist = (d) => ({ type: 'GET_DENTIST', payload: d })

const setTreatments = (t) => ({ type: 'GET_OD_TREATMENTS', payload: t })
