import React from 'react'
import style from './treatment-tag.module.css'
import PropTypes from 'prop-types'

const TreatmentTag = ({ treatmentType }) => {
  if (treatmentType === 1) return <div className={style.full}>FULL</div>
  if (treatmentType === 2) return <div className={style.fast}>FAST</div>
  if (treatmentType === 3) return <div className={style.medium}>MED</div>
  if (treatmentType === 4) return <div className={style.kids}>KIDS</div>
  if (treatmentType === 5) return <div className={style.kdFast}>KIDS FAST</div>
  if (treatmentType === 6) return <div className={style.teen}>TEEN</div>
  return <div className={style.empty}>-</div>
}

export default TreatmentTag

TreatmentTag.propTypes = {
  treatmentType: PropTypes.number,
}
