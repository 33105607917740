import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ksSmileImage from '../../assets/images/ks-smile.png'
import apiClient from '../../utils/backend'
import './dentistConsult.css'
import displayImage from './Utilis'

const DentistConsult = () => {
  const [data, setData] = useState()

  const { countryId } = useSelector((state) => state.userReducer)

  const requestData = async () => {
    try {
      const res = await apiClient(
        `/api/consults?filter[country_id]=${countryId}`
      )
      setData(res.data.table.body)
      console.log(res.data.table.body)
    } catch (err) {
      console.log(err)
    }
  }

  //Load initial data
  useEffect(() => {
    requestData()
  }, [])

  if (!data) return null
  return (
    <div className="fw-bold fs-2 d-flex flex-column  ">
      <div className="consult-header-container px-5 py-3">
        <img className="my-4" src={ksSmileImage} alt="ks-smile" width={120} />
        <p className="my-0 fs-2">¡Estamos para ayudarte!</p>
        <p className="my-0 fs-4 fw-light my-2">
          Para una mejor atención, a continuación clasifica el tipo de consulta
          que quieres realizar
        </p>
      </div>
      <div
        className={`d-flex px-3 mb-5 mt-5 flex-wrap justify-content-center mx-auto w-100`}
        style={{ maxWidth: '1500px' }}
      >
        {data.map((item, i) => {
          return (
            <div className="shadow consult-card-container p-3 m-2" key={i}>
              <div>
                <div
                  className="d-flex justify-content-between flex-column"
                  style={{ height: 150, alignItems: 'center' }}
                >
                  <img
                    src={displayImage(item.title)}
                    alt="card-icon"
                    width={item.title === 'Otras' ? 45 : 65}
                    height={65}
                    className="mt-3"
                  />
                  <p style={{ fontSize: 16 }} className="my-0 text-center">
                    {item.title}
                  </p>
                </div>
                <p
                  className="mt-3 mb-5"
                  style={{ fontSize: 13, fontWeight: '200' }}
                >
                  {item.description}
                </p>
              </div>
              <a
                href={item.link_whapp}
                style={{ fontSize: 13, fontWeight: '600' }}
                className="consult-send-btn"
                target="_blank"
              >
                Enviar una consulta
              </a>
            </div>
          )
        })}
      </div>
      <div className="d-flex px-5">
        <p className="fw-light fs-6 mx-auto my-0">
          En caso de ser una consulta de{' '}
          <span className="fw-bold">urgencia</span> puede comunicarse por
          WhatsApp haciendo click{' '}
          <a
            style={{ borderBottom: '2px solid rgb(0, 21, 92)' }}
            href="https://web.whatsapp.com/"
          >
            aquí
          </a>
        </p>
      </div>
    </div>
  )
}

export default DentistConsult
