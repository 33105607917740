import React from 'react'
import ImageDisplay from '../utilities/ImageDisplay'
import ImageInput from '../utilities/ImageInput'

const OtherStudies = ({ backendFiles, othStudies, setOthStudies }) => {
  const handleImageSelect = (image) => {
    const newFile = { file: image }
    setOthStudies([...othStudies, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newPlanFiles = othStudies
    newPlanFiles.splice(indexToDelete, 1)
    setOthStudies([...newPlanFiles])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newPlanFiles = othStudies
    newPlanFiles[indexToEdit].file = image
    setOthStudies([...newPlanFiles])
  }

  return (
    <>
      {backendFiles.otherStudies.map((item, i) => (
        <ImageDisplay
          key={i}
          name={`Otros - ${i + 1}`}
          previewImage={item.thumb}
          image={item.path}
        />
      ))}
      {othStudies.map((item, i) => (
        <ImageInput
          index={i}
          name={`Otros - ${backendFiles.otherStudies.length + i + 1}`}
          src={'otros'}
          imageToSave={{
            image: item.file,
          }}
          handleEditPlan={handleEditPlan}
          handleDeletePlan={handleDeletePlan}
        />
      ))}
      {backendFiles.otherStudies.length + othStudies.length < 5 && (
        <ImageInput
          name={'Cargar Otros'}
          src={'otros'}
          id={'Otros'}
          imageToSave={null}
          handleImageSelect={handleImageSelect}
        />
      )}
    </>
  )
}

export default OtherStudies
