import React, { useState } from 'react'
import Paginator from '../../components/Paginator'
import Searchbar from '../../components/searchbar'
import PatientCard from './components/patient_card'
import ClinicHistoryModal from '../../components/MedicalRecordModal/ClinicHistoryModal'
import Loader from '../../components/loader'
import handleToast from '../../components/toaster'
import CountryFilterComponent from '../../components/CountryFilters/CountryFilterComponent'
import TypeFilterComponent from './components/TypeFilterComponent'
import { usePatientIndex } from './hooks/usePatientIndex'
import NotFoundComponent from '../../components/NotFound.js/NotFoundComponent'

const Pacientes = () => {
  const [modalState, setModalState] = useState({
    modal_show: false,
    modal_id: null,
  })

  const {
    state,
    setState,
    changeCountryHandler,
    changeTypeHandler,
    getUrl,
    handleChangePaginator,
    setInitialData,
  } = usePatientIndex()

  const handleModal = (id) => {
    if (id) return setModalState({ modal_show: true, modal_id: id })
    else
      return setModalState({
        modal_id: null,
        modal_show: false,
      })
  }

  const handleOpenMR = (patient) => {
    if (patient?.treatments[0]?.id)
      return handleModal(patient?.treatments[0]?.id)
    handleToast('error', 'El paciente no cuenta con tratamiento')
  }

  if (state.loading) return <Loader />
  return (
    <div className="container mt-4">
      <ClinicHistoryModal
        id={modalState.modal_id}
        opened={modalState.modal_show}
        handleModal={handleModal}
      />
      <div className="row mt-3 mb-3 d-flex justify-content-between">
        <div className="col-8 d-flex  justify-content-between px-4">
          <h1 style={{ fontWeight: 700 }} className="text-primary">
            Pacientes
          </h1>
        </div>
        <div className="mt-2 me-3" style={{ width: '250px' }}>
          <Searchbar
            page="patient"
            url={`${getUrl(state.countryId, state.type)}&`}
            state={state}
            setState={setState}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between">
        <TypeFilterComponent
          state={state}
          changeTypeHandler={changeTypeHandler}
        />
        <CountryFilterComponent
          state={state.countryId}
          changeCountryHandler={changeCountryHandler}
        />
      </div>
      <hr />
      <div className="d-flex mt-3 ">
        <div className="col-3 d-flex fw-bold justify-content-center">
          Paciente
        </div>
        <div className="col-3 d-flex fw-bold justify-content-center">
          Doctor
        </div>
        <div className="col-6 d-flex">
          <div className="col-4 d-flex fw-bold justify-content-center">
            Perfil
          </div>
          <div className="col-3 d-flex fw-bold justify-content-center">
            Tratamiento
          </div>
        </div>
      </div>
      <div className="row mt-3 px-4">
        <div className="col-12">
          {state.patients.map((patient, i) => {
            return (
              <div
                className={` shadow border rounded  py-2 my-2 position-relative`}
              >
                <button
                  className={`clinic-history-button type-1`}
                  onClick={() => handleOpenMR(patient)}
                />
                <PatientCard
                  key={patient.id}
                  patient={patient}
                  setInitialData={setInitialData}
                  handleOpenMR={handleOpenMR}
                />
              </div>
            )
          })}
        </div>

        {state.patients.length < 1 && <NotFoundComponent />}
      </div>
      <div className="row px-4">
        <Paginator
          paginator={state.paginator}
          getPaginator={handleChangePaginator}
        />
      </div>
    </div>
  )
}

export default Pacientes
