import React from 'react'
import TreatmentStateStepBar from '../../../../components/TreatmentStateStepBar'
import '../styles/styles.css'
import { handleRedirect } from '../../../Notificaciones/utilities/helpers'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const NotificationCard6 = ({ data }) => {
  const { treatment } = data
  const history = useHistory()
  return (
    <div className="w-100 notificationCard my-2 p-3 d-flex">
      <div className="middleLine pe-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap">
            <div className="circleNotification">
              {treatment.frontal_sonriendo !== null && (
                <img src={treatment.frontal_sonriendo} alt="avatar" />
              )}
            </div>
            <div className="d-flex flex-column ms-2">
              <p>{`${treatment.patient.name} ${treatment.patient.surname}`}</p>
              <p className="fw-bold">Reclamo de Documentación</p>
            </div>
          </div>
          <div>
            <p className="text-muted notificationTime">
              {`${moment(data.created_at).startOf('minutes').fromNow()}`}
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="circleDisabled"></div>
          <div>
            <p className="treatment-state-notifiaction-dentist">
              Su paciente tiene la documentación incompleta. Recuerde que
              KeepSmiling no dará comienzo a la planificación del tratamiento
              hasta no contar con toda la documentación requerida.
            </p>
            <div className="d-flex w-50 my-3 ms-2">
              {!treatment.estudios && (
                <button className="pendingDocBTN me-1">Estudios</button>
              )}
              {!treatment.fotos && (
                <button className="pendingDocBTN mx-1">Fotos</button>
              )}
              {!treatment.plan && (
                <button className="pendingDocBTN mx-1">Plan</button>
              )}
            </div>
            <button
              className="medicalRecordBTN ms-2 py-1 px-4"
              onClick={() => handleRedirect(treatment.id, history)}
            >
              Historia Clinica
            </button>
          </div>
        </div>
      </div>
      <div className="treatment-state-notifiaction-dentist">
        <TreatmentStateStepBar type={6} />
      </div>
    </div>
  )
}

export default NotificationCard6
