import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PretratmentDiagnosis from './PretreatmentDiagnosis'
import TratmentInstructions from './TratmentInstructions'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from '../propsStateConnect'
import { useErrorList } from './Utilities/UseErrorsList'
import './index.css'
import { ChevronLeft, ChevronRight } from 'react-feather'
import TextAreaCounter from './Utilities/TextAreaCounter'

function PacientData(props) {
  type_dispatch.step = 'AestheticPriority'
  type_dispatch.field = 'General'
  const state_form = props.formOnlineReducer

  const { colors, updateState, updateErrors, enable, nextParentStep } = props

  const [stepMain, setStepMain] = useState(
    state_form.CurrentStep.Step.ParentStep
  )
  const [stepPretratment, setStepPretratment] = useState(
    state_form.CurrentStep.Step.ParentStep === 2
      ? state_form.CurrentStep.Step.ChildStep
      : 1
  )
  const [stepInstructions, setStepInstructions] = useState(
    state_form.CurrentStep.Step.ParentStep === 3
      ? state_form.CurrentStep.Step.ChildStep
      : 1
  )

  useEffect(() => {
    enable(true)
  }, [enable])

  const nextStepMain = () => {
    if (!validateRequiredFields()) return
    if (stepMain === 2 && stepPretratment < 4) {
      setStepPretratment(stepPretratment + 1)
      saveStep(stepMain, stepPretratment + 1)
    } else if (stepMain === 3 && stepInstructions < 11) {
      setStepInstructions(stepInstructions + 1)
      saveStep(stepMain, stepInstructions + 1)
    } else if (stepMain < 3) {
      setStepMain(stepMain + 1)
      saveStep(stepMain + 1, 1)
    }
  }

  const backStepMain = () => {
    if (stepMain === 2 && stepPretratment > 1) {
      setStepPretratment(stepPretratment - 1)
      saveStep(2, stepPretratment - 1)
    } else if (stepMain === 3 && stepInstructions > 1) {
      setStepInstructions(stepInstructions - 1)
      saveStep(3, stepInstructions - 1)
    } else if (stepMain > 1) {
      setStepMain(stepMain - 1)
      saveStep(stepMain - 1, stepMain - 1 === 2 ? 4 : 1)
    }
  }

  const saveStep = (parent, child) => {
    type_dispatch.step = 'CurrentStep'
    type_dispatch.field = 'Step'
    updateState({ key: 'ParentStep', value: parent })
    updateState({ key: 'ChildStep', value: child })
    type_dispatch.step = 'AestheticPriority'
    type_dispatch.field = 'General'
  }

  const finishFormTyping = () => {
    if (!validateRequiredFields()) return
    nextParentStep(state_form)
    saveStep(1, 1)
  }

  const validateRequiredFields = () => {
    let success = true
    let errorsList = []
    switch (stepMain) {
      default:
        break
      case 1:
        if (!state_form.AestheticPriority.General.description) {
          errorsList = [
            ...errorsList,
            { order: 1, message: 'Complete el campo descripción' },
          ]
          success = false
        }
        if (state_form.AestheticPriority.General.description.length > 2500) {
          errorsList = [
            ...errorsList,
            { order: 3, message: 'Límite de caracteres superado' },
          ]
          success = false
        }
        if (!state_form.Header.MainInformation.age) {
          errorsList = [
            ...errorsList,
            { order: 2, message: 'Complete el campo edad' },
          ]
          success = false
        }
        break
      case 2:
        switch (stepPretratment) {
          default:
            let movErros = state_form.PretratmentDiagnosisValues.Movements
            let arr1 = movErros.permanentOdontogramsUp.find(
              (value) => value !== 0
            )
            let arr2 = movErros.permanentOdontogramsDown.find(
              (value) => value !== 0
            )
            let arr3 = movErros.temporalOdontogramsUp.find(
              (value) => value !== 0
            )
            let arr4 = movErros.temporalOdontogramsDown.find(
              (value) => value !== 0
            )
            if (!movErros.movement) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (
              arr1 === undefined &&
              arr2 === undefined &&
              arr3 === undefined &&
              arr4 === undefined &&
              movErros.movement === 2
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione mínimo un diente' },
              ]
              success = false
            }

            if (movErros.movement === 2 && movErros.description.length > 2500) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }

            break
          case 2:
            let extErros = state_form.PretratmentDiagnosisValues.Extractions
            if (!extErros.extraction) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            let arr5 = extErros.permanentOdontogramsUp.find(
              (value) => value !== 0
            )
            let arr6 = extErros.permanentOdontogramsDown.find(
              (value) => value !== 0
            )
            let arr7 = extErros.temporalOdontogramsUp.find(
              (value) => value !== 0
            )
            let arr8 = extErros.temporalOdontogramsDown.find(
              (value) => value !== 0
            )
            if (
              arr5 === undefined &&
              arr6 === undefined &&
              arr7 === undefined &&
              arr8 === undefined &&
              extErros.extraction === 3
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione mínimo un diente' },
              ]
              success = false
            }

            break
          case 3:
            if (
              !state_form.PretratmentDiagnosisValues.VirtualTooths.virtualTooth
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }

            if (
              state_form.PretratmentDiagnosisValues.VirtualTooths
                .virtualTooth === 1 &&
              state_form.PretratmentDiagnosisValues.VirtualTooths.description
                .length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 4:
            let attErros = state_form.PretratmentDiagnosisValues.Attachments
            if (!attErros.attachment) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }

            let arr9 = attErros.permanentOdontogramsUp.find(
              (value) => value !== 0
            )
            let arr10 = attErros.permanentOdontogramsDown.find(
              (value) => value !== 0
            )
            let arr11 = attErros.temporalOdontogramsUp.find(
              (value) => value !== 0
            )
            let arr12 = attErros.temporalOdontogramsDown.find(
              (value) => value !== 0
            )
            if (
              arr9 === undefined &&
              arr10 === undefined &&
              arr11 === undefined &&
              arr12 === undefined &&
              attErros.attachment === 3
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione mínimo un diente' },
              ]
              success = false
            }

            if (
              attErros.attachment === 3 &&
              attErros.description.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }

            break
        }
        break
      case 3:
        switch (stepInstructions) {
          default:
            if (!state_form.InstructionsTratment.MiddleLine.middleLineHigh) {
              errorsList = [
                ...errorsList,
                { order: 10, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (!state_form.InstructionsTratment.MiddleLine.middleLineLow) {
              errorsList = [
                ...errorsList,
                { order: 11, message: 'Seleccione una opción' },
              ]
              success = false
            }

            if (
              state_form.InstructionsTratment.MiddleLine.middleLineHigh !== 1
            ) {
              if (
                parseInt(
                  state_form.InstructionsTratment.MiddleLine.amountMMMoveHihg
                ) > 100 ||
                state_form.InstructionsTratment.MiddleLine.amountMMMoveHihg == 0
              ) {
                errorsList = [
                  ...errorsList,
                  {
                    order: 10,
                    message: 'La opción debe ser mayor a 0 o menor a 100',
                  },
                ]
                success = false
              }
            }

            if (
              state_form.InstructionsTratment.MiddleLine.middleLineLow !== 1
            ) {
              if (
                parseInt(
                  state_form.InstructionsTratment.MiddleLine.amountMMMoveLow
                ) > 100 ||
                state_form.InstructionsTratment.MiddleLine.amountMMMoveLow == 0
              ) {
                errorsList = [
                  ...errorsList,
                  {
                    order: 11,
                    message: 'La opción debe ser mayor a 0 y menor a 100',
                  },
                ]
                success = false
              }
            }

            break
          //
          case 2:
            let OverjetOverbiteErros =
              state_form.InstructionsTratment.OverjetOverbite

            if (OverjetOverbiteErros.overjet) {
              if (OverjetOverbiteErros.overjet === 2) {
                if (
                  OverjetOverbiteErros.overjetUp.length > 0 ||
                  OverjetOverbiteErros.overjetDown.length > 0
                ) {
                  // Izquierda
                  if (OverjetOverbiteErros.overjetUp.includes(5)) {
                    if (!OverjetOverbiteErros.overjetUpOthers) {
                      errorsList = [
                        ...errorsList,
                        { order: 1, message: 'Complete el campo otros' },
                      ]
                      success = false
                    }
                    if (OverjetOverbiteErros.overjetUpOthers.length > 2500) {
                      errorsList = [
                        ...errorsList,
                        { order: 3, message: 'Límite de caracteres superado' },
                      ]
                      success = false
                    }
                  }
                  //Derecha
                  if (OverjetOverbiteErros.overjetDown.includes(5)) {
                    if (!OverjetOverbiteErros.overjetDownOthers) {
                      errorsList = [
                        ...errorsList,
                        { order: 1, message: 'Complete el campo otros' },
                      ]
                      success = false
                    }
                    if (OverjetOverbiteErros.overjetDownOthers.length > 2500) {
                      errorsList = [
                        ...errorsList,
                        { order: 4, message: 'Límite de caracteres superado' },
                      ]
                      success = false
                    }
                  }
                } else {
                  errorsList = [
                    ...errorsList,
                    { order: 1, message: 'Seleccione mínimo una opción' },
                  ]
                  success = false
                }
              }
            } else {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (OverjetOverbiteErros.overbite) {
              if (OverjetOverbiteErros.overbite === 2) {
                if (
                  OverjetOverbiteErros.overbiteUp.length > 0 ||
                  OverjetOverbiteErros.overbiteDown.length > 0
                ) {
                  // Izquierda
                  if (OverjetOverbiteErros.overbiteUp.includes(5)) {
                    if (!OverjetOverbiteErros.overbiteUpOthers) {
                      errorsList = [
                        ...errorsList,
                        { order: 2, message: 'Complete el campo otros' },
                      ]
                      success = false
                    }
                    if (OverjetOverbiteErros.overbiteUpOthers.length > 2500) {
                      errorsList = [
                        ...errorsList,
                        { order: 5, message: 'Límite de caracteres superado' },
                      ]
                      success = false
                    }
                  }
                  //Derecha
                  if (OverjetOverbiteErros.overbiteDown.includes(5)) {
                    if (!OverjetOverbiteErros.overbiteDownOthers) {
                      errorsList = [
                        ...errorsList,
                        { order: 2, message: 'Complete el campo otros' },
                      ]
                      success = false
                    }
                    if (OverjetOverbiteErros.overbiteDownOthers.length > 2500) {
                      errorsList = [
                        ...errorsList,
                        { order: 6, message: 'Límite de caracteres superado' },
                      ]
                      success = false
                    }
                  }
                } else {
                  errorsList = [
                    ...errorsList,
                    { order: 2, message: 'Seleccione mínimo una opción' },
                  ]
                  success = false
                }
              }
            } else {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Seleccione una opción' },
              ]
              success = false
            }

            break
          case 3:
            let canineErros = state_form.InstructionsTratment.CanineRelationship
            if (!canineErros.right) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (canineErros.right === 2 && !canineErros.rightDescription) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Complete el campo descripción' },
              ]
              success = false
            }
            if (
              canineErros.right === 2 &&
              canineErros.rightDescription.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 4, message: 'Límite de caracteres superado' },
              ]
              success = false
            }

            if (!canineErros.left) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (canineErros.left === 2 && !canineErros.leftDescription) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Complete el campo descripción' },
              ]
              success = false
            }
            if (
              canineErros.left === 2 &&
              canineErros.leftDescription.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 3, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 4:
            let molarErros = state_form.InstructionsTratment.MolarRelationship
            if (!molarErros.right) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (molarErros.right === 2 && !molarErros.rightDescription) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Complete el campo descripción' },
              ]
              success = false
            }
            if (
              molarErros.right === 2 &&
              molarErros.rightDescription.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 4, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            if (!molarErros.left) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (molarErros.left === 2 && !molarErros.leftDescription) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Complete el campo descripción' },
              ]
              success = false
            }
            if (
              molarErros.left === 2 &&
              molarErros.leftDescription.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 3, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 5:
            let crossbiteErros = state_form.InstructionsTratment.CrossBite
            if (!crossbiteErros.crossBite) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (
              crossbiteErros.crossBite === 2 &&
              crossbiteErros.improveCrossBite.length === 0
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione mínimo una opción' },
              ]
              success = false
            }
            if (
              crossbiteErros.crossBite === 2 &&
              crossbiteErros.improveCrossBite.includes(5) &&
              !crossbiteErros.improveCrossBiteOthers
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Complete el campo otros' },
              ]
              success = false
            }
            if (
              crossbiteErros.crossBite === 2 &&
              crossbiteErros.improveCrossBite.includes(5) &&
              crossbiteErros.improveCrossBiteOthers.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 6:
            let strippingErros = state_form.InstructionsTratment.Stripping
            console.log(strippingErros)
            //Step 1
            if (!strippingErros.stripping) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            //Step 2
            if (!strippingErros.virtualRemodel) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Seleccione una opción' },
              ]
              success = false
            }

            let strippingArr1 = strippingErros.permanentOdontogramsUp.find(
              (value) => value !== 0
            )
            let strippingArr2 = strippingErros.permanentOdontogramsDown.find(
              (value) => value !== 0
            )
            let strippingArr3 = strippingErros.temporalOdontogramsUp.find(
              (value) => value !== 0
            )
            let strippingArr4 = strippingErros.temporalOdontogramsDown.find(
              (value) => value !== 0
            )
            if (
              strippingArr1 === undefined &&
              strippingArr2 === undefined &&
              strippingArr3 === undefined &&
              strippingArr4 === undefined &&
              strippingErros.virtualRemodel === 1
            ) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Seleccione mínimo un diente' },
              ]
              success = false
            }

            if (!strippingErros.withInclination) {
              errorsList = [
                ...errorsList,
                { order: 3, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (!strippingErros.withoutInclination) {
              errorsList = [
                ...errorsList,
                { order: 4, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (
              strippingErros.virtualRemodel === 1 &&
              strippingErros.description &&
              strippingErros.description.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 9, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            if (
              strippingErros.othersInclination &&
              strippingErros.othersInclination.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 10, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            if (
              strippingErros.othersGain &&
              strippingErros.othersGain.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 11, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 7:
            let diastemsErros = state_form.InstructionsTratment.Diastems
            if (!diastemsErros.diastems) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (diastemsErros.diastems === 2) {
              if (!diastemsErros.retroDiastems && !diastemsErros.mesiDiastems) {
                errorsList = [
                  ...errorsList,
                  { order: 5, message: 'Seleccione una opción' },
                ]
                success = false
              }
              if (diastemsErros.description.length > 2500) {
                errorsList = [
                  ...errorsList,
                  { order: 2, message: 'Límite de caracteres superado' },
                ]
                success = false
              }
            }
            break
          case 8:
            let spacesErros = state_form.InstructionsTratment.Spaces
            if (!spacesErros.spaces) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (spacesErros.spaces === 2) {
              if (spacesErros.amountOpen == 0) {
                errorsList = [
                  ...errorsList,
                  { order: 1, message: 'La opción abrir debe ser mayor a 0' },
                ]
                success = false
              }
            }

            if (
              spacesErros.spaces === 4 &&
              spacesErros.closeSpaces.length === 0
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione mínimo una opción' },
              ]
              success = false
            }
            if (
              spacesErros.spaces === 4 &&
              spacesErros.closeSpaces.includes(4) &&
              !spacesErros.closeSpacesDescription
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Complete el campo descripción' },
              ]
              success = false
            }
            if (
              spacesErros.spaces === 4 &&
              spacesErros.closeSpaces.includes(4) &&
              spacesErros.closeSpacesDescription.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 9:
            let sequentialDistalationErros =
              state_form.InstructionsTratment.SequentialDistalation
            if (!sequentialDistalationErros.sequentialDistalation) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (sequentialDistalationErros.sequentialDistalation === 1) {
              if (
                sequentialDistalationErros.downLeft == 0 &&
                sequentialDistalationErros.downRight == 0 &&
                sequentialDistalationErros.upLeft == 0 &&
                sequentialDistalationErros.upRight == 0
              ) {
                errorsList = [
                  ...errorsList,
                  { order: 1, message: 'Complete mínimo una opción mayor a 0' },
                ]
                success = false
              }
            }
            break
          case 10:
            let auxErros = state_form.InstructionsTratment.AuxiliarResources
            if (!auxErros.auxiliarResources) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (
              auxErros.auxiliarResources === 1 &&
              auxErros.auxiliarResourcesSelected.length === 0
            ) {
              errorsList = [
                ...errorsList,
                { order: 1, message: 'Seleccione una opción' },
              ]
              success = false
            }
            if (
              auxErros.auxiliarResources === 1 &&
              auxErros.auxiliarResourcesDescription.length > 2500
            ) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
          case 11:
            let generalInstructionErrors =
              state_form.InstructionsTratment.GeneralInstructions
            if (generalInstructionErrors.instructions.length > 2500) {
              errorsList = [
                ...errorsList,
                { order: 2, message: 'Límite de caracteres superado' },
              ]
              success = false
            }
            break
        }
        break
    }
    updateErrors(errorsList)
    return success
  }

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error3 = errors_list.find((err) => err.order === 3)
  useErrorList(
    errors_list,
    updateErrors,
    stepMain === 1
      ? [props.formOnlineReducer.AestheticPriority.General.description]
      : null
  )

  return (
    <div>
      <div className="col-12">
        <nav>
          <div
            className="nav nav-tabs nav-fill"
            id="nav-tab"
            role="tablist"
            style={{ borderTop: 'solid 3px #dbdbdb' }}
          >
            <div className="d-flex justify-content-center col-4">
              <a
                className={`nav-item2 nav-link2 col-10`}
                style={{
                  borderBottom:
                    'solid 5px ' + (stepMain >= 1 ? colors.color : '#dbdbdb'),
                  color: colors.alternativeColor,
                }}
                id="nav-home-tab"
                href={() => false}
              >
                Prioridad estética del paciente
              </a>
            </div>
            <div className="d-flex justify-content-center col-4">
              <a
                className={`nav-item2 nav-link2 col-10`}
                style={{
                  borderBottom:
                    'solid 5px ' + (stepMain >= 2 ? colors.color : '#dbdbdb'),
                  color: colors.alternativeColor,
                }}
                id="nav-profile-tab"
                href={() => false}
              >
                Diagnóstico previo al tratamiento
              </a>
            </div>
            <div className="d-flex justify-content-center col-4">
              <a
                className={`nav-item2 nav-link2 col-10`}
                style={{
                  borderBottom:
                    'solid 5px ' + (stepMain >= 3 ? colors.color : '#dbdbdb'),
                  color: colors.alternativeColor,
                }}
                id="nav-contact-tab"
                href={() => false}
              >
                Indicaciones de tratamiento
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div className="pt-4">
        <div style={{ minHeight: '10rem' }}>
          {stepMain === 1 && (
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column col-11">
                <div className="col-12">
                  <div className="d-flex">
                    <label className="fw-bold">
                      Indique a continuación la prioridad estética del paciente:
                      *
                    </label>
                  </div>
                  <hr className="m-0 mt-1" />
                </div>
                <textarea
                  value={
                    props.formOnlineReducer.AestheticPriority.General
                      .description
                  }
                  onChange={(e) => {
                    updateState({ key: 'description', value: e.target.value })
                  }}
                  className="form-control mt-3"
                  name="observacion"
                  id="observacion"
                  cols="30"
                  rows="10"
                ></textarea>
                <div className="d-flex justify-content-between w-100">
                  <TextAreaCounter
                    value={
                      props.formOnlineReducer.AestheticPriority.General
                        .description
                    }
                    maxValue={2500}
                    error={error3}
                  />
                </div>
              </div>
            </div>
          )}
          {stepMain === 2 && (
            <PretratmentDiagnosis colors={colors} step={stepPretratment} />
          )}
          {stepMain === 3 && (
            <TratmentInstructions
              colors={colors}
              step={stepInstructions}
              applicationState={props.applicationState}
              setState={props.setState}
            />
          )}
        </div>
        <div
          className={
            stepMain > 1
              ? 'd-flex justify-content-between mt-2 me-2'
              : 'd-flex justify-content-end mt-2 me-2'
          }
        >
          {stepMain > 1 && (
            <div className="d-flex">
              <ChevronLeft style={{ color: 'rgb(138, 185, 241)' }} />
              <div onClick={backStepMain} className="online-form-btn">
                Anterior
              </div>
            </div>
          )}
          {stepMain === 3 && stepInstructions === 11 ? (
            <div className="d-flex">
              <div
                onClick={finishFormTyping}
                className="online-form-btn"
                style={{ color: 'rgb(138, 185, 241)' }}
              >
                Finalizar
              </div>
              <ChevronRight style={{ color: 'rgb(138, 185, 241)' }} />
            </div>
          ) : (
            <div className="d-flex">
              <div onClick={nextStepMain} className="online-form-btn">
                Siguiente
              </div>
              <ChevronRight style={{ color: 'rgb(138, 185, 241)' }} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PacientData)
