import React from 'react'

export default function case_card({id, description, photo}) {
    return (
        <div className="border border-1" style={{overflow: 'hidden', width: "250px", height: '250px', borderRadius: '20px'}}>
            <div className="d-flex flex-column bg-primary text-left px-3 pt-3" style={{maxHeight: '75px', height: '100%', width: '100%'}}>
                <p className="fw-bold text-white m-0">{id}</p>
                <p className="text-white">{description}</p>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
                <img src={photo} className="w-auto h-100" alt="" />
            </div>
        </div>
    )
}
