import React, { Component } from "react";
import apiClient from "../../../../utils/backend";
import { connect } from "react-redux";

import Header from '../../components/header_image'

import "./index.css";

class index extends Component {

  render() {

    return (
        <div className="container-fluid p-0">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item"><a href="/ayuda/caso" className="text-muted">Presenta tu caso</a></li>
                            <li className="breadcrumb-item active" aria-current="page">¿Cómo funciona?</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <div className="shadow container pt-5 pb-4" style={{borderRadius: '20px'}}>
                <div className="row px-5">
                    <div className="col-12 text-center"><h1 className="fw-bold">¡PRESENTÁ TU CASO Y OBTENÉ UN BENEFICIO!</h1></div>
                        <div className="col-12 mt-3"><p className="fs-5 lh-base">Si tenés un paciente que haya finalizado con su tratamiento tenés la posibilidad de presentar tu caso de estudio y obtener un <strong>5% de descuento en tu proximo caso. El caso de estudio será compartido con la comunidad KS en nuestros medios de difusión.</strong> A continuacón te detallamos los pasos a seguir para participar.</p></div>
                </div>
                <div className="row px-5 mt-5">
                    <div className="col-12"><h2 className="fw-bold"><span className="bg-primary text-white rounded-circle px-2" style={{fontSize: '.8em', margin: 0}}>1</span> CARGÁ LOS DATOS DE TUS PACIENTES</h2></div>
                    <div className="col-12 d-flex flex-column">
                        <p className="fs-5 mt-2">A continuación te solicitaremos que nos indiques:</p>
                        <ul className="help-ul">
                            <li>Motivo de consulta del paciente</li>
                            <li>Diagnóstico</li>
                            <li>Cantidad de etapas en las que resolvió el caso</li>
                            <li>Cantidad de alineadores totales</li>
                            <li>Maxilar a tratar</li>
                            <li>Plan de tratamiento (como lo resolvió)</li>
                        </ul>
                    </div>
                </div>
                <div className="row px-5 mt-5">
                    <div className="col-12"><h2 className="fw-bold"><span className="bg-primary text-white rounded-circle px-2" style={{fontSize: '.8em', margin: 0}}>2</span> ADJUNTÁ LOS SIGUIENTES ARCHIVOS</h2></div>
                    <div className="col-12 d-flex flex-column">
                        <p className="fs-5 mt-2">A continuación te mostramos los archivos que te solicitaremos para que tu caso de estudio sea presentado correctamente. Para esto será necesario contar con la documentación tanto <strong>inicial como final del tratamiento.</strong></p>
                        <p className="mt-3 fw-bold">Estudios del Paciente</p>
                        <ul className="help-ul">
                            <li>RX Panorámica</li>
                            <li>Telerradiografía de Perfil</li>
                            <li>Cefalograma de Ricketts</li>
                        </ul>
                        <p className="mt-3 fw-bold">Fotos faciales Extra-Orales</p>
                        <ul className="help-ul">
                            <li>Frontal en Reposo</li>
                            <li>Frontal Sonriendo</li>
                            <li>3/4 de Perfil Sonriendo</li>
                            <li>Perfil Izquierdo en Reposo</li>
                        </ul>
                        <p className="mt-3 fw-bold">Fotos faciales Intra-Orales</p>
                        <ul className="help-ul">
                            <li>Oclusal del Maxilar Superior</li>
                            <li>Oclusal del Maxilar Inferior</li>
                            <li>Anterior en Oclusión</li>
                            <li>Anterior en Inoclusión</li>
                            <li>Bucal Derecho</li>
                            <li>Bucal Izquierdo</li>
                        </ul>
                    </div>
                </div>
                <div className="row px-5 mt-5">
                    <div className="col-12"><h2 className="fw-bold"><span className="bg-primary text-white rounded-circle px-2" style={{fontSize: '.8em', margin: 0}}>3</span> ¿CÓMO EVALUAREMOS TU CASO?</h2></div>
                    <div className="col-12 d-flex flex-column">
                        <p className="fs-5 mt-2">Una vez cargados todos los archivos correctamente, el caso será evaluado </p>
                        
                    </div>
                </div>
                <div className="row px-5 mt-5">
                    <div className="col-12"><h2 className="fw-bold"><span className="bg-primary text-white rounded-circle px-2" style={{fontSize: '.8em', margin: 0}}>4</span> CONDICIONES</h2></div>
                    <div className="col-12 d-flex flex-column">
                        <p className="fs-5 mt-2">Los  objetivos previamente planteados deben cumplirse y además todas las fotos deberán ser nítidas, claras y bien tomadas con respecto a la angulación. Únicamente podrás presentar un caso <strong>por mes y será vigente dentro de los periodos trimestrales</strong> (Enero, Febero, Marzo / Abril, Mayo, Junio / Etc.). <strong>Los mismos no serán acumulables.</strong></p>
                    </div>
                </div>
                <div className="row px-5 mt-5">
                    <div className="col-12"><h2 className="fw-bold"><span className="bg-primary text-white rounded-circle px-2" style={{fontSize: '.8em', margin: 0}}>5</span> CONDICIONES</h2></div>
                    <div className="col-12 d-flex flex-column">
                        <p className="fs-5 mt-2">Te mostraremos un ejemplo de un caso presentado por nuestra directora odontológica, ka Dra. Gabriela La Valle, el cual fue publicado en nuestras redes sociales.</p>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
