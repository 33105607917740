import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../utils/backend'

export const useDentistIndex = () => {
  const userInfo = useSelector((state) => state.userReducer)
  const [state, setState] = useState({
    loading: true,
  })

  const getUrl = (type = 1, countryId, page = 1) => {
    // set base path with country filter
    let path = `/api/dentists?page=${page}&include=treatmentsCount,patientsCount&filter[country_id]=${countryId}`
    // add type path
    if (type === 2)
      path = path + ',treatments,user.groups&filter[deactivated]=true'
    if (type === 3) path = path + '&filter[user.groups.id]=1'
    if (type === 4) path = path + '&filter[user.groups.id]=2'
    if (type === 5) path = path + '&filter[user.groups.id]=3'
    return path
  }

  function handleOnChangePaginator(page) {
    let url = getUrl(state.type, state.countryId, page)
    if (state.searchQuery) url = url + `&filter[fullname]=${state.searchQuery}`

    apiClient
      .get(url)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          dentistArray: res.data.table.body,
          paginator: res.data.paginator,
          clearSearchBarInput: false,
        }))
      })
      .catch((err) => console.log(err))
  }

  function typeFilterHandler(type) {
    apiClient
      .get(getUrl(type, state.countryId))
      .then((res) => {
        setState((prevState) => ({
          countryId: prevState.countryId,
          type: type,
          dentistArray: res.data.table.body,
          paginator: res.data.paginator,
          clearSearchBarInput: true,
        }))
      })
      .catch((err) => console.log(err))
  }

  function countryFilterHandler(countryId) {
    apiClient
      .get(getUrl(1, countryId))
      .then((res) => {
        setState({
          countryId: countryId,
          type: 1,
          dentistArray: res.data.table.body,
          paginator: res.data.paginator,
          clearSearchBarInput: true,
        })
      })
      .catch((err) => console.log(err))
  }

  // Initial apiCall
  useEffect(() => {
    let initialType = 1

    apiClient
      .get(getUrl(initialType, userInfo.countryId))
      .then((res) => {
        setState({
          countryId: userInfo.countryId,
          type: initialType,
          dentistArray: res.data.table.body,
          paginator: res.data.paginator,
        })
      })
      .catch((err) => console.log(err))
  }, [userInfo.countryId])

  // When search input is clean by the user callsApis for Todos filter
  useEffect(() => {
    if (!state.loadTodosFilter) return
    apiClient
      .get(getUrl(1, state.countryId))
      .then((res) => {
        setState((prevState) => ({
          dentistArray: res.data.table.body,
          paginator: res.data.paginator,
          countryId: prevState.countryId,
          type: 1,
        }))
      })
      .catch((err) => console.log(err))
  }, [state, userInfo.countryId])

  return {
    state,
    setState,
    typeFilterHandler,
    countryFilterHandler,
    handleOnChangePaginator,
    getUrl,
  }
}
