import React, { useState } from "react";
import { ChevronUp, ChevronDown } from "@styled-icons/boxicons-regular";
import ConsultItem from "./consult_item";
import moment from "moment";

export default function ConsultCard({ data }) {
  const [opened, setOpened] = useState(false);
  const [newConsult, setNewConsult] = useState(false);
  const [consult, setConsult] = useState("");

  let path = window.location.pathname.split("/");

  function handleSendConsult() {}

  return (
    <div
      className="container-fluid p-0 mt-3 border border-1 shadow"
      style={{ borderRadius: "20px", overflow: "hidden" }}
    >
      <div className="row m-0 w-100 p-2 border-bottom py-2">
        <div className="col-1 d-flex justify-content-center align-items-center">
          <div className="p-4 w-75 h-75 rounded-circle border"></div>
        </div>
        <div className="col-2 d-flex flex-column py-2">
          <small className="text-primary">Paciente</small>
          <p className="text-primary fw-bold">{data.patient}</p>
        </div>
        <div className="col-2 d-flex flex-column py-2">
          <small className="text-primary">Doctor</small>
          <p className="text-primary fw-bold">{data.dentist}</p>
        </div>
        <div className="col-1 d-flex flex-column py-2">
          <small className="text-primary">Categoría:</small>
          <p className="text-primary fw-bold">{data.category}</p>
        </div>
        <div className="col-2 d-flex flex-column py-2">
          <small className="text-primary">Consulta:</small>
          <p className="text-primary fw-bold">{data.type}</p>
        </div>
        <div className="col-1 pt-3">
          <small className="text-primary">{data.created_at}</small>
        </div>
        <div className="col-2 d-flex flex-column py-2">
          <small className="text-primary">Estado:</small>
          <p className="text-primary fw-bold">{data.status.title}</p>
        </div>
        <div className="col-1 pt-3 text-end">
          {!opened && (
            <button
              onClick={() => setOpened(true)}
              className="btn bg-none border-none p-0"
            >
              <ChevronDown color="#000" size="32" />
            </button>
          )}
          {opened && (
            <button
              onClick={() => {
                setOpened(false);
                setNewConsult(false);
              }}
              className="btn bg-none border-none p-0"
            >
              <ChevronUp color="#000" size="32" />
            </button>
          )}
        </div>
      </div>
      <div className="row m-0 w-100 p-4 mb-2 d-flex flex-column">
        <p className="mb-1" style={{ lineHeight: "1.2em" }}>
          {data.messages[0].content}
        </p>
        <small className="text-muted">
          {moment(data.created_at, "DD/MM/YYYY").fromNow()}
        </small>
      </div>
      {opened && (
        <div className="row">
          {path.indexOf("admin") !== -1 && <ConsultItem od={data.dentist} />}
          {path.indexOf("od") !== -1 && !newConsult && (
            <button
              className="w-100 h-100 p-3 text-primary fw-bold border-none btn fs-5"
              style={{ backgroundColor: "#D7E4EB" }}
              onClick={() => setNewConsult(!newConsult)}
            >
              Hacer otra consulta
            </button>
          )}
        </div>
      )}
      <div
        className="row m-0 w-100 p-0 border-top"
        style={{ background: newConsult ? "none" : "#D7E4EB" }}
      >
        {newConsult === true && path.indexOf("od") !== -1 && (
          <div className="w-100 h-100 p-4 d-flex flex-row">
            <textarea
              onChange={(e) => setConsult(e.target.value)}
              className="w-100"
              name=""
              id=""
              cols="30"
              rows="5"
            ></textarea>
            <div className="d-flex flex-column w-25 px-4">
              <button
                onClick={() => setNewConsult(false)}
                className="btn bg-none"
              >
                Cancelar
              </button>
              <button
                onClick={() => handleSendConsult()}
                className="btn btn-outline-primary rounded-pill mt-3"
              >
                Enviar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
