import { useLoadScript } from '@react-google-maps/api'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import handleToast from '../../../../components/toaster'
import apiClient from '../../../../utils/backend'

const useCreateDentist = () => {
  const [requiredData, setRequiredData] = useState({
    name: null,
    surname: null,
    email: null,
    gender: null,
    birthdate: null,
  })
  const [loadingData, setLoadingData] = useState(false)
  const history = useHistory()
  const [avatar, setAvatar] = useState(null)
  const [genderObject, setGenderObject] = useState(null)
  const [fiscalConditionObject, setFiscalConditionObject] = useState(null)
  const [documentoDeIdentidad, setDocumentoDeIdentidad] = useState(null)
  const [personalData, setPersonalData] = useState({
    accreditation_course: null,
    profilePhoto: null,

    phone_number: null,
    cuit: null,
    fiscal_condition: null,
    national_enrollment: null,
    provintial_enrollment: null,
    facebook: null,
    instagram: null,
    twitter: null,
    web: null,
  })

  const [profile, setProfile] = useState({
    sao: false,
    study_group: false,
    sg_team: null,
    sg_type: null,
    sg_level: null,
  })

  const [errorField, setErrorField] = useState({
    name: '',
    surname: '',
    gender: '',
    birthdate: '',
    email: '',
    accreditation_course: '',
    cuit: '',
    profile: '',
    phone_number: '',
    national_enrollment: '',
    provintial_enrollment: '',
  })

  const [directions, setDirections] = useState([
    {
      direction_name: '#Dirección 1',
      toSave: false,
      inputId: 1,
      search: '',
      street_address: '',
      street_number: '',
      floor: '',
      apartment: '',
      hood: '',
      zip_code: '',
      province: '',
      city: '',
      country: '',
      lat: 0,
      lng: 0,
      visible: false,
    },
  ])
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)
  const isArgentine = countryId === 1
  const isUruguayan = countryId === 2
  const isColombian = countryId === 4
  const isChilean = countryId === 5

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA5GePOrq5a4XfoMbjjiLrOBYq4hpH7Cds',
    libraries: ['places'],
  })

  if (!isLoaded) return <div></div>

  const addDirection = () => {
    if (directions.length === 5)
      return handleToast('error', 'Solo puede agregar hasta 5 direcciones')
    setDirections((prev) => [
      ...prev,
      {
        direction_name: `#Dirección ${directions.length + 1}`,
        inputId: directions.length + 1,
        toSave: false,
        search: '',
        street_address: '',
        street_number: '',
        floor: '',
        apartment: '',
        hood: '',
        zip_code: '',
        province: '',
        city: '',
        country: '',
        lat: 0,
        lng: 0,
        visible: false,
      },
    ])
  }

  const handleSetErrors = (err) => {
    let profileError = false
    if (
      err['profile.sg_type'] ||
      err['profile.sg_team'] ||
      err['profile.sg_level']
    )
      profileError = true

    setErrorField({
      ...errorField,
      name: err['user.name'] !== null && err['user.name'],
      surname: err['user.surname'] !== null && err['user.surname'],
      gender: err['user.gender'] !== null && err['user.gender'],
      email: err['user.email'] !== null && err['user.email'],
      cuit: err['dentist.cuit'] !== null && err['dentist.cuit'],
      national_enrollment:
        err['dentist.national_enrollment'] !== null &&
        err['dentist.national_enrollment'],
      provintial_enrollment:
        err['dentist.provintial_enrollment'] !== null &&
        err['dentist.provintial_enrollment'],
      profile: profileError,
      accreditation_course:
        err['dentist.accreditation_course'] !== null &&
        err['dentist.accreditation_course'],
    })
  }

  const cleanError = (errName) => {
    setErrorField({ ...errorField, [errName]: null })
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const handleRedirect = () => {
    handleToast('success', 'Nuevo Odontólogo creado')
    setTimeout(() => {
      history.replace('/odontologos')
    }, 3000)
  }

  const handleRequest = async () => {
    setLoadingData(true)
    handleToast('updating', 'Creando nuevo Odontólogo')
    let data = {
      user: {
        ...requiredData,
      },
      dentist: {
        ...personalData,
      },
      profile,
    }
    if (!profile.sao && !profile.study_group) delete data.profile
    try {
      const res = await apiClient.post(`api/dentists/`, data)
      if (res.data.success && res.data.data?.id) {
        saveDirections(res.data.data.id)
        return handleSendAvatar(res.data.data.id)
      }
      handleToast('error', '¡Error al crear el Odontólogo!')
      handleSetErrors(res.data.errors)
      setLoadingData(false)
    } catch (error) {
      setLoadingData(false)
      handleToast('error', '¡Error al actualizar el Odontólogo!')
    }
  }

  const handleSendAvatar = async (dentistId) => {
    if (!avatar) return handleRedirect()
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const data = new FormData()
    data.append('avatar', avatar)
    data.append('_method', 'PUT')
    try {
      const res = await apiClient.post(
        `/api/dentists/${dentistId}/avatar`,
        data,
        headers
      )
      if (res.status === 200) return handleRedirect()
      setLoadingData(false)
      handleToast('error', '¡Error al cargar el avatar')
    } catch (err) {
      setLoadingData(false)
      handleToast('error', '¡Error al cargar el avatar')
    }
  }

  const handleDeleteDirection = (id) => {
    if (directions.length === 1)
      return handleToast('error', 'Se necesita al menos una dirección')
    let newDirections = directions
    setDirections(newDirections.filter((direction) => direction.inputId !== id))
    handleToast('success', 'Dirección eliminada')
  }

  const handleSaveDirection = (
    directionData,
    lat = '',
    lng = '',
    isWithoutGoogleApi = false
  ) => {
    const newForm = directions.map((direction) => {
      if (direction.inputId === directionData.inputId) {
        if (isWithoutGoogleApi) {
          return { ...directionData, lat: lat.toString(), lng: lng.toString() }
        }
        return directionData
      }
      return direction
    })
    setDirections(newForm)
    handleToast('success', 'Dirección guardada exitosamente')
  }

  const saveDirections = (dentistId) => {
    directions.forEach(async (direction) => {
      delete direction.toSave
      delete direction.inputId
      try {
        await apiClient.post(`api/dentists/${dentistId}/offices`, direction)
      } catch (error) {
        console.log(error)
      }
    })
  }

  return {
    personalData,
    avatar,
    requiredData,
    errorField,
    genderObject,
    isChilean,
    isColombian,
    documentoDeIdentidad,
    countryId,
    fiscalConditionObject,
    directions,
    isArgentine,
    isUruguayan,
    profile,
    loadingData,
    setAvatar,
    getBase64,
    setPersonalData,
    cleanError,
    setRequiredData,
    setGenderObject,
    setDocumentoDeIdentidad,
    setFiscalConditionObject,
    addDirection,
    setProfile,
    handleRequest,
    setErrorField,
    handleDeleteDirection,
    handleSaveDirection,
  }
}

export default useCreateDentist
