import React, { useState, useEffect } from 'react'
import { MoreVertical, Upload } from 'react-feather'
import '../documentation.css'

// IMAGES STEP 2
import trescuartos_perfil from '../../../../../../assets/images/34deperfilsonriendo.png'
import anterioroclusion from '../../../../../../assets/images/anteriorenoclusion.png'
import anteriorinoclusion from '../../../../../../assets/images/anterioreninoclusion.png'
import bucalderecho from '../../../../../../assets/images/bucalderecho.png'
import bucalizquierdo from '../../../../../../assets/images/bucalizquierdo.png'
import frontalreposo from '../../../../../../assets/images/frontalenreposo.png'
import frontalsonriendo from '../../../../../../assets/images/frontalsonriendo.png'
import oclusalmaxilarinf from '../../../../../../assets/images/oclusaldelmaxilarinferior.png'
import oclusalmaxilarsup from '../../../../../../assets/images/oclusaldelmaxilarsuperior.png'
import perfilizqreposo from '../../../../../../assets/images/perfilizquierdoenreposo.png'
// IMAGES STEP 3
import radioperfil from '../../../../../../assets/images/telerradiografiadeperfil.png'
import ricketts from '../../../../../../assets/images/cefalogramadericketts.png'
import rxpanoramica from '../../../../../../assets/images/rxpanoramica.png'
// IMAGES STEP 4
import plan from '../../../../../../assets/images/plan.png'

import { ImageUploadContainer, ImageUploadReference } from './styled'
import Compressor from 'compressorjs'

// Import the editor styles
import 'pintura/pintura.css'

// Import the editor component from `react-pintura`
import {
  // Import plugins
  plugin_crop,
  plugin_finetune,
  plugin_annotate,
  plugin_resize,
  // Import languajes
  openDefaultEditor,
  setPlugins,
} from 'pintura'
import { getQuality } from '../../../../../../utils/quality'
import PDFViewer from '../../../../../../components/Modals/PDFViewer'

const ImageInput = React.forwardRef(
  ({
    index,
    id,
    name,
    src,
    loadImage,
    deleteImage,
    imageToSave,
    handleImageSelect,
    handleEditPlan,
    handleDeletePlan,
  }) => {
    const [image, setImage] = useState()
    const [isPDF, setisPDF] = useState(false)
    const [dropDown, setDropDown] = useState(false)
    const Components = {
      trescuartos_perfil: trescuartos_perfil,
      frontal_sonriendo: frontalsonriendo,
      frontal_reposo: frontalreposo,
      anterior_oclusion: anterioroclusion,
      anterior_inoclusion: anteriorinoclusion,
      bucal_izquierdo: bucalizquierdo,
      bucal_derecho: bucalderecho,
      oclusal_maxilar_sup: oclusalmaxilarsup,
      oclusal_maxilar_inf: oclusalmaxilarinf,
      perfil_izq_reposo: perfilizqreposo,
      telerradiografia: radioperfil,
      ricketts: ricketts,
      rxpanoramica: rxpanoramica,
      plan: plan,
      otros: plan,
    }

    //Function to add a new image
    const handleImage = async (e) => {
      e.preventDefault()
      if (e.target.files[0]?.type === 'application/pdf') {
        return
        /*   setImage(e.target.files[0])
        setisPDF(true) */
      } else {
        setisPDF(false)
        let imageFile = e.target.files[0]
        console.log('image file', imageFile)
        new Compressor(imageFile, {
          quality: getQuality(),
          success(result) {
            let reader = new FileReader()
            let file_compressed = result
            reader.onloadend = () => {
              // Action with image
              if (src === 'otros') {
                /*  handleImageSelect(reader.result) */
              } else {
                loadImage(reader.result, id)
                setImage(reader.result)
              }
              handleImageEditor(reader.result)
            }
            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      }
    }
    //Function to edit an image with CropperJS
    const handleImageEditor = (imageSrc) => {
      setPlugins(plugin_crop, plugin_finetune, plugin_annotate, plugin_resize)
      const editor = openDefaultEditor({
        src: imageSrc,
      })
      editor.on('process', ({ dest }) => {
        // Here we use URL.createObjectURL to create a URL to the output image
        new Compressor(dest, {
          quality: getQuality(),
          success(result) {
            let reader = new FileReader()
            let file_compressed = result

            reader.onloadend = () => {
              // After edit Action
              if (src === 'otros') {
                if (id) handleImageSelect(reader.result)
                else handleEditPlan(reader.result, index)
              } else {
                loadImage(reader.result, id)
                setImage(reader.result)
              }
            }

            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      })
    }

    const displayTopRightBtn = () => {
      if (image)
        return (
          <MoreVertical
            color="#10456d"
            size={24}
            className="moreVerticalBTN"
            onClick={() => {
              setDropDown(!dropDown)
            }}
          />
        )
      return <Upload color="#10456d" size={24} className="uploadBTN" />
    }

    const imageSource = () => {
      if (image) return image
      return Components[src]
    }

    useEffect(() => {
      if (!imageToSave) return
      setImage(imageToSave.image)
    }, [imageToSave, image])

    return (
      <div className="my-2 mx-2 p-1 position-relative">
        <ImageUploadContainer>
          {!image && (
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={handleImage}
            />
          )}

          {isPDF ? (
            <PDFViewer imageSource={imageSource} />
          ) : (
            <ImageUploadReference
              src={imageSource()}
              className={`${!image && 'disabled'}`}
              alt="Imagen"
            />
          )}
        </ImageUploadContainer>

        {displayTopRightBtn()}
        {dropDown && (
          <div className="inputDropDown">
            {!isPDF && (
              <>
                <span
                  onClick={() => {
                    handleImageEditor(image)
                    setDropDown(!dropDown)
                  }}
                >
                  Editar
                </span>
                <hr className="m-1" />
              </>
            )}
            <span
              onClick={() => {
                if (src === 'otros') {
                  handleDeletePlan(index)
                  setImage(null)
                  setDropDown(!dropDown)
                } else {
                  setImage(null)
                  deleteImage(id)
                  setDropDown(!dropDown)
                }
              }}
            >
              Eliminar
            </span>
          </div>
        )}
        <p className="my-0 image-name text-primary">{name}</p>
      </div>
    )
  }
)

export default ImageInput
