import styled from 'styled-components'

export const MultifileUploader = styled.label`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 500px;
  height: 50px;
  cursor: pointer;
  background: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 0.5px solid #ccc;
  border-radius: 30px;

  & > input {
    display: none;
  }

  & > p {
    font-weight: bold;
    margin: 0;
  }
`

export const ImageUploadContainer = styled.label`
  width: 100px;
  height: 100px;
  cursor: pointer;
  background: #f4f4f4;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > input {
    display: none;
  }

  & > svg {
    width: 40px;
    height: 40px;
    margin: auto;
  }
`

export const ImageUploadReference = styled.img`
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  position: relative;
  background-color: #112154;
`
export const ImageUploadLabel = styled.label`
  position: relative;
  background: #cecece;
  padding: 5px;
  color: #444;
  max-height: 60px;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  max-width: 200px;
`

export const MultifileImage = styled.img`
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  position: relative;
  background-color: #112154;
`
