import React from 'react'
import { MoreVertical, ArrowUpRight } from 'react-feather'

export default function DataCard({data}) {
    return (
        <div className="mx-1 my-1 p-3 shadow text-left" style={{maxWidth: '250px', borderRadius: '20px'}}>
            <div className="row">
                <div className="col-10">
                    <h5 className="fw-bold">{data.title}</h5>
                </div>
                <div className="col-2">
                    <button className="btn bg-none p-0"><MoreVertical /></button>
                </div>
            </div>
            <div className="row mt-3">
                {data.data.map(data => {
                    return <div className="col-6 d-flex flex-column">
                        <p className="fw-bold fs-4">{data.count}</p>
                        <small className="text-muted">Nuevas</small>
                        <small style={{fontSize: '.8rem'}} className="text-secondary mt-1">{data.text}</small>
                    </div>
                })}
                <div className="mt-2 d-flex w-100 justify-content-end">
                    <a href={data.href}><ArrowUpRight size="24" color="#fff" className="bg-primary rounded-circle p-1" /></a>
                </div>
            </div>
        </div>
    )
}
