import { useEffect, useState } from 'react'
import { ReferralsModalOfficesCard } from './ReferralsModalOfficesCard'
import PropTypes from 'prop-types'

export const ReferralsModalDentistAddress = ({
  offices,
  setReferralModalState,
  showOfficesError,
}) => {
  const [officeState, setOfficeState] = useState({})

  const selectOffice = (id, office) => {
    let mutatedOffices = {}
    offices.forEach(
      (item) => (mutatedOffices = { ...mutatedOffices, [item.id]: false })
    )
    mutatedOffices[id] = true
    setOfficeState({ ...mutatedOffices })
    setReferralModalState((prevState) => ({
      ...prevState,
      selectedOffice: office,
    }))
  }

  useEffect(() => {
    if (!offices) return
    offices.map((item) =>
      setOfficeState((prevState) => ({ ...prevState, [item.id]: false }))
    )
  }, [offices])

  return (
    <div className="row mt-3 fs-7 mx-2">
      {showOfficesError && (
        <div style={{ color: 'red' }}>
          * El dentista seleccionado no puede realizar una derivación ya que no
          cuenta con una oficina registrada
        </div>
      )}
      {offices?.map((item) => {
        return (
          <div
            key={item.id}
            className="col d-flex align-items-center mt-2 text-truncate px-2 
              justify-content-center"
          >
            <ReferralsModalOfficesCard
              office={item}
              officeState={officeState}
              id={item.id}
              selectOffice={selectOffice}
            />
          </div>
        )
      })}
    </div>
  )
}
ReferralsModalDentistAddress.propTypes = {
  offices: PropTypes.array,
  showOfficesError: PropTypes.bool.isRequired,
  setReferralModalState: PropTypes.func.isRequired,
}
