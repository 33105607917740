import React from 'react'
import saologo from '../../../assets/images/sao_logo.png'
import sglogo from '../../../assets/images/study_group_logo.png'
import checkIcon from '../../../assets/images/RO-check.png'
import '../styles/index.css'

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import StatusOne from './status/StatusOne'
import StatusTwo from './status/StatusTwo'
import StatusThree from './status/StatusThree'
import StatusFour from './status/StatusFour'
import StatusFive from './status/StatusFive'
import StatusZero from './status/StatusZero'
import moment from 'moment'
import { useSelector } from 'react-redux'

const ClinicHistoryDetail = ({
  treatmentStatus,
  paymentStatus,
  paymentDate,
  data,
}) => {
  const displayStatusBar = () => {
    if (treatmentStatus.dispatched === 'completed') return <StatusFive />
    if (treatmentStatus.manufacturing === 'completed') return <StatusThree />
    if (treatmentStatus.plan === 'completed') return <StatusFour />
    if (treatmentStatus.documentation === 'completed') return <StatusTwo />
    if (treatmentStatus.patient === 'completed') return <StatusOne />
    return <StatusZero />
  }

  const { countryId } = useSelector((state) => state.userReducer)
  return (
    <div className="row ms-2 p-2 d-flex flex-wrap">
      <div className="col-5">
        <div className="row d-flex flex-row">
          <div className="col-3">
            <p>Perfil:</p>
          </div>
          <div className="col-3 text-muted">
            <p>Movimientos:</p>
          </div>
          <div className="col-3 text-muted">
            <p>Attachments:</p>
          </div>
          <div className="col-3 text-muted">
            <p>Planilla:</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            {data.patient.groups.length !== 0 &&
              data.patient.groups.map((group) => (
                <div className="p-2 pt-3 detailProfileIMG">
                  <img
                    className="ms-2"
                    src={group.id === 1 ? saologo : sglogo}
                    alt={group.name}
                    style={{ width: '36px', height: '30px' }}
                  />
                </div>
              ))}
          </div>
          <div className="col-3">
            <h4 className="fw-bold text-primary ">{data?.movements}</h4>
          </div>
        </div>
      </div>
      <div className="ms-5 col-6 d-flex">
        {displayStatusBar()}
        <div
          className="col-3 treatmentStateBox p-2 ms-4 d-flex flex-column align-items-center"
          style={{ width: '120px' }}
        >
          <p className="text-center text-muted">Estado de pago:</p>
          {paymentStatus === 1 && countryId === 1 ? (
            <div>
              <img src={checkIcon} alt="payment-complete" width="70px" />
              <p className="fs-7 text-green text-center m-0 mt-1">Habilitado</p>
              <p className="text-center mb-1" style={{ fontSize: 10 }}>
                {moment(paymentDate).format('DD/MM/YYYY')}
              </p>
            </div>
          ) : (
            <CircularProgressbarWithChildren
              value={paymentStatus}
              styles={buildStyles({
                pathColor: `#E99C01`,
                trailColor: '##E99C01',
              })}
            >
              <div
                className="d-flex align-items-center"
                style={{ color: '#E99C01' }}
              >
                <span className="fs-6 me-1">%</span>
                <span className="fs-3">{paymentStatus}</span>
              </div>
            </CircularProgressbarWithChildren>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClinicHistoryDetail
