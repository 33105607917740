import React from 'react'

const CardDecriptionComponent = ({ state }) => {
  return (
    <div className="row mt-4 mb-0 ">
      <div className="col-2 ">
        <p className="text-primary fw-bold m-0 text-center text-truncate">
          Nombre y apellido
        </p>
      </div>
      <div className="col-1 ">
        <p className="text-primary fw-bold m-0 text-center text-truncate">
          Perfil
        </p>
      </div>
      <div className="col-2 ">
        <p className="text-primary fw-bold m-0 text-start text-truncate">
          Smile Creator
        </p>
      </div>
      <div className="col-1 ">
        <p className="text-primary fw-bold m-0 text-start text-truncate">
          Email
        </p>
      </div>
      {state.type !== 2 && (
        <>
          <div className="col-2 ">
            <p className="text-primary fw-bold m-0 text-center text-truncate">
              Telefono
            </p>
          </div>
          <div className="col-1 ">
            <p className="text-primary fw-bold m-0 text-center text-truncate">
              Pacientes
            </p>
          </div>
          <div className="col-2 ">
            <p className="text-primary fw-bold m-0 text-center text-truncate">
              Tratamientos activos
            </p>
          </div>
          <div className="col-1 ">
            <p className="text-primary fw-bold m-0 text-center text-truncate">
              Desactivar
            </p>
          </div>
        </>
      )}
    </div>
  )
}

export default CardDecriptionComponent
