import { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'

export const useReferrals = () => {
  const [referralsState, setReferralsState] = useState({
    referralsList: null,
    referralsFilters: null,
    filter: 1,
    paginator: [],
    loading: true,
  })
  const [isAllSelected, setisAllSelected] = useState(false)

  const getUrl = (page = 1) => {
    let url = `/api/referrals?cant_pages=30&page=${page}`
    if (referralsState.filter === 2) url = url + `&filter[status]=pending`
    if (referralsState.filter === 3) url = url + `&filter[status]=derived`
    return url
  }

  const handlePagination = async (page) => {
    let url = getUrl(page)
    if (referralsState.searchQuery)
      url =
        url +
        `&filter[${referralsState.currentFilterType}]=${referralsState.searchQuery}`

    try {
      const res = await apiClient(url)
      setReferralsState((prevState) => ({
        ...prevState,
        referralsList: res.data.table.body.map((referral) => ({
          ...referral,
          selected: false,
        })),
        loadTodosFilter: false,
        clearSearchBarInput: false,
        referralsFilters: res.data.meta,
        paginator: res.data.paginator,
        loading: false,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const getInitialReferrals = async (page = 1) => {
    try {
      const res = await apiClient(getUrl(page))
      setReferralsState((prevState) => ({
        ...prevState,
        referralsList: res.data.table.body.map((referral) => ({
          ...referral,
          selected: false,
        })),
        loadTodosFilter: false,
        clearSearchBarInput: true,
        referralsFilters: res.data.meta,
        paginator: res.data.paginator,
        loading: false,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const setAllReferrals = () => {
    if (isAllSelected) {
      setisAllSelected(false)
      return setReferralsState({
        ...referralsState,
        referralsList: referralsState.referralsList.map((referral) => {
          return {
            ...referral,
            selected: false,
          }
        }),
      })
    } else {
      setisAllSelected(true)
      setReferralsState({
        ...referralsState,
        referralsList: referralsState.referralsList.map((referral) => {
          if (referral.status === 'derived') {
            return {
              ...referral,
              selected: false,
            }
          } else
            return {
              ...referral,
              selected: true,
            }
        }),
      })
    }
  }

  useEffect(() => {
    if (!referralsState.referralsList) return
    const amountOfReferrals = referralsState.referralsList.filter(
      (referral) => referral.selected
    )
    const referralsPending = referralsState.referralsList.filter(
      (referral) => referral.status === 'pending'
    )
    if (
      amountOfReferrals.length === referralsPending.length &&
      referralsPending.length !== 0
    ) {
      setisAllSelected(true)
    } else {
      setisAllSelected(false)
    }
  }, [referralsState])

  useEffect(() => {
    getInitialReferrals()
  }, [referralsState.filter])

  useEffect(() => {
    if (referralsState.loadTodosFilter) return getInitialReferrals()
  }, [referralsState])

  return {
    getUrl,
    referralsState,
    isAllSelected,
    getInitialReferrals,
    setReferralsState,
    setAllReferrals,
    setisAllSelected,
    handlePagination,
  }
}
