import React, { useEffect, useState } from 'react'
import apiClient from '../../../utils/backend'
import throttle from '../../../utils/throttle'
import successImage from '../../../assets/images/RO-check.png'
import './index.css'
import TreatmentCard from '../components/TreatmentCard'
import proposalService from '../../../services/proposals'
import { Spinner } from 'reactstrap'
import ProposalItem from '../components/ProposalItem/ProposalItem'

const Proposal = () => {
  const [treatmentProposal, setTreatmentProposal] = useState([])
  const [inputState, setInputState] = useState()
  const [treatmentCardData, setTreatmentData] = useState(null)
  const [success, setSuccess] = useState(false)
  const [wasEdited, setWasEdited] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ id: null, link: null })

  function idInputHandler({ target: { value } }) {
    setTreatmentData(null)
    setInputState(value)
    setTreatmentProposal([])
    throttle(getTreatmentById, value, 800)
    setErrors({ id: null, link: null })
  }

  async function getTreatmentById(query) {
    if (!query) return setTreatmentData(null)
    let treatId
    try {
      const res = await apiClient.post('api/getByExternalId', {
        external_id: query,
      })
      treatId = res.data.data.id
      setTreatmentData(res.data.data)
    } catch (err) {
      setErrors({ id: 'Paciente no encontrado.', link: null })
    }

    try {
      const res = await apiClient(`api/treatments/${treatId}/proposals`, {
        external_id: query,
      })

      setTreatmentProposal(res.data.table)
    } catch (err) {
      console.log(err)
    }
  }

  async function editProposal({ proposalId, data }) {
    if (wasEdited) {
      try {
        setLoading(true)
        await proposalService.editProposal({
          proposalId,
          params: { url: data },
        })
      } catch (err) {
        console.log('err', err)
      } finally {
        setLoading(false)
      }
    }
  }

  async function createProposal({ proposalsToCreate, isThereAProposalEdited }) {
    try {
      setLoading(true)
      const res = await apiClient.post(
        `api/treatments/${treatmentCardData.id}/proposals`,
        proposalsToCreate
      )

      if (res && !isThereAProposalEdited) {
        setSuccess(true)
        cleanSuccessMessage()
      }
    } catch (err) {
      if (
        err.response.data.msg ===
        'To create a treatment proposal, the treatment must have complete documentation'
      )
        return setErrors({
          id: null,
          link: 'El tratamiento debe tener la documentación completa.',
        })
      else return setErrors({ id: null, link: 'Link invalido.' })
    } finally {
      setLoading(false)
    }
  }

  async function deleteProposal({ proposalId }) {
    try {
      setLoading(true)
      const res = await proposalService.deleteProposal(proposalId)

      if (res) {
        await getTreatmentById(inputState)
      }
    } catch (err) {
      console.log('err', err)
    } finally {
      setLoading(false)
    }
  }

  function addProposalHandler() {
    setTreatmentProposal([
      ...treatmentProposal,
      { id: treatmentProposal.length + 1, isCreated: true },
    ])
  }

  async function onSaveBtnHandler() {
    const isThereAProposalEdited = treatmentProposal.some(
      ({ isEdited }) => isEdited
    )
    const isThereAProposalCreated = treatmentProposal.some(
      (proposal) => proposal?.isCreated
    )

    if (isThereAProposalCreated) {
      const proposalsToCreate = []
      treatmentProposal
        .filter((proposal) => proposal?.isCreated)
        .forEach((proposal) =>
          proposal?.url?.length > 0
            ? proposalsToCreate.push(proposal.url)
            : null
        )

      const newUrls = { urls: [...proposalsToCreate] }

      await createProposal({
        proposalsToCreate: newUrls,
        isThereAProposalEdited,
      })
    }

    if (isThereAProposalEdited) {
      const proposalsToEdit = treatmentProposal.filter(
        ({ isEdited }) => isEdited
      )

      for (const proposal of proposalsToEdit) {
        await editProposal({
          proposalId: proposal.id,
          data: proposal.url,
        })
      }

      setSuccess(true)
      cleanSuccessMessage()
    }
  }

  function cleanSuccessMessage() {
    setTimeout(() => {
      setTreatmentData(null)
      setSuccess(false)
    }, 3000)
  }

  useEffect(() => {
    if (!success) return
    setInputState('')
    setTreatmentProposal([])
    setErrors({ id: null, link: null })
  }, [inputState, success])

  useEffect(() => {
    if (!treatmentProposal.length) {
      setTreatmentProposal([{ id: 0, isCreated: true }])
    }
  }, [treatmentProposal])

  return (
    <>
      {!success ? (
        <div className="RO-container">
          <h4 className="fw-bold mb-4 text-primary">
            Propuesta de tratamiento
          </h4>
          <p className="my-0 fw-bold mb-3 text-primary">ID:</p>
          <input
            maxLength={15}
            className="RO-input-id"
            value={inputState}
            onChange={idInputHandler}
          />
          {errors.id && <p className="mb-3 mt-2 RO-error">{errors.id}</p>}

          <TreatmentCard treatmentCardData={treatmentCardData} />

          <p
            className={`my-0 fw-bold mb-4 text-primary proposal-link ${
              !treatmentCardData ? 'disabled' : null
            }`}
          >
            Link:
          </p>
          {treatmentCardData ? (
            treatmentProposal.map((item, i) => (
              <ProposalItem
                status={item?.status}
                key={i}
                index={i}
                id={item.id}
                url={item.url}
                deleteAction={deleteProposal}
                setWasEdited={setWasEdited}
                setDisabled={setDisabled}
                setTreatmentProposal={setTreatmentProposal}
                treatmentProposal={treatmentProposal}
                loading={loading}
                isCreated={item?.isCreated}
              />
            ))
          ) : (
            // SKELETON INFORMATION
            <div className="d-flex align-items-center">
              <p className="my-0 fw-bold proposal-tag text-primary disabled">
                1° Propuesta
              </p>
              <div
                disabled={treatmentCardData ? false : true}
                className="RO-input-link w-75"
                type="text"
              />
            </div>
          )}

          {errors.link && <p className="ms-2 mt-2 RO-error">{errors.link}</p>}
          {treatmentProposal?.length < 9 && (
            <button
              className="proposal-btn RO-light-shadow"
              onClick={() => addProposalHandler()}
            >
              Agregar propuesta
            </button>
          )}

          <div className="d-flex justify-content-end mt-4">
            <button
              className={`RO-btn RO-light-shadow ${
                disabled ? 'disabled' : null
              }`}
              onClick={onSaveBtnHandler}
              disabled={disabled}
            >
              Guardar
              {loading && <Spinner size={'sm'} color="white"></Spinner>}
            </button>
          </div>
        </div>
      ) : (
        <div className="RO-container d-flex">
          <h4 className="fw-bold mb-4 text-primary">
            Propuesta de tratamiento
          </h4>
          <TreatmentCard treatmentCardData={treatmentCardData} />
          {errors.link && <p className="mb-3 mt-2 RO-error">{errors.link}</p>}
          <div className="mt-3 RO-success-image">
            <img width={200} height={200} alt="check" src={successImage} />
          </div>
          <p className="mt-4 RO-success-text justify-content-end">
            ¡Propuesta de tratamiento cargada correctamente!
          </p>
        </div>
      )}
    </>
  )
}

export default Proposal
