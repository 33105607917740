import React from 'react'
import Loader from '../../../components/loader'
import Paginator from '../../../components/Paginator'
import Searchbar from '../../../components/searchbar'
import NotificationSwitch from './components/NotificationSwitch'
import { useNotifications } from './hooks/useNotifications'

export const NotificationsMobile = () => {
  const {
    loading,
    state,
    setState,
    getNotificationsPag,
    url,
    changeFilterHandler,
    filter,
  } = useNotifications(1)

  if (loading) return <Loader />

  return (
    <>
      <div className="notificationsHeader py-4">
        <div className="notification-mobile-title-container">
          <p className="fs-2 fw-bold text-white m-0 px-3">Notificaciones</p>
        </div>
      </div>
      <div className="notifications-mobile-container px-3">
        <div className="d-flex flex-column my-2">
          <Searchbar
            url={`${url}&`}
            state={state}
            setState={setState}
            page="notificationDentist"
          />
          <hr />
          <div className="d-flex w-100 flex-wrap">
            <div
              className={`my-1 ${
                filter === 1 ? 'filterBTNSelected' : 'filterBTN'
              } me-3`}
              onClick={() => changeFilterHandler(1)}
            >
              Todos <span>({state.meta.total})</span>
            </div>
            <div
              className={`my-1 ${
                filter === 2 ? 'filterBTNSelected' : 'filterBTN'
              }`}
              onClick={() => changeFilterHandler(2)}
            >
              Reclamos <span>({state.meta.total_actions})</span>
            </div>
          </div>
          {state.notifications.map((notification) => (
            <NotificationSwitch data={notification} key={notification.id} />
          ))}
          <Paginator
            paginator={state.paginator}
            getPaginator={getNotificationsPag}
          />
        </div>
      </div>
    </>
  )
}
