import React, {useState} from 'react'
import { X } from 'react-feather'

import './index.css'

export default function ConsultItem(props) {
    const [responseType, setResponseType] = useState(null)
    const [comunication, setComunication] = useState(false)
    return (
        <div className="consult_item position-relative col-10 mb-4 mx-auto d-flex flex-column">
            <small className="fw-bold text-primary">{props.od}</small>
            {responseType === null && <div className="d-flex flex-row mt-3">
                <button onClick={() => setResponseType('sistema')} className="btn btn-outline-primary rounded-pill px-3 mx-2">Responder por sistema</button>
                <button onClick={() => setResponseType('llamado')} className="btn btn-outline-primary rounded-pill px-3 mx-2">Respondido por llamado</button>
                <button onClick={() => setResponseType('whatsapp')} className="btn btn-outline-primary rounded-pill px-3 mx-2">Respondido por WhatsApp</button>
            </div>}

            {responseType === 'sistema' && <div className="row mt-3">
                <div className="col-10">
                    <textarea name="response" id="response" cols="30" rows="5" className="w-100 form-control"></textarea>
                </div>
                <div className="col-2 d-flex flex-column pt-3">
                    <button onClick={() => setResponseType(null)} className="btn bg-none p-0"><X size={24} color="#000" /></button>
                    <button className="btn btn-outline-primary px-3 rounded-pill mt-3">Enviar</button>
                </div>
                <div className="d-flex flex-row mt-3">
                    <input onChange={e => setComunication(!comunication)} checked={comunication} className="form-check-input" type="radio" name="intern" id="intern" />
                    <label className="form-check-label mx-2" htmlFor="intern">Agregar comunicación interna</label>
                </div>
                {comunication && <div className="row mt-3">
                    <div className="col-10">
                        <textarea name="comunication" id="comunication" cols="30" rows="5" className="w-100 form-control"></textarea>
                    </div>
                    <div className="col-2 d-flex flex-column pt-5">
                        <button className="btn btn-outline-primary px-3 rounded-pill mt-2">Guardar</button>

                    </div>
                </div>}
            </div>}

            {responseType === 'llamado' && <div className="row mt-3">
                <div className="col-10">
                    <textarea name="response" id="response" cols="30" rows="5" className="w-100 form-control"></textarea>
                </div>
                <div className="col-2 d-flex flex-column pt-3">
                    <button onClick={() => setResponseType(null)} className="btn bg-none p-0"><X size={24} color="#000" /></button>
                    <button className="btn btn-outline-primary px-3 rounded-pill mt-3">Enviar</button>
                </div>
            </div>}

            {responseType === 'whatsapp' && <div className="row mt-3">
                <div className="col-10">
                    <textarea name="response" id="response" cols="30" rows="5" className="w-100 form-control"></textarea>
                </div>
                <div className="col-2 d-flex flex-column pt-3">
                    <button onClick={() => setResponseType(null)} className="btn bg-none p-0"><X size={24} color="#000" /></button>
                    <button className="btn btn-outline-primary px-3 rounded-pill mt-3">Enviar</button>
                </div>
            </div>}
        </div>
    )
}
