import React from 'react'

import loadingIcon from '../assets/images/ksloader.png'

import { Spinner } from 'reactstrap'

const Loader = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center h-100"
      style={{
        width: '100%',
        height: '400px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
      }}
    >
      <img
        src={loadingIcon}
        style={{ width: '200px', height: 'auto' }}
        alt="Loading..."
      />
      <Spinner
        color="primary"
        style={{
          height: '3rem',
          width: '3rem',
          marginTop: '40px',
        }}
        type="grow"
      >
        Cargando...
      </Spinner>
    </div>
  )
}

export default Loader
