import React from 'react'
import claimModal from '../styles/claimModal.module.css'
import PropTypes from 'prop-types'

const AprobacionPropuestaCard = ({ date }) => {
  return (
    <div className={claimModal.cardContainer}>
      <p className={claimModal.redTitle}>
        Aprobación de Propuesta de Tratamiento
      </p>
      <div>
        <p className={claimModal.infoTitle}>Fecha de envio:</p>
        <p className="my-0">{date}</p>
      </div>
    </div>
  )
}

AprobacionPropuestaCard.propTypes = {
  date: PropTypes.string.isRequired,
}

export default AprobacionPropuestaCard
