import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import apiClient from "../../../utils/backend";
import { useHistory } from "react-router";

const ApprobationModal = (props) => {
  const { className, id, name, opened, handleModal } = props;

  const [obs, setObs] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleApprove() {
    setLoading(true);

    apiClient
      .post(`/api/status/nextStatus`, {
        treatment_id: id,
        process: "Aprobacion",
        observation: obs,
      })
      .then((res) => {
        if (res.status === 200) {
          history.go(0);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <Modal isOpen={opened} toggle={handleModal} className={className}>
        <ModalBody>
          <div className="container">
            <h2 className="mb-3 fw-bold text-success text-center">
              Aprobar tratamiento
            </h2>
            <div className="row">
              <div className="col-6 mx-auto mt-3 mb-3">
                <p className="fw-bold">ACUERDO DE TRATAMIENTO</p>
              </div>
              <div
                className="col-12 px-3 text-center"
                style={{ lineHeight: "1.2em" }}
              >
                <p>
                  Aprobando este tratamiento confeccionado por KeepSmiling para
                  el paciente <strong>{name}</strong>, usted se muestra de
                  acuerdo que este se ajusta al Plan de Tratamiento propuesto en
                  el Formulario de Diagnóstico y Tratamiento.{" "}
                </p>
                <br />
                <p>
                  Una vez que se aprueba el tratamiento, KeepSmiling iniciará la
                  produccion de las placas alineadoras.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button
                  className="btn btn-success text-white w-100 rounded-pill px-5"
                  onClick={handleApprove}
                >
                  APROBAR AHORA
                </button>
                <button
                  className="btn btn-danger text-white w-100 rounded-pill px-5 mt-2"
                  onClick={handleModal}
                >
                  NO AHORA
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ApprobationModal;
