import React from 'react'
import { useSelector } from 'react-redux'

const DocumentationOriginalRegister = ({ originalRegister }) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  if (pageWidth === 'desktop')
    return <iframe title="algo" src={originalRegister?.url} />
  else
    return (
      <div className="form-error-card text-center text-primary p-4">
        <a href={originalRegister?.url}>
          <p className="fs-5 fw-bold mb-3"> Registro Original</p>
          <p>
            Ya se encuentra disponible el registro original de su paciente. haga
            click aqui para acceder al visor 3D.
          </p>
        </a>
      </div>
    )
}

export default DocumentationOriginalRegister
