import React from 'react'
import '../../../style.css'

const Overjet = ({ formData }) => {

  const searchOverjetUp = (number) => {
    return formData?.overjet_up.includes(number)
  }
  const searchOverjetDown = (number) => {
    return formData?.overjet_down.includes(number)
  }

  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Overjet</p>
      <hr className="mt-2" />
      <div className="d-flex flex-column">
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="overjet"
            disabled
            checked={formData.overjet == 1 && true}
          />
          <label className="ms-2">Mantener</label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="overjet"
            disabled
            checked={formData.overjet == 2 && true}
          />
          <label className="ms-2">Mejorar</label>
        </div>
      </div>
      {formData.overjet == 2 && (
        <div className="improve-overjet-container">
          <div className="w-100">
            <p className="improve-overjet-title">
              ¿Cómo desea mejorar el Overjet?
            </p>
            <hr className="mt-1" />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="mb-0">Overjet Aumentado</p>
              <hr className="mt-1" />
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetImproved"
                  disabled
                  checked={searchOverjetUp(1)}
                />
                <label className="ms-2">Por retrusión superior</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetImproved"
                  disabled
                  checked={searchOverjetUp(2)}
                />
                <label className="ms-2">Por retroinclinación superior(torque negativo)</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetImproved"
                  disabled
                  checked={searchOverjetUp(3)}
                />
                <label className="ms-2">Por protrusión inferior</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetImproved"
                  disabled
                  checked={searchOverjetUp(4)}
                />
                <label className="ms-2">Por proinclinación inferior(torque positivo)</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetImproved"
                  disabled
                  checked={searchOverjetUp(5)}
                />
                <label className="ms-2">Otras:</label>
              </div>
              {searchOverjetUp(5) && (
                <>
                  <p className="mt-3">Otras:</p>
                  <div className="step-text-area-overjet overflow-scroll">
                    {formData.overjet_up_other}
                  </div>
                </>
              )}
            </div>
            <div>
              <p className="mb-0">Overjet Disminuido</p>
              <hr className="mt-1" />
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetDiminished"
                  disabled
                  checked={searchOverjetDown(1)}
                />
                <label className="ms-2">Por protrusión superior</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetDiminished"
                  disabled
                  checked={searchOverjetDown(2)}
                />
                <label className="ms-2">Por proinclinación superior(torque positivo)</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetDiminished"
                  disabled
                  checked={searchOverjetDown(3)}
                />
                <label className="ms-2">Por retrusión inferior</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetDiminished"
                  disabled
                  checked={searchOverjetDown(4)}
                />
                <label className="ms-2">Por retroinclinación inferior(torque negativo)</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="mt-2 inputColor"
                  name="overjetDiminished"
                  disabled
                  checked={searchOverjetDown(5)}
                />
                <label className="ms-2">Otras:</label>
              </div>
              {searchOverjetDown(5) && (
                <>
                  <p className="mt-3">Otras:</p>
                  <div className="step-text-area-overjet overflow-scroll">
                    {formData.overjet_down_other}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Overjet
