import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";
import ComunicationsModal from "./components/comunications_modal";
import FiltersModal from "./components/filters_modal";

// COMPONENTS
import ConsultCard from "../Consultas/components/consult_card";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      step: "all",
      consults: [],
      all: [],
      clinic: [],
      facturation: [],
      marketing: [],
      cobranzas: [],
      other: [],
    };
  }

  componentDidMount() {
    apiClient
      .get("/api/consults")
      .then((res) => {
        console.log(res);
        let c = res.data;

        this.setState({
          atention: c.atencion,
          clinic: c.clinica,
          facturation: c.facturacion,
          marketing: c.marketing,
          other: c.otras,
          all: c.todas,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  handleTreatmentColor(type) {
    const colors = {
      "KeepSmiling Full": 1,
      "KeepSmiling Fast": 2,
      "KeepSmiling Medium": 3,
      "KeepSmiling Kids": 4,
      "KeepSmiling Kids Fast": 5,
      "KeepSmiling Teens": 6,
    };

    return colors[type];
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-5 position-relative">
          <div className="row pt-4" style={{ zIndex: 2 }}>
            <div className="col-12 d-flex flex-row justify-content-between px-4">
              <h1 className="text-primary fw-bold">Comunicaciones</h1>

              <ComunicationsModal />
            </div>
          </div>

          <div className="row px-4 mt-5">
            <div className="col-12">
              <input
                type="text"
                className="w-100 border-0 p-3 shadow rounded-3"
                placeholder="Escribe al menos 3 caracteres..."
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="row px-2">
            <div className="col-12">
              <ul
                className="d-flex flex-row justify-content-start p-0 px-2 border-bottom"
                style={{ listStyleType: "none" }}
              >
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "all" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "all" })}
                  >
                    Todas{" "}
                    <span className="text-muted">
                      ({this.state.all.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "clinic" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "clinic" })}
                  >
                    Clinica{" "}
                    <span className="text-muted">
                      ({this.state.clinic.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "atention" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "facturation" })}
                  >
                    Atencion y Logistica{" "}
                    <span className="text-muted">
                      ({this.state.atention.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "marketing" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "marketing" })}
                  >
                    Marketing{" "}
                    <span className="text-muted">
                      ({this.state.marketing.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "other" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "other" })}
                  >
                    Otras{" "}
                    <span className="text-muted">
                      ({this.state.other.length})
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-2 px-4">
            <div className="col-10 d-flex flex-row">
              <button
                className="btn rounded-pill py-1 px-2 mx-2 fw-bold text-uppercase"
                style={{ border: "1px solid #1D9A5F", color: "#1D9A5F" }}
              >
                Resuelto: {this.state[this.state.step].length}
              </button>
              <button
                className="btn rounded-pill py-1 px-2 mx-2 fw-bold text-uppercase"
                style={{ border: "1px solid #FFED46", color: "#FFED46" }}
              >
                Pendiente: {this.state[this.state.step].length}
              </button>
              <button
                className="btn rounded-pill py-1 px-2 mx-2 fw-bold text-uppercase"
                style={{ border: "1px solid #FFBB00", color: "#FFBB00" }}
              >
                En Espera: {this.state[this.state.step].length}
              </button>
              <button
                className="btn rounded-pill py-1 px-2 mx-2 fw-bold text-uppercase"
                style={{ border: "1px solid #EF64F3", color: "#EF64F3" }}
              >
                Requiere seguimiento: {this.state[this.state.step].length}
              </button>
            </div>
            <div className="col-2">
              <FiltersModal />
            </div>
          </div>
          <div className="row mt-3 px-4">
            <div className="col-12">
              {this.state[this.state.step].todos.map((item, i) => {
                console.log(item);
                return (
                  <div>
                    <ConsultCard data={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Home);
