import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
// import toast from 'react-hot-toast'
import {
  gendersData,
  selectStyleData,
  documentoDeIdentidadCol,
} from './utilies/constantInfo'
import { User } from 'react-feather'
import Select from 'react-select'
import OdProfile from './components/OdProfile'
import './styles/index.css'
import { useSelector } from 'react-redux'
import { fiscalConditionOptions } from './utilies/fiscalConditionOptions'
import apiClient from '../../../utils/backend'
import Input from '../../../components/input'
import handleToast from '../../../components/toaster'
import useCountryInputs from './hooks/useCountryInputs'

const ProfileMobile = () => {
  const [loadingData, setLoadingData] = useState(false)
  const userId = useSelector((state) => state.userReducer.dentistId)
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)
  const history = useHistory()
  const [avatar, setAvatar] = useState(null)
  const [avatarLink, setAvatarLink] = useState(null)
  const [genderObject, setGenderObject] = useState(null)
  const [fiscalConditionObject, setFiscalConditionObject] = useState()
  const [documentoDeIdentidad, setDocumentoDeIdentidad] = useState(null)
  const isColombian = countryId === 4
  const isChilean = countryId === 5
  const {
    handleGetCUIT,
    handleGetCondicionFiscal,
    getCondicionFiscalData,
    getMatriculaNacional,
    getMatriculaProvincial,
  } = useCountryInputs(countryId)
  const [requiredData, setRequiredData] = useState({
    name: null,
    surname: null,
    email: null,
    gender: null,
    birthdate: null,
    rut: null,
  })
  const [personalData, setPersonalData] = useState({
    accreditation_course: null,
    profilePhoto: null,
    phone_number: null,
    cuit: null,
    fiscal_condition: null,
    national_enrollment: null,
    provintial_enrollment: null,
    facebook: null,
    instagram: null,
    twitter: null,
    web: null,
  })
  const [profile, setProfile] = useState({
    sao: false,
    study_group: false,
    sg_team: null,
    sg_type: null,
    sg_level: null,
  })
  const [errorField, setErrorField] = useState({
    name: '',
    surname: '',
    gender: '',
    birthdate: '',
    email: '',
    accreditation_course: '',
    cuit: '',
    sg_type: '',
    national_enrollment: '',
    provintial_enrollment: '',
  })

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const handleSetErrors = (err) => {
    let profileError = false
    if (
      err['profile.sg_type'] ||
      err['profile.sg_team'] ||
      err['profile.sg_level']
    )
      profileError = true

    setErrorField({
      ...errorField,
      name: err['user.name'] !== null && err['user.name'],
      surname: err['user.surname'] !== null && err['user.surname'],
      gender: err['user.gender'] !== null && err['user.gender'],
      email: err['user.email'] !== null && err['user.email'],
      cuit: err['dentist.cuit'] !== null && err['dentist.cuit'],
      rut: err['user.rut'] !== null && err['user.rut'],
      national_enrollment:
        err['dentist.national_enrollment'] !== null &&
        err['dentist.national_enrollment'],
      provintial_enrollment:
        err['dentist.provintial_enrollment'] !== null &&
        err['dentist.provintial_enrollment'],
      profile: profileError,
      accreditation_course:
        err['dentist.accreditation_course'] !== null &&
        err['dentist.accreditation_course'],
    })
  }

  const cleanError = (errName) => {
    setErrorField({ ...errorField, [errName]: null })
  }

  const removeAvatar = () => {
    setAvatar(null)
  }

  const handleRedirect = () => {
    handleToast('success', '¡Dentista editado exitosamente!')

    setTimeout(() => {
      history.replace('/od/home')
    }, 2000)
  }

  const handleRequest = async () => {
    setLoadingData(true)
    const data = {
      user: {
        ...requiredData,
      },
      dentist: {
        ...personalData,
      },
      profile,
    }
    try {
      const res = await apiClient.put(`api/dentists/${userId}`, data)
      if (res.data.success) {
        return handleSendAvatar(res.data.data.id)
      }
      handleToast('error', '¡Error al actualizar el dentista!')

      handleSetErrors(res.data.errors)
      setLoadingData(false)
    } catch (error) {
      handleToast('error', '¡Error al actualizar el dentista!')
      setLoadingData(false)
      console.log(error)
    }
  }

  const handleSendAvatar = async (dentistId) => {
    if (!avatar) return handleRedirect()
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const data = new FormData()
    data.append('avatar', avatar)
    data.append('_method', 'PUT')
    try {
      const res = await apiClient.post(
        `/api/dentists/${dentistId}/avatar`,
        data,
        headers
      )
      if (res.status === 200) handleRedirect()
    } catch (err) {
      setLoadingData(false)
      console.log(err)
      handleToast('error', 'Error al cargar el avatar!')
    }
  }

  const handleGender = (value) => {
    switch (value) {
      case 'f':
        setGenderObject({ label: 'Femenino', value: value })
        break
      case 'm':
        setGenderObject({ label: 'Masculino', value: value })
        break
      case 'o':
        setGenderObject({ label: 'Otro', value: value })
        break
      default:
        break
    }
  }

  const handleFisicalCondition = (value) => {
    setFiscalConditionObject({
      label: fiscalConditionOptions[value],
      value: value,
    })
  }

  // Set data from current dentist
  useEffect(() => {
    if (!userId) return
    const apiCall = async () => {
      const res = await apiClient.get(
        `/api/dentists/${userId}?include=user,offices,studyGroupRelationship`
      )
      let dentist = res.data.data

      let birthdate_polyfill = dentist.user.birthdate
        ?.split('/')
        .reverse()
        .join('-')

      setRequiredData({
        name: dentist.user.name,
        surname: dentist.user.surname,
        email: dentist.user.email,
        gender: dentist.user.gender,
        birthdate: birthdate_polyfill,
        rut: dentist.user.rut,
      })
      handleGender(dentist.user.gender)
      handleFisicalCondition(dentist.fiscal_condition)
      if (dentist.user.avatar) setAvatarLink(dentist.user.avatar)
      setPersonalData({
        id: dentist.id,
        profilePhoto: dentist.user.avatar,
        phone_number: dentist.phone_number,
        cuit: dentist.cuit,
        fiscal_condition: dentist.fiscal_condition,
        national_enrollment: dentist.national_enrollment,
        provintial_enrollment: dentist.provintial_enrollment,
        facebook: dentist.facebook,
        instagram: dentist.instagram,
        twitter: dentist.twitter,
        web: dentist.web,
        accreditation_course: moment(dentist.accreditation_course).format(
          'YYYY-MM-DD'
        ),
      })
      setDocumentoDeIdentidad({
        value: dentist.cuit_prefix,
        label: dentist.cuit_prefix,
      })
      setProfile({
        sao: dentist.sao,
        study_group: dentist.study_group,
        sg_type: dentist.study_group_relationship?.rol
          ? Number(dentist.study_group_relationship?.rol)
          : null,
        sg_level: dentist.study_group_relationship?.type
          ? Number(dentist.study_group_relationship?.type)
          : null,
        sg_team:
          dentist.study_group_relationship?.group_id !== null
            ? Number(dentist.study_group_relationship?.group_id)
            : null,
      })
    }
    apiCall()
  }, [userId])

  const displayAvatarImage = () => {
    if (avatar || avatarLink) {
      return (
        <img
          className="shadow-lg"
          src={avatar ? personalData.profilePhoto : avatarLink}
          alt="Foto de perfil"
          height={200}
          width={200}
          style={{ borderRadius: 100 }}
        />
      )
    } else {
      return (
        <div className="flex ">
          <User size={124} color="#ccc" />
          <p className="text-primary text-center">Avatar</p>
        </div>
      )
    }
  }

  return (
    <div className="px-4 py-4" style={{ maxWidth: 600, margin: 'auto' }}>
      <p className="fw-bold text-primary fs-1 mb-4">Mi Perfil</p>

      <input
        type="file"
        name="avatar"
        onChange={(e) => {
          setAvatar(e.target.files[0])
          getBase64(e.target.files[0], (res) =>
            setPersonalData({ ...personalData, profilePhoto: res })
          )
        }}
        id="od_profile_pic_input"
      />
      <label htmlFor="od_profile_pic_input">{displayAvatarImage()}</label>
      {avatar && !avatarLink && (
        <div class="d-grid gap-2 mx-auto mt-3">
          <button
            className="btn btn-danger text-white"
            onClick={() => removeAvatar()}
          >
            Eliminar
          </button>
        </div>
      )}
      <p className="fw-bold mt-4 text-primary fs-4">Datos Personales</p>
      <div className="my-4">
        <Input
          disabled
          label="Nombre"
          type="text"
          id="name"
          name="name"
          onChange={(e) => {
            cleanError('name')
            setRequiredData({
              ...requiredData,
              name: e.target.value,
            })
          }}
          value={requiredData.name}
        />
        {errorField.name && (
          <p className="text-danger fw-light">{errorField.name}</p>
        )}
      </div>
      <div className="my-4">
        <Input
          disabled
          label="Apellido"
          type="text"
          id="surname"
          name="surname"
          value={requiredData.surname}
          onChange={(e) => {
            cleanError('surname')
            setRequiredData({
              ...requiredData,
              surname: e.target.value,
            })
          }}
        />
        {errorField.surname && (
          <p className="text-danger fw-light">{errorField.surname}</p>
        )}
      </div>
      <div className="my-4 mx-2">
        <label htmlFor="gender">Género</label>
        <Select
          styles={selectStyleData}
          options={gendersData}
          placeholder="Seleccione el género..."
          value={genderObject}
          onChange={(e) => {
            cleanError('gender')
            setGenderObject(e)
            setRequiredData({
              ...requiredData,
              gender: e.value,
            })
          }}
        />
        {errorField.gender && (
          <p className="text-danger fw-light mt-2">{errorField.gender}</p>
        )}
      </div>

      <div className="my-4">
        <Input
          label="Fecha de Nacimiento"
          type="date"
          id="birthdate"
          value={requiredData.birthdate}
          onChange={(e) =>
            setRequiredData({
              ...requiredData,
              birthdate: e.target.value,
            })
          }
        />
      </div>
      <div className="my-4">
        <Input
          disabled
          label="Email"
          type="email"
          value={requiredData.email}
          onChange={(e) => {
            cleanError('email')
            setRequiredData({
              ...requiredData,
              email: e.target.value,
            })
          }}
        />
        {errorField.email && (
          <p className="text-danger fw-light">{errorField.email}</p>
        )}
      </div>
      <div className="my-4">
        <Input
          label="Telefono"
          type="number"
          value={personalData.phone_number}
          onChange={(e) => {
            if (e.target.value.length < 25)
              setPersonalData({
                ...personalData,
                phone_number: e.target.value,
              })
          }}
        />
      </div>
      <hr />
      <div className="row mb-4">
        {isColombian && (
          <div className="d-flex col-6 flex-column m-0">
            <label>Documento de Identidad</label>
            <div className="d-flex">
              <div className="col-4 ">
                <Select
                  styles={selectStyleData}
                  placeholder="Seleccione..."
                  options={documentoDeIdentidadCol}
                  value={documentoDeIdentidad}
                  onChange={(e) => {
                    setDocumentoDeIdentidad(e)
                    setPersonalData({
                      ...personalData,
                      cuit_prefix: e.value,
                    })
                  }}
                />
              </div>
              <div className="col-8">
                <Input
                  type="number"
                  id="cuit_col"
                  value={personalData.cuit}
                  onChange={(e) => {
                    setPersonalData({
                      ...personalData,
                      cuit: e.target.value,
                    })
                    cleanError('cuit_col')
                  }}
                />
              </div>
            </div>
            {errorField.cuit && (
              <p className="text-danger fw-light">{errorField.cuit}</p>
            )}
          </div>
        )}
        {isChilean ? (
          <div className="col-6">
            <Input
              label={handleGetCUIT(countryId) ?? 'CUIT'}
              type="text"
              id="rut"
              value={requiredData.rut}
              onChange={(e) => {
                cleanError('rut')
                setRequiredData({
                  ...requiredData,
                  rut: e.target.value,
                })
              }}
            />
            {errorField.rut && (
              <p className="text-danger fw-light">{errorField.rut}</p>
            )}
          </div>
        ) : (
          <div className="col-6">
            <Input
              label={handleGetCUIT(countryId) ?? 'CUIT'}
              type="number"
              id="cuit"
              value={personalData.cuit}
              onChange={(e) => {
                cleanError('cuit')
                setPersonalData({
                  ...personalData,
                  cuit: e.target.value,
                })
              }}
            />
            {errorField.cuit && (
              <p className="text-danger fw-light">{errorField.cuit}</p>
            )}
          </div>
        )}
        <div className="col-6">
          {/* <label htmlFor="fiscal_condition">Condición Fiscal</label> */}
          <label htmlFor="fiscal_condition">{handleGetCondicionFiscal()}</label>
          <Select
            styles={selectStyleData}
            // placeholder="Seleccione la condición fiscal..."
            placeholder="Seleccione..."
            // options={fiscalConditionData}
            options={getCondicionFiscalData()}
            value={fiscalConditionObject}
            onChange={(e) => {
              setFiscalConditionObject(e)
              setPersonalData({
                ...personalData,
                fiscal_condition: e.value,
              })
            }}
          />
        </div>
      </div>
      {!isColombian && !isChilean && (
        <div className="row mb-4">
          <div className="col-6">
            <Input
              label={getMatriculaNacional()}
              type="number"
              value={personalData.national_enrollment}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  cleanError('national_enrollment')
                setPersonalData({
                  ...personalData,
                  national_enrollment: e.target.value,
                })
              }}
            />
            {errorField.national_enrollment && (
              <p className="text-danger fw-light">
                {errorField.national_enrollment}
              </p>
            )}
          </div>
          <div className="col-6">
            <Input
              label={getMatriculaProvincial()}
              type="number"
              value={personalData.provintial_enrollment}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  cleanError('provintial_enrollment')
                setPersonalData({
                  ...personalData,
                  provintial_enrollment: e.target.value,
                })
              }}
            />
            {errorField.provintial_enrollment && (
              <p className="text-danger fw-light">
                {errorField.provintial_enrollment}
              </p>
            )}
          </div>
        </div>
      )}
      <hr />
      <h5 className="fw-bold">Curso de Acreditación</h5>
      <div className="row mb-4 mt-3 me-2">
        <Input
          disabled
          label="Fecha en la que se realizó el curso"
          type="date"
          id="dateOfBirth"
          value={personalData.accreditation_course}
          onChange={(e) => {
            cleanError('accreditation_course')
            setPersonalData({
              ...personalData,
              accreditation_course: e.target.value,
            })
          }}
        />
        {errorField.accreditation_course && (
          <p className="text-danger fw-light">
            {errorField.accreditation_course}
          </p>
        )}
      </div>
      <hr />
      <h5 className="fw-bold">Redes Sociales</h5>
      <div className="row mb-4 mt-3">
        <label htmlFor="facebook">Facebook</label>
        <input
          type="text"
          className="form-control"
          id="facebook"
          value={personalData.facebook}
          onChange={(e) =>
            setPersonalData({
              ...personalData,
              facebook: e.target.value,
            })
          }
        />

        <label htmlFor="instagram">Instagram</label>
        <input
          type="text"
          className="form-control"
          id="instagram"
          value={personalData.instagram}
          onChange={(e) =>
            setPersonalData({
              ...personalData,
              instagram: e.target.value,
            })
          }
        />

        <label htmlFor="twitter">Twitter</label>
        <input
          type="text"
          className="form-control"
          id="twitter"
          value={personalData.twitter}
          onChange={(e) =>
            setPersonalData({
              ...personalData,
              twitter: e.target.value,
            })
          }
        />

        <label htmlFor="web">Web Personal</label>
        <input
          type="text"
          className="form-control"
          id="web"
          value={personalData.web}
          onChange={(e) =>
            setPersonalData({
              ...personalData,
              web: e.target.value,
            })
          }
        />
      </div>

      <hr />
      <OdProfile profile={profile} mobile={true} />
      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <button
          disabled={loadingData ? true : false}
          className="btn btn-primary"
          onClick={handleRequest}
        >
          Guardar
        </button>
      </div>
    </div>
  )
}

export default ProfileMobile
