import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../utils/backend'

export const useNotifications = (page) => {
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    notifications: [],
    paginator: [],
    meta: [],
  })

  const [filter, setFilter] = useState(1)
  const { dentistId } = useSelector((state) => state.userReducer)
  const url = `api/dentists/${dentistId}/notifications?cant_pages=10&page=1`

  const getUrl = (type = 1, page = 1) => {
    return `api/dentists/${dentistId}/notifications${
      type === 2 ? '/actions' : ''
    }?cant_pages=10&page=${page}`
  }

  const getNotifications = async () => {
    try {
      const response = await apiClient.get(getUrl(state.filter, 1))
      setState({
        notifications: response.data.table,
        paginator: response.data.paginator,
        meta: response.data.meta,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getNotificationsPag = async (currentPage) => {
    console.log(currentPage)
    let paginatorURL = getUrl(filter, currentPage)
    if (state.searchQuery) {
      paginatorURL += `&filter[treatment.patient.fullname]=${state.searchQuery}`
    }

    try {
      const response = await apiClient.get(paginatorURL)
      setState({
        notifications: response.data.table,
        paginator: response.data.paginator,
        meta: response.data.meta,
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const changeFilterHandler = async (newFilter) => {
    setFilter(newFilter)
    try {
      const response = await apiClient.get(getUrl(newFilter))
      setState({
        clearSearchBarInput: true,
        notifications: response.data.table,
        paginator: response.data.paginator,
        meta: response.data.meta,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    //load initial data
    getNotifications()
  }, [])

  useEffect(() => {
    //Reload inital data on searchbar clear
    if (!state.loadTodosFilter) return
    getNotifications()
  }, [state])

  return {
    loading,
    state,
    setState,
    getNotificationsPag,
    url,
    changeFilterHandler,
    filter,
  }
}
