import React, { Component } from "react";
import Select from "react-select";
import apiClient from "../../../utils/backend";

// IMAGES

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      apellido: null,
      gender: null,
      email: null,
      password: null,
      fecha_nacimiento: null,

      error: { error: false, message: "" },
    };

    this.handleRequest = this.handleRequest.bind(this);
  }

  handleRequest() {
    let user = {
      name: this.state.name,
      surname: this.state.apellido,
      od: this.state.od,
      age: this.state.age,
      gender: this.state.gender,
      email: this.state.email,
      user: this.state.user,
      password: this.state.password,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    console.log(this.state);
    apiClient
      .post("/api/dentists", { user }, headers)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.props.history.push("/pacientes");
        } else if (res.status === 422) {
          console.log("res error, ", res);
          this.setState({
            error: {
              error: true,
              message:
                "Error al crear el paciente, por favor intente nuevamente.",
            },
          });
        } else {
          this.setState({
            error: {
              error: true,
              message:
                "Error al crear el paciente, por favor intente nuevamente.",
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: {
            error: true,
            message:
              "Error al crear el paciente, por favor intente nuevamente.",
          },
        });
        this.alertComponent.scrollIntoView({ behavior: "smooth" });
      });
  }

  handleAddressModify(id, value) {
    let state = [...this.state.direcciones];
    let dir = state[0];

    dir[id] = value;

    state[0] = dir;
    console.log(state);
  }

  render() {
    const selectStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        borderBottom: "2px solid #0027a8!important",
      }),
    };

    const genders = [
      { value: "m", label: "Masculino" },
      { value: "f", label: "Femenino" },
      { value: "o", label: "Otro" },
    ];

    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-10 d-flex flex-row justify-content-between">
            <h1 style={{ fontWeight: 700 }} className="text-primary">
              Nuevo Paciente
            </h1>
          </div>
        </div>
        <div className="row mt-4">
          {this.state.error.error ? (
            <div
              className="col-12 alert alert-danger text-center"
              ref={(el) => {
                this.alertComponent = el;
              }}
            >
              {this.state.error.message}
            </div>
          ) : null}
          <div className="col-6">
            <h5 className="fw-bold">Datos personales</h5>
            <form className="row mb-4 mt-2">
              <div className="col-6">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  onChange={(e) =>
                    this.setState({ [e.target.id]: e.target.value })
                  }
                />
              </div>
              <div className="col-6">
                <label htmlFor="apellido">Apellido</label>
                <input
                  type="text"
                  className="form-control"
                  id="apellido"
                  onChange={(e) =>
                    this.setState({ [e.target.id]: e.target.value })
                  }
                />
              </div>
            </form>
            <form className="row mb-4">
              <div className="col-6">
                <label htmlFor="gender">Genero</label>
                <Select
                  styles={selectStyle}
                  options={genders}
                  placeholder="Seleccione el genero..."
                  isSearchable
                  onChange={(e) => this.setState({ gender: e.value })}
                />
              </div>
              <div className="col-6">
                <label htmlFor="fecha_nacimiento">Fecha de nacimiento</label>
                <input
                  type="date"
                  className="form-control"
                  id="fecha_nacimiento"
                  onChange={(e) =>
                    this.setState({ [e.target.id]: e.target.value })
                  }
                />
              </div>
            </form>
            <form className="row mb-4">
              <div className="col-12">
                <label htmlFor="od">Odontologo</label>
                <Select
                  styles={selectStyle}
                  options={genders}
                  placeholder="Seleccione el odontologo..."
                  isSearchable
                  onChange={(e) => this.setState({ od: e.value })}
                />
              </div>
            </form>
          </div>
          <div
            className="col-4"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <h5 className="fw-bold">Usuario</h5>
            <form className="row mb-4 mt-3">
              <div className="col-6">
                <label htmlFor="user">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ [e.target.id]: e.target.value })
                  }
                />
              </div>
              <div className="col-6">
                <label htmlFor="password">Contraseña</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  onChange={(e) =>
                    this.setState({ [e.target.id]: e.target.value })
                  }
                />
              </div>
              <div className="col-12">
                <label htmlFor="password">Usuario</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  onChange={(e) =>
                    this.setState({ [e.target.id]: e.target.value })
                  }
                />
              </div>
              <p className="m-0">
                El usuario no debe contener letras mayusculas, y debe ser una
                combinación única entre letras y numeros. También puede ser el
                mismo email colocado anteriormente.
              </p>
            </form>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-row justify-content-end">
              <button
                className="btn btn-light mx-3"
                onClick={() => this.props.history.push("/")}
              >
                Volver
              </button>
              <button className="btn btn-primary" onClick={this.handleRequest}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
