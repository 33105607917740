import React, { useState } from 'react'
import paymentAll from '../styles/paymentAll.module.css'
import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import PaymentsAllCard from '../components/PaymentsAllCard'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import handleToast from '../../../components/toaster'
import apiClient from '../../../utils/backend'

const PaymentsAllModal = ({
  paymentState,
  setPaymentState,
  getAllPayments,
}) => {
  const [opened, setOpened] = useState(false)
  const [total, setTotal] = useState(0)

  const handleSendPayments = async () => {
    handleToast('updating', 'Habilitando tratamientos...')
    const paymentsSelected = paymentState.payments.filter(
      (payment) => payment.selected !== false
    )

    const dataToSend = {
      payment_validations: paymentsSelected.map((payment) => ({
        treatment_id: payment.id,
        payment_status: 1,
      })),
    }
    try {
      const res = await apiClient.post(`api/paymentvalidations`, dataToSend)
      getAllPayments(1, 1)
      handleToast('success', 'Tratamientos habilitados correctamente')
      setOpened(!opened)
    } catch (error) {
      handleToast('error', 'Hubo un error al habilitar los tratamientos')
      console.log(error)
    }
  }

  useEffect(() => {
    let payments = paymentState.payments.filter(
      (payment) => payment.selected === true
    )
    setTotal(payments.length)
  }, [paymentState])

  return (
    <>
      <div
        className={paymentAll.paymentSelectedBTN}
        onClick={() => setOpened(!opened)}
      >
        Habilitar seleccionado <span className="text-muted">({total})</span>
      </div>
      <Modal
        style={{ marginTop: '150px' }}
        isOpen={opened}
        size="lg"
        toggle={() => setOpened(!opened)}
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-between">
              <p className={`${paymentAll.titleHeader} fs-4`}>
                Habilitar seleccionado
              </p>
              <X
                className={paymentAll.closeBTN}
                onClick={() => setOpened(false)}
                size={30}
              />
            </div>
            <div className="d-flex mt-3">
              <div
                className={`${paymentAll.numberOfCases} d-flex flex-column w-50`}
              >
                <p>Cantidad de casos para habilitar:</p> <span>{total}</span>
              </div>
            </div>
            <div className={paymentAll.headerList}>
              <div className={paymentAll.id}>ID</div>
              <div className={paymentAll.patient}>Paciente</div>
              <div className={paymentAll.doctor}>Doctor</div>
            </div>
            <div className={paymentAll.cardsContainer}>
              {paymentState.payments.map((payment) => (
                <PaymentsAllCard
                  key={payment.id}
                  data={payment}
                  paymentState={paymentState}
                  setPaymentState={setPaymentState}
                />
              ))}
            </div>
            <div className="d-flex justify-content-end mt-3">
              <div
                className={paymentAll.paymentButton}
                onClick={() => handleSendPayments()}
              >
                Habilitar
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PaymentsAllModal

PaymentsAllModal.propTypes = {
  paymentState: PropTypes.object.isRequired,
  setPaymentState: PropTypes.func.isRequired,
}
