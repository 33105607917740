import React, { useEffect, useState } from 'react'
import ImageInput from './ImageInput'
import FormOnline from '../../../FormOnlineOnePage/FormOnline'
import apiClient from '../../../../utils/backend'
import { baseToFile } from '../../../../utils/baseToFile'
import { Spinner } from 'reactstrap'

const CHTreatmentPlan = ({
  data,
  documentationData,
  loadDataAdmin,
  backendFiles,
}) => {
  const [loading, setLoading] = useState(false)
  const [planFiles, setPlanFiles] = useState([])
  const formData = documentationData.plan.online_form

  const onDeleteImage = async (fileId) => {
    setLoading(true)
    try {
      await apiClient.delete(`api/images/${fileId}`)
      setPlanFiles([])
      loadDataAdmin()
    } catch (error) {
      console.log(error)
    }
  }

  const handlePostImg = async ({ file, src, imageId, isPDF }) => {
    setLoading(true)
    if (imageId) {
      try {
        await apiClient.delete(`api/images/${imageId}`)
      } catch (error) {
        console.log(error)
      }
    }

    try {
      if (isPDF) {
        //PDF LOAD
        let dataImage = new FormData()
        dataImage.append('plan', file)
        dataImage.append('id_file', Math.ceil(Math.random() * 1000))
        await apiClient.post(`api/incomes/${data.id}/plan`, dataImage)
        loadDataAdmin()
        setPlanFiles([])
      } else {
        //IMAGE LOAD
        const imageFile = baseToFile(file, src + '.jpg')
        let dataImage = new FormData()
        dataImage.append('plan', imageFile)
        dataImage.append('id_file', Math.ceil(Math.random() * 1000))
        await apiClient.post(`api/incomes/${data.id}/plan`, dataImage)
        loadDataAdmin()
        setPlanFiles([])
      }
    } catch (error) {
      setLoading(false)
      setPlanFiles([])
      loadDataAdmin()
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(false)
    setPlanFiles([...backendFiles.plan])
  }, [backendFiles])

  if (loading)
    return (
      <div className="d-flex justify-content-center mx-auto my-6">
        <Spinner
          color="primary"
          style={{
            height: '3rem',
            width: '3rem',
            marginTop: '40px',
          }}
        >
          Cargando...
        </Spinner>
      </div>
    )

  return (
    <div className={`d-flex  justify-content-center mx-4`}>
      {!formData ? (
        <>
          {planFiles.map((item, i) => (
            <ImageInput
              section={'plan'}
              previewImage={item?.thumb ?? item.path}
              fullImage={item.path}
              imageId={item.id}
              src={'plan'}
              name={`Plan - ${i + 1}`}
              onDeleteImage={onDeleteImage}
              handleUpload={handlePostImg}
            />
          ))}
          {planFiles.length < 5 && (
            <ImageInput
              section={'plan'}
              previewImage={''}
              fullImage={''}
              imageId={''}
              src={'plan'}
              name={`Cargar plan`}
              onDeleteImage={onDeleteImage}
              handleUpload={handlePostImg}
            />
          )}
        </>
      ) : (
        <FormOnline
          patientData={data}
          formData={formData}
          documentationData={documentationData}
        />
      )}
    </div>
  )
}

export default CHTreatmentPlan
