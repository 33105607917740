import React from 'react'
import { Check } from 'react-feather'

export default function index({step, first_completed, second_completed, third_completed}) {
    return (
        <div
                  className="col-6 mt-5 mb-5 mx-auto"
                  style={{ position: "relative", maxWidth: "70vw" }}
                  id="timeline"
                >
                  <ul
                    className="w-100"
                    style={{
                      background: "#cecece",
                      height: "3px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      listStyleType: "none",
                      position: "relative",
                    }}
                  >
                    <li
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        background: step > 1 && first_completed ? "#2ecc71" : step > 1 && !first_completed ? "#f1c40f" : "#cecece",
                        position: "absolute",
                        top: "-15px",
                        zIndex: 10,
                        left: "0",
                      }}
                    >
                      {step > 1 && first_completed ? <Check style={{color: '#fff', position: 'absolute', top: '5px', left: '15%', strokeWidth: '3px'}} /> : step > 1 && !first_completed ? <span style={{color: '#fff', fontSize: '2em', position: 'absolute', top: '0', left: '35%', fontWeight: 'bolder'}}>!</span> : null}
                      <p
                        style={{
                          position: "absolute",
                          top: "40px",
                          width: "100px",
                          textAlign: "center",
                          left: "-30px",
                          fontWeight: "500",
                          fontSize: ".9rem",
                        }}
                      >
                        Datos del paciente
                      </p>
                    </li>
                    <li
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        background: step > 2 && second_completed ? "#2ecc71" : step > 2 && !second_completed ? "#f1c40f" : "#cecece",
                        position: "absolute",
                        top: "-15px",
                        zIndex: 10,
                        left: "50%",
                      }}
                    >
                    {step > 2 && second_completed ? <Check style={{color: '#fff', position: 'absolute', top: '5px', left: '15%', strokeWidth: '3px'}} /> : step > 2 && !second_completed ? <span style={{color: '#fff', fontSize: '2em', position: 'absolute', top: '0', left: '35%', fontWeight: 'bolder'}}>!</span> : null}
                      <p
                        style={{
                          position: "absolute",
                          top: "40px",
                          width: "100px",
                          textAlign: "center",
                          left: "-30px",
                          fontWeight: "500",
                          fontSize: ".9rem",
                        }}
                      >
                        Antes del tratamiento
                      </p>
                    </li>
                    <li
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        background: step > 3 && third_completed ? "#2ecc71" : step > 3 && !third_completed ? "#f1c40f" : "#cecece",
                        position: "absolute",
                        top: "-15px",
                        zIndex: 10,
                        left: "100%",
                      }}
                    >
                    {step > 3 && third_completed ? <Check style={{color: '#fff', position: 'absolute', top: '5px', left: '15%', strokeWidth: '3px'}} /> : step > 2 && !third_completed ? <span style={{color: '#fff', fontSize: '2em', position: 'absolute', top: '0', left: '35%', fontWeight: 'bolder'}}>!</span> : null}
                      <p
                        style={{
                          position: "absolute",
                          top: "40px",
                          width: "100px",
                          textAlign: "center",
                          left: "-30px",
                          fontWeight: "500",
                          fontSize: ".9rem",
                        }}
                      >
                        Despues del tratamiento
                      </p>
                    </li>
                  </ul>
                  <ul
                    // className="w-100"
                    style={{
                      background: "#2ecc71",
                      height: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      listStyleType: "none",
                      position: "absolute",
                      top: "0",
                      zIndex: 9,
                    }}
                  ></ul>
                </div>
    )
}
