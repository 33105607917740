import React from 'react'
import styles from '../styles/stepTwo.module.css'

const StepFour = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className={styles.mainContainer}>
        <div className={styles.textContainer}>
          <p className={styles.title}>Tercer paso:</p>
          <p className={styles.text}>
            Consulte el material de ayuda de la plataforma para poder conocerla.
          </p>
          <p className={styles.text}>
            En el sidebar en el ítem "Como funciona la plataforma" encontrará
            todo el material para poder navegar por el portal de KeepSmiling.
          </p>
          <p className={styles.text}>
            Igualmente ante cualquier duda, nuestro equipo siempre estará
            disponible para ayudar.
          </p>
        </div>
        <div className={styles.videoSquare}>1</div>
      </div>
      <div className={styles.footerStep3}>
        <div className={styles.nextButton} onClick={() => setStep(5)}>
          Siguiente
        </div>
      </div>
    </div>
  )
}

export default StepFour
