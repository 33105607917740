import React from 'react'
import legalIcon from '../../../assets/images/legal.png'
import styles from '../styles/legalCards.module.css'

const LegalCards = ({ title }) => {
  return (
    <div className={styles.card}>
      <div>
        <img src={legalIcon} alt="legal" className={styles.icon} />
      </div>
      <div className="d-flex flex-column justify-content-between ms-3">
        <div>
          <p className="text-green">Firmado</p>
          <p className="fw-bold fs-5">{title}</p>
        </div>
        <div className={styles.button}>Revisar y firmar</div>
      </div>
    </div>
  )
}

export default LegalCards
