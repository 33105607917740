import React, { useState, useEffect } from "react";
import apiClient from "../../utils/backend";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { SidebarLogo } from "./components/SidebarLogo";
import { NewPassword } from "./NewPassword";
import { Login } from "./Login";

import "react-toastify/dist/ReactToastify.css";
import { PasswordUpdate } from "./PasswordUpdate";

const NewLogin = () => {
  const [formValues, setFormValues] = useState({
    email: "",
    forgotEmail: "",
    password: "",
    password1: "",
    password2: "",
  });

  const { email, forgotEmail, password, password1, password2 } = formValues;

  const [recover_email, setRecover_email] = useState(null);
  const [token, setToken] = useState(null);
  const [view, setView] = useState("login");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ error: false, message: "" });
  const [password_match, setPassword_match] = useState(null);
  const [password_format_error, setPassword_format_error] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const path = window.location.pathname.split("/");

    if (path.indexOf("changePassword") !== -1 && path.length > 2) {
      let data = { token: path[2] };
      apiClient
        .get("/api/helper/checkPasswordToken", { data })
        .then((res) => {
          if (res.status === 502) {
            window.location.href = "/login";
          } else {
            setLoading(false);
            setRecover_email(res.data);
            setToken(token);
          }
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "/login";
        });
    } else {
      setLoading(false);
    }
  }, []);

  const handleInputChange = (e) => {
    setFormValues({ [e.target.name]: e.target.value });
  };

  const handleRecoverPassword = (e) => {
    e.preventDefault();
    apiClient
      .post(`/api/forgotPassword/?email=${e.target.elements[0].value}`)
      .then((res) => {
        console.log(res);
        toast.success(
          "Le enviamos un email a su casilla de correo para reestablecer la contraseña. 👍",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("🤔 Hubo un error, por favor, vuelva a intentar.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlePasswordValidation = () => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm;

    if (password1 !== password2) {
      setPassword_match(false);
    } else {
      setPassword_match(true);
    }

    if (!regex.test(password1) || !regex.test(password2)) {
      setPassword_format_error(true);
    } else {
      setPassword_format_error(false);
    }
  };

  const handleSetPassword = () => {
    if (password1 === password2) {
      apiClient
        .post(
          `/api/changePassword?password=${password1}&email=${recover_email}&token=${token}`
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const path = window.location.pathname.split("/");
  const parsed = qs.parse(window.location.search);

  return (
    <>
      {loading ? (
        <SidebarLogo />
      ) : path.indexOf("changePassword") !== -1 ? (
        path.length > 2 ? (
          <NewPassword
            error={error}
            handlePasswordValidation={handlePasswordValidation}
            handleInputChange={handleInputChange}
            password_match={password_match}
            password_format_error={password_format_error}
            email={email}
            password={password}
            password1={password1}
            password2={password2}
            handleSetPassword={handleSetPassword}
          />
        ) : (
          (window.location.href = "/login")
        )
      ) : path.indexOf("passwordUpdate") !== -1 ? (
        <PasswordUpdate />
      ): (
        /* LOGIN - RECUPERAR CONTRASEÑA */
        <Login
          dispatch={dispatch}
          email={email}
          password={password}
          error={error}
          setError={setError}
          setView={setView}
          handleInputChange={handleInputChange}
          forgotEmail={forgotEmail}
          handleRecoverPassword={handleRecoverPassword}
          view={view}
        />
      )}
    </>
  );
};

export default NewLogin;
