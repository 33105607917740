import React from 'react'
import PropTypes from 'prop-types'
import SidebarDropdownItem from './atoms/SidebarDropdownItem'
import SidebarItem from './atoms/SidebarItem'

const OpenSidebarItems = ({ item }) => {
  if (item.subItems) return <SidebarDropdownItem item={item} />
  return <SidebarItem item={item} />
}

export default OpenSidebarItems
OpenSidebarItems.propTypes = {
  item: PropTypes.object.isRequired,
}
