import React from 'react'
import { ChevronDown } from 'react-feather'
import referralAll from '../styles/referralAll.module.css'
import PropTypes from 'prop-types'

export const ReferralsModalDentists = ({
  query,
  setQuery,
  handleShowList,
  showList,
  handleSelectedDentist,
  dentists,
  selectedDentist,
  showSearchError,
}) => {
  return (
    <>
      <div className="d-flex align-items-center gap-3">
        <span>Buscar Ortodoncista:</span>
        <div>
          <div className="d-flex align-items-center">
            <div className={referralAll.searchbar}>
              <input
                className="form-control my-0"
                placeholder=" 🔍 Buscar..."
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
            </div>

            <ChevronDown
              className="col-2 mt-2 cursor-pointer"
              onClick={() => handleShowList()}
            />
          </div>
          {showList && (
            <ul className={`${referralAll.ulScroll} shadow mt-2 p-absolute`}>
              {dentists?.map((dentist) => (
                <li
                  style={{
                    backgroundColor:
                      dentist.id === selectedDentist?.id
                        ? 'rgb(220, 226, 255)'
                        : 'white',
                  }}
                  onClick={() => handleSelectedDentist(dentist)}
                  className={`${referralAll.liScroll} ps-2 pt-2`}
                  key={dentist.user.id}
                >
                  {dentist.user.name + ' ' + dentist.user.surname}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {showSearchError && (
        <div style={{ color: 'red' }} className="mt-3">
          No se encontraron resultados de su busqueda
        </div>
      )}
    </>
  )
}
ReferralsModalDentists.propTypes = {
  query: PropTypes.string.isRequired,
  dentists: PropTypes.array.isRequired,
  selectedDentist: PropTypes.object,
  showList: PropTypes.bool.isRequired,
  setQuery: PropTypes.func.isRequired,
  handleShowList: PropTypes.func.isRequired,
  handleSelectedDentist: PropTypes.func.isRequired,
  showSearchError: PropTypes.bool.isRequired,
}
