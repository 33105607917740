import React, { useEffect, useState } from 'react'
import { ChevronLeft, LogOut } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import OpenSidebarItems from '../components/OpenSidebarItems'
import { adminSidebar, dentistSidebar } from '../data/data'
import KeepSmilingLogo from '../Icons/KeepSmilingLogo'
import './mobile-sidebar.css'

const MobileSidebar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [sidebarState, setSidebarState] = useState([])
  const { collapsed, userType } = useSelector((state) => ({
    collapsed: state.navbarReducer.collapsed,
    userType: state.userReducer.typeId,
  }))

  useEffect(() => {
    if (!userType) return
    if (userType === 1) return setSidebarState([...adminSidebar])
    if (userType === 2) return setSidebarState([...dentistSidebar])
    throw new Error('User type is not identified')
  }, [userType])

  // Closes Sidebar on Route change
  useEffect(() => {
    if (collapsed) return
    dispatch({ type: 'COLLAPSE' })
  }, [location])

  return (
    <div className={`position-relative`}>
      <div className={`mobile-sidebar-container ${collapsed && 'side-close'}`}>
        <div className="mobile-sidebar">
          <div>
            <ChevronLeft
              className={`mobile-close-btn ${collapsed && 'rotate-180'}`}
              onClick={() => dispatch({ type: 'COLLAPSE' })}
            />
            <div className="d-flex justify-content-center KS-Logo">
              <KeepSmilingLogo />
            </div>
            {sidebarState.map((item) => (
              <OpenSidebarItems item={item} />
            ))}
            <div className="mb-5 px-3 py-2 scale">
              <a
                href="/login"
                className="text-white"
                onClick={() => dispatch({ type: 'LOGOUT' })}
              >
                <LogOut />
                <span className="ms-2 py-2">Cerrar sesión</span>
              </a>
            </div>
          </div>
          {!collapsed && userType === 1 && (
            <div
              className="text-center mb-4 text-white d-flex align-items-center justify-content-center gap-1 flex-wrap p-1"
              style={{ opacity: 0.7 }}
            >
              <p className="m-0">Hecho con </p>
              <span className="fs-4"> ♥ </span>
              <p className="m-0">por un gran equipo</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileSidebar
