import React from 'react'
import SmileIndicator from '../../../../../assets/icons/smileIndicator.svg'
import PropTypes from 'prop-types'
import { setIndicator } from '../../utilities/helpers'

const SmileProgressBar = ({ treatmentCant }) => {
  return (
    <>
      <div className={`smileIndicator wd-${setIndicator(treatmentCant)} my-3`}>
        {treatmentCant >= 5 && (
          <img src={SmileIndicator} alt="smileindicator" />
        )}
      </div>
      <div className="smileProgresBar">
        <div className="w-25 gold"></div>
        <div className="w-25 platinum"></div>
        <div className="w-25 black"></div>
        <div className="w-25 elite"></div>
      </div>
      <div className="d-flex mt-2">
        <div className="w-25">
          <p>5</p>
          <p className="fw-bold" style={{ color: '#ddb802' }}>
            Gold
          </p>
        </div>
        <div className="w-25">
          <p>20</p>
          <p className="fw-bold" style={{ color: '#c9d6dd' }}>
            Platinum
          </p>
        </div>
        <div className="w-25">
          <p>35</p>
          <p className="fw-bold">Black</p>
        </div>
        <div className="w-25">
          <p>+50</p>
          <p className="fw-bold" style={{ color: '#001d57' }}>
            Elite
          </p>
        </div>
      </div>
    </>
  )
}

SmileProgressBar.propTypes = {
  treatmentCant: PropTypes.number.isRequired,
}

export default SmileProgressBar
