import React from 'react'
import TreatmentStateStepBar from '../../../../components/TreatmentStateStepBar'
import '../styles/styles.css'
import { handleRedirect } from '../../../Notificaciones/utilities/helpers'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const NotificationCard2 = ({ data }) => {
  const { treatment } = data
  const history = useHistory()
  return (
    <div className="w-100 notificationCard my-2 p-3 d-flex">
      <div className=" middleLine pe-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap">
            <div className="circleNotification">
              {treatment.frontal_sonriendo !== null && (
                <img src={treatment.frontal_sonriendo} alt="avatar" />
              )}
            </div>
            <div className="d-flex flex-column ms-2">
              <p>{`${treatment.patient.name} ${treatment.patient.surname}`}</p>
              <p className="fw-bold">Su paciente fue dado de alta</p>
            </div>
          </div>
          <div>
            <p className="text-muted notificationTime">
              {`${moment(data.created_at).startOf('minutes').fromNow()}`}
            </p>
          </div>
        </div>
        <div className="d-flex">
          <div className="circleDisabled"></div>
          <div>
            <p className="ms-2 mt-2 descriptionCard">
              La solicitud de su paciente fue de dada de alta con documentación
              incompleta. Recuerde que para recibir la propuesta de tratamiento,
              la documentación debe estar completa. Puede completarla desde la
              Historia Clinica de su paciente.
            </p>
            <button
              className="medicalRecordBTN ms-2 py-1 px-4 mt-2"
              onClick={() => handleRedirect(treatment.id, history)}
            >
              Historia Clinica
            </button>
          </div>
        </div>
      </div>
      <div className="treatment-state-notifiaction-dentist">
        <TreatmentStateStepBar type={2} />
      </div>
    </div>
  )
}

export default NotificationCard2
