import React from 'react'
import { HelpCircle } from 'react-feather'

const AestheticPriority = ({ formData }) => {
  return (
    <div className="w-100 d-flex ">
      <div className="step-title mx-3 d-flex align-items-end flex-column">
        <p className="fs-4 fw-bold text-end p-2 m-0 pe-1">
          Prioridad estética del paciente
        </p>
        <div className="title-sub-bar mb-2"></div>
        <HelpCircle />
      </div>
      <div className="step-text-area">
        {formData?.general_description}
      </div>
    </div>
  )
}

export default AestheticPriority
