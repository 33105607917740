import React from 'react'
import moment from 'moment'
import AprobarModal from './Modals/AprobarModal'
import RechazarModal from './Modals/RechazarModal'
import ViewerConfirmationModal from './Modals/ViewerConfirmationModal'
import ExpandViewer from '../../../../../../components/ExpandViewer'
import { useSelector } from 'react-redux'

const DocumentationTreatmentProposal = ({
  proposalData,
  userName,
  loadData,
}) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const displayTreatmentStatus = (status) => {
    if (status === 'approved')
      return (
        <p className="fw-bold CH-stage-tag CH-aprobado my-0 px-2 py-1">
          APROBADO
        </p>
      )
    if (status === 'pending')
      return (
        <p className="fw-bold CH-stage-tag CH-pendiente my-0 px-2 py-1">
          PENDIENTE
        </p>
      )
    if (status === 'rejected')
      return (
        <p className="fw-bold CH-stage-tag CH-rechazado my-0 px-2 py-1">
          RECHAZADO
        </p>
      )
  }

  return (
    <>
      {proposalData.map((item, i) => (
        <div className="mx-4 my-5 ">
          <div
            className={`${
              pageWidth === 'mobile' &&
              'form-error-card pb-4 pt-2 px-3 cursor-pointer'
            }`}
          >
            <a href={pageWidth === 'mobile' && item.url}>
              <div className="d-flex my-3 align-items-center">
                <p className="my-0 fw-bold me-4">{i + 1}° propuesta</p>
                {displayTreatmentStatus(item.status)}
              </div>
              <p className="my-2">
                Fecha de envio: {moment(item.created_at).format('DD-MM-YYYY')}
              </p>
            </a>
          </div>
          {item.status === 'pending' && (
            <div className="d-flex justify-content-center flex-wrap">
              <AprobarModal
                item={item}
                userName={userName}
                loadData={loadData}
              />

              <RechazarModal item={item} loadData={loadData} />
            </div>
          )}
          {pageWidth === 'desktop' && (
            <div className="mt-4 propsal-visor-container">
              <ExpandViewer url={item.url} isOd={true} />
              <ViewerConfirmationModal url={item.url} />
              <iframe
                loading={i > 2 ? 'lazy' : 'auto'}
                title="algo"
                src={item.url}
              />
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default DocumentationTreatmentProposal
