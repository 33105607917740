const gendersData = [
  { value: 'm', label: 'Masculino' },
  { value: 'f', label: 'Femenino' },
  {
    value: 'o',
    label: 'Otro',
  },
]

const fiscalConditionData = [
  { value: 1, label: 'Monotributista' },
  { value: 2, label: 'Responsable inscripto' },
  {
    value: 3,
    label: 'Excento',
  },
  { value: 4, label: 'No Alcanzado' },
]
const fiscalConditionDataCol = [
  { value: 5, label: 'Personal Natural' },
  { value: 6, label: 'Persona Jurídica' },
]
const fiscalConditionDataMex = [
  { value: 7, label: 'General de Ley de personas Morales' },
  { value: 8, label: 'Personas Morales con fines no Lucrativos' },
  {
    value: 9,
    label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  },
  { value: 10, label: 'Arrendamiento' },
  { value: 11, label: 'Demás Ingresos' },
  { value: 12, label: 'Consilidación' },
  {
    value: 13,
    label:
      'Residentes en el Extranjero sin Establecimiento Permanente en México',
  },
  { value: 14, label: 'Ingresos por Dividendos (Socios y accionistas)' },
  {
    value: 15,
    label: 'Personas Físicas con Actividades Empresariales y Profesionales',
  },
  { value: 16, label: 'Ingresos por intereses' },
  { value: 17, label: 'Sin obligaciones Fiscales' },
  {
    value: 18,
    label:
      'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
  },
  { value: 19, label: 'Incorporación Fiscal' },
  {
    value: 20,
    label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  },
  { value: 21, label: 'Opcional para Grupos de Sociedades' },
  { value: 22, label: 'Coordinados' },
  { value: 23, label: 'Hidrocarburos' },
  { value: 24, label: 'Régimen de Enajenación o Adquisición de Bienes' },
  {
    value: 25,
    label:
      'De los Régimenes Fiscales Preferentes y de las Empresas Multinacionales',
  },
  { value: 26, label: 'Enajenación de acciones en bolsa de valores' },
  { value: 27, label: 'Régimen de los ingresos por obtención de premios' },
  {
    value: 28,
    label:
      'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  },
  { value: 29, label: 'Régimen Simplificado de Confianza' },
]
const documentoDeIdentidadCol = [
  { value: 'CC', label: 'CC' },
  { value: 'NIT', label: 'NIT' },
  { value: 'CE', label: 'CE' },
]

const toastPropsData = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
}

const selectStyleData = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    borderBottom: '2px solid #0027a8!important',
  }),
}

export {
  gendersData,
  fiscalConditionData,
  toastPropsData,
  selectStyleData,
  fiscalConditionDataMex,
  fiscalConditionDataCol,
  documentoDeIdentidadCol,
}
