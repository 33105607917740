import React from 'react'
import styles from '../styles/styles.module.css'
const DentistFiltersDesktop = ({ handleFilter, filter, state, countryId }) => {
  return (
    <div className="d-flex ">
      <p
        className={`${styles.filterBTN} ${filter === 1 ? 'fw-bold' : ''}`}
        onClick={() => handleFilter(1)}
      >
        Todos
        <span className="text-muted">({state.meta.total_patients})</span>
      </p>
      {countryId !== 7 && (
        <p
          className={`${styles.filterBTN} ${filter === 2 ? 'fw-bold' : ''}`}
          onClick={() => handleFilter(2)}
        >
          SAO <span className="text-muted">({state.meta.total_sao})</span>
        </p>
      )}
      <p
        className={`${styles.filterBTN} ${filter === 3 ? 'fw-bold' : ''}`}
        onClick={() => handleFilter(3)}
      >
        Acciones Requeridas{' '}
        <span className="text-muted">({state.meta.total_pending_actions})</span>
      </p>
    </div>
  )
}

export default DentistFiltersDesktop
