import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import apiClient from '../../../utils/backend'
import { setDropdown } from '../../../redux/actions/notificationDropdown'
import handleToast from '../../toaster'
import moment from 'moment'

const useMedicalRecordModal = (id, opened) => {
  const [data, setData] = useState()
  const [documentationData, setDocumentationData] = useState([])
  const [originalRegister, setOriginalRegister] = useState()
  const [loading, setloading] = useState(true)
  const [currentModalId, setCurrentModalId] = useState(id)
  const [dataImage, setDataImage] = useState(null)
  const [externalValue, setExternalValue] = useState('')
  const [editRegistrationDate, setEditRegistrationDate] = useState(false)
  const dispatch = useDispatch()

  const displayDoctorName = () => {
    const docName = data.dentists.map((dentist) => {
      if (dentist.id === data.principal_dentist.dentist_id)
        return <div>{`${dentist.user.name} ${dentist.user.surname}`}</div>
      return <div> - </div>
    })
    return docName
  }

  const updateData = async () => {
    try {
      const res = await apiClient.get(
        `api/medicalRecord/${currentModalId}?include=dentists`
      )
      setData(res.data.data)
      setDataImage(res.data.data?.frontal_sonriendo)
    } catch (error) {
      console.log(error)
    }
  }

  const handleApproveTreatment = async () => {
    if (!externalValue) return
    handleToast('updating', 'Actualizando ID...')
    try {
      await apiClient.put(`/api/medicalRecord/${data.id}`, {
        external_id: externalValue,
      })
      handleToast('success', 'El ID se actualizo correctamente')
      // setTimeout(() => window.location.reload(), 1500)
    } catch (error) {
      Object.keys(error.response.data.errors).includes('external_id') &&
        handleToast('error', 'El valor del campo external id ya está en uso')

      //error.response.data.errors.find((error) => console.log(error))
    }
  }

  const handleChangeRegistro = async (registroString) => {
    let intraoralNumber = 0
    if (registroString === 'Intraoral') intraoralNumber = 1
    try {
      await apiClient.put(`/api/medicalRecord/${data.id}`, {
        intraoral: intraoralNumber,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeMaxilar = async (maxillaString) => {
    let maxillaNumber = 3
    if (maxillaString === 'Superior') maxillaNumber = 2
    if (maxillaString === 'Inferior') maxillaNumber = 1
    console.log(maxillaNumber)
    try {
      await apiClient.put(`/api/medicalRecord/${data.id}`, {
        maxilla: maxillaNumber,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetRegistrarionDate = async (date) => {
    const today = new Date().toISOString().split('T')[0]
    const isAfter = moment(date).isAfter(today)
    if (isAfter) return

    try {
      const res = await apiClient.patch(`/api/treatments/${data.id}`, {
        registration_datetime: date,
      })
      setData({ ...data, registration_date: date })
    } catch (error) {
      handleToast(
        'error',
        'La fecha de ingreso no debe ser superior a la de hoy'
      )
      console.log(error)
    }
  }

  const loadDataAdmin = async (
    info = true,
    adm = true,
    id = currentModalId
  ) => {
    if (info) {
      try {
        const res = await apiClient.get(
          `api/medicalRecord/${id}?include=dentists`
        )
        setData(res.data.data)
        setDataImage(res.data.data?.frontal_sonriendo)
        setExternalValue(res.data.data.external_id || '')
        setEditRegistrationDate(res.data.data.registration_datetime)
        //Load original register data
        try {
          const resTwo = await apiClient(`api/viewer/${res.data.data.id}`)
          setOriginalRegister(resTwo.data.data)
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
    }
    // load documentation inital data
    if (adm) {
      try {
        const res = await apiClient.get(
          `api/medicalRecordDocumentation/${id}?include=dentists,form`
        )
        setDocumentationData(res.data.data)
      } catch (error) {
        console.log(error)
      }
      setloading(false)
    }
  }

  useEffect(() => {
    if (!currentModalId || !opened) return
    loadDataAdmin()
    return () => {
      // setDocumentationData([])
      setData([])
      setOriginalRegister(null)
      setloading(true)
    }
  }, [opened, currentModalId])

  useEffect(() => {
    if (opened) return setCurrentModalId(id)
    setDocumentationData([])
    setData([])
    setOriginalRegister(null)
    setloading(true)
  }, [id, opened])

  useEffect(() => {
    if (!opened) return

    dispatch(setDropdown(false))
  }, [dispatch, opened])

  return {
    loading,
    dataImage,
    data,
    externalValue,
    documentationData,
    currentModalId,
    originalRegister,
    editRegistrationDate,
    loadDataAdmin,
    setCurrentModalId,
    setEditRegistrationDate,
    displayDoctorName,
    handleSetRegistrarionDate,
    handleChangeRegistro,
    handleChangeMaxilar,
    setExternalValue,
    handleApproveTreatment,
    setData,
    setDocumentationData,
    updateData,
  }
}

export default useMedicalRecordModal
