import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import apiClient from '../../../../utils/backend'

const useOdHome = ({ isMobile }) => {
  const [filter, setFilter] = useState(1)
  const [category, setCategory] = useState({
    image: '',
    loading: true,
  })
  const [state, setState] = useState({
    data: [],
    paginator: [],
    totalPatients: 0,
    totalPendings: 0,
    loading: true,
  })
  const history = useHistory()
  let num = isMobile ? 6 : 20
  const { name, surname, dentistId, gender } = useSelector(
    (state) => state.userReducer
  )

  const handleGender = (gender) => {
    const genders = {
      m: 'Dr',
      f: 'Dra',
      o: 'Dr',
    }
    return genders[gender]
  }

  const getPaginatorData = async (page = 1) => {
    let path = getUrl(filter, page)
    if (state.searchQuery) {
      path = path + `&filter[fullname]=${state.searchQuery}`
    }
    try {
      const res = await apiClient.get(path)
      setState({
        data: res.data.table.body,
        paginator: res.data.paginator,
        loading: false,
        totalPatients: res.data.meta.total_patients,
        totalPendings: res.data.meta.total_pending_actions,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleFilter = async (type) => {
    try {
      const res = await apiClient.get(getUrl(type, 1))
      setState({
        clearSearchBarInput: true,
        data: res.data.table.body,
        paginator: res.data.paginator,
        loading: false,
        totalPatients: res.data.meta.total_patients,
        totalPendings: res.data.meta.total_pending_actions,
      })
      setFilter(type)
    } catch (error) {
      console.log(error)
    }
  }

  const getUrl = (type = 1, page = 1) =>
    `api/dentists/${dentistId}/patients${
      type === 2 ? '/actions' : ''
    }?cant_pages=${num}&page=${page}`

  const getInitialData = async () => {
    try {
      const res = await apiClient.get(getUrl(filter, 1))
      setState({
        loading: false,
        data: res.data.table.body,
        paginator: res.data.paginator,
        totalPatients: res.data.meta.total_patients,
        totalPendings: res.data.meta.total_pending_actions,
        clearSearchBarInput: false,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getCategory = async () => {
    try {
      const res = await apiClient('api/smileCreator')
      setCategory({ image: res.data.data.category, loading: false })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInitialData()
    getCategory()
  }, [])

  useEffect(() => {
    if (!state.loadTodosFilter) return
    getInitialData()
  }, [state])

  return {
    state,
    filter,
    history,
    name,
    surname,
    gender,
    category,
    setState,
    handleFilter,
    handleGender,
    getPaginatorData,
    getUrl,
  }
}

export default useOdHome
