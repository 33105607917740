import React, { useState, useEffect } from 'react'
import ClinicHistoryModal from '../../../components/MedicalRecordModal/ClinicHistoryModal'
import ClaimModal from '../modals/ClaimModal'
import styles from '../styles/styles.module.css'
import buttons from '../styles/buttons.module.css'
import PropTypes from 'prop-types'
import moment from 'moment'
import useClaimCard from '../hooks/useClaimCard'
import { handleCanBeClaimed } from '../utilitis/handleCanBeClaimed'
const ClaimsCard = ({
  data,
  setClaimState,
  claimState,
  currentClaim,
  updateAfterClaimAll,
  setisAllSelected,
}) => {
  const [canBeClaimed, setCanBeClaimed] = useState(false)

  const [date, setDate] = useState(() => {
    let now = moment().format('YYYY-MM-DD')
    if (!data.last_claims) return '-'
    if (moment(now).isSame(moment(data.last_claims).format('YYYY-MM-DD')))
      return moment(data.last_claims).fromNow()
    return moment(data.last_claims).format('YYYY-MM-DD')
  })

  const {
    handleModal,
    handleSetRequiredData,
    handleCantClaimByAction,
    setState,
    state,
  } = useClaimCard(data)

  const updateCardDate = () => {
    if (data.last_claims) return
    setDate('Hace unos segundos')
  }

  const setClinicHistoryType = (type) => {
    const types = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
      'Sin tipo': 1,
    }
    return types[type]
  }

  const selectClaim = (thisClaim) => {
    if (thisClaim.selected) {
      setisAllSelected(false)
      setClaimState({
        ...claimState,
        claims: claimState.claims.map((claim) => {
          if (claim.id === thisClaim.id) {
            return { ...claim, selected: false }
          }
          return claim
        }),
      })
    } else {
      setClaimState({
        ...claimState,
        claims: claimState.claims.map((claim) => {
          if (claim.id === thisClaim.id) {
            return { ...claim, selected: true }
          }
          return claim
        }),
      })
    }
  }

  useEffect(() => {
    // if (!updateClaimDate) return
    setDate(() => {
      let now = moment().format('YYYY-MM-DD')
      if (!data.last_claims) return '-'
      if (moment(now).isSame(moment(data.last_claims).format('YYYY-MM-DD')))
        return moment(data.last_claims).fromNow()
      return moment(data.last_claims).format('YYYY-MM-DD')
    })

    let isCanBeClaimed = handleCanBeClaimed(
      data.proposals_pending,
      data.pending_proposals_count,
      data.flags,
      data.incomplete_documentation_count
    )
    setCanBeClaimed(isCanBeClaimed)
  })

  return (
    <div className={styles.claimCard}>
      <div
        className={`${
          buttons[`claimHistoryType-${setClinicHistoryType(data.treatment)}`]
        }`}
        onClick={() => setState({ ...state, modal_show: !state.modal_show })}
      >
        <ClinicHistoryModal
          id={data.id}
          opened={state.modal_show}
          handleModal={handleModal}
          isProposalControl={false}
        />
      </div>
      <div className={styles.allFilter}>
        {canBeClaimed && (
          <input
            type="checkbox"
            name="claim"
            checked={data.selected}
            onClick={() => selectClaim(currentClaim)}
          />
        )}
        {!canBeClaimed && <input type="checkbox" name="claim" disabled />}
      </div>
      <p className={styles.id}>{data.id}</p>
      <p
        className={`${styles.patients} text-truncate`}
        title={`${data.patient.name} ${data.patient.surname}`}
      >{`${data.patient.name} ${data.patient.surname}`}</p>
      <p
        className={`${styles.doctor} text-truncate`}
        title={`${data.dentists[0].user.name} ${data.dentists[0].user.surname}`}
      >
        {`${data.dentists[0].user.name} ${data.dentists[0].user.surname}`}
      </p>
      <p className={styles.treatment}>{data.treatment}</p>
      <p className={styles.stage}>{data.stage}</p>
      <p className={styles.incomeDate}>
        {moment(data.registration_datetime).format('DD/MM/YYYY')}
      </p>
      <p
        className={`${styles.requiredAction} fw-bold`}
        style={{ color: '#E42129' }}
      >
        {handleSetRequiredData(data.proposals_pending, data.flags)}
      </p>
      <p className={styles.lastClaim}>{date}</p>
      <p className={styles.claimNro}>
        {handleCantClaimByAction(
          data.proposals_pending,
          data.pending_proposals_count,
          data.flags,
          data.incomplete_documentation_count
        )}
      </p>

      <ClaimModal
        updateCardDate={updateCardDate}
        dentists={data.dentists}
        principalDentistId={data.principal_dentist}
        patient={`${data.patient.name} ${data.patient.surname}`}
        type={handleSetRequiredData(data.proposals_pending, data.flags)}
        treatId={data?.id}
        flags={data?.flags}
        date={moment(data.registration_datetime).format('DD/MM/YYYY')}
        setClaimState={setClaimState}
        claimState={claimState}
        updateAfterClaimAll={updateAfterClaimAll}
        canClaimed={canBeClaimed}
      />
    </div>
  )
}
export default ClaimsCard
ClaimsCard.propTypes = {
  data: PropTypes.object.isRequired,
  claimsList: PropTypes.array.isRequired,
  setClaimsList: PropTypes.func.isRequired,
}
