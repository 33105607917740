import React, { useEffect, useState } from 'react'
import Searchbar from '../../components/searchbar'
import apiClient from '../../utils/backend'
import NewUserModal from './components/new_user_modal'
import UserList from './components/user_list_item'
import { useDispatch, useSelector } from 'react-redux'
import {
  getProfilesPositions,
  startGetUserList,
} from '../../redux/actions/users'
import Loader from '../../components/loader'
import NotFoundComponent from '../../components/NotFound.js/NotFoundComponent'

const UserPage = () => {
  const [state, setState] = useState({
    loading: true,
  })
  const dispatch = useDispatch()
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)

  const countryArray = [
    { country: 'Argentina', countryId: 1 },
    { country: 'Uruguay', countryId: 2 },
    { country: 'Paraguay', countryId: 3 },
    { country: 'Colombia', countryId: 4 },
    { country: 'Chile', countryId: 5 },
    { country: 'Perú', countryId: 6 },
    { country: 'México', countryId: 7 },
  ]

  const apiCall = async () => {
    try {
      const res = await apiClient.get(
        '/api/admins?include=country,dentist,patient,profile,position'
      )
      setState({
        cards_type: 'list',
        step: countryId === 1 ? 0 : countryId,
        loading: false,
        usersList: res.data.table.body,
        clearSearchBarInput: null,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getData = async (step) => {
    try {
      const res = await apiClient.get(
        '/api/admins?include=country,dentist,patient,profile,position'
      )
      setState({
        ...state,
        cards_type: 'list',
        step: step,
        loading: false,
        usersList: res.data.table.body,
        clearSearchBarInput: true,
        searchQuery: null,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const displayList = () => {
    if (state.searchQuery) {
      if (!state.usersList.table.body.length) return <NotFoundComponent />
      return state.usersList.table.body.map((user) => (
        <UserList
          id={user.id}
          avatar={user.avatar}
          email={user.email}
          name={user.name}
          surname={user.surname}
          sector={user.profile}
          position={user.position}
          country={user.country_id}
          key={user.id}
        />
      ))
    }

    if (state.step) {
      let filterCountry = state.usersList.filter(
        (item) => state.step === item.country_id
      )
      if (!filterCountry.length) return <NotFoundComponent />
      return filterCountry.map((user) => (
        <UserList
          id={user.id}
          name={user.name}
          email={user.email}
          surname={user.surname}
          sector={user.profile}
          position={user.position}
          country={user.country_id}
          key={user.id}
        />
      ))
    }

    return state.usersList.map((user) => (
      <UserList
        id={user.id}
        name={user.name}
        email={user.email}
        surname={user.surname}
        sector={user.profile}
        position={user.position}
        country={user.country_id}
        key={user.id}
      />
    ))
  }

  useEffect(() => {
    apiCall()
  }, [])

  useEffect(() => {
    if (!state.loadTodosFilter) return
    apiCall()
  }, [state])

  useEffect(() => {
    dispatch(startGetUserList())
    dispatch(getProfilesPositions())
  }, [dispatch])

  if (state.loading) return <Loader />

  return (
    <div className="container-fluid px-5 position-relative">
      <div className="row pt-4" style={{ zIndex: 2 }}>
        <div className="col-9">
          <h1 className="text-primary fw-bold">Usuarios</h1>
        </div>
        <div className="col-3 d-flex flex-column px-4">
          <NewUserModal countryId={countryId} />
          <Searchbar
            state={state}
            setState={setState}
            page={'users'}
            url={
              '/api/admins?include=country,dentist,patient,profile,position&'
            }
          />
        </div>
      </div>
      <div className="row mt-3 align-items-between mb-3">
        {countryId === 1 && (
          <div className="col-8 d-flex flex-row ">
            <ul
              className="d-flex flex-row flex-wrap justify-content-start align-items-center p-0 mb-0"
              style={{ listStyleType: 'none' }}
            >
              <li>
                <div
                  className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                    state.step === 0 && 'disabled'
                  }`}
                  onClick={() => {
                    if (state.searchQuery) getData(0)
                    else
                      setState({
                        ...state,
                        step: 0,
                        clearSearchBarInput: true,
                        searchQuery: null,
                      })
                  }}
                >
                  Todos
                </div>
              </li>
              {countryArray.map((item, index) => {
                return (
                  <li>
                    <div
                      key={index}
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === item.countryId && 'disabled'
                      }`}
                      onClick={() => {
                        if (state.searchQuery) getData(item.countryId)
                        else
                          setState({
                            ...state,
                            step: item.countryId,
                            clearSearchBarInput: true,
                            searchQuery: null,
                          })
                      }}
                    >
                      {item.country}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {/*  <div className="col-3 ms-5 px-3 d-flex flex-row justify-content-end">
          <button
            className="btn bg-none p-1"
            onClick={() => setState({ ...state, cards_type: 'cards', step: 0 })}
          >
            <Layout size={24} color="#00155C" />
          </button>
          <button
            className="btn bg-none p-1"
            onClick={() => setState({ ...state, cards_type: 'list', step: 0 })}
          >
            <Menu size={24} color="#00155C" />
          </button>
        </div> */}
        <hr className="mt-2" />
      </div>
      {/* 
      {state.cards_type === 'cards' && (
        <div className="row mt-3 px-4">
          {state.step > 0
            ? state?.usersList
                .filter((item) => state.step === item.country_id)
                .map((user) => {
                  return (
                    <UserCard
                      id={user.id}
                      name={user.name}
                      surname={user.surname}
                      sector={user.profile.title}
                      position={user.position.title}
                      key={user.id}
                    />
                  )
                })
            : state.usersList.map((user, index) => {
                return (
                  <UserCard
                    id={user.id}
                    name={user.name}
                    surname={user.surname}
                    sector={user.profile.title}
                    position={user.position.title}
                    key={index}
                  />
                )
              })}
        </div>
      )} */}

      {state.cards_type === 'list' && (
        <div className="row mt-3 px-4">
          <div className="col-3 px-3">
            <p className="text-primary text-center fw-bold m-0 mt-2">Usuario</p>
          </div>
          <div className="col-3 px-3">
            <p className="text-primary text-center fw-bold m-0 mt-2">Sector</p>
          </div>
          <div className="col-2 px-3">
            <p className="text-primary text-center fw-bold m-0 mt-2">Puesto</p>
          </div>
          <div className="col-2 px-3 text-center">
            <p className="text-primary text-center fw-bold m-0 mt-2">Pais</p>
          </div>
          {displayList()}
        </div>
      )}
    </div>
  )
}

export default UserPage
