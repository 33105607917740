import React from 'react';
import {LightgalleryItem} from "react-lightgallery";

const PhotoItem = React.forwardRef(({image, group}, ref) => (
    <div style={{width: '100%', height: '100%'}}>
        <LightgalleryItem group={group} src={image}>
            <img ref={ref} style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={image} alt={group}/>
        </LightgalleryItem>
    </div>
));

export default PhotoItem;
