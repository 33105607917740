import React from 'react'
import apiClient from '../utils/backend'
import { connect } from 'react-redux'
import { PieChart, Smile } from 'react-feather'

import logo from '../assets/images/KSLOGO.png'
import smile from '../assets/images/ks-smile.svg'
import { withRouter } from 'react-router-dom'
import './styles.css'
import Home from '../assets/icons/Home.svg'
import Inquires from '../assets/icons/Inquires.svg'
import Pacientes from '../assets/icons/Patients.svg'
import Application from '../assets/icons/Application.svg'
import Payment from '../assets/icons/Payment.svg'
import SmileCreator from '../assets/icons/Smile.svg'
import Studies from '../assets/icons/Studies.svg'
import Help from '../assets/icons/Help.svg'
import User from '../assets/icons/User.svg'
import UsuariosAdmin from '../assets/icons/Usuarios.svg'
import Odontologos from '../assets/icons/Odontologo.svg'
import Reclamo from '../assets/icons/reclamo.svg'
import Procesos from '../assets/icons/Procesos.svg'
import Tratamientos from '../assets/icons/Tratamientos.svg'

let countryId = localStorage.getItem('country_id')
class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCollapsed: this.props.collapsed,
      items: [],
      item_selected: {},
      dropdown: false,
      profileData: {},
    }
  }
  componentDidMount() {
    apiClient
      .get('api/helper/getSidebarMenu')
      .then((res) => {
        this.setState({ items: res.data })
      })
      .catch((err) => console.error(err))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.item_selected) {
      let level = this.state.item_selected.level
      let name = this.state.item_selected.name
      let ord = this.state.item_selected.ord
      let route = this.state.item_selected.route
      if (
        (level === 1 && ord >= 100 && name === 'sarasa') ||
        level > 1 ||
        (level === 1 && ord < 100)
      ) {
        console.log(route.indexOf('centrodeayuda'))
        if (route.indexOf('centrodeayuda') > -1 && level > 1) {
          this.props.history.push({
            pathname: route,
            state: {
              route: route.split('/')[2],
              idParent: 1,
              parent: { name: name, body: null },
            },
          })
        } else {
          this.props.history.push({
            pathname: route,
          })
        }
        this.setState({ ...this.state, item_selected: null })
      }
    }
  }

  handleSidebarIcon(name) {
    const Components = {
      Ingresos: Inquires,
      Pagos: Payment,
      Odontologos: Smile,
      Pacientes: Pacientes,
      Procesos: Inquires,
      Reclamos: Inquires,
      Usuarios: User,
      'Mis Pacientes': Pacientes,
      'Nueva Solicitud': Application,
      Consultas: Inquires,
      'Centro de Ayuda': Help,
      Home: Home,
      Tratamientos: Inquires,
      'Smile Creator': SmileCreator,
      'Study Group': Studies,
      Usuarios: UsuariosAdmin,
      Odontólogos: Odontologos,
      Reclamo: Reclamo,
      Procesos: Procesos,
      Tratamientos: Tratamientos,
    }

    if (Components[name]) {
      const Component = Components[name]

      return (
        <img
          src={Components[name]}
          strokeWidth="1px"
          color="#fff"
          style={{ marginBottom: '5px', marginRight: '10px', width: '15px' }}
        />
      )
    } else {
      return (
        <PieChart
          strokeWidth="1.5px"
          size="22"
          style={{ marginBottom: '5px', marginRight: '10px' }}
        />
      )
    }
  }

  sendHome() {
    const typeUser = localStorage.getItem('type_user')
    if (typeUser) {
      window.location.href = '/admin/home'
    }
    if (typeUser == 2) {
      window.location.href = '/od/home'
    }
  }

  render() {
    return (
      <div
        id="sidebar"
        className={
          this.props.collapsed === true
            ? 'collapsed d-flex flex-column justify-content-between'
            : 'd-flex flex-column justify-content-between'
        }
        style={{ overflowX: 'hidden', backgroundColor: '#001D57' }}
      >
        <div className="d-flex flex-column justify-content-between align-items-center h-100">
          <div>
            <div className="w-100 d-flex justify-content-center">
              {this.props.collapsed ? (
                <div style={{ height: '70px' }}></div>
              ) : (
                <img
                  src={logo}
                  alt="KeepSmiling logo"
                  style={{
                    padding: '5px',
                    marginTop: '10px',
                    maxWidth: '120px',
                  }}
                />
              )}
            </div>
            <div className="row sidebar-row">
              {this.state.items.map((item, i) => {
                const itemord = Number(item.ord)
                if (item.status === 1) {
                  if (item.level === 1 && itemord >= 100) {
                    const subitems = this.state.items.filter(
                      (item) =>
                        item.level === 2 &&
                        item.ord > itemord &&
                        item.ord < itemord + 99
                    )
                    return (
                      <div
                        className="col-12 sidebar-item"
                        id={item.id}
                        key={'parent_' + i}
                      >
                        <label
                          className={`btn text-white sidebar-drop-toggle  ${
                            this.state.item_selected === item ? 'toggled' : ''
                          } ${!item.active && 'disabled'}`}
                        >
                          <span
                            onClick={() => {
                              if (!item.active) return
                              if (item.id == 30) return
                              this.state.item_selected === item
                                ? this.setState({
                                    ...this.state,
                                    item_selected: null,
                                  })
                                : this.setState({
                                    ...this.state,
                                    item_selected: item,
                                  })
                            }}
                          >
                            {this.handleSidebarIcon(item.name)}
                            {this.props.collapsed ? null : item.name}
                            {!this.props.collapsed && !item.active && (
                              <div>
                                <small className="my-0 ms-4">
                                  ¡Próximamente!
                                </small>
                              </div>
                            )}
                          </span>
                          <div className="d-flex flex-column sidebar-drop">
                            {!this.props.collapsed &&
                              subitems.map((sub_item, i) => {
                                if (sub_item.id === 34 && countryId != 1)
                                  return null
                                return (
                                  <label
                                    key={'child_' + i}
                                    className={`btn text-white ${
                                      this.props.collapsed ? 'px-4' : ''
                                    }  ${!sub_item.active && 'disabled'}`}
                                    onClick={() => {
                                      if (!sub_item.active) return
                                      if (
                                        sub_item.name ===
                                          'Master de Alineadores' ||
                                        sub_item.name === 'Guía de ayuda'
                                      ) {
                                        window.open(sub_item.route, '_blank')
                                      } else {
                                        this.setState({
                                          ...this.state,
                                          item_selected: sub_item,
                                        })
                                      }
                                    }}
                                  >
                                    {this.props.collapsed
                                      ? null
                                      : sub_item.name}
                                    {(!this.props.collapsed &&
                                      !sub_item.active) ||
                                      (countryId === 5 && (
                                        <div>
                                          <small className="my-0 ms-4">
                                            ¡Próximamente!
                                          </small>
                                        </div>
                                      ))}
                                  </label>
                                )
                              })}
                          </div>
                        </label>
                      </div>
                    )
                  } else if (item.level === 1) {
                    return (
                      <div
                        className="col-12 sidebar-item"
                        id={item.id}
                        key={'parent_' + i}
                      >
                        <label
                          className={`btn text-white ${
                            this.props.collapsed ? 'px-4' : ''
                          }${!item.active && 'disabled'}`}
                          onClick={() => {
                            if (!item.active) return
                            this.setState({
                              ...this.state,
                              item_selected: item,
                            })
                          }}
                        >
                          {this.handleSidebarIcon(item.name)}
                          {this.props.collapsed ? null : item.name}
                          {!this.props.collapsed && !item.active && (
                            <div>
                              <small className="my-0 ms-4">
                                ¡Próximamente!
                              </small>
                            </div>
                          )}
                        </label>
                      </div>
                    )
                  }
                }
              })}
            </div>
          </div>
          {this.props.collapsed && (
            <img
              src={smile}
              alt="KeepSmiling logo"
              style={{
                padding: '5px',
                maxWidth: '60px',
              }}
              onClick={() => this.sendHome()}
              className="kslogo"
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    collapsed: state.navbarReducer.collapsed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarCollapsed: () => dispatch({ type: 'COLLAPSE' }),
    collapse: () => {
      dispatch({ type: 'COLLAPSE' })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))
