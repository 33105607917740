import React from 'react'
import ClinicHistoryModal from '../../../components/MedicalRecordModal/ClinicHistoryModal'
import styles from '../styles/styles.module.css'
import buttons from '../styles/buttons.module.css'
import PropTypes from 'prop-types'
import moment from 'moment'
import usePaymentCard from '..//hooks/usePaymentCard'
import PaymentValidationModal from '../modals/PaymentValidationModal'

const PaymentValidationCard = ({
  data,
  setPaymentState,
  paymentState,
  currentPayment,
  setisAllSelected,
  getAllPayments,
}) => {
  const { handleModal, setState, setClinicHistoryType, selectPayment, state } =
    usePaymentCard(data, setPaymentState, paymentState, setisAllSelected)

  return (
    <div className={styles.paymentCard}>
      <div
        className={`${
          buttons[`paymentHistoryType-${setClinicHistoryType(data.treatment)}`]
        }`}
        onClick={() => setState({ ...state, modal_show: !state.modal_show })}
      >
        <ClinicHistoryModal
          id={data.id}
          opened={state.modal_show}
          handleModal={handleModal}
          isProposalControl={false}
        />
      </div>
      <div className={styles.allFilter}>
        <input
          className="cursor-pointer"
          type="checkbox"
          name="payment"
          checked={data.selected}
          onClick={() => selectPayment(currentPayment)}
        />
      </div>
      <p className={styles.id}>{data.external_id}</p>
      <p
        className={`${styles.patients} text-truncate`}
        title={`${data.patient.name} ${data.patient.surname}`}
      >{`${data.patient.name} ${data.patient.surname}`}</p>
      <p
        className={`${styles.doctor} text-truncate`}
        title={`${data.principal_dentist.user.name} ${data.principal_dentist.user.surname}`}
      >
        {`${data.principal_dentist.user.name} ${data.principal_dentist.user.surname}`}
      </p>
      <p className={styles.treatment}>{data.treatment}</p>
      <p className={styles.stage}>{data.stage}</p>
      <p className={styles.incomeDate}>
        {moment(data.registration_datetime).format('DD/MM/YYYY')}
      </p>

      <PaymentValidationModal
        principalDentist={data.principal_dentist}
        patient={`${data.patient.name} ${data.patient.surname}`}
        treatId={data?.id}
        externalId={data.external_id}
        getAllPayments={getAllPayments}
      />
    </div>
  )
}
export default PaymentValidationCard
PaymentValidationCard.propTypes = {
  data: PropTypes.object.isRequired,
  paymentState: PropTypes.object.isRequired,
  setPaymentState: PropTypes.func.isRequired,
}
