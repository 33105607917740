import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import apiClient from '../../../../../../../utils/backend'

const RechazarModal = ({ item, loadData }) => {
  const [opened, setOpened] = useState(false)
  const [note, setNote] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggle = () => setOpened(true)

  const handleEnviar = async (note) => {
    if (!note) return setError(true)
    setLoading(true)
    await apiClient.post(`/api/proposals/${item.id}/reject`, { note: note })
    setLoading(false)
    setOpened(false)
    loadData(true, false)
  }

  return (
    <>
      <div className="red-proposal-btn shadow mt-3 ms-1" onClick={toggle}>
        Rechazar
      </div>
      <Modal className="AM-container" isOpen={opened}>
        <ModalBody>
          <div className="p-2 d-flex flex-column align-items-center">
            <div className="fw-bold fs-3 my-4">Rechazar Tratamiento</div>
            <div className="fw-bold my-4">¿POR QUÉ NO ESTA CONFORME?</div>
            <div className="RM-first-text">
              A continuación detalle los cambios requeridos de la propuesta de
              tratamiento:
            </div>
            <textarea
              value={note}
              onChange={(e) => {
                setError(false)
                setNote(e.target.value)
              }}
              maxLength={600}
              className="RM-text"
            ></textarea>
            {error && <p className="my-1 red fw-bold">Campo obligatorio</p>}
            <div
              className={`AM-btn PD-green ${loading ? 'disabled' : null}`}
              onClick={() => handleEnviar(note)}
            >
              Enviar
            </div>
            <div className="AM-btn PD-red" onClick={() => setOpened(false)}>
              NO AHORA
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default RechazarModal
