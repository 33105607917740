import React from 'react'
import paymentAll from '../styles/paymentAll.module.css'
import PropTypes from 'prop-types'

const PaymentsAllCard = ({ data, paymentState, setPaymentState }) => {
  const selectPayment = (thisPayment) => {
    if (thisPayment.selected) {
      setPaymentState({
        ...paymentState,
        payments: paymentState.payments.map((payment) => {
          if (payment.id === thisPayment.id) {
            return { ...payment, selected: false }
          }
          return payment
        }),
      })
    } else {
      setPaymentState({
        ...paymentState,
        payments: paymentState.payments.map((payment) => {
          if (payment.id === thisPayment.id) {
            return { ...payment, selected: true }
          }
          return payment
        }),
      })
    }
  }

  return (
    <div className={paymentAll.card}>
      <div className={paymentAll.id}>{data.external_id}</div>
      <div
        className={`${paymentAll.patient} text-truncate`}
        title={`${data.patient.name} ${data.patient.surname}`}
      >
        {`${data.patient.name} ${data.patient.surname}`}
      </div>
      <div
        className={`${paymentAll.doctor} text-truncate`}
        title={`${data.principal_dentist.user.name} ${data.principal_dentist.user.surname}`}
      >
        {`${data.principal_dentist.user.name} ${data.principal_dentist.user.surname}`}
      </div>
      <div className="w-50 d-flex justify-content-end">
        <input
          type="checkbox"
          className={paymentAll.checkbox}
          checked={data.selected}
          onClick={() => selectPayment(data)}
        />
      </div>
    </div>
  )
}

export default PaymentsAllCard

PaymentsAllCard.propTypes = {
  data: PropTypes.object.isRequired,
  setPaymentState: PropTypes.func.isRequired,
  paymentState: PropTypes.array.isRequired,
}
