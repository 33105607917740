import React from 'react'
import Icons from '../../Icons/Icons'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SidebarItem = ({ item }) => {
  return (
    <div
      className={`position-relative cursor-pointer ${
        item.active ? 'scale' : 'disabled mb-2'
      }`}
    >
      <Link
        to={item.active && item.link}
        className="text-white d-flex flex-start align-items-center px-3 py-2 mb-4 "
      >
        <Icons type={item.logo} />
        <span className="mx-2">{item.title}</span>
      </Link>
      {!item.active && (
        <small className="sb-inactive-text">¡Próximamente!</small>
      )}
    </div>
  )
}

export default SidebarItem
SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
}
