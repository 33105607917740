import { combineReducers } from 'redux'
import userReducer from './reducers/userReducer'
import { pageWidthReducer } from './reducers/pageWidthReducer'
import { loginModalReducer } from './reducers/loginModalReducer'
import navbarReducer from './reducers/navbarReducer'
import modalReducer from './reducers/modalReducer'
import formOnlineReducer from './reducers/formOnlineReducer'
import usersReducer from './reducers/usersReducer'
import { patientReducer } from './reducers/patientReducer'
import { odTreatments } from './reducers/odTreatmentsReducer'
import { notificationsDropdownReducer } from './reducers/notificationsDropdownReducer'

const reducers = combineReducers({
  userReducer,
  navbarReducer,
  modalReducer,
  formOnlineReducer,
  patientReducer,
  usersReducer,
  odTreatments,
  notificationsDropdownReducer,
  pageWidthReducer,
  loginModalReducer,
})

export default reducers
