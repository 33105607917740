import React from 'react'
import DocImageInput from '../../components/DocImageInput'
import '../../index.css'
import DocImage from '../../components/DocImage'

export const TreatmentAdminStepFour = ({ planFiles, setPlanFiles }) => {
  const handleImageSelect = (image) => {
    let type = image?.type ? 'pdf' : 'image'
    const newFile = { file: image, type }
    setPlanFiles([...planFiles, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newPlanFiles = planFiles
    newPlanFiles.splice(indexToDelete, 1)
    setPlanFiles([...newPlanFiles])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newPlanFiles = planFiles
    newPlanFiles[indexToEdit].file = image
    setPlanFiles([...newPlanFiles])
  }

  return (
    <div className="container my-6 mx-auto">
      <div className="mx-6 d-flex">
        {planFiles.map((item, index) => (
          <DocImage
            index={index}
            type={item.type}
            image={item.file}
            handleDeletePlan={handleDeletePlan}
            handleEditPlan={handleEditPlan}
          />
        ))}
        {planFiles.length < 5 && (
          <DocImageInput
            inputName="Plan de Tratamiento"
            handleImageSelect={handleImageSelect}
          />
        )}
      </div>
    </div>
  )
}
