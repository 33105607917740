import React from 'react'
import claimModal from '../styles/claimModal.module.css'
import bell from '../../../assets/icons/feather-bell.svg'
import mail from '../../../assets/icons/feather-mail.svg'
import whatsapp from '../../../assets/icons/feather-whatsapp.svg'
import moment from 'moment'

const ClaimModalHistory = ({ claimState }) => {
  if (!claimState || claimState.length < 1) return null

  return (
    <div>
      <p className="mt-4 mb-2 small ">Historial de Reclamos:</p>
      <div className={claimModal.claimsContainer}>
        {claimState &&
          claimState.map((claim, i) => (
            <div key={i} className={claimModal.claimHistoryCardContainer}>
              <h4 className="text-primary fw-bold">{`${
                claimState.length - i
              }° Reclamo`}</h4>
              <div className="d-flex justify-content-between">
                <p className={`${claimModal.redTitle} small`}>
                  {claim.action_type}
                </p>
                <p className={`my-0 small`}>
                  Fecha:
                  <span className="fs-6 fw-bold">
                    {moment(claim.claim_date).format('DD/MM/YYYY')}
                  </span>
                </p>
                <div className="d-flex">
                  <p className={`my-0 small`}>Medio:</p>

                  {claim.claim_means.notification > 0 && (
                    <img
                      className="mx-1"
                      alt="bell-icon"
                      src={bell}
                      height={18}
                    />
                  )}
                  {claim.claim_means.email > 0 && (
                    <img
                      className="mx-1"
                      alt="bell-icon"
                      src={mail}
                      height={18}
                    />
                  )}
                  {claim.claim_means.whatsapp > 0 && (
                    <img
                      className="mx-1"
                      alt="bell-icon"
                      src={whatsapp}
                      height={18}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ClaimModalHistory
