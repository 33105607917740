import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from '../propsStateConnect'

function Header(props) {
  const { colors, updateState, applicationState, checkFrotalSonriendo } = props
  type_dispatch.step = 'Header'
  type_dispatch.field = 'MainInformation'
  const state_form = props.formOnlineReducer

  const getDescriptionType = () => {
    switch (applicationState.treatment_type) {
      case 1:
        return 'Full'
      case 2:
        return 'Fast'
      case 3:
        return 'Medium'
      case 4:
        return 'Kids'
      case 5:
        return 'Kids Fast'
      case 6:
        return 'Teens'
      default:
        return 'Full'
    }
  }

  useEffect(() => {
    updateState({ key: 'firstName', value: applicationState?.nombre })
    updateState({ key: 'lastName', value: applicationState?.apellido })
    updateState({ key: 'maxilar', value: applicationState?.maxilla?.value })
    updateState({ key: 'patient_id', value: applicationState?.patient_id })
    updateState({ key: 'type', value: applicationState?.treatment_type })
  }, [applicationState, updateState])
  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 2)

  return (
    <div className="form-online-header-border pb-4">
      <label className="fs-4 p-2">Formulario Online</label>
      <div className="d-flex">
        <div className="col-11 d-flex align-items-center ms-2">
          <div className="col-2">
            <div className="circulo" style={{ overflow: 'hidden' }}>
              <img
                src={checkFrotalSonriendo()}
                alt="Frontal sonriendo"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-3 d-flex flex-column">
            <label>Paciente</label>
            <p className="fw-bold fs-5">
              {`${applicationState.nombre} ${applicationState.apellido}`}
            </p>
          </div>
          <div className="col-3 d-flex flex-column">
            <label>Tratamiento</label>
            <div className="d-flex">
              <p className={`fs-5`} style={{ color: colors.color }}>
                KeepSmiling
              </p>
              <p
                className={`fw-bold fs-5 ms-1`}
                style={{ color: colors.color }}
              >
                {getDescriptionType()}
              </p>
            </div>
          </div>
          {applicationState.maxilar_string && (
            <div className="col-2 d-flex flex-column">
              <label>Maxilar</label>
              <p className="fw-bold fs-5">{applicationState.maxilar_string}</p>
            </div>
          )}
          <div className="col-3 d-flex flex-column">
            <label>Fecha de Nacimiento *</label>
            <input
              max="2020-01-01"
              type="date"
              disabled={!state_form.Enabled}
              value={state_form[type_dispatch.step][type_dispatch.field].age}
              onChange={(e) => {
                console.log(state_form)

                updateState({ key: 'age', value: e.target.value })
              }}
              style={{ border: `1px solid black` }}
              className="w-60 p-1 rounded"
            />
            {error1 && error1.message == 'Complete el campo edad' && (
              <div className="alert-danger alert-link p-2 mt-1 col-10">
                {error1.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
