import React from 'react'
import './styles.css'
import AvatarPlaceHolder from '../../../../assets/images/frontalsonriendo.png'
import InformationProgressBar from './InformationProgressBar'
import moment from 'moment'
import { useSelector } from 'react-redux'
import MobilePatientCard from '../../../../components/MobilePatientCard/MobilePatientCard'

const DentistMedicalRecordInformation = ({ informationState }) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const handleType = (type) => {
    const types = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
      'Sin tipo': 1,
    }
    return types[type]
  }

  if (pageWidth === 'mobile')
    return (
      <MobilePatientCard
        avatar={
          informationState.frontal_sonriendo
            ? informationState.frontal_sonriendo
            : AvatarPlaceHolder
        }
        name={`${informationState?.patient?.name}   ${informationState?.patient?.surname}`}
        maxilar={
          informationState.maxilla ? informationState.maxilla : 'Sin asignar'
        }
        stage={informationState?.order}
        type={'medical'}
      />
    )

  return (
    <div className="d-flex justify-content-between containerInformation flex-wrap ">
      <div className="patientData mb-2">
        <img
          src={
            informationState.frontal_sonriendo
              ? informationState.frontal_sonriendo
              : AvatarPlaceHolder
          }
          style={{ height: '100px', width: '100px' }}
          alt="avatar"
          className="cardImageInformation me-1"
        />
        <div className="information-patient">
          <div className="d-flex justify-content-between">
            <span className="text-primary fs-5 text-truncate fw-bold">{`${informationState?.patient?.name}   ${informationState?.patient?.surname}`}</span>
            <div
              className={`treatmentCardInformation${handleType(
                informationState?.type?.name
              )}`}
            >
              {informationState?.type?.name.toUpperCase()}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="d-flex flex-column align-items-start me-4">
              <span className="fw-bold fs-7">Fecha de nacimiento:</span>
              {informationState.patient.birthdate !==
                '-000001-11-30T00:00:00.000000Z' &&
              informationState.patient.birthdate !== null ? (
                <span className="fw-bold fs-7 ">{`${moment
                  .utc(informationState.patient.birthdate)
                  .format('DD-MM-YYYY')}`}</span>
              ) : (
                <span className=" fs-7 "> Sin fecha</span>
              )}
            </div>
            <div className="d-flex flex-column align-items-start me-4">
              <span className="fw-bold fs-7">Maxillar:</span>
              <span className="fs-7 ">{`${
                informationState.maxilla
                  ? informationState.maxilla
                  : 'Sin asignar'
              }`}</span>
            </div>
            <div className="d-flex align-self-center">
              <div className="rounded-pill stageBTN fs-6">{`ETAPA ${informationState.order}`}</div>
            </div>
          </div>
        </div>
      </div>
      <InformationProgressBar
        treatmentStatus={informationState.treatment_status}
      />
    </div>
  )
}

export default DentistMedicalRecordInformation

function MobileCard(informationState, handleType) {
  return (
    <div className="containerInformationMob mx-auto ">
      <img
        src={
          informationState.frontal_sonriendo
            ? informationState.frontal_sonriendo
            : AvatarPlaceHolder
        }
        style={{ height: '200px', width: '200px', borderRadius: 125 }}
        alt="avatar"
        className="mt-2"
      />
      <div className="d-flex mt-4">
        <span className="text-primary fs-5 text-truncate fw-bold">{`${informationState?.patient?.name}   ${informationState?.patient?.surname}`}</span>
        <div
          style={{ marginLeft: 10 }}
          className={`treatmentCardInformation${handleType(
            informationState?.type?.name
          )}`}
        >
          {informationState?.type?.name.toUpperCase()}
        </div>
      </div>

      <span className="fw-bold fs-6 mt-4">Fecha de nacimiento:</span>
      {informationState.patient.birthdate !==
        '-000001-11-30T00:00:00.000000Z' &&
      informationState.patient.birthdate !== null ? (
        <span className="fw-bold fs-6 ">{`${moment
          .utc(informationState.patient.birthdate)
          .format('DD-MM-YYYY')}`}</span>
      ) : (
        <span className=" fs-6 "> Sin fecha</span>
      )}
      <span className="fw-bold fs-6 mt-4">Maxillar:</span>
      <span className="fs-6 ">{`${
        informationState.maxilla ? informationState.maxilla : 'Sin asignar'
      }`}</span>

      <div className="rounded-pill stageBTN fs-5 mt-4 px-4 py-2">{`ETAPA ${informationState.order}`}</div>

      <div className="mt-4 mb-3 ">
        <InformationProgressBar
          treatmentStatus={informationState.treatment_status}
        />
      </div>
    </div>
  )
}
