import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from '../../propsStateConnect'
import { movements_options } from '../Utilities/OptionsRadios'
import RadioList from '../Utilities/RadioList'
import TextAreaCounter from '../Utilities/TextAreaCounter'
import ToothsSelect from '../Utilities/ToothsSelector'

function Movements(props) {
  type_dispatch.step = 'PretratmentDiagnosisValues'
  type_dispatch.field = 'Movements'
  const { colors, updateState, updateErrors } = props
  const movements_state =
    props.formOnlineReducer.PretratmentDiagnosisValues.Movements
  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  useEffect(() => {
    if (movements_state.movement) {
      updateErrors([])
    }
  }, [movements_state.movement, updateErrors])

  return (
    <div>
      <div
        className="w-100 fw-bold fs-6 pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Movimientos
      </div>
      {error1 && (
        <div className="alert-danger alert-link p-2 mt-1">{error1.message}</div>
      )}
      <RadioList
        enabled={props.formOnlineReducer.Enabled}
        colors={colors}
        radios={movements_options}
        setter={(value) => updateState({ key: 'movement', value })}
        stateField={movements_state.movement}
        name="typeOfMovement"
      />
      {movements_state.movement === 2 && (
        <div>
          <div className="text-muted mt-3 small">
            (Indique implantes, puentes, dientes que no quiera mover)
          </div>
          <div className="mt-3">
            <ToothsSelect
              enabled={props.formOnlineReducer.Enabled}
              step={type_dispatch.step}
              field={type_dispatch.field}
              tooths_state={
                props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
              }
            />
          </div>
          <div className="row">
            <div className="fw-bolder small mt-2">
              Si desea indicar el movimiento individualizado para cada pieza,
              puede detallar a continuación:
            </div>
            <div className="small text-muted mt-2">
              (Recuerde indicar la pieza a la que hace referencia el detalle)
            </div>
            <div className="mt-2">
              <textarea
                disabled={!props.formOnlineReducer.Enabled}
                value={movements_state.description}
                onChange={(e) => {
                  updateState({ key: 'description', value: e.target.value })
                }}
                className="form-control"
                name="observacion"
                id="observacion"
                cols="30"
                rows="7"
              ></textarea>

              <TextAreaCounter
                value={movements_state.description}
                maxValue={2500}
                error={error2}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Movements)
