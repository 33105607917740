import React from 'react'
import Loader from '../../components/loader'
import NotFoundComponent from '../../components/NotFound.js/NotFoundComponent'
import Paginator from '../../components/Paginator'
import Searchbar from '../../components/searchbar'
import { Filters } from './components/Filters'
import { ReferralsCard } from './components/ReferralsCard'
import { useReferrals } from './hooks/useReferrals'
import { ReferralAllModal } from './modals/ReferralAllModal'
import styles from './styles/referrals.module.css'

const Referrals = () => {
  const {
    referralsState,
    isAllSelected,
    getInitialReferrals,
    setReferralsState,
    setAllReferrals,
    setisAllSelected,
    getUrl,
    handlePagination,
  } = useReferrals()

  if (referralsState.loading) return <Loader />
  return (
    <div className="px-5 py-4">
      <div className="d-flex justify-content-between">
        <p className="fs-2 fw-bold m-0">Derivaciones</p>
        <div className={`d-flex flex-column gap-2 mb-3 ${styles.headerRight}`}>
          <Searchbar
            url={`${getUrl()}&`}
            state={referralsState}
            setState={setReferralsState}
            page={'referrals'}
            initialQueryType={'patient'}
          />

          {referralsState.referralsList.filter(
            (referral) => referral.selected
          ) !== 0 && (
            <ReferralAllModal
              referralsState={referralsState}
              setReferralsState={setReferralsState}
              setisAllSelected={setisAllSelected}
              getInitialReferrals={getInitialReferrals}
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between flex-column mt-2">
        <div className="d-flex justify-content-start">
          <div
            className={`my-2 me-3 ${referralsState.filter === 1 && 'fw-bold'} ${
              styles.filterBTNS
            }`}
            onClick={() =>
              setReferralsState((prev) => ({ ...prev, filter: 1 }))
            }
          >
            Todos{' '}
            <span className="text-muted">
              ({referralsState.referralsFilters?.all})
            </span>
          </div>
          <div
            className={`my-2 mx-3 ${referralsState.filter === 2 && 'fw-bold'} ${
              styles.filterBTNS
            }`}
            onClick={() =>
              setReferralsState((prev) => ({ ...prev, filter: 2 }))
            }
          >
            Pendientes{' '}
            <span className="text-muted">
              ({referralsState.referralsFilters?.pending})
            </span>
          </div>
          <div
            className={`my-2 mx-2 ${referralsState.filter === 3 && 'fw-bold'} ${
              styles.filterBTNS
            }`}
            onClick={() =>
              setReferralsState((prev) => ({ ...prev, filter: 3 }))
            }
          >
            Historial de Derivaciones{' '}
            <span className="text-muted">
              ({referralsState.referralsFilters?.derived})
            </span>
          </div>
        </div>
        <hr className="mb-0" />
      </div>

      <Filters
        setAllReferrals={setAllReferrals}
        isAllSelected={isAllSelected}
        visible={referralsState.referralsList.length}
      />

      {referralsState.referralsList.length ? (
        referralsState.referralsList.map((referral) => (
          <ReferralsCard
            key={referral.id}
            referrals={referral}
            setisAllSelected={setisAllSelected}
            referralsState={referralsState}
            setReferralsState={setReferralsState}
          />
        ))
      ) : (
        <NotFoundComponent />
      )}

      <Paginator
        visible={referralsState.referralsList.length}
        paginator={referralsState.paginator}
        getPaginator={handlePagination}
      />
    </div>
  )
}

export default Referrals
