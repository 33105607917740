import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Compressor from 'compressorjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  startGetUserList,
  startSaveNewUser,
} from '../../../redux/actions/users'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// BANDERAS
import ARG from '../../../assets/images/Argentina2.png'
import CHI from '../../../assets/images/Chile2.png'
import COL from '../../../assets/images/Colombia2.png'
import PAR from '../../../assets/images/Paraguay2.png'
import PER from '../../../assets/images/Peru2.png'
import URU from '../../../assets/images/Uruguay2.png'
import MEX from '../../../assets/images/Mexico.png'

import frontal_sonriendo from '../../../assets/images/frontalsonriendo.png'
import {
  ImageUploadContainer,
  ImageUploadReference,
} from '../../CargarTratamiento/components/styled'
import { Upload } from 'react-feather'
import handleToast from '../../../components/toaster'
import { getQuality } from '../../../utils/quality'

export default function NewUserModal({ countryId }) {
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const { profilesData } = useSelector((state) => state.usersReducer)

  const [userData, setUserData] = useState({
    name: { value: null, required: true },
    surname: { value: null, required: true },
    profile_id: { value: null, required: true },
    country_id: { value: 1, required: true },
    position_id: { value: 0, required: false },
    profileImage: { value: null, required: false },
  })
  const [error, setError] = useState({})
  const [countryData, setCountryData] = useState({
    countryName: '',
    countryFlag: '',
  })

  useEffect(() => {
    let input = userData.country_id
    input.value = countryId
    setUserData({ ...userData, country_id: input })
    if (countryId === 2) {
      return setCountryData({ countryName: 'URU', countryFlag: 'Uruguay' })
    }
    if (countryId === 3) {
      return setCountryData({ countryName: 'PAR', countryFlag: 'Paraguay' })
    }
    if (countryId === 4) {
      return setCountryData({ countryName: 'COL', countryFlag: 'Colombia' })
    }
    if (countryId === 5) {
      return setCountryData({ countryName: 'CHI', countryFlag: 'Chile' })
    }
    if (countryId === 6) {
      return setCountryData({ countryName: 'PER', countryFlag: 'Peru' })
    }
    if (countryId === 7) {
      return setCountryData({ countryName: 'MEX', countryFlag: 'Mexico' })
    }
  }, [])

  function saveUser() {
    const keys = Object.keys(userData)
    const values = Object.values(userData)
    const errors = {}
    values.map((value, index) => {
      if (value.required) {
        if (value.value === null || value.value === undefined || !value.value) {
          errors[keys[index]] = true
        } else {
          errors[keys[index]] = false
        }
      }
    })
    setError(errors)
    const validation = Object.values(errors)
    const hasError = validation.includes(true)
    if (hasError) {
      return handleToast('error', 'Hubo un error al guardar el usuario')
    } else {
      setError({})
    }

    handleToast('updating', 'Creando nuevo usuario...')
    const data = {
      name: userData.name.value,
      surname: userData.surname.value,
      profile_id: userData.profile_id.value,
      country_id: userData.country_id.value,
      position_id: userData.position_id.value,
      profileImage: userData.profileImage.value,
    }

    dispatch(startSaveNewUser(data, image))
    dispatch(startGetUserList())

    setUserData({
      name: '',
      surname: '',
      profile_id: null,
      country_id: 1,
      position_id: 0,
      profileImage: null,
    })
  }

  function handleModal() {
    setOpened(!opened)
  }

  function handleCountryFlag(name) {
    const countrys = {
      Argentina: ARG,
      Uruguay: URU,
      Paraguay: PAR,
      Colombia: COL,
      Chile: CHI,
      Peru: PER,
      México: MEX,
    }

    return countrys[name]
  }

  async function handleImageSelect(e) {
    e.preventDefault()

    let file = e.target.files[0]
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg'
    ) {
      toast.error(
        '👎 Solo se permite la carga de archivos tipo PNG,JPG,JPEG. Intente nuevamente.',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      )
      return
    }
    const maxAllowedSize = 5 * 1024 * 1024

    if (file.size > maxAllowedSize) {
      toast.warning('La imagen de perfil sobrepasa el peso máximo de 5MB.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })

      e.target.value = ''
    } else {
      setImage(file)
      new Compressor(file, {
        quality: getQuality(),

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          let reader = new FileReader()
          let file_compressed = result
          reader.onloadend = () => {
            let input = userData.profileImage
            input.value = reader.result
            setUserData({ ...userData, profileImage: input })
          }

          reader.readAsDataURL(file_compressed)
        },
        error(err) {
          console.log(err.message)
        },
      })
    }
  }

  useEffect(() => {
    if (userData.position_id.value === null) return
    if (userData.position_id.value === 3) {
      return setUserData({
        ...userData,
        profile_id: { value: null, required: false },
      })
    }
  }, [userData.position_id.value])

  return (
    <div>
      <button
        className="btn btn-primary px-3 rounded-pill w-100"
        onClick={() => setOpened(true)}
      >
        Nuevo usuario
      </button>
      <Modal isOpen={opened} toggle={handleModal} size="lg">
        <ModalBody>
          <div className="container">
            <div className="mb-5 text-primary fw-bold">Nuevo Usuario</div>
            <div className="row">
              <div
                className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                style={{
                  maxHeight: '200px',
                  width: '200px',
                  position: 'relative',
                }}
              >
                <div className="d-flex flex-row  m-0">
                  <ImageUploadContainer>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageSelect}
                      id="profile"
                      className="estudios"
                    />
                    <Upload />
                  </ImageUploadContainer>
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      position: 'relative',
                    }}
                  >
                    <ImageUploadReference
                      src={
                        userData.profileImage?.value
                          ? userData.profileImage?.value
                          : frontal_sonriendo
                      }
                      alt="Imagen de perfil"
                    />
                    {error.profileImage && (
                      <p
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          fontWeight: 'light',
                        }}
                      >
                        Este campo es obligatorio.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-7 px-4">
                <form>
                  <div className="">
                    <label
                      for="name"
                      className="form-label"
                      style={{ fontSize: '.7em' }}
                    >
                      Nombre
                    </label>
                    <input
                      onChange={(e) => {
                        const data = userData
                        data.name.value = e.target.value
                        setUserData(data)
                      }}
                      type="text"
                      className="form-control mb-0"
                      style={{ maxHeight: '30px' }}
                      id="name"
                    />
                    {error.name && (
                      <p
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          fontWeight: 'light',
                        }}
                      >
                        Este campo es obligatorio.
                      </p>
                    )}
                  </div>
                  <div className="">
                    <label
                      for="surname"
                      className="form-label"
                      style={{ fontSize: '.7em' }}
                    >
                      Apellido
                    </label>
                    <input
                      onChange={(e) => {
                        const data = userData
                        data.surname.value = e.target.value
                        setUserData(data)
                      }}
                      type="text"
                      className="form-control mb-0"
                      style={{ maxHeight: '30px' }}
                      id="surname"
                    />
                    {error.surname && (
                      <p
                        style={{
                          fontSize: '12px',
                          color: 'red',
                          fontWeight: 'light',
                        }}
                      >
                        Este campo es obligatorio.
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-12 p-0 mt-3">
                <p className="text-primary">Puesto</p>
                <div className=" row p-0">
                  {profilesData.positions?.map((position) => (
                    <div className="col-3 me-1">
                      <button
                        onClick={() => {
                          let input = userData.position_id
                          input.value = position.id
                          setUserData({ ...userData, position_id: input })
                        }}
                        className={
                          `btn px-2 w-100 rounded-3 mx-2 my-1 ` +
                          (userData.position_id?.value === position.id
                            ? 'btn-primary'
                            : 'btn-outline-primary')
                        }
                      >
                        {position.title}
                      </button>
                    </div>
                  ))}
                  {error.position_id && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: 'red',
                        fontWeight: 'light',
                      }}
                    >
                      Este campo es obligatorio.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-6 p-0 mt-4">
                <p className="text-primary">Perfil</p>
                <div className="d-flex flex-row flex-wrap row justify-content-left">
                  {profilesData.profiles?.map((profile) => (
                    <button
                      onClick={() => {
                        let input = userData.profile_id
                        input.value = profile.id
                        setUserData({ ...userData, profile_id: input })
                      }}
                      className={
                        `btn px-2 col-3 rounded-3 mx-2 my-1 ` +
                        (userData.profile_id?.value === profile.id
                          ? 'btn-primary'
                          : 'btn-outline-primary') +
                        (userData.position_id?.value === 3 ? ' disabled' : '')
                      }
                    >
                      {profile.title}
                    </button>
                  ))}
                  {error.profile_id && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: 'red',
                        fontWeight: 'light',
                      }}
                    >
                      Este campo es obligatorio.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-6 p-0 mt-4">
                <p className="text-primary">Pais</p>
                <div className="d-flex flex-row flex-wrap row justify-content-left">
                  {countryId === 1 ? (
                    profilesData.countries?.map((country) => (
                      <button
                        onClick={() => {
                          let input = userData.country_id
                          input.value = country.id
                          setUserData({ ...userData, country_id: input })
                        }}
                        className={
                          `btn  px-2 col-3 rounded-3 mx-2 my-1 ` +
                          (userData.country_id?.value === country.id
                            ? 'btn-primary'
                            : 'btn-outline-primary' +
                              (userData.position_id?.value === 3
                                ? ' disabled'
                                : ''))
                        }
                      >
                        {country.name[0].toString().toUpperCase() +
                          country.name[1].toString().toUpperCase()}
                        {
                          <img
                            src={handleCountryFlag(country.name)}
                            style={{ maxHeight: '25px', marginLeft: '3px' }}
                            alt={country.name}
                          />
                        }
                      </button>
                    ))
                  ) : (
                    <button
                      className={
                        `btn btn-primary px-2 col-3 rounded-3 mx-2 my-1 ` +
                        (userData.position_id?.value === 3 ? ' disabled' : '')
                      }
                    >
                      {countryData.countryName}
                      {
                        <img
                          src={handleCountryFlag(countryData.countryFlag)}
                          style={{ maxHeight: '25px', marginLeft: '3px' }}
                          alt={countryData.countryFlag}
                        />
                      }
                    </button>
                  )}

                  {error.country_id && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: 'red',
                        fontWeight: 'light',
                      }}
                    >
                      Este campo es obligatorio.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {!loading && (
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-primary text-white rounded-pill px-5"
                onClick={saveUser}
              >
                Guardar
              </button>
            </div>
          )}

          {loading && (
            <button
              className="btn btn-success rounded-pill"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  )
}
