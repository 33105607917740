import React from "react";
import { Bar } from "react-chartjs-2";

const data = {
  labels: ["L", "M", "M", "J", "V"],
  datasets: [
    {
      label: "Ingresos",
      data: [12, 19, 3, 5, 2],
      backgroundColor: "#00155C",
    },
  ],
};

const options = {
  offsetGridLines: true,
  drawTicks: true,
  layout: {
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
    },
  },
  legend: {
    display: false,
  },
  tooltips: { display: true },
  responsive: false,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          padding: 5,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    ],
  },
};

const WeeklyChart = () => (
  <div className="shadow p-3" style={{ borderRadius: "20px", height: "250px" }}>
    <ul
      className="d-flex flex-row justify-content-end"
      style={{ listStyleType: "none" }}
    >
      <li className="mx-3">
        <a className="fw-bold" style={{ fontSize: ".8rem" }} href="#">
          Ingresos
        </a>
      </li>
      <li className="mx-3">
        <a className="fw-bold" style={{ fontSize: ".8rem" }} href="#">
          Reclamos
        </a>
      </li>
      <li className="mx-3">
        <a className="fw-bold" style={{ fontSize: ".8rem" }} href="#">
          Consultas
        </a>
      </li>
      <li className="mx-3">
        <a className="fw-bold" style={{ fontSize: ".8rem" }} href="#">
          Control
        </a>
      </li>
    </ul>
    <Bar data={data} options={options} height={200} width={400} />
  </div>
);

export default WeeklyChart;
