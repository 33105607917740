import React from 'react'
import '../../../style.css'

const Overbite = ({ formData }) => {
  const searchOverbiteUp = (number) => {
    return formData?.overbite_up.includes(number)
  }
  const searchOverbiteDown = (number) => {
    return formData?.overbite_down.includes(number)
  }

  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Overbite - Entrecruzamiento</p>
      <hr className="mt-2" />
      <div className="d-flex flex-column">
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="overbite"
            disabled
            checked={formData?.overbite == 1 && true}
          />
          <label className="ms-2">Mantener</label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="overbite"
            disabled
            checked={formData?.overbite == 2 && true}
          />
          <label className="ms-2">Mejorar</label>
        </div>
        {formData?.overbite == 2 && (
          <div className="improve-overjet-container">
            <div className="w-100">
              <p className="improve-overjet-title">
                ¿Cómo desea mejorar el Overbite?
              </p>
              <hr className="mt-1" />
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Overbite Aumentado</p>
                <hr className="mt-1" />
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteImproved"
                    disabled
                    checked={searchOverbiteUp(1)}
                  />
                  <label className="ms-2">Por instrusión anterosuperior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteImproved"
                    disabled
                    checked={searchOverbiteUp(2)}
                  />
                  <label className="ms-2">Por instrusión anteroinferior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteImproved"
                    disabled
                    checked={searchOverbiteUp(3)}
                  />
                  <label className="ms-2">Por extrusíon posterosuperior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteImproved"
                    disabled
                    checked={searchOverbiteUp(4)}
                  />
                  <label className="ms-2">Por extrusíon posteroinferior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteImproved"
                    disabled
                    checked={searchOverbiteUp(5)}
                  />
                  <label className="ms-2">Otras:</label>
                </div>
                {searchOverbiteUp(5) && (
                  <>
                    <p className="mt-3">Otras:</p>
                    <div className="step-text-area-overjet overflow-scroll">
                      {formData?.overbite_up_other}
                    </div>
                  </>
                )}
              </div>
              <div>
                <p className="mb-0">Overbite Disminuido</p>
                <hr className="mt-1" />
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteDiminished"
                    disabled
                    checked={searchOverbiteDown(1)}
                  />
                  <label className="ms-2">Por intrusión posterosuperior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteDiminished"
                    disabled
                    checked={searchOverbiteDown(2)}
                  />
                  <label className="ms-2">Por intrusión posteroinferior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteDiminished"
                    disabled
                    checked={searchOverbiteDown(3)}
                  />
                  <label className="ms-2">Por extrusión anterosuperior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteDiminished"
                    disabled
                    checked={searchOverbiteDown(4)}
                  />
                  <label className="ms-2">Por extrusión anteroinferior</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="mt-2 inputColor"
                    name="overbiteDiminished"
                    disabled
                    checked={searchOverbiteDown(5)}
                  />
                  <label className="ms-2">Otras:</label>
                </div>
                {searchOverbiteDown(5) && (
                  <>
                    <p className="mt-3">Otras:</p>
                    <div className="step-text-area-overjet overflow-scroll">
                      {formData?.overbite_down_other}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Overbite
