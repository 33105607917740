import React, { useRef } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import ImageInput from '../components/ImageInput'
import DocImage from './DocImage'
import DocImageInput from './DocImageInput'

export const TreatmentAdminStepThree = ({
  state,
  setState,
  studiesFiles,
  setStudiesFiles,
}) => {
  const handleImagePic = (image) => {
    let type = image?.type ? 'pdf' : 'image'
    const newFile = { file: image, type }
    setStudiesFiles([...studiesFiles, newFile])
  }

  const handleDeletePlan = (indexToDelete) => {
    let newStudiesFiles = studiesFiles
    newStudiesFiles.splice(indexToDelete, 1)
    setStudiesFiles([...newStudiesFiles])
  }

  const handleEditPlan = (image, indexToEdit) => {
    let newStudiesFiles = studiesFiles
    newStudiesFiles[indexToEdit].file = image
    setStudiesFiles([...newStudiesFiles])
  }
  const handleImageSelect = async (name, img, type) => {
    setState({
      ...state,
      [name]: img,
      cropper_img: img,
      [type]: {
        ...state[type],
        [name]: img,
      },
    })
  }

  const rxpanoramica_ref = useRef()
  const telerradiografia_ref = useRef()
  const ricketts_ref = useRef()

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="container mt-5">
        <div className="row col-11 d-flex flex-row justify-content-center mx-auto px-5 mb-5">
          <ImageInput
            name="rxpanoramica"
            src="rxpanoramica"
            id="Rx Panoramica"
            type="estudios"
            loadImage={state.rxpanoramica}
            ref={rxpanoramica_ref}
            handleImageSelect={handleImageSelect}
          />

          <ImageInput
            name="telerradiografia"
            src="telerradiografia"
            id="Telerradiografia"
            type="estudios"
            loadImage={state.telerradiografia}
            ref={telerradiografia_ref}
            handleImageSelect={handleImageSelect}
          />
          <ImageInput
            name="ricketts"
            src="ricketts"
            id="Ricketts"
            type="estudios"
            loadImage={state.ricketts}
            ref={ricketts_ref}
            handleImageSelect={handleImageSelect}
          />
          <div className="mt-3 d-flex flex-wrap gap-5">
            {studiesFiles.map((item, index) => (
              <DocImage
                index={index}
                type={item.type}
                image={item.file}
                handleDeletePlan={handleDeletePlan}
                handleEditPlan={handleEditPlan}
                inputName="Otros"
              />
            ))}
            {studiesFiles.length < 5 && (
              <DocImageInput
                allowPDF={true}
                inputName="Otros"
                handleImageSelect={handleImagePic}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
