import React from 'react'
import { useSelector } from 'react-redux'
import DesktopHome from './DesktopHome'
import MobileHome from './MobileHome'

const Home = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  if (pageWidth === 'desktop') return <DesktopHome />
  else return <MobileHome />
}

export default Home
