import React, {Fragment, useEffect, useState} from "react";
import Header from '../components/header_image'
import Categories from "./Categories";
import SubCategories from "./SubCategories";
import Noticia from "./Noticia";

function Admin(props) {

    const [selectedCategorie, setSelectedCategorie] = useState(null)
    const [selectedSubCategorie, setSelectedSubCategorie] = useState(null)

    return (
        <Fragment>
            <Header/>
            <div className="container">

                <div className="row mt-5">
                    <h2>Nueva Noticia</h2>
                </div>

                <div className="row d-flex justify-content-center">
                {selectedCategorie === null && <Categories setCategorie={setSelectedCategorie}/>}
                {selectedCategorie && selectedSubCategorie === null && <SubCategories setSubCategorie={setSelectedSubCategorie} categorie={selectedCategorie}/>}
                {selectedSubCategorie && <Noticia categorie={selectedCategorie} subCategorie={selectedSubCategorie}/>}
                </div>
            </div>
        </Fragment>
    )
}

export default Admin