import React, { useEffect } from 'react'
import RadioList from '../Utilities/RadioList'
import ToothsSelect from '../Utilities/ToothsSelector'
import { connect } from 'react-redux'
import {
  mapDispatchToProps,
  mapStateToProps,
  type_dispatch,
} from '../../propsStateConnect'
import { yes_nothing_options, yes_no_options } from '../Utilities/OptionsRadios'
import TextAreaCounter from '../Utilities/TextAreaCounter'

function Stripping(props) {
  type_dispatch.step = 'InstructionsTratment'
  type_dispatch.field = 'Stripping'
  const { colors, updateState, updateErrors } = props
  const middle_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]

  const stripping = middle_state.stripping
  const virtualRemodel = middle_state.virtualRemodel
  const withInclination = middle_state.withInclination
  const withoutInclination = middle_state.withoutInclination
  const withGain = middle_state.withGain
  const withoutGain = middle_state.withoutGain

  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  const error3 = errors_list.find((err) => err.order === 3)
  const error4 = errors_list.find((err) => err.order === 4)
  const error5 = errors_list.find((err) => err.order === 5)
  const error6 = errors_list.find((err) => err.order === 6)
  // const error7 = errors_list.find((err) => err.order === 7)
  const error9 = errors_list.find((err) => err.order === 9)
  const error10 = errors_list.find((err) => err.order === 10)
  const error11 = errors_list.find((err) => err.order === 11)
  // useErrorList(errors_list, updateErrors, [stripping, virtualRemodel, withInclination, withoutInclination, withGain, withoutGain, middle_state.othersGain])
  useEffect(() => {
    if (errors_list.find((err) => err.order === 1)) {
      updateErrors(errors_list.filter((err) => err.order === 2))
    }
    if (errors_list.find((err) => err.order === 2)) {
      updateErrors(errors_list.filter((err) => err.order === 3))
    }
    if (errors_list.find((err) => err.order === 3)) {
      updateErrors(errors_list.filter((err) => err.order === 4))
    }
    if (errors_list.find((err) => err.order === 4)) {
      updateErrors(errors_list.filter((err) => err.order === 5))
    }
    if (errors_list.find((err) => err.order === 5)) {
      updateErrors(errors_list.filter((err) => err.order === 6))
    }
    if (errors_list.find((err) => err.order === 6)) {
      updateErrors(errors_list.filter((err) => err.order === 7))
    }
    if (errors_list.find((err) => err.order === 7)) {
      updateErrors(errors_list.filter((err) => err.order === 8))
    }
    if (errors_list.find((err) => err.order === 9)) {
      updateErrors(errors_list.filter((err) => err.order === 12))
    }
    if (errors_list.find((err) => err.order === 10)) {
      updateErrors(errors_list.filter((err) => err.order === 12))
    }
    if (errors_list.find((err) => err.order === 11)) {
      updateErrors(errors_list.filter((err) => err.order === 12))
    }
  }, [middle_state])

  return (
    <div>
      <div
        className="w-100 fw-bolder pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Apiñamiento
      </div>
      <div className="mt-3 ms-3">
        <div
          className="fw-bolder small pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          ¿Desea realizar Stripping?
        </div>
        {error1 && (
          <div className="alert-danger alert-link p-2 mt-1">
            {error1.message}
          </div>
        )}
        <RadioList
          enabled={props.formOnlineReducer.Enabled}
          colors={colors}
          radios={yes_nothing_options}
          setter={(value) => updateState({ key: 'stripping', value })}
          stateField={stripping}
          name="stripping"
        />
        <div
          className="fw-bolder small mt-3 pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Remodelado Virtual
        </div>
        {error2 && (
          <div className="alert-danger alert-link p-2 mt-1">
            {error2.message}
          </div>
        )}
        <RadioList
          enabled={props.formOnlineReducer.Enabled}
          colors={colors}
          radios={yes_no_options}
          setter={(value) => updateState({ key: 'virtualRemodel', value })}
          stateField={virtualRemodel}
          name="virtualRemodel"
        />
        {virtualRemodel === 1 && (
          <div>
            <div className="mt-3 small text-muted mt-2">
              Indique las piezas en las que desea realizar remodelado virtual:
            </div>
            <div className="mt-5">
              <ToothsSelect
                enabled={props.formOnlineReducer.Enabled}
                step={type_dispatch.step}
                field={type_dispatch.field}
                tooths_state={
                  props.formOnlineReducer[type_dispatch.step][
                    type_dispatch.field
                  ]
                }
              />
            </div>
            <div>
              <div className="mt-3 small text-muted mt-2">
                Indique las piezas en las que desea realizar remodelado virtual:
              </div>
              <textarea
                disabled={!props.formOnlineReducer.Enabled}
                onChange={(e) => {
                  updateState({ key: 'description', value: e.target.value })
                }}
                value={middle_state.description}
                className="form-control mt-3"
                name="observacion"
                id="observacion"
                cols="30"
                rows="3"
              ></textarea>
              <TextAreaCounter
                value={middle_state.description}
                maxValue={2500}
                error={error9}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-5 w-100">
        <div
          className="w-100 fw-bolder pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Ganancia de Espacio Anterior
        </div>
        <div className="d-flex mt-3">
          <div className="col-6 ms-3">
            <div
              className="w-90 fw-bolder small pb-1"
              style={{ borderBottom: `1px solid ${colors.color}` }}
            >
              Proinclinación (con torque):
            </div>
            {error3 && (
              <div className="alert-danger alert-link p-2 mt-1 col-11">
                {error3.message}
              </div>
            )}
            <RadioList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={yes_nothing_options}
              setter={(value) => updateState({ key: 'withInclination', value })}
              stateField={withInclination}
              name="withInclination"
            />
          </div>
          <div className="col-6">
            <div
              className="col-11 fw-bolder small pb-1"
              style={{ borderBottom: `1px solid ${colors.color}` }}
            >
              Protusión (sin torque):
            </div>
            {error4 && (
              <div className="alert-danger alert-link p-2 mt-1 col-11">
                {error4.message}
              </div>
            )}
            <RadioList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={yes_nothing_options}
              setter={(value) =>
                updateState({ key: 'withoutInclination', value })
              }
              stateField={withoutInclination}
              name="withoutInclination"
            />
          </div>
        </div>
        <div>
          <div className="mt-3 mt-2 small fw-bolder">Otras</div>
          <textarea
            disabled={!props.formOnlineReducer.Enabled}
            onChange={(e) => {
              updateState({ key: 'othersInclination', value: e.target.value })
            }}
            value={middle_state.othersInclination}
            className="form-control mt-3"
            name="observacion"
            id="observacion"
            cols="30"
            rows="3"
          ></textarea>
          <TextAreaCounter
            value={middle_state.othersInclination}
            maxValue={2500}
            error={error10}
          />
        </div>
      </div>
      <div className="mt-5 w-100">
        <div
          className="w-100 fw-bolder pb-1"
          style={{ borderBottom: `1px solid ${colors.color}` }}
        >
          Ganancia de Espacio Posterior
        </div>
        <div className="d-flex mt-3">
          <div className="col-6 ms-3">
            <div
              className="w-90 small pb-1"
              style={{ borderBottom: `1px solid ${colors.color}` }}
            >
              Expansión con torque:
            </div>
            {error5 && (
              <div className="alert-danger alert-link p-2 mt-1 col-11">
                {error5.message}
              </div>
            )}
            <RadioList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={yes_nothing_options}
              setter={(value) => updateState({ key: 'withGain', value })}
              stateField={withGain}
              name="withGain"
            />
          </div>
          <div className="col-6">
            <div
              className="col-11 small pb-1"
              style={{ borderBottom: `1px solid ${colors.color}` }}
            >
              Expansión sin torque:
            </div>
            {error6 && (
              <div className="alert-danger alert-link p-2 mt-1 col-11">
                {error6.message}
              </div>
            )}
            <RadioList
              enabled={props.formOnlineReducer.Enabled}
              colors={colors}
              radios={yes_nothing_options}
              setter={(value) => updateState({ key: 'withoutGain', value })}
              stateField={withoutGain}
              name="withoutGain"
            />
          </div>
        </div>
        <div>
          <div className="mt-3 mt-2 small fw-bolder">Otras</div>
          <textarea
            disabled={!props.formOnlineReducer.Enabled}
            onChange={(e) => {
              updateState({ key: 'othersGain', value: e.target.value })
            }}
            value={middle_state.othersGain}
            className="form-control mt-3"
            name="observacion"
            id="observacion"
            cols="30"
            rows="3"
          ></textarea>
          <TextAreaCounter
            value={middle_state.othersGain}
            maxValue={2500}
            error={error11}
          />
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Stripping)
