import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../../utils/backend'

const useDentistPatients = ({ isMobile }) => {
  const { dentistId, countryId } = useSelector((state) => state.userReducer)
  const [filter, setFilter] = useState(1)
  const [state, setState] = useState(null)
  let num = isMobile ? 6 : 20

  const getUrl = (type = 1, page = 1) => {
    let url = `api/dentists/${dentistId}/patients?cant_pages=${num}&page=${page}`
    if (type === 2) url = url + ` &filter[user.groups.id]=1`
    if (type === 3)
      url = `api/dentists/${dentistId}/patients/actions?cant_pages=${num}&page=${page}`
    return url
  }

  const handleFilter = async (type) => {
    setFilter(type)
    try {
      const res = await apiClient(getUrl(type))
      setState((prevState) => ({
        ...prevState,
        patients: res.data.table.body,
        paginator: res.data.paginator,
        meta: res.data.meta,
        clearSearchBarInput: true,
        loadTodosFilter: false,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const getPaginatorData = async (page) => {
    let path = getUrl(filter, page)
    if (state.searchQuery)
      path = path + `&filter[fullname]=${state.searchQuery}`
    try {
      const res = await apiClient.get(path)
      setState((prevState) => ({
        ...prevState,
        patients: res.data.table.body,
        paginator: res.data.paginator,
        meta: res.data.meta,
        loadTodosFilter: false,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleFilter(1)
  }, [])

  useEffect(() => {
    if (state?.loadTodosFilter) return handleFilter(1)
  }, [state])

  return {
    state,
    filter,
    countryId,
    handleFilter,
    setState,
    getUrl,
    getPaginatorData,
  }
}

export default useDentistPatients
