import gold from '../../../../assets/images/sc-gold.png'
import black from '../../../../assets/images/sc-black.png'
import platinum from '../../../../assets/images/sc-platinum.png'
import elite from '../../../../assets/images/sc-elite.png'
import begginer from '../../../../assets/images/sc-begginer.png'
import silver from '../../../../assets/images/sc-silver.png'

export const getProfileData = (id, isImg = false) => {
  if (isImg) {
    const imgs = {
      gold: gold,
      platinum: platinum,
      black: black,
      black_elite: elite,
      beginner: begginer,
      silver: silver,
    }
    return imgs[id]
  } else {
    const name = {
      gold: 'Gold',
      platinum: 'Platinum',
      black: 'Black',
      black_elite: 'Elite',
      beginner: 'Begginer',
      silver: 'Silver',
    }
    return name[id]
  }
}

export const setIndicator = (cant) => {
  if (cant < 5) {
    return 0
  }
  if (cant >= 5 && cant <= 10) {
    return 1
  }
  if (cant >= 11 && cant <= 15) {
    return 2
  }
  if (cant >= 16 && cant <= 19) {
    return 3
  }
  if (cant >= 20 && cant <= 25) {
    return 4
  }
  if (cant >= 26 && cant <= 30) {
    return 5
  }
  if (cant >= 31 && cant <= 35) {
    return 6
  }
  if (cant >= 36 && cant <= 40) {
    return 7
  }
  if (cant >= 41 && cant <= 45) {
    return 8
  }
  if (cant >= 46 && cant < 50) {
    return 9
  }
  if (cant >= 50) {
    return 10
  }
}
