import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import apiClient from '../../../utils/backend'
import { Trash } from 'react-feather'
import Danger from '../../../assets/images/danger_triangle.png'
import 'react-toastify/dist/ReactToastify.css'
import handleToast from '../../../components/toaster'
import { useSelector } from 'react-redux'

const DeleteModal = ({ username, id, setInitialData }) => {
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(false)
  const user = useSelector((state) => state.userReducer)

  const handleDelete = async () => {
    setLoading(true)
    handleToast('updating', 'Eliminando datos del paciente...')

    try {
      await apiClient.delete(`/api/patients/${id}`)
      handleToast('success', 'Paciente eliminado')
      // Reload index data
      setTimeout(() => {
        setInitialData(user.countryId)
        setLoading(false)
        setOpened(false)
      }, 1200)
    } catch (error) {
      handleToast('error', 'Error al eliminar al paciente')
      setLoading(false)
    }
  }

  const handleModal = () => {
    setOpened(!opened)
  }

  return (
    <>
      <span style={{ fontSize: '.8rem', lineHeight: '25px' }} className="trash">
        <Trash className="mt-2" onClick={() => setOpened(true)} />
      </span>
      <Modal isOpen={opened} toggle={handleModal}>
        <ModalBody>
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 flex text-center">
                <img
                  alt="keso"
                  src={Danger}
                  className="mt-1"
                  style={{ width: '80px' }}
                />
                <p className="mt-2 text-black fs-4 fw-bold">
                  ¿Estas seguro que deseas eliminar el paciente: {username}?
                </p>
                <p
                  style={{ color: '#000', fontSize: '13px' }}
                  className="pt-2 mt-4"
                >
                  Se eliminaran todos los tratamientos existentes asignados a
                  este paciente
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center my-4">
            {!loading && (
              <div className="w-50 d-flex justify-content-around">
                <button
                  className="btn btn-light border rounded-pill px-5 me-3"
                  onClick={() => handleModal()}
                >
                  Volver
                </button>
                <button
                  className="btn btn-outline-warning rounded-pill px-5 ms-3"
                  onClick={() => handleDelete()}
                >
                  Eliminar
                </button>
              </div>
            )}

            {loading && (
              <button className="btn btn-danger" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DeleteModal
