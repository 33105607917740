import React from 'react'
import {
  gendersData,
  selectStyleData,
  selectStyleDataGender,
  documentoDeIdentidadCol,
} from './utilies/constantInfo'
import Select from 'react-select'
import OdProfile from './components/OdProfile'
import Input from '../../../components/input'
import Loader from '../../../components/loader'
import useDentistProfile from './hooks/useDentistProfile'
import DentistDirections from './components/DentistDirections'
import directionStyles from './styles/directionsForm.module.css'
import './styles/index.css'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

const ProfileDesktop = () => {
  const {
    avatar,
    avatarLink,
    personalData,
    errorField,
    requiredData,
    genderObject,
    isColombian,
    documentoDeIdentidad,
    countryId,
    fiscalConditionObject,
    isChilean,
    profile,
    loadingData,
    directions,
    removeAvatar,
    setAvatar,
    getBase64,
    setPersonalData,
    displayAvatarImage,
    cleanError,
    setRequiredData,
    setGenderObject,
    handleGetCUIT,
    handleGetCondicionFiscal,
    getCondicionFiscalData,
    setFiscalConditionObject,
    getMatriculaNacional,
    getMatriculaProvincial,
    handleRequest,
    setDocumentoDeIdentidad,
    handleDeleteDirection,
    handleSaveDirection,
    addDirection,
  } = useDentistProfile()

  if (!requiredData) return <Loader />

  return (
    <>
      <div className="container mt-4 px-5">
        <div className="row">
          <div className="col-10 d-flex flex-row justify-content-between">
            <h1 style={{ fontWeight: 701 }} className="text-primary">
              Mi Perfil
            </h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                {avatar && !avatarLink && (
                  <div class="d-grid gap-2 mx-auto">
                    <button
                      className="btn btn-danger text-white"
                      onClick={() => removeAvatar()}
                    >
                      Eliminar
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  name="avatar"
                  onChange={(e) => {
                    setAvatar(e.target.files[0])
                    getBase64(e.target.files[0], (res) =>
                      setPersonalData({ ...personalData, profilePhoto: res })
                    )
                  }}
                  id="od_profile_pic_input"
                />

                <label htmlFor="od_profile_pic_input">
                  {displayAvatarImage()}
                </label>
              </div>

              <div className="col-8 px-2">
                <h5 className="fw-bold">Datos Personales</h5>
                <div className="row mb-4 mt-2">
                  <div className="col-6">
                    <Input
                      disabled
                      label="Nombre"
                      type="text"
                      id="name"
                      name="name"
                      onChange={(e) => {
                        cleanError('name')
                        setRequiredData({
                          ...requiredData,
                          name: e.target.value,
                        })
                      }}
                      value={requiredData.name}
                    />
                    {errorField.name && (
                      <p className="text-danger fw-light">{errorField.name}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <Input
                      disabled
                      label="Apellido"
                      type="text"
                      id="surname"
                      name="surname"
                      value={requiredData.surname}
                      onChange={(e) => {
                        cleanError('surname')
                        setRequiredData({
                          ...requiredData,
                          surname: e.target.value,
                        })
                      }}
                    />
                    {errorField.surname && (
                      <p className="text-danger fw-light">
                        {errorField.surname}
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mx-2">
                    <label htmlFor="gender">Género</label>
                    <Select
                      styles={selectStyleDataGender}
                      options={gendersData}
                      placeholder="Seleccione el género..."
                      value={genderObject}
                      onChange={(e) => {
                        cleanError('gender')
                        setGenderObject(e)
                        setRequiredData({
                          ...requiredData,
                          gender: e.value,
                        })
                      }}
                    />
                    {errorField.gender && (
                      <p className="text-danger fw-light mt-2">
                        {errorField.gender}
                      </p>
                    )}
                  </div>
                  <div className="col-6">
                    <Input
                      label="Fecha de Nacimiento"
                      type="date"
                      id="birthdate"
                      value={requiredData.birthdate}
                      onChange={(e) =>
                        setRequiredData({
                          ...requiredData,
                          birthdate: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4 mt-4">
              <div className="col-6">
                <Input
                  disabled
                  label="Email"
                  type="email"
                  value={requiredData.email}
                  onChange={(e) => {
                    cleanError('email')
                    setRequiredData({
                      ...requiredData,
                      email: e.target.value,
                    })
                  }}
                />
                {errorField.email && (
                  <p className="text-danger fw-light">{errorField.email}</p>
                )}
              </div>
              <div className="col-6">
                <Input
                  label="Telefono"
                  type="number"
                  value={personalData.phone_number}
                  onChange={(e) => {
                    if (e.target.value.length < 25)
                      setPersonalData({
                        ...personalData,
                        phone_number: e.target.value,
                      })
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="row mb-4">
              {isColombian && (
                <div className="d-flex col-6 flex-column m-0">
                  <label>Documento de Identidad</label>
                  <div className="d-flex">
                    <div className="col-4 ">
                      <Select
                        styles={selectStyleData}
                        placeholder="Seleccione..."
                        options={documentoDeIdentidadCol}
                        value={documentoDeIdentidad}
                        onChange={(e) => {
                          setDocumentoDeIdentidad(e)
                          setPersonalData({
                            ...personalData,
                            cuit_prefix: e.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col-8">
                      <Input
                        type="number"
                        id="cuit_col"
                        value={personalData.cuit}
                        onChange={(e) => {
                          setPersonalData({
                            ...personalData,
                            cuit: e.target.value,
                          })
                          cleanError('cuit_col')
                        }}
                      />
                    </div>
                  </div>
                  {errorField.cuit && (
                    <p className="text-danger fw-light">{errorField.cuit}</p>
                  )}
                </div>
              )}
              {isChilean ? (
                <div className="col-6">
                  <Input
                    label={handleGetCUIT(countryId) ?? 'CUIT'}
                    type="text"
                    id="rut"
                    value={requiredData.rut}
                    onChange={(e) => {
                      cleanError('rut')
                      setRequiredData({
                        ...requiredData,
                        rut: e.target.value,
                      })
                    }}
                  />
                  {errorField.rut && (
                    <p className="text-danger fw-light">{errorField.rut}</p>
                  )}
                </div>
              ) : (
                <div className="col-6">
                  <Input
                    label={handleGetCUIT(countryId) ?? 'CUIT'}
                    type="number"
                    id="cuit"
                    value={personalData.cuit}
                    onChange={(e) => {
                      cleanError('cuit')
                      setPersonalData({
                        ...personalData,
                        cuit: e.target.value,
                      })
                    }}
                  />
                  {errorField.cuit && (
                    <p className="text-danger fw-light">{errorField.cuit}</p>
                  )}
                </div>
              )}
              <div className="col-6">
                <label htmlFor="fiscal_condition">
                  {handleGetCondicionFiscal()}
                </label>
                <Select
                  styles={selectStyleData}
                  placeholder="Seleccione..."
                  options={getCondicionFiscalData()}
                  value={fiscalConditionObject}
                  onChange={(e) => {
                    setFiscalConditionObject(e)
                    setPersonalData({
                      ...personalData,
                      fiscal_condition: e.value,
                    })
                  }}
                />
              </div>
            </div>
            {!isColombian && !isChilean && (
              <div className="row mb-4">
                <div className="col-6">
                  <Input
                    label={getMatriculaNacional()}
                    type="number"
                    value={personalData.national_enrollment}
                    onChange={(e) => {
                      if (e.target.value.length <= 10)
                        cleanError('national_enrollment')
                      setPersonalData({
                        ...personalData,
                        national_enrollment: e.target.value,
                      })
                    }}
                  />
                  {errorField.national_enrollment && (
                    <p className="text-danger fw-light">
                      {errorField.national_enrollment}
                    </p>
                  )}
                </div>
                <div className="col-6">
                  <Input
                    label={getMatriculaProvincial()}
                    type="number"
                    value={personalData.provintial_enrollment}
                    onChange={(e) => {
                      if (e.target.value.length <= 10)
                        cleanError('provintial_enrollment')
                      setPersonalData({
                        ...personalData,
                        provintial_enrollment: e.target.value,
                      })
                    }}
                  />
                  {errorField.provintial_enrollment && (
                    <p className="text-danger fw-light">
                      {errorField.provintial_enrollment}
                    </p>
                  )}
                </div>
              </div>
            )}
            <hr />
            <h5 className="fw-bold">Dirección</h5>
            {directions.map((direction) => (
              <DentistDirections
                direction={direction}
                key={direction.id || direction.inputId}
                usePlacesAutocomplete={usePlacesAutocomplete}
                getGeocode={getGeocode}
                getLatLng={getLatLng}
                handleDeleteDirection={handleDeleteDirection}
                handleSaveDirection={handleSaveDirection}
              />
            ))}
            <div className="w-100 d-flex justify-content-end pe-4">
              <button
                type="button"
                className={directionStyles.addDirection}
                onClick={addDirection}
              >
                Agregar dirección
              </button>
            </div>
            <hr />
            <h5 className="fw-bold">Curso de Acreditación</h5>
            <div className="row mb-4 mt-3">
              <div className="col-6">
                <Input
                  disabled
                  label="Fecha en la que se realizó el curso"
                  type="date"
                  id="dateOfBirth"
                  value={personalData.accreditation_course}
                  onChange={(e) => {
                    cleanError('accreditation_course')
                    setPersonalData({
                      ...personalData,
                      accreditation_course: e.target.value,
                    })
                  }}
                />
                {errorField.accreditation_course && (
                  <p className="text-danger fw-light">
                    {errorField.accreditation_course}
                  </p>
                )}
              </div>
            </div>
            <hr />
            <h5 className="fw-bold">Redes Sociales</h5>
            <div className="row mb-4 mt-3">
              <div className="col-12">
                <label htmlFor="facebook">Facebook</label>
                <input
                  type="text"
                  className="form-control"
                  id="facebook"
                  value={personalData.facebook}
                  onChange={(e) =>
                    setPersonalData({
                      ...personalData,
                      facebook: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label htmlFor="instagram">Instagram</label>
                <input
                  type="text"
                  className="form-control"
                  id="instagram"
                  value={personalData.instagram}
                  onChange={(e) =>
                    setPersonalData({
                      ...personalData,
                      instagram: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label htmlFor="twitter">Twitter</label>
                <input
                  type="text"
                  className="form-control"
                  id="twitter"
                  value={personalData.twitter}
                  onChange={(e) =>
                    setPersonalData({
                      ...personalData,
                      twitter: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label htmlFor="web">Web Personal</label>
                <input
                  type="text"
                  className="form-control"
                  id="web"
                  value={personalData.web}
                  onChange={(e) =>
                    setPersonalData({
                      ...personalData,
                      web: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <OdProfile profile={profile} requiredData={requiredData} />
        </div>
        <div className="row">
          <hr />
          <div className="d-flex mb-4 justify-content-end">
            <button
              disabled={loadingData ? true : false}
              className="btn btn-primary"
              onClick={handleRequest}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileDesktop
