import React from "react";

import { Line } from "react-chartjs-2";

const data = {
  labels: ["Semana 1", "Semana 2", "Semana 3", "Semana 4"],
  datasets: [
    {
      label: "Second dataset",
      data: [33, 25, 35, 51],
      fill: false,
      borderColor: "#00155C",
      borderWidth: 5,
      tension: 0.3,
    },
  ],
};

const options = {
  offsetGridLines: true,
  drawTicks: true,
  layout: {
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
    },
  },
  legend: {
    display: false,
  },
  tooltips: { display: false },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          padding: 5,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
};

export default function MontlyChart() {
  return (
    <div className="shadow p-3" style={{ borderRadius: "20px" }}>
      <div className="d-flex flex-row justify-content-between">
        <h3 className="fw-bold text-primary">Mes: </h3>
        <ul
          className="d-flex flex-row justify-content-end"
          style={{ listStyleType: "none" }}
        >
          <li className="mx-3">
            <a className="fw-bold" href="#">
              Ingresos
            </a>
          </li>
          <li className="mx-3">
            <a className="fw-bold" href="#">
              Reclamos
            </a>
          </li>
          <li className="mx-3">
            <a className="fw-bold" href="#">
              Consultas
            </a>
          </li>
          <li className="mx-3">
            <a className="fw-bold" href="#">
              Control
            </a>
          </li>
        </ul>
      </div>
      <Line data={data} options={options} />
    </div>
  );
}
