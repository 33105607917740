import React from 'react'

const TypeFilterComponent = ({ state, changeTypeHandler }) => {
  return (
    <ul
      className="d-flex align-items-center mb-0"
      style={{ listStyleType: 'none' }}
    >
      <li className="me-5">
        <div
          className={`text-primary cursor-pointer m-0 ${
            state.type === 1 && 'fw-bold'
          }`}
          onClick={() => changeTypeHandler(1)}
        >
          Todos
        </div>
      </li>
      {state.countryId === 1 && (
        <li className="me-4">
          <div
            className={` text-primary cursor-pointer border-0 m-0 ${
              state.type === 2 && 'fw-bold'
            }`}
            onClick={() => changeTypeHandler(2)}
          >
            SAO
          </div>
        </li>
      )}
      {state.countryId === 4 && (
        <li className="me-4">
          <div
            className={` text-primary cursor-pointer border-0 m-0 ${
              state.type === 3 && 'fw-bold'
            }`}
            onClick={() => changeTypeHandler(3)}
          >
            Pontificia Universidad Javeriana
          </div>
        </li>
      )}
    </ul>
  )
}

export default TypeFilterComponent
