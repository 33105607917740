import React, { useState } from 'react'
import ConfirmationModal from '../../../../components/confirmation_modal'
import ClinicHistoryModal from '../../../../components/MedicalRecordModal/ClinicHistoryModal'
import '../../buttons.css'
import '../../index.css'
import PropTypes from 'prop-types'
import moment from 'moment'
import handleToast from '../../../../components/toaster'
import apiClient from '../../../../utils/backend'
import { useSelector } from 'react-redux'

const ProposalControlCard = ({ data, getInitialData }) => {
  const { countryId } = useSelector((state) => state.userReducer)
  const [state, setState] = useState({
    modal_show: false,
    modal_id: data.id,
  })

  const principalDentist = data.dentists.filter(
    (dentist) => dentist.id === data.treatment.principal_dentist
  )
  const treatmentProposalDate = data?.created_at

  const handleModal = (id) => {
    if (id)
      return setState({ ...state, modal_show: !state.modal_show, modal_id: id })
    else
      return setState({
        ...state,
        modal_show: !state.modal_show,
      })
  }

  const handlePublish = async (id) => {
    handleToast('updating', 'Publicando tratamiento...')
    try {
      await apiClient.post(`api/proposals/${id}/publish`)
      getInitialData(countryId)
      handleToast('success', 'Tratamiento publicado correctamente!')
    } catch (error) {
      getInitialData(countryId)
      console.log('Error al borrar la propuesta')
      /* handleToast('error', 'Error al publicar el tratamiento') */
    }
  }

  return (
    <>
      {data && (
        <div className="patientCard d-flex align-items-center justify-content-between">
          <div
            className={`patientHistory type-${
              data.treatment?.type !== null ? data.treatment?.type?.id : 1
            }`}
            onClick={() =>
              setState({ ...state, modal_show: !state.modal_show })
            }
          >
            <ClinicHistoryModal
              id={data.treatment.id}
              opened={state.modal_show}
              handleModal={handleModal}
              isProposalControl={true}
            />
          </div>
          <div className="d-flex my-2 ms-4">
            <div className="idControl">
              <p
                className="m-0 text-truncate"
                title={data.treatment.external_id}
                style={{ minWidth: '50px' }}
              >
                {data.treatment.external_id}
              </p>
              <div className="avatar mx-1">
                <img
                  alt="ola"
                  src={data.treatment.frontal_sonriendo}
                  className="avatarIMG"
                />
              </div>
            </div>
            <div className="patientControl d-flex align-items-center">
              <p
                className="m-0 text-truncate"
                title={`${data.treatment.patient.name} ${data.treatment.patient.surname} `}
              >
                {`${data.treatment.patient.name} ${data.treatment.patient.surname} `}
              </p>
            </div>
            <div className="doctorControl d-flex align-items-center">
              <p
                className="m-0 text-truncate"
                title={`${principalDentist[0].user.name} ${principalDentist[0].user.surname}`}
              >
                {`${principalDentist[0].user.name} ${principalDentist[0].user.surname}`}
              </p>
            </div>
            <div className="treatmentControl d-flex align-items-center">
              <p
                className="m-0 text-truncate"
                title={data.treatment?.type?.name}
              >
                {data.treatment?.type?.name}
              </p>
            </div>
            <div className="stageControl d-flex align-items-center">
              <p className="m-0 text-truncate" title={data.treatment.order}>
                {data.treatment.order}
              </p>
            </div>
            <div className="maxillarControl d-flex align-items-center">
              <p
                className="m-0 text-truncate"
                title={`${
                  data.treatment.maxilla === null ? '-' : data.treatment.maxilla
                }`}
              >
                {`${
                  data.treatment.maxilla === null ? '-' : data.treatment.maxilla
                }`}
              </p>
            </div>
            <div className="movementsControl d-flex align-items-center">
              <p
                className="m-0 text-truncate"
                title={`${
                  data.treatment.movements === null
                    ? 0
                    : data.treatment.movements
                }`}
              >
                {`${
                  data.treatment.movements === null
                    ? 0
                    : data.treatment.movements
                }`}
              </p>
            </div>
            <div className="treatmentProposalControl d-flex align-items-center">
              <p
                className="m-0 text-truncate"
                title={moment(treatmentProposalDate).format('DD/MM/YYYY')}
              >
                {moment(treatmentProposalDate).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="d-flex align-content-center controlBTNS">
            <div
              className="seeBTNControl"
              onClick={() =>
                setState({ ...state, modal_show: !state.modal_show })
              }
            >
              <p className="m-0">Ver</p>
            </div>
            <ConfirmationModal
              type="publishProcess"
              data={data}
              proposalId={data.id}
              principalDentist={principalDentist}
              handlePublish={handlePublish}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ProposalControlCard

ProposalControlCard.propTypes = {
  data: PropTypes.object.isRequired,
  getInitialData: PropTypes.func.isRequired,
}
