import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import saologo from '../../../assets/images/sao_logo.png'
import sglogo from '../../../assets/images/study_group_logo.png'
import Danger from '../../../assets/images/danger_green.png'
import apiClient from '../../../utils/backend'
import 'react-toastify/dist/ReactToastify.css'
import handleToast from '../../../components/toaster'

const ActivateModal = (props) => {
  const { patients, active_treatments, name, groups, id, setState } = props

  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [opened, setOpened] = useState(false)

  function handleActivate() {
    if (!checked) return
    handleToast('updating', 'Activando Odontólogo...')
    apiClient.post(`/api/dentists/${id}/activate`)

    setTimeout(() => {
      apiClient
        .get(
          '/api/dentists?page=1&include=treatments,treatmentsCount,patientsCount,user.groups&filter[deactivated]=true'
        )
        .then((res) => {
          handleToast('success', 'Odontólogo activado !')
          setOpened(false)
          setState((prevState) => ({
            countryId: prevState.countryId,
            type: prevState.type,
            dentistArray: res.data.table.body,
            paginator: res.data.paginator,
            clearSearchBarInput: true,
          }))
        })
        .catch((err) => handleToast('error', 'Error al activar Odontólogo'))
    }, 2000)
  }

  useEffect(() => {
    if (opened === false) {
      setChecked(false)
    }
  }, [opened])

  return (
    <div>
      <label className="checkbox-google mt-2">
        <input
          type="checkbox"
          onClick={(e) => {
            setOpened(true)
            setChecked(e.target.checked)
          }}
          checked={checked}
        />
        <span className="checkbox-google-switch"></span>
      </label>
      <Modal isOpen={opened} size="md" toggle={() => setOpened(!opened)}>
        <ModalBody className="px-5 py-4">
          <div className="container d-flex flex-column">
            <div className="mx-auto">
              <img
                src={Danger}
                alt="imagen"
                className="mt-1"
                style={{ width: '80px' }}
              />
            </div>
            <h5 className="my-4 mx-auto fw-bold text-black fs-5-5">
              Está seguro que desea activar al Odontólogo/a
            </h5>
            <div className="row">
              <div className="col-4 d-flex flex-column">
                <small className="fw-light text-muted">
                  Nombre y Apellido:
                </small>
                <p
                  title={name}
                  className="mt-1 fs-6 text-black fw-bold text-truncate"
                >
                  {name}
                </p>
              </div>
              <div className="col-2 d-flex flex-column">
                <small className="fw-light text-muted">Perfil:</small>
                <div className="mt-1 fs-5">
                  {groups.length !== 0 ? (
                    groups.map((group) => (
                      <img
                        src={group.id === 1 ? saologo : sglogo}
                        alt={group.name}
                        className="me-1"
                        style={{ width: '25px' }}
                      />
                    ))
                  ) : (
                    <p className="fs-7 text-black fw-bold">Sin perfil</p>
                  )}
                </div>
              </div>
              <div className="col-2 d-flex flex-column">
                <small className="fw-light text-muted">Pacientes:</small>
                {!patients ? (
                  <p className="fs-7 fw-bold mt-1">No hay pacientes</p>
                ) : (
                  <p className="mt-1 fs-5  text-black">{patients}</p>
                )}
              </div>
              <div className="col-4 d-flex flex-column">
                <small className="fw-light text-muted">
                  Tratamientos activos:
                </small>
                {active_treatments ? (
                  <p className="mt-1 fs-5 text-black">{active_treatments}</p>
                ) : (
                  <p className="text-black fs-7 fw-bold mt-1">
                    {'No hay tratamientos'}
                  </p>
                )}
              </div>
            </div>
            <div className="row fs-7 text-center mt-2">
              <p>
                Se activaran todos los tratamientos y pacientes asignados a este
                odontólogo/a
              </p>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            {!loading && (
              <div className="d-flex flex-row">
                <div className="col-6">
                  <button
                    className="btn btn-outline-dark rounded-pill my-3 px-4"
                    onClick={() => setOpened(false)}
                  >
                    Volver
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-outline-success rounded-pill ms-3 my-3 px-4"
                    onClick={handleActivate}
                  >
                    Activar
                  </button>
                </div>
              </div>
            )}

            {loading && (
              <button class="btn btn-danger" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ActivateModal
