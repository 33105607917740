const pageSelector = (page, res, setState, query, queryType) => {
  if (page === 'patient') {
    setState((prevState) => ({
      type: prevState.type,
      countryId: prevState.countryId,
      patients: res.data.table.body,
      paginator: res.data.paginator,
      clearSearchBarInput: false,
      searchQuery: query,
    }))
  }

  if (page === 'notificationDentist') {
    setState({
      notifications: res.data.table,
      paginator: res.data.paginator,
      meta: res.data.meta,
      clearSearchBarInput: false,
      searchQuery: query,
    })
  }

  if (page === 'notificationAdmin') {
    setState({
      country: null,
      type: 0,
      meta: res.data.meta,
      notifications: res.data.table,
      paginator: res.data.paginator,
      clearSearchBarInput: false,
      searchQuery: query,
      currentFilterType: queryType,
    })
  }

  if (page === 'users') {
    setState({
      cards_type: 'list',
      step: null,
      loading: false,
      usersList: res.data,
      clearSearchBarInput: false,
      searchQuery: query,
    })
  }

  if (page === 'dentist') {
    setState((prevState) => ({
      ...prevState,
      dentistArray: res.data.table.body,
      paginator: res.data.paginator,
      clearSearchBarInput: false,
      searchQuery: query,
    }))
  }

  if (page === 'treatment') {
    setState((prevState) => ({
      ...prevState,
      treatments: res.data.table.body,
      paginator: res.data.paginator,
      clearSearchBarInput: false,
      searchQuery: query,
      currentFilterType: queryType,
    }))
  }
  if (page === 'referrals') {
    setState((prevState) => ({
      ...prevState,
      referralsList: res.data.table.body,
      paginator: res.data.paginator,
      clearSearchBarInput: false,
      searchQuery: query,
      currentFilterType: queryType,
    }))
  }

  if (page === 'adminClaimsIndex') {
    setState({
      filter: 0,
      searchQuery: query,
      searchType: queryType,
      claims: res.data.table.body,
      paginator: res.data.paginator,
      meta: res.data.meta,
    })
  }
  if (page === 'adminPaymentsIndex') {
    setState({
      searchQuery: query,
      searchType: queryType,
      payments: res.data.table.body,
      paginator: res.data.paginator,
    })
  }
  if (page === 'myPatients') {
    setState((prevState) => ({
      ...prevState,
      searchQuery: query,
      patients: res.data.table.body,
      paginator: res.data.paginator,
      meta: res.data.meta,
      clearSearchBarInput: false,
    }))
  }
  if (page === 'odHome') {
    setState({
      data: res.data.table.body,
      paginator: res.data.paginator,
      totalPatients: res.data.meta.total_patients,
      totalPendings: res.data.meta.total_pending_actions,
      clearSearchBarInput: false,
      searchQuery: query,
    })
  }
  if (page === 'qualityControl') {
    setState({
      treatments: res.data.table.body,
      paginator: res.data.paginator,
      currentFilterType: queryType,
      searchQuery: query,
    })
  }
  if (page === 'dispatchControl') {
    setState({
      treatments: res.data.table.body,
      paginator: res.data.paginator,
      currentFilterType: queryType,
      searchQuery: query,
    })
  }
}
export default pageSelector
