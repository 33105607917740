import apiClient from "../../utils/backend"

export const getPatient = (id) => {
    return async(dispatch) => {
        try {
            const res = await apiClient.get(`/api/patients/${id}`);  //${id}
            const data = {
                treatments: res.data.treatments,
                user: res.data.user
            }
            dispatch(setPatient(data));
        } catch (error) {
            console.log(error)
        }
    }
}

const setPatient = (patient) => ({ type:'GET_PATIENT', payload:patient });
