import { Fragment } from "react";
import {useHistory} from 'react-router';
import { connect } from "react-redux";
//import { Book, HelpCircle, Bookmark, PenTool, Monitor, User, Video } from 'react-feather'


function MainMenu(props){
    const history = useHistory();
    const { main_menu, goSubMenu } = props
    // const getIcon = (icon ) =>{
    //     if(icon.indexOf('.')>-1){
    //         const item_icon = require(`../../../assets/images/Centro de Ayuda/${icon}`).default
    //         return <img style={{width:'60px',height:'70px'}} 
    //         src={item_icon} alt={icon} />   
    //     }else{
    //         const icons = {
    //             book : Book,
    //             help: HelpCircle,
    //             bookmark: Bookmark,
    //             pentool: PenTool,
    //             video: Video,
    //             monitor: Monitor,
    //             user: User
    //         }
    //         let IconComponent = icons[icon]
    //         return <IconComponent style={{width:'70px',height:'70px'}} />
    //     }
    // }

    const handleRedirection = (item) => {
        switch(item.name) {
            case 'Master Alineadores':
                window.location = "http://masteralineadores.com"
                break;
            case 'Galeria de Casos':
                window.location = "/centrodeayuda/galeriacasos"
                break;
            case 'Presentá tu Caso':
                window.location = "/centrodeayuda/caso"
                break;
            default:
                goSubMenu(item)
        }
    }

    return (
        <Fragment>
            <div className="d-flex flex-row justify-content-end px-5">
                <button className="btn btn-outline-primary px-5 rounded-pill m-3" onClick={() => history.push('/centrodeayuda/admin')}>Nueva Noticia</button>
            </div>
            {
                main_menu?.map((item, key)=>
                <div className="w-100 d-flex justify-content-center grow_hover" key={key}>
                    <div onClick={()=>{handleRedirection(item)}} style={{cursor: 'pointer'}} className="w-90 d-flex flex-row bg-white shadow rounded m-0 px-10 py-3 my-2 align-items-center tc-container">
                        <div className='d-flex align-items-center justify-content-around w-60'>
                            <div className='d-flex align-items-center justify-content-center w-25'>
                                <img src={item.icon} alt="" style={{width:"5rem"}}/>
                            </div>
                            <div className='d-flex flex-row align-items-center w-75'>
                                <div className="d-flex flex-column bg-transparent">
                                    <p className="h3">{item.name}</p>
                                    <p className="small">{item.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center w-40'>
                            <button
                            className={`btn rounded-pill align-self-end mx-5`}
                            style={{color:'white',backgroundColor: '#89C5E8', width:'200px'}}
                            onClick={() => handleRedirection(item)}>
                                {item.name === 'Master Alineadores'?'Ir a la Página Oficial':'Ver'}
                            </button>
                            <button
                                className={`btn rounded-pill mt-3 align-self-end mx-5 border-danger text-danger`}
                                style={{width:'200px'}}
                                onClick={null}>
                                Eliminar
                            </button>
                        </div>
                    </div>
                </div>
                )
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(MainMenu);