import React from 'react'
import { Edit2 } from 'react-feather'

const EditButton = ({ btnText, onClickHandler }) => {
  return (
    <div
      onClick={() => onClickHandler()}
      className="d-flex align-items-center btn-edit pointer"
      style={{ border: '1px solid #5d8aa8', borderRadius: 10 }}
    >
      <Edit2 className="mx-3" style={{ color: '#5d8aa8', height: 20 }} />
      <p
        style={{ lineHeight: '100%', color: '#5d8aa8' }}
        className="my-0 me-3 fs-6"
      >
        {btnText}
      </p>
    </div>
  )
}

export default EditButton
