import React from 'react'
import useDocumentationAdmin from '../../hooks/useDocumentationAdmin'
import '../../styles/index.css'
import { photosInput } from './filesNames/photosInput'
import { studiesInput } from './filesNames/studiesInput'

//Components
import CHOriginalRegister from '../Utils/CHOriginalRegister'
import CHProposal from '../Utils/CHProposal'
import CHTreatmentPlan from '../Utils/CHTreatmentPlan'
import ImageInput from '../Utils/ImageInput'
import FilterSection from './components/FilterSection'
import Loader from '../../../loader'

const ClinicHistoryDocumentation = ({
  loadDataAdmin,
  updateData,
  data,
  documentationData,
  originalRegister,
  isApplication,
  isProposalControl,
  treatmentId,
}) => {
  const {
    filter,
    loading,
    backendFiles,
    setFilter,
    reCategorize,
    onDeleteImage,
    handleEditPhotos,
    handleUploadPhotos,
    handleUploadStudies,
  } = useDocumentationAdmin({
    loadDataAdmin,
    data,
    treatmentId,
    documentationData,
    isProposalControl,
  })

  if (!documentationData) return <Loader />
  return (
    <>
      <FilterSection
        propsToFilter={{
          isApplication,
          filter,
          originalRegister,
          data,
          setFilter,
        }}
      />

      <hr className="mx-4" />
      {filter === 'photos' && (
        <div className="d-flex flex-wrap p-2 mb-5">
          {photosInput.map(({ slug, label }) => {
            const hasImageUploaded = backendFiles.photos.find(
              ({ name }) => name === slug
            )
            return (
              <div className={loading ? 'mx-2 disabled' : 'mx-2'} key={slug}>
                <ImageInput
                  section={'photos'}
                  previewImage={hasImageUploaded?.thumb}
                  fullImage={hasImageUploaded?.path}
                  imageId={hasImageUploaded?.id}
                  src={slug}
                  name={label}
                  categories={photosInput}
                  onDeleteImage={onDeleteImage}
                  reCategorize={reCategorize}
                  handleUpload={handleUploadPhotos}
                  handleEditPhotos={handleEditPhotos}
                />
              </div>
            )
          })}
          {backendFiles.otherPhotos.map((item, i) => (
            <div className={loading ? 'mx-2 disabled' : 'mx-2'} key={item.id}>
              <ImageInput
                section={'others'}
                previewImage={item.thumb}
                fullImage={item.path}
                imageId={item.id}
                src={'otros'}
                name={'Otros - ' + (i + 1)}
                onDeleteImage={onDeleteImage}
                reCategorize={reCategorize}
                handleUpload={handleUploadPhotos}
                handleEditPhotos={handleEditPhotos}
              />
            </div>
          ))}
          {backendFiles.otherPhotos.length < 5 && (
            <div className={loading ? 'mx-2 disabled' : 'mx-2'}>
              <ImageInput
                section={'others'}
                src={'otros'}
                name={'Cargar Otros'}
                onDeleteImage={onDeleteImage}
                reCategorize={reCategorize}
                handleUpload={handleUploadPhotos}
                handleEditPhotos={handleEditPhotos}
              />
            </div>
          )}
        </div>
      )}

      {filter === 'studies' && (
        <div className="d-flex justify-content-center flex-wrap p-2 mb-5">
          {studiesInput.map(({ slug, label }) => {
            const hasImageUploaded = backendFiles.studies.find(
              ({ name }) => name === slug
            )

            return (
              <div className={loading ? 'mx-2 disabled' : 'mx-2'} key={slug}>
                <ImageInput
                  section={'studies'}
                  previewImage={hasImageUploaded?.thumb}
                  fullImage={hasImageUploaded?.path}
                  imageId={hasImageUploaded?.id}
                  src={slug}
                  name={label}
                  categories={studiesInput}
                  onDeleteImage={onDeleteImage}
                  reCategorize={reCategorize}
                  handleUpload={handleUploadStudies}
                  handleEditPhotos={handleEditPhotos}
                />
              </div>
            )
          })}
          {backendFiles.otherStudies.map((item, i) => (
            <div className={loading ? 'mx-2 disabled' : 'mx-2'} key={item.id}>
              <ImageInput
                section={'others'}
                previewImage={item.thumb}
                fullImage={item.path}
                imageId={item.id}
                src={'otros'}
                name={'Otros - ' + (i + 1)}
                onDeleteImage={onDeleteImage}
                reCategorize={reCategorize}
                handleUpload={handleUploadStudies}
                handleEditPhotos={handleEditPhotos}
              />
            </div>
          ))}
          {backendFiles.otherStudies.length < 5 && (
            <div className={loading ? 'mx-2 disabled' : 'mx-2'}>
              <ImageInput
                section={'others'}
                src={'otros'}
                name={'Cargar Otros'}
                onDeleteImage={onDeleteImage}
                reCategorize={reCategorize}
                handleUpload={handleUploadStudies}
                handleEditPhotos={handleEditPhotos}
              />
            </div>
          )}
        </div>
      )}
      {filter === 'plan' && (
        <CHTreatmentPlan
          handleEditPhotos={handleEditPhotos}
          data={data}
          backendFiles={backendFiles}
          documentationData={documentationData}
          loadDataAdmin={loadDataAdmin}
        />
      )}

      {filter === 'originalRegister' && (
        <CHOriginalRegister originalRegister={originalRegister} />
      )}

      {filter === 'proposal' && (
        <CHProposal proposalData={data.proposals} updateData={updateData} />
      )}
    </>
  )
}

export default ClinicHistoryDocumentation
