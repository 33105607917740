import React from 'react'

const SelectAllCheckBox = ({ styles, setAllPayments, isAllSelected }) => {
  return (
    <div className={styles.allFilter}>
      <input
        type="checkbox"
        onClick={() => setAllPayments()}
        checked={isAllSelected}
      />
    </div>
  )
}

export default SelectAllCheckBox
