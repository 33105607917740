import React from 'react'
import { useSelector } from 'react-redux'
import ProfileDesktop from './ProfileDesktop'
import ProfileMobile from './ProfileMobile'

const ProfileIndex = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)

  if (pageWidth === 'desktop') return <ProfileDesktop />
  else return <ProfileMobile />
}

export default ProfileIndex
