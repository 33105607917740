import React from 'react'

const FilterSection = ({ propsToFilter }) => {
  const { isApplication, filter, originalRegister, data, setFilter } =
    propsToFilter

  return (
    <div
      className={`d-flex ${
        isApplication ? 'justify-content-start' : 'justify-content-between'
      } w-75 flex-wrap ms-4`}
    >
      <p
        className={` ${
          isApplication ? 'mx-3 my-0' : ''
        } btnDocumentation text-primary ${
          filter === 'photos' ? 'fw-bold' : null
        }`}
        onClick={() => setFilter('photos')}
      >
        Fotos
      </p>
      <p
        className={` ${
          isApplication ? 'mx-3 my-0' : ''
        } btnDocumentation text-primary ${
          filter === 'studies' ? 'fw-bold' : null
        }`}
        onClick={() => setFilter('studies')}
      >
        Estudios
      </p>
      <p
        className={` ${
          isApplication ? 'mx-3 my-0' : ''
        } btnDocumentation text-primary ${
          filter === 'plan' ? 'fw-bold' : null
        }`}
        onClick={() => setFilter('plan')}
      >
        Plan de Tratamiento
      </p>
      {!isApplication && (
        <>
          <p
            className={`btnDocumentation text-primary ${
              filter === 'originalRegister' ? 'fw-bold' : null
            } ${originalRegister?.url ? null : 'disabled'}`}
            onClick={() => {
              if (originalRegister?.url) {
                setFilter('originalRegister')
              }
            }}
          >
            Registro Original
          </p>
          <p
            className={`btnDocumentation text-primary ${
              filter === 'proposal' ? 'fw-bold' : null
            }
             ${data?.proposals.length === 0 ? 'disabled' : null}
            `}
            onClick={() => {
              if (data?.proposals.length >= 1) {
                setFilter('proposal')
              }
            }}
          >
            Propuesta de Tratamiento
          </p>
          <p
            className={`btnDocumentation text-primary disabled ${
              filter === 'observations' ? 'fw-bold' : null
            }`}
            onClick={() => setFilter('observations')}
          >
            Observaciones
          </p>
        </>
      )}
    </div>
  )
}

export default FilterSection
