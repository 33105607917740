import React, { useState, useEffect } from 'react'
import Header from './components/StickyHeader'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from './propsStateConnect'
import { connect } from 'react-redux'
import Movements from './components/PretreatmentDiagnosis/Movements'
import Extractions from './components/PretreatmentDiagnosis/Extractions'
import VirtualThoots from './components/PretreatmentDiagnosis/VirtualThoots'
import Attachments from './components/PretreatmentDiagnosis/Attachments'
import MiddleLines from './components/TratmentInstructions/MiddleLines'
import OverjetOverbite from './components/TratmentInstructions/OverjetOverbite'
import CanineRelationship from './components/TratmentInstructions/CanineRelationship'
import MolarRelationship from './components/TratmentInstructions/MolarRelationship'
import CrossBite from './components/TratmentInstructions/CrossBite'
import Stripping from './components/TratmentInstructions/Stripping'
import Diastems from './components/TratmentInstructions/Diastems'
import Spaces from './components/TratmentInstructions/Spaces'
import SequentialDistalation from './components/TratmentInstructions/SequentialDistalation'
import AuxiliarResources from './components/TratmentInstructions/AuxiliarResources'
import GeneralInstructions from './components/TratmentInstructions/GeneralInstructions'
import apiClient from '../../utils/backend'

export function FormOnline(props) {
  type_dispatch.step = ''
  type_dispatch.field = ''
  const state_form = props.formOnlineReducer
  const { enable, setState } = props
  useEffect(() => {
    const getDataFormOnline = async () => {
      const data = await apiClient.get(
        `api/helper/getTreatmentOnlineForm/${props.treatment_id}`
      )
      setState(data.data)
    }
    if (props.treatment_id) {
      getDataFormOnline()
    }
  }, [props.treatment_id, setState])

  const get_colors = () => {
    console.log(state_form.Header.MainInformation.type)
    switch (state_form.Header.MainInformation.type) {
      case 1: //'full'
        return { color: '#001b70', alternative_color: '#fff' }
      case 2: //'Fast'
        return { color: '#288b7e', alternative_color: '#000' }
      case 3: //Medium
        return { color: '#5a468e', alternative_color: '#fff' }
      case 4: //'Kids'
        return { color: '#e47662', alternative_color: '#000' }
      case 5: //'Kids Fast'
        return { color: '#e8793a', alternative_color: '#fff' }
      case 6: //'Teens'
        return { color: '#f7c05b', alternative_color: '#fff' }
      default:
        return { color: '#288B7E', alternative_color: '#fff' }
    }
  }

  useEffect(() => {
    enable(false)
  }, [enable])
  return (
    <div className="d-flex justify-content-center mt-3">
      <div className="w-100 d-flex flex-column bg-white rounded shadow p-3">
        <div className="mt-3">
          <Header colors={get_colors()} />
          <div className="d-flex">
            <div className="col-2">
              <label className="fs-5 text-end me-3">
                Prioridad estética del paciente
              </label>
              <div className="d-flex justify-content-end">
                <div
                  className="line-horizontal-form-online me-3"
                  style={{ backgroundColor: get_colors().color }}
                ></div>
              </div>
            </div>
            <div className="col-10">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column col-12">
                  <label className="">
                    Indique a continuación la prioridad estética del paciente:
                  </label>
                  <textarea
                    value={state_form.AestheticPriority.General.description}
                    className="form-control"
                    disabled
                    name="observacion"
                    id="observacion"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2">
            <label className="fs-5 mt-2 text-end me-3">
              Diagnóstico previo al tratamiento
            </label>
            <div className="d-flex justify-content-end">
              <div
                className="line-horizontal-form-online me-3"
                style={{ backgroundColor: get_colors().color }}
              ></div>
            </div>
            <div className="d-flex mt-4">
              <div className="d-flex flex-column w-100">
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Movimientos
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Extracciones
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2 force-inline-text">
                    Dientes Virtuales
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex">
                  <span className="text-stepper col-9 text-end pe-2">
                    Attachments
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
              </div>
              <div
                className="full-vertical-line ms-xl-L1 ms-lg-L1"
                style={{ backgroundColor: get_colors().color }}
              ></div>
            </div>
            <label
              className="fs-5 text-end me-3"
              style={{ marginTop: '300px' }}
            >
              Indicaciones de tratamiento
            </label>
            <div className="d-flex justify-content-end">
              <div
                className="line-horizontal-form-online me-3"
                style={{ backgroundColor: get_colors().color }}
              ></div>
            </div>
            <div className="d-flex mt-4">
              <div className="d-flex flex-column w-100">
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Línea Media
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Overjet/Overbite
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Relación Canina
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Relación Molar
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Mordida Cruzada
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Apiñamiento
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Diastemas
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Espacios
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span
                    className="text-stepper col-9 text-end pe-2"
                    style={{ fontSize: '0.8em' }}
                  >
                    Distalación Secuencial
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex mb-2">
                  <span className="text-stepper col-9 text-end pe-2">
                    Recursos Auxiliares
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
                <div className="d-flex">
                  <span
                    className="text-stepper col-9 text-end pe-2"
                    style={{ fontSize: '0.75em' }}
                  >
                    Indicaciones Generales
                  </span>
                  <span
                    className="circle col-1"
                    style={{ backgroundColor: get_colors().color }}
                  ></span>
                </div>
              </div>
              <div
                className="full-vertical-line ms-xl-L1 ms-lg-L1"
                style={{ backgroundColor: get_colors().color }}
              ></div>
            </div>
          </div>
          <div className="col-10">
            <div className="mt-5"></div>
            <Movements colors={get_colors()} />
            <div className="mt-3"></div>
            <Extractions colors={get_colors()} />
            <div className="mt-3"></div>
            <VirtualThoots colors={get_colors()} />
            <div className="mt-3"></div>
            <Attachments colors={get_colors()} />
            <div className="mt-3"></div>
            <MiddleLines colors={get_colors()} />
            <div className="mt-3"></div>
            <OverjetOverbite colors={get_colors()} />
            <div className="mt-3"></div>
            <CanineRelationship colors={get_colors()} />
            <div className="mt-3"></div>
            <MolarRelationship colors={get_colors()} />
            <div className="mt-3"></div>
            <CrossBite colors={get_colors()} />
            <div className="mt-3"></div>
            <Stripping colors={get_colors()} />
            <div className="mt-3"></div>
            <Diastems colors={get_colors()} />
            <div className="mt-3"></div>
            <Spaces colors={get_colors()} />
            <div className="mt-3"></div>
            <SequentialDistalation colors={get_colors()} />
            <div className="mt-3"></div>
            <AuxiliarResources colors={get_colors()} />
            <div className="mt-3"></div>
            <GeneralInstructions colors={get_colors()} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(FormOnline)
