import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import styles from '../styles/referrals.module.css'
import referralAll from '../styles/referralAll.module.css'
import { X } from 'react-feather'
import { ReferralsAllCard } from '../components/ReferralsAllCard'
import { ReferralsModalDentistAddress } from '../components/ReferralsModalDentistAddress'
import { ReferralsModalDentists } from '../components/ReferralsModalDentists'
import { useReferralModal } from '../hooks/useReferralModal'

export const ReferralAllModal = ({
  referralsState,
  setReferralsState,
  setisAllSelected,
  getInitialReferrals,
}) => {
  const {
    handleModalOpen,
    handleSelectedDentist,
    handleSendReferrals,
    handleShowList,
    setReferralModalState,
    setQuery,
    setOpened,
    amountSelectedReferrals,
    referralModalState,
    query,
    showList,
    opened,
    showOfficesError,
    showSearchError,
  } = useReferralModal(referralsState, getInitialReferrals)

  return (
    <>
      {amountSelectedReferrals ? (
        <button
          className={`${styles.generateReferralsButton} border-0 px-3 py-2 mt-2 rounded-pill fw-bold`}
          onClick={() => setOpened(!opened)}
        >
          Generar Derivación ({`${amountSelectedReferrals}`})
        </button>
      ) : (
        <div className={styles.referralsButtonHidden}></div>
      )}
      <Modal
        style={{ marginTop: '150px' }}
        isOpen={opened}
        size="lg"
        toggle={() => handleModalOpen()}
      >
        <ModalBody>
          <div className="p-4">
            <div className="d-flex justify-content-between">
              <p className={`${referralAll.titleHeader} fs-4 fw-bold`}>
                Derivación
              </p>
              <X
                className={referralAll.closeBTN}
                onClick={() => handleModalOpen()}
                size={30}
              />
            </div>
            <ReferralsModalDentists
              setQuery={setQuery}
              handleShowList={handleShowList}
              handleSelectedDentist={handleSelectedDentist}
              showSearchError={showSearchError}
              query={query}
              showList={showList}
              dentists={referralModalState.dentists}
              selectedDentist={referralModalState.selectedDentist}
            />
            <ReferralsModalDentistAddress
              showOfficesError={showOfficesError}
              offices={referralModalState.offices}
              setReferralModalState={setReferralModalState}
            />
            <div className={referralAll.headerList}>
              <div className={referralAll.fullName}>Nombre y Apellido</div>
              <div className={referralAll.email}>Email</div>
              <div className={referralAll.phone}>Teléfono</div>
              <div className={referralAll.preferenceDate}>
                Días y horario de preferencia
              </div>
              <div className={referralAll.locality}>Zona/Localidad</div>
            </div>
            <div className={referralAll.cardsContainer}>
              {referralsState.referralsList.map((referral) => (
                <ReferralsAllCard
                  data={referral}
                  key={referral.id}
                  referralsState={referralsState}
                  setReferralsState={setReferralsState}
                  setisAllSelected={setisAllSelected}
                />
              ))}
            </div>
            <div className="d-flex justify-content-end mt-5">
              {amountSelectedReferrals ? (
                <button
                  className={referralAll.referralBTN}
                  onClick={() => handleSendReferrals()}
                >
                  Generar Derivación
                </button>
              ) : (
                <div className={styles.referralsButtonHidden}></div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
