import React, { useEffect, useState } from 'react'
import './index.css'
import apiClient from '../../../../utils/backend'
import throttle from '../../../../utils/throttle'
import NewPatientForm from '../components/NewPatientForm'
import CurrentPatientForm from '../components/CurrentPatientForm'
import { useSelector } from 'react-redux'
import { ChevronDown } from 'react-feather'

export const TreatmentAdminStepOne = ({ state, setState }) => {
  const user = useSelector((state) => state.userReducer)
  const [patients, setPatients] = useState([])
  const [showList, setShowList] = useState(false)
  const [query, setQuery] = useState(null)
  const [dentists, setDentists] = useState(null)
  const [selectedDentist, setSelectedDentist] = useState(null)

  const handleSelectedDentist = (dentist) => {
    setShowList(false)
    setState({
      ...state,
      is_new_patient: true,
      odontologo: dentist,
      dentist_id: dentist.user.id,
    })
    setSelectedDentist({
      id: dentist.user.id,
      patients: dentist.patients,
      fullname: dentist.user.full_name,
    })
  }

  useEffect(() => {
    if (!selectedDentist) return
    let patientsArray = []
    selectedDentist.patients.map((patient) =>
      patientsArray.push({
        ...patient,
        label: `${patient.name} ${patient.surname}`,
        value: patient.id,
      })
    )
    setPatients(patientsArray)
  }, [selectedDentist])

  useEffect(() => {
    const handleRequest = async (queryValue) => {
      if (queryValue === '') {
        setPatients([])
        setDentists([])
        setSelectedDentist(null)
        setState((prevState) => ({
          ...prevState,
          is_new_patient: true,
          odontologo: null,
          dentist_id: null,
        }))
        return
      }

      if (!queryValue || queryValue.length < 3) return
      try {
        const queryValueEncoded = encodeURIComponent(queryValue)
        const res = await apiClient.get(
          `/api/dentists?cant_pages=all&include=patients,patients.lastTreatment&filter[country_id]=${user.countryId}&filter[fullname]=${queryValueEncoded}`
        )
        let dentists = res.data.table.body.slice(0, 11)
        setDentists(dentists)
        setShowList(true)
      } catch (err) {
        console.log(err)
      }
    }

    throttle(handleRequest, query, 1000)
  }, [query, setState, user.countryId])

  return (
    <div style={{ maxWidth: '700px' }} className="container mt-6 mx-auto">
      <p className="fs-4 text-primary fw-bold mb-3">Odontologo</p>
      <form className="d-flex justify-content-between">
        <div className="col-6 pe-2 mb-2 p-relative">
          <div>
            <div className="d-flex align-items-center">
              <div className="col-10">
                <input
                  className="form-control my-0"
                  placeholder=" 🔍 Buscar..."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <ChevronDown
                className="col-2 mt-2 cursor-pointer"
                onClick={() => setShowList(!showList)}
              />
            </div>
            {showList && (
              <ul className="ulScroll shadow mt-2  p-absolute">
                {dentists?.map((dentist) => (
                  <li
                    style={{
                      backgroundColor:
                        dentist.user.id === selectedDentist?.id
                          ? 'rgb(220, 226, 255)'
                          : 'white',
                    }}
                    onClick={() => handleSelectedDentist(dentist)}
                    className="liScroll ps-2 pt-2"
                    key={dentist.user.id}
                  >
                    {dentist.user.name + ' ' + dentist.user.surname}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="col-6 mt-2 pt-1 ps-2">
          <input
            disabled={!patients.length ? true : false}
            checked={!state.is_new_patient}
            className="checkbox_input rounded mb-2"
            type="checkbox"
            id="new_patient"
            onClick={() => {
              setShowList(false)
              setState({
                ...state,
                is_new_patient: !state.is_new_patient,
                nombre: '',
                apellido: '',
                external_id: '',
                genero: '',
                maxilla: null,
                maxilarString: '',
                selected_patient: null,
                gender_select: null,
                etapa: 1,
                treatment_type: 1,
              })
            }}
          />

          <label className="ms-2" htmlFor="new_patient">
            Usar paciente existente
          </label>
          {selectedDentist && (
            <p className="ms-2" style={{ color: 'red' }}>
              {`${!patients.length ? 'No hay paciente existente' : ''}`}
            </p>
          )}
        </div>
      </form>

      {state.odontologo && (
        <h3 className="mt-4" style={{ fontWeight: 700, color: '#10456D' }}>
          {state.odontologo?.user?.gender === 'f' ? 'Dra.' : 'Dr.'}{' '}
          {state.odontologo?.user?.name + ' ' + state.odontologo?.user?.surname}
        </h3>
      )}

      {state.is_new_patient ? (
        <NewPatientForm state={state} setState={setState} />
      ) : (
        <CurrentPatientForm
          state={state}
          setState={setState}
          patients={patients}
        />
      )}
    </div>
  )
}
