import React from 'react'
import styles from './styles/viewerStyles.module.css'
import expand from '../assets/icons/expand.png'
import PropTypes from 'prop-types'

const ExpandViewer = ({ url,isOd = false }) => {
  return (
    <a
      href={url}
      target="_blank"
      className="d-flex justify-content-end w-100"
      style={{ height: '33px' }}
      title="Ver en pantalla completa"
    >
      <div className={`${isOd ? styles.expandBTNFatherOd : styles.expandBTNFather}`}>
        <img src={expand} className={`${styles.expandBTN}`} />
      </div>
    </a>
  )
}

export default ExpandViewer

ExpandViewer.propTypes = {
  url: PropTypes.string.isRequired,
}
