import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useHistory } from 'react-router-dom'
import './styles.css'

const DentistMedicalRecordPagination = ({ informationState, pageWidth }) => {
  const history = useHistory()

  return (
    <div className="d-flex justify-content-between pagination-container">
      {informationState.prev_stage_id ? (
        <div
          className="d-flex align-items-center pointer"
          onClick={() => {
            history.push(
              `/od/historia-clinica/${informationState.prev_stage_id}`
            )
            setTimeout(() => history.go(0), 200)
          }}
        >
          <ChevronLeft className="text-primary" />
          {pageWidth === 'desktop' && (
            <p className="my-0 text-primary fs-5">Etapa Anterior</p>
          )}
        </div>
      ) : (
        <p style={{ color: 'white' }}>.</p>
      )}
      {informationState.next_stage_id && (
        <div
          className="d-flex align-items-center pointer"
          onClick={() => {
            history.push(
              `/od/historia-clinica/${informationState.next_stage_id}`
            )
            setTimeout(() => history.go(0), 200)
          }}
        >
          {pageWidth === 'desktop' && (
            <p className="my-0 text-primary fs-5">Etapa Siguiente</p>
          )}

          <ChevronRight className="text-primary" />
        </div>
      )}
    </div>
  )
}

export default DentistMedicalRecordPagination
