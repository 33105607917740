import React from 'react'
import { useSelector } from 'react-redux'
import { DesktopSmileCreator } from './DesktopSmileCreator'
import { useBenefits } from './hooks/useBenefits'
import { MobileSmileCreator } from './MobileSmileCreator'

const SmileCreator = () => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const { loading, countryId, dentistProfile } = useBenefits()

  if (pageWidth === 'desktop')
    return (
      <DesktopSmileCreator
        loading={loading}
        countryId={countryId}
        dentistProfile={dentistProfile}
      />
    )
  else
    return (
      <MobileSmileCreator
        loading={loading}
        countryId={countryId}
        dentistProfile={dentistProfile}
      />
    )
}

export default SmileCreator
