import React from 'react'
import styles from '../styles/styles.module.css'
const DentistFiltersMobile = ({ handleFilter, filter, state, countryId }) => {
  return (
    <div className="d-flex flex-wrap">
      <p
        className={filter === 1 ? styles.active : styles.inActive}
        onClick={() => handleFilter(1)}
      >
        Todos ({state.meta.total_patients})
      </p>

      <p
        className={filter === 3 ? styles.active : styles.inActive}
        onClick={() => handleFilter(3)}
      >
        Acciones Requeridas ({state.meta.total_pending_actions})
      </p>

      {countryId !== 7 && (
        <p
          className={filter === 2 ? styles.active : styles.inActive}
          onClick={() => handleFilter(2)}
        >
          SAO ({state.meta.total_sao})
        </p>
      )}
    </div>
  )
}

export default DentistFiltersMobile
