import React from 'react'

import logo from '../../../assets/images/logo-ks.svg'

export default function index() {
    return (
        <div className="w-100 d-flex flex-row justify-content-start px-5" style={{backgroundColor: '#B4CDDA', height: '150px'}}>
            <div className="mt-4 mx-4">
                <img src={logo} alt="" style={{height: '50px'}} />
                <h2 className="fw-bold mt-2 text-white">Centro de Ayuda</h2>
            </div>
        </div>
    )
}
