import React from 'react'
import styles from '../styles/referrals.module.css'
import moment from 'moment'
import PropTypes from 'prop-types'
import { selectReferral } from '../utils/selectReferral'

export const ReferralsCard = ({
  referrals,
  referralsState,
  setReferralsState,
  setisAllSelected,
}) => {
  const {
    dentist,
    email,
    full_name,
    phone,
    preferred_time,
    status,
    location,
    referral_date,
    selected,
  } = referrals

  const getStatus = (status) => {
    if (status.toUpperCase() === 'PENDING') return 'PENDIENTE'
    return 'DERIVADO'
  }

  return (
    <div className={styles.referralCard}>
      {status === 'derived' ? (
        <input
          type="checkbox"
          name="referral"
          disabled
          className={styles.allFilter}
        />
      ) : (
        <input
          type="checkbox"
          name="referral"
          checked={selected}
          className={styles.allFilter}
          onChange={() =>
            selectReferral(
              referrals,
              setisAllSelected,
              setReferralsState,
              referralsState
            )
          }
        />
      )}
      <p className={styles.completeName} title={full_name}>
        {full_name}
      </p>
      <p className={styles.email} title={email}>
        {email}
      </p>
      <p className={styles.phone} title={phone}>
        {phone}
      </p>
      <p className={styles.preferenceDate} title={preferred_time}>
        {preferred_time}
      </p>
      <p className={styles.locality} title={location}>
        {location}
      </p>
      <p
        title={getStatus(status)}
        className={`${styles.state} ${
          status === 'pending' ? styles.pending : styles.derivated
        }`}
      >
        {getStatus(status)}
      </p>
      {dentist ? (
        <p
          title={`${dentist.user.name} ${dentist.user.surname}`}
          className={styles.od}
        >{`${dentist.user.name} ${dentist.user.surname}`}</p>
      ) : (
        <p className={styles.od}> Sin asignar </p>
      )}

      <p className={styles.referral}>
        {status === 'derived' && moment(referral_date).format('DD/MM/YYYY')}
      </p>
    </div>
  )
}
ReferralsCard.propTypes = {
  referrals: PropTypes.object.isRequired,
  referralsState: PropTypes.object.isRequired,
  setReferralsState: PropTypes.func.isRequired,
  setisAllSelected: PropTypes.func.isRequired,
}
