import React from 'react'

function Paciente({paciente}) {
    return (
        <div className="d-flex flex-column">
            <h5 className="fw-bold">Paciente:</h5>
            <img style={{width: 100, height: 120, border: "1px solid black", marginTop: 10}}
                 src="https://a.espncdn.com/combiner/i?img=/i/headshots/nba/players/full/1966.png"/>
            <span className="mt-3 fw-bold" style={{whiteSpace: 'nowrap'}}>{paciente.label}</span>
            <span className="mt-2">Full - 1era etapa</span>
        </div>
    )
}

export default Paciente