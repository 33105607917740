import React, { Component, Fragment } from "react";
import apiClient from "../../../utils/backend";
import { connect } from "react-redux";
import CaseCard from './components/case_card'

import Header from '../components/header_image'

import trescuartos_perfil from "../../../assets/images/34deperfilsonriendo.png";

class index extends Component {

    constructor(props){
        super(props)

        this.state = {
            cases: []
        }
    }

    componentDidMount(){
        apiClient.get(`/api/cases`, { withCredentials: true, headers: { Authorization: `Bearer ${this.props.state.userReducer.login_token}` } })
          .then(res => {
            this.setState({cases: res.data})
            if(res.status === 500 || res.status === 404) {
              this.setState({loading: false, error: {error: true, message: 'Error cargando la historia clinica de este tratamiento. Contáctese con el equipo de sistemas con el código 0x0500h'}})
            }
          })
          .catch(err => {console.log(err); this.setState({loading: false, error: {error: true, message: `Error cargando la historia clinica de este tratamiento. Contáctese con el equipo de sistemas con el código 0x0500h-${this.props.id}`}})})
    }

  render() {

    return (
        <Fragment>
            <div className="container-fluid p-0">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item"><a href="/ayuda/galeriacasos" className="text-muted">Galeria de casos</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Todos</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <div className="shadow container p-4" style={{borderRadius: '20px'}}>
            <div className="row px-4">
                <h1 className="fw-bold">Todos</h1>
            </div>
                <div className="row px-4">
                {this.state.cases.map(cases => <div className="col-3 p-1 my-2">
                                <CaseCard photo={cases.photo} id={cases.id} description={cases.description} />
                            </div>)}
                </div>
            </div>
            </div>
        </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
