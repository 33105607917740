import React from 'react'
import priceListChile1 from '../../../assets/images/KSPreciosChile1.jpeg'
import priceListChile2 from '../../../assets/images/KSPreciosChile2.jpeg'

const PriceListChile = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img
        src={priceListChile1}
        alt="Price List Chile"
        style={{ width: '67vw',marginBottom:'20px' }}
      />
      <img
        src={priceListChile2}
        alt="Price List Chile"
        style={{ width: '67vw' }}
      />
    </div>
  )
}

export default PriceListChile