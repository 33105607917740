const adminSidebar = [
  {
    logo: 'home',
    title: 'Home',
    active: false,
    link: '/admin/home',
  },
  {
    logo: 'income',
    title: 'Ingresos',
    active: true,
    link: '/ingresos',
  },
  {
    logo: 'treatment',
    title: 'Tratamientos',
    active: true,
    link: '/tratamientos',
  },
  {
    logo: 'patient',
    title: 'Pacientes',
    active: true,
    link: '/pacientes',
  },
  {
    logo: 'dentist',
    title: 'Odontólogos',
    active: true,
    link: '/odontologos',
  },

  {
    logo: 'process',
    title: 'Procesos',
    active: true,
    link: null,
    subItems: [
      {
        title: 'Escaneo Original',
        link: '/procesos/escaneo',
        active: true,
      },
      {
        title: 'Movimiento',
        link: '/procesos/movimiento',
        active: true,
      },
      {
        title: 'Propuesta de tratamiento',
        link: '/procesos/propuesta',
        active: true,
      },
      {
        title: 'Control de calidad',
        link: '/procesos/controlCalidad',
        active: true,
      },
      {
        title: 'Despacho',
        link: '/procesos/despacho',
        active: true,
      },
      {
        title: 'Validación de Pagos',
        link: '/procesos/validacionPagos',
        active: true,
      },
    ],
  },
  {
    logo: 'pay',
    title: 'Pagos',
    active: true,
    link: null,
    subItems: [
      {
        title: 'Cobranzas',
        link: '/pagos/cobranzas',
        active: false,
      },
      {
        title: 'Estado de pago',
        link: '/pagos/estado',
        active: false,
      },
      {
        title: 'Cuenta corriente',
        link: '/pagos/cuentacorriente',
        active: false,
      },
      {
        title: 'Listado de precios',
        link: '/pagos/lista-precios',
        active: true,
      },
      {
        title: 'Datos bancarios',
        link: '/bancos',
        active: true,
      },
    ],
  },
  {
    logo: 'claim',
    title: 'Reclamos',
    active: true,
    link: '/reclamos',
  },
  {
    logo: 'smile',
    title: 'Smile Creator',
    active: false,
    link: '/smileCreator',
  },
  {
    logo: 'user',
    title: 'Usuarios',
    active: true,
    link: '/admin/usuarios',
  },
  {
    logo: 'referral',
    title: 'Derivaciones',
    active: true,
    link: '/derivaciones',
  },
]

const dentistSidebar = [
  {
    logo: 'home',
    title: 'Home',
    active: true,
    link: '/od/home',
  },
  {
    logo: 'patient',
    title: 'Mis Pacientes',
    active: true,
    link: '/od/pacientes',
  },
  {
    logo: 'application',
    title: 'Nueva Solicitud',
    active: true,
    link: '/od/solicitud',
  },
  {
    logo: 'consult',
    title: 'Consultas',
    active: true,
    link: '/od/consultas',
  },

  {
    logo: 'smile',
    title: 'Smile Creator',
    active: true,
    link: '/od/smileCreator',
  },
  {
    logo: 'group',
    title: 'Study Group',
    active: false,
    link: '/od/study-group',
  },
  {
    logo: 'pay',
    title: 'Pagos',
    active: true,
    link: 'null',
    subItems: [
      {
        title: 'Estado Pagos',
        link: '/pagos/estado-pagos',
        active: false,
      },
      {
        title: 'Listado Precios',
        link: '/pagos/lista-precios',
        active: true,
      },
      {
        title: 'Datos Bancarios',
        link: '/bancos',
        active: true,
      },
    ],
  },
  {
    logo: 'help',
    title: 'Centro de Ayuda',
    active: true,
    link: 'null',
    subItems: [
      {
        title: 'Master de Alineadores',
        link: 'https://masteralineadores.com/',
        active: true,
      },
      {
        title: 'Guia de Ayuda',
        link: 'https://keepsmilinghelp.zendesk.com/hc/es-419',
        active: true,
      },
    ],
  },
  {
    logo: 'legal',
    title: 'Legal',
    active: true,
    link: '/od/legal',
  },
]

export { dentistSidebar, adminSidebar }
