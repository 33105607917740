import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {Download} from 'react-feather'
//import { Book, HelpCircle, Bookmark, PenTool, Monitor, User, Video } from 'react-feather'

function FilesCentroDeAyuda(props){
    const { files_data, parent } = props
    console.log(files_data)
    return (
        <Fragment>
            <div className="w-100 d-flex justify-content-center py-5">
                <div className='d-flex align-items-center w-90'>
                    {/* {getIcon(parent()?.icon) &&
                <div className='d-flex align-items-center justify-content-center w-10'>
                    {getIcon(parent()?.icon)}
                </div>
                } */}
                    <div className='d-flex flex-row align-items-center'>
                        <div className="d-flex flex-column bg-transparent">
                            <p className={`h1 bold`}>{parent.name}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100 d-flex flex-column align-items-center">
                {
                    files_data.files.map(file => {
                        return (
                            <div style={{height: 80, borderRadius: 20}} className="p-5 w-75 d-flex flex-row bg-white shadow m-0 px-10 py-3 my-2 align-items-center tc-container">
                                <div className="col-6">
                                    <p style={{fontSize: 20, fontWeight: 'bold'}}>{file.name}</p>
                                </div>
                                <div className="col-6 d-flex flex-row justify-content-end">
                                    <button className="btn btn-light"><a href={file.path} target="_blank" download className="textprimary"><Download /></a></button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(FilesCentroDeAyuda);