import React from 'react'
import styles from '../styles/stepTwo.module.css'

const StepThree = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className={styles.mainContainer}>
        <div className={styles.textContainer}>
          <p className={styles.title}>Segundo paso:</p>
          <p className={styles.text}>
            Leer y aceptar los términos y condiciones de la empresa.
          </p>
          <p className={styles.text}>
            Para esto tiene que ir hacia el ítem de "Legal" en el sidebar. Aquí
            podrá leer los términos y condiciones y las políticas de privacidad
            de KeepSmiling.
          </p>
          <p className={styles.text}>
            Puede aceptar estos documentos haciendo click en "Leí y me muestro
            de acuerdo con los términos y condiciones".
          </p>
        </div>
        <div className={styles.videoSquare} />
      </div>
      <div className={styles.footerStep3}>
        <div className={styles.nextButton} onClick={() => setStep(4)}>
          Siguiente
        </div>
      </div>
    </div>
  )
}

export default StepThree
