export const fiscalConditionOptions = [
  '',
  'Monotributista',
  'Responsable Inscripto',
  'Excento',
  'No Alcanzado',
  'Personal Natural',
  'Persona Jurídica',
  'General de Ley de personas Morales',
  'Personas Morales con fines no Lucrativos',
  'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  'Arrendamiento',
  'Demás Ingresos',
  'Consilidación',
  'Residentes en el Extranjero sin Establecimiento Permanente en México',
  'Ingresos por Dividendos (Socios y accionistas)',
  'Personas Físicas con Actividades Empresariales y Profesionales',
  'Ingresos por intereses',
  'Sin obligaciones Fiscales',
  'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
  'Incorporación Fiscal',
  'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  'Opcional para Grupos de Sociedades',
  'Coordinados',
  'Hidrocarburos',
  'Régimen de Enajenación o Adquisición de Bienes',
  'De los Régimenes Fiscales Preferentes y de las Empresas Multinacionales',
  'Enajenación de acciones en bolsa de valores',
  'Régimen de los ingresos por obtención de premios',
  'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  'Régimen Simplificado de Confianza',
]
