import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import apiClient from '../../../utils/backend'

export const useTreatmentIndex = () => {
  const user = useSelector((state) => state.userReducer)
  const [state, setState] = useState({ loading: true })

  const getUrl = (countryId, type = 1, page = 1) => {
    console.log(countryId)
    let url = `/api/treatments?page=${page}&include=dentists,patient.user.groups&filter[patient.user.country.id]=${countryId}`
    if (type === 2) url = url + `&filter[patient.user.groups.id]=${type}`
    if (type === 3) url = url + `&filter[patient.user.groups.id]=${type}`

    return url
  }

  const setInitialData = async (userCountry) => {
    apiClient
      .get(getUrl(userCountry))
      .then((res) => {
        setState({
          type: 1,
          countryId: userCountry,
          treatments: res.data.table.body,
          paginator: res.data.paginator,
          clearSearchBarInput: true,
        })
      })
      .catch((err) => console.log(err))
  }

  const getPaginatorData = async (page) => {
    let path = getUrl(state.countryId, state.type, page)
    if (state.searchQuery)
      path = path + `&filter[${state.currentFilterType}]=${state.searchQuery}`

    try {
      const res = await apiClient.get(path)
      setState((prevState) => ({
        ...prevState,
        treatments: res.data.table.body,
        paginator: res.data.paginator,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const changeCountryHandler = async (countryId) => {
    try {
      const res = await apiClient.get(getUrl(countryId))
      setState({
        type: 1,
        countryId: countryId,
        treatments: res.data.table.body,
        paginator: res.data.paginator,
        clearSearchBarInput: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const changeTypeHandler = async (newType) => {
    try {
      const res = await apiClient.get(getUrl(state.countryId, newType))
      setState((prevState) => ({
        type: newType,
        countryId: prevState.countryId,
        treatments: res.data.table.body,
        paginator: res.data.paginator,
        clearSearchBarInput: true,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setInitialData(user.countryId)
  }, [user.countryId])

  useEffect(() => {
    if (!state.loadTodosFilter) return
    apiClient
      .get(getUrl(state.countryId, state.type))
      .then((res) => {
        setState((prevState) => ({
          type: prevState.type,
          countryId: prevState.countryId,
          treatments: res.data.table.body,
          paginator: res.data.paginator,
        }))
      })
      .catch((err) => console.log(err))
  }, [state])

  return {
    state,
    setState,
    setInitialData,
    changeTypeHandler,
    changeCountryHandler,
    getPaginatorData,
    getUrl,
  }
}
