import { useState } from 'react'
import PropTypes from 'prop-types'

const useClaimCard = (data) => {
  const [state, setState] = useState({ modal_show: false, modal_id: data.id })
  const handleModal = (id) => {
    if (id)
      return setState({ ...state, modal_show: !state.modal_show, modal_id: id })
    else
      return setState({
        ...state,
        modal_show: !state.modal_show,
      })
  }

  const handleSetRequiredData = (pendings, flags) => {
    const { studies, photos, plan } = flags
    if (pendings.length > 0) {
      return `Aprobación de tratamiento`
    } else if (!studies || !photos || !plan) {
      return `Completar documentación`
    } else return `-`
  }

  const handleCantClaimByAction = (
    pendings,
    countPendings,
    flags,
    countFlags
  ) => {
    const { studies, photos, plan } = flags
    if (pendings.length > 0) {
      return countPendings
    }
    if (!studies || !photos || !plan) {
      return countFlags
    }
  }

  return {
    handleModal,
    handleSetRequiredData,
    handleCantClaimByAction,
    setState,
    state,
  }
}

export default useClaimCard

useClaimCard.propTypes = {
  data: PropTypes.object.isRequired,
}
