import React, { useEffect } from 'react'
import { virtual_tooths_options } from '../Utilities/OptionsRadios'
import RadioList from '../Utilities/RadioList'
import {
  type_dispatch,
  mapStateToProps,
  mapDispatchToProps,
} from '../../propsStateConnect'
import { connect } from 'react-redux'
import TextAreaCounter from '../Utilities/TextAreaCounter'
function VirtualTooths(props) {
  type_dispatch.step = 'PretratmentDiagnosisValues'
  type_dispatch.field = 'VirtualTooths'
  const { colors, updateState, updateErrors } = props
  const virtualTooth_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]

  const extractions_state =
    props.formOnlineReducer[type_dispatch.step][type_dispatch.field]
  const errors_list = props.formOnlineReducer.ErrorsList
  const error1 = errors_list.find((err) => err.order === 1)
  const error2 = errors_list.find((err) => err.order === 2)
  useEffect(() => {
    if (extractions_state.virtualTooth) {
      updateErrors([])
    }
  }, [extractions_state.virtualTooth, updateErrors])

  return (
    <div>
      <div
        className="w-100 fw-bold pb-1"
        style={{ borderBottom: `1px solid ${colors.color}` }}
      >
        Dientes Virtuales
      </div>
      {error1 && (
        <div className="alert-danger alert-link p-2 mt-1">{error1.message}</div>
      )}
      <RadioList
        enabled={props.formOnlineReducer.Enabled}
        colors={colors}
        radios={virtual_tooths_options}
        setter={(value) => updateState({ key: 'virtualTooth', value })}
        stateField={virtualTooth_state.virtualTooth}
        name="virtualTooth"
      />
      {virtualTooth_state.virtualTooth === 1 && (
        <div className="row">
          <div className="fw-bolder small mt-2">
            Puede detallar a continuación:
          </div>
          <div className="small text-muted mt-2">
            (Recuerde indicar la pieza a la que hace referencia el detalle)
          </div>
          <div className="mt-2">
            <textarea
              disabled={!props.formOnlineReducer.Enabled}
              onChange={(e) => {
                updateState({ key: 'description', value: e.target.value })
              }}
              value={virtualTooth_state.description}
              className="form-control"
              name="observacion"
              id="observacion"
              cols="30"
              rows="7"
            ></textarea>
            <TextAreaCounter
              value={virtualTooth_state.description}
              maxValue={2500}
              error={error2}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(VirtualTooths)
