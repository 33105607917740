import React, { Component } from "react";
import apiClient from "../../utils/backend";
import moment from "moment";
import { connect } from "react-redux";
import { CheckCircle, XCircle } from "react-feather";
import "moment/locale/es";

// COMPONENTS
import Card from "./components/card";
import PrintModal from "./components/print_modal";
import { Tomar, Borrar, OK, Cargar, Finalizar } from "./components/buttons";
import CardPlatesRepeat from "./components/CardPlatesRepeat";
import HeadersPlatesRepeat from "./components/HeadersPlatesRepeat";
import CardLabRepeat from "./components/CardLabRepeat";
import HeadersLabRepeat from "./components/HeadersLabRepeat";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: {
        head: [],
      },
      title: "",
      cards: [],
      loading: true,
      country: "",
      country_filter: false,
      isopened: false,
    };

    this.handleModal = this.handleModal.bind(this);
    this.handleButtonType = this.handleButtonType.bind(this);
  }

  componentDidMount() {
    const path = window.location.pathname.split("/")[2];
    moment.locale("es");

    apiClient
      .get(`/api/processes/Impresion`)
      .then((res) => {
        let total_prints =
          res.data.print_cases_inprocess +
          res.data.print_cases_pending +
          res.data.printed_cases;
        let width_printed_cases = 0;
        let width_print_cases_pending = 0;
        let width_print_cases_inprocess = 0;
        if (total_prints > 0) {
          width_printed_cases = (res.data.printed_cases / total_prints) * 100;
          width_print_cases_pending =
            (res.data.print_cases_pending / total_prints) * 100;
          width_print_cases_inprocess =
            (res.data.print_cases_inprocess / total_prints) * 100;
        } else {
          width_printed_cases = 33;
          width_print_cases_pending = 33;
          width_print_cases_inprocess = 33;
        }
        width_printed_cases = width_printed_cases + "%";
        width_print_cases_pending = width_print_cases_pending + "%";
        width_print_cases_inprocess = width_print_cases_inprocess + "%";
        this.setState({
          table: res.data.table,
          title: res.data.title,
          country_filter: res.data.country_filter,
          country: res.data.user_country,
          cards: res.data.card,
          print_cases_inprocess: res.data.print_cases_inprocess,
          print_cases_pending: res.data.print_cases_pending,
          printed_cases: res.data.printed_cases,
          width_printed_cases,
          width_print_cases_pending,
          width_print_cases_inprocess,
          type: "asignado",
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  handleButtonIcon(icon, color, value) {
    const path = window.location.pathname.split("/")[2];
    console.log(icon, color, value);

    if (path === "aprobacion") {
      let components = {
        CheckCircle: CheckCircle,
        X: XCircle,
      };

      let IconComponent = components[icon];

      return <IconComponent color={color} />;
    } else {
      let components = {
        Tomar: Tomar,
        Finalizar: Finalizar,
        Borrar: Borrar,
        Cargar: Cargar,
        OK: OK,
      };

      let IconComponent = components[icon];

      return <IconComponent color={color} value={value ? value : null} />;
    }
  }

  handleModal(modal) {
    console.log("handling modal, ", modal);
    this.setState({ modal_show: modal });
  }

  handleButtonType({ button, id }) {
    const path = window.location.pathname.split("/")[2];

    if (button.type === "modal") {
      this.handleModal(button.modal_id + "-" + id);
    } else {
      console.log(button.path, id);
      apiClient
        .post(`${button.path}`, { treatment_id: id, process: path })
        .then((res) =>
          res.status === 200 ? window.location.reload() : console.log(res)
        )
        .catch((err) => console.log(err));
    }
  }

  render() {
    moment.locale();
    const dia = moment().format("dddd");
    const fecha = moment().format("LL");

    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-3 mt-2">
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontWeight: 700 }} className="text-primary">
                Cola de impresión
              </h1>
            </div>
          </div>
          <div className="row mt-5">
            {this.state.country_filter && (
              <div className="col-12">
                <ul
                  className="d-flex flex-row justify-content-start p-0"
                  style={{ listStyleType: "none" }}
                >
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className={
                        this.state.type === "asignado"
                          ? "text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                          : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                      }
                      href="#"
                      onClick={() => this.setState({ type: "asignado" })}
                    >
                      Asignados
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className={
                        this.state.type === "falta_asignar"
                          ? "text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                          : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                      }
                      href="#"
                      onClick={() => this.setState({ type: "falta_asignar" })}
                    >
                      Falta asignar fecha
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className={
                        this.state.type === "imprimir"
                          ? "text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                          : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                      }
                      href="#"
                      onClick={() => this.setState({ type: "imprimir" })}
                    >
                      Placas de repeticion
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className={
                        this.state.type === "repeticiones_laboratorio"
                          ? "text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                          : "text-primary cursor-pointer fs-6 btn border-0 m-0"
                      }
                      href="#"
                      onClick={() =>
                        this.setState({ type: "repeticiones_laboratorio" })
                      }
                    >
                      Repeticiones laboratorio
                    </a>
                  </li>
                  <li style={{ marginRight: "15px", marginLeft: "auto" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0"
                      href="#"
                      onClick={() => this.setState({ type: "resumen_semanal" })}
                    >
                      Resumen semanal
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <div className="col-12">
              <input
                type="text"
                className="w-100 border-0 p-3"
                placeholder="Escribe al menos 3 caracteres..."
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="row px-4 mt-4 bg-white border border-3 p-1">
            <div className="col-3">
              <h3 className="fw-bold fs-5">{dia + ", " + fecha}</h3>
              <p className="mt-2 fw-normal fs-5">
                Cantidad de placas asignadas:{" "}
                <span className="text-danger fs-5 fw-bold">723</span>/700
              </p>
            </div>
            <div className="col-8 d-flex flex-column">
              <div className="d-flex flex-row flex-nowrap justify-content-around my-2">
                <div className="d-flex flex-row align-items-center">
                  <div
                    className="bg-success"
                    style={{ width: "10px", height: "10px" }}
                  ></div>
                  Casos impresos
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div
                    className="bg-warning"
                    style={{ width: "10px", height: "10px" }}
                  ></div>
                  Casos en proceso de impresión
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div
                    className="bg-dark"
                    style={{ width: "10px", height: "10px" }}
                  ></div>
                  Casos pendientes de impresión
                </div>
              </div>
              <div
                className="d-flex flex-row flex-nowrap"
                style={{ height: "3ch" }}
              >
                <div
                  className="bg-success text-white d-flex align-items-center justify-content-end px-2"
                  style={{
                    borderTopLeftRadius: "50rem",
                    borderBottomLeftRadius: "50rem",
                    zIndex: 3,
                    minWidth: "5%",
                    width: this.state.width_printed_cases,
                  }}
                >
                  {this.state.printed_cases}
                </div>
                <div
                  className="bg-warning text-white d-flex align-items-center justify-content-end px-2"
                  style={{
                    zIndex: 2,
                    minWidth: "5%",
                    width: this.state.width_print_cases_inprocess,
                  }}
                >
                  {this.state.print_cases_inprocess}
                </div>
                <div
                  className="bg-dark text-white d-flex align-items-center justify-content-end px-2"
                  style={{
                    borderTopRightRadius: "50rem",
                    borderBottomRightRadius: "50rem",
                    minWidth: "5%",
                    width: this.state.width_print_cases_pending,
                  }}
                >
                  {this.state.print_cases_pending}
                </div>
              </div>
            </div>
            <div className="col-1 d-flex align-items-start">
              <div className="d-flex flex-column">
                <div style={{ textAlign: "center" }}>Total de casos:</div>
                <div style={{ textAlign: "center" }}>
                  {this.state.print_cases_pending +
                    this.state.print_cases_inprocess +
                    this.state.printed_cases}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {this.state.type === "imprimir" && <HeadersPlatesRepeat />}
            {this.state.type === "repeticiones_laboratorio" && (
              <HeadersLabRepeat />
            )}
            {this.state.type !== "imprimir" &&
              this.state.type !== "repeticiones_laboratorio" &&
              this.state.table.head
                ?.filter((item) => item !== "Placas")
                ?.map((header) => (
                  <div
                    className={`${
                      header === "ID"
                        ? "col-half"
                        : header === "Paciente" || header === "Doctor"
                        ? "col-2"
                        : "col-1"
                    } d-flex flex-row justify-content-center`}
                  >
                    <p className="text-primary fw-bold">{header}</p>
                  </div>
                ))}
          </div>
          {this.state.table.body[this.state.type][this.state.country].map(
            (item) => (
              <div>
                {this.state.type === "imprimir" && (
                  <CardPlatesRepeat
                    item={item}
                    handleModal={this.handleModal}
                  />
                )}
                {this.state.type === "repeticiones_laboratorio" && (
                  <CardLabRepeat item={item} handleModal={this.handleModal} />
                )}
                {this.state.type !== "imprimir" &&
                  this.state.type !== "repeticiones_laboratorio" && (
                    <Card item={item} handleModal={this.handleModal} />
                  )}
                <PrintModal
                  type={this.state.type}
                  item={item}
                  opened={this.state.modal_show}
                  className={`modal_impresion-${item.id}`}
                  handleModal={this.handleModal}
                />
              </div>
            )
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
