import React from 'react'
import { AlertCircle, HelpCircle } from 'react-feather'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import frontalSonriendo from '../../../../assets/images/frontalsonriendo.png'
const CurrentPatientForm = ({ state, setState, patients }) => {
  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      borderBottom: '2px solid #0027a8!important',
    }),
  }
  const maxillaOptions = [
    { label: 'Superior', value: 2 },
    { label: 'Inferior', value: 1 },
    { label: 'Ambos', value: 3 },
  ]

  const showMaxillaAlert = () => {
    if (!state.selected_patient || !state.maxilla) return
    if (state.selected_patient.maxilarNumber !== state.maxilla.value)
      return (
        <div className="col-12 d-flex justify-content-end">
          <div className="alert alert-danger d-flex p-2">
            <span>
              <AlertCircle size={15} />
            </span>
            <p className="fs-6 ms-1">
              Recuerde que si cambia el
              <br /> maxilar a tratar, esta indicando
              <br /> que el paciente modifica en
              <br /> esta etapa el maxilar que trato
              <br /> en su etapa anterior.
            </p>
          </div>
        </div>
      )
  }

  const imageSource = () => {
    if (state.selected_patient?.last_treatment?.frontal_sonriendo)
      return state.selected_patient?.last_treatment?.frontal_sonriendo
    return frontalSonriendo
  }

  return (
    <>
      <form className="row mt-4">
        <div className="col-4 pe-2">
          <p>Id Externo</p>
          <input
            maxLength={15}
            type="text"
            className="form-control"
            value={state.external_id}
            onChange={(e) =>
              setState({ ...state, external_id: e.target.value })
            }
          />
        </div>
        <div className="col-8 ps-2">
          <p>Nombre y Apellido</p>

          <Select
            styles={selectStyle}
            options={patients}
            placeholder="Seleccionar Paciente"
            value={state.selected_patient}
            onChange={(e) => {
              console.log(e)
              setState({
                ...state,
                selected_patient: e,
                nombre: e.name,
                apellido: e.surname,
                genero: e.gender,
                etapa: e.last_treatment?.order + 1,
                treatment_type: e.last_treatment?.type?.id,
                maxilla: {
                  label: e.last_treatment.maxilla,
                  value: e.last_treatment.maxilla_number,
                },
                maxilar: Number(e.last_treatment.maxilla_number),
              })
            }}
          />
        </div>
      </form>
      {state.selected_patient && (
        <div className="row ps-3 ">
          <div className="col-4" />
          <div className="border w-full col-8 income-patient-card d-flex align-items-center shadow">
            <img
              className="income-img-card "
              src={imageSource()}
              alt="patient"
            />
            <p className="my-0 text-primary fw-bold fs-5 ps-4">{`${state.selected_patient.surname} ${state.selected_patient.name}`}</p>
          </div>
        </div>
      )}
      <form className="row">
        <div className="col-12 mb-2 d-flex">
          <h4 className="me-2">Tratamiento</h4>
          <div data-tip data-for="treatment_tooltip">
            <HelpCircle size={22} className="mt-1" />
          </div>
          {/* LOS TOOLTIP SOLO DEBEN MOSTRARSE COMO ODONTOLOGO */}
          <ReactTooltip id="treatment_tooltip">
            <div className="col-6 d-flex flex-row">
              <p className="fs-3 fw-bold">?</p>
              <p className="mt-2 ms-1">
                Ayuda <span className="fw-bold">KeepSmiling</span>
              </p>
            </div>
            <div>
              <p>Tipo de Tratamiento</p>
              <p>
                Si tiene dudas del tipo de tratamiento que requiere su paciente,
                <br /> entonces debe, primero, enviar una solicitud de
                Viabilidad de
                <br /> tratamiento. En la cual, el equipo de KeepSmiling
                evaluara la
                <br /> documentacion de su paciente y le aconsejara que tipo de
                <br />
                tratamiento corresponde al caso de su paciente.
              </p>
            </div>
          </ReactTooltip>
        </div>
        {!state.treatment_type && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-warning text-white active'
              }
            >
              Sin tipo de tratamiento
            </div>
          </div>
        )}

        {state.treatment_type === 1 && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-primary active'
              }
            >
              KeepSmiling Full
            </div>
          </div>
        )}
        {state.treatment_type === 4 && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-warning active'
              }
            >
              KeepSmiling Kids
            </div>
          </div>
        )}
        {state.treatment_type === 2 && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-secondary active'
              }
            >
              KeepSmiling Fast
            </div>
          </div>
        )}
        {state.treatment_type === 5 && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-danger active'
              }
            >
              KeepSmiling Kids Fast
            </div>
          </div>
        )}
        {state.treatment_type === 3 && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-info active'
              }
            >
              KeepSmiling Medium
            </div>
          </div>
        )}
        {state.treatment_type === 6 && (
          <div className="col-6 mt-2">
            <div
              className={
                'btn w-100 my-2 rounded-pill scanButton btn-outline-yellow active'
              }
            >
              KeepSmiling Teen
            </div>
          </div>
        )}
        <div className="col-6">
          <form className="d-flex col-12">
            <div className="col-3 ps-3">
              <label htmlFor="etapa">Etapa</label>
              <select
                value={state.etapa}
                disabled
                name="etapa"
                id="etapa"
                className="form-control"
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </select>
            </div>
            <div className="col-8 ms-3">
              <label htmlFor="maxilar">Maxilar</label>
              <Select
                styles={selectStyle}
                options={maxillaOptions}
                isSearchable
                value={state.maxilla}
                placeholder="Seleccionar Maxilar.."
                onChange={(e) =>
                  setState({
                    ...state,
                    maxilla: e,
                    maxilar_string: e.label,
                    maxilar: Number(e.value),
                  })
                }
              />
            </div>
          </form>
          {showMaxillaAlert()}
        </div>
      </form>
    </>
  )
}

export default CurrentPatientForm
