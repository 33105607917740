import React from 'react'
import { TeethSelector } from '../../../Utilities/TeethSelector'
import '../../../style.css'

const Attachments = ({ formData }) => {
  return (
    <div className="steps-diagnosis-container">
      <p className="fw-bold m-0 ms-1">Attachments</p>
      <hr className="mt-2" />
      <div className="d-flex flex-column">
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="attachments"
            disabled
            checked={formData.attachment == 1 && true}
          />
          <label className="ms-2">
            Estoy de acuerdo con la colocación de attachment de ser necesario
          </label>
        </div>
        <div>
          <input
            type="radio"
            className="mt-2 inputColor"
            name="attachments"
            disabled
            checked={formData.attachment == 2 && true}
          />
          <label className="ms-2">
            No estoy de acuerdo con la colocación de attachments en NINGUNA de
            las piezas
          </label>
        </div>
        <div>
          <input
            type="radio"
            className={`mt-2 ${formData.attachment !== 3 ? 'mb-4' : ''} inputColor`}
            name="attachments"
            disabled
            checked={formData.attachment == 3 && true}
          />
          <label className="ms-2">
            Estoy de acuerdo con la colocación de attachments, pero NO en las
            siguientes piezas:
          </label>
        </div>
      </div>
      {formData.attachment == 3 && (
        <TeethSelector formData={formData} type="attachments" />
      )}
      <p>Puede detallar a continuación:</p>
      <div className="step-movements-text-area">
        {formData.attachment_description}
      </div>
    </div>
  )
}

export default Attachments
