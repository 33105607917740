import { Message } from '@styled-icons/boxicons-regular'
import React from 'react'

export default function ConsultCard(props) {
    return (
        <div className="d-flex flex-row row p-0 mt-3">
            <div className="col-2">
                <div className="rounded-circle p-2 d-flex justify-content-center" style={{backgroundColor: '#57758C'}}><Message size="22" color="#fff" /></div>
            </div>
            <div className="col-10 d-flex flex-column">
                <p className="fw-bold text-muted mb-1">Consulta Atención y Logística</p>
                <small className="text-muted">@Camila Palmeiro</small>
                <div className="mt-4 d-flex flex-column">
                    <small className="fw-bold mb-1 text-muted">Fecha de entrega de tratamiento</small>
                    <p className="text-muted" style={{fontSize: '.8rem', marginBottom: '5px'}}>Doctor: <span className="fw-bold">Maria Lumi</span></p>
                    <p className="text-muted" style={{fontSize: '.8rem', marginBottom: '5px'}}>Paciente: <span className="fw-bold">Maria Lumi</span></p>
                </div>
            </div>
            <hr className="mt-3 mb-0 bg-gray-700" />
        </div>
    )
}
