import React from 'react'
import { Edit } from 'react-feather'
import DeleteOdModal from './delete_od_modal'
import { User } from 'react-feather'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import sglogo from '../../../assets/images/study_group_logo.png'
import saologo from '../../../assets/images/sao_logo.png'

import gold from '../../../assets/images/sc-gold.png'
import platinum from '../../../assets/images/sc-platinum.png'
import black from '../../../assets/images/sc-black.png'

import './index.css'
import handleToast from '../../../components/toaster'

function OdCard({ data, state, setState }) {
  function handleSCImage() {
    let components = {
      Silver: platinum,
      Gold: gold,
      Platinum: platinum,
      Black: black,
      Beginner: platinum,
    }

    return components[data.smile_creator_type]
  }

  return (
    <div className="row mx-auto px-3">
      <div className="col-2 d-flex flex-row justify-content-left">
        <div
          style={{
            width: '30px',
            height: '30px',
            border: '1px solid #ccc',
            borderRadius: '20px',
            marginTop: '5px',
            marginRight: '5px',
          }}
        >
          {data?.user?.avatar ? (
            <img
              src={data.user.avatar}
              alt="avatar"
              width={28}
              height={28}
              className="od_avatar"
            />
          ) : (
            <User size={25} />
          )}
        </div>
        <CopyToClipboard text={`${data.user?.name} ${data.user?.surname}`}>
          <p
            onClick={() => handleToast('success', '¡Nombre copiado!', 1500)}
            title={`${data.user?.name} ${data.user?.surname}`}
            className="mb-0 text-truncate me-2 hover-bold cursor-pointer"
            style={{ lineHeight: '38px' }}
          >
            {data.user?.name} {data.user?.surname}
          </p>
        </CopyToClipboard>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <div
          className="mb-0 d-flex flex-row justify-content-center align-items-center"
          style={{ lineHeight: '38px' }}
        >
          {data?.user?.groups?.length !== 0 &&
            data?.user?.groups?.map((group) => (
              <img
                className="ms-2"
                src={group.id === 1 ? saologo : sglogo}
                alt={group.name}
                style={{ width: '36px', height: '30px' }}
              />
            ))}
        </div>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        {data?.smile_creator_type && (
          <img
            src={handleSCImage()}
            style={{ width: '30px', height: '30px', marginTop: '5px' }}
            alt="Smile Creator"
          />
        )}
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <CopyToClipboard text={data.user?.email}>
          <p
            onClick={() => handleToast('success', '¡Email copiado!', 1500)}
            title={data.user?.email}
            className=" mb-0 text-center cursor-pointer text-truncate hover-bold"
            style={{ lineHeight: '38px' }}
          >
            {data.user?.email}
          </p>
        </CopyToClipboard>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <CopyToClipboard text={data.phone_number}>
          <p
            onClick={() => handleToast('success', '¡Telefono copiado!', 1500)}
            title={data.phone_number}
            className="mb-0 text-truncate cursor-pointer hover-bold"
            style={{ lineHeight: '38px' }}
          >
            {data.phone_number}
          </p>
        </CopyToClipboard>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {data.patients_count}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {data.treatments_count}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-end">
        <a
          href={`/odontologos/${data.id}`}
          style={{ fontSize: '.8rem', lineHeight: '25px' }}
          className="edit ms-1 me-2"
        >
          <Edit color="#444" className="mt-2" />
        </a>

        <DeleteOdModal
          state={state}
          setState={setState}
          id={data.id}
          groups={data?.user?.groups}
          patients={data.patients_count}
          active_treatments={data.treatments_count}
          name={data.user?.full_name}
          sao={data.sao}
        />
      </div>
    </div>
  )
}

export default OdCard
