import React from 'react'
import ReactTooltip from 'react-tooltip'
import { HelpCircle } from 'react-feather'
import Select from 'react-select'
import { useSelector } from 'react-redux'
export const TreatmentStepOne = ({ state, setState }) => {
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const handleChangeTreatment = (id) => {
    setState({ ...state, treatment_type: id })
  }

  const maxillaOptions = [
    { label: 'Superior', value: 2 },
    { label: 'Inferior', value: 1 },
    { label: 'Ambos', value: 3 },
  ]

  const genderOptions = [
    { label: 'Masculino', value: 'm' },
    { label: 'Femenino', value: 'f' },
    { label: 'Otro', value: 'o' },
  ]

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      borderBottom: '2px solid #0027a8!important',
    }),
  }

  if (pageWidth === 'mobile')
    return (
      <div className="mt-5 px-4 max-width-600">
        <p className="fs-4 fw-bold text-primary">Datos del Paciente </p>
        <label htmlFor="nombre">Nombre</label>
        <input
          type="text"
          className="form-control"
          id="nombre"
          onChange={(e) => setState({ ...state, nombre: e.target.value })}
          value={state.nombre}
        />
        <label htmlFor="apellido">Apellido</label>
        <input
          type="text"
          className="form-control"
          id="apellido"
          onChange={(e) => setState({ ...state, apellido: e.target.value })}
          value={state.apellido}
        />
        <label htmlFor="genero">Género</label>
        <Select
          styles={selectStyle}
          options={genderOptions}
          placeholder="Seleccionar Género..."
          value={state.gender_select}
          onChange={(e) => {
            setState({
              ...state,
              gender_select: e,
              genero: e.value,
            })
          }}
        />

        <label htmlFor="maxilar" className="me-1 mt-3">
          Maxilar
        </label>
        <Select
          styles={selectStyle}
          options={maxillaOptions}
          isSearchable
          value={state.maxilla}
          placeholder={'Seleccionar Maxilar..'}
          onChange={(e) =>
            setState({
              ...state,
              maxilla: e,
              maxilar_string: e.label,
              maxilar: Number(e.value),
            })
          }
        />
        <span style={{ color: 'red' }}>{state.errors['gender']}</span>
        <p className="fs-3 my-3 fw-bold text-primary"> Tratamiento </p>

        <button
          onClick={(e) => {
            e.preventDefault()
            handleChangeTreatment(1)
          }}
          className={` btn w-100 my-2 rounded-pill btn-outline-primary ${
            state.treatment_type === 1 && 'active'
          }`}
        >
          KeepSmiling Full
        </button>

        <button
          onClick={(e) => {
            e.preventDefault()
            handleChangeTreatment(4)
          }}
          className={` btn w-100 my-2 rounded-pill btn-outline-warning ${
            state.treatment_type === 4 && 'active'
          }`}
        >
          KeepSmiling Kids
        </button>

        <button
          onClick={(e) => {
            e.preventDefault()
            handleChangeTreatment(2)
          }}
          className={` btn w-100 my-2 rounded-pill btn-outline-secondary ${
            state.treatment_type === 2 && 'active'
          }`}
        >
          KeepSmiling Fast
        </button>

        <button
          onClick={(e) => {
            e.preventDefault()
            handleChangeTreatment(5)
          }}
          className={` btn w-100 my-2 rounded-pill btn-outline-danger ${
            state.treatment_type === 5 && 'active'
          }`}
        >
          KeepSmiling Kids Fast
        </button>

        <button
          onClick={(e) => {
            e.preventDefault()
            handleChangeTreatment(3)
          }}
          className={` btn w-100 my-2 rounded-pill btn-outline-info ${
            state.treatment_type === 3 && 'active'
          }`}
        >
          KeepSmiling Medium
        </button>

        <button
          onClick={(e) => {
            // e.preventDefault()
            // handleChangeTreatment(6)
          }}
          disabled
          className={` btn w-100 my-2 rounded-pill btn-outline-yellow ${
            state.treatment_type === 6 && 'active'
          }`}
        >
          KeepSmiling Teen ¡Próximamente!
        </button>
      </div>
    )
  return (
    <>
      <div style={{ height: '100%', overflowX: 'hidden' }}>
        <div className="container mt-2">
          <div className="row mt-4">
            {state.error.error ? (
              <div className="col-12 alert alert-danger text-center">
                {state.error.message}
              </div>
            ) : null}

            <div className="col-12 mx-auto">
              <form className="row col-6 mx-auto">
                <div className="col-12 d-flex flex-column mt-3">
                  <label className="fs-4 mt-2 mb-3" htmlFor="paciente">
                    Datos del Paciente
                  </label>
                </div>
                <div className="col-6 pe-1">
                  <label htmlFor="nombre">Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    onChange={(e) =>
                      setState({ ...state, nombre: e.target.value })
                    }
                    value={state.nombre}
                  />
                  <span style={{ color: 'red' }}>{state.errors['name']}</span>
                </div>
                <div className="col-6">
                  <label htmlFor="apellido">Apellido</label>
                  <input
                    type="text"
                    className="form-control"
                    id="apellido"
                    onChange={(e) =>
                      setState({ ...state, apellido: e.target.value })
                    }
                    value={state.apellido}
                  />
                  <span style={{ color: 'red' }}>
                    {state.errors['surname']}
                  </span>
                </div>
              </form>
              <form className="row mb-4 col-6 mx-auto">
                <div className="col-6 pe-1">
                  <label htmlFor="genero">Género</label>
                  <Select
                    styles={selectStyle}
                    options={genderOptions}
                    placeholder="Seleccionar Género..."
                    value={state.gender_select}
                    onChange={(e) => {
                      setState({
                        ...state,
                        gender_select: e,
                        genero: e.value,
                      })
                    }}
                  />
                  <span style={{ color: 'red' }}>{state.errors['gender']}</span>
                </div>
                <div className="col-6">
                  <div className="d-flex">
                    <label htmlFor="maxilar" className="me-1">
                      Maxilar
                    </label>
                    <div data-tip data-for="maxilar_tooltip">
                      <HelpCircle size={18} />
                    </div>
                  </div>
                  <ReactTooltip id="maxilar_tooltip">
                    <div className="col-6 d-flex flex-row">
                      <p className="fs-3 fw-bold">?</p>
                      <p className="mt-2 ms-1">
                        Ayuda <span className="fw-bold">KeepSmiling</span>
                      </p>
                    </div>
                    <div>
                      <p>Maxilar</p>
                      <p>
                        Debe indicar el maxilar a tratar. Superior, inferior o
                        ambos. Si
                        <br /> tiene dudas sobre la viabilidad del tratamiento
                        sobre un
                        <br /> maxilar, debe enviar antes una consulta de
                        Viabilidad. En la
                        <br /> cual, el equipo KeepSmiling evaluara la
                        documentacion de
                        <br /> su paciente y le podrá aconsejar.
                      </p>
                    </div>
                  </ReactTooltip>
                  <Select
                    styles={selectStyle}
                    options={maxillaOptions}
                    isSearchable
                    value={state.maxilla}
                    placeholder={'Seleccionar Maxilar..'}
                    onChange={(e) =>
                      setState({
                        ...state,
                        maxilla: e,
                        maxilar_string: e.label,
                        maxilar: Number(e.value),
                      })
                    }
                  />
                  <span style={{ color: 'red' }}>{state.errors['gender']}</span>
                </div>
              </form>
              <form className="row col-6 mx-auto">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-row">
                    <h4 className="me-2">Tratamiento</h4>
                    <div data-tip data-for="treatment_tooltip">
                      <HelpCircle size={22} className="mt-1" />
                    </div>
                    {/* LOS TOOLTIP SOLO DEBEN MOSTRARSE COMO ODONTOLOGO */}
                    <ReactTooltip id="treatment_tooltip">
                      <div className="col-6 d-flex flex-row">
                        <p className="fs-3 fw-bold">?</p>
                        <p className="mt-2 ms-1">
                          Ayuda <span className="fw-bold">KeepSmiling</span>
                        </p>
                      </div>
                      <div>
                        <p>Tipo de Tratamiento</p>
                        <p>
                          Si tiene dudas del tipo de tratamiento que requiere su
                          paciente,
                          <br /> entonces debe, primero, enviar una solicitud de
                          Viabilidad de
                          <br /> tratamiento. En la cual, el equipo de
                          KeepSmiling evaluara la
                          <br /> documentacion de su paciente y le aconsejara
                          que tipo de
                          <br />
                          tratamiento corresponde al caso de su paciente.
                        </p>
                      </div>
                    </ReactTooltip>
                  </div>
                </div>
                <div className="col-6 pe-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleChangeTreatment(1)
                    }}
                    className={` btn w-100 my-2 rounded-pill btn-outline-primary ${
                      state.treatment_type === 1 && 'active'
                    }`}
                  >
                    KeepSmiling Full
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleChangeTreatment(4)
                    }}
                    className={` btn w-100 my-2 rounded-pill btn-outline-warning ${
                      state.treatment_type === 4 && 'active'
                    }`}
                  >
                    KeepSmiling Kids
                  </button>
                </div>
                <div className="col-6 pe-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleChangeTreatment(2)
                    }}
                    className={` btn w-100 my-2 rounded-pill btn-outline-secondary ${
                      state.treatment_type === 2 && 'active'
                    }`}
                  >
                    KeepSmiling Fast
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleChangeTreatment(5)
                    }}
                    className={` btn w-100 my-2 rounded-pill btn-outline-danger ${
                      state.treatment_type === 5 && 'active'
                    }`}
                  >
                    KeepSmiling Kids Fast
                  </button>
                </div>
                <div className="col-6 pe-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      handleChangeTreatment(3)
                    }}
                    className={` btn w-100 my-2 rounded-pill btn-outline-info ${
                      state.treatment_type === 3 && 'active'
                    }`}
                  >
                    KeepSmiling Medium
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={(e) => {
                      // e.preventDefault()
                      // handleChangeTreatment(6)
                    }}
                    disabled
                    className={` btn w-100 my-2 rounded-pill btn-outline-yellow ${
                      state.treatment_type === 6 && 'active'
                    }`}
                  >
                    KeepSmiling Teen ¡Próximamente!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
