import React, { useState } from 'react'
import ClinicHistoryModal from '../../../../components/MedicalRecordModal/ClinicHistoryModal'
import PropTypes from 'prop-types'
import '../../buttons.css'
import '../../index.css'
import ConfirmationModal from '../../../../components/confirmation_modal'
import apiClient from '../../../../utils/backend'
import handleToast from '../../../../components/toaster'
import moment from 'moment'
import { useSelector } from 'react-redux'

const DispatchCard = ({ data, getInitialData }) => {
  const { countryId } = useSelector((state) => state.userReducer)
  const [state, setState] = useState({
    modal_show: false,
    modal_id: data.id,
  })
  const principalDentist = data.dentists.filter(
    (dentist) => dentist.id === data.principal_dentist
  )
  const treatmentProposalDate =
    data.proposals[data.proposals.length - 1]?.created_at

  const handleTreatmentType = (type) => {
    const types = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      'Kids Fast': 5,
      Teens: 6,
      'Sin tipo': 1,
    }
    return types[type]
  }

  const handleDispatch = async (id) => {
    try {
      await apiClient.post(`api/treatments/${id}/dispatch`)
      getInitialData(countryId)
      handleToast('success', 'Tratamiento despachado!')
    } catch (error) {
      console.log(error)
      getInitialData(countryId)
      handleToast('error', 'Error al despachar tratamiento')
    }
  }

  const handleModal = (id) => {
    if (id)
      return setState({ ...state, modal_show: !state.modal_show, modal_id: id })
    else
      return setState({
        ...state,
        modal_show: !state.modal_show,
      })
  }

  return (
    <div className="patientCard d-flex align-items-center justify-content-between">
      <div
        className={`patientHistory type-${handleTreatmentType(data.treatment)}`}
        onClick={() => setState({ ...state, modal_show: !state.modal_show })}
      >
        <ClinicHistoryModal
          id={state.modal_id}
          opened={state.modal_show}
          handleModal={handleModal}
        />
      </div>
      <div className="d-flex my-2 ms-3">
        <div className="id">
          <span
            title={data.external_id}
            className="m-0 text-truncate"
            style={{ minWidth: '50px' }}
          >
            {data.external_id}
          </span>
          <div className="avatar ms-1">
            <img
              alt="avatar"
              src={data.patient?.avatar}
              className="avatarIMG"
            />
          </div>
        </div>
        <div className="patient d-flex align-items-center">
          <span
            className="m-0 text-truncate"
            title={`${data.patient.name} ${data.patient.surname}`}
          >
            {`${data.patient.name} ${data.patient.surname}`}
          </span>
        </div>
        <div className="doctor d-flex align-items-center">
          <span
            className="m-0 text-truncate"
            title={`${principalDentist[0].user.name} ${principalDentist[0].user.surname}`}
          >
            {`${principalDentist[0].user.name} ${principalDentist[0].user.surname}`}
          </span>
        </div>
        <div className="treatment d-flex align-items-center">
          <span className="m-0 ms-2 text-truncate" title={`${data.treatment}`}>
            {`${data.treatment}`}
          </span>
        </div>
        <div className="stage d-flex align-items-center">
          <span className="m-0 text-truncate" title={`${data.stage}`}>
            {`${data.stage}`}
          </span>
        </div>
        <div className="maxillar d-flex align-items-center">
          <span
            className="m-0 text-truncate"
            title={`${data.maxilla === null ? '-' : data.maxilla}`}
          >
            {`${data.maxilla === null ? '-' : data.maxilla}`}
          </span>
        </div>
        <div className="movements d-flex align-items-center">
          <span
            className="m-0 text-truncate"
            title={`${data.movements === null ? 0 : data.movements}`}
          >
            {`${data.movements === null ? 0 : data.movements}`}
          </span>
        </div>
        <div className="treatmentProposal d-flex align-items-center">
          <span
            className="m-0 text-truncate"
            title={moment(treatmentProposalDate).format('DD/MM/YYYY')}
          >
            {moment(treatmentProposalDate).format('DD/MM/YYYY')}
          </span>
        </div>
      </div>
      <div className="mx-3">
        <ConfirmationModal
          type="dispatch"
          data={data}
          proposalId={data.id}
          principalDentist={principalDentist}
          handleDispatch={handleDispatch}
        />
      </div>
    </div>
  )
}

DispatchCard.propTypes = {
  data: PropTypes.object.isRequired,
  getInitialData: PropTypes.func.isRequired,
}

export default DispatchCard
