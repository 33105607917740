import React from 'react'
import { useSelector } from 'react-redux'
import CHEmpty from '../../../../assets/images/CH-empty.png'

const StatusZero = () => {
  const { countryId } = useSelector((reduxState) => reduxState.userReducer)
  const isChilean = countryId === 5

  return (
    <div className="col-9 treatmentStateBox p-2">
      <p className="text-center fw-bold">Estado de tratamiento:</p>
      <div
        className="col-10 mt-5 mb-5 ms-2"
        style={{ position: 'relative', maxWidth: '70vw' }}
        id="timeline"
      >
        <ul
          className="w-100"
          style={{
            background: '#ECEEF1',
            height: '6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            listStyleType: 'none',
            position: 'relative',
          }}
        >
          <li
            style={{
              width: '110%',
              height: '100%',
              position: 'absolute',
              padding: '23px',
              top: '-30px',
              borderRadius: '30px',
              left: '-3%',
              right: '0',
            }}
          ></li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '-2px',
            }}
          >
            <img src={CHEmpty} className="barItem" alt="CHCheck" />
            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              {isChilean ? (
                <p>
                  Ingreso <br /> del paciente
                </p>
              ) : (
                <p>
                  Alta de <br /> paciente
                </p>
              )}
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '35px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '25%',
            }}
          >
            <img src={CHEmpty} className="barItem" alt="CHCheck" />

            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              Documentación completa
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '50%',
            }}
          >
            <img src={CHEmpty} className="barItem" alt="CHCheck" />

            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              Propuesta de tratamiento
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '75%',
            }}
          >
            <img src={CHEmpty} className="barItem" alt="CHCheck" />

            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              En producción de alineadores
            </span>
          </li>
          <li
            style={{
              background: '#fff',
              width: '42px',
              height: '39px',
              borderRadius: '50%',
              position: 'absolute',
              top: '-14px',
              zIndex: 10,
              left: '100%',
            }}
          >
            <img className="barItem" alt="CHCheck" src={CHEmpty} />
            <span
              style={{
                position: 'absolute',
                top: '50px',
                width: '100px',
                textAlign: 'center',
                left: '-30px',
                fontWeight: 'bold',
                fontSize: '.6rem',
              }}
            >
              Entrega de <br /> Tratamiento
            </span>
          </li>
        </ul>
        <ul
          style={{
            background: '#2ecc71',
            height: '6px',
            width: '0%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            listStyleType: 'none',
            position: 'absolute',
            top: '0',
            zIndex: 9,
          }}
        ></ul>
      </div>
    </div>
  )
}

export default StatusZero
