import React from 'react'
import { Edit2 } from 'react-feather'
import moment from 'moment'

const CHIsTreatment = (props) => {
  const {
    editRegistrationDate,
    data,
    handleSetRegistrarionDate,
    setEditRegistrationDate,
  } = props
  return (
    <div className="d-flex align-items-center">
      <p className="my-1 fw-bold">
        {editRegistrationDate ? (
          <input
            max={new Date().toISOString().split('T')[0]}
            type="date"
            className="registrationDateInput"
            value={data.registration_date}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              handleSetRegistrarionDate(e.target.value, e)
            }}
          />
        ) : (
          <span>
            {moment(data.registration_date.split(' ')[0]).format('DD-MM-YYYY')}
          </span>
        )}
        <Edit2
          size={13}
          className="ms-1 mb-1 editRegistrationDate"
          onClick={() => setEditRegistrationDate(!editRegistrationDate)}
        />
      </p>
    </div>
  )
}

export default CHIsTreatment
