import React, { useEffect, useState } from 'react'
import { Bell } from 'react-feather'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './styles.css'
import AdminNotificationsList from './components/AdminNotificationsList'
import DentistNotificationsList from './components/DentistNotificationsList'
import { useLocation } from 'react-router-dom'
import { setDropdown } from '../../redux/actions/notificationDropdown'

export default function Notifications() {
  const [showTag, setShowTag] = useState(false)
  const user = useSelector((state) => state.userReducer)
  const { active } = useSelector((state) => state.notificationsDropdownReducer)
  const { collapsed } = useSelector((state) => state.navbarReducer)
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const location = useLocation()
  const dispatch = useDispatch()

  const displayRedTag = (display) => {
    if (display) return setShowTag(true)
    return setShowTag(false)
  }

  const handleDropdownState = (state) => {
    dispatch(setDropdown(state))
    if (pageWidth === 'desktop' || collapsed) return
    dispatch({ type: 'COLLAPSE' })
  }

  // Closes dropdown on Route change
  useEffect(() => {
    handleDropdownState(false)
  }, [location])

  useEffect(() => {
    if (pageWidth === 'desktop') return
    if (!collapsed && active) dispatch(setDropdown(false))
  }, [active, collapsed, dispatch, pageWidth])

  return (
    <div style={{ position: 'relative', maxWidth: '300px' }}>
      <button
        className="btn bg-none"
        onClick={() => handleDropdownState(!active)}
      >
        <Bell className="DD-red-tag-container" size={20} color="#072462" />

        {showTag && <div className="DD-red-tag"></div>}
      </button>
      <div
        className={
          active ? 'DD-notifications-container container-fluid' : 'd-none'
        }
      >
        <div className="row">
          <div className="col-12 border-bottom border-1 p-2 px-4">
            <h4 className="text-primary fw-bold">Notificaciones</h4>
          </div>
        </div>
        <div className="row overflow-auto" style={{ height: '80%' }}>
          {user.typeId === 1 && (
            <AdminNotificationsList
              active={active}
              displayRedTag={displayRedTag}
            />
          )}

          {user.typeId === 2 && (
            <DentistNotificationsList
              displayRedTag={displayRedTag}
              active={active}
              dentistId={user.dentistId}
            />
          )}
        </div>
        <div className="rounded-bottom" style={{ backgroundColor: '#D7E4EB' }}>
          <Link
            to={
              user.typeId === 1 ? '/admin/notificaciones' : '/od/notificaciones'
            }
            onClick={() => handleDropdownState(false)}
            className="btn p-2 text-center text-primary w-100 m-0"
          >
            Ver todas
          </Link>
        </div>
      </div>
    </div>
  )
}
