import React, { useRef, useState } from 'react'
import FormOnline from '../../../FormularioOnline'
import ImageImput from '../components/ImageInput'
import '../../index.css'

export const ViabilityFourDentist = ({
  state,
  setState,
  checkFrotalSonriendo,
}) => {
  const plantratamiento = useRef()

  const handleImageSelect = async (name, img, type) => {
    setState({
      ...state,
      [name]: img,
      cropper_img: img,
      [type]: {
        ...state[type],
        [name]: img,
      },
    })
  }

  return (
    <div style={{ height: '100%', overflowX: 'hidden', minHeight: '300px' }}>
      <div className="container mt-2">
        <div className="row d-flex flex-row justify-content-center mx-auto px-1 mb-5">
          <div className="col-6 d-flex flex-row justify-content-center">
            <button
              className={`btn btn-outline-primary border-2 p-4 ${
                state.online_plan && 'disabled bg-primary text-white'
              }`}
              style={{ borderRadius: '15px' }}
              onClick={() =>
                setState({
                  ...state,
                  online_plan: !state.online_plan,
                  image_plan: false,
                })
              }
            >
              Cargar Formulario Online
            </button>
          </div>
          <div
            className="col-6 d-flex flex-row justify-content-center"
            style={{ paddingRight: '50px' }}
          >
            <button
              className={`btn btn-outline-primary border-2 p-4 ${
                state.image_plan && 'disabled bg-primary text-white'
              }`}
              style={{ borderRadius: '15px' }}
              onClick={() =>
                setState({
                  ...state,
                  image_plan: !state.image_plan,
                  online_plan: false,
                })
              }
            >
              Cargar Formulario en Foto
            </button>
          </div>
          {state.online_plan && (
            <div className="col-12">
              <FormOnline
                applicationState={state}
                setState={setState}
                type={state.treatment_type}
                checkFrotalSonriendo={checkFrotalSonriendo}
                nextParentStep={(e) =>
                  setState({ ...state, step: 5, online_form_state: e })
                }
              />
            </div>
          )}
          {state.image_plan && (
            <div className="col-8 d-flex flex-row justify-content-end mx-auto">
              <div
                className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                style={{
                  maxHeight: '200px',
                  width: '200px',
                  position: 'relative',
                }}
              >
                <ImageImput
                  name="plantratamiento"
                  id="Plan de Tratamiento"
                  src="plan"
                  loadImage={state.plantratamiento}
                  ref={plantratamiento}
                  handleImageSelect={handleImageSelect}
                />
              </div>
            </div>
          )}
          <div className="col-12"></div>
        </div>
      </div>
    </div>
  )
}
